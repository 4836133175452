import { IStackTokens, mergeStyles, SharedColors, Stack, Text,
} from '@fluentui/react';
import { ActionCenterContext } from 'components/actionCenterProvider/actionCenterContext';
import { ActionCenterData } from 'components/actionCenterProvider/actionCenterProvider';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { LoadingState } from 'models/loadingState';
import React, { useContext, useEffect, useState } from 'react';
import { SummaryCount } from 'components/summaryCount/summaryCount';

const cardStyles = mergeStyles({
  flex: 1,
  border: '1.5px solid #eee',
  '@media (max-width: 320px)': {
    overflowX: 'scroll',
  },
});

const containerStyle = mergeStyles({
  padding: '2em 2em 2em 7em',
});

const labelStyle = mergeStyles({
  paddingTop: '2em',
  paddingLeft: '1.5em',
  fontSize: '1.5em',
  fontWeight: 'bold',
});

const stackTokens: IStackTokens = {
  childrenGap: 70,
};

export const NewSummaryWidget: React.FunctionComponent = () => {
  const actionCenterContext = useContext(ActionCenterContext);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);

  useEffect(() => {
    setIsLoading(actionCenterContext.actionCenterMetrics.loadingState);
  }, [actionCenterContext.actionCenterMetrics.loadingState]);

  useEffect(() => {
    actionCenterContext.requestContextData(ActionCenterData.ActionCenterMetrics);
  }, [actionCenterContext]);

  return (
    <div className={cardStyles}>
      <Stack horizontal>
        <Text
          className={labelStyle}
          role="heading"
          aria-level={2}>
            Summary of all items
        </Text>

        {isLoading !== LoadingState.Loaded ? (
          <CenteredProgressDots />
        ) : (
          <Stack horizontal tokens={stackTokens} wrap className={containerStyle}>
            <SummaryCount color={SharedColors.cyanBlue10} title="Clouds" count={actionCenterContext.actionCenterMetrics.contextData?.cloudCount} />
            <SummaryCount color="#EF6950" title="Offerings" count={actionCenterContext.actionCenterMetrics.contextData?.offeringCount} />
            <SummaryCount color="#001a8b" title="Services" count={actionCenterContext.actionCenterMetrics.contextData?.serviceCount} />
            <SummaryCount
              color="#00A2AD"
              title="Evidence Packages"
              count={actionCenterContext.actionCenterMetrics.contextData?.evidencePackageCount}
            />
          </Stack>
        )}
      </Stack>
    </div>
  );
};
