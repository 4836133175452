/* DEVELOPER NOTE: These changes are server side rendered and following files SHOULD maintain parity.
 * Changes are copied from the API project to the UX project on build.
 *
 * UX project : src\Microsoft.Mtac.Portal.Ux\ClientApp\src\components\textWithLineBreaksViewer.tsx
 * API project : src\Microsoft.Mtac.Portal.Api\Content\Components\domainEvidenceData\textWithLineBreaksViewer.tsx
 *
 * This component makes a simple attempt to format unformatted text like domainEvidence.description better.
 * The text from domain evidence.description contains carriage returns and bullet lists that are plain text that begin with a dash.
 * This component replaces carriage returns with <br/> and adds two breaks everywhere except before and within lists.
 * It does not attempt to create paragraphs and unordered lists with list items.
 */

import React, { FunctionComponent } from 'react';

export interface TextWithLineBreaksViewerProps {
  text?: string,
}

export const TextWithLineBreaksViewer: FunctionComponent<TextWithLineBreaksViewerProps> = (props: TextWithLineBreaksViewerProps) => {
  if (!props.text) {
    return <></>;
  }

  const lines = props.text.split('\r\n');
  return (
    <p>
      {lines.map((line, index) => {
        const nextIndex = index + 1;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {line}
            <br />
            { // regular lines that are not followed by bullets get a second line break
              !line.startsWith(' - ') && (lines.length === nextIndex || (lines.length >= nextIndex && !lines[nextIndex].startsWith(' - '))) && (
                <br />
              )
            }

            { // bullet lines not followed by a bullet line get a second line break
              line.startsWith(' - ') && lines.length > nextIndex && !lines[nextIndex].startsWith(' - ') && (
                <br />
              )
            }
          </React.Fragment>
        );
      })}
    </p>
  );
};
