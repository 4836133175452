import { Checkbox, IColumn } from '@fluentui/react';
import { BasicPoam } from 'generated/clientApi';
import React, { useEffect, useMemo, useState } from 'react';
import { getFormattedDate } from 'modules/datetime/datetime';
import { PoamColumnNames } from '../poamTable';
import { baseSortableColumn, handleTableSort } from '../../components/tableUtils';
import { formatRiskWithColor } from '../poamTableColumns';

type ClosureTableColumnsProps = {
  displayedPoams: BasicPoam[];
  onSelectionChanged: (selectedPoams: BasicPoam[]) => void;
  selectedPoams: BasicPoam[];
  sortTable: (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc') => void;
};
export const ClosureTableColumns = (props: ClosureTableColumnsProps): IColumn[] => {
  const { displayedPoams, onSelectionChanged, selectedPoams, sortTable } = props;

  useEffect(() => {
    setColumns(initialColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedPoams.length, selectedPoams.length]);

  const initialColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'column1',
        name: 'SelectAll',
        ariaLabel: 'Select All Rows',
        minWidth: 24,
        maxWidth: 24,
        onRender: (item: BasicPoam) => (
          <Checkbox
            checked={selectedPoams.some((selected) => selected.id === item.id)}
            onChange={(ev, checked) => {
              if (checked) {
                onSelectionChanged([...selectedPoams, item]);
              } else {
                onSelectionChanged(selectedPoams.filter((val) => val.id !== item.id));
              }
            }}
          />
        ),
        onRenderHeader: () => (
          <Checkbox
            styles={{
              root: {
                height: '42px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            }}
            checked={displayedPoams.length > 0 && selectedPoams.length === displayedPoams.length}
            onChange={() => {
              if (selectedPoams.length === displayedPoams.length) {
                onSelectionChanged([]);
              } else if (selectedPoams.length > 0 && selectedPoams.length < displayedPoams.length) {
                onSelectionChanged(displayedPoams);
              } else {
                onSelectionChanged(displayedPoams);
              }
            }}
          />
        ),
      },
      {
        key: PoamColumnNames['POA&M ID'],
        name: 'POA&M ID',
        fieldName: 'id',
        minWidth: 125,
        maxWidth: 225,
        isMultiline: true,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
      },
      {
        key: PoamColumnNames['Vuln ID'],
        name: 'Vuln ID',
        fieldName: 'vulnerabilityId',
        minWidth: 80,
        maxWidth: 125,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
      },
      {
        key: PoamColumnNames['Cloud type'],
        name: 'Cloud',
        fieldName: 'cloudType',
        minWidth: 75,
        maxWidth: 100,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
      },
      {
        key: PoamColumnNames.Title,
        name: 'Title',
        fieldName: 'title',
        minWidth: 125,
        maxWidth: 225,
        isMultiline: true,
      },
      {
        key: PoamColumnNames.Due,
        name: 'Due Date',
        fieldName: 'dueDate',
        minWidth: 80,
        maxWidth: 150,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
        onRender: (item: BasicPoam) => {
          if (!item.dueDate) {
            return '';
          }

          const date = new Date(item.dueDate);
          return getFormattedDate(date);
        },
      },
      {
        key: PoamColumnNames['Original risk'],
        name: 'Risk',
        fieldName: 'originalRisk',
        minWidth: 100,
        maxWidth: 125,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable, true),
        onRender: (item: BasicPoam) => formatRiskWithColor(item.originalRating ?? ''),
      },
      {
        key: PoamColumnNames.OR,
        name: 'OR',
        fieldName: 'or',
        minWidth: 75,
        maxWidth: 75,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
      },
      {
        key: PoamColumnNames['Vendor dependency'],
        name: 'Vendor dependency',
        fieldName: 'remediationInfo.vendorDependency',
        minWidth: 100,
        maxWidth: 145,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
        onRender: (item: BasicPoam) => {
          const vendorDependency = item.remediationInfo?.vendorDependency;
          return vendorDependency ?? '';
        },
      },
      {
        key: PoamColumnNames['Internal comments'],
        name: 'Internal comments',
        fieldName: 'internalComments',
        minWidth: 175,
        maxWidth: 200,
        isMultiline: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayedPoams, selectedPoams, sortTable],
  );

  const [columns, setColumns] = useState<IColumn[]>(initialColumns);
  return columns;
};
