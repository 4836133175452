import React from 'react';
import { DeviationStatuses, DeviationTypes, Poam } from 'generated/clientApi';
import { IconButton, Separator } from '@fluentui/react';
import { DeviationInformationItem } from './deviationInformationItem';

type DeviationInformationTabProps = {
  poam: Poam;
  onDeleteDeviation(poam: Poam, deviationId: string): void;
};

export const DeviationInformationTab: React.FunctionComponent<DeviationInformationTabProps> = (props) => {
  const { poam, onDeleteDeviation } = props;
  const deviations = [...(poam.deviationInfo ?? [])].sort((a, b) => new Date(b.drDate ?? '').getTime() - new Date(a.drDate ?? '').getTime());

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'flex-start',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingLeft: '0.5rem',
        gap: '0.5em',
        margin: '1.5rem 0',
      }}
    >
      {deviations.length
        ? deviations.map((deviation, index) => (
            // eslint-disable-next-line react/jsx-indent
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} key={`${deviation.id}`}>
              {index !== 0 && <Separator />}
              {deviation.drApproval === DeviationStatuses.Proposed && (
                <div style={{ marginLeft: 'auto', marginTop: '-1rem' }}>
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title="Delete"
                    ariaLabel="Delete"
                    style={{ height: '16px' }}
                    onClick={() => {
                      onDeleteDeviation(poam, deviation.id ?? '');
                    }}
                  />
                </div>
              )}
              <DeviationInformationItem title="Deviation type" text={deviation.deviationType!.replace(/([a-z])([A-Z])/g, '$1 $2')} />
              {(deviation.deviationType === DeviationTypes.ManualRiskAdjustment ||
                deviation.deviationType === DeviationTypes.AutoRiskAdjustment ||
                deviation.deviationType === DeviationTypes.RiskCalculatorAdjustment) && (
                <DeviationInformationItem
                  title="Proposed risk adjustment"
                  text={`${deviation.riskDeviationInfo?.proposedRating || ''}${
                    deviation.riskDeviationInfo?.proposedScore ? ` (${deviation.riskDeviationInfo?.proposedScore})` : ''
                  }`}
                />
              )}
              {(deviation.deviationType === DeviationTypes.AutoRiskAdjustment ||
                deviation.deviationType === DeviationTypes.RiskCalculatorAdjustment) && (
                <DeviationInformationItem title="Proposed vector string" text={deviation.riskDeviationInfo?.fullVectorString || ''} />
              )}
              <DeviationInformationItem title="DR title" text={deviation.drTitle} />
              <DeviationInformationItem title="DR date" text={deviation.drDate} />
              <DeviationInformationItem title="DR approval" text={deviation.drApproval} />
              <DeviationInformationItem title="DR review date" text={deviation.drReviewDate} />
              {deviation.justification && <DeviationInformationItem title="Justification" text={deviation.justification} />}
              {deviation.riskDeviationInfo && deviation.riskDeviationInfo?.justifications && (
                <div style={{ display: 'flex', fontSize: '1.1em', flexDirection: 'column' }}>
                  <div style={{ color: '#605E5C' }}>Risk calculator justifications</div>
                  <ul style={{ display: 'flex', flexDirection: 'column', marginLeft: '2rem' }}>
                    {Object.entries(deviation.riskDeviationInfo?.justifications).map((value) => {
                      const [key, justification] = value;
                      return <li key={crypto.randomUUID()}>{`${key.toUpperCase()} - ${justification}`}</li>;
                    })}
                  </ul>
                </div>
              )}
            </div>
            // eslint-disable-next-line indent
          ))
        : 'No deviations submitted'}
    </div>
  );
};
