import React, { FunctionComponent } from 'react';
import { DatePicker, ITextFieldStyles, TextField, defaultDatePickerStrings, mergeStyles } from '@fluentui/react';
import { textFieldStyles } from 'styles/textFieldStyles';
import { formatDateInternationalStandard, getFormattedDate, parseDate } from 'modules/datetime/datetime';
import { datePickerStyles } from 'styles/datePickerStyles';

const readonlyWrapperStyles = mergeStyles({
  display: 'flex',
  marginBottom: '1em',
});

const fieldLabelStyles = mergeStyles({
  margin: 'auto 0.5em auto  0',
  whiteSpace: 'nowrap',
});

const multiLineFieldLabelStyles = mergeStyles({
  margin: '1em 0.5em 0  0',
  whiteSpace: 'nowrap',
});

const inplaceTextFieldStyles = mergeStyles({
  width: '100%',
  '.ms-TextField-fieldGroup': {
    width: 'unset',
  },
});

const dateStyles = mergeStyles({
  width: '-webkit-fill-available',
  '.ms-TextField-fieldGroup': {
    width: 'unset',
  },
});

const inplaceTextFieldReadonlyStyles = mergeStyles({
  width: '100%',
  '.ms-TextField-fieldGroup': {
    border: '1px solid transparent !important',
    height: 'unset',
  },
  '.ms-TextField-field': {
    fontWeight: '600',
  },
});

const headerFieldStyles: Partial<ITextFieldStyles> = {
  field: {
    fontSize: '2em',
    fontWeight: 'bold',
    padding: 0,
  },
};

interface InplaceTextFieldProps {
  label: string;
  value: string;
  editMode: boolean;
  name?: string;
  notEditable?: boolean;
  isMultiline?: boolean;
  headerStyle?: boolean;
  disabled?: boolean;
  isDate?: boolean;
  onChange?(event: any, newValue?: string): void;
}

export const InplaceTextField: FunctionComponent<InplaceTextFieldProps> = (props) => (
  <div className={readonlyWrapperStyles}>
    {/* With header style, only want to show the label when in edit mode */}
    {(!props.headerStyle || props.editMode) && <div className={props.isMultiline ? multiLineFieldLabelStyles : fieldLabelStyles}>{props.label}:</div>}
    {props.isDate && props.editMode ? (
      <DatePicker
        allowTextInput
        ariaLabel="Select a date"
        value={parseDate(props.value)}
        styles={datePickerStyles}
        className={dateStyles}
        placeholder="Select a date"
        formatDate={formatDateInternationalStandard}
        onSelectDate={(date: Date | null | undefined) => props.onChange && props.onChange(undefined, date ? getFormattedDate(date) : '')}
        strings={defaultDatePickerStrings}
        disabled={props.disabled}
      />
    ) : (
      <TextField
        styles={props.headerStyle && !props.editMode ? headerFieldStyles : textFieldStyles}
        className={props.editMode && !props.notEditable ? inplaceTextFieldStyles : inplaceTextFieldReadonlyStyles}
        name={props.name || props.label}
        value={props.value || ''}
        onChange={props.onChange}
        borderless={!props.editMode}
        readOnly={!props.editMode || props.notEditable}
        multiline={props.isMultiline}
        disabled={props.disabled}
        resizable={props.isMultiline && props.editMode}
      />
    )}
  </div>
);
