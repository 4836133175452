import React, { FunctionComponent, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

export interface SavePromptProps {
  when: boolean;
}

export const SavePrompt: FunctionComponent<SavePromptProps> = (props: SavePromptProps) => {
  const { when } = props;

  useEffect(() => {
    if (when) {
      const beforeUnloadHandler = (ev: BeforeUnloadEvent): void => {
        ev.preventDefault();
        // eslint-disable-next-line no-param-reassign
        ev.returnValue = 'Your unsaved edits will be discarded.';
      };

      window.addEventListener('beforeunload', beforeUnloadHandler);

      return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
    }

    return undefined;
  }, [when]);

  return <Prompt when={when} message="Are you sure you want to leave this page? Your unsaved edits will be discarded." />;
};
