import { Observable, Subject } from 'rxjs';
import { ApiException, MtacClaim, UserOperationsGetClient } from '../../generated/clientApi';
import { logError } from '../../modules/logging/logging';

import { getConfig } from '../config/config';

const userRolesChangeSubject = new Subject<string>();

let userOperations: MtacClaim[];

export interface UserOperationsResponse {
  userOperations?: MtacClaim[];
  error?: string;
}

export const initializeUserOperations = async (): Promise<UserOperationsResponse> => {
  try {
    const client = new UserOperationsGetClient(getConfig().apiBaseUri);
    const result = await client.get();
    userOperations = result.claims ? result.claims : [];
    return { userOperations };
  } catch (error) {
    logError('An error occurred while initializing the user operation', error);
    const apiError = error as ApiException;
    return { error: apiError.response };
  }
};

export const getUserOperations = (): MtacClaim[] => userOperations;
export const onChangeUserRoles = (): Observable<string> => userRolesChangeSubject.asObservable();
