import {
  DetailsList,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  IPanelStyles,
  Link,
  MessageBar,
  MessageBarType,
  NeutralColors,
  Panel,
  PanelType,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { BaselineControlGroupResponse, CatalogGetResponse, IGroupGetResponse, OscalCatalogControl } from '../../generated/clientApi';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { LoadingState } from '../../models/loadingState';
import { getControl, getControlCatalog, getControlCatalogControlGroups, getControlGroup } from '../../modules/controlCatalogs/controlCatalogs';
import { logError } from '../../modules/logging/logging';
import { ControlCatalogRouteParams } from '../../modules/routes/routes';
import { ControlDetail } from './controlDetail';
import { updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { createControlCatalogBladeChromeConfig } from '../../modules/pageChrome/catalogPageChrome';
import { ControlCatalogNavLinks } from '../../models/controlCatalogNavLinks';
import { HorizontalRule } from '../../styles/horizontalRule';

const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    margin: '1em 0 2em',
  },
  dropdown: {
    color: '#707070 !important',
    height: 'unset !important',
    width: 300,
    ':focus': {
      border: `1px solid ${NeutralColors.black} !important`,
    },
  },
  title: {
    borderRadius: '4px',
    border: '1px solid lightgrey !important',
    color: '#707070 !important',
    height: 'unset',
    padding: '0.25em 0.5em',
  },
  label: {
    fontWeight: '600 !important',
  },
  dropdownOptionText: {
    color: '#707070 !important',
  },
};

const panelStyles: Partial<IPanelStyles> = {
  scrollableContent: {
    overflow: 'auto',
  },
};

export const ControlCatalogDetail: React.FunctionComponent = () => {
  const [controlCatalog, setControlCatalog] = useState<CatalogGetResponse>();
  const [controlCatalogControlGroupOptions, setControlCatalogControlGroupOptions] = useState<IDropdownOption[]>([]);
  const [selectedControlCatalogGroup, setSelectedControlCatalogGroup] = useState<IGroupGetResponse>();
  const [selectedControl, setSelectedControl] = useState<OscalCatalogControl>();
  const [loading, setLoading] = useState(LoadingState.Loading);
  const [isControlListLoading, setIsControlListLoading] = useState(false);
  const [isControlLoading, setIsControlLoading] = useState(false);
  const { controlCatalogId } = useParams<ControlCatalogRouteParams>();

  useEffect(() => {
    updatePageChromeConfig(createControlCatalogBladeChromeConfig(ControlCatalogNavLinks.ControlCatalogs));
    return () => updatePageChromeConfig();
  }, []);

  useEffect(() => {
    const getItems = async () => {
      try {
        setLoading(LoadingState.Loading);
        const [controlCatalogResult, catalogControlGroupsResult] = await Promise.all([
          getControlCatalog(controlCatalogId),
          getControlCatalogControlGroups(controlCatalogId),
        ]);
        setControlCatalog(controlCatalogResult);
        setControlCatalogControlGroupOptions(
          catalogControlGroupsResult
            .map((controlGroupResult) => ({
              key: controlGroupResult.id ?? '',
              text: `${controlGroupResult.title ?? ''} (${controlGroupResult.id?.toUpperCase()})`,
            }))
            .filter((controlGroup) => controlGroup.key !== '' && controlGroup.text !== ''),
        );
        setLoading(LoadingState.Loaded);
      } catch (e) {
        logError(`Error retreiving Control Catalog with Id ${controlCatalogId}`, e);
        setLoading(LoadingState.Error);
      }
    };
    getItems();
  }, [controlCatalogId]);

  const onChangeControlGroupDropdown = (ev: any, selectedControlGroup: any) => {
    const getGroupAndSet = async () => {
      try {
        setIsControlListLoading(true);
        const controlGroupGetResult = await getControlGroup(controlCatalogId, selectedControlGroup.key);
        setSelectedControlCatalogGroup(controlGroupGetResult);
        setSelectedControl(undefined);
        setIsControlListLoading(false);
      } catch (e) {
        logError(`Error retreiving Control Catalog with Id ${controlCatalogId}`, e);
        setLoading(LoadingState.Error);
      }
    };
    getGroupAndSet();
  };

  const onControlClick = (controlId: string | undefined) => {
    const getControlAndSet = async () => {
      setSelectedControl(undefined);
      if (!controlId) {
        setLoading(LoadingState.Error);
        return;
      }

      try {
        setIsControlLoading(true);
        const controlGetResult = await getControl(controlCatalogId, selectedControlCatalogGroup?.id || '', controlId);
        setSelectedControl(controlGetResult?.control);
        setIsControlLoading(false);
      } catch (e) {
        logError(`Error retreiving Control Catalog with Id ${controlCatalogId}`, e);
        setLoading(LoadingState.Error);
      }
    };
    getControlAndSet();
  };

  const controlPanelDisplay = (
    <Panel
      headerText={selectedControl ? `(${selectedControl?.id}) - ${selectedControl?.title}` : ''}
      isOpen={isControlLoading || selectedControl !== undefined}
      onDismiss={() => setSelectedControl(undefined)}
      closeButtonAriaLabel="Close"
      isBlocking={false}
      type={PanelType.medium}
      styles={panelStyles}
    >
      {isControlLoading && <CenteredProgressDots />}
      {selectedControl && <ControlDetail control={selectedControl} />}
    </Panel>
  );

  const controlCatalogDetailColumns = [
    {
      key: 'id',
      name: 'Catalog ID',
      minWidth: 400,
      maxWidth: 800,
      isResizable: true,
      onRender: (control: BaselineControlGroupResponse) => <Link onClick={() => onControlClick(control.id)}>{control.label}</Link>,
    },
    {
      key: 'description',
      name: 'Catalog description',
      minWidth: 400,
      maxWidth: 800,
      isResizable: true,
      onRender: (control: BaselineControlGroupResponse) => <>{control.title}</>,
    },
  ];

  if (loading === LoadingState.Loading) return <CenteredProgressDots />;
  if (loading === LoadingState.Error) {
    return <MessageBar messageBarType={MessageBarType.error}>{`Failed to load control catalog with id ${controlCatalogId}.`}</MessageBar>;
  }

  return (
    <div className={pageChromeChildStyles}>
      <h1>{controlCatalog?.title}</h1>
      <div>You can select control families from the dropdown to view individual controls.</div>
      <Dropdown
        placeholder="Select a control family"
        label="Control families"
        selectedKey={selectedControlCatalogGroup?.id || undefined}
        options={controlCatalogControlGroupOptions}
        styles={dropdownStyles}
        onChange={onChangeControlGroupDropdown}
      />
      <HorizontalRule />
      {isControlListLoading ? (
        <CenteredProgressDots />
      ) : (
        selectedControlCatalogGroup && <DetailsList items={selectedControlCatalogGroup.controls || []} columns={controlCatalogDetailColumns} />
      )}
      {controlPanelDisplay}
    </div>
  );
};
