import { mergeStyles } from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DOD_AUTHORIZATION_ACTIONS_WRITE, SITE_WIDE_SUBJECT } from 'modules/constants';
import { AuthContext } from 'components/authProvider/authContext';
import { IPendingActionCounts, IPendingActions, PendingActionCounts } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { ActionCenterContext } from '../actionCenterProvider/actionCenterContext';
import { ActionCenterData } from '../actionCenterProvider/actionCenterProvider';
import { DonutChart } from '../charts/donutChart';
import { ChartLegend } from '../charts/legend';
import { CenteredProgressDots } from '../progressDots/progressDots';

const widgetStyle = mergeStyles({
  border: '1.5px solid #eee',
  backgroundColor: 'white',
  padding: '1em',
  marginTop: '1em',
  marginBottom: '1em',
  maxWidth: '30em',
  display: 'flex',
  flexDirection: 'column',
});

const headerStyle = mergeStyles({
  alignItems: 'flex-start',
  display: 'flex',
});

const donutWrapperStyle = mergeStyles({
  margin: '0 auto',
});

const labelStyle = mergeStyles({
  padding: '0 1em',
  fontSize: '1em',
  height: 'fit-content',
  fontWeight: 'bold',
});

interface DonutChartData {
  label: string;
  count: number;
}

const chartColors = ['#228286', '#F7894A'];
export interface PendingActionsKey {
  label: string;
  property: string;
}

export interface PendingActionsWidgetProps {
  userRoleKey: string;
  chartKeys: PendingActionsKey[];
  userRoleTitle?: string;
}

export const PendingActionsWidget: React.FunctionComponent<PendingActionsWidgetProps> = (props) => {
  const { userRoleKey, chartKeys, userRoleTitle } = props;
  const actionCenterContext = useContext(ActionCenterContext);
  const authContext = useContext(AuthContext);
  const [donutChartData, setDonutChartData] = useState<DonutChartData[]>([]);
  const [pendingActions, setPendingActions] = useState<PendingActionCounts | undefined>();
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const isDodOfficial = authContext.isAuthorized([{ operation: DOD_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    actionCenterContext.requestContextData(ActionCenterData.PendingActions);
  }, [actionCenterContext]);

  useEffect(() => {
    setIsLoading(actionCenterContext.pendingActions.loadingState);
  }, [actionCenterContext.pendingActions.loadingState]);

  useEffect(() => {
    if (!actionCenterContext.pendingActions.contextData) {
      return;
    }

    setPendingActions(actionCenterContext.pendingActions.contextData[userRoleKey as keyof IPendingActions]);
  }, [userRoleKey, actionCenterContext.pendingActions.contextData]);

  const formatDonutChartData = useCallback(
    (pendingActions: PendingActionCounts | undefined): DonutChartData[] => {
      const data: DonutChartData[] = [];
      const pendingDocumentUploadDodCount = pendingActions?.pendingDocumentUploadDodCount || 0;
      const pendingEvidencePackageReviewDodCount = pendingActions?.pendingEvidencePackageReviewDodCount || 0;

      if (!pendingActions) {
        return data;
      }

      chartKeys.forEach((key) => {
        let countAction = pendingActions[key.property as keyof IPendingActionCounts];
        if (!isDodOfficial && key.property === 'pendingDocumentUploadCount') {
          countAction -= pendingDocumentUploadDodCount;
        } else if (!isDodOfficial && key.property === 'pendingEvidencePackageReviewCount') {
          countAction -= pendingEvidencePackageReviewDodCount;
        }
        data.push({
          label: key.label,
          count: countAction,
        });
      });

      return data;
    },
    [chartKeys, isDodOfficial],
  );

  useEffect(() => setDonutChartData(formatDonutChartData(pendingActions)), [formatDonutChartData, pendingActions]);

  return (
    <div className={widgetStyle}>
      <span className={labelStyle}>{userRoleTitle} pending actions</span>

      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <div className={donutWrapperStyle}>
          <DonutChart data={donutChartData} props={{ colors: chartColors, subText: 'PENDING ACTIONS' }} />
        </div>
      )}
      <div className={headerStyle}>
        <ChartLegend props={{ colors: chartColors, keys: chartKeys.flatMap((key) => key.label), width: 150 }} />
      </div>
    </div>
  );
};
