import { useId } from '@uifabric/react-hooks';
import {
  Callout,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  IButtonStyles,
  IDetailsRowStyles,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
  Selection,
  SelectionMode,
  Stack,
  TextField,
  Icon,
} from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

export interface Filter {
  operator: FilterOperator;
  values: string[];
}

export enum FilterOperator {
  Equals = '==',
  NotEquals = '!=',
}

const headerStyle = mergeStyles({
  fontSize: '18px',
  margin: '0em',
  marginBottom: '0.5em',
});

const newUiButtonStyle: IButtonStyles = {};

const buttonStyle: IButtonStyles = {
  root: {
    backgroundColor: '#e6f2fb',
    borderRadius: '15px',
    color: 'black',
    height: '24px',
    marginRight: '10px',
    fontWeight: 400,
    border: 'none',
  },
  rootHovered: {
    backgroundColor: '#cce4f6',
    border: 'none',
  },
  rootFocused: {
    backgroundColor: '#b3d7f2',
    borderColor: '#015cda',
  },
  rootPressed: {
    backgroundColor: '#b3d7f2',
    borderColor: '#b3d7f2',
  },
  rootChecked: {
    backgroundColor: '#b3d7f2',
    border: 'none',
  },
  rootCheckedPressed: {
    backgroundColor: '#b3d7f2',
    borderColor: '#015cda',
  },
  rootCheckedHovered: {
    backgroundColor: '#b3d7f2',
    color: 'black',
    border: 'none',
  },
};

const calloutStyles = mergeStyleSets({
  callout: {
    width: 400,
    maxWidth: '90%',
    padding: '20px 24px',
    overflow: 'hidden',
  },
});

const bottomButtonStyles = mergeStyles({
  paddingTop: '15px',
});

const stackStyles = mergeStyles({
  height: '100%',
});

const applyButtonStyles = mergeStyles({
  marginRight: '8px',
  height: '24px',
});

// For the outline to display correctly
const choiceGroupStyle = mergeStyles({
  marginLeft: '5px',
  paddingLeft: '5px',
  overflowY: 'auto',
  overflowX: 'hidden',
});

const cancelButton = mergeStyles({
  height: '24px',
});

const dropdownButtonTextAreaStyles = mergeStyles({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

const detailsListItemStyles: Partial<IDetailsRowStyles> = {
  root: { borderBottomColor: 'blue' },
  cell: { height: '1500px' },
};

const searchTextAreaStyle = mergeStyles({
  paddingTop: '0.2em',
  paddingBottom: '0.5em',
});

export interface FilterBubbleProps {
  id: string;
  fieldName: string;
  filter?: string[];
  valueOptions: string[];
  includeNone?: boolean;
  noneDisplay?: string;
  onChange: (newValue: any, id: string) => void; // HACK: newValue was string[] for mtac, but DeepReadonly<Filter> in stratum
  disableSearch?: boolean;
  selectionMode?: SelectionMode;
  filterDefaultSelection?: string[];
  isNewUi?: boolean;
  buttonLabelText?: JSX.Element;
  isDisabled?: boolean;
}

interface SearchFilter {
  name: string;
  value: string;
}

const minimumSearchCount = 10;

// Note: This will change with ux updates. Once ux updates are completed this should be renamed.
export const FilterBubble: FunctionComponent<FilterBubbleProps> = (props) => {
  const { id, fieldName, valueOptions, includeNone, noneDisplay, onChange, disableSearch, selectionMode, isNewUi, buttonLabelText, isDisabled } =
    props;

  const filter = useMemo(() => props.filter || [], [props.filter]);
  const buttonId = useId('filter-button');
  const [distinctValueOptions, setDistinctValueOptions] = useState<string[]>([]);
  const [valueItems, setValueItems] = useState<{ key: string }[]>([]);
  const [allValueItems, setAllValueItems] = useState<{ key: string }[]>([]);
  const [selectedDisplay, setSelectedDisplay] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const [directionalHint] = useState<DirectionalHint>(DirectionalHint.bottomLeftEdge);
  const [internalFilter, setInternalFilter] = useState<string[]>(filter);
  const [searchFilter, setSearchFilter] = useState<SearchFilter[]>([]);

  useEffect(() => {
    const valueOptionsSet = new Set<string>();
    valueOptions.forEach((option) => valueOptionsSet.add(option));
    const sortedOptions = Array.from(valueOptionsSet).sort();

    if (includeNone) {
      return setDistinctValueOptions([noneDisplay || 'None'].concat(sortedOptions));
    }

    return setDistinctValueOptions(sortedOptions);
  }, [valueOptions, includeNone, noneDisplay]);

  useEffect(() => {
    setValueItems(distinctValueOptions.map((value) => ({ key: value })));
  }, [distinctValueOptions]);

  useEffect(() => {
    if (!filter || filter.length === 0) {
      setSelectedDisplay(isNewUi ? 'Select an option' : 'None Selected');
      return;
    }
    if (filter.length === distinctValueOptions.length) {
      setSelectedDisplay('all');
      return;
    }

    if (filter.length === 1) {
      setSelectedDisplay(filter[0]);
      return;
    }

    setSelectedDisplay(`${filter.length} of ${distinctValueOptions.length} selected`);
  }, [filter, distinctValueOptions.length, isNewUi]);

  const columns = [
    {
      key: 'column1',
      name: 'All',
      fieldName: 'key',
      minWidth: 100,
      maxWidth: 200,
      isResizable: false,
    },
  ];

  const handleBubbleClick = useCallback(() => {
    setIsOpen((isOpen) => !isOpen);
  }, []);

  const dismissCallout = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    setInternalFilter(filter);
  }, [filter]);

  const selection = useMemo(() => {
    let selectionReady = false;
    const newSelection = new Selection({
      onSelectionChanged: () => {
        if (selectionReady) {
          setInternalFilter(() => newSelection.getSelection().map((item) => String(item?.key ?? '')));
        }
      },
    });
    newSelection.setItems(valueItems);
    internalFilter.forEach((value: string) => newSelection.setKeySelected(value, true, false));
    selectionReady = true;
    return newSelection;
  }, [valueItems, internalFilter]);

  const onCancel = () => {
    dismissCallout();
    setInternalFilter(filter);
  };

  const onApply = () => {
    dismissCallout();
    onChange(internalFilter, id);
  };

  const onRenderHeader = () => <></>;

  const onSearchChange = useCallback(
    (input: string | undefined, fieldName: string) => {
      if (input) {
        if (allValueItems.length === 0) {
          setAllValueItems(valueItems);
        }
        const filteredItems = allValueItems.filter((item) => item.key.toUpperCase().includes(input.toUpperCase()));
        const copiedSearchFilters = [...searchFilter];
        const currentFilterValue = copiedSearchFilters.find((filter) => filter.name === fieldName);
        if (!currentFilterValue) {
          copiedSearchFilters.push({ name: fieldName, value: input });
        } else {
          currentFilterValue.value = input;
        }
        setValueItems(filteredItems);
        setSearchFilter(copiedSearchFilters);
      }
      return input;
    },
    [allValueItems, searchFilter, valueItems],
  );

  const searchInput = useCallback(
    (fieldName: string) => {
      if (!disableSearch && (valueItems.length > minimumSearchCount || allValueItems.length > 0)) {
        return (
          <TextField
            className={searchTextAreaStyle}
            placeholder={`Search ${fieldName}'s`}
            onChange={(event, input) => onSearchChange(input, fieldName)}
          />
        );
      }
      return <></>;
    },
    [allValueItems.length, disableSearch, onSearchChange, valueItems.length],
  );

  return (
    <>
      {isNewUi ? (
        <Stack verticalAlign="space-between">
          <label hidden={buttonLabelText === undefined} htmlFor="uiButton">
            {buttonLabelText}
          </label>
          <DefaultButton id="uiButton" onClick={handleBubbleClick} disabled={isDisabled}>
            <div className={dropdownButtonTextAreaStyles}>
              <span>{selectedDisplay}</span>
              <Icon iconName="ChevronDown" />
            </div>
          </DefaultButton>
        </Stack>
      ) : (
        <DefaultButton toggle checked={isOpen} id={buttonId} styles={isNewUi ? newUiButtonStyle : buttonStyle} onClick={handleBubbleClick}>
          {`${fieldName}: `}
          &nbsp;
          <span style={{ fontWeight: 600 }}>{selectedDisplay}</span>
        </DefaultButton>
      )}
      {isOpen && (
        <Callout
          className={calloutStyles.callout}
          target={`#${buttonId}`}
          gapSpace={0}
          onDismiss={dismissCallout}
          directionalHint={directionalHint}
          setInitialFocus
        >
          <Stack verticalAlign="space-between" className={stackStyles}>
            <h3 className={headerStyle}>{fieldName}</h3>
            {searchInput(fieldName)}
            <div className={choiceGroupStyle}>
              <DetailsList
                styles={detailsListItemStyles}
                items={valueItems}
                columns={columns}
                onRenderDetailsHeader={onRenderHeader}
                isHeaderVisible
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selection={selection}
                selectionMode={selectionMode === undefined ? SelectionMode.single : selectionMode}
                selectionPreservedOnEmptyClick
                ariaLabelForSelectionColumn="Toggle inclusion in filter"
                ariaLabelForSelectAllCheckbox="Toggle selection of all filter items"
                checkButtonAriaLabel="select row"
                compact
              />
            </div>
            <Stack horizontal className={bottomButtonStyles}>
              <PrimaryButton text="Apply" className={applyButtonStyles} onClick={onApply} />
              <DefaultButton text="Cancel" className={cancelButton} onClick={onCancel} />
            </Stack>
          </Stack>
        </Callout>
      )}
    </>
  );
};
