const kiloByte = 1024;
export const megaByte = kiloByte * 1024;

export const generateFileSizeDisplay = (fileSize: number): string => {
  let fileSizeDisplay = '';

  if (fileSize < 0) {
    fileSizeDisplay = '0 bytes';
  } else if (fileSize < kiloByte) {
    fileSizeDisplay = `${fileSize} bytes`;
  } else if (fileSize < megaByte) {
    fileSizeDisplay = `${(fileSize / kiloByte).toFixed(0)} KB`;
  } else {
    const convertedSize = fileSize / megaByte;
    const fixedAmount = convertedSize % 1 === 0 ? 0 : 1;
    fileSizeDisplay = `${convertedSize.toFixed(fixedAmount)} MB`;
  }

  return fileSizeDisplay;
};
