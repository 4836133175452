import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { updatePageChromeConfig } from 'modules/pageChrome/pageChrome';
import { createSspManagerChromeConfig } from 'modules/pageChrome/sspManagerPageChrome';
import { SspManagerNavLinks } from 'models/sspManagerNavLinks';
import { ComplianceSelfTestListResponse } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { getComplianceSelfTestList } from 'modules/reports/reports';
import { logError } from 'modules/logging/logging';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { UserContext } from 'components/userProvider/userContext';
import { HeroContentContext } from 'components/heroContentProvider/heroContentContext';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { ComplianceSelfTestReport } from './complianceSelfTestReport';
import { CustomerFacingReport } from './customerFacingReport';

enum ReportOptions {
  ComplianceSelfTest = 'complianceSelfTest',
  CustomerFacing = 'customerFacing',
}

const reportOptions: IChoiceGroupOption[] = [
  { key: ReportOptions.ComplianceSelfTest, text: 'Generate Compliance self test metadata' },
  { key: ReportOptions.CustomerFacing, text: 'Generate customer facing control report' },
];

export const Report: FunctionComponent = () => {
  const defaultSelectedKey = reportOptions[0].key;
  const heroContentContext = useContext(HeroContentContext);
  const userContext = useContext(UserContext);
  const [complianceSelfTestList, setComplianceSelfTestList] = useState<ComplianceSelfTestListResponse>();
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [selectedReport, setSelectedReport] = useState<string>(reportOptions[0].key);

  useEffect(() => {
    if (userContext.organizations.length > 0) {
      heroContentContext.setShowHero(false);
      heroContentContext.setShowOrganizationDropdown(true);
    }
  }, [heroContentContext, userContext.organizations]);

  useEffect(() => {
    updatePageChromeConfig(createSspManagerChromeConfig(SspManagerNavLinks.Reports));
    return () => updatePageChromeConfig();
  }, []);

  useEffect(() => {
    const getComplianceList = async () => {
      try {
        const complianceSelfTestList = await getComplianceSelfTestList();
        setComplianceSelfTestList(complianceSelfTestList);
        setIsLoading(LoadingState.Loaded);
      } catch (error) {
        setIsLoading(LoadingState.Error);
        logError('There was an issue getting the compliance self test list', error);
      }
    };

    getComplianceList();
  }, []);

  const handleReportChange = (_ev: FormEvent<HTMLElement | HTMLInputElement> | undefined, selectedOption: IChoiceGroupOption | undefined) => {
    if (!selectedOption || !selectedOption.text) {
      return;
    }
    setSelectedReport(selectedOption.key);
  };

  return (
    <div className={pageChromeChildStyles}>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          <h2>Reports</h2>
          <ChoiceGroup options={reportOptions} defaultSelectedKey={defaultSelectedKey} onChange={handleReportChange} />
          {selectedReport === ReportOptions.ComplianceSelfTest ? (
            <ComplianceSelfTestReport complianceSelfTestList={complianceSelfTestList} />
          ) : (
            <CustomerFacingReport />
          )}
        </>
      )}
    </div>
  );
};
