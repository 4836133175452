import { Icon, Link, mergeStyles, PanelType } from '@fluentui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AuthContext } from 'components/authProvider/authContext';
import { SITE_WIDE_SUBJECT, DOD_AUTHORIZATION_ACTIONS_WRITE } from 'modules/constants';
import { dodExclusivePackages } from 'models/dodExclusivePackages';
import { InformationPane } from '../../components/informationPane/informationPane';
import { EvidencePackagesCurrentListResponse } from '../../generated/clientApi';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { evidenceDetailRoute } from '../../modules/routes/routes';
import { BasicList, ListColumn } from '../basicList/basicList';
import { Domains } from '../../models/domainConstants';
import { LoadingState } from '../../models/loadingState';
import { CenteredProgressDots } from '../progressDots/progressDots';
import { ActionCenterContext } from '../actionCenterProvider/actionCenterContext';
import { ActionCenterData } from '../actionCenterProvider/actionCenterProvider';

const widgetStyle = mergeStyles({
  border: '1.5px solid #eee',
  backgroundColor: 'white',
  padding: '1em',
  marginTop: '1em',
  marginBottom: '1em',
  marginLeft: '1em',
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  minWidth: '45em', // otherwise there is a horizontal overflow
  '@media (max-width: 320px)': {
    overflowX: 'scroll',
  },
});

const tableStyle = mergeStyles({
  overflow: 'hidden',
});

const columnStyle = mergeStyles({
  display: 'flex',
});

const linkStyle = {
  textDecoration: 'none',
};

const iconBlueStyle = mergeStyles({
  margin: 'auto 0',
  color: '#0078d4',
  cursor: 'pointer',
});

const iconGreyStyle = mergeStyles({
  margin: 'auto 0',
  color: 'grey',
  cursor: 'pointer',
});

const labelStyle = mergeStyles({
  margin: 'auto 0.5em',
});

const informationPaneLinkStyle = mergeStyles({
  margin: '0 1em',
});

export interface StatusMapping {
  inProgress: EvidencePackageStatus;
  notStarted: EvidencePackageStatus;
  alternateStatus?: EvidencePackageStatus;
}

export interface ActionItem {
  id: string;
  serviceId: string;
  serviceName: string;
  cloud: string;
  certification: string;
  evidencePackageId: string;
  evidencePackageStatus: EvidencePackageStatus;
  submittedDate: Date;
}

export interface ActionItemWidgetProps {
  statusMapping: StatusMapping;
  userRoleTitle: string;
}

export const ActionItemWidget: React.FunctionComponent<ActionItemWidgetProps> = (props) => {
  const actionCenterContext = useContext(ActionCenterContext);
  const authContext = useContext(AuthContext);
  const { userRoleTitle, statusMapping } = props;
  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const maxNumberPreviewRows = 5;
  const isDodOfficial = authContext.isAuthorized([{ operation: DOD_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    actionCenterContext.requestContextData(ActionCenterData.CurrentEvidencePackages);
  }, [actionCenterContext]);

  useEffect(() => {
    setIsLoading(actionCenterContext.currentEvidencePackages.loadingState);
  }, [actionCenterContext.currentEvidencePackages.loadingState]);

  useEffect(() => {
    if (!actionCenterContext.currentEvidencePackages.contextData) {
      return;
    }

    const actionList: ActionItem[] = [];
    actionCenterContext.currentEvidencePackages.contextData.forEach((serviceResponse: EvidencePackagesCurrentListResponse) => {
      const { service } = serviceResponse;
      serviceResponse.currentEvidencePackages.forEach((evidencePackage) => {
        // do not show any packages that are not in progress or not started
        if (
          evidencePackage.status !== statusMapping.inProgress &&
          evidencePackage.status !== statusMapping.notStarted &&
          (statusMapping.alternateStatus ? evidencePackage.status !== statusMapping.alternateStatus : true)
        ) {
          return;
        }

        if (
          (evidencePackage.certification === dodExclusivePackages.IL4 || evidencePackage.certification === dodExclusivePackages.IL5) &&
          !isDodOfficial
        ) {
          return;
        }

        actionList.push({
          id: evidencePackage.id,
          serviceId: service.id,
          serviceName: service.name,
          cloud: evidencePackage.cloud,
          certification: evidencePackage.certification,
          evidencePackageId: evidencePackage.id,
          evidencePackageStatus: evidencePackage.status as EvidencePackageStatus,
          submittedDate: evidencePackage.submittedDate,
        });
      });
    });

    // sort by status and then by date
    actionList.sort((actionItemA, actionItemB) => {
      if (actionItemA.evidencePackageStatus === statusMapping.inProgress && actionItemB.evidencePackageStatus === statusMapping.inProgress) {
        return actionItemA.submittedDate.getTime() > actionItemB.submittedDate.getTime() ? 1 : -1;
      }
      if (actionItemA.evidencePackageStatus === statusMapping.inProgress) {
        return -1;
      }
      if (actionItemB.evidencePackageStatus === statusMapping.inProgress) {
        return 1;
      }
      return actionItemA.submittedDate.getTime() > actionItemB.submittedDate.getTime() ? 1 : -1;
    });

    setActionItems(actionList);
  }, [
    statusMapping.inProgress,
    statusMapping.notStarted,
    actionCenterContext.currentEvidencePackages.contextData,
    isDodOfficial,
    statusMapping.alternateStatus,
  ]);

  const inProgress = useMemo(
    () => (
      <div className={columnStyle}>
        <Icon iconName="SyncStatusSolid" className={iconBlueStyle} title="In Progress" />
        <span className={labelStyle}>In progress</span>
      </div>
    ),
    [],
  );

  const notStarted = useMemo(
    () => (
      <div className={columnStyle}>
        <Icon iconName="CircleFill" className={iconGreyStyle} title="Not Started" />
        <span className={labelStyle}>Not started</span>
      </div>
    ),
    [],
  );

  const columns: ListColumn[] = useMemo(
    () => [
      {
        key: 'serviceName',
        name: 'Service name',
        onRender: (actionItem: ActionItem) => (
          <div className={columnStyle}>
            <Link
              as={RouterLink}
              to={evidenceDetailRoute({
                serviceOid: actionItem.serviceId,
                evidencePackageId: actionItem.evidencePackageId,
                navMenuTab: Domains.SERVICE_TREE_PROPERTIES,
              })}
              style={linkStyle}
            >
              {actionItem.serviceName}
            </Link>
          </div>
        ),
        minWidth: 150,
      },
      {
        key: 'cloud',
        name: 'Cloud',
        fieldName: 'cloud',
        minWidth: 100,
      },
      {
        key: 'certification',
        name: 'Certification',
        fieldName: 'certification',
        minWidth: 100,
      },
      {
        key: 'status',
        name: 'Status',
        onRender: (actionItem: ActionItem) => (
          <div>
            {actionItem.evidencePackageStatus === statusMapping.inProgress && inProgress}
            {actionItem.evidencePackageStatus === statusMapping.notStarted && notStarted}
            {actionItem.evidencePackageStatus === statusMapping.alternateStatus && notStarted}
          </div>
        ),
        minWidth: 150,
      },
    ],
    [statusMapping.inProgress, statusMapping.notStarted, statusMapping.alternateStatus, inProgress, notStarted],
  );

  return (
    <div className={widgetStyle}>
      <div style={{ fontWeight: 'bold' }}>
        All&nbsp;
        {userRoleTitle}
        action items
      </div>
      {/* Note: Tabs/pivot item buttons do not show unless there is more than 1 - not adding until needed */}
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <div className={tableStyle}>
          <BasicList items={actionItems.slice(0, maxNumberPreviewRows)} columns={columns} />
        </div>
      )}
      <div className={informationPaneLinkStyle}>
        <InformationPane text="View all services" isPanelBlocking={false} panelHeader="All Services" panelType={PanelType.large}>
          <BasicList items={actionItems} columns={columns} />
        </InformationPane>
      </div>
    </div>
  );
};
