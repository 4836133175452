import { IColumn, IDetailsColumnProps, IDetailsHeaderProps, IRenderFunction, Icon, Sticky, StickyPositionType } from '@fluentui/react';
import React from 'react';

export const renderFixedDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
  if (!props) {
    return null;
  }

  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
      {defaultRender!({
        ...props,
      })}
    </Sticky>
  );
};

export function sortByProperty<Type>(objects: Type[], key: string, order: 'asc' | 'desc', isRiskRatingSort = false): Type[] {
  return objects.sort((a, b) => {
    const keys = key.split('.');
    const valueA = getNestedObjectValue(a, keys);
    const valueB = getNestedObjectValue(b, keys);

    let comparison = 0;
    if (isRiskRatingSort && typeof valueA === 'string' && typeof valueB === 'string') {
      // Custom sorting for risk ratings
      const ratingOrder = ['Low', 'Moderate', 'High'];
      const partialMatchA = ratingOrder.find((order) => valueA.startsWith(order));
      const partialMatchB = ratingOrder.find((order) => valueB.startsWith(order));
      if (partialMatchA && partialMatchA === partialMatchB) {
        // Same rating, sort numerically
        const numericValueA = parseNumericValueFromRiskRating(valueA);
        const numericValueB = parseNumericValueFromRiskRating(valueB);
        comparison = numericValueA - numericValueB;
      } else if (partialMatchA !== partialMatchB) {
        // Different rating, use risk rating for ordering.
        comparison = ratingOrder.indexOf(partialMatchA ?? '') - ratingOrder.indexOf(partialMatchB ?? '');
      }
    } else if (valueA === valueB || (valueA == null && valueB == null)) {
      comparison = 0;
    } else if (valueA == null || valueA < valueB) {
      comparison = -1;
    } else {
      comparison = 1;
    }

    return order === 'desc' ? -comparison : comparison;
  });
}

const getNestedObjectValue = (obj: any, keys: string[]): any => keys.reduce((acc, key) => acc?.[key], obj);

/** Returns true if risk rating has a lower risk level than other risk rating.
 * Risk rating order is Low < Moderate < High.
 */
export const riskRatingIsLower = (riskRating: string, otherRiskRating?: string): boolean => {
  if (!otherRiskRating) {
    return true;
  }
  const ratingOrder = ['Low', 'Moderate', 'High'];
  const partialMatchA = ratingOrder.find((order) => riskRating.startsWith(order));
  const partialMatchB = ratingOrder.find((order) => otherRiskRating.startsWith(order));
  return ratingOrder.indexOf(partialMatchA ?? '') < ratingOrder.indexOf(partialMatchB ?? '');
};

/** Extract the numeric portion from the risk string (e.g., "9.8" from "High (9.8)"). */
const parseNumericValueFromRiskRating = (value: string): number => {
  const numericPart = value.match(/\d+(\.\d+)?/);
  return numericPart ? parseFloat(numericPart[0]) : 0;
};

const onRenderHeader = (props: IDetailsColumnProps | undefined): JSX.Element => {
  // Customize the rendering of the column header
  const column = props?.column;

  if (!column) {
    return <></>;
  }

  if (column?.isSorted || column?.isSorted === undefined) {
    return (
      <div>
        <span>{column.name}</span>
      </div>
    );
  }

  return (
    <div>
      <span>{column.name}</span>
      <Icon className="ms-Icon" iconName="Sort" style={{ paddingLeft: 4 }} />
    </div>
  );
};

export const handleTableSort = (
  column: IColumn,
  setColumns: (value: React.SetStateAction<IColumn[]>) => void,
  sortTable: (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc', isRiskRatingSort: boolean) => void,
  isRiskRatingSort = false,
): void => {
  setColumns((prevColumns) => {
    const { key: clickedColumnKey } = column;
    const newColumns = prevColumns.map((col: IColumn) => {
      if (col.key === clickedColumnKey) {
        const isSortedDescending = !col.isSortedDescending;
        sortTable(column, isSortedDescending ? 'desc' : 'asc', isRiskRatingSort);
        return {
          ...col,
          isSorted: true,
          isSortedDescending,
        };
      }

      return {
        ...col,
        isSorted: false,
        isSortedDescending: false,
      };
    });

    return newColumns;
  });
};

export const handleServerSideTableSort = (
  column: IColumn,
  sortTable: (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc') => void,
  setColumns: (value: React.SetStateAction<IColumn[]>) => void,
): void => {
  sortTable(column, column.isSortedDescending ? 'desc' : 'asc');
  setColumns((prevColumns) => {
    const { key: clickedColumnKey } = column;
    const newColumns = prevColumns.map((col: IColumn) => {
      if (col.key === clickedColumnKey) {
        const isSortedDescending = !col.isSortedDescending;
        return {
          ...col,
          isSorted: true,
          isSortedDescending,
        };
      }

      return {
        ...col,
        isSorted: false,
        isSortedDescending: false,
      };
    });

    return newColumns;
  });
};

export const baseSortableColumn = { isSorted: false, isSortedDescending: false, onRenderHeader };
