import { IStackTokens, SharedColors, Stack, mergeStyles } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { IDataPoint, DonutChart, IChartProps } from '@fluentui/react-charting';
import { ControlMetadataListResponse } from '../../generated/clientApi';
import { CustomLegend } from '../../components/charts/customLegend';
import { getMetadataStatistic } from './controlMetadataStatistics.functions';

const horizontalStackToken: IStackTokens = {
  childrenGap: 5,
};

const chartStyles = mergeStyles({
  margin: '1em 0',
  padding: '0 1em 1em',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
});

const chartTitleStyles = mergeStyles({
  textAlign: 'center',
  fontWeight: 'bold',
  margin: '1em 0',
});

interface ControlMetadataStatisticsProps {
  controlMetadata: ControlMetadataListResponse[];
  controlIds: string[];
}

export interface MetadataDataPoint {
  dataWithoutMetadata: IDataPoint;
  dataWithMetadata: IDataPoint;
}

const donutChartDimension = 350; // React DonutChart width and height props in pixels.
const donutChartInnerRadius = 75; // React DonutChart inner radius props in pixels.

const getLegend = (controlsWithMetadataCount: number, controlsWithoutMetadataCount: number) => [
  {
    title: 'Controls',
    subtitle: 'with metadata',
    count: controlsWithMetadataCount,
    color: '#038387',
  },
  {
    title: 'Controls',
    subtitle: 'without metadata',
    count: controlsWithoutMetadataCount,
    color: '#98D9DB',
  },
];

export const ControlMetaDataStatistics: FunctionComponent<ControlMetadataStatisticsProps> = (props) => {
  const { controlMetadata, controlIds } = props;
  const [controlMetadataMap, setControlMetadataMap] = useState<Map<string, ControlMetadataListResponse>>(
    new Map<string, ControlMetadataListResponse>(),
  );

  useEffect(() => {
    setControlMetadataMap(new Map(controlMetadata.map((metadata) => [metadata.controlId, metadata] as [string, ControlMetadataListResponse])));
  }, [controlMetadata]);

  const chartsStatistics = [
    {
      key: 'internalServiceTeamChart',
      chartTitle: 'Internal Service Teams',
      data: getMetadataStatistic(
        controlMetadataMap,
        controlIds,
        (metadata: ControlMetadataListResponse | undefined) => !!metadata && !!metadata.internalServiceTeamNames,
      ),
    },
    {
      key: 'complianceSelfTestChart',
      chartTitle: 'Compliance Self Test',
      data: getMetadataStatistic(
        controlMetadataMap,
        controlIds,
        (metadata: ControlMetadataListResponse | undefined) => !!metadata && !!metadata.complianceSelfTestDocuments,
      ),
    },
    {
      key: 'standardOperatingProcedureChart',
      chartTitle: 'Standard Operating Procedures',
      data: getMetadataStatistic(
        controlMetadataMap,
        controlIds,
        (metadata: ControlMetadataListResponse | undefined) => !!metadata && !!metadata.standardOperatingProcedureNames,
      ),
    },
    {
      key: 'policiesChart',
      chartTitle: 'Policies',
      data: getMetadataStatistic(
        controlMetadataMap,
        controlIds,
        (metadata: ControlMetadataListResponse | undefined) => !!metadata && !!metadata.policyIds,
      ),
    },
  ];

  const getData = (withMetadata: IDataPoint, withoutMetadata: IDataPoint): IChartProps => ({
    chartData: [
      { legend: 'Controls with metadata', data: withMetadata.y, color: SharedColors.cyan20 },
      { legend: 'Controls without metadata', data: withoutMetadata.y, color: '#98D9DB' },
    ],
  });

  return (
    <Stack className={chartStyles}>
      <h2>Metadata indicator</h2>
      <Stack horizontal tokens={horizontalStackToken}>
        {controlIds.length ? (
          chartsStatistics.map((statistic) => (
            <Stack verticalAlign="center" key={statistic.key} grow>
              <Stack.Item align="center">
                <div className={chartTitleStyles}>{statistic.chartTitle}</div>
                <Stack horizontal>
                  <DonutChart
                    data={getData(statistic.data.dataWithMetadata, statistic.data.dataWithoutMetadata)}
                    innerRadius={donutChartInnerRadius}
                    width={donutChartDimension}
                    height={donutChartDimension}
                    hideLegend
                  />
                </Stack>
              </Stack.Item>
              <Stack.Item align="center" shrink grow>
                <Stack horizontal grow>
                  <CustomLegend legends={getLegend(statistic.data.dataWithMetadata.y, statistic.data.dataWithoutMetadata.y)} />
                </Stack>
              </Stack.Item>
            </Stack>
          ))
        ) : (
          <span>No controls found, please try a different filter selection.</span>
        )}
      </Stack>
    </Stack>
  );
};
