import { Link, Panel, PanelType } from '@fluentui/react';
import React, { FunctionComponent, useState, useCallback } from 'react';

/**
 * Example Usage

  <InformationPane text="Link" isPanelBlocking={false} panelHeader="Test">
    <div>Hello!</div>
  </InformationPane>

 */

export interface InformationPaneProps {
  text: string;
  isPanelBlocking: boolean;
  panelType?: PanelType;
  panelHeader?: string;
  children?: JSX.Element | JSX.Element[];
}

export const InformationPane: FunctionComponent<InformationPaneProps> = (props: InformationPaneProps) => {
  const { text, panelHeader, panelType, children, isPanelBlocking } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const togglePaneClick = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, [setIsExpanded]);

  const informationPanelDisplay = (
    <Panel
      headerText={panelHeader}
      isOpen={isExpanded}
      isBlocking={isPanelBlocking}
      onDismiss={togglePaneClick}
      closeButtonAriaLabel="Close"
      type={panelType || PanelType.smallFixedFar}
    >
      {children}
    </Panel>
  );

  return (
    <>
      <Link onClick={togglePaneClick}>{text}</Link>
      {isExpanded && informationPanelDisplay}
    </>
  );
};
