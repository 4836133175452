import { INavLink, INavLinkGroup, INavStyles, Nav, SharedColors, mergeStyles } from '@fluentui/react';
import React, { useMemo, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { useMtacIsAuthenticated } from 'hooks/useMtacIsAuthenticated';
import { DeepReadonly } from '../util/deepReadonly';

const navGroupHeaderStyle = mergeStyles({
  margin: '0 20px',
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: '32px',
  borderBottom: '1px solid rgb(204, 204, 204)',
});

const navStyles: Partial<INavStyles> = {
  root: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    overflowY: 'auto',
    fontSize: 0,
    marginTop: '4px',
    marginLeft: '1px',
  },
  link: {
    height: '42px',
    paddingLeft: '24px',
    paddingRight: '16px',
    selectors: {
      '::after': {
        borderWidth: 0,
      },
    },
    '.is-disabled &': {
      cursor: 'default',
    },
  },
  chevronButton: {
    display: 'flex',
    height: '-webkit-fill-available',
  },
  chevronIcon: {
    height: 'inherit',
    margin: 'auto',
    position: 'unset',
  },
  linkText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  navItem: {
    '.is-selected': {
      borderLeft: `3px solid ${SharedColors.cyan20}`,
    },
  },
  groupContent: {
    marginBottom: '8px',
  },
};

const onRenderGroupHeader = (group?: INavLinkGroup): JSX.Element => <>{group && <div className={navGroupHeaderStyle}>{group.name}</div>}</>;

export interface INavMenuProps {
  navLinkGroups: INavLinkGroup[];
  selectedLink: string;
  className?: string;
  icon?: string;
}

export const NavMenu: FunctionComponent<DeepReadonly<INavMenuProps>> = (props) => {
  const history = useHistory();
  const isUserAuthenticated = useMtacIsAuthenticated();

  const { navLinkGroups, selectedLink, className } = props;

  const handleLinkClick = (event: any, navLink: INavLink | undefined) => {
    event.preventDefault();
    history.push(navLink?.url || '/');
  };

  const authFilteredNavLinkGroups = useMemo(
    () =>
      navLinkGroups
        .map(
          (group) =>
            ({
              name: group.name,
              links: group.links,
              automationId: group.automationId,
              collapseByDefault: group.collapseByDefault,
              onHeaderClick: group.onHeaderClick,
            }) as INavLinkGroup,
        )
        .filter((group) => group.links.length > 0),
    [navLinkGroups],
  );

  return (
    <>
      {isUserAuthenticated && (
        <Nav
          className={className}
          selectedKey={selectedLink}
          ariaLabel="Navigation Menu"
          styles={navStyles}
          groups={authFilteredNavLinkGroups}
          onLinkClick={handleLinkClick}
          onRenderGroupHeader={onRenderGroupHeader}
        />
      )}
    </>
  );
};
