import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import {
  ActionCenterMetricsGetResponse,
  EvidencePackagesCurrentListResponse,
  IPendingActions,
  UpcomingServicesListResponse,
  WorkloadInsights,
} from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { getActionCenterMetrics, getCurrentEvidencePackages } from '../../modules/evidencePackage/evidencePackage';
import { logError } from '../../modules/logging/logging';
import { getUpcomingServices } from '../../modules/service/service';
import { getPendingActions } from '../../modules/statistics/statistics';
import { getWorkloadInsights } from '../../modules/workloadInsights/workloadInsights';
import { ActionCenterContext, ActionCenterContextProps, ContextDataObject } from './actionCenterContext';

export enum ActionCenterData {
  CurrentEvidencePackages,
  PendingActions,
  WorkloadInsights,
  UpcomingServices,
  ActionCenterMetrics,
}

export const ActionCenterProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [currentEvidencePackagesData, setCurrentEvidencePackagesData] = useState<ContextDataObject<EvidencePackagesCurrentListResponse[]>>({
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  });
  const [pendingActionsData, setPendingActionsData] = useState<ContextDataObject<IPendingActions>>({
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  });
  const [workloadInsightsData, setWorkloadInsightsData] = useState<ContextDataObject<WorkloadInsights[]>>({
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  });
  const [upcomingServicesData, setUpcomingServicesData] = useState<ContextDataObject<UpcomingServicesListResponse[]>>({
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  });
  const [actionCenterMetricsData, setActionCenterMetricsData] = useState<ContextDataObject<ActionCenterMetricsGetResponse>>({
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  });

  const contextProps: ActionCenterContextProps = {
    currentEvidencePackages: {
      contextData: currentEvidencePackagesData.contextData,
      loadingState: currentEvidencePackagesData.loadingState,
    },
    pendingActions: {
      contextData: pendingActionsData.contextData,
      loadingState: pendingActionsData.loadingState,
    },
    workloadInsights: {
      contextData: workloadInsightsData.contextData,
      loadingState: workloadInsightsData.loadingState,
    },
    upcomingServices: {
      contextData: upcomingServicesData.contextData,
      loadingState: upcomingServicesData.loadingState,
    },
    actionCenterMetrics: {
      contextData: actionCenterMetricsData.contextData,
      loadingState: actionCenterMetricsData.loadingState,
    },
    requestContextData: (key: ActionCenterData) => {
      switch (key) {
        case ActionCenterData.CurrentEvidencePackages:
          requestContextData(currentEvidencePackagesData.loadingState, currentEvidencePackagesData.contextData, setCurrentEvidencePackagesData);
          break;
        case ActionCenterData.PendingActions:
          requestContextData(pendingActionsData.loadingState, pendingActionsData.contextData, setPendingActionsData);
          break;
        case ActionCenterData.WorkloadInsights:
          requestContextData(workloadInsightsData.loadingState, workloadInsightsData.contextData, setWorkloadInsightsData);
          break;
        case ActionCenterData.UpcomingServices:
          requestContextData(upcomingServicesData.loadingState, upcomingServicesData.contextData, setUpcomingServicesData);
          break;
        case ActionCenterData.ActionCenterMetrics:
          requestContextData(actionCenterMetricsData.loadingState, actionCenterMetricsData.contextData, setActionCenterMetricsData);
          break;
        default:
        // error
      }
    },
  };

  const requestContextData = (loadingState: LoadingState, data: any, setMethod: (value: React.SetStateAction<ContextDataObject<any>>) => void) => {
    if (loadingState === LoadingState.NotLoaded || loadingState === LoadingState.Error) {
      setMethod({ contextData: data, loadingState: LoadingState.Requested });
    }
  };

  const getData = async (
    callMethod: () => Promise<any>,
    setMethod: (value: React.SetStateAction<ContextDataObject<any>>) => void,
    errorMessage: string,
  ) => {
    try {
      const data = await callMethod();
      setMethod({
        contextData: data,
        loadingState: LoadingState.Loaded,
      });
    } catch (error) {
      setMethod({
        contextData: undefined,
        loadingState: LoadingState.Error,
      });
      logError(`There was an issue loading the ${errorMessage}`, error);
    }
  };

  // Auto grab the current evidence packages if someone requests them
  useEffect(() => {
    if (currentEvidencePackagesData.loadingState === LoadingState.Requested && currentEvidencePackagesData.contextData === undefined) {
      getData(getCurrentEvidencePackages, setCurrentEvidencePackagesData, 'current evidence packages');
    }
  }, [currentEvidencePackagesData.loadingState, currentEvidencePackagesData.contextData]);

  // Auto grab the pending actions if someone requests them
  useEffect(() => {
    if (pendingActionsData.loadingState === LoadingState.Requested && pendingActionsData.contextData === undefined) {
      getData(getPendingActions, setPendingActionsData, 'pending actions');
    }
  }, [pendingActionsData.loadingState, pendingActionsData.contextData]);

  // Auto grab the workload insights if someone requests them
  useEffect(() => {
    if (workloadInsightsData.loadingState === LoadingState.Requested && workloadInsightsData.contextData === undefined) {
      getData(getWorkloadInsights, setWorkloadInsightsData, 'workload insights');
    }
  }, [workloadInsightsData.loadingState, workloadInsightsData.contextData]);

  // Auto grab the upcoming services if someone requests them
  useEffect(() => {
    if (upcomingServicesData.loadingState === LoadingState.Requested && upcomingServicesData.contextData === undefined) {
      getData(getUpcomingServices, setUpcomingServicesData, 'upcoming services');
    }
  }, [upcomingServicesData.loadingState, upcomingServicesData.contextData]);

  // Auto grab the metrics data if someone requests them
  useEffect(() => {
    if (actionCenterMetricsData.loadingState === LoadingState.Requested && actionCenterMetricsData.contextData === undefined) {
      getData(getActionCenterMetrics, setActionCenterMetricsData, 'action center metrics');
    }
  }, [actionCenterMetricsData.loadingState, actionCenterMetricsData.contextData]);

  return <ActionCenterContext.Provider value={contextProps}>{children}</ActionCenterContext.Provider>;
};
