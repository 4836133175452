import { mergeStyles, TextField } from '@fluentui/react';
import { ReviewComments, ReviewCommentType } from 'components/reviewComments/reviewComments';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FileAttachmentTableMinimal } from 'components/fileAttachment/fileAttachmentTableMinimal';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { textFieldStyles } from 'styles/textFieldStyles';
import { SupplementalData } from 'components/domainEvidenceData/supplementalData';
import { AuthContext } from '../../components/authProvider/authContext';
import { DomainEvidenceData } from '../../components/domainEvidenceData/domainEvidenceData';
import { EvidenceAttachments } from '../../components/evidenceAttachments/evidenceAttachments';
import { Notes } from '../../components/notes/notes';
import {
  DomainEvidenceAttachmentInfo,
  EvidenceComment,
  EvidencePackage,
  EvidenceUpdateType,
  FileAttachment,
  IDomainEvidence,
} from '../../generated/clientApi';
import { domainMap, Domains } from '../../models/domainConstants';
import { COMPLIANCE_TEAM_ACTIONS_READ, EVIDENCE_PACKAGE_NOTES_WRITE, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { updateEvidencePackageNotes } from '../../modules/evidencePackage/evidencePackage';

const tabWrapperStyle = mergeStyles({
  display: 'flex',
  height: '100%',
});

const tabWrapperHiddenStyle = mergeStyles({
  display: 'none',
});

const borderTopStyles = mergeStyles({
  borderTop: '3px solid #eee',
});

const containerStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const domainEvidenceStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: '60% 1',
});

const rightBladeStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  borderLeft: '3px solid #eee',
  paddingLeft: '1em',
});

const bottomPaddingStyles = mergeStyles({
  paddingBottom: '1em',
});

const textFieldStyle = mergeStyles({
  width: '100%',
  '.ms-TextField-fieldGroup': {
    width: '50%',
  },
});

interface NavMenuElement {
  title: string;
  element: JSX.Element;
}

export enum SupplementalInfoFields {
  Description = 'description',
  Endpoint = 'endpoint',
  Narrative = 'narrative',
}

export interface DomainViewerProps {
  selectedTitle: string;
  serviceOid: string;
  validDomains: string[];
  displayMinimalAttachmentsComponent: boolean;
  fileAttachments: FileAttachment[];
  setFileAttachments(fileAttachments: FileAttachment[]): void;
  setIsLoading(isLoading: boolean): void;
}

export const DomainViewer: FunctionComponent<DomainViewerProps> = (props) => {
  const [isSavingNote, setSavingNote] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const evidenceContext = useContext(EvidenceContext);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    setIsReadOnly(!isComplianceTeam);
  }, [isComplianceTeam]);

  const { selectedTitle, serviceOid, displayMinimalAttachmentsComponent, fileAttachments, setFileAttachments } = props;

  const canWriteNotes = authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_NOTES_WRITE, subject: SITE_WIDE_SUBJECT }]);

  const renderContent = (contentElement: NavMenuElement) => {
    const style = contentElement.title === selectedTitle ? tabWrapperStyle : tabWrapperHiddenStyle;
    return (
      <div key={contentElement.title} className={style}>
        {contentElement.element}
      </div>
    );
  };

  const onNoteSave = (input: string) => {
    if (!evidenceContext.evidencePackage) {
      return;
    }

    setSavingNote(true);
    const evidenceComment = new EvidenceComment();
    evidenceComment.comment = input;
    evidenceComment.domain = Domains.PACKAGE_NOTES;
    evidenceComment.createdDate = new Date();

    updateEvidencePackageNotes({
      id: evidenceContext.evidencePackage.id,
      serviceOid: evidenceContext.evidencePackage.serviceOid,
      commentType: EvidenceUpdateType.Notes,
      comment: evidenceComment,
    }).then((evidencePackage: EvidencePackage) => {
      // We don't want to reload here because it causes the entire page to refresh
      evidenceContext.updateEvidencePackage(evidencePackage);
      setSavingNote(false);
    });
  };

  const renderSupplementalInfo = (domainEvidence: IDomainEvidence) => (
    <SupplementalData
      domainName={domainEvidence.name}
      serviceDescriptionElement={
        <TextField
          placeholder="Enter a description"
          value={evidenceContext.evidencePackage?.supplementalInfo?.serviceTreeSupplementalInfo?.description || ''}
          onChange={(ev: any, newValue?: string) =>
            evidenceContext.addSupplementalInfo(Domains.SERVICE_TREE_PROPERTIES, SupplementalInfoFields.Description, newValue)
          }
          className={textFieldStyle}
          styles={textFieldStyles}
        />
      }
      serviceUrlElement={
        <TextField
          placeholder="Enter a url"
          value={evidenceContext.evidencePackage?.supplementalInfo?.serviceTreeSupplementalInfo?.endpoint || ''}
          onChange={(ev: any, newValue?: string) =>
            evidenceContext.addSupplementalInfo(Domains.SERVICE_TREE_PROPERTIES, SupplementalInfoFields.Endpoint, newValue)
          }
          className={textFieldStyle}
          styles={textFieldStyles}
        />
      }
      dataFlowNarrativeElement={
        <TextField
          placeholder="Enter a narrative"
          value={evidenceContext.evidencePackage?.supplementalInfo?.dataFlowSupplementalInfo?.narrative || ''}
          onChange={(ev: any, newValue?: string) =>
            evidenceContext.addSupplementalInfo(Domains.DATA_FLOW_DIAGRAM, SupplementalInfoFields.Narrative, newValue)
          }
          className={textFieldStyle}
          styles={textFieldStyles}
        />
      }
    />
  );

  const renderNotesComponent = (domainEvidence: IDomainEvidence, serviceOid: string): any => {
    const packageNotesText = domainMap.get(Domains.PACKAGE_NOTES) ?? 'Package Notes';
    if (domainEvidence.name === Domains.PACKAGE_NOTES) {
      return (
        <Notes
          comments={evidenceContext.evidencePackage?.evidenceComments?.notes}
          buttonText="Save"
          isSaving={isSavingNote}
          onSave={onNoteSave}
          disabled={!canWriteNotes}
          showNotes
          header={packageNotesText}
        />
      );
    }

    return (
      <DomainEvidenceData
        title={domainMap.get(domainEvidence.name) || ''}
        domainEvidence={domainEvidence}
        serviceName={evidenceContext.evidencePackage?.serviceName || ''}
        serviceOid={serviceOid}
        shouldRenderAttachments
        shouldRenderComments
        evidenceAttachments={
          <EvidenceAttachments
            domainEvidenceAttachmentInfos={domainEvidence.attachmentInfo as DomainEvidenceAttachmentInfo[]}
            serviceOid={serviceOid}
          />
        }
        isReadOnly={isReadOnly}
        supplementalInfo={evidenceContext.evidencePackage?.supplementalInfo}
        supplementalInfoElement={renderSupplementalInfo(domainEvidence)}
      />
    );
  };

  const renderReviewCommentsComponent = (domainEvidence: IDomainEvidence): any => {
    if (domainEvidence.name === Domains.PACKAGE_NOTES) {
      return <></>;
    }
    return (
      <ReviewComments
        reviewCommentType={ReviewCommentType.Domain}
        domainName={domainEvidence.name}
        hideAuditorComments={false}
        hideAuthorizingOfficialComments={false}
        disableAuthorizingOfficialComments={false}
        disableAuditorComments={false}
        onSaved={evidenceContext.updateEvidencePackage}
        showPivot
        hideSave
      />
    );
  };

  const navMenuElements: NavMenuElement[] =
    evidenceContext.evidencePackage?.evidenceDomains?.map((e) => ({
      title: e.name,
      element: renderNotesComponent(e, serviceOid),
    })) || [];

  const commentElements: NavMenuElement[] = [
    ...(evidenceContext.evidencePackage?.evidenceDomains?.map((e) => ({
      title: e.name,
      element: renderReviewCommentsComponent(e),
    })) || []),
  ];

  const renderNonDomainTitle = (title: string) => (!domainMap.has(title) ? <h2>{title}</h2> : '');

  return (
    <div className={containerStyles}>
      <div className={domainEvidenceStyles}>
        {renderNonDomainTitle(selectedTitle)}
        {navMenuElements.map(renderContent)}
      </div>
      <div className={rightBladeStyles}>
        <div className={bottomPaddingStyles} hidden={!displayMinimalAttachmentsComponent}>
          <FileAttachmentTableMinimal fileAttachments={[...fileAttachments]} setFileAttachments={setFileAttachments} />
        </div>
        <div className={borderTopStyles}>{commentElements.map(renderContent)}</div>
      </div>
    </div>
  );
};
