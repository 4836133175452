import * as H from 'history';
import qs from 'query-string';

/**
 * Takes the existing history object and merges in the supplied search parameters.
 * @param history The history object from react-router-dom
 * @param mergeParams The parameters to merge into the history
 * @example //Current URL is '...azure.us/conmon?exclude=true&asset=VM&cloud=public'
 * const history = useHistory();
 * const newParams = { exclude: false, cloud: undefined, search: 'MyServiceTree'};
 * historyParamUpdate(history, newParams);
 * // new URL is '...azure.us/conmon?exclude=false&asset=VM&search=MyServiceTree'
 */
export const historyParamUpdate = (
  history: H.History<H.LocationState>,
  mergeParams: {
    [k: string]: string | number | boolean | undefined;
  },
): void => {
  const params = qs.parse(history.location.search);

  // Create a merged search object, keeping old values that are unchanged.
  const alternateHistory = {
    pathname: history.location.pathname,
    search: qs.stringify({
      ...params,
      ...mergeParams,
    }),
  };
  history.push(alternateHistory);
};
