import { Icon, mergeStyles, PrimaryButton } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { getSeverityColor, getSeverityIcon, Severity } from '../../models/severity';

const rootStyle = mergeStyles({
  padding: '8px 20px',
  display: 'flex',
});

const iconStyle = mergeStyles({
  margin: 'auto 0',
  fontSize: '1.5em',
  color: 'orange',
});

const buttonStyle = mergeStyles({
  margin: 'auto 0',
});

const messageStyle = mergeStyles({
  margin: 'auto 20px',
  flex: 1,
});

export interface IButtonBannerProps {
  severity?: Severity;
  message?: string;
  buttonText?: string;
  buttonDisabled?: boolean;
  removeButton?: boolean;
  onClick?(): void;
}

export const ButtonBanner: FunctionComponent<IButtonBannerProps> = (props) => {
  const { severity, message, buttonText, buttonDisabled, removeButton, onClick } = props;
  const [bannerColor, setBannerColor] = useState<string>('');
  const [bannerIcon, setBannerIcon] = useState<string>('');
  const displayButton = !removeButton;

  useEffect(() => {
    if (severity === undefined) {
      return;
    }

    setBannerColor(getSeverityColor(severity));
    setBannerIcon(getSeverityIcon(severity));
  }, [severity]);

  const handleOnClick = useCallback(() => {
    if (!onClick) {
      throw new Error('There is no callback defined for the button banner');
    }
    onClick();
  }, [onClick]);

  const bannerButton = (
    <PrimaryButton className={buttonStyle} disabled={buttonDisabled} onClick={handleOnClick}>
      {buttonText}
    </PrimaryButton>
  );

  return (
    <div className={rootStyle} style={{ backgroundColor: bannerColor }} data-testid="button-banner">
      <Icon className={iconStyle} iconName={bannerIcon} />
      <div className={messageStyle}>{message}</div>
      {displayButton && bannerButton}
    </div>
  );
};
