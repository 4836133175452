import {
  INotificationSettings,
  NotificationSettingsGetClient,
  NotificationSettingsPutClient,
  NotificationSettingsPutCommand,
} from 'generated/clientApi';
import { getConfig } from '../config/config';

export const getNotificationSettings = async (): Promise<INotificationSettings> => {
  const notificationSettingsGetClient = new NotificationSettingsGetClient(getConfig().apiBaseUri);
  return notificationSettingsGetClient.get();
};

export const putNotificationSettings = async (command: NotificationSettingsPutCommand): Promise<INotificationSettings> => {
  const notificationSettingsPutClient = new NotificationSettingsPutClient(getConfig().apiBaseUri);
  return notificationSettingsPutClient.put(command);
};
