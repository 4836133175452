import { FontIcon, SharedColors, mergeStyles } from '@fluentui/react';
import { DayCoverage } from 'generated/clientApi';
import React, { useMemo } from 'react';

export const systemSelectedBgColor = 'rgb(255 244 212)';
export const userSelectedBgColor = '#EDEBE9';
export const selectedBorder = '1px solid #038387';
export const nonSelectedBorder = '1px solid #E1DFDD';

type DayCoverageCellProps = {
  dayCoverage: DayCoverage;
  isCurrentSelection: boolean;
  isOldUserSelection: boolean;
  isDefaultSystemSelection: boolean;
  showRawNumberAsTooltip: boolean;
};

const DayCoverageCell: React.FunctionComponent<DayCoverageCellProps> = (props) => {
  const { scanAssetCount, inventoryAssetCount, percentage, authenticatedPercentage, vulnerabilityCount } = props.dayCoverage;
  const { isCurrentSelection, isOldUserSelection, isDefaultSystemSelection, showRawNumberAsTooltip } = props;

  const cellBgColor = useMemo(() => {
    if (isDefaultSystemSelection) {
      return systemSelectedBgColor;
    }
    if (isOldUserSelection) {
      return userSelectedBgColor;
    }
    return 'inherit';
  }, [isDefaultSystemSelection, isOldUserSelection]);

  const cellBoxStyles = mergeStyles({
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '90px',
    height: '45px',
    textAlign: 'center',
    color: percentage! < 90 ? SharedColors.redOrange10 : 'inherit',
    backgroundColor: cellBgColor,
    border: isCurrentSelection ? selectedBorder : nonSelectedBorder,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={cellBoxStyles} tabIndex={0}>
      {!showRawNumberAsTooltip && (
        <span
          aria-label={`Scan asset count of ${scanAssetCount} over Inventory asset count of ${inventoryAssetCount}.
      Vulnerability count of ${vulnerabilityCount} vulnerabilities.`}
          style={{ paddingTop: '4px' }}
        >
          {`${scanAssetCount} / ${inventoryAssetCount}`}
        </span>
      )}
      <div style={showRawNumberAsTooltip ? { marginTop: '12px' } : {}}>
        {authenticatedPercentage !== percentage && <FontIcon aria-label="Unauthenticated" iconName="Unlock" />}
        <span aria-label={`Authenticated percentage of ${percentage}%`}>
          <b style={{ fontWeight: 600 }}>{`${percentage}%`}</b>
        </span>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default React.memo(DayCoverageCell);
