import { FileAttachment } from 'generated/clientApi';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Icon, SharedColors, Spinner, SpinnerSize, mergeStyles } from '@fluentui/react';
import { deleteServiceAttachment } from 'modules/fileAttachment/fileAttachment';
import { FileAttachmentIcon } from './fileAttachmentIcon';

const fileRowStyles = mergeStyles({
  display: 'flex',
  justifyContent: 'left',
  paddingBottom: '0.35em',
});

const imageStyles = mergeStyles({
  maxWidth: '2em',
  maxHeight: '1.5em',
  paddingRight: '0.75em',
});

const iconStyles = mergeStyles({
  marginLeft: 'auto',
  color: SharedColors.cyanBlue10,
  cursor: 'pointer',
});

interface FileAttachmentMinimalDetail {
  fileAttachment: FileAttachment;
  isDeleting: boolean;
}

export interface FileAttachmentTableMinimalProps {
  fileAttachments: FileAttachment[];
  setFileAttachments(fileAttachments: FileAttachment[]): void;
}

const fileLength = 30;
const getFileAttachmentName = (name?: string) => {
  if (name && name.length > fileLength) {
    return `${name.substring(0, fileLength)}...`;
  }
  return name;
};

export const FileAttachmentTableMinimal: FunctionComponent<FileAttachmentTableMinimalProps> = (props) => {
  const { fileAttachments, setFileAttachments } = props;
  const [fileAttachmentMinimalDetails, setFileAttachmentMinimalDetails] = useState<FileAttachmentMinimalDetail[]>([]);

  useEffect(() => {
    if (fileAttachments) {
      const fileAttachmentMinimalDetails = fileAttachments.map((fileAttachment) => ({
        fileAttachment,
        isDeleting: false,
      }));
      setFileAttachmentMinimalDetails(fileAttachmentMinimalDetails);
    }
  }, [fileAttachments]);

  const onSetFileAttachmentMinimalDetails = (
    fileAttachmentMinimalDetails: FileAttachmentMinimalDetail[],
    fileAttachmentId: string,
    isDeleting: boolean,
  ) => {
    const copiedFileAttachmentMinimalDetails = [...fileAttachmentMinimalDetails];
    return copiedFileAttachmentMinimalDetails.map((fileAttachmentMinimalDetail) => {
      const current = fileAttachmentMinimalDetail;
      if (current.fileAttachment.id === fileAttachmentId) {
        current.isDeleting = isDeleting;
      }
      return current;
    });
  };

  const onDeleteAttachment = useCallback(
    (serviceOid: string, fileAttachmentId: string) => {
      const deleteFileTask = async () => {
        setFileAttachmentMinimalDetails((fileAttachmentMinimalDetails) =>
          onSetFileAttachmentMinimalDetails(fileAttachmentMinimalDetails, fileAttachmentId, true),
        );
        const deleteAttachmentResponse = await deleteServiceAttachment(serviceOid, fileAttachmentId);
        setFileAttachmentMinimalDetails((fileAttachmentMinimalDetails) =>
          onSetFileAttachmentMinimalDetails(fileAttachmentMinimalDetails, fileAttachmentId, false),
        );
        const copiedFileAttachments = fileAttachments.filter((fileAttachment) => fileAttachment.id !== deleteAttachmentResponse.id);
        setFileAttachments([...copiedFileAttachments]);
      };
      deleteFileTask();
    },
    [fileAttachments, setFileAttachments],
  );

  const getFileAttachmentElements = () =>
    fileAttachmentMinimalDetails.length > 0 ? (
      fileAttachmentMinimalDetails.map((fileAttachmentDetail) => {
        const { fileAttachment, isDeleting } = fileAttachmentDetail;
        return (
          <div key={fileAttachment.name} className={fileRowStyles} title={fileAttachment.name}>
            <FileAttachmentIcon extensionType={fileAttachment.documentType} imageStyles={imageStyles} />
            <div>
              {getFileAttachmentName(fileAttachment.name)}
              &nbsp; ({fileAttachment.sizeInMb})
            </div>
            {isDeleting ? (
              <Spinner className={iconStyles} size={SpinnerSize.small} />
            ) : (
              <Icon
                className={iconStyles}
                iconName="Delete"
                title={`Delete's file: ${fileAttachment.name}`}
                onClick={() => onDeleteAttachment(fileAttachment.serviceOid, fileAttachment.id)}
              />
            )}
          </div>
        );
      })
    ) : (
      <div>None.</div>
    );

  return (
    <>
      <h2>Supporting Documents</h2>
      {getFileAttachmentElements()}
    </>
  );
};
