import { FileAttachmentComponent, ProgressStatus } from 'components/fileAttachment/fileAttachment';
import { WizardNavigation } from 'components/wizardNavigation/wizardNavigation';
import { createServiceAttachment } from 'modules/fileAttachment/fileAttachment';
import { mergeStyles } from '@fluentui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { FileAttachment } from 'generated/clientApi';
import { BasicList, ListColumn } from 'components/basicList/basicList';
import { getFormattedDateTime } from 'modules/datetime/datetime';
import { useBoolean } from '@uifabric/react-hooks';
import { FileAttachmentIcon } from 'components/fileAttachment/fileAttachmentIcon';

const padding = mergeStyles({
  paddingTop: '1em',
  paddingBottom: '1em',
});

const nameColumnStyle = mergeStyles({
  display: 'flex',
});

const fileIconCell = mergeStyles({
  textAlign: 'center',
  selectors: {
    '&:before': {
      content: '.',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
      width: '0px',
      visibility: 'hidden',
    },
  },
});

const fileIconHeaderIcon = mergeStyles({
  padding: 0,
  fontSize: '16px',
});

export interface FileEvidenceTabProps {
  fileAttachments: FileAttachment[];
  setFileAttachments(fileAttachment: FileAttachment[]): void;
  onClickNextButton(): void;
  onClickPreviousButton(): void;
  serviceOid: string;
  certification: string;
  cloud: string;
}

export const FileEvidenceTab: React.FunctionComponent<FileEvidenceTabProps> = (props) => {
  const { fileAttachments, setFileAttachments, onClickNextButton, onClickPreviousButton, serviceOid, certification, cloud } = props;
  const [progressStatus, setProgressStatus] = useState<ProgressStatus>();
  const [isUploadInProgress, { setTrue: setUploadInProgress, setFalse: setUploadComplete }] = useBoolean(false);

  const createAttachmentTableColumn = useCallback(
    (
      key: string,
      name: string,
      fieldName: string,
      minWidth: number,
      ariaLabel?: string,
      getFieldValue?: (value: any) => string,
      onRender?: (fileAttachment: FileAttachment) => JSX.Element,
    ): ListColumn => ({
      key,
      name,
      fieldName,
      minWidth,
      ariaLabel,
      onRender,
      getFieldValue,
    }),
    [],
  );

  const attachmentTableColumns: ListColumn[] = useMemo(
    () => [
      {
        key: 'fileIcon',
        name: 'File Icon',
        className: fileIconCell,
        iconClassName: fileIconHeaderIcon,
        iconName: 'Page',
        isIconOnly: true,
        onRender: (fileAttachment: FileAttachment) => <FileAttachmentIcon extensionType={fileAttachment.documentType} />,
        minWidth: 16,
        maxWidth: 16,
      },
      createAttachmentTableColumn('name', 'Name', 'name', 600, 'Document Name', undefined, (fileAttachment: FileAttachment) => (
        <div className={nameColumnStyle}>{fileAttachment.name}</div>
      )),
      createAttachmentTableColumn(
        'uploadDate',
        'Uploaded',
        'uploadDate',
        100,
        'Upload Date',
        (fileAttachment: FileAttachment) => fileAttachment.uploadDate!.toLocaleDateString(),
        (fileAttachment: FileAttachment) => <>{fileAttachment.uploadDate && getFormattedDateTime(fileAttachment?.uploadDate)}</>,
      ),
      createAttachmentTableColumn(
        'fileSize',
        'File Size',
        'fileSize',
        100,
        'File Size',
        (fileAttachment: FileAttachment) => fileAttachment.size!.toString(),
        (fileAttachment: FileAttachment) => <>{fileAttachment.sizeInMb}</>,
      ),
      createAttachmentTableColumn('owner', 'Owner', 'owner', 100, 'Owner', undefined, (fileAttachment: FileAttachment) => (
        <>{fileAttachment.owner}</>
      )),
    ],
    [createAttachmentTableColumn],
  );

  const progressStatusCallback = (loaded: number, total: number): void => {
    setProgressStatus({ loaded, total });
  };

  const onFileChange = useCallback(
    async (file: File) => {
      setUploadInProgress();
      const fileAttachment = await createServiceAttachment(file, serviceOid, certification, cloud, file.type, false, progressStatusCallback);
      const copiedFileAttachments = [...fileAttachments];
      copiedFileAttachments.push(fileAttachment);
      setProgressStatus(undefined);
      setUploadComplete();
      setFileAttachments(copiedFileAttachments);
    },
    [setUploadInProgress, serviceOid, certification, cloud, fileAttachments, setFileAttachments, setUploadComplete],
  );

  return (
    <div>
      <p className={padding}>You can upload any additional documentation for review.</p>
      <FileAttachmentComponent
        progressStatus={progressStatus}
        allowedExtensions={['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.tm7', '.ppt', '.pptx', '.png', '.jpg', '.visio']}
        onFileChange={onFileChange}
        disableUpload={isUploadInProgress}
      />
      <BasicList items={fileAttachments} columns={attachmentTableColumns} notScrollable />
      <WizardNavigation
        isNextDisabled={isUploadInProgress}
        nextButtonText="Next: Review"
        isNextVisible
        isNextLoading={isUploadInProgress}
        isPreviousVisible
        onClickNextButton={onClickNextButton}
        onClickPreviousButton={onClickPreviousButton}
      />
    </div>
  );
};
