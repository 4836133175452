/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ActionButton,
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  Popup,
  PrimaryButton,
  Separator,
  mergeStyleSets,
  mergeStyles,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { toInteger } from 'lodash';
import {
  CoverageGetClient,
  Coverage,
  ScanCoverageIncludePostClient,
  ScanCoverageIncludePostCommand,
  ScanCoverageSelectionPostClient,
  ScanCoverageSelectionPostCommand,
  ServiceTreeAssetCloud,
  Selection as CoverageSelection,
  CoverageFilterValues,
  CoverageTableMetadataGetResponse,
  DayTotal,
  PagedResultOfCoverage,
  CoverageTableMetadataGetClient,
} from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { showError } from 'modules/messageBar/messageBar';
import { separatorStyles } from '../conMon';
import { ExcludeButton } from './excludeButton';
import CoverageTable from './coverageTable';
import { Legend } from './legend';
import { FilterBar, over90 } from './filterBar';
import { useCoverageFilters } from './useCoverageFilters';
import { ExcludingCoverageTable } from './excludingCoverageTable';
import { Totals } from './totals';
import { ComparePanel } from './comparePanel';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

const buttonBarStyle = mergeStyles({
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
});

const popupStyles = mergeStyleSets({
  root: {
    background: 'rgba(0, 0, 0, 0.2)',
    bottom: '0',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: 10000,
  },
  content: {
    background: 'white',
    left: '50%',
    maxWidth: '400px',
    padding: '0 2em 2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const changeOptions: IChoiceGroupOption[] = [
  { key: '0', text: 'Selected better coverage' },
  { key: '1', text: 'Selected better Scan result' },
  { key: '2', text: 'Other' },
];

type CoverageTabProps = {
  lockedMap: Record<string, boolean>;
};

const excludeDialogContentProps = (exclude: boolean) => ({
  type: DialogType.normal,
  title: `Are you sure you want to ${exclude ? 'include' : 'exclude'} the following rows?`,
  closeButtonAriaLabel: 'Close',
});

const excludeDialogStyles = { main: { maxWidth: 450, minWidth: 450 } };

export const UnpagedCoverageTab: React.FunctionComponent<CoverageTabProps> = (props) => {
  const { lockedMap } = props;
  const { readonly, organization } = useContext(ConMonFilterContext);
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
  const [changeReasonKey, setChangeReasonKey] = useState<string>('');
  const periods = useMemo(() => Object.keys(lockedMap), [lockedMap]);
  const [coverageData, setCoverageData] = useState<PagedResultOfCoverage>(
    new PagedResultOfCoverage({
      results: [] as Coverage[],
      continuationToken: undefined,
    }),
  );
  const [coverage] = useMemo(() => [coverageData.results ?? []], [coverageData]);

  const [coverageMetadata, setCoverageMetadata] = useState<CoverageTableMetadataGetResponse>(
    new CoverageTableMetadataGetResponse({
      totals: [] as DayTotal[],
      filters: {} as CoverageFilterValues,
    }),
  );
  const { totals, filters: coverageFilterValues } = coverageMetadata;

  const [filteredCoverage, setFilteredCoverage] = useState<Coverage[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [isExcludeDialogClosed, setIsExcludeDialogClosed] = useState(true);
  const [selectedItems, setSelectedItems] = useState<ServiceTreeAssetCloud[]>([]);
  const [userSelectedDays, systemSelectedDays] = useMemo(() => {
    const system = new Map<string, number>(coverage.map((x) => [x.id, x.systemSelectedDay!]));
    const user = new Map<string, number>(coverage.map((x) => [x.id, x.userSelectedDay ?? x.systemSelectedDay!]));
    return [user, system];
  }, [coverage]);

  const [newSelectedDays, setNewSelectedDays] = useState(new Map<string, CoverageSelection>());
  const [scrollLeft, setScrollLeft] = useState(0);
  const [comparePanelOpen, setComparePanelOpen] = useState(false);

  const labelId = 'excludeDialogLabel';
  const excludeModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      isBlocking: false,
      styles: excludeDialogStyles,
    }),
    [labelId],
  );

  const onPopupOptionChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
    if (option?.key) {
      setChangeReasonKey(option.key);
    }
  };

  const popUpSave = (isSkip: boolean) => {
    let reason = '';
    if (isSkip || changeReasonKey === '') {
      reason = 'N/A';
    } else {
      reason = changeOptions[toInteger(changeReasonKey)].text;
    }
    hidePopup();
    onSaveSelection(reason);
    setChangeReasonKey('');
  };

  const filters = useCoverageFilters(periods[0]);

  const isCurrentPeriodLocked = useMemo(() => {
    if (filters.period) {
      return lockedMap[filters.period] ?? true;
    }
    return lockedMap[periods[0]];
  }, [filters.period, lockedMap, periods]);

  const filterCoverage = useCallback(() => {
    let filteredCoverage = coverage;
    filteredCoverage = filteredCoverage.filter((cov) => cov.exclude === filters.exclude);
    if (filters.assetType) {
      filteredCoverage = filteredCoverage.filter((cov) => cov.assetType?.toLowerCase() === filters.assetType.toLowerCase());
    }
    if (filters.cloud) {
      filteredCoverage = filteredCoverage.filter((cov) => cov.cloudType?.toLowerCase() === filters.cloud.toLowerCase());
    }
    if (filters.percentCoverage) {
      if (filters.percentCoverage === over90) {
        filteredCoverage = filteredCoverage.filter((cov) => cov.max! >= 90);
      } else {
        filteredCoverage = filteredCoverage.filter((cov) => cov.max! < 90);
      }
    }
    if (filters.serviceTreeName) {
      filteredCoverage = filteredCoverage.filter((cov) => cov.serviceTreeName?.toLowerCase().includes(filters.serviceTreeName.toLowerCase()));
    }
    setFilteredCoverage(filteredCoverage);
  }, [coverage, filters.assetType, filters.cloud, filters.percentCoverage, filters.serviceTreeName, filters.exclude]);

  useEffect(() => {
    filterCoverage();
  }, [filterCoverage]);

  const getCoverage = useCallback(async () => {
    setLoading(true);
    onResetSelection();
    const client = new CoverageGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(
        filters.period ?? periods[0],
        organization,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );
      setCoverageData(response.coverageData);
    } catch (e) {
      showError('There was an error fetching coverage. Please refresh to try again.');
    } finally {
      setLoading(false);
    }
  }, [filters.period, periods, organization]);

  useEffect(() => {
    getCoverage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.period, organization]);
  const getCoverageMetadata = useCallback(async () => {
    const client = new CoverageTableMetadataGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(filters.period ?? periods[0], undefined);
      setCoverageMetadata(response);
    } catch (e) {
      showError('There was an error fetching coverage. Please refresh to try again.');
    }
  }, [filters.period, periods]);

  useEffect(() => {
    getCoverageMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.period]);
  const saveCoverageInclusion = async () => {
    setLoading(true);
    setIsExcludeDialogClosed(true);

    const client = new ScanCoverageIncludePostClient(getConfig().apiBaseUri);
    const command = { compositeKeys: selectedItems, include: filters.exclude, period: filters.period };

    try {
      await client.post(command as ScanCoverageIncludePostCommand);
      getCoverage();
    } catch {
      showError('There was an error updating coverage. Please try your operation again.');
    }
  };

  const onSaveSelection = async (reason: string) => {
    setLoading(true);
    const client = new ScanCoverageSelectionPostClient(getConfig().apiBaseUri);
    const selections = Array.from(newSelectedDays.values());
    const command = { selections, period: filters.period ?? periods[0], reason };
    try {
      await client.post(command as ScanCoverageSelectionPostCommand);
      getCoverage();
    } catch {
      showError('There was an error saving coverage. Please try your operation again.');
    }
  };

  const onResetSelection = () => {
    setNewSelectedDays(new Map());
  };

  const getSelectedCoverage = () => {
    const selectedCov = coverage.find(
      (cov) =>
        cov.assetType === selectedItems.at(0)?.assetType &&
        cov.cloudType === selectedItems.at(0)?.cloudType &&
        cov.serviceTreeName === selectedItems.at(0)?.serviceTreeName,
    );
    return selectedCov;
  };

  const onUpdateSelection = (item: Coverage, selectedDay: number) => {
    const { id } = item;
    const dayId = item.days?.find((day) => day.day === selectedDay)?.id;
    if (dayId === undefined) {
      showError('There was an error updating coverage selection.');
      return;
    }
    const newMap = new Map(newSelectedDays);
    const covSelection = {
      serviceTreeName: item.serviceTreeName,
      assetType: item.assetType,
      cloudType: item.cloudType,
      newSelectedId: dayId,
    };
    newMap.set(id, covSelection as CoverageSelection);
    setNewSelectedDays(newMap);
  };

  return (
    <div>
      {isPopupVisible && (
        <Popup className={popupStyles.root} role="dialog" aria-modal="true">
          <div role="document" className={popupStyles.content}>
            <h2>Select reason for change</h2>
            <ChoiceGroup style={{ paddingBottom: '1rem' }} options={changeOptions} onChange={onPopupOptionChange} label="Pick one" required={false} />
            <PrimaryButton style={{ marginRight: '1rem' }} disabled={changeReasonKey === ''} onClick={() => popUpSave(false)}>
              Save
            </PrimaryButton>
            <DefaultButton onClick={() => popUpSave(true)}>Skip</DefaultButton>
          </div>
        </Popup>
      )}
      {comparePanelOpen && (
        <ComparePanel
          selectedCoverage={getSelectedCoverage()}
          close={() => setComparePanelOpen(false)}
          period={filters.period ?? periods[0]}
          onUpdateSelection={onUpdateSelection}
          isCurrentPeriodLocked={isCurrentPeriodLocked}
        />
      )}
      <div className={buttonBarStyle}>
        {!isCurrentPeriodLocked && (
          <>
            {!filters.exclude ? (
              <>
                <PrimaryButton
                  style={{
                    minWidth: '56px',
                    width: '56px',
                    height: '32px',
                    borderRadius: '4px',
                  }}
                  disabled={newSelectedDays.size <= 0 || loading || readonly}
                  onClick={showPopup}
                >
                  Save
                </PrimaryButton>
                <ActionButton
                  className={actionButtonStyles}
                  disabled={newSelectedDays.size <= 0 || loading}
                  iconProps={{ iconName: 'Undo' }}
                  onClick={onResetSelection}
                >
                  Revert
                </ActionButton>
                <ExcludeButton disabled={selectedItems.length === 0 || loading} onClick={() => setIsExcludeDialogClosed(false)} />
              </>
            ) : (
              <PrimaryButton
                style={{
                  minWidth: '56px',
                  width: 'fit-content',
                  height: '32px',
                  borderRadius: '4px',
                  marginRight: '.5rem',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setIsExcludeDialogClosed(false)}
                disabled={selectedItems.length === 0 || loading || readonly}
              >
                Include and Save
              </PrimaryButton>
            )}
          </>
        )}
        <ActionButton
          className={actionButtonStyles}
          disabled={selectedItems.length !== 1}
          iconProps={{ iconName: 'Switch' }}
          onClick={() => setComparePanelOpen(true)}
        >
          Compare vulns
        </ActionButton>
        <Legend />
      </div>

      <Separator styles={separatorStyles} />
      <FilterBar filterValues={coverageFilterValues ?? ({} as CoverageFilterValues)} />
      <CoverageTable
        coverage={filteredCoverage}
        loading={loading}
        loadingInitial={false}
        totals={totals ?? []}
        setSelectedItems={setSelectedItems}
        userSelectedDays={userSelectedDays}
        systemSelectedDays={systemSelectedDays}
        newSelectedDays={newSelectedDays}
        setNewSelectedDays={setNewSelectedDays}
        isCurrentPeriodLocked={isCurrentPeriodLocked}
        setScrollLeft={setScrollLeft}
        onScrollTable={() => {}}
        setSort={() => {}}
      />
      <Totals totals={totals ?? []} scrollLeft={scrollLeft} />

      <Dialog
        hidden={isExcludeDialogClosed}
        onDismiss={() => setIsExcludeDialogClosed(true)}
        dialogContentProps={excludeDialogContentProps(filters.exclude)}
        modalProps={excludeModalProps}
        maxWidth="600px"
        minWidth="600px"
      >
        <ExcludingCoverageTable selectedItems={selectedItems} />
        <DialogFooter>
          <PrimaryButton onClick={saveCoverageInclusion} text={filters.exclude ? 'Include' : 'Exclude'} />
          <DefaultButton onClick={() => setIsExcludeDialogClosed(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
