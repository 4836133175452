import {
  ActionButton,
  DetailsList,
  IColumn,
  IconButton,
  ProgressIndicator,
  SelectionMode,
  Spinner,
  mergeStyleSets,
  mergeStyles,
} from '@fluentui/react';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import download from 'downloadjs';
import {
  ConmonReportMetadata,
  ConmonReportsDeleteClient,
  ConmonReportsDeleteCommand,
  ConmonReportsGetClient,
  ConmonReportsListClient,
  ReportStatus,
} from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import { showError } from 'modules/messageBar/messageBar';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { fileIconCell, fileIconHeaderIcon } from 'styles/fileIconStyles';
import { buttonStyles } from '../styles/buttonStyles';
import { DefaultJustificationPanel } from './defaultJustificationTab/defaultJustificationPanel';
import { ReportOwnersPanel } from './reportOwnersPanel';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

const commonStatusStyles = mergeStyles({
  color: 'white',
  borderRadius: '7px',
  width: '65px',
  textAlign: 'center',
});

const statusClassNames = mergeStyleSets({
  completed: [{ background: '#107c10', border: '4px solid #107c10' }, commonStatusStyles],
  failed: [{ background: '#a80000', border: '4px solid #a80000' }, commonStatusStyles],
});

const panelTypes = {
  defaultJustification: 'defaultJustification',
  reportOwners: 'reportOwners',
} as const;

type ReportTabProps = {
  lockedMap: Record<string, boolean>;
};

export const ReportTab: React.FunctionComponent<ReportTabProps> = (props) => {
  const { lockedMap } = props;
  const { organization } = useContext(ConMonFilterContext);

  const [reports, setReports] = useState<{ [key: string]: ConmonReportMetadata[] }>();
  const [loading, setLoading] = useState(true);
  const [downloadingIds, setDownloadingIds] = useState(new Set<string>());
  const [openPanelName, setOpenPanelName] = useState<keyof typeof panelTypes>();
  const currentPeriod = useMemo(() => Object.keys(lockedMap).at(0), [lockedMap]);

  const fetchReports = async () => {
    if (!currentPeriod) {
      return;
    }
    setLoading(true);
    const client = new ConmonReportsListClient(getConfig().apiBaseUri);
    try {
      const response = await client.list(currentPeriod, organization);
      setReports(response ?? {});
    } catch (e) {
      showError('There was an error fetching reports. Please refresh to try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPeriod, organization]);

  const getReport = async (reportName: string, period: string) => {
    setDownloadingIds((prev) => {
      const newIds = new Set(prev);
      newIds.add(reportName);
      return newIds;
    });
    const reportClient = new ConmonReportsGetClient(getConfig().apiBaseUri);
    try {
      const res = await reportClient.get(reportName, period);
      download(res.data, reportName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    } catch (e) {
      showError(`There was an error downloading the report '${reportName}'. Please refresh and try again.`);
    } finally {
      setDownloadingIds((prev) => {
        const newIds = new Set(prev);
        newIds.delete(reportName);
        return newIds;
      });
    }
  };

  const deleteReport = async (report: ConmonReportMetadata) => {
    setLoading(true);
    const reportClient = new ConmonReportsDeleteClient(getConfig().apiBaseUri);
    const command = {
      id: report.id,
      reportName: report.name,
      period: report.period,
    };
    try {
      await reportClient.delete(report.name, report.period, command as ConmonReportsDeleteCommand);
      await fetchReports();
    } catch (e) {
      showError(`There was an error deleting the report '${report.name}'. Please refresh and try again.`);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    (): IColumn[] =>
      // eslint-disable-next-line implicit-arrow-linebreak
      [
        {
          key: 'name',
          name: 'Name',
          minWidth: 200,
          maxWidth: 550,
          fieldName: 'name',
        },
        {
          key: 'cloud',
          name: 'Cloud',
          minWidth: 75,
          maxWidth: 100,
          fieldName: 'cloudType',
        },
        {
          key: 'type',
          name: 'Report type',
          minWidth: 100,
          maxWidth: 150,
          fieldName: 'type',
        },
        {
          key: 'creation-date',
          name: 'Creation date',
          minWidth: 100,
          maxWidth: 150,
          onRender: (item: ConmonReportMetadata) => {
            const date = new Date(item.creationDateUtc!);
            // format to "dd/mm/yyyy"
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
          },
        },
        {
          key: 'status',
          name: 'Status',
          minWidth: 100,
          maxWidth: 250,
          fieldName: 'status',
          onRender: (item: ConmonReportMetadata) => {
            if (item.status === ReportStatus.Pending) {
              return (
                <div style={{ marginLeft: '6px', width: 'fit-content' }}>
                  <Spinner />
                </div>
              );
            }
            return <div className={item.status === ReportStatus.Completed ? statusClassNames.completed : statusClassNames.failed}>{item.status}</div>;
          },
        },
        {
          key: 'download',
          name: 'Download',
          className: fileIconCell,
          iconClassName: fileIconHeaderIcon,
          iconName: 'Download',
          isIconOnly: true,
          minWidth: 50,
          maxWidth: 50,
          onRender: (item: ConmonReportMetadata) => {
            if (item.status !== ReportStatus.Completed) {
              return <></>;
            }
            if (downloadingIds.has(item.name ?? '')) {
              return <ProgressIndicator />;
            }
            return (
              <div style={{ textAlign: 'initial', marginLeft: '-.5rem' }}>
                <IconButton
                  iconProps={{ iconName: 'Download' }}
                  title="Download"
                  ariaLabel="Download"
                  style={{ height: '16px' }}
                  onClick={() => getReport(item.name ?? '', item.period ?? '')}
                />
              </div>
            );
          },
        },
        {
          key: 'delete',
          name: 'Delete',
          className: fileIconCell,
          iconClassName: fileIconHeaderIcon,
          iconName: 'Delete',
          isIconOnly: true,
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: ConmonReportMetadata) => {
            const isOlderThanOneHour = item.creationDateUtc!.getTime() < Date.now() - 60 * 60 * 1000;
            if (item.status === ReportStatus.Failed || (item.status === ReportStatus.Pending && isOlderThanOneHour)) {
              return (
                <div style={{ textAlign: 'initial', marginLeft: '-.5rem' }}>
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title="Delete"
                    ariaLabel="Delete"
                    style={{ height: '16px' }}
                    onClick={() => deleteReport(item)}
                  />
                </div>
              );
            }
            return <></>;
          },
        },
      ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadingIds],
  );

  return (
    <div>
      <div style={{ marginTop: '1.5rem' }}>
        {organization !== undefined && (
          <>
            <ActionButton
              className={actionButtonStyles}
              style={{ ...buttonStyles }}
              iconProps={{ iconName: 'Edit' }}
              onClick={() => setOpenPanelName('defaultJustification')}
            >
              Edit default justification
            </ActionButton>
            <ActionButton
              className={actionButtonStyles}
              style={{ ...buttonStyles }}
              iconProps={{ iconName: 'View' }}
              onClick={() => setOpenPanelName('reportOwners')}
            >
              View report owners
            </ActionButton>
          </>
        )}
        <ActionButton className={actionButtonStyles} style={{ ...buttonStyles }} iconProps={{ iconName: 'Refresh' }} onClick={fetchReports}>
          Refresh
        </ActionButton>
      </div>
      <div style={{ height: 'calc(100vh - (355px))', overflow: 'auto', paddingBottom: '3rem' }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <CenteredProgressDots />
        ) : !reports || Object.keys(reports).length === 0 ? (
          <em style={{ display: 'block', marginTop: '2rem', marginLeft: '2rem' }}>
            No report items were found in the previous 12 months. Try generating reports in the POA&Ms tab and check back.
          </em>
        ) : (
          Object.entries(reports).map(([period, reports]) => (
            <React.Fragment key={period}>
              <h2 style={{ marginTop: '1.5rem', marginBottom: '0' }}>{period}</h2>
              <DetailsList
                items={reports.sort((a, b) => b.creationDateUtc!.getTime() - a.creationDateUtc!.getTime())}
                columns={columns}
                selectionMode={SelectionMode.none}
              />
            </React.Fragment>
          ))
        )}
      </div>
      {openPanelName === 'defaultJustification' && (
        <DefaultJustificationPanel isOpen={openPanelName === 'defaultJustification'} close={() => setOpenPanelName(undefined)} />
      )}
      {openPanelName === 'reportOwners' && <ReportOwnersPanel close={() => setOpenPanelName(undefined)} />}
    </div>
  );
};
