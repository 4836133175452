import { mergeStyles, MessageBar, MessageBarType, SelectionMode, Stack } from '@fluentui/react';
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FilterBubble, FilterBubbleProps } from '../../components/filterBubble/filterBubbleComponent';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { CatalogsListResponse, ControlMetadataListResponse, ControlsListResponse, FrameworkManagementControl } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { allControls, getControlCatalogControlsByGroup, getControlCatalogs } from '../../modules/controlCatalogs/controlCatalogs';
import {
  Control,
  getControlsMetadata,
  GetInternalAzureServiceTeams,
  mapControlsListResponseToControlsList,
} from '../../modules/controlMetadata/controlMetadata';
import { logError } from '../../modules/logging/logging';
import { showError } from '../../modules/messageBar/messageBar';
import { filterControls, getAzureServiceTeamFilters, getControlGroupIdByTitle } from './controlMetadataFilter.functions';

export interface ControlMetadataFilter {
  name: string;
  value: string;
}

enum FilterName {
  controlCatalogFilter = 'controlCatalogFilter',
  controlGroupsFilter = 'controlGroupsFilter',
  internalAzureServiceTeamFilter = 'internalAzureServiceTeamFilter',
  complianceSelfTestFilter = 'complianceSelfTestFilter',
}

const centeredDotsContainerStyles = mergeStyles({
  padding: 25,
});

const filterButtonsContainerStyles = mergeStyles({
  marginTop: '1em',
  marginBottom: '1em',
});

const all = 'All';

export interface IControlMetadataFilterProps {
  loadAllControlGroupsForCurrentCatalog: boolean; // set to true if we should load, false if passing them in
  allControlGroupsForCurrentCatalog: Control[];
  setAllControlGroupsForCurrentCatalog: Dispatch<SetStateAction<Control[]>>;
  allControlsMetadata: ControlMetadataListResponse[];
  refreshMetadata?: boolean;
  setSelectedCatalog?: Dispatch<SetStateAction<string>>;
  setControlsMetadata: Dispatch<SetStateAction<ControlMetadataListResponse[]>>;
  setControls: Dispatch<SetStateAction<FrameworkManagementControl[]>>;
  internalServiceTeams: string[];
  setInternalServiceTeams: Dispatch<SetStateAction<string[]>>;
  controlCatalog?: string; // Passing in controlCatalog will hide the control catalog dropdown and limit to the passed value
  onFilter?: () => void;
  filterHasMetadata?: boolean;
}

export const ControlMetadataFilters: FunctionComponent<IControlMetadataFilterProps> = (props) => {
  // destructure props
  const {
    loadAllControlGroupsForCurrentCatalog,
    allControlGroupsForCurrentCatalog,
    setAllControlGroupsForCurrentCatalog,
    allControlsMetadata,
    setSelectedCatalog,
    setControlsMetadata,
    setControls,
    internalServiceTeams,
    setInternalServiceTeams,
    controlCatalog,
    onFilter,
    filterHasMetadata: filterByHasMetadata,
    refreshMetadata,
  } = props;

  // Data loaded once on render. Remains static.
  const [controlCatalogs, setControlCatalogs] = useState<CatalogsListResponse[]>([]);
  const [allControlGroups, setAllControlGroups] = useState<ControlsListResponse[]>([]);
  const [allComplianceSelfTests, setAllComplianceSelfTests] = useState<string[]>([]);

  // State tracking
  const [selectedControlCatalog, setSelectedControlCatalog] = useState<ControlMetadataFilter>(
    controlCatalog ? { name: controlCatalog, value: controlCatalog } : { name: all, value: allControls },
  );
  const [selectedControlGroup, setSelectedControlGroup] = useState<ControlMetadataFilter>(
    controlCatalog ? { name: controlCatalog, value: controlCatalog } : { name: all, value: allControls },
  );
  const [selectedInternalServiceTeams, setSelectedInternalServiceTeams] = useState<ControlMetadataFilter[]>([]);
  const [selectedComplianceSelfTestNames, setSelectedComplianceSelfTestNames] = useState<string[]>([]);
  const [loading, setLoading] = useState(LoadingState.Loaded);

  const getControlCatalogControlsByGroupCallback = useCallback((catalogId?: string) => getControlCatalogControlsByGroup(catalogId), []);

  useEffect(() => {
    const getControlsAndFilterData = async () => {
      let allControlsForAllGroupsResults: ControlsListResponse[];

      try {
        setLoading(LoadingState.Loading);
        if (controlCatalog) {
          setSelectedControlCatalog({ value: controlCatalog, name: controlCatalog });
          allControlsForAllGroupsResults = await getControlCatalogControlsByGroupCallback(controlCatalog);
        } else {
          const controlCatalogsResults = await getControlCatalogs();
          setControlCatalogs(controlCatalogsResults.concat({ id: allControls, title: all } as CatalogsListResponse));
          setSelectedControlCatalog({ value: allControls, name: all });
          allControlsForAllGroupsResults = await getControlCatalogControlsByGroupCallback();
        }

        // this is used for display options only.
        setAllControlGroups(allControlsForAllGroupsResults.concat({ title: all, id: allControls } as ControlsListResponse));
        setSelectedControlGroup({ value: allControls, name: all });

        // If refresh metadata is undefined, tell the API NOT to grab the latest data
        const allControlsMetadata = await getControlsMetadata(refreshMetadata || false, true);
        setControlsMetadata(allControlsMetadata);
        setAllComplianceSelfTests(
          allControlsMetadata.flatMap((metadata) => metadata.complianceSelfTestDocuments?.map((csd) => csd.title || '') || []),
        );

        const allInternalServiceTeams = await GetInternalAzureServiceTeams(allControlsMetadata);
        setInternalServiceTeams(allInternalServiceTeams);
        setLoading(LoadingState.Loaded);
      } catch (error) {
        const errorMessage = 'There was an error retrieving data.';
        logError(errorMessage, error);
        showError(`${errorMessage} Please refresh and try again.`);
        setLoading(LoadingState.Error);
      }
    };

    // We only want to grab this data initially and then when refreshMetadata is set to true
    if (refreshMetadata === undefined || refreshMetadata) {
      getControlsAndFilterData();
    }
  }, [setControlsMetadata, setInternalServiceTeams, controlCatalog, getControlCatalogControlsByGroupCallback, refreshMetadata]);

  // Triggers only when the the control catalog has changed. Loads the control catalog's controls via API.
  useEffect(() => {
    const getControlCatalogControls = async () => {
      try {
        if (loadAllControlGroupsForCurrentCatalog) {
          setLoading(LoadingState.Loading);
          const allControlsForCatalogResult = await getControlCatalogControlsByGroupCallback(selectedControlCatalog.value);
          const allControlsForCatalog = mapControlsListResponseToControlsList(allControlsForCatalogResult);
          setAllControlGroupsForCurrentCatalog(allControlsForCatalog);
          if (onFilter) {
            onFilter();
          }
          setLoading(LoadingState.Loaded);
        }
      } catch (error) {
        const errorMessage = 'There was an error retrieving catalog control data.';
        logError(errorMessage, error);
        showError(`${errorMessage}  Please refresh and try again.`);
        setLoading(LoadingState.Error);
      }
    };
    if (!selectedControlCatalog) {
      return;
    }
    getControlCatalogControls();
  }, [
    selectedControlCatalog,
    setAllControlGroupsForCurrentCatalog,
    loadAllControlGroupsForCurrentCatalog,
    getControlCatalogControlsByGroupCallback,
    onFilter,
  ]);

  useEffect(() => {
    if (allControlsMetadata.length === 0 || allControlGroupsForCurrentCatalog.length === 0 || filterByHasMetadata === undefined) {
      return;
    }

    setControls(
      allControlGroupsForCurrentCatalog
        .filter((control) =>
          filterControls(
            control,
            allControlsMetadata,
            selectedControlGroup,
            selectedInternalServiceTeams,
            selectedComplianceSelfTestNames,
            filterByHasMetadata,
          ),
        )
        .map((control) => ({ id: control.controlId, title: control.title }) as FrameworkManagementControl),
    );
  }, [
    allControlGroupsForCurrentCatalog,
    selectedControlGroup,
    selectedInternalServiceTeams,
    selectedComplianceSelfTestNames,
    allControlsMetadata,
    filterByHasMetadata,
    setControls,
  ]);

  const setFilters = useCallback(
    (selectedValues: string[], filter: string) => {
      switch (filter) {
        case FilterName.controlCatalogFilter: {
          const controlCatalog = controlCatalogs.find((catalog) => catalog.title === selectedValues[0]);
          if (controlCatalog?.id) {
            setSelectedControlCatalog({ value: controlCatalog.id, name: selectedValues[0] });
            if (setSelectedCatalog) {
              setSelectedCatalog(controlCatalog.id);
            }
          }
          break;
        }
        case FilterName.controlGroupsFilter: {
          setSelectedControlGroup({
            name: selectedValues[0],
            value: getControlGroupIdByTitle(allControlGroups, selectedValues[0]),
          });
          break;
        }
        case FilterName.internalAzureServiceTeamFilter: {
          setSelectedInternalServiceTeams(getAzureServiceTeamFilters(internalServiceTeams, selectedValues));
          break;
        }
        case FilterName.complianceSelfTestFilter: {
          setSelectedComplianceSelfTestNames(selectedValues);
          break;
        }
        default:
          break;
      }
      if (onFilter) {
        onFilter();
      }
    },
    [allControlGroups, internalServiceTeams, onFilter, controlCatalogs, setSelectedCatalog],
  );

  const filterFields: FilterBubbleProps[] = [
    {
      id: FilterName.controlCatalogFilter,
      fieldName: 'Control Catalog',
      filter: selectedControlCatalog !== undefined ? [selectedControlCatalog.name] : [],
      valueOptions: controlCatalogs?.map((catalog) => catalog.title || '') || [],
      selectionMode: SelectionMode.single,
      onChange: setFilters,
    },
    {
      id: FilterName.controlGroupsFilter,
      fieldName: 'Control Group',
      filter: selectedControlGroup !== undefined ? [selectedControlGroup.name] : [],
      valueOptions: allControlGroups?.map((group) => `${group.title}` || '') || [],
      selectionMode: SelectionMode.single,
      onChange: setFilters,
    },
    {
      id: FilterName.internalAzureServiceTeamFilter,
      fieldName: 'Internal Service Teams',
      filter: selectedInternalServiceTeams.map((selection) => selection.name) || [], // want without the markers - new endpoint? - resolve controls tokens - controlmetadta list
      valueOptions: internalServiceTeams,
      selectionMode: SelectionMode.multiple,
      onChange: setFilters,
    },
    {
      id: FilterName.complianceSelfTestFilter,
      fieldName: 'Compliance Self Tests',
      filter: selectedComplianceSelfTestNames.map((selection) => selection) || [],
      valueOptions: allComplianceSelfTests?.map((testName) => testName || '') || [],
      selectionMode: SelectionMode.multiple,
      onChange: setFilters,
    },
  ];

  const getLoadedUi = () => (
    <Stack horizontal className={filterButtonsContainerStyles}>
      {filterFields
        .filter((field) => !(controlCatalog && field.id === FilterName.controlCatalogFilter))
        .map((field) => (
          <FilterBubble
            key={field.id}
            id={field.id}
            fieldName={field.fieldName}
            filter={field.filter}
            valueOptions={field.valueOptions}
            selectionMode={field.selectionMode}
            onChange={field.onChange}
          />
        ))}
    </Stack>
  );

  const getUiFromState = () => {
    switch (loading) {
      case LoadingState.Loading:
        return (
          <div className={centeredDotsContainerStyles}>
            <CenteredProgressDots />
          </div>
        );
      case LoadingState.Error:
        return <MessageBar messageBarType={MessageBarType.error}>Failed to load Control Metadata.</MessageBar>;
      case LoadingState.Loaded:
      default:
        return getLoadedUi();
    }
  };

  return getUiFromState();
};
