import { CloudCertification } from 'models/cloudCertification';
import { Authorizations, EnvironmentAuthorizationListClient, IEnvironmentAuthorizationListResponse } from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getEnvironmentAuthorizations = async (): Promise<IEnvironmentAuthorizationListResponse> => {
  const environmentAuthorizationListClient = new EnvironmentAuthorizationListClient(getConfig().apiBaseUri);
  return environmentAuthorizationListClient.get();
};

export const getCombinedCloudCertifications = (environmentAuthorizations: Authorizations[]): string[] =>
  environmentAuthorizations.map((ea) => `${ea.cloud} ${ea.certification}`);

export const getCloudCertification = (combinedCloudCertification?: string): CloudCertification => {
  if (!combinedCloudCertification) {
    return { cloud: undefined, certification: undefined };
  }

  // splits the string by the first space in the string
  const cloudCertSplit = combinedCloudCertification.split(/ (.*)/, 2);
  const cloud = cloudCertSplit[0];
  const certification = cloudCertSplit[1];
  return { cloud, certification };
};
