import { Spinner, SpinnerSize } from '@fluentui/react';
import { CoverageIsPagedGetClient } from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { showError } from 'modules/messageBar/messageBar';
import { useCoverageFilters } from './useCoverageFilters';
import { CoverageTab } from './coverageTab';
import { UnpagedCoverageTab } from './unpagedCoverageTab';

type CoverageTabProps = {
  lockedMap: Record<string, boolean>;
};

export const CoverageTabDataContainer: React.FunctionComponent<CoverageTabProps> = (props) => {
  const { lockedMap } = props;
  const [isPaged, setIsPaged] = useState<boolean>();

  const filters = useCoverageFilters();

  const isCurrentPeriodLocked = useMemo(() => lockedMap[filters.period] ?? true, [filters.period, lockedMap]);

  const getIsPaged = useCallback(async () => {
    const client = new CoverageIsPagedGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(filters.period);
      setIsPaged(response);
    } catch (e) {
      showError('There was an error fetching coverage. Please refresh to try again.');
    }
  }, [filters.period, setIsPaged]);

  useEffect(() => {
    if (filters.period) {
      getIsPaged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.period]);

  if (isPaged === undefined) {
    return <Spinner label="Loading Coverage Data..." size={SpinnerSize.large} />;
  }
  if (isPaged === true) {
    return <CoverageTab isCurrentPeriodLocked={isCurrentPeriodLocked} />;
  }
  return <UnpagedCoverageTab lockedMap={lockedMap} />;
};
