import { createContext } from 'react';
import { AtoAOrganization, AuthorizedSystem } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';

export interface UserContextProps {
  organizations: AtoAOrganization[];
  systems: AuthorizedSystem[];
  selectedOrganization: AtoAOrganization | undefined;
  userLoadingState: LoadingState;
  requestUserInformation: () => void;
  updateSelectedOrganization: (organizationId: string) => void;
}

export const UserContext = createContext<UserContextProps>({
  organizations: [],
  systems: [],
  selectedOrganization: undefined,
  userLoadingState: LoadingState.NotLoaded,
  requestUserInformation: (): void => {
    // do nothing for the base case
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateSelectedOrganization: (organizationId: string): void => {
    // do nothing for the base case
  },
});

export const UserConsumer = UserContext.Consumer;
