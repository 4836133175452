import { ConstrainMode, DetailsList, IColumn, IDetailsHeaderProps, IRenderFunction, SelectionMode, mergeStyles } from '@fluentui/react';
import { PoamGetRiskClient, PoamGetRiskResponse, RiskGroup } from 'generated/clientApi';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { showError } from 'modules/messageBar/messageBar';
import { getConfig } from 'modules/config/config';
import { ProgressDots } from 'components/progressDots/progressDots';
import { riskColors } from './poamTab';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

const boxStyles = mergeStyles({
  display: 'flex',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)',
  borderRadius: '4px',
  marginBottom: '1rem',
  marginTop: '2rem',
  paddingRight: '1.5rem',
  gap: '10%',
  maxWidth: '1500px',
});
const customTableStyles = {
  root: {
    '.ms-DetailsRow': {
      height: '10px',
      minHeight: '32px',
    },
    '.ms-DetailsRow-cell': {
      paddingTop: 0,
      paddingLeft: 0,
      height: '32px',
      minHeight: '32px',
    },
    '.ms-List-cell': {
      minHeight: 0,
    },
  },
};

type PoamRiskBoxProps = {
  currentPeriod: string;
  isLocked: boolean;
  cloud?: string;
};

export const PoamRiskBox: React.FunctionComponent<PoamRiskBoxProps> = (props) => {
  const { currentPeriod, cloud, isLocked } = props;
  const { organization } = useContext(ConMonFilterContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [riskTotals, setRiskTotals] = useState<PoamGetRiskResponse>();

  const getRiskTotals = useCallback(async () => {
    setLoading(true);
    const client = new PoamGetRiskClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(currentPeriod, isLocked, cloud, organization);
      setRiskTotals(response);
    } catch (e) {
      showError('There was an error fetching risk totals. Please refresh to try again.');
    } finally {
      setLoading(false);
    }
  }, [cloud, currentPeriod, isLocked, organization]);

  useEffect(() => {
    getRiskTotals();
  }, [getRiskTotals]);

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (headerProps, defaultRender) => {
    if (!headerProps || !defaultRender) {
      return null;
    }
    return defaultRender({
      ...headerProps,
      styles: {
        root: {
          selectors: {
            '.ms-DetailsHeader-cell': {
              whiteSpace: 'normal',
              textOverflow: 'clip',
              lineHeight: 'normal',
            },
            '.ms-DetailsHeader-cellTitle': {
              height: '100%',
              alignItems: 'center',
            },
          },
        },
      },
    });
  };

  const columns = useMemo(
    (): IColumn[] => [
      {
        key: 'risk',
        name: 'Risk',
        minWidth: 80,
        maxWidth: 120,
        onRender: (item: RiskGroup) => {
          const riskCat = item.effectiveRating?.toLowerCase() as keyof typeof riskColors;
          const barColor = Object.hasOwn(riskColors, riskCat) ? riskColors[riskCat] : 'white';
          const barStyle = mergeStyles({ width: '6px', height: '35px', backgroundColor: barColor });
          return (
            <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
              <div className={barStyle} />
              <b>{riskCat.charAt(0).toUpperCase() + riskCat.slice(1)}</b>
            </div>
          );
        },
      },
      {
        key: 'NotInReportWindow',
        name: 'Not In Report Window',
        minWidth: 100,
        maxWidth: 140,
        onRender: (item: RiskGroup, index?: number) => <RiskTableCell value={item.notInReportWindow!} index={index ?? 0} />,
      },
      {
        key: 'DueThisReport',
        name: 'Due This Report',
        minWidth: 90,
        maxWidth: 110,
        onRender: (item: RiskGroup, index?: number) => <RiskTableCell value={item.dueThisReport!} index={index ?? 0} />,
      },
      {
        key: 'DueNextReport',
        name: 'Due Next Report',
        minWidth: 90,
        maxWidth: 110,
        onRender: (item: RiskGroup, index?: number) => <RiskTableCell value={item.dueNextReport!} index={index ?? 0} />,
      },
      {
        key: 'Overdue',
        name: 'Overdue',
        minWidth: 60,
        maxWidth: 90,
        onRender: (item: RiskGroup, index?: number) => <RiskTableCell value={item.overdue!} index={index ?? 0} />,
      },
      {
        key: 'PendingClosure',
        name: 'Pending closure',
        minWidth: 80,
        maxWidth: 100,
        onRender: (item: RiskGroup, index?: number) => <RiskTableCell value={item.pendingClosure!} index={index ?? 0} />,
      },
      {
        key: 'Closed',
        name: 'Closed',
        minWidth: 60,
        maxWidth: 100,
        onRender: (item: RiskGroup, index?: number) => <RiskTableCell value={item.closed!} index={index ?? 0} />,
      },
      {
        key: 'Total',
        name: 'Total',
        minWidth: 60,
        maxWidth: 100,
        onRender: (item: RiskGroup) => {
          const total = Object.values(item)
            .filter((value) => typeof value === 'number')
            .reduce((acc, value) => acc + value, 0);
          return <b style={{ marginTop: '10px', paddingLeft: '12px', display: 'block' }}>{total}</b>;
        },
      },
    ],
    [],
  );

  const RiskTableCell = ({ value, index }: { value: number; index: number }) => {
    const fontweight = index === 3 ? 700 : 'inherit';
    return <div style={{ marginTop: '10px', paddingLeft: '12px', fontWeight: fontweight }}>{value}</div>;
  };

  return (
    <div className={boxStyles}>
      <div style={{ flexBasis: '25%', padding: '2.5rem 0 0 1rem' }}>
        <h2 style={{ fontWeight: 600 }}>Risk level status table</h2>
        <span>
          Changing the filters below will be reflected in the table to the right by showing the status by risk levels of POA&Ms that exist within the
          scope of FedRAMP.
        </span>
      </div>
      <div style={{ overflowX: 'hidden', flexGrow: 2, maxWidth: '950px' }}>
        {riskTotals?.risks === undefined || loading ? (
          <div style={{ marginLeft: '25%', marginTop: '5rem', paddingBottom: '8.35rem' }}>
            <ProgressDots />
          </div>
        ) : (
          <>
            <DetailsList
              items={riskTotals.risks}
              columns={columns}
              selectionMode={SelectionMode.none}
              constrainMode={ConstrainMode.horizontalConstrained}
              styles={customTableStyles}
              onRenderDetailsHeader={onRenderDetailsHeader}
            />
            <p>*SLA status based on effective rating</p>
          </>
        )}
      </div>
    </div>
  );
};
