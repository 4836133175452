import { ClientApiBase } from 'generated/clientApiBase';

/**
 * Adapter to use XMLHttpRequest instead of the browser provided window.fetch API.
 * The fetch API does not support monitoring upload progress.
 */
export interface ProgressCallback {
  (loaded: number, total: number): void;
}

export const createFetchWithProgress = (callback: ProgressCallback): { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> } => ({
  fetch: (url: RequestInfo, init?: RequestInit): Promise<Response> =>
    new Promise<Response>((resolve) => {
      if (!init || typeof init.method !== 'string' || typeof url !== 'string') {
        throw new Error('Unable to create HTTP request for system attachment');
      }

      const clientBaseApi = new ClientApiBase();
      Promise.resolve(clientBaseApi.CreateXMLHttpRequest(url, init)).then((request) => {
        const headers = init.headers as Record<string, string>;
        Object.keys(headers).forEach((header) => {
          request.setRequestHeader(header, headers[header]);
        });

        request.onreadystatechange = () => {
          if (request.readyState === XMLHttpRequest.DONE) {
            resolve({
              ok: request.status < 300 && request.status >= 200,
              status: request.status,
              statusText: request.statusText,
              url: request.responseURL,
              text: (): Promise<string> => Promise.resolve(request.responseText),
              headers: new Headers(), // If this becomes important, this needs to be properly set
              redirected: false, // If this becomes important, this needs to be properly set
              type: 'default', // If this becomes important, this needs to be properly set
              body: new ReadableStream(), // If this becomes important, this needs to be properly set
              bodyUsed: false, // If this becomes important, this needs to be properly set
              arrayBuffer: (): Promise<ArrayBuffer> => {
                throw new Error('Not implemented.');
              },
              clone: (): Response => {
                throw new Error('Not implemented.');
              },
              blob: (): Promise<Blob> => {
                throw new Error('Not implemented.');
              },
              formData: (): Promise<FormData> => {
                throw new Error('Not implemented.');
              },
              json: (): Promise<any> => {
                throw new Error('Not implemented.');
              },
            });
          }
        };

        request.upload.onprogress = (event) => {
          callback(event.loaded, event.total);
        };

        request.send(init.body as XMLHttpRequestBodyInit);
      });
    }),
});
