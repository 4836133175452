export enum LoadingState {
  NotLoaded,
  Loading,
  Requested,
  Loaded,
  Error,
}

export const aggregateLoadingState = (loadingStates: LoadingState[]): LoadingState => {
  if (loadingStates.every((x) => x === LoadingState.Loaded)) {
    return LoadingState.Loaded;
  }

  if (loadingStates.some((x) => x === LoadingState.Error)) {
    return LoadingState.Error;
  }

  if (loadingStates.some((x) => x === LoadingState.Loading)) {
    return LoadingState.Loading;
  }

  return LoadingState.NotLoaded;
};
