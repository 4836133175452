import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getCuratedTenant } from 'modules/tenanceGovernance/curatedTenant';
import { LoadingState } from 'models/loadingState';
import { logError } from 'modules/logging/logging';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { IBreadcrumbItem, SelectionMode } from '@fluentui/react';
import { SearchableFilterableList } from 'components/searchableFilterableList/searchableFilterableList';
import { getFormattedDateTime } from 'modules/datetime/datetime';
import { tenantGovernanceRoute } from 'modules/routes/routes';
import { BladeHeader } from '../../components/bladeHeader/bladeHeader';
import { ICuratedTenantMonitoringDataGetResponse } from '../../generated/clientApi';
import { HorizontalRule } from '../../styles/horizontalRule';
import { pageAlignStyles, paragraphStyles, tableAlignStyles } from './tenantGovernance';
import { getTenantGovernanceDetailsColumns } from './tenantGovernanceDetailsColumns';
import {
  getCuratedTenantDetailCompliantStates,
  getCurtatedTenantDetailsDataForDisplay,
  getCuratedTenantDetailNames,
  getCuratedTenantDetailScopes,
  getCuratedTenantDetailViolationTypes,
} from './functions/tenantGovernanceDetails.functions';
import { TenantGovernanceDetailComplianceOverview } from './tenantGovernanceDetailsCompliance';
import { TenantGovernanceDetailsTimeline } from './tenantGovernanceDetailsTimeline';

interface TenantGovernanceDetailsRouteParams {
  tenantId: string;
  monitoringScanId: string;
}

export interface ISortableCuratedTenantDetailDisplay {
  id: string;
  name: string;
  scope: string;
  complianceState: boolean;
  typeOfViolation: string;
}

export interface ISortableCuratedTenantMonitoringDataGetResponse extends ICuratedTenantMonitoringDataGetResponse {
  id: string;
}

export const TenantGovernanceDetails: FunctionComponent = () => {
  const history = useHistory();
  const { tenantId, monitoringScanId } = useParams<TenantGovernanceDetailsRouteParams>();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [curatedTenant, setCuratedTenant] = useState<ISortableCuratedTenantMonitoringDataGetResponse>();
  const [curatedTenantTableDisplayData, setCuratedTenantTableDisplayData] = useState<ISortableCuratedTenantDetailDisplay[]>();
  const [customerName, setCustomerName] = useState<string>();
  const [customerTenantId, setCustomerTenantId] = useState<string>();
  const [tenantName, setTenantName] = useState<string>();
  const [dataLastRefreshed, setDataLastRefreshed] = useState<Date>();
  const [lastDeploymentDate, setLastDeploymentDate] = useState<Date>();
  const [names, setNames] = useState<string[]>([]);
  const [scopes, setScopes] = useState<string[]>([]);
  const [typeOfViolations, setTypeOfViolations] = useState<string[]>([]);
  const [compliantStates, setCompliantStates] = useState<string[]>([]);

  const remapCuratedDataWithIds = (curatedTenantData: ICuratedTenantMonitoringDataGetResponse): ISortableCuratedTenantMonitoringDataGetResponse => {
    const { tenantId } = curatedTenantData;
    const newCuratedTenantData: ISortableCuratedTenantMonitoringDataGetResponse = {
      id: tenantId ?? '',
      ...curatedTenantData,
    };
    return newCuratedTenantData;
  };

  useEffect(() => {
    const fetchTenantDetails = async () => {
      try {
        const curatedTenantDetails = await getCuratedTenant(tenantId, monitoringScanId);

        if (curatedTenantDetails) {
          const curatedTenantDetailsDataForDisplay = getCurtatedTenantDetailsDataForDisplay(curatedTenantDetails);
          if (curatedTenantDetailsDataForDisplay) {
            setCustomerTenantId(curatedTenantDetails.tenantId ?? '');
            setNames(getCuratedTenantDetailNames(curatedTenantDetailsDataForDisplay));
            setScopes(getCuratedTenantDetailScopes(curatedTenantDetailsDataForDisplay));
            setCompliantStates(getCuratedTenantDetailCompliantStates(curatedTenantDetailsDataForDisplay));
            setTypeOfViolations(getCuratedTenantDetailViolationTypes(curatedTenantDetailsDataForDisplay));
            setCuratedTenantTableDisplayData(curatedTenantDetailsDataForDisplay);
          }

          setCustomerName(curatedTenantDetails.customerName ?? '');
          setTenantName(curatedTenantDetails.tenantName ?? '');
          setDataLastRefreshed(curatedTenantDetails.findingDate);
          setLastDeploymentDate(curatedTenantDetails.lastDeploymentDate);
          setCuratedTenant(remapCuratedDataWithIds(curatedTenantDetails));
          setLoadingState(LoadingState.Loaded);
        } else {
          setLoadingState(LoadingState.NotLoaded);
        }
      } catch (error) {
        setLoadingState(LoadingState.Error);
        logError(`There was an issue loading the curated tenant details for tenant with ID ${tenantId} and scan ID ${monitoringScanId}`, error);
      }
    };
    fetchTenantDetails();
  }, [tenantId, monitoringScanId]);

  const tenantGovernanceDetailsBreadcrumbItems: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'Home',
      onClick: () => history.push('/'),
    },
    {
      text: 'Tenant governance',
      key: 'TenantGovernance',
      onClick: () => history.push(tenantGovernanceRoute()),
    },
    {
      text: curatedTenant?.customerName ?? 'Tenant details',
      key: curatedTenant?.customerName ?? 'tenantDetails',
      isCurrentItem: true,
    },
  ];

  return (
    <div className={pageChromeChildStyles}>
      {loadingState !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          <BladeHeader breadcrumbs={tenantGovernanceDetailsBreadcrumbItems} />
          <HorizontalRule />
          <div className={pageAlignStyles}>
            <h2>{customerName}</h2>
            <p className={paragraphStyles}>
              Tenant name: {tenantName}
              <br />
              Tenant ID: {customerTenantId}
              <br />
              Last deployment date: {getFormattedDateTime(lastDeploymentDate)}
              <br />
              Data last refreshed: {getFormattedDateTime(dataLastRefreshed)}
            </p>
            {curatedTenant && <TenantGovernanceDetailComplianceOverview curatedTenant={curatedTenant} />}
            {curatedTenant && <TenantGovernanceDetailsTimeline tenantId={tenantId} />}
            <h2>Policy Compliance Details</h2>
          </div>
          <div className={tableAlignStyles}>
            <SearchableFilterableList
              items={curatedTenantTableDisplayData}
              columns={getTenantGovernanceDetailsColumns(names, scopes, compliantStates, typeOfViolations)}
              selectionMode={SelectionMode.none}
              includeSearchFilterValue={(): boolean => false}
            />
          </div>
        </>
      )}
    </div>
  );
};
