import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

/**
 * A utility hook that parses the current location and returns an object with all query parameters
 * @returns an object containing all of the current query parameters.
 */
export const useQueryParameters = (): qs.ParsedQuery<string | boolean> => {
  const { search } = useLocation();
  return React.useMemo(() => qs.parse(search, { parseBooleans: true }), [search]);
};
