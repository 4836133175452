import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { MessageBar, MessageBarType, Panel, mergeStyles } from '@fluentui/react';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { NotificationId } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { AuthContext } from 'components/authProvider/authContext';
import { NotificationSettingContext } from '../notificationSettingProvider/notificationSettingContext';
import { INotificationItemProps, NotificationItem } from './notificationItem';
import { AUDITOR_ACTIONS_READ, AUTHORIZATION_ACTIONS_READ, COMPLIANCE_TEAM_ACTIONS_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';

const categoryTextStyles = mergeStyles({
  fontWeight: '600',
  margin: '0.5em auto 0 auto',
});

export interface ISettingsPanelProps {
  isOpen: boolean;
  toggleVisibility: () => void;
  headerText?: string;
  secondaryText?: string;
}

const summaryEmails = 'Summary emails';
const realTimeEmails = 'Real-time emails';

export const NotificationsPanel: FunctionComponent<ISettingsPanelProps> = (props) => {
  const notificationSettingContext = useContext(NotificationSettingContext);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);

  useEffect(() => {
    if (props.isOpen) {
      notificationSettingContext.requestNotificationSettings();
    }
  }, [notificationSettingContext, props.isOpen]);

  useEffect(() => {
    setIsLoading(notificationSettingContext.notificationSettingsLoadingState);
  }, [notificationSettingContext.notificationSettingsLoadingState]);

  const isAuditor = authContext.isAuthorized([{ operation: AUDITOR_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isAO = authContext.isAuthorized([{ operation: AUTHORIZATION_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isMultipleRoles = Number(isAuditor) + Number(isAO) + Number(isComplianceTeam) > 1;

  const auditorSummarySettings: INotificationItemProps[] = [
    {
      id: NotificationId.MonthSummaryAuditor,
      isActive: notificationSettingContext.notificationSettings?.monthSummaryAuditor,
    },
    {
      id: NotificationId.WeekSummaryAuditor,
      isActive: notificationSettingContext.notificationSettings?.weekSummaryAuditor,
    },
  ];
  const auditorRealTimeSettings: INotificationItemProps[] = [
    {
      id: NotificationId.PackageSubmitAuditor,
      isActive: notificationSettingContext.notificationSettings?.packageSubmitAuditor,
    },
  ];

  const aoSummarySettings: INotificationItemProps[] = [
    {
      id: NotificationId.MonthSummaryAO,
      isActive: notificationSettingContext.notificationSettings?.monthSummaryAO,
    },
    {
      id: NotificationId.WeekSummaryAO,
      isActive: notificationSettingContext.notificationSettings?.weekSummaryAO,
    },
  ];
  const aoRealTimeSettings: INotificationItemProps[] = [
    {
      id: NotificationId.PackageSubmitAO,
      isActive: notificationSettingContext.notificationSettings?.packageSubmitAO,
    },
  ];

  const cpmSummarySettings = undefined;
  const cpmRealTimeSettings: INotificationItemProps[] = [
    {
      id: NotificationId.PackageApproveCPM,
      isActive: notificationSettingContext.notificationSettings?.packageApproveCPM,
    },
    {
      id: NotificationId.PackageRejectCPM,
      isActive: notificationSettingContext.notificationSettings?.packageRejectCPM,
    },
  ];

  const loadContent = (
    headerText: string,
    summaryEmailSettings: INotificationItemProps[] | undefined,
    realTimeEmailSettings: INotificationItemProps[] | undefined,
  ) => (
    <>
      {isMultipleRoles && <h1 className={categoryTextStyles}>{headerText}</h1>}

      {summaryEmailSettings && <h2 className={categoryTextStyles}>{summaryEmails}</h2>}
      {summaryEmailSettings?.map((notificationSetting) => (
        <NotificationItem key={notificationSetting.id} id={notificationSetting.id} isActive={notificationSetting.isActive} />
      ))}

      {realTimeEmailSettings && <h2 className={categoryTextStyles}>{realTimeEmails}</h2>}
      {realTimeEmailSettings?.map((notificationSetting) => (
        <NotificationItem key={notificationSetting.id} id={notificationSetting.id} isActive={notificationSetting.isActive} />
      ))}
    </>
  );

  return (
    <Panel
      isLightDismiss
      isOpen={props.isOpen}
      onDismiss={props.toggleVisibility}
      closeButtonAriaLabel="Close"
      headerText={props.headerText || 'Notification Settings'}
    >
      <p>{props.secondaryText || 'Select which notifications you would like to receive.'}</p>
      {isLoading === LoadingState.Error ? (
        <MessageBar messageBarType={MessageBarType.error}>There was an issue loading the notification settings.</MessageBar>
      ) : (
        <>
          {isLoading !== LoadingState.Loaded ? (
            <CenteredProgressDots />
          ) : (
            <>
              {isAuditor && loadContent('Auditor', auditorSummarySettings, auditorRealTimeSettings)}
              {isAO && loadContent('AO', aoSummarySettings, aoRealTimeSettings)}
              {isComplianceTeam && loadContent('CPM', cpmSummarySettings, cpmRealTimeSettings)}
            </>
          )}
        </>
      )}
    </Panel>
  );
};
