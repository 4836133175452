import { IImageStyles, Image, ImageFit, mergeStyles, SharedColors } from '@fluentui/react';
import React, { FunctionComponent, useCallback } from 'react';

const cardStyles = mergeStyles({
  boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px 0px rgba(0, 0, 0, 0.14)',
  borderRadius: '8px',
  padding: '2em 3em',
  minHeight: 'calc(430px - 2em)', // subtract the top padding
  maxWidth: 'calc(320px - 6em)', // subtract the left and right padding
  margin: '1em',
  display: 'flex',
  flexDirection: 'column',
});

const imageStyles: Partial<IImageStyles> = {
  image: {
    cursor: 'pointer',
    width: 'auto',
    height: 'auto',
    maxHeight: '192px',
    margin: '2em auto',
  },
};

const linkStyles = mergeStyles({
  marginTop: 'auto',
  textDecoration: 'none',
  color: `${SharedColors.cyan20}`,
  fontWeight: 'bold',
});

export interface InfoCardProps {
  image: string;
  title: string;
  description: string;
  link: string;
  linkText: string;
  openNewTab?: boolean;
}

export const InfoCard: FunctionComponent<InfoCardProps> = (props) => {
  const { image, title, description, link, linkText, openNewTab } = props;

  const goToLink = useCallback(() => {
    window.open(link, openNewTab === true ? '_blank' : '_self');
  }, [link, openNewTab]);

  return (
    <div className={cardStyles}>
      <Image styles={imageStyles} imageFit={ImageFit.contain} src={image} alt={title} onClick={goToLink} />
      <h4>{title}</h4>
      <div>{description}</div>
      <br />
      <a className={linkStyles} href={link} target={openNewTab === true ? '_blank' : '_self'} rel="noreferrer">
        {linkText}
        &nbsp;&gt;
      </a>
    </div>
  );
};
