import { ActionButton } from '@fluentui/react';
import React from 'react';
import { actionButtonStyles } from 'styles/actionButtonStyles';

type ExcludeButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const ExcludeButton: React.FunctionComponent<ExcludeButtonProps> = (props) => {
  const { disabled, onClick } = props;
  const iconName = 'DependencyRemove';

  return (
    <ActionButton className={actionButtonStyles} disabled={disabled} iconProps={{ iconName }} onClick={onClick}>
      Exclude
    </ActionButton>
  );
};
