/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { separatorStyles } from 'pages/conMon/conMon';
import { Separator, mergeStyles, TooltipHost, ITooltipHostStyles, TooltipDelay, DirectionalHint, IconButton } from '@fluentui/react';
import { Tab, TabItem } from './poamDetailsTabs';

export const fieldsStyle = mergeStyles({
  selectors: {
    '& .ms-TextField-fieldGroup': {
      border: 0,
    },
    '& .ms-TextField,.ms-TextField-wrapper,.ms-TextField-fieldGroup,.ms-Dropdown': {
      height: '32px !important',
    },
    '& .ms-TextField-field,.ms-Dropdown-title': {
      height: '32px',
      border: '1px solid #D1D1D1 !important',
      borderRadius: '4px',
      padding: '.3em 1em',
      borderBottom: '1px solid #616161 !important',
    },
    '& .ms-DatePicker-event--without-label,.ms-Dropdown-caretDownWrapper': {
      top: '50%',
      paddingTop: 0,
      transform: 'translateY(-50%)',
    },
  },
});

const tabTitleStyles = mergeStyles({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'flex' } };

type PanelDetailsTabProps = {
  tab: Tab;
};

export const PanelDetailsTab: React.FunctionComponent<PanelDetailsTabProps> = (props) => {
  const { tab } = props;

  const createTooltip = (tabItem: TabItem): ReactElement => (
    <TooltipHost
      tooltipProps={tabItem.tooltip}
      delay={TooltipDelay.zero}
      id={tabItem.title}
      directionalHint={DirectionalHint.bottomRightEdge}
      styles={hostStyles}
    >
      <IconButton style={{ color: '#605E5C', cursor: 'pointer' }} iconProps={{ iconName: 'Info' }}>
        {' '}
      </IconButton>
    </TooltipHost>
  );

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'flex-start',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingLeft: '0.5rem',
        gap: '1.2em',
      }}
    >
      {tab.items.map((tabItem) => (
        <div style={{ display: 'flex', fontSize: '1.1em' }} key={`tabItem-for-${tabItem.title}`}>
          {tabItem.type === 'text' ? (
            <>
              <div className={tabTitleStyles} style={{ flexBasis: '30%' }}>
                <div style={{ color: '#605E5C' }}>{tabItem.title}</div>
                {tabItem.tooltip ? createTooltip(tabItem) : null}
              </div>
              <div style={{ flex: 1 }}>{tabItem.value}</div>
            </>
          ) : tabItem.type === 'separator' ? (
            <div style={{ margin: '0.5em 0', display: 'flex', flexDirection: 'column', flexBasis: '100%' }}>
              <Separator styles={separatorStyles} />
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '100%', gap: '0.25em' }}>
              <div className={tabTitleStyles}>
                <div style={{ color: '#242424', fontSize: '1.25em', fontWeight: 'bold' }}>{tabItem.title}</div>
                {tabItem.tooltip ? createTooltip(tabItem) : null}
              </div>
              <p
                style={{
                  height: 75,
                  overflow: 'auto',
                  background: 'rgb(243, 242, 241)',
                  whiteSpace: 'pre-line',
                  margin: 0,
                  padding: '.1rem .5rem',
                }}
              >
                {tabItem.value}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
