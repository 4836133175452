import { createSspManagerNavLinkGroup } from 'modules/navMenu/sspManagerNavMenu';
import { ICommandBarItemProps, NeutralColors } from '@fluentui/react';
import { DeepReadonly } from 'ts-essentials';
import { IPageChromeConfig, findNavItem } from './pageChrome';

export const createSspManagerChromeConfig = (
  pageId: string,
  commandBarItems?: DeepReadonly<ICommandBarItemProps[]>,
): DeepReadonly<IPageChromeConfig> => {
  const navLinkGroups = [...createSspManagerNavLinkGroup(false)];
  const selectedNavLink = findNavItem(navLinkGroups, pageId);
  return {
    pageId,
    title: 'Home',
    contentBackgroundColor: NeutralColors.white,
    isBlade: true,
    bladeNavGroups: navLinkGroups as any,
    bladePageTitles: [selectedNavLink?.name || ''],
    bladeCommandBarItems: commandBarItems as any,
  };
};
