import { IDropdownOption, PrimaryButton, Spinner, mergeStyles } from '@fluentui/react';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { SearchableDropdown } from 'components/searchDropdown/searchDropdown';
import { UserContext } from 'components/userProvider/userContext';
import { LoadingState } from 'models/loadingState';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { dropdownStyles } from 'styles/dropdownStyles';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';
import { getOrganizationsResponsibleRoles } from 'modules/organization/organization';
import { logError } from 'modules/logging/logging';
import { getCustomerFacingExcel } from 'modules/reports/reports';
import { sortDropdownOptions } from './report.functions';

const buttonStyles = mergeStyles(primaryButtonStyles, {
  margin: '1em 0',
});

const spinnerStyle = mergeStyles({
  marginRight: '5px',
});

export const CustomerFacingReport: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [isDocumentLoading, setIsDocumentLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [systemOptions, setSystemOptions] = useState<IDropdownOption[]>([]);
  const [responsibleRoleOptions, setResponsibleRoleOptions] = useState<IDropdownOption[]>([]);
  const [selectedSystemId, setSelectedSystemId] = useState<string>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const userContext = useContext(UserContext);
  const ALL = 'All';

  useEffect(() => {
    setIsLoading(LoadingState.Requested);
    const fetchSystems = async () => {
      if (!userContext.selectedOrganization) {
        // error?
        return;
      }

      try {
        const responsibleRoles = await getOrganizationsResponsibleRoles(userContext.selectedOrganization.id);
        setSystemOptions(
          sortDropdownOptions(
            userContext.systems.map(
              (system) =>
                ({
                  id: system.id || '',
                  key: system.systemMetadata?.name || '',
                  text: system.systemMetadata?.name || '',
                }) as IDropdownOption,
            ) || [],
          ),
        );

        // Add all to the top of the list
        responsibleRoles.push(ALL);
        setResponsibleRoleOptions(
          sortDropdownOptions(
            responsibleRoles.map(
              (role) =>
                ({
                  id: role || '',
                  key: role || '',
                  text: role || '',
                }) as IDropdownOption,
            ) || [],
          ),
        );
        setIsLoading(LoadingState.Loaded);
      } catch (error) {
        setIsLoading(LoadingState.Error);
      }
    };
    fetchSystems();
  }, [userContext.selectedOrganization, userContext.systems]);

  const onButtonClick = async () => {
    try {
      if (!selectedSystemId) {
        return;
      }

      setIsDocumentLoading(LoadingState.Loading);
      // the 'All' option is a UI option and isn't a valid responsible role.
      const validRoles = selectedRoles.filter((selectedRole) => selectedRole !== ALL);
      await getCustomerFacingExcel(selectedSystemId, validRoles);
      setIsDocumentLoading(LoadingState.Loaded);
    } catch (error) {
      setIsDocumentLoading(LoadingState.Error);
      logError('There was an issue getting the CST word doc', error);
    }
  };

  const onSystemSelected = (ev: any, item: IDropdownOption | undefined): void => {
    if (item) {
      setSelectedSystemId(item.id);
    }
  };

  const onSelectionChanged = (ev: any, item: IDropdownOption | undefined): void => {
    if (!item) {
      return;
    }

    if (item.key === ALL && item.selected) {
      setSelectedRoles(responsibleRoleOptions.flatMap((role) => role.key as string));
    } else if (item.key === ALL && !item.selected) {
      setSelectedRoles([]);
    } else {
      setSelectedRoles((roles) => (item.selected ? [...roles, item.key as string] : roles.filter((key) => key !== item.key)));
    }
  };
  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          <SearchableDropdown
            placeholder="Select an option"
            label="System Security Plans (SSP)"
            options={systemOptions}
            styles={dropdownStyles}
            onChange={onSystemSelected}
          />
          {selectedSystemId && (
            <SearchableDropdown
              placeholder="Select an option"
              label="Responsible Role"
              multiSelect
              selectedKeys={selectedRoles}
              options={responsibleRoleOptions}
              styles={dropdownStyles}
              onChange={onSelectionChanged}
            />
          )}
          <PrimaryButton onClick={onButtonClick} disabled={isDocumentLoading === LoadingState.Loading} className={buttonStyles}>
            {isDocumentLoading === LoadingState.Loading ? (
              <>
                <Spinner className={spinnerStyle} />
                Generating report...
              </>
            ) : (
              <>Generate report</>
            )}
          </PrimaryButton>
        </>
      )}
    </>
  );
};
