import { IDialogContentProps } from '@fluentui/react';
import { Observable, Subject } from 'rxjs';

const subject = new Subject<DialogInputs>();

export interface DialogInputs {
  dialogProps: IDialogContentProps;
  dialogFunction: () => Promise<void> | void;
  dialogBody?: JSX.Element;
  dialogCancelFunction?: () => Promise<void> | void;
  defaultConfirmText?: string;
  dialogMinWidth?: string;
  shouldHideConfirmButton?: boolean;
  defaultCancelText?: string;
}

export const showDialogModal = (
  dialogInputContent: IDialogContentProps,
  dialogInputFunction: () => Promise<void> | void,
  dialogBody?: JSX.Element,
  dialogInputCancelFunction?: () => Promise<void>,
  defaultConfirmText?: string,
  dialogMinWidth?: string,
  shouldHideConfirmButton?: boolean,
  defaultCancelText?: string,
): void =>
  subject.next({
    dialogProps: dialogInputContent,
    dialogFunction: dialogInputFunction,
    dialogBody,
    dialogCancelFunction: dialogInputCancelFunction,
    defaultConfirmText,
    dialogMinWidth,
    shouldHideConfirmButton,
    defaultCancelText,
  });

export const onDialog = (): Observable<DialogInputs> => subject.asObservable();
