import { ICommandBarItemProps, INavLink, INavLinkGroup } from '@fluentui/react';
import { Observable, Subject } from 'rxjs';
import { BreadcrumbItem } from 'components/breadcrumb/breadcrumb';
import { IBladeHeaderButtonConfig } from '../../components/bladeHeader/bladeHeader';
import { DeepReadonly } from '../../components/util/deepReadonly';
import { createHomeNavLinkGroup } from '../navMenu/navMenu';

export interface IPageChromeConfig {
  pageId: string;
  title: string;
  isBlade?: boolean;
  // todo: breadcrumbs go here one day
  bladePageTitles?: string[];
  bladeEntityType?: string;
  bladeIconName?: string;
  bladeNavGroups?: INavLinkGroup[];
  bladeCommandBarItems?: ICommandBarItemProps[];
  contentBackgroundColor?: string;
  bladeButtonInfo?: IBladeHeaderButtonConfig;
  downloadButtonInfo?: IBladeHeaderButtonConfig;
  chatButtonInfo?: IBladeHeaderButtonConfig;
  breadcrumbs?: BreadcrumbItem[];
}

const subject = new Subject<DeepReadonly<IPageChromeConfig>>();

export const updatePageChromeConfig = (pageChromeConfig?: DeepReadonly<IPageChromeConfig>): void => subject.next(pageChromeConfig);

export const onPageChromeConfigChange = (): Observable<DeepReadonly<IPageChromeConfig> | undefined> => subject.asObservable();

export const findNavItem = (navLinkGroups: INavLinkGroup[], key: string): INavLink | undefined => {
  for (let navLinkGroupIndex = 0; navLinkGroupIndex < navLinkGroups.length; ++navLinkGroupIndex) {
    const navLink = navLinkGroups[navLinkGroupIndex].links.find((navLink) => navLink.key === key);
    if (navLink !== undefined) {
      return navLink;
    }
  }

  return undefined;
};

export const createHomeChromeConfig = (
  pageId: string,
  isComplianceTeam: boolean,
  commandBarItems?: DeepReadonly<ICommandBarItemProps[]>,
  contentBackgroundColor?: string,
): DeepReadonly<IPageChromeConfig> => {
  const navLinkGroups = [...createHomeNavLinkGroup(isComplianceTeam, false)];
  const selectedNavLink = findNavItem(navLinkGroups, pageId);
  return {
    pageId,
    title: 'Home',
    contentBackgroundColor: contentBackgroundColor !== undefined ? contentBackgroundColor : '#fafafa',
    isBlade: true,
    bladeNavGroups: navLinkGroups as any,
    bladePageTitles: [selectedNavLink?.name || ''],
    bladeCommandBarItems: commandBarItems as any,
  };
};

export const createAccessDeniedChromeConfig = (): DeepReadonly<IPageChromeConfig> => ({
  pageId: 'accessDenied',
  title: 'Access denied',
  contentBackgroundColor: '#0078D4',
});

export const createEvidenceDetailBladeChromeConfig = (
  title: string,
  displayButton: boolean,
  onBeginReviewButtonClick?: () => void,
  downloadButtonInfo?: IBladeHeaderButtonConfig,
  chatButtonInfo?: IBladeHeaderButtonConfig,
): DeepReadonly<IPageChromeConfig> => {
  const onButtonClick = async () => {
    if (onBeginReviewButtonClick) {
      onBeginReviewButtonClick();
    }
  };

  const buttonInfo: IBladeHeaderButtonConfig = {
    buttonText: 'Begin Review',
    onButtonClick,
  };

  return {
    pageId: 'evidence',
    title: `Home > Service Evidence > ${title}`, // TODO: remove "Evidence > Services " when we get proper breadcrumbs
    isBlade: true,
    bladeButtonInfo: displayButton ? buttonInfo : undefined,
    downloadButtonInfo: downloadButtonInfo ?? undefined,
    chatButtonInfo: chatButtonInfo ?? undefined,
  };
};

export const createChromeConfig = (
  pageId: string,
  title: string,
  isComplianceTeam: boolean,
  commandBarItems?: DeepReadonly<ICommandBarItemProps[]>,
  breadcrumbs?: BreadcrumbItem[],
): DeepReadonly<IPageChromeConfig> => {
  const navLinkGroups = [...createHomeNavLinkGroup(isComplianceTeam, false)];
  const selectedNavLink = findNavItem(navLinkGroups, pageId);
  return {
    pageId,
    title,
    isBlade: true,
    bladeNavGroups: navLinkGroups as any,
    bladePageTitles: [selectedNavLink?.name || ''],
    bladeCommandBarItems: commandBarItems as any,
    breadcrumbs,
  };
};
