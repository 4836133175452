import { Poam } from 'generated/clientApi';
import React from 'react';
import { Label, Text } from '@fluentui/react';

type PanelDetailsOverviewProps = {
  internalPoam?: Poam;
};

const wrapperFlexColumnStyles: React.CSSProperties = {
  display: 'flex',
  padding: '1rem',
  flexDirection: 'column',
  maxWidth: '60%',
  gap: '10px',
};

const flexRowStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const textStyles: React.CSSProperties = {
  maxWidth: '60%',
};

const vulnerabilityDescriptionStyles: React.CSSProperties = {
  overflow: 'auto',
  background: 'rgba(243, 242, 241, 1)',
  maxWidth: '60%',
};

export const PanelDetailsOverview: React.FunctionComponent<PanelDetailsOverviewProps> = (props) => {
  const poamIdLabelId = 'poamIdLabel';
  const environmentLabelId = 'environmentLabel';
  const statusLabelId = 'statusLabel';
  const controlIdLabelId = 'controlIdLabel';
  const vulnerabilityNameLabelId = 'vulnerabilityNameLabel';
  const vulnerabilityDescriptionLabelId = 'vulnerabilityDescriptionLabel';

  return (
    <div style={wrapperFlexColumnStyles}>
      <div style={flexRowStyles}>
        <Label id={poamIdLabelId}>POA&M ID:</Label>
        <Text aria-labelledby={poamIdLabelId} style={textStyles}>
          {props?.internalPoam?.poamId}
        </Text>
      </div>

      <div style={flexRowStyles}>
        <Label id={environmentLabelId}>Environment:</Label>
        <Text aria-labelledby={environmentLabelId} style={textStyles}>
          {props?.internalPoam?.cloudType}
        </Text>
      </div>

      <div style={flexRowStyles}>
        <Label id={statusLabelId}>Status:</Label>
        <Text aria-labelledby={statusLabelId} style={textStyles}>
          {props?.internalPoam?.state}
        </Text>
      </div>

      <div style={flexRowStyles}>
        <Label id={controlIdLabelId}>Control ID:</Label>
        <Text aria-labelledby={controlIdLabelId} style={textStyles}>
          {props?.internalPoam?.controlId}
        </Text>
      </div>

      <div style={flexRowStyles}>
        <Label id={vulnerabilityNameLabelId}>Vulnerability name:</Label>
        <Text aria-labelledby={vulnerabilityNameLabelId} style={textStyles}>
          {props?.internalPoam?.title}
        </Text>
      </div>

      <div style={flexRowStyles}>
        <Label id={vulnerabilityDescriptionLabelId}>Vulnerability Description:</Label>
        <Text aria-labelledby={vulnerabilityDescriptionLabelId} style={vulnerabilityDescriptionStyles}>
          {props?.internalPoam?.description}
        </Text>
      </div>
    </div>
  );
};
