import { IButtonStyles, mergeStyles, NeutralColors, SharedColors } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { FileAttachment } from '../../generated/clientApi';
import { FILE_ATTACHMENT_DELETE, FILE_ATTACHMENT_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { getFormattedDateTime } from '../../modules/datetime/datetime';
import { deleteServiceAttachment, downloadServiceAttachment } from '../../modules/fileAttachment/fileAttachment';
import { AuthContext } from '../authProvider/authContext';
import { BasicList, ListColumn } from '../basicList/basicList';
import { SaveButton } from '../saving/saveButton';

export const nameColumnStyle = mergeStyles({
  display: 'flex',
});

const deleteStyle: IButtonStyles = {
  root: [
    {
      background: SharedColors.red10,
      borderColor: SharedColors.red10,
      color: NeutralColors.white,
    },
  ],
  rootHovered: {
    background: SharedColors.red20,
    color: NeutralColors.white,
  },
};

interface DocumentsTabProps {
  serviceOid: string;
  fileAttachments: FileAttachment[];
  downloadDisabled: boolean;
  columns : FileListColumn[];
  showDelete?: boolean;
  deleteDisabled?: boolean;
  onFileChange?: () => void;
}

export interface FileListColumn extends ListColumn {
  key: string;
  name: string;
  fieldName: string;
  minWidth: number;
  ariaLabel?: string;
  onRender?: (fileAttachment: FileAttachment) => JSX.Element;
  onFileChange?: () => void;
}

export const FileAttachmentTable: FunctionComponent<DocumentsTabProps> = (props) => {
  const { serviceOid, fileAttachments, downloadDisabled, columns, showDelete, deleteDisabled, onFileChange } = props;
  const authContext = useContext(AuthContext);
  const [isDownloading, setIsDownloading] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<string>();

  const downloadButtonClick = useCallback(
    async (fileAttachment: FileAttachment) => {
      if (!fileAttachment.name) {
        return;
      }
      setIsDownloading(fileAttachment.id);
      await downloadServiceAttachment(serviceOid, fileAttachment.id, fileAttachment.name);
      setIsDownloading(undefined);
    },
    [serviceOid],
  );

  const deleteButtonClick = useCallback(
    async (fileAttachment: FileAttachment) => {
      if (!fileAttachment.name) {
        return;
      }
      setIsDeleting(fileAttachment.id);
      await deleteServiceAttachment(serviceOid, fileAttachment.id);
      onFileChange?.();
      setIsDeleting(undefined);
    },
    [onFileChange, serviceOid],
  );

  const internalColumns: ListColumn[] = useMemo(
    () => [
      ...columns,
      {
        key: 'uploadDate',
        name: 'Upload date',
        fieldName: 'uploadDate',
        minWidth: 200,
        ariaLabel: 'Upload Date',
        onRender: (fileAttachment: FileAttachment) => (
          <>{fileAttachment.uploadDate && getFormattedDateTime(fileAttachment?.uploadDate)}</>
        ),
      },
      {
        key: 'downloadButton',
        name: '',
        fieldName: '',
        minWidth: 100,
        ariaLabel: 'Download',
        onRender: (fileAttachment: FileAttachment) => (
          <SaveButton
            defaultText="Download"
            saveText="Downloading..."
            isSaving={isDownloading === fileAttachment.id}
            disabled={
              isDownloading !== undefined ||
              downloadDisabled ||
              !authContext.isAuthorized([{ operation: FILE_ATTACHMENT_READ, subject: SITE_WIDE_SUBJECT }])
            }
            onSave={() => downloadButtonClick(fileAttachment)}
          />
        ),
      },
      showDelete && {
        key: 'deleteButton',
        name: '',
        fieldName: '',
        minWidth: 100,
        ariaLabel: 'Download',
        onRender: (fileAttachment: FileAttachment) => (
          <SaveButton
          styles={deleteStyle}
            defaultText="Delete"
            saveText="Deleting..."
            isSaving={isDeleting === fileAttachment.id}
            disabled={
              isDeleting !== undefined ||
              deleteDisabled ||
              !authContext.isAuthorized([{ operation: FILE_ATTACHMENT_DELETE, subject: SITE_WIDE_SUBJECT }])
            }
            onSave={() => deleteButtonClick(fileAttachment)}
          />
        ),
      },
    ].filter(Boolean) as ListColumn[],
    [columns, showDelete, isDownloading, downloadDisabled, authContext, downloadButtonClick, isDeleting, deleteDisabled, deleteButtonClick],
  );

  return <BasicList items={fileAttachments} columns={internalColumns} notScrollable />;
};
