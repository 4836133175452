import { Dropdown, IDropdownOption } from '@fluentui/react';
import React, { useMemo } from 'react';
import { dropdownStyles } from '../coverageTab/filterBar';

type PeriodFilterProps = {
  periods: string[];
  selectedPeriod: string;
  onPeriodChange: (key: string) => void;
};
export const PeriodFilter: React.FunctionComponent<PeriodFilterProps> = (props) => {
  const { selectedPeriod, periods, onPeriodChange } = props;

  const formatDate = (input: string): string => {
    const [year, month] = input.split('-');
    const date = new Date(Number(year), Number(month) - 1);

    return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
  };
  const periodOptions: IDropdownOption[] = useMemo(() => periods.map((period) => ({ key: period, text: formatDate(period) })), [periods]);

  return (
    <Dropdown
      selectedKey={selectedPeriod}
      options={periodOptions}
      styles={dropdownStyles}
      onChange={(ev, item) => onPeriodChange(item?.key as string)}
    />
  );
};
