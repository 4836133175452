import { ChoiceGroup, IChoiceGroupOption, TextField, mergeStyles } from '@fluentui/react';
import { BasicPoam, Deviation } from 'generated/clientApi';
import React, { useState } from 'react';
import { PoamRiskTable } from './poamRiskTable';

type ManualRiskAdjustmentTabProps = {
  poamSelection: BasicPoam[];
  updateDeviation: (newDeviation: Deviation) => void;
};

export const manualRiskAdjustmentTabStyle = mergeStyles({
  selectors: {
    '& .ms-TextField-fieldGroup': {
      height: '80% !important',
    },
    '& .ms-TextField, .ms-TextField div': {
      height: '100%',
    },
    '& .ms-Label, .is-checked': {
      fontWeight: 'bold',
    },
  },
});

export const ManualRiskAdjustmentTab: React.FunctionComponent<ManualRiskAdjustmentTabProps> = (props) => {
  const { poamSelection, updateDeviation } = props;
  const [riskRating, setRiskRating] = useState<string>('');
  const [justification, setJustification] = useState<string>('');

  const riskLevelOptions: IChoiceGroupOption[] = [
    { key: 'Low', text: 'Low' },
    { key: 'Moderate', text: 'Moderate' },
    { key: 'High', text: 'High' },
  ];

  const riskLevelChange = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
    setRiskRating(option?.key as string);
    updateDeviation({ riskDeviationInfo: { proposedRating: option?.key as string }, justification } as Deviation);
  };

  const justificationChange = (ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, newText: string | undefined): void => {
    setJustification(newText as string);
    updateDeviation({
      riskDeviationInfo: { proposedRating: riskRating },
      justification: newText as string,
    } as Deviation);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <span style={{ fontWeight: 'bold', marginTop: '1.5rem' }}>Please select a risk level and enter a justification.</span>
      <div style={{ display: 'flex', flex: 1 }} className={manualRiskAdjustmentTabStyle}>
        <div style={{ flex: 2 }}>
          <ChoiceGroup options={riskLevelOptions} onChange={riskLevelChange} selectedKey={riskRating} label="Risk level" />
        </div>
        <div style={{ flex: 8, height: '250px', overflow: 'hidden' }}>
          <TextField
            label="Justification"
            multiline
            resizable={false}
            required
            placeholder="Enter a justification"
            onChange={justificationChange}
            value={justification}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginBottom: '1.5rem' }}>
        <span style={{ fontWeight: 'bold' }}>Selected POA&Ms</span>
        <PoamRiskTable poams={poamSelection} />
      </div>
    </div>
  );
};
