import * as React from 'react';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useId, useBoolean } from '@uifabric/react-hooks';
import { Dialog, mergeStyles } from '@fluentui/react';
import { DialogInputs, onDialog } from '../../modules/dialog/dialog';
import { LoadingState } from '../../models/loadingState';
import { PanelFooterButtons } from '../panel/panelFooterButtons';

const dialogStyles = { main: { maxWidth: 450 } };

const footerStyle = mergeStyles({
  display: 'flex',
  justifyContent: 'center',
});

export const DialogModal: FunctionComponent = () => {
  const [dialogInput, setDialogInputs] = useState<DialogInputs>();
  const [loadingState, setLoadingState] = useState(LoadingState.Loaded);
  const location = useLocation();
  const [isHidden, { setFalse: showDialog, setTrue: hideDialog }] = useBoolean(true);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  useEffect(() => {
    const onDialogSubscription = onDialog().subscribe((dialogInputs: DialogInputs) => {
      showDialog();
      setDialogInputs(dialogInputs);
    });

    return () => {
      onDialogSubscription.unsubscribe();
    };
  });

  useEffect(() => {
    hideDialog();
  }, [location, hideDialog]);

  const handleSubmit = async () => {
    setLoadingState(LoadingState.Loading);
    await dialogInput?.dialogFunction();
    setLoadingState(LoadingState.Loaded);
    hideDialog();
  };

  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId],
  );

  const handleDismiss = async () => {
    if (dialogInput?.dialogCancelFunction) {
      await dialogInput?.dialogCancelFunction();
    }
    hideDialog();
  };

  return (
    <Dialog
      hidden={isHidden}
      onDismiss={handleDismiss}
      minWidth={dialogInput?.dialogMinWidth}
      dialogContentProps={dialogInput?.dialogProps}
      modalProps={modalProps}
    >
      {dialogInput && React.isValidElement(dialogInput.dialogBody) && dialogInput.dialogBody}
      <PanelFooterButtons
        shouldHideConfirmButton={dialogInput?.shouldHideConfirmButton}
        defaultConfirmText={dialogInput?.defaultConfirmText ?? 'Yes'}
        defaultCancelText={dialogInput?.defaultCancelText}
        onSave={handleSubmit}
        onCancel={handleDismiss}
        isSaving={loadingState !== LoadingState.Loaded}
        className={footerStyle}
        isConfirmPrimary
      />
    </Dialog>
  );
};
