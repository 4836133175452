import { CommandBar, ICommandBarStyles, mergeStyles } from '@fluentui/react';
import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { IPageChromeConfig, onPageChromeConfigChange } from '../../modules/pageChrome/pageChrome';
import { BladeHeader } from '../bladeHeader/bladeHeader';
import { NavMenu } from '../navMenu/navMenu';
import { DeepReadonly } from '../util/deepReadonly';

export interface IPageChromeProps {
  children?: JSX.Element | JSX.Element[];
  pageChromeConfig?: IPageChromeConfig;
}

const navContentContainerClassName = mergeStyles({
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  flexGrow: 1,
  flexShrink: 1,
  height: '100%',
  minWidth: 0,
});

const navMenuClassName = mergeStyles({
  borderRight: 'solid',
  borderRightColor: '#eee',
  borderRightWidth: 'thin',
});

const actionBarContentContainerClassName = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  flexGrow: 1,
  flexShrink: 1,
  minWidth: 0,
});

const commandStyles = {
  root: {
    boxSizing: 'border-box',
    margin: '0 20px',
    borderBottom: '1px solid #eee',
    height: '36px',
    padding: '0px',
  },
} as ICommandBarStyles;

const contentClassName = mergeStyles({
  flexGrow: 1,
  flexShrink: 1,
  boxSizing: 'border-box',
  minHeight: 0,
  minWidth: 0,
});

const appDisplayName = 'MTAC Portal';

const PageChrome: FunctionComponent<DeepReadonly<IPageChromeProps>> = (props) => {
  const { pageChromeConfig, children } = props;

  const contentStyle =
    pageChromeConfig?.contentBackgroundColor !== undefined
      ? ({
          backgroundColor: pageChromeConfig?.contentBackgroundColor,
        } as CSSProperties)
      : undefined;

  // Editing the title global is ok here, because PageChrome is a singleton
  document.title =
    pageChromeConfig?.title === undefined || pageChromeConfig.title.length === 0 ? appDisplayName : `${pageChromeConfig.title} - ${appDisplayName}`;

  return (
    <>
      <div className={navContentContainerClassName}>
        {pageChromeConfig?.isBlade && pageChromeConfig?.bladeNavGroups && (
          <NavMenu navLinkGroups={pageChromeConfig.bladeNavGroups} selectedLink={pageChromeConfig.pageId} className={navMenuClassName} />
        )}
        <div className={actionBarContentContainerClassName}>
          {pageChromeConfig?.bladeCommandBarItems && <CommandBar items={pageChromeConfig.bladeCommandBarItems as any} styles={commandStyles} />}
          {pageChromeConfig?.isBlade && (
            <BladeHeader
              titleText={pageChromeConfig.title}
              titleTextSuffixes={pageChromeConfig?.bladePageTitles}
              subtitleText={pageChromeConfig?.bladeEntityType}
              iconName={pageChromeConfig?.bladeIconName}
              buttonInfo={pageChromeConfig?.bladeButtonInfo}
              downloadButtonInfo={pageChromeConfig?.downloadButtonInfo}
              chatButtonInfo={pageChromeConfig?.chatButtonInfo}
              breadcrumbs={pageChromeConfig.breadcrumbs}
            />
          )}
          <div className={contentClassName} style={contentStyle}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export interface IPageChromeHostProps {
  children?: JSX.Element | JSX.Element[];
}

export const PageChromeHost: FunctionComponent<DeepReadonly<IPageChromeHostProps>> = (props) => {
  const { children } = props;
  const [pageChromeConfig, setPageChromeConfig] = useState<DeepReadonly<IPageChromeConfig> | undefined>();

  // Listen for new page chrome config from pages
  useEffect(() => {
    const onPageChromeConfigChangeSubscription = onPageChromeConfigChange().subscribe((pageChromeConfig?: DeepReadonly<IPageChromeConfig>) => {
      setPageChromeConfig(pageChromeConfig);
    });

    return () => {
      onPageChromeConfigChangeSubscription.unsubscribe();
    };
  }, []);

  return <PageChrome pageChromeConfig={pageChromeConfig}>{children}</PageChrome>;
};
