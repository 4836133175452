import { NeutralColors, mergeStyles } from '@fluentui/react';
import ReactMarkdown from 'react-markdown';
import React, { useState } from 'react';
import { Accordion } from 'components/accordion/accordion';
import { ISystemSnapshotControlGetResponse, OscalCatalogControl, OscalCatalogPart, OscalCatalogProperty } from '../../generated/clientApi';
import { getPartsFromCatalog } from './controlDetail.functions';

const sectionStyles = mergeStyles({
  marginBottom: '10px',
});

const accordionStyle = {
  root: {
    display: 'flex',
    borderTop: `0.5px solid ${NeutralColors.gray30}`,
    paddingTop: '10px',
    width: '100%',
  },
  icon: {
    margin: 'auto 1em auto auto',
  },
  headerDiv: {
    width: '100%',
  },
  operatorDiv: {
    alignSelf: 'start',
    paddingTop: '28px',
  },
};

const controlDetailsTabKeys = {
  statement: 'statement',
  guidance: 'guidance',
  objective: 'objective',
  assessment: 'assessment',
};

const RecursiveBulletedList = ({ data }: any) => (
  <ul>
    {data?.map((m: any, index: any) => (
      // NOTE: Generally using the key in the index can get you into trouble, however this is a scenario where
      // the data is static, never sorted or filtered, and do not have an Id field. Normally I wouldn't listen
      // to a Medium article, but this one does a good job explaining why this can be a problem and also why
      // it is okay to use in this scenario: https://robinpokorny.medium.com/index-as-a-key-is-an-anti-pattern-e0349aece318
      // eslint-disable-next-line react/no-array-index-key
      <li key={`${index}${m?.prose}`}>
        <span>{m?.prose}</span>
        <span>{m?.parts && m?.parts.length > 0 && <RecursiveBulletedList data={m.parts} />}</span>
      </li>
    ))}
  </ul>
);

interface ControlDetailProps {
  control: OscalCatalogControl;
  systemControl?: ISystemSnapshotControlGetResponse;
}

export const ControlDetail: React.FunctionComponent<ControlDetailProps> = (props) => {
  const { control, systemControl } = props;
  const [isStatementsExpanded, setIsStatementsExpaned] = useState(true);
  const [isGuidanceExpanded, setIsGuidanceExpaned] = useState(true);
  const [isObjectiveExpanded, setIsObjectiveExpaned] = useState(true);
  const [isAssessmentsExpanded, setIsAssessmentsExpaned] = useState(true);

  const renderBulletedListFromPartsProse = (partName: string) => <RecursiveBulletedList data={getPartsFromCatalog(partName, control)} />;

  // Assessments render differently as the assessment source is at the top level props. This is slight departure from the old
  // ATO Accelerator look and feel as per PM direction. Now all assessments are kept under the same pivot item and categorized
  // by the type specified in their top level props.
  const renderAssessments = () => {
    const assessmentParts = getPartsFromCatalog(controlDetailsTabKeys.assessment, control);
    return assessmentParts.map((part: OscalCatalogPart, index: number) => (
      // It is possible that the Id, class, links and ns are null in the CatalogPart as they are not required fields. Therefore, to ensure a unique Id, use the
      // index in the key.
      // eslint-disable-next-line react/no-array-index-key
      <ul key={`${part.name}${index}`}>
        <li>
          <span>{part?.props?.find((prop: OscalCatalogProperty) => prop?.value !== undefined)?.value}</span>
          <span>{part?.prose}</span>
          <RecursiveBulletedList data={part?.parts} />
        </li>
      </ul>
    ));
  };

  const renderSection = (sectionName: string, content: string | JSX.Element) => (
    <div className={sectionStyles}>
      <h2>{sectionName}</h2>
      <>{content}</>
    </div>
  );

  return (
    <>
      <Accordion
        headerElement={<h2>Statement</h2>}
        styles={accordionStyle}
        displayCheckbox={false}
        isExpanded={isStatementsExpanded}
        onExpanded={() => setIsStatementsExpaned(!isStatementsExpanded)}
      >
        {renderBulletedListFromPartsProse(controlDetailsTabKeys.statement)}
      </Accordion>
      <Accordion
        headerElement={<h2>Guidance</h2>}
        styles={accordionStyle}
        displayCheckbox={false}
        isExpanded={isGuidanceExpanded}
        onExpanded={() => setIsGuidanceExpaned(!isGuidanceExpanded)}
      >
        {renderBulletedListFromPartsProse(controlDetailsTabKeys.guidance)}
      </Accordion>
      <Accordion
        headerElement={<h2>Objective</h2>}
        styles={accordionStyle}
        displayCheckbox={false}
        isExpanded={isObjectiveExpanded}
        onExpanded={() => setIsObjectiveExpaned(!isObjectiveExpanded)}
      >
        {renderBulletedListFromPartsProse(controlDetailsTabKeys.objective)}
      </Accordion>
      <Accordion
        headerElement={<h2>Assesment</h2>}
        styles={accordionStyle}
        displayCheckbox={false}
        isExpanded={isAssessmentsExpanded}
        onExpanded={() => setIsAssessmentsExpaned(!isAssessmentsExpanded)}
      >
        {renderAssessments()}
      </Accordion>
      {systemControl && (
        <>
          {systemControl.responsibleRole && renderSection('Responsible Role', systemControl.responsibleRole)}
          {systemControl.implementationStatus && renderSection('Implementation Status', systemControl.implementationStatus)}
          {systemControl.controlOrigination && renderSection('Control Origination', systemControl.controlOrigination)}
          {systemControl.parameters &&
            Object.entries(systemControl.parameters).length > 0 &&
            renderSection(
              'Parameter Overrides',
              <>
                {/* We are getting a dictionary from the API. The parameters come in as a { key1: value1, key2: value2, ... } object.
              The first index is the key and the second is the value */}
                {Object.entries(systemControl.parameters).map((entry: [string, string]) => (
                  <div key={`${entry[0]}-${entry[1]}`}>
                    {entry[0]}
                    :&nbsp;
                    {entry[1]}
                  </div>
                ))}
              </>,
            )}
          {systemControl.markdownContent && <ReactMarkdown>{systemControl.markdownContent}</ReactMarkdown>}
        </>
      )}
    </>
  );
};
