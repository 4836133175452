import React, { useContext, useEffect, useRef } from 'react';
import { mergeStyles, Stack } from '@fluentui/react';
import { ConfigContext } from '../../components/configProvider/configContext';
import { InfoCard } from '../../components/infoCard/infoCard';
import permissionsImage from '../../assets/permissions.png';
import requestImage from '../../assets/requests.png';
// import rolesImage from '../../assets/roles_scopes.png';
import { HeroContentContext } from '../../components/heroContentProvider/heroContentContext';

const heroStyles = mergeStyles({
  fontFamily: 'Segoe UI',
  justifyContent: 'left',
  marginTop: '2em',
  marginBottom: '3.5em',
  boxSizing: 'border-box',
  width: '80%',
});

const labelStyle = mergeStyles({
  fontWeight: '600',
  fontSize: '2.5em',
});

const descriptionStyle = mergeStyles({
  fontWeight: '400',
  fontSize: '1.25em',
});

const cardWrapperStyles = mergeStyles({
  display: 'flex',
  margin: '0 auto',
  width: 'fit-content',
});

const accessManagementHeroContent = (
  <>
    <Stack className={heroStyles}>
      <Stack.Item align="start">
        <span className={labelStyle}>Access Management</span>
      </Stack.Item>
      <Stack.Item align="start">
        <span className={descriptionStyle}>You can manage who has access to roles, permissions and requests.</span>
      </Stack.Item>
    </Stack>
  </>
);

export const AccessManagementPage: React.FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const heroContentContext = useRef(useContext(HeroContentContext));

  useEffect(() => {
    heroContentContext.current.setAndDisplayHeroContent(accessManagementHeroContent, false);
  }, []);

  return (
    <div className={cardWrapperStyles}>
      <InfoCard
        title="Permissions"
        description="Defines what access you have based on what role you are assigned to, add and remove users to existing roles."
        image={permissionsImage}
        link={configContext.serverConfig?.permissionsLink || ''}
        linkText="Manage Permissions"
      />
      {/* <InfoCard
        title="Roles/Scopes"
        description="Roles and scopes are used to define additional layers of access within MTAC's vertical tools. Edit existing roles or create new roles and scopes."
        image={rolesImage}
        link={configContext.serverConfig?.rolesLink || ''}
        linkText="Manage roles"
      /> */}
      <InfoCard
        title="Requests"
        description="Request for new features (feature intake) or request new personas and roles to be created."
        image={requestImage}
        link={configContext.serverConfig?.requestsLink || ''}
        linkText="Manage Requests"
      />
    </div>
  );
};
