export enum Cloud {
  Public = 'Public',
  Fairfax = 'Fairfax',
  USSec = 'USSec',
  USNat = 'USNat',
}

export const getFormattedCloud = (unformattedCloud: string): string => {
  const lowerCaseCloud = unformattedCloud.toLowerCase();
  switch (lowerCaseCloud) {
    case Cloud.Public.toLowerCase():
      return Cloud.Public;
    case Cloud.Fairfax.toLowerCase():
      return Cloud.Fairfax;
    default:
      return unformattedCloud;
  }
};
