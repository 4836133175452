import { CuratedTenantMonitoringDataGetResponseComplianceEvaluation, CuratedTenantMonitoringDataGetResponse } from 'generated/clientApi';
import { ISortableCuratedTenantDetailDisplay } from '../tenantGovernanceDetails';

export const getCuratedTenantDetailNames = (curatedTenantDisplayData: ISortableCuratedTenantDetailDisplay[]): string[] => {
  const tenantNames = curatedTenantDisplayData.map((x) => x.name);
  return findUniqueValuesAndSort(tenantNames);
};

export const getCuratedTenantDetailScopes = (curatedTenantDisplayData: ISortableCuratedTenantDetailDisplay[]): string[] => {
  const tenantCustomerNames = curatedTenantDisplayData.map((x) => x.scope);
  return findUniqueValuesAndSort(tenantCustomerNames);
};

export const getCuratedTenantDetailCompliantStates = (curatedTenantDisplayData: ISortableCuratedTenantDetailDisplay[]): string[] => {
  const tenantCompliantList = curatedTenantDisplayData.map((x) => isCuratedTenantDisplayDataCompliant(x));
  return findUniqueValuesAndSort(tenantCompliantList);
};

export const isCuratedTenantDisplayDataCompliant = (curatedTenantDisplayData: ISortableCuratedTenantDetailDisplay): string =>
  curatedTenantDisplayData.complianceState ? 'Yes' : 'No';

export const getCuratedTenantDetailViolationTypes = (curatedTenantDisplayData: ISortableCuratedTenantDetailDisplay[]): string[] => {
  const tenantCustomerNames = curatedTenantDisplayData.map((x) => x.typeOfViolation);
  return findUniqueValuesAndSort(tenantCustomerNames);
};

const findUniqueValuesAndSort = (curatedTenantDisplayData: string[]): string[] => {
  const uniqueValues = curatedTenantDisplayData.filter((value, index) => curatedTenantDisplayData.indexOf(value) === index);
  return uniqueValues.sort();
};

export const getCurtatedTenantDetailsDataForDisplay = (
  curatedTenantDetails: CuratedTenantMonitoringDataGetResponse,
): ISortableCuratedTenantDetailDisplay[] => {
  let res: ISortableCuratedTenantDetailDisplay[] = [];

  if (curatedTenantDetails.compliances && curatedTenantDetails.compliances.length > 0) {
    curatedTenantDetails.compliances.forEach((compliance) => {
      if (compliance.complianceEvaluations && compliance.complianceEvaluations.length > 0) {
        res = res.concat(getCuratedTenantComplianceEvaluationHelper(compliance.complianceEvaluations));
      }
    });
  }

  return res;
};

const getCuratedTenantComplianceEvaluationHelper = (
  curatedTenantComplianceEvaluations: CuratedTenantMonitoringDataGetResponseComplianceEvaluation[],
): ISortableCuratedTenantDetailDisplay[] =>
  curatedTenantComplianceEvaluations.map(
    (complianceEvaluation) =>
      ({
        id: crypto.randomUUID(),
        name: complianceEvaluation.resourceName ?? '',
        scope: complianceEvaluation.resourceScope ?? '',
        complianceState: complianceEvaluation.isCompliant ?? false,
        typeOfViolation: complianceEvaluation.evaluationType ? upperCaseFirstLetterOfFirstWord(tokenizeCamelCasingString(complianceEvaluation.evaluationType)) : '',
      }) satisfies ISortableCuratedTenantDetailDisplay,
  );

// helper function to tokenize string based on camel casing e.g. "PolicyViolation" -> "Policy Violation"
const tokenizeCamelCasingString = (str: string): string => str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

// helper function to lower case every string of words except for the first work e.g. "Policy Violation" -> "Policy violation"
const upperCaseFirstLetterOfFirstWord = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
