import { Toggle } from '@fluentui/react';
import React from 'react';
import { CoverageFilters } from './useCoverageFilters';

type IncludeToggleProps = {
  isToggleOn: boolean;
  onFilterChange: (key: keyof CoverageFilters, value: string | boolean | undefined) => void;
};
export const IncludeToggle: React.FunctionComponent<IncludeToggleProps> = (props) => {
  const { isToggleOn, onFilterChange } = props;

  return (
    <div style={{ marginLeft: 'auto', width: 'fit-content', marginRight: '-1.08rem', minWidth: '130px' }}>
      <Toggle
        label="Show excluded"
        inlineLabel
        onText=" "
        offText=" "
        onChange={(ev, checked) => onFilterChange('exclude', checked ?? false)}
        checked={isToggleOn}
      />
    </div>
  );
};
