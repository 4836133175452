export enum EvidencePackageStatus {
  Created = 'Created',
  AuditorSubmit = 'Auditor Submit',
  AuditorReview = 'Auditor Review',
  AuditorReturned = 'Auditor Returned',
  AoSubmit = 'AO Submit',
  AoReview = 'AO Review',
  AoReturned = 'AO Returned',
  Approved = 'Authorization Approved',
  AuthorizationApproved = 'Authorization Stage Approved',
  Achieved = 'Authorization Achieved',
  Queued = 'Queued',
  Completed = 'Stage Completed',
}
