import React, { FunctionComponent, useState } from 'react';
import { mergeStyles, PanelType, SelectionMode } from '@fluentui/react';
import { SummaryCount } from 'components/summaryCount/summaryCount';
import { HorizontalRule } from '../../styles/horizontalRule';
import { InformationPane } from '../informationPane/informationPane';
import { BasicList, ListRow, ListColumn } from '../basicList/basicList';
import { Tab } from '../tab/tab';
import { TabPage } from '../tab/tabPage';

export interface ColumnTabWidgetProps {
  title: string;
  columnTabs: ColumnTabsProps[];
  totalTitle: string;
  totalCount: number;
  defaultActiveColumn: number;
  listColumns: ListColumn[];
}

export interface ColumnTabsProps {
  title: string;
  count: number;
  statisticColor: string;
  items: any[];
}

const widgetStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '0 1em 1em',
  margin: '0.5em',
  display: 'flex',
  flexDirection: 'column',
});

const statisticsStyle = mergeStyles({
  display: 'flex',
});

const totalStyle = mergeStyles({
  padding: '0 1em',
  margin: '1em 1em 1em 0',
  fontWeight: 'bold',
  marginLeft: 'auto',
});

const totalLabelStyle = mergeStyles({
  fontSize: '1.5em',
});

const totalCountStyle = mergeStyles({
  fontSize: '2.5em',
  textAlign: 'center',
});

const informationPaneLinkStyle = mergeStyles({
  margin: '0 1em',
  float: 'right',
});

const tableStyle = mergeStyles({
  width: '100%',
  marginTop: '1em',
});

const maxNumberPreviewRows = 5;

export const ColumnTabWidget: FunctionComponent<ColumnTabWidgetProps> = (props) => {
  const { title, columnTabs, totalTitle, totalCount, defaultActiveColumn, listColumns } = props;
  const [activeTabPage, setActiveTabPage] = useState<number>(defaultActiveColumn);

  const createTab = (title: string, items: any[]) => (
    <TabPage key={title} title={title} noMargin>
      <div className={tableStyle}>
        <BasicList items={items.slice(0, maxNumberPreviewRows)} columns={listColumns} selectionMode={SelectionMode.multiple} />
      </div>
    </TabPage>
  );

  const listItems: ListRow[] = [];
  columnTabs.map((column) => listItems.push(...column.items));

  return (
    <div className={widgetStyle}>
      <h3>{title}</h3>
      <div>
        <HorizontalRule />
        <div className={statisticsStyle}>
          {columnTabs.map((column) => (
            <SummaryCount color={column.statisticColor} title={column.title} count={column.count} />
          ))}
          <div className={totalStyle}>
            <div className={totalLabelStyle}>{totalTitle}</div>
            <div className={totalCountStyle}>{totalCount}</div>
          </div>
        </div>
        <HorizontalRule />
        <Tab activePage={activeTabPage} onPageUpdated={setActiveTabPage}>
          {columnTabs.map((column) => createTab(column.title, column.items))}
        </Tab>
        <div className={informationPaneLinkStyle}>
          <InformationPane
            text="View all services status"
            isPanelBlocking={false}
            panelHeader="All services authorization status"
            panelType={PanelType.large}
          >
            <BasicList items={listItems} columns={listColumns} />
          </InformationPane>
        </div>
      </div>
    </div>
  );
};
