import { DatePicker, mergeStyles, Dropdown, IDropdownOption, defaultDatePickerStrings, TextField } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { AuthContext } from 'components/authProvider/authContext';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { Tab } from 'components/tab/tab';
import { TabPage } from 'components/tab/tabPage';
import { UserContext } from 'components/userProvider/userContext';
import { WizardNavigation } from 'components/wizardNavigation/wizardNavigation';
import { SystemPostCommand } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { getBaselines } from 'modules/baselines/baselines';
import { SITE_WIDE_SUBJECT, SYSTEM_CREATE } from 'modules/constants';
import { formatDateInternationalStandard, parseDate } from 'modules/datetime/datetime';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { getOrganization, getOrganizationControlsBases } from 'modules/organization/organization';
import { systemDetailRoute } from 'modules/routes/routes';
import { createSystem } from 'modules/system/system';
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { datePickerStyles } from 'styles/datePickerStyles';
import { dropdownStyles } from 'styles/dropdownStyles';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { textFieldStyles } from 'styles/textFieldStyles';

const messageDismissSeconds = 5;

const formWrapperStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2em',
});

const dropdownStyle = mergeStyles({
  marginBottom: '2em',
});

const readonlyWrapperStyles = mergeStyles({
  display: 'flex',
  margin: '1em 0',
});

const fieldLabelStyles = mergeStyles({
  fontWeight: '600',
  minWidth: '300px',
  marginRight: '1em',
});

enum SystemCreateTabs {
  Information,
  Review,
}

export enum SystemFieldLabels {
  Organization = 'Organization',
  Name = 'Name',
  Description = 'Description',
  Manager = 'Manager',
  Custodian = 'Custodian',
  Authorizer = 'Authorizer',
  AuthorizationDate = 'Authorization date',
  ReauthorizationCadence = 'Reauthorization cadence',
  ControlCatalog = 'Control catalog',
  Cloud = 'Cloud',
  ControlsBasePath = 'Controls Base Path',
}

const sortDropdownOptions = (dropdownOptions: IDropdownOption[]) => dropdownOptions.sort((a, b) => a.text.localeCompare(b.text)) as IDropdownOption[];

export const SystemCreate: FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const authContext = useContext(AuthContext);
  const [isSaving, { setTrue: setSaving, setFalse: setNotSaving }] = useBoolean(false);
  const [fieldsValidated, setFieldsValidated] = useState<boolean>(false);
  const [system, setSystem] = useState<SystemPostCommand>({
    organizationId: userContext.selectedOrganization?.id,
  } as SystemPostCommand);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [baselineDropdownOptions, setBaselineDropdownOptions] = useState([] as IDropdownOption[]);
  const [platformDropdownOptions, setPlatformDropdownOptions] = useState([] as IDropdownOption[]);
  const [controlsBasePathDropdownOptions, setControlsBasePathDropdownOptions] = useState([] as IDropdownOption[]);
  const [activeTabPage, setActiveTabPage] = useState<SystemCreateTabs>();
  const userAuthorizedToCreateSystems = authContext.isAuthorized([{ operation: SYSTEM_CREATE, subject: SITE_WIDE_SUBJECT }]);

  const history = useHistory();

  useEffect(() => {
    const loadOrganizationData = async () => {
      try {
        if (!userContext.selectedOrganization?.id) {
          return;
        }
        setIsLoading(LoadingState.Loading);
        // TODO: Move to functions Bug #24940073
        const [organization, baselines, controlsBases] = await Promise.all([
          getOrganization(userContext.selectedOrganization.id),
          getBaselines(),
          getOrganizationControlsBases(userContext.selectedOrganization.id),
        ]);
        setBaselineDropdownOptions(
          sortDropdownOptions(baselines.map((baseline) => ({ key: baseline.id, text: baseline.name })) as IDropdownOption[]),
        );
        setPlatformDropdownOptions(
          sortDropdownOptions(
            organization?.platforms?.map((baseline) => ({ key: baseline.code ?? '', text: baseline.text ?? '' })) ?? ([] as IDropdownOption[]),
          ),
        );
        setControlsBasePathDropdownOptions(
          sortDropdownOptions(controlsBases.controlsBasePaths?.map((path) => ({ key: path, text: path })) ?? ([] as IDropdownOption[])),
        );
        setIsLoading(LoadingState.Loaded);
      } catch (error) {
        setIsLoading(LoadingState.Error);
        showError('There was an issue loading the snapshots. Please refresh and try again.');
      }
    };
    loadOrganizationData();
  }, [userContext.selectedOrganization?.id]);

  useEffect(() => {
    if (userContext.selectedOrganization) {
      setSystem((prevSys) => ({ ...prevSys, organizationId: userContext.selectedOrganization?.id }) as SystemPostCommand);
    }
  }, [userContext.selectedOrganization]);

  // TODO: Move to functions Bug #24940073
  useMemo(() => {
    const currentValidationErrors = [];
    if (!system?.systemMetadata?.name?.trim().length) {
      currentValidationErrors.push(SystemFieldLabels.Name);
    }

    if (!system?.systemParameters?.complianceRegime?.trim().length) {
      currentValidationErrors.push(SystemFieldLabels.ControlCatalog);
    }

    if (!system?.systemParameters?.platform?.trim().length) {
      currentValidationErrors.push(SystemFieldLabels.Cloud);
    }

    if (!system?.systemParameters?.controlsBase?.trim().length) {
      currentValidationErrors.push(SystemFieldLabels.ControlsBasePath);
    }

    setFieldsValidated(currentValidationErrors.length === 0);
  }, [system]);

  const onSave = async () => {
    try {
      setSaving();
      if (!userAuthorizedToCreateSystems) {
        showError('You do not have permission to create systems.');
        return;
      }
      if (!system?.organizationId) {
        showError('The system does not have an Organization Id and cannot be saved.');
        return;
      }
      const createdSystem = await createSystem(system.organizationId, system);
      showSuccess('Successfully saved system.', messageDismissSeconds);
      history.push(systemDetailRoute({ systemId: createdSystem.id }));
    } catch (error) {
      showError('There was an issue saving the system. Please refresh and try again.');
    } finally {
      setNotSaving();
    }
  };

  const onSystemMetadataChange = (event: any, newValue: any): void => {
    const { name } = event.target;
    setSystem((prevSys) => ({ ...prevSys, systemMetadata: { ...prevSys?.systemMetadata, [name]: newValue } }) as SystemPostCommand);
  };

  const onSystemParametersChange = (fieldName: string, value: any): void =>
    setSystem((prevSys) => ({ ...prevSys, systemParameters: { ...prevSys?.systemParameters, [fieldName]: value } }) as SystemPostCommand);
  const onComplianceRegimeChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void =>
    onSystemParametersChange('complianceRegime', option?.key);
  const onPlatformChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void =>
    onSystemParametersChange('platform', option?.key);
  const onControlsBaseChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void =>
    onSystemParametersChange('controlsBase', option?.key);
  const onAuthorizationDateChange = (date: Date | null | undefined) =>
    date &&
    setSystem(
      (prevSys) =>
        ({
          ...prevSys,
          systemMetadata: { ...prevSys?.systemMetadata, authorizationDate: formatDateInternationalStandard(date) },
        }) as SystemPostCommand,
    );

  const ReadonlyField: FunctionComponent<{ label: string; value: string }> = (props) => (
    <div className={readonlyWrapperStyles}>
      <div className={fieldLabelStyles}>{props.label}</div>
      {props.value}
    </div>
  );

  const InformationTabContent = (
    <div className={formWrapperStyles}>
      <p>
        Please fill out the information and parameters of your new system for organization: &nbsp;
        <b>{userContext.selectedOrganization?.name}</b>
      </p>
      <h3>Information</h3>
      <TextField
        styles={textFieldStyles}
        label={SystemFieldLabels.Name}
        value={system.systemMetadata?.name}
        name="name"
        required
        onChange={onSystemMetadataChange}
      />
      <TextField
        styles={textFieldStyles}
        label={SystemFieldLabels.Description}
        value={system.systemMetadata?.description}
        name="description"
        onChange={onSystemMetadataChange}
        multiline
      />
      <TextField
        styles={textFieldStyles}
        label={SystemFieldLabels.Manager}
        value={system.systemMetadata?.manager}
        name="manager"
        onChange={onSystemMetadataChange}
      />
      <TextField
        styles={textFieldStyles}
        label={SystemFieldLabels.Custodian}
        value={system.systemMetadata?.custodian}
        name="custodian"
        onChange={onSystemMetadataChange}
      />
      <TextField
        styles={textFieldStyles}
        label={SystemFieldLabels.Authorizer}
        value={system.systemMetadata?.authorizer}
        name="authorizer"
        onChange={onSystemMetadataChange}
      />
      <DatePicker
        label={SystemFieldLabels.AuthorizationDate}
        allowTextInput
        ariaLabel="Select a date"
        value={parseDate(system.systemMetadata?.authorizationDate)}
        styles={datePickerStyles}
        placeholder="Select a date"
        formatDate={formatDateInternationalStandard}
        onSelectDate={onAuthorizationDateChange}
        strings={defaultDatePickerStrings}
      />
      <TextField
        styles={textFieldStyles}
        label={SystemFieldLabels.ReauthorizationCadence}
        value={system.systemMetadata?.reauthorizationCadence}
        name="reauthorizationCadence"
        onChange={onSystemMetadataChange}
      />
      <h3>Parameters</h3>
      <Dropdown
        label={SystemFieldLabels.ControlCatalog}
        options={baselineDropdownOptions}
        placeholder="Select an option"
        required
        selectedKey={system.systemParameters?.complianceRegime}
        className={dropdownStyle}
        styles={dropdownStyles}
        dropdownWidth="auto"
        onChange={onComplianceRegimeChange}
      />
      <Dropdown
        label={SystemFieldLabels.Cloud}
        options={platformDropdownOptions}
        placeholder="Select an option"
        required
        selectedKey={system.systemParameters?.platform}
        className={dropdownStyle}
        styles={dropdownStyles}
        dropdownWidth="auto"
        onChange={onPlatformChange}
      />
      <Dropdown
        label={SystemFieldLabels.ControlsBasePath}
        options={controlsBasePathDropdownOptions}
        placeholder="Select an option"
        required
        className={dropdownStyle}
        styles={dropdownStyles}
        dropdownWidth="auto"
        onChange={onControlsBaseChange}
      />
      {/* TODO: We do not have the concept of front matter yet - once we implement that, we can enable this dropdown */}
      {/* <Dropdown
      label="Front Matter"
      placeholder="Select an option"
      required
      styles={dropdownStyles}
      onChange={onControlsBaseChange}
    /> */}
      <WizardNavigation
        isNextVisible
        isNextDisabled={!fieldsValidated}
        isNextLoading={false}
        isNextTitle={!fieldsValidated ? 'Fill out the required fields before continuing' : 'Go to review page'}
        isPreviousVisible
        previousButtonText="Cancel"
        nextButtonText="Next: Review"
        onClickPreviousButton={() => history.goBack()}
        onClickNextButton={() => setActiveTabPage(SystemCreateTabs.Review)}
      />
    </div>
  );

  const ReviewTabContent = (
    <div className={formWrapperStyles}>
      <p>
        Please review the information and parameters of your new system for organization: &nbsp;
        <b>{userContext.selectedOrganization?.name}</b>
      </p>
      <h3>Information</h3>
      <ReadonlyField label={SystemFieldLabels.Name} value={system.systemMetadata?.name || ''} />
      <ReadonlyField label={SystemFieldLabels.Description} value={system.systemMetadata?.description || ''} />
      <ReadonlyField label={SystemFieldLabels.Manager} value={system.systemMetadata?.manager || ''} />
      <ReadonlyField label={SystemFieldLabels.Custodian} value={system.systemMetadata?.custodian || ''} />
      <ReadonlyField label={SystemFieldLabels.Authorizer} value={system.systemMetadata?.authorizer || ''} />
      <ReadonlyField label={SystemFieldLabels.AuthorizationDate} value={system.systemMetadata?.authorizationDate || ''} />
      <ReadonlyField label={SystemFieldLabels.ReauthorizationCadence} value={system.systemMetadata?.reauthorizationCadence || ''} />
      <h3>Parameters</h3>
      <ReadonlyField label={SystemFieldLabels.ControlCatalog} value={system.systemParameters?.complianceRegime || ''} />
      <ReadonlyField label={SystemFieldLabels.Cloud} value={system.systemParameters?.platform || ''} />
      <ReadonlyField label={SystemFieldLabels.ControlsBasePath} value={system.systemParameters?.platform || ''} />
      {/* TODO: Add front matter in follow on story */}
      <WizardNavigation
        isNextVisible
        isNextDisabled={isSaving}
        isNextLoading={isSaving}
        isPreviousVisible
        nextButtonText="Create system"
        onClickPreviousButton={() => setActiveTabPage(SystemCreateTabs.Information)}
        onClickNextButton={() => onSave()}
      />
    </div>
  );

  if (isLoading === LoadingState.Loading) return <CenteredProgressDots />;
  return (
    <div className={pageChromeChildStyles}>
      <h2>Create new system</h2>
      <Tab activePage={activeTabPage} onPageUpdated={setActiveTabPage}>
        <TabPage title="Information">{InformationTabContent}</TabPage>
        <TabPage title="Review + create">{ReviewTabContent}</TabPage>
      </Tab>
    </div>
  );
};
