import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { IOffering } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { logError } from '../../modules/logging/logging';
import { getOfferings } from '../../modules/offering/offering';
import { OfferingContext, OfferingContextProps } from './offeringContext';

export const OfferingProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [offerings, setOfferings] = useState<IOffering[] | undefined>(undefined);
  const [offeringsLoaded, setOfferingsLoaded] = useState<LoadingState>(LoadingState.NotLoaded);

  const contextProps: OfferingContextProps = {
    offerings,
    offeringsLoadingState: offeringsLoaded,
    requestOfferings: () => {
      if (offeringsLoaded === LoadingState.NotLoaded || offeringsLoaded === LoadingState.Error) {
        setOfferingsLoaded(LoadingState.Requested);
      }
    },
  };

  // Auto grab the offerings if someone requests them
  useEffect(() => {
    const getAllOfferings = async () => {
      try {
        const allOfferings = await getOfferings();
        setOfferings(allOfferings);
        setOfferingsLoaded(LoadingState.Loaded);
      } catch (error) {
        setOfferingsLoaded(LoadingState.Error);
        logError('There was an issue loading the offerings', error);
      }
    };

    if (offeringsLoaded === LoadingState.Requested && offerings === undefined) {
      getAllOfferings();
    }
  }, [offeringsLoaded, offerings]);

  return <OfferingContext.Provider value={contextProps}>{children}</OfferingContext.Provider>;
};
