import React, { FunctionComponent, useContext, useState } from 'react';
import { MessageBar, MessageBarType, Spinner, SpinnerSize, Toggle, mergeStyles } from '@fluentui/react';
import { getNotificationDescription, getNotificationHeader } from 'models/notificationSettingDetails';
import { NotificationId, NotificationSettingsPutCommand } from 'generated/clientApi';
import { putNotificationSettings } from 'modules/notificationSetting/notificationSetting';
import { NotificationSettingContext } from 'components/notificationSettingProvider/notificationSettingContext';

const notificationItemStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: 'auto auto auto 0.5em',
});

const notificationDetailsStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto 1em auto auto',
});

const notificationHeaderStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'row',
});

const headerTextStyles = mergeStyles({
  fontWeight: '600',
  margin: '0.5em 1em 0 0',
});

const descriptionTextStyles = mergeStyles({
  margin: 'auto auto 1em 1em',
});

export interface INotificationItemProps {
  id: NotificationId | undefined;
  isActive: boolean | undefined;
}

export const NotificationItem: FunctionComponent<INotificationItemProps> = (props) => {
  const notificationSettingContext = useContext(NotificationSettingContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showError, setShowError] = useState(false);

  const toggleNotificationSetting = async () => {
    setShowSpinner(true);
    setShowError(false);

    const putCommand = { id: props.id, isActive: !props.isActive } as NotificationSettingsPutCommand;
    try {
      const response = await putNotificationSettings(putCommand);
      notificationSettingContext.updateNotificationSettings(response);
    } catch {
      setShowError(true);
    }

    setShowSpinner(false);
  };

  return (
    <>
      <div className={notificationItemStyles}>
        <div className={notificationDetailsStyles}>
          <div className={notificationHeaderStyles}>
            <h3 className={headerTextStyles}>{getNotificationHeader(props.id as NotificationId)}</h3>
            {showSpinner && <Spinner size={SpinnerSize.small} />}
          </div>
          <p className={descriptionTextStyles}>{getNotificationDescription(props.id as NotificationId)}</p>
        </div>
        <Toggle id={`${props.id}`} checked={props.isActive} onChange={toggleNotificationSetting} />
      </div>
      {showError && <MessageBar messageBarType={MessageBarType.error}>An issue occurred while updating the setting.</MessageBar>}
    </>
  );
};
