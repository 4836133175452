import { DefaultButton, mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import { defaultButtonStyles } from 'styles/defaultButtonStyles';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';
import { SaveButton } from '../saving/saveButton';

const buttonStyle = mergeStyles(primaryButtonStyles, {
  marginRight: '18px',
});

interface PanelFooterButtonsProps {
  isSaving: boolean;
  defaultConfirmText: string;
  className?: string;
  saveDisabled?: boolean;
  saveText?: string;
  saveTitle?: string;
  onSave(): void;
  onCancel?(): void;
  defaultCancelText?: string;
  shouldHideConfirmButton?: boolean;
  isConfirmPrimary?: boolean;
}

export const PanelFooterButtons: FunctionComponent<PanelFooterButtonsProps> = (props) => {
  PanelFooterButtons.defaultProps = {
    saveText: 'Saving...',
    saveTitle: '',
    className: undefined,
    saveDisabled: false,
  };
  const {
    shouldHideConfirmButton,
    saveDisabled,
    isSaving,
    className,
    defaultConfirmText,
    defaultCancelText,
    saveText,
    saveTitle,
    onSave,
    onCancel,
    isConfirmPrimary,
  } = props;
  return (
    <div className={className} style={{ display: 'flex', justifyContent: shouldHideConfirmButton ? 'right' : 'normal' }}>
      {!shouldHideConfirmButton && (
        <SaveButton
          disabled={saveDisabled}
          isSaving={isSaving}
          onSave={onSave}
          className={buttonStyle}
          defaultText={defaultConfirmText}
          saveText={saveText}
          title={saveTitle}
          isPrimary={isConfirmPrimary === true}
        />
      )}
      {onCancel !== undefined ? (
        <DefaultButton className={defaultButtonStyles} onClick={onCancel} text={defaultCancelText || 'Cancel'} disabled={isSaving} />
      ) : (
        <></>
      )}
    </div>
  );
};
