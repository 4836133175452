import {
  ActionButton,
  Dropdown,
  IColumn,
  IDropdownOption,
  IDropdownStyles,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  SelectionMode,
  ShimmeredDetailsList,
  TextField,
} from '@fluentui/react';
import { CoverageCompareGetClient, Coverage, ScanCoverageSearchVulnGetClient, ScanCoverageSearchVulnGetResponse } from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import React, { useContext, useMemo, useState } from 'react';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { pivotClass, pivotStyles } from 'styles/pivot';
import { belowCoverageBgColor } from './coverageTable';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 125 },
  dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
  dropdownItem: { height: 'auto' },
  title: { height: 28, paddingTop: '2px' },
  root: {
    '.ms-Dropdown': {
      height: '28px',
    },
  },
};

type ComparePanelProps = {
  period: string;
  selectedCoverage?: Coverage;
  isCurrentPeriodLocked: boolean;
  close: () => void;
  onUpdateSelection: (item: Coverage, selectedDay: number) => void;
};

export const ComparePanel: React.FunctionComponent<ComparePanelProps> = (props) => {
  const { selectedCoverage, close, period, onUpdateSelection, isCurrentPeriodLocked } = props;
  const { organization } = useContext(ConMonFilterContext);

  const currentSelectedDay = useMemo(
    () => selectedCoverage?.userSelectedDay || selectedCoverage?.systemSelectedDay,
    [selectedCoverage?.systemSelectedDay, selectedCoverage?.userSelectedDay],
  );
  const [isLoading, setLoading] = useState<boolean>();
  const [day1, setDay1] = useState<number>(currentSelectedDay ?? 1);
  const [day2, setDay2] = useState<number>(0);
  const [day1Vulns, setDay1Vulns] = useState<Set<string>>(new Set());
  const [day2Vulns, setDay2Vulns] = useState<Set<string>>(new Set());
  const [vulnIds, setVulnIds] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [lastUpdatedSelection, setLastUpdatedSelection] = useState(0);

  const [searchText, setSearchText] = useState<string>('');
  const [searchResponse, setSearchResponse] = useState<ScanCoverageSearchVulnGetResponse>();
  const [included, notIncluded, unknown] = useMemo(() => {
    if (searchResponse === undefined) {
      return [new Set<string>(), new Set<string>(), new Set<string>()];
    }
    const included = new Set<string>(searchResponse.included ?? []);
    const notIncluded = new Set<string>(searchResponse.notIncluded ?? []);
    const unknown = new Set<string>(searchResponse.unknown ?? []);
    return [included, notIncluded, unknown];
  }, [searchResponse]);

  const daysInMonth = useMemo(() => {
    const maxDay = selectedCoverage?.days?.reduce((maxDay, currentDay) => (currentDay.day! > maxDay.day! ? currentDay : maxDay)).day ?? 0;
    const dropdownOptions: IDropdownOption[] = [];
    for (let i = 1; i <= maxDay; i++) {
      dropdownOptions.push({ text: `${i}`, key: i });
    }
    return dropdownOptions;
  }, [selectedCoverage?.days]);

  const infoElement = (title: string, value: string) => (
    <div style={{ marginTop: '1rem', display: 'flex' }}>
      <div style={{ fontSize: '1rem', fontWeight: 600, minWidth: '175px' }}>{title}</div>
      <div style={{ fontSize: '1rem', fontWeight: 600 }}>{value}</div>
    </div>
  );

  const fetchComparison = async () => {
    setLoading(true);
    const client = new CoverageCompareGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(
        period,
        organization,
        selectedCoverage?.serviceTreeName,
        selectedCoverage?.cloudType,
        selectedCoverage?.assetType,
        day1,
        day2,
      );
      const set1 = new Set(response.vuln1?.map((vuln) => vuln.vulnId ?? ''));
      const set2 = new Set(response.vuln2?.map((vuln) => vuln.vulnId ?? ''));

      const onlyInSet1 = Array.from(set1).filter((item) => !set2.has(item));
      const intersection = Array.from(set1).filter((item) => set2.has(item));
      const onlyInSet2 = Array.from(set2).filter((item) => !set1.has(item));

      const combined = [...onlyInSet1, ...onlyInSet2, ...intersection];

      setDay1Vulns(set1);
      setDay2Vulns(set2);
      setVulnIds(combined);
    } catch (e) {
      setErrorMessage('There was an error fetching comparison. Please refresh to try again.');
    } finally {
      setLoading(false);
    }
  };
  const columns: IColumn[] = useMemo(
    () => [
      {
        key: 'vuln1',
        name: `Vulns for day ${day1} (${day1Vulns.size})`,
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: string) => {
          if (day1Vulns.has(item)) {
            return <span>{item}</span>;
          }
          return <div style={{ background: belowCoverageBgColor, height: '100%', width: '65px' }} />;
        },
      },
      {
        key: 'vuln2',
        name: `Vulns for day ${day2} (${day2Vulns.size})`,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: string) => {
          if (day2Vulns.has(item)) {
            return <span>{item}</span>;
          }
          return <div style={{ background: belowCoverageBgColor, height: '100%', width: '65px' }} />;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [day1Vulns, day2Vulns],
  );

  const searchByVuln = async () => {
    setLoading(true);
    const client = new ScanCoverageSearchVulnGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(
        period,
        organization,
        selectedCoverage?.serviceTreeName,
        selectedCoverage?.cloudType,
        selectedCoverage?.assetType,
        searchText,
      );
      setSearchResponse(response);
    } catch (e) {
      setErrorMessage('There was an error searching by vuln ID. Please refresh to try again.');
    } finally {
      setLoading(false);
    }
  };

  const searchColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'Included',
        name: 'Included',
        minWidth: 150,
        maxWidth: 150,
        onRender: (item: string) => {
          if (included.has(item)) {
            return <span>{item}</span>;
          }
          return <></>;
        },
      },
      {
        key: 'NotIncluded',
        name: 'Not included',
        minWidth: 150,
        maxWidth: 150,
        onRender: (item: string) => {
          if (notIncluded.has(item)) {
            return <span>{item}</span>;
          }
          return <></>;
        },
      },
      {
        key: 'unknown',
        name: 'Unknown',
        minWidth: 150,
        maxWidth: 150,
        onRender: (item: string) => {
          if (unknown.has(item)) {
            return <span>{item}</span>;
          }
          return <></>;
        },
      },
    ],
    [included, notIncluded, unknown],
  );

  if (!selectedCoverage) {
    return <></>;
  }
  return (
    <Panel
      isOpen
      onDismiss={close}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      headerText="Analyze vulnerabilities across days for the following service asset type"
      className={sideModalPoamStyles}
    >
      {errorMessage && (
        <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setErrorMessage(undefined)} dismissButtonAriaLabel="Dismiss">
          {errorMessage}
        </MessageBar>
      )}
      <div style={{ marginTop: '3.5rem' }}>
        {infoElement('Service tree name: ', selectedCoverage.serviceTreeName)}
        {infoElement('Asset type: ', selectedCoverage.assetType)}
        {infoElement('Cloud type: ', selectedCoverage.cloudType)}
      </div>
      <Pivot className={pivotClass} styles={pivotStyles} style={{ marginTop: '2rem' }}>
        <PivotItem headerText="Compare">
          <div style={{ display: 'flex', gap: '3rem', marginTop: '2rem', marginBottom: '2rem' }}>
            <Dropdown
              label="Selected date"
              styles={dropdownStyles}
              defaultSelectedKey={currentSelectedDay}
              options={daysInMonth}
              onChange={(ev, option) => setDay1(option?.key as number)}
            />
            <div>
              <Dropdown
                label="Day to compare"
                style={{ marginBottom: '.5rem' }}
                styles={dropdownStyles}
                options={daysInMonth}
                onChange={(ev, option) => setDay2(option?.key as number)}
              />
              {!isCurrentPeriodLocked && (
                <ActionButton
                  className={actionButtonStyles}
                  disabled={day2 === lastUpdatedSelection}
                  iconProps={{ iconName: 'NavigateForward' }}
                  onClick={() => {
                    onUpdateSelection(selectedCoverage, day2);
                    setLastUpdatedSelection(day2);
                  }}
                >
                  Update selection
                </ActionButton>
              )}
            </div>
          </div>
          <PrimaryButton
            style={{ width: 'fit-content', height: '32px', borderRadius: '4px' }}
            onClick={fetchComparison}
            disabled={day1 === 0 || day2 === 0}
          >
            Compare
          </PrimaryButton>
          {isLoading !== undefined && (
            <ShimmeredDetailsList items={vulnIds} columns={columns} enableShimmer={isLoading} shimmerLines={20} selectionMode={SelectionMode.none} />
          )}
        </PivotItem>
        <PivotItem headerText="Search">
          <div style={{ marginTop: '2rem', marginBottom: '2.5rem', width: 'fit-content' }}>
            <TextField
              label="Vulnerability ID"
              required
              placeholder="Enter a vuln ID"
              onChange={(ev, newValue) => setSearchText(newValue ?? '')}
              value={searchText}
            />
          </div>
          <PrimaryButton
            style={{ width: 'fit-content', height: '32px', borderRadius: '4px' }}
            onClick={searchByVuln}
            disabled={isLoading || !searchText}
          >
            Search
          </PrimaryButton>
          {searchResponse !== undefined && (
            <ShimmeredDetailsList
              items={([] as string[]).concat(...Object.values(searchResponse))}
              columns={searchColumns}
              enableShimmer={isLoading}
              shimmerLines={12}
              selectionMode={SelectionMode.none}
            />
          )}
        </PivotItem>
      </Pivot>
    </Panel>
  );
};
