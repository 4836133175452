import { IDropdownOption } from '@fluentui/react';

const all = 'all';
export const next30 = 'next30';
export const next60 = 'next60';
export const previous = 'previous';

export const getDateStringFromFilterValue = (filterValue: string): string => {
  if (!filterValue || filterValue === all) {
    return '';
  }
  const today = new Date();
  if (filterValue === next30) {
    today.setDate(today.getDate() + 30);
  } else if (filterValue === next60) {
    today.setDate(today.getDate() + 60);
  }
  return today.toISOString().split('T')[0];
};

export const cloudOptions: IDropdownOption[] = [
  { key: all, text: 'Cloud type: All' },
  { key: 'Public', text: 'Public' },
  { key: 'Fairfax', text: 'Fairfax' },
  { key: 'DoD', text: 'DoD' },
  { key: 'GCC', text: 'GCC' },
  { key: 'GCCH', text: 'GCCH' },
];

export const slaStateOptions: IDropdownOption[] = [
  { key: all, text: 'State: All' },
  { key: 'NotInReportWindow', text: 'Not In Report Window' },
  { key: 'DueThisReport', text: 'Due This Report' },
  { key: 'DueNextReport', text: 'Due Next Report' },
  { key: 'Overdue', text: 'Overdue' },
  { key: 'PendingClosure', text: 'Pending Closure' },
];

export const scanTypeOptions: IDropdownOption[] = [
  { key: all, text: 'Scan type: All' },
  { key: 'BeyondTrust', text: 'BeyondTrust' },
  { key: 'Manual', text: 'Manual' },
  { key: 'Qualys', text: 'Qualys' },
  { key: 'Nessus', text: 'Nessus' },
  { key: 'URSA', text: 'URSA' },
  { key: 'WebScout', text: 'WebScout' },
];

export const originalRiskOptions: IDropdownOption[] = [
  { key: all, text: 'Original risk: All' },
  { key: 'High', text: 'High' },
  { key: 'Moderate', text: 'Moderate' },
  { key: 'Low', text: 'Low' },
];

export const effectiveRiskOptions: IDropdownOption[] = [
  { key: all, text: 'Effective risk: All' },
  { key: 'High', text: 'High' },
  { key: 'Moderate', text: 'Moderate' },
  { key: 'Low', text: 'Low' },
];

const yesMaybePendingOptions: IDropdownOption[] = [
  { key: 'Yes', text: 'Yes' },
  { key: 'No', text: 'No' },
  { key: 'Pending', text: 'Pending' },
];
export const fpOptions: IDropdownOption[] = [{ key: all, text: 'FP: All' }, ...yesMaybePendingOptions];

export const orOptions: IDropdownOption[] = [{ key: all, text: 'OR: All' }, ...yesMaybePendingOptions];

export const raOptions: IDropdownOption[] = [{ key: all, text: 'RA: All' }, ...yesMaybePendingOptions];

export const vendorDependency: IDropdownOption[] = [
  { key: all, text: 'Vendor dependency: All' },
  { key: 'Yes', text: 'Yes' },
  { key: 'No', text: 'No' },
];

const dateOptions: IDropdownOption[] = [
  { key: previous, text: 'Past dates' },
  { key: next30, text: 'Next 30 days' },
  { key: next60, text: 'Next 60 days' },
];

export const dueOptions: IDropdownOption[] = [{ key: all, text: 'Due: All' }, ...dateOptions];

export const vendorCheckinOptions: IDropdownOption[] = [{ key: all, text: 'Check-in: All' }, ...dateOptions];

export const plannedRemediationOptions: IDropdownOption[] = [{ key: all, text: 'Planned remediation: All' }, ...dateOptions];
