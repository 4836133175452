import { IStackTokens, Icon, Stack, TooltipHost, mergeStyles } from '@fluentui/react';
import React, { CSSProperties, FunctionComponent } from 'react';
import { IDataPoint, DonutChart, IChartProps } from '@fluentui/react-charting';
import { colors } from 'modules/constants';
import { CustomLegend, LegendData } from '../../components/charts/customLegend';
import { paragraphStyles } from './tenantGovernance';
import { ICuratedTenantMonitoringDataGetResponse } from '../../generated/clientApi';
import {
  getCompliantDetailCount,
  getPolicyDriftDetailCount,
  getPolicyViolationDetailCount,
} from './functions/tenantGovernanceDetailsCompliance.functions';
import { ISortableCuratedTenantMonitoringDataGetResponse } from './tenantGovernanceDetails';

const horizontalStackToken: IStackTokens = {
  childrenGap: 5,
};

const chartStyles = mergeStyles({
  margin: '3em 0',
  padding: '0 1em 1em',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
});

const chartTitleStyles = mergeStyles({
  textAlign: 'center',
  margin: '0 0 1em 1em',
});

const tooltipStyles = mergeStyles({
  verticalAlign: 'middle',
});

const iconStyles = mergeStyles({
  marginLeft: '5px',
  paddingRight: '5px',
  verticalAlign: 'middle',
  paddingBottom: '1px',
});

const legendStyles: CSSProperties = {
  marginTop: '322px',
};

interface TenantGovernanceComplianceOverviewProps {
  curatedTenant: ISortableCuratedTenantMonitoringDataGetResponse;
}

export interface MetadataDataPoint {
  dataCompliant: IDataPoint;
  dataNonCompliant: IDataPoint;
}
export interface ISortableCuratedTenantMonitoringListResponse extends ICuratedTenantMonitoringDataGetResponse {
  id: string;
}

type ChartStat = {
  key: string;
  chartTitle: string;
  tooltip: string;
  data: MetadataDataPoint;
};

const donutChartDimension = 350; // React DonutChart width and height props in pixels.
const donutChartInnerRadius = 75; // React DonutChart inner radius props in pixels.

const getLegendForResources = (controlsWithCompliantCount: number, controlsWithNonCompliantCount: number): LegendData[] => [
  {
    title: 'Compliant',
    subtitle: 'resources',
    count: controlsWithCompliantCount,
    color: colors.blueGreen,
  },
  {
    title: 'Non-compliant',
    subtitle: 'resources',
    count: controlsWithNonCompliantCount,
    color: colors.orange,
  },
];

const getLegendForPolicies = (controlsWithCompliantCount: number, controlsWithNonCompliantCount: number): LegendData[] => [
  {
    title: 'Compliant',
    subtitle: 'policies',
    count: controlsWithCompliantCount,
    color: colors.blueGreen,
  },
  {
    title: 'Non-compliant',
    subtitle: 'policies',
    count: controlsWithNonCompliantCount,
    color: colors.orange,
  },
];

export const TenantGovernanceDetailComplianceOverview: FunctionComponent<TenantGovernanceComplianceOverviewProps> = (props) => {
  const { curatedTenant } = props;

  const chartsStatistics: ChartStat[] = [
    {
      key: 'complianceChart',
      chartTitle: 'Compliance',
      tooltip: 'Represents the overall compliance of all MS-ISR customers.',
      data: getCompliantDetailCount(curatedTenant),
    },
    {
      key: 'policyViolationChart',
      chartTitle: 'Policy violation',
      tooltip: 'Represents any policy or resource type that is currently not compliant with the Deployment package.',
      data: getPolicyViolationDetailCount(curatedTenant),
    },
    {
      key: 'policyDriftsChart',
      chartTitle: 'Policy drifts',
      tooltip: 'Represents any policy or resource type with a detected configuration change made to a customer tenant’s architecture and controls.',
      data: getPolicyDriftDetailCount(curatedTenant),
    },
  ];

  const getData = (withMetadata: IDataPoint, withoutMetadata: IDataPoint): IChartProps => ({
    chartData: [
      { legend: 'Compliant customers', data: withMetadata.y, color: colors.blueGreen },
      {
        legend: 'Non-compliant customers',
        data: withoutMetadata.y,
        color: colors.orange,
      },
    ],
  });

  return (
    <Stack className={chartStyles}>
      <h2>Compliance Overview</h2>
      <p className={paragraphStyles}>{curatedTenant.customerName}</p>
      <Stack horizontal tokens={horizontalStackToken}>
        {chartsStatistics.map((statistic) => (
          <Stack verticalAlign="center" key={statistic.key} grow>
            <Stack.Item align="center">
              <div className={chartTitleStyles}>
                {statistic.chartTitle}
                <TooltipHost content={statistic.tooltip} className={tooltipStyles}>
                  <Icon iconName="Info" className={iconStyles} />
                </TooltipHost>
              </div>
              <Stack horizontal>
                <DonutChart
                  data={getData(statistic.data.dataCompliant, statistic.data.dataNonCompliant)}
                  innerRadius={donutChartInnerRadius}
                  width={donutChartDimension}
                  height={donutChartDimension}
                  hideLegend
                />
              </Stack>
            </Stack.Item>
            <Stack.Item align="center" shrink grow>
              <Stack horizontal grow>
                <CustomLegend
                  style={statistic.data.dataCompliant.y === 0 && statistic.data.dataNonCompliant.y === 0 ? legendStyles : {}}
                  legends={
                    statistic.chartTitle === 'Policy violation'
                      ? getLegendForPolicies(statistic.data.dataCompliant.y, statistic.data.dataNonCompliant.y)
                      : getLegendForResources(statistic.data.dataCompliant.y, statistic.data.dataNonCompliant.y)
                  }
                />
              </Stack>
            </Stack.Item>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
