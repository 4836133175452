import { mergeStyles, Spinner, SpinnerSize } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import { LoadingState } from '../../models/loadingState';
import { logError } from '../../modules/logging/logging';
import './table.css';

const wrapperStyle = mergeStyles({
  overflow: 'auto',
});

export interface ExcelViewerProps {
  content: ArrayBuffer;
}

export const ExcelViewer: FunctionComponent<ExcelViewerProps> = (props) => {
  const { content } = props;
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [rows, setRows] = useState<any>();
  const [columns, setColumns] = useState<any>();

  /**
   * The parent component rerenders this component multiple times, even while the ExcelRenderer is still executing its async function
   * As a result, there are multiple instances of this component that are no longer mounted/useable when the async function completes
   * Thus, we need to tell the renderer that we no longer need its data and to stop execution if it is not the current instance
   * Without this, we get a console error stating that there is a memory leak.
   */
  const mountedRef = useRef(true);

  const renderExcel = useCallback(async () => {
    setLoadingState(LoadingState.Loading);
    const file = new Blob([content]) as File;
    await ExcelRenderer(file, (error: any, response: any) => {
      // We need to check if this is the most recent rendered component of this excelViewer instance. If not, return
      if (!mountedRef.current) {
        return;
      }

      if (error) {
        setLoadingState(LoadingState.Error);
        logError('There was an issue converting the excel to a table: ', error);
      } else {
        setRows(response.rows);

        // There is a bug with the library where it doesn't add a header for the last column
        // Add a blank cell in the upper left hand corner
        const columns = [{ name: '', key: 0 }];
        response.cols.map((column: any) => {
          columns.push({ name: column.name, key: column.key + 1 });
          return null;
        });

        setColumns(columns);
        setLoadingState(LoadingState.Loaded);
      }
    });
  }, [content]);

  useEffect(() => {
    renderExcel();

    // useEffect clean up method to signify this is not the most recently rendered version of this component
    return () => {
      mountedRef.current = false;
    };
  }, [renderExcel]);

  return (
    <>
      {loadingState !== LoadingState.Loaded ? (
        <Spinner size={SpinnerSize.medium} data-testid="spinner" />
      ) : (
        <div className={wrapperStyle}>
          <OutTable data={rows} columns={columns} tableClassName="ExcelTable" tableHeaderRowClass="heading" />
        </div>
      )}
    </>
  );
};
