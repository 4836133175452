import { EvidencePackage, IEvidencePackageCommentsPutCommand, ISupplementalInfo } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { createContext } from 'react';

export interface EvidenceContextProps {
  evidencePackage: EvidencePackage | undefined;
  evidencePackageLoadingState: LoadingState;
  // When an evidence package is selected, request the evidence package data and call the API to retrieve the latest
  requestEvidencePackage: (serviceOid: string, evidencePackageId: string) => void;
  // When we have the latest evidence package from a separate api call and want to update the context to have the latest
  // There are a lot of API calls that already return the evidence package and getting it a 2nd time from the API seemed unnecessary
  // and this prevents the page contents from refreshing
  updateEvidencePackage: (evidencePackage: EvidencePackage) => void;
  // Erases the current evidence package in the context and re-grabs it from the API (results in a refresh of the page content)
  reloadEvidencePackage: () => void;
  // Erases the current evidence package in the context and resets it to the cached/locally saved version
  // This is primarily used to erase state when the user goes to another tab and doesn't explicitly save the data (rather than reaching back out to the api and causing a refresh of the page
  resetEvidencePackage: () => void;
  dirtyDomains: string[];
  supplementalInfo: ISupplementalInfo | undefined;
  addSupplementalInfo: (domainName: string, field: string, fieldValue: string | undefined) => void;
  supportingComments: IEvidencePackageCommentsPutCommand[];
  addSupportingComments: (domainName: IEvidencePackageCommentsPutCommand) => void;
}

export const EvidenceContext = createContext<EvidenceContextProps>({
  evidencePackage: undefined,
  evidencePackageLoadingState: LoadingState.NotLoaded,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  requestEvidencePackage: (serviceOid: string, evidencePackageId: string): void => {
    // do nothing for the base case
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateEvidencePackage: (evidencePackage: EvidencePackage): void => {
    // do nothing for the base case
  },
  reloadEvidencePackage: (): void => {
    // do nothing for the base case
  },
  resetEvidencePackage: (): void => {
    // do nothing for the base case
  },
  dirtyDomains: [],
  supplementalInfo: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addSupplementalInfo: (domainName: string, field: string, fieldValue: string | undefined): void => {
    // do nothing for the base case
  },
  supportingComments: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addSupportingComments: (domainName: IEvidencePackageCommentsPutCommand): void => {
    // do nothing for the base case
  },
});

export const EvidenceConsumer = EvidenceContext.Consumer;
