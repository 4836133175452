import {
  CcmUser,
  GraphUser,
  IUserPostCommand,
  UserDeleteClient,
  UserGetInfoClient,
  UserListGetClient,
  UserPostClient,
  UserPostCommand,
  AtoAOrganization,
  UserOrganizationsGetClient,
  CacRequestListClient,
  CacRequest,
  CacRequestRejectPutClient,
  ICacRequestRejectPutCommand,
  CacRequestRejectPutCommand,
  ICacRequestApprovePutCommand,
  CacRequestApprovePutCommand,
  CacRequestApprovePutClient,
  UserRoleDeleteClient,
  UserRoleDeleteCommand,
  IUserRoleDeleteCommand,
  UserGetClient,
  ConmonOrganization,
  ConmonUserOrganizationsGetClient,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getUserList = async (): Promise<CcmUser[]> => {
  const userListGetClient = new UserListGetClient(getConfig().apiBaseUri);
  return userListGetClient.get();
};

export const deleteUserRole = async (userRoleDeleteClient: IUserRoleDeleteCommand): Promise<void> => {
  const userDeleteClient = new UserRoleDeleteClient(getConfig().apiBaseUri);
  return userDeleteClient.delete(new UserRoleDeleteCommand(userRoleDeleteClient));
};

export const deleteUser = async (oid: string): Promise<void> => {
  const userDeleteClient = new UserDeleteClient(getConfig().apiBaseUri);
  return userDeleteClient.delete(oid);
};

export const getUserInfo = async (email: string): Promise<GraphUser> => {
  const userGetInfoClient = new UserGetInfoClient(getConfig().apiBaseUri);
  return userGetInfoClient.get(email);
};

export const getUser = async (): Promise<CcmUser> => {
  const userGetClient = new UserGetClient(getConfig().apiBaseUri);
  return userGetClient.get();
};

export const addUpdateUser = async (userCommand: IUserPostCommand): Promise<CcmUser> => {
  const userPostClient = new UserPostClient(getConfig().apiBaseUri);
  return userPostClient.post(new UserPostCommand(userCommand));
};

export const getUserOrganizations = async (): Promise<AtoAOrganization[]> => {
  const userOrganizationsGetClient = new UserOrganizationsGetClient(getConfig().apiBaseUri);
  const result = await userOrganizationsGetClient.get();
  return result.organizations ? result.organizations : [];
};

export const getConmonUserOrganizations = async (): Promise<ConmonOrganization[]> => {
  const userOrganizationsGetClient = new ConmonUserOrganizationsGetClient(getConfig().apiBaseUri);
  const result = await userOrganizationsGetClient.get();
  return result.organizations ?? [];
};

export const getCacRequests = async (): Promise<CacRequest[]> => {
  const cacRequestListClient = new CacRequestListClient(getConfig().apiBaseUri);
  const result = await cacRequestListClient.get();
  return result.cacRequests ? result.cacRequests : [];
};

export const putRejectedCacRequests = async (userIds: ICacRequestRejectPutCommand): Promise<CacRequest[]> => {
  const cacRequestRejectPutClient = new CacRequestRejectPutClient(getConfig().apiBaseUri);
  const command = userIds as CacRequestRejectPutCommand;
  const result = await cacRequestRejectPutClient.put(command);
  return result.cacRequests ? result.cacRequests : [];
};

export const putApprovedCacRequests = async (approvedCommand: ICacRequestApprovePutCommand): Promise<CacRequest[]> => {
  const cacRequestApprovePutClient = new CacRequestApprovePutClient(getConfig().apiBaseUri);
  const command = approvedCommand as CacRequestApprovePutCommand;
  const result = await cacRequestApprovePutClient.put(command);
  return result.cacRequests ? result.cacRequests : [];
};
