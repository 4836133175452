import { INavLink, INavLinkGroup } from '@fluentui/react';
import { IMtacClaim } from '../../generated/clientApi';
import { actionCenterRoute, offeringsRoute, serviceDetailRoute, serviceEvidenceRoute, servicesRoute, submitOfferingRoute } from '../routes/routes';

export interface ICcmNavLink extends INavLink {
  claims: IMtacClaim[];
}
export const createNavLink = (
  name: string,
  url: string,
  icon: string,
  disabled: boolean,
  key: string,
  title = '',
  links?: ICcmNavLink[],
  claims?: IMtacClaim[],
): ICcmNavLink => ({
  name,
  url,
  icon,
  disabled,
  key,
  title,
  links: links || [],
  claims: claims || [],
  isExpanded: true,
});

export const createNavLinkGroup = (name: string, links: INavLink[]): INavLinkGroup[] => [{ name, links }];

const createActionCenterNavLink = (disabled: boolean): INavLink => createNavLink('Action Center', actionCenterRoute(), '', disabled, 'action-center');

const createOfferingsNavLink = (disabled: boolean, isComplianceTeam: boolean): INavLink => {
  const links = [createNavLink('Offering Insights', offeringsRoute(), '', disabled, 'offerings-insights')];

  if (isComplianceTeam) {
    links.push(createNavLink('Submit Offering', submitOfferingRoute(), '', disabled, 'submit-offering'));
  }

  return createNavLink('Offerings', offeringsRoute(), '', true, 'offerings', '', links);
};

const createServicesNavLink = (disabled: boolean): INavLink => {
  const links = [
    createNavLink('Service Insights', servicesRoute(), '', disabled, 'service-insights'),
    createNavLink('Service Details', serviceDetailRoute(), '', disabled, 'service-details'),
    createNavLink('Service Evidence', serviceEvidenceRoute(), '', disabled, 'service-evidence'),
  ];

  return createNavLink('Services', servicesRoute(), '', true, 'services', '', links);
};

export const createHomeNavLinkGroup = (isComplianceTeam: boolean, disabled: boolean): INavLinkGroup[] => {
  const links = [createActionCenterNavLink(disabled), createOfferingsNavLink(disabled, isComplianceTeam)];

  if (isComplianceTeam) {
    links.push(createServicesNavLink(disabled));
  }

  return createNavLinkGroup('', links);
};
