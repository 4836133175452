import { createContext } from 'react';
import { Authorizations } from '../../generated/clientApi';
import { ServerConfig } from '../../models/serverConfig';

export interface ConfigContextProps {
  environmentAuthorizations: Authorizations[];
  isInternalUser: boolean;
  serverConfig: ServerConfig | undefined;
}

export const InitialConfigState: ConfigContextProps = {
  environmentAuthorizations: [],
  isInternalUser: false,
  serverConfig: undefined,
};

export const ConfigContext = createContext(InitialConfigState);

export const ConfigConsumer = ConfigContext.Consumer;
