import { DefaultButton, IButtonStyles, IIconProps, mergeStyles, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

const spinnerStyle = mergeStyles({
  marginRight: '5px',
  paddingBottom: '5px',
});
interface ActionButtonProps {
  id?: string;
  disabled?: boolean;
  title?: string;
  isSaving?: boolean;
  saveText?: string;
  defaultText: string;
  className?: string;
  isApprove?: boolean;
  dataTestId: string;
  onSave(): Promise<void> | null;
  styles: IButtonStyles;
  iconProps?: IIconProps;
}

export const ActionButton: React.FunctionComponent<ActionButtonProps> = (props) => {
  ActionButton.defaultProps = {
    disabled: false,
    title: '',
    isSaving: false,
    saveText: 'Saving...',
    className: '',
  };

  const { id, disabled, title, isSaving, onSave, className, dataTestId, styles, iconProps } = props;
  const { saveText, defaultText } = props;

  return (
    <DefaultButton
      id={id}
      disabled={disabled || isSaving}
      title={title}
      styles={styles}
      data-is-focusable="true"
      className={className}
      onClick={onSave}
      data-testid={dataTestId}
      iconProps={iconProps}
    >
      {isSaving ? (
        <>
          <Spinner className={spinnerStyle} size={SpinnerSize.small} />
          {saveText}
        </>
      ) : (
        <>{defaultText}</>
      )}
    </DefaultButton>
  );
};
