import { Icon, mergeStyles, StackItem } from '@fluentui/react';
import React, { FunctionComponent, useContext } from 'react';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { EvidencePackageUserStatus, IEvidencePackage } from '../../../generated/clientApi';
import { Certification } from '../../../models/certification';
import { getIconData } from './evidenceDetailReview.functions';

const decisionTableStyles = mergeStyles({
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  padding: '10px',
  height: '185px',
  margin: '8px',
});

const decisionCellStyles = mergeStyles({
  padding: '7px',
  verticalAlign: 'top',
});

const getIcon = (evidencePackage: IEvidencePackage, key: string) => {
  const iconData = getIconData(evidencePackage, key);
  return <Icon className={iconData?.className || incompleteIconStyles} iconName={iconData?.iconName || 'CircleFill'} />;
};

const incompleteIconStyles = mergeStyles({
  color: 'grey !important',
  margin: 'auto',
});

const formatUserStatus = (status: EvidencePackageUserStatus | undefined): JSX.Element =>
  status ? (
    <>
      <span>{` - ${status}`}</span>
      <br />
    </>
  ) : (
    <></>
  );

export const AuthorizingOfficialsDecision: FunctionComponent = () => {
  const evidenceContext = useContext(EvidenceContext);
  return (
    <>
      {evidenceContext.evidencePackage?.certification === Certification.FedRAMP && (
        <StackItem disableShrink>
          <br />
          <table className={decisionTableStyles}>
            <thead>
              <tr>
                <th colSpan={2}>Authorizing officials&apos; decisions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={decisionCellStyles}>{getIcon(evidenceContext.evidencePackage, 'DOD')}</td>
                <td className={decisionCellStyles}>
                  <strong>{evidenceContext.evidencePackage.dodUserDecision?.name}</strong>
                  {formatUserStatus(evidenceContext.evidencePackage.dodUserDecision?.userStatus)}
                  Department of Defense
                </td>
              </tr>
              <tr>
                <td className={decisionCellStyles}>{getIcon(evidenceContext.evidencePackage, 'DHS')}</td>
                <td className={decisionCellStyles}>
                  <strong>{evidenceContext.evidencePackage.dhsUserDecision?.name}</strong>
                  {formatUserStatus(evidenceContext.evidencePackage.dhsUserDecision?.userStatus)}
                  Department of Homeland Security
                </td>
              </tr>
              <tr>
                <td className={decisionCellStyles}>{getIcon(evidenceContext.evidencePackage, 'GSA')}</td>
                <td className={decisionCellStyles}>
                  <strong>{evidenceContext.evidencePackage.gsaUserDecision?.name}</strong>
                  {formatUserStatus(evidenceContext.evidencePackage.gsaUserDecision?.userStatus)}
                  Government Services Administration
                </td>
              </tr>
            </tbody>
          </table>
        </StackItem>
      )}
    </>
  );
};
