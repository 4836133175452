import { Poam } from 'generated/clientApi';
import React from 'react';
import { IColumn } from '@fluentui/react';
import { PoamColumnNames } from '../poamTable';
import { formatRiskWithColor } from '../poamTableColumns';

export const ManualPoamTableColumns = (): IColumn[] => [
  {
    key: PoamColumnNames['POA&M ID'],
    name: 'POA&M ID',
    minWidth: 100,
    maxWidth: 200,
    fieldName: 'id',
    isMultiline: true,
  },
  {
    key: PoamColumnNames['Vuln ID'],
    name: 'Vuln ID',
    fieldName: 'vulnerabilityId',
    minWidth: 100,
    maxWidth: 100,
    isMultiline: true,
  },
  {
    key: PoamColumnNames['Cloud type'],
    name: 'Cloud type',
    fieldName: 'cloudType',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'Organization',
    name: 'Organization',
    fieldName: 'organization',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'slaState',
    name: 'Status',
    fieldName: 'slaState',
    minWidth: 150,
    maxWidth: 150,
  },
  {
    key: PoamColumnNames['Scan type'],
    name: 'Scan type',
    fieldName: 'scanType',
    minWidth: 100,
    maxWidth: 110,
  },
  {
    key: 'Source',
    name: 'Source',
    fieldName: 'source',
    minWidth: 100,
    maxWidth: 110,
  },
  {
    key: PoamColumnNames.Title,
    name: 'Title',
    fieldName: 'title',
    minWidth: 100,
    maxWidth: 300,
    isMultiline: true,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 200,
    maxWidth: 350,
    isMultiline: true,
  },
  {
    key: PoamColumnNames.Due,
    name: 'Due',
    fieldName: 'dueDate',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: PoamColumnNames['Original risk'],
    name: 'Org Risk',
    fieldName: 'originalRisk',
    minWidth: 150,
    maxWidth: 200,
    onRender: (item: Poam) => formatRiskWithColor(item.originalRating ?? '', item.originalRiskScore),
  },
  {
    key: 'OriginalDetectionDate',
    name: 'Org detection date',
    fieldName: 'detectionDate',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    key: PoamColumnNames['Planned remediation'],
    name: 'Planned remediation',
    minWidth: 125,
    maxWidth: 150,
    fieldName: 'remediationInfo.plannedRemediationDate',
    onRender: (item: Poam) => <div>{item.remediationInfo?.milestoneHistory?.at(-1)?.plannedRemediationDate}</div>,
  },
  {
    key: PoamColumnNames['Planned remediation comments'],
    name: 'Planned remediation comments',
    minWidth: 150,
    maxWidth: 220,
    isMultiline: true,
    onRender: (item: Poam) => <div>{item.remediationInfo?.milestoneHistory?.at(-1)?.comment}</div>,
  },
  {
    key: PoamColumnNames['Vendor dependency'],
    name: 'Vendor dependency',
    minWidth: 120,
    maxWidth: 130,
    onRender: (item: Poam) => <div>{item.remediationInfo?.vendorDependency}</div>,
  },
  {
    key: PoamColumnNames['Vendor Check-in Date'],
    name: 'Vendor check-in',
    fieldName: 'vendor Check-in Date',
    minWidth: 120,
    maxWidth: 120,
    onRender: (item: Poam) => <div>{item.remediationInfo?.checkInDate}</div>,
  },

  {
    key: PoamColumnNames['Product name'],
    name: 'Product name',
    minWidth: 150,
    maxWidth: 150,
    isMultiline: true,
    onRender: (item: Poam) => <div>{item.remediationInfo?.productName}</div>,
  },
  {
    key: PoamColumnNames['Internal comments'],
    name: 'Internal comments',
    fieldName: 'internalComments',
    minWidth: 150,
    maxWidth: 220,
    isMultiline: true,
  },
  {
    key: 'External comments',
    name: 'External comments',
    minWidth: 150,
    maxWidth: 220,
    isMultiline: true,
    onRender: (item: Poam) => <div>{item.remediationInfo?.externalComments}</div>,
  },
  {
    key: 'assetMetadata',
    name: 'Asset metadata',
    minWidth: 250,
    maxWidth: 300,
    isMultiline: true,
    onRender: (item: Poam) => (
      <div style={{ whiteSpace: 'pre' }}>
        {item.assets
          ?.map((asset) => `{${asset.serviceTreeName}, ${asset.assetType}: ${asset.affectedAssetCount}/${asset.inventoryAssetCount}}`)
          .join('\n')}
      </div>
    ),
  },
];
