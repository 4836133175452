import { mergeStyles, Pivot, PivotItem, IStyleSet, IPivotStyles, mergeStyleSets, IStyle } from '@fluentui/react';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo } from 'react';

const tabStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
} as IStyle;

const pivotItemStyle = mergeStyles({
  display: 'flex',
  flex: 1,
});

const bodyStyle = mergeStyles({
  overflow: 'auto',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

const pivotStyles: Partial<IStyleSet<IPivotStyles>> = {
  itemContainer: {
    flex: '1',
    display: 'flex',
  },
};

const hideTabsStyle = mergeStyles({
  selectors: {
    'div[role=tablist]': {
      display: 'none',
    },
  },
});

export interface TabProps {
  children?: ReactNode;
  className?: string;
  activePage?: number;
  onPageUpdated(updatedPage: number): any;
}

export const Tab: FunctionComponent<TabProps> = (props) => {
  const { className, activePage, onPageUpdated } = props;
  const children = React.Children.toArray(props.children);
  const [selectedPageKey, setSelectedPageKey] = React.useState(activePage);

  useEffect(() => {
    setSelectedPageKey(activePage);
  }, [activePage]);

  const classNames = useMemo(
    () =>
      mergeStyleSets({
        root: [className, tabStyle, children.length <= 1 ? hideTabsStyle : {}],
      }),
    [className, children.length],
  );

  const setSelectedPage = useCallback(
    (item) => {
      const newSelectedPage = parseInt(item.props.itemKey, 10);
      setSelectedPageKey(newSelectedPage);
      onPageUpdated(newSelectedPage);
    },
    [setSelectedPageKey, onPageUpdated],
  );

  return (
    <div className={classNames.root}>
      <Pivot className={bodyStyle} selectedKey={String(selectedPageKey)} onLinkClick={setSelectedPage} styles={pivotStyles}>
        {children.map(
          (child, index) =>
            React.isValidElement(child) && (
              <PivotItem key={child.props.title} itemKey={index.toString()} headerText={child.props.title} className={pivotItemStyle}>
                {child}
              </PivotItem>
            ),
        )}
      </Pivot>
    </div>
  );
};
