import { NeutralColors, SharedColors, mergeStyles } from '@fluentui/react';

/**
 * A custom class for the fluent UI <PrimaryButton> component to style it properly with MTAC's current AzureThemeLight theme
 * @example
 * <PrimaryButton className={actionButtonStyles} iconProps={{ iconName: 'Undo' }}>
 *     Undo
 * </PrimaryButton>
 */
export const primaryButtonStyles = mergeStyles({
  minWidth: '56px',
  borderRadius: '4px',
  padding: '0.5em 1em !important',
  backgroundColor: SharedColors.cyan20,
  border: `1px solid ${SharedColors.cyan20} !important`,
  height: 'unset !important',
  color: `${NeutralColors.white} !important`,
  ':active:hover': {
    textDecoration: 'none',
  },
  ':hover': {
    color: NeutralColors.white,
    backgroundColor: SharedColors.cyan30,
    border: `1px solid ${SharedColors.cyan20} !important`,
    textDecoration: 'none',
  },
  ':active': {
    backgroundColor: SharedColors.cyan30,
    border: `1px solid ${SharedColors.cyan20} !important`,
    textDecoration: 'none',
  },
  ':disabled': {
    color: 'lightgrey !important',
    backgroundColor: SharedColors.cyan30,
    borderColor: SharedColors.cyan20,
  },
  ':focus': {
    backgroundColor: `${SharedColors.cyan30} !important`,
    border: `1px solid ${SharedColors.cyan20} !important`,
    outline: 'none',
  },
});
