import { MessageBar as FluentUIMessageBar } from '@fluentui/react';
import * as React from 'react';
import { MessageBarItem, onMessage, onMessageDismiss } from '../../modules/messageBar/messageBar';

export const conflictMessage = (): JSX.Element => (
  <>
    <div>
      Your work cannot be saved because another user has made a change to this version of the page before you. To resolve this you can either:
    </div>
    <div>1. Refresh this page to get the latest copy and try and make your changes again.</div>
    <div>
      2. Open a new tab, navigate to the same page you are on now to see the latest version of this page and copy over the changes you made where
      applicable into the new tab you opened. This will allow you to merge your changes with the latest version and not lose your work.
    </div>
    &nbsp;
  </>
);

export const MessageBar: React.FunctionComponent = () => {
  const [messageBarItems, setMessageBarItems] = React.useState<readonly MessageBarItem[]>([]);

  const handleDismiss = React.useCallback(
    (keyToDismiss: string) => {
      setMessageBarItems((prevState) => prevState.filter((x) => x.key !== keyToDismiss));
    },
    [setMessageBarItems],
  );

  React.useEffect(() => {
    const onMessageSubscription = onMessage().subscribe((messageBarItem: MessageBarItem) => {
      setMessageBarItems((prevState) => [...prevState, messageBarItem]);

      if (messageBarItem.autoDismissSeconds > 0) {
        setTimeout(() => handleDismiss(messageBarItem.key), messageBarItem.autoDismissSeconds * 1000);
      }
    });

    return () => {
      onMessageSubscription.unsubscribe();
    };
  }, [handleDismiss, setMessageBarItems]);

  React.useEffect(() => {
    const onMessageDismissSubscription = onMessageDismiss().subscribe((messageKey: string) => {
      handleDismiss(messageKey);
    });

    return () => {
      onMessageDismissSubscription.unsubscribe();
    };
  }, [handleDismiss]);

  const formatMessage = (message: string | JSX.Element) => {
    if (typeof message === 'string') {
      return <div style={{ lineHeight: '13px' }}>{message}</div>;
    }
    return message;
  };
  return (
    <>
      {messageBarItems.map((messageBarItem) => (
        <FluentUIMessageBar
          style={messageBarItem.isMultiline ? { paddingBottom: '1rem' } : {}}
          key={messageBarItem.key}
          messageBarType={messageBarItem.severity}
          isMultiline={messageBarItem.isMultiline}
          onDismiss={messageBarItem.canDismiss ? () => handleDismiss(messageBarItem.key) : undefined}
          dismissButtonAriaLabel="Dismiss"
        >
          {formatMessage(messageBarItem.message)}
        </FluentUIMessageBar>
      ))}
    </>
  );
};
