import { AccountInfo } from '@azure/msal-browser';
import { createContext } from 'react';
import { CcmUser, IMtacClaim } from '../../generated/clientApi';

export enum AuthType {
  UNKNOWN = 'UNKNOWN',
  CERT_AUTH = 'CERT_AUTH',
  AAD_TOKEN_AUTH = 'AAD_TOKEN_AUTH',
}
export interface AuthContextProps {
  signin: (tenantId?: string) => Promise<void>;
  signout: () => Promise<void>;
  isAuthorized: (roles: IMtacClaim[]) => boolean;
  user: () => Promise<AccountInfo | CcmUser | null>;
  getAuthType: () => AuthType;
  setAuthType: (authType: AuthType) => void;
}

export const AuthContext = createContext<AuthContextProps>({
  signin: (): Promise<void> => new Promise<void>(() => undefined),
  signout: (): Promise<void> => new Promise<void>(() => undefined),
  isAuthorized: (_roles: IMtacClaim[]): boolean => false,
  user: (): Promise<AccountInfo | CcmUser | null> => new Promise<AccountInfo | CcmUser | null>(() => null),
  getAuthType: (): AuthType => AuthType.UNKNOWN,
  setAuthType: (): void => undefined,
});

export const AuthConsumer = AuthContext.Consumer;
