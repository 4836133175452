import { mergeStyles } from '@fluentui/react';

export const fileIconHeaderIcon = mergeStyles({
  padding: 0,
  fontSize: '16px',
});

export const fileIconCell = mergeStyles({
  textAlign: 'center',
  selectors: {
    '&:before': {
      content: '.',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
      width: '0px',
      visibility: 'hidden',
    },
  },
});
