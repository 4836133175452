import {
  ActionButton,
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  Popup,
  PrimaryButton,
  Separator,
  mergeStyleSets,
  mergeStyles,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import {
  CoverageGetClient,
  Coverage,
  ScanCoverageIncludePostClient,
  ScanCoverageIncludePostCommand,
  ScanCoverageSelectionPostClient,
  ScanCoverageSelectionPostCommand,
  ServiceTreeAssetCloud,
  Selection as CoverageSelection,
  CoverageFilterValues,
  CoverageTableMetadataGetResponse,
  CoverageTableMetadataGetClient,
  DayTotal,
  PagedResultOfCoverage,
  SortDirection,
} from 'generated/clientApi';
import { toInteger } from 'lodash';
import { getConfig } from 'modules/config/config';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { showError } from 'modules/messageBar/messageBar';
import { separatorStyles } from '../conMon';
import { ExcludeButton } from './excludeButton';
import CoverageTable from './coverageTable';
import { Legend } from './legend';
import { FilterBar, over90, under90 } from './filterBar';
import { useCoverageFilters } from './useCoverageFilters';
import { ExcludingCoverageTable } from './excludingCoverageTable';
import { Totals } from './totals';
import { ComparePanel } from './comparePanel';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

let timeoutId: NodeJS.Timeout | undefined;
const debounce = (func: (...args: any[]) => void, delay: number) => {
  const debouncedFunction = (...args: any[]) => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
  return debouncedFunction;
};

const buttonBarStyle = mergeStyles({
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
});

const popupStyles = mergeStyleSets({
  root: {
    background: 'rgba(0, 0, 0, 0.2)',
    bottom: '0',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: 10000,
  },
  content: {
    background: 'white',
    left: '50%',
    maxWidth: '400px',
    padding: '0 2em 2em',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const changeOptions: IChoiceGroupOption[] = [
  { key: '0', text: 'Selected better coverage' },
  { key: '1', text: 'Selected better Scan result' },
  { key: '2', text: 'Other' },
];

type CoverageTabProps = {
  isCurrentPeriodLocked: boolean;
};

const excludeDialogContentProps = (exclude: boolean) => ({
  type: DialogType.normal,
  title: `Are you sure you want to ${exclude ? 'include' : 'exclude'} the following rows?`,
  closeButtonAriaLabel: 'Close',
});

const excludeDialogStyles = { main: { maxWidth: 450, minWidth: 450 } };

export const CoverageTab: React.FunctionComponent<CoverageTabProps> = (props) => {
  const { isCurrentPeriodLocked } = props;
  const { readonly, organization } = useContext(ConMonFilterContext);
  const [isPopupVisible, { setTrue: showPopup, setFalse: hidePopup }] = useBoolean(false);
  const [changeReasonKey, setChangeReasonKey] = useState<string>('');
  const [coverageData, setCoverageData] = useState<PagedResultOfCoverage>(
    new PagedResultOfCoverage({
      results: [] as Coverage[],
      continuationToken: undefined,
    }),
  );
  const [coverage] = useMemo(() => [coverageData.results ?? []], [coverageData]);

  const [coverageMetadata, setCoverageMetadata] = useState<CoverageTableMetadataGetResponse>(
    new CoverageTableMetadataGetResponse({
      totals: [] as DayTotal[],
      filters: {} as CoverageFilterValues,
    }),
  );
  const { totals, filters: coverageFilterValues } = coverageMetadata;
  const [itemTotal, setItemTotal] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [sort, setSort] = useState<{ column: keyof Coverage; direction: SortDirection }>();
  const [loadingInitial, setLoadingInitial] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState<boolean>(true);
  const [isExcludeDialogClosed, setIsExcludeDialogClosed] = useState(true);
  const [selectedItems, setSelectedItems] = useState<ServiceTreeAssetCloud[]>([]);
  const [userSelectedDays, systemSelectedDays] = useMemo(() => {
    const system = new Map<string, number>(coverage.map((x) => [x.id, x.systemSelectedDay!]));
    const user = new Map<string, number>(coverage.map((x) => [x.id, x.userSelectedDay ?? x.systemSelectedDay!]));
    return [user, system];
  }, [coverage]);

  const [newSelectedDays, setNewSelectedDays] = useState(new Map<string, CoverageSelection>());
  const [scrollLeft, setScrollLeft] = useState(0);
  const [comparePanelOpen, setComparePanelOpen] = useState(false);
  const firstRender = useRef(true);

  const labelId = 'excludeDialogLabel';
  const excludeModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      isBlocking: false,
      styles: excludeDialogStyles,
    }),
    [labelId],
  );

  const filters = useCoverageFilters();

  const getMinMaxPercentFilters = (coveragePercent: string) => {
    if (coveragePercent === over90) {
      return [90, undefined];
    }
    if (coveragePercent === under90) {
      return [undefined, 89];
    }
    return [undefined, undefined];
  };

  const onPopupOptionChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => {
    if (option?.key) {
      setChangeReasonKey(option.key);
    }
  };

  const popUpSave = (isSkip: boolean) => {
    let reason = '';
    if (isSkip || changeReasonKey === '') {
      reason = 'N/A';
    } else {
      reason = changeOptions[toInteger(changeReasonKey)].text;
    }
    hidePopup();
    onSaveSelection(reason);
    setChangeReasonKey('');
  };

  const getCoverage = useCallback(
    async (replace = false) => {
      setLoadingInitial(false);
      if (replace) {
        setLoadingInitial(true);
        onResetSelection();
        setItemCount(0);
        setItemTotal(0);
        setCoverageData(new PagedResultOfCoverage({ results: [], continuationToken: undefined }));
      } else {
        setLoadingMore(true);
      }
      const [minPercent, maxPercent] = getMinMaxPercentFilters(filters.percentCoverage);
      const client = new CoverageGetClient(getConfig().apiBaseUri);
      try {
        const response = await client.get(
          filters.period,
          organization,
          true,
          replace ? '' : coverageData.continuationToken,
          sort?.direction,
          sort?.column,
          filters.serviceTreeName,
          filters.cloud,
          filters.assetType,
          filters.exclude,
          maxPercent,
          minPercent,
        );
        if (replace) {
          setCoverageData(response.coverageData);
          setItemCount(response.coverageData.results?.length ?? 0);
          setItemTotal(response.totalCount);
        } else {
          setCoverageData(
            (prev) =>
              new PagedResultOfCoverage({
                results: [...(prev?.results ?? []), ...(response?.coverageData?.results ?? [])],
                continuationToken: response?.coverageData?.continuationToken,
              }),
          );
          setItemCount((prev) => prev + (response?.coverageData?.results?.length ?? 0));
        }
      } catch (e) {
        showError('There was an error fetching coverage. Please refresh to try again.');
      } finally {
        setLoadingMore(false);
        setLoadingInitial(false);
      }
    },
    [
      coverageData.continuationToken,
      filters.assetType,
      filters.cloud,
      filters.exclude,
      filters.percentCoverage,
      filters.period,
      organization,
      filters.serviceTreeName,
      sort,
    ],
  );

  const getCoverageMetadata = useCallback(async () => {
    const client = new CoverageTableMetadataGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(filters.period, organization);
      setCoverageMetadata(response);
    } catch (e) {
      showError('There was an error fetching coverage. Please refresh to try again.');
    }
  }, [filters.period, organization]);

  useEffect(() => {
    getCoverageMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.period, organization]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const debouncedFetchData = debounce(() => getCoverage(true), 350);
      debouncedFetchData();
    }
    return () => {
      clearTimeout(timeoutId as NodeJS.Timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.serviceTreeName]);

  useEffect(() => {
    getCoverage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.period, organization, filters.cloud, filters.assetType, filters.percentCoverage, filters.exclude, sort]);

  const saveCoverageInclusion = async () => {
    setLoadingInitial(true);
    setIsExcludeDialogClosed(true);

    const client = new ScanCoverageIncludePostClient(getConfig().apiBaseUri);
    const keys = selectedItems.map((item) => new ServiceTreeAssetCloud(item));
    const command = new ScanCoverageIncludePostCommand({
      compositeKeys: keys,
      include: filters.exclude,
      period: filters.period,
    });

    try {
      await client.post(command);
      getCoverage(true);
    } catch {
      showError('There was an error updating coverage. Please try your operation again.');
    }
  };

  const onSaveSelection = async (reason: string) => {
    if (!organization) {
      showError('You must select an organization in order to modify coverage.');
      return;
    }
    setLoadingInitial(true);
    const client = new ScanCoverageSelectionPostClient(getConfig().apiBaseUri);
    const selections = Array.from(newSelectedDays.values()).map((selectedDay) => new CoverageSelection(selectedDay));
    const command = new ScanCoverageSelectionPostCommand({ selections, period: filters.period, organization, reason });
    try {
      await client.post(command);
      getCoverage(true);
    } catch (e) {
      showError('There was an error saving coverage. Please try your operation again.');
    }
  };

  const onResetSelection = () => {
    setNewSelectedDays(new Map());
  };

  const getSelectedCoverage = () => {
    const selectedCov = coverage.find(
      (cov) =>
        cov.assetType === selectedItems.at(0)?.assetType &&
        cov.cloudType === selectedItems.at(0)?.cloudType &&
        cov.serviceTreeName === selectedItems.at(0)?.serviceTreeName,
    );
    return selectedCov;
  };

  const onUpdateSelection = (item: Coverage, selectedDay: number) => {
    const { id } = item;
    const dayId = item.days?.find((day) => day.day === selectedDay)?.id;
    if (dayId === undefined) {
      showError('There was an error updating coverage selection.');
      return;
    }
    const newMap = new Map(newSelectedDays);
    const covSelection = {
      serviceTreeName: item.serviceTreeName,
      assetType: item.assetType,
      cloudType: item.cloudType,
      newSelectedId: dayId,
    };
    newMap.set(id, covSelection as CoverageSelection);
    setNewSelectedDays(newMap);
  };

  const onScrollReachedBottom = useCallback(() => {
    if (loadingMore === false && !!coverageData.continuationToken) {
      getCoverage();
    }
  }, [coverageData.continuationToken, getCoverage, loadingMore]);

  const onScrollTable = (event: React.UIEvent<HTMLElement>): void => {
    const target = event.target as HTMLInputElement;
    if (target.scrollTop > 0 && target.scrollHeight - target.scrollTop <= 1.05 * target.clientHeight) {
      onScrollReachedBottom();
    }
  };

  return (
    <div>
      {isPopupVisible && (
        <Popup className={popupStyles.root} role="dialog" aria-modal="true">
          <div role="document" className={popupStyles.content}>
            <h2>Select reason for change</h2>
            <ChoiceGroup style={{ paddingBottom: '1rem' }} options={changeOptions} onChange={onPopupOptionChange} label="Pick one" required={false} />
            <PrimaryButton style={{ marginRight: '1rem' }} disabled={changeReasonKey === ''} onClick={() => popUpSave(false)}>
              Save
            </PrimaryButton>
            <DefaultButton onClick={() => popUpSave(true)}>Skip</DefaultButton>
          </div>
        </Popup>
      )}
      {comparePanelOpen && (
        <ComparePanel
          selectedCoverage={getSelectedCoverage()}
          close={() => setComparePanelOpen(false)}
          period={filters.period}
          onUpdateSelection={onUpdateSelection}
          isCurrentPeriodLocked={isCurrentPeriodLocked}
        />
      )}
      <div className={buttonBarStyle}>
        {!isCurrentPeriodLocked && (
          <>
            {!filters.exclude ? (
              <>
                <PrimaryButton
                  style={{
                    minWidth: '56px',
                    width: '56px',
                    height: '32px',
                    borderRadius: '4px',
                  }}
                  disabled={newSelectedDays.size <= 0 || loadingMore || readonly}
                  onClick={showPopup}
                >
                  Save
                </PrimaryButton>
                <ActionButton
                  className={actionButtonStyles}
                  disabled={newSelectedDays.size <= 0 || loadingMore}
                  iconProps={{ iconName: 'Undo' }}
                  onClick={onResetSelection}
                >
                  Revert
                </ActionButton>
                <ExcludeButton disabled={selectedItems.length === 0 || loadingMore || readonly} onClick={() => setIsExcludeDialogClosed(false)} />
              </>
            ) : (
              <PrimaryButton
                style={{
                  minWidth: '56px',
                  width: 'fit-content',
                  height: '32px',
                  borderRadius: '4px',
                  marginRight: '.5rem',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => setIsExcludeDialogClosed(false)}
                disabled={selectedItems.length === 0 || loadingMore || readonly}
              >
                Include and Save
              </PrimaryButton>
            )}
          </>
        )}
        <ActionButton
          className={actionButtonStyles}
          disabled={selectedItems.length !== 1}
          iconProps={{ iconName: 'Switch' }}
          onClick={() => setComparePanelOpen(true)}
        >
          Compare vulns
        </ActionButton>
        <Legend />
      </div>

      <Separator styles={separatorStyles} />
      <FilterBar filterValues={coverageFilterValues ?? ({} as CoverageFilterValues)} />
      <div style={{ marginTop: '.5rem', marginBottom: '.75rem', fontWeight: 600 }}>{`Displaying ${itemCount} of ${itemTotal} rows`}</div>
      <CoverageTable
        coverage={coverage}
        loading={loadingMore}
        loadingInitial={loadingInitial}
        totals={totals ?? []}
        setSelectedItems={setSelectedItems}
        userSelectedDays={userSelectedDays}
        systemSelectedDays={systemSelectedDays}
        newSelectedDays={newSelectedDays}
        setNewSelectedDays={setNewSelectedDays}
        isCurrentPeriodLocked={isCurrentPeriodLocked}
        setScrollLeft={setScrollLeft}
        onScrollTable={onScrollTable}
        setSort={setSort}
      />
      <Totals totals={totals ?? []} scrollLeft={scrollLeft} />
      <Dialog
        hidden={isExcludeDialogClosed}
        onDismiss={() => setIsExcludeDialogClosed(true)}
        dialogContentProps={excludeDialogContentProps(filters.exclude)}
        modalProps={excludeModalProps}
        maxWidth="600px"
        minWidth="600px"
      >
        <ExcludingCoverageTable selectedItems={selectedItems} />
        <DialogFooter>
          <PrimaryButton onClick={saveCoverageInclusion} text={filters.exclude ? 'Include' : 'Exclude'} />
          <DefaultButton onClick={() => setIsExcludeDialogClosed(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
