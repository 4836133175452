import React, { useContext } from 'react';
import { mergeStyles } from '@fluentui/react';
import { ConfigContext } from '../../components/configProvider/configContext';

const supportPageStyles = mergeStyles({
  padding: '1em',
  fontSize: '1.75em',
});

export const SupportPage: React.FunctionComponent = () => {
  const configContext = useContext(ConfigContext);

  return (
    <div className={supportPageStyles}>
      If you have any problems using the Mission Trust Assurance Center please contact&nbsp;
      <a href={`mailto: ${configContext.serverConfig?.contactEmail}`}>{configContext.serverConfig?.contactEmail}</a>
      <br />
      <h2>MTAC User Guides</h2>
      <a href={configContext.serverConfig?.aoUserGuideEndpoint} target="_blank" rel="noreferrer">
        Control Evidence for Auditors and AOs
      </a>
      <br />
      <a href={configContext.serverConfig?.pmUserGuideEndpoint} target="_blank" rel="noreferrer">
        Control Evidence for Compliance PMs
      </a>
    </div>
  );
};
