// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/stacked-bar-chart

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { mergeStyles } from '@fluentui/react';

const legendWrapperStyle = mergeStyles({
  marginLeft: 'auto',
});

export const ChartLegend = ({ props }) => {
  let {
    colors,
    keys,
    width = 100, // outer width, in pixels
    height = 50, // outer height, in pixels
  } = props;
  const svgRef = React.useRef(null);
  useEffect(() => {
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll('*').remove();
    const svg = svgEl
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [0, 0, width, height])
      .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');
    const legendKeys = new d3.InternSet(keys);
    const color = d3.scaleOrdinal(legendKeys, colors);

    // Add one dot in the legend for each name.
    const size = 20;
    svg.selectAll('mydots')
      .data(legendKeys)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (d, i) => i * (size + 5)) // 100 is where the first dot appears. 25 is the distance between dots
      .attr('width', size)
      .attr('height', size)
      .style('fill', (d) => color(d));

    // Add one dot in the legend for each name.
    svg.selectAll('mylabels')
      .data(legendKeys)
      .enter()
      .append('text')
      .attr('x', (size * 1.2))
      .attr('y', (d, i) => i * (size + 5) + (size / 2)) // 100 is where the first dot appears. 25 is the distance between dots
      .style('fill', (d) => '#000')
      .text((d) => d)
      .attr('text-anchor', 'left')
      .style('alignment-baseline', 'middle');
  }, []);
  return (
    <div className={legendWrapperStyle}>
      <svg ref={svgRef} width={width} height={height} />
    </div>
  );
};
