import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const countWrapperStyle = mergeStyles({
  padding: '0 1em',
  height: 'fit-content',
  fontWeight: 'bold',
});

const countStyle = mergeStyles({
  fontSize: '2em',
});

export interface SummaryCountProps {
  color: string;
  title: string;
  count?: number;
}

export const SummaryCount: FunctionComponent<SummaryCountProps> = (props) => {
  const getCountSummaryClass = (baseClass: string, borderColor: string) => mergeStyles(baseClass, { borderLeft: `0.5em solid ${borderColor}` });

  return (
    <>
      <div key={props.title} className={getCountSummaryClass(countWrapperStyle, props.color)}>
        {props.title}
        <div className={countStyle}>{props.count}</div>
      </div>
    </>
  );
};
