import React from 'react';
import { Icon, TooltipHost, mergeStyles } from '@fluentui/react';
import { ISortableFilterableListColumn } from 'components/searchableFilterableList/searchableFilterableList';
import { getCuratedTenantDetailViolationTypes, isCuratedTenantDisplayDataCompliant } from './functions/tenantGovernanceDetails.functions';
import { ISortableCuratedTenantDetailDisplay } from './tenantGovernanceDetails';

const compliantStyles = mergeStyles({
  paddingRight: '5px',
});

const iconStyles = mergeStyles({
  marginLeft: '5px',
  paddingRight: '5px',
  verticalAlign: 'middle',
  paddingBottom: '1px',
});

const tooltipStyles = mergeStyles({
  verticalAlign: 'middle',
});

export const getTenantGovernanceDetailsColumns = (
  names: string[],
  scopes: string[],
  compliantStates: string[],
  typeOfViolations: string[],
): ISortableFilterableListColumn[] => [
  {
    key: 'name',
    name: 'Name',
    isFilterable: true,
    customFilterValues: names,
    getFieldValue: (item: ISortableCuratedTenantDetailDisplay) => item.name,
    onRender: (item: ISortableCuratedTenantDetailDisplay) => <>{item.name}</>,
    getFilterValue: (item: any) => item.name,
    minWidth: 50,
    isRowHeader: true,
  },
  {
    key: 'scope',
    name: 'Scope',
    isFilterable: true,
    customFilterValues: scopes,
    getFieldValue: (item: ISortableCuratedTenantDetailDisplay) => item.scope,
    onRender: (item: ISortableCuratedTenantDetailDisplay) => <>{item.scope}</>,
    onRenderHeader: () =>
    <>
      Scope
      <TooltipHost content="If it is a policy violation, scope indicates where policy was assigned. If it is a policy drift, indicating which resource was not as expected." className={tooltipStyles}>
        <Icon iconName="Info" className={iconStyles} />
      </TooltipHost>
    </>,
    getFilterValue: (item: any) => item.scope,
    minWidth: 100,
  },
  {
    key: 'complianceState',
    name: 'Compliance state',
    isFilterable: true,
    customFilterValues: compliantStates,
    getFieldValue: (item: ISortableCuratedTenantDetailDisplay) => isCuratedTenantDisplayDataCompliant(item) || '',
    onRender: (item: ISortableCuratedTenantDetailDisplay) => (
      <>
        <Icon
          role="img"
          aria-label="Valid Compliant"
          title="Valid Compliant"
          className={mergeStyles(compliantStyles, { color: item.complianceState ? 'teal' : 'silver' })}
          iconName={item.complianceState ? 'SkypeCircleCheck' : 'StatusCircleOuter'}
        />
        {isCuratedTenantDisplayDataCompliant(item)}
      </>
    ),
    getFilterValue: (item: any) => isCuratedTenantDisplayDataCompliant(item),
    minWidth: 100,
  },
  {
    key: 'typeOfViolation',
    name: 'Type of violation',
    isFilterable: true,
    customFilterValues: typeOfViolations,
    getFieldValue: (item: ISortableCuratedTenantDetailDisplay) => getCuratedTenantDetailViolationTypes([item]).join(', ') || '',
    onRender: (item: ISortableCuratedTenantDetailDisplay) => <>{getCuratedTenantDetailViolationTypes([item]).join(', ')}</>,
    getFilterValues: (item: any) => getCuratedTenantDetailViolationTypes([item]),
    minWidth: 100,
  },
];
