import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Panel,
  PanelType,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  mergeStyles,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { SaveButton } from 'components/saving/saveButton';
import {
  BasicPoam,
  ConmonReportsDefaultJustificationGetClient,
  CveInfo,
  DeviationTypes,
  PoamDeviationPostClient,
  PoamDeviationPostCommand,
  ProposedVectorStringListClient,
} from 'generated/clientApi';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { getConfig } from 'modules/config/config';
import { riskRatingIsLower } from 'pages/conMon/components/tableUtils';
import { ConMonFilterContext } from 'pages/conMon/conMonFilterContext/conMonFilterContext';
import { PoamDeviationPostCommandType } from 'models/typeHelper';
import { calculateCVSSFromVector } from '../riskPanel/cvssCalc';
import { AutoRiskTable } from './autoRiskTable';
import { RiskCalculatorJustifications, emptyJustifications } from '../riskPanel/riskCalculatorTypes';
import { getRatingFromScore } from '../poamTableColumns';

export type PoamWithProposedScore = BasicPoam & {
  proposedScore: string;
  fullVectorString: string;
  maxOriginalCve: CveInfo;
  maxProposedCve: CveInfo;
};

type AutoRiskPanelProps = {
  selectedPeriod: string;
  close: () => void;
  updatePoamsRating: (newRating: string, poamIds: string[]) => void;
};

const saveDialogContentProps = {
  type: DialogType.normal,
  title: 'Are you sure you want to create an auto risk deviation for the following POA&Ms?',
  closeButtonAriaLabel: 'Close',
};

const saveDialogStyles = { main: { maxWidth: 250, minWidth: 250, zIndex: 10 } };

const spinnerStyles = mergeStyles({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const AutoRiskPanel: React.FunctionComponent<AutoRiskPanelProps> = (props) => {
  const { close, selectedPeriod } = props;
  const { organization } = useContext(ConMonFilterContext);

  const [isSovereignCloud, setIsSovereignCloud] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isSaveDialogClosed, setIsSaveDialogClosed] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [messageBarMessages, setMessageBarMessages] = useState({ success: '', error: '' });
  const [poamsWithProposedScore, setPoamsWithProposedScore] = useState<PoamWithProposedScore[]>([]);
  const [defaultJustification, setDefaultJustifications] = useState<RiskCalculatorJustifications>(emptyJustifications);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    getInitialJustifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialJustifications = async () => {
    const client = new ConmonReportsDefaultJustificationGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(organization);
      if (response) {
        setDefaultJustifications(response as RiskCalculatorJustifications);
      }
    } catch (e) {
      setMessageBarMessages({
        success: '',
        error: 'There was an error fetching the justifications. Please refresh to try again.',
      });
    }
  };

  useEffect(() => {
    fetchProposedVectorStrings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProposedVectorStrings = async (): Promise<void> => {
    setLoading(true);
    const proposedVectorStringListClient = new ProposedVectorStringListClient(getConfig().apiBaseUri);

    try {
      const proposedVectorStringsResponse = await proposedVectorStringListClient.get(selectedPeriod, organization);
      const proposedVectorStringList = proposedVectorStringsResponse.proposedVectorStringList ?? {};
      const autoRiskPoams = proposedVectorStringsResponse.poams ?? [];

      setIsSovereignCloud(proposedVectorStringsResponse.isNoHighToLowCloud ?? false);

      const proposedScores: PoamWithProposedScore[] = autoRiskPoams
        .filter((poam) => proposedVectorStringList[poam.id]?.length > 0)
        .map((poam) => {
          const proposedCves = proposedVectorStringList[poam.id];

          let maxOriginalCve: CveInfo | undefined;
          let maxProposedCve: CveInfo | undefined;
          proposedCves.forEach((cve) => {
            maxOriginalCve = (maxOriginalCve?.baseScore ?? '0') < (cve?.baseScore ?? '0') ? cve : maxOriginalCve;
            const environmentalMetricScore = (calculateCVSSFromVector(cve.proposedVectorString) as any).environmentalMetricScore ?? '0';
            if (!maxProposedCve || !maxProposedCve.proposedScore || maxProposedCve.proposedScore < environmentalMetricScore) {
              maxProposedCve = { ...cve, proposedScore: environmentalMetricScore } as CveInfo;
            }
          });

          return {
            ...poam,
            proposedScore: maxProposedCve?.proposedScore,
            fullVectorString: maxProposedCve?.proposedVectorString,
            maxOriginalCve,
            maxProposedCve,
          } as PoamWithProposedScore;
        })
        .filter((poam) => riskRatingIsLower(getRatingFromScore(poam.proposedScore), poam.effectiveRating));

      setPoamsWithProposedScore(proposedScores);
    } catch (e) {
      setMessageBarMessages({
        success: '',
        error: `There was an error fetching proposed vector strings for period: ${selectedPeriod}. Please refresh to try again.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const isSovereignCloudHighToLow = (originalRating?: string, proposedScore?: string) => {
    if (!isSovereignCloud) {
      return false;
    }
    const isHigh: boolean = parseFloat(originalRating ?? '') >= 7.0;
    const isLow: boolean = parseFloat(proposedScore ?? '') < 4;
    return isHigh && isLow;
  };

  const labelId = 'saveDialogLabel';
  const excludeModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      isBlocking: false,
      styles: saveDialogStyles,
    }),
    [labelId],
  );

  const onSave = async () => {
    setSaving(true);

    const client = new PoamDeviationPostClient(getConfig().apiBaseUri);
    const autoRiskAdjustmentJustification = 'Auto risk adjustment.';
    const sovereignHighToLowJustification = `${autoRiskAdjustmentJustification} Per AO, a risk adjustment may not be submitted for High -> Low, so this is being requested as a High -> Moderate adjustment.`;
    const selectedPoamIdSet = new Set(selectedKeys);
    const poamSelection = poamsWithProposedScore.filter((poam) => selectedPoamIdSet.has(poam.id));
    try {
      const postDeviationCommand: PoamDeviationPostCommandType = {
        deviations: poamSelection.reduce(
          (result, poam) => ({
            ...result,
            [poam.id]: {
              deviationType: DeviationTypes.AutoRiskAdjustment,
              justification: isSovereignCloudHighToLow(poam.originalRiskScore, poam.proposedScore)
                ? sovereignHighToLowJustification
                : autoRiskAdjustmentJustification,
              riskDeviationInfo: {
                proposedScore: poam.proposedScore ?? '0',
                fullVectorString: poam.fullVectorString,
                justifications: getDefaultJustificationsFromVector(poam.fullVectorString),
                maxProposedCve: poam.maxProposedCve,
                maxOriginalCve: poam.maxOriginalCve,
              },
            },
          }),
          {},
        ),
        period: selectedPeriod,
        isDraft: false,
      };
      const response = await client.post(postDeviationCommand as PoamDeviationPostCommand);
      setMessageBarMessages({ success: `${response.length} deviations successfully saved`, error: '' });
    } catch (e) {
      setMessageBarMessages({
        success: '',
        error: 'There was an error saving the deviations. Please refresh to try again.',
      });
    } finally {
      setIsSaveDialogClosed(true);
      setSaving(false);
    }
  };

  const getDefaultJustificationsFromVector = (vectorString: string) => {
    const justifications: { [key: string]: string } = {};
    const parts = vectorString.split('/');
    parts.forEach((part) => {
      const key = part.toLowerCase();
      if (key in defaultJustification) {
        justifications[key] = defaultJustification[key as keyof RiskCalculatorJustifications];
      }
    });
    return justifications;
  };

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ position: 'absolute', bottom: '1rem' }}>
        <PrimaryButton onClick={() => setIsSaveDialogClosed(false)} text="Save" disabled={selectedKeys.length === 0} />
        <DefaultButton onClick={close} styles={{ root: { marginLeft: 8 } }}>
          Cancel
        </DefaultButton>
      </div>
    ),
    [close, selectedKeys.length],
  );

  return (
    <Panel
      isOpen
      onDismiss={close}
      type={PanelType.large}
      closeButtonAriaLabel="Close"
      headerText="Auto risk adjustment"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalPoamStyles}
    >
      {(messageBarMessages.success || messageBarMessages.error) && (
        <MessageBar
          messageBarType={messageBarMessages.success ? MessageBarType.success : MessageBarType.error}
          onDismiss={() => setMessageBarMessages({ success: '', error: '' })}
          dismissButtonAriaLabel="Dismiss"
        >
          {messageBarMessages.success || messageBarMessages.error}
        </MessageBar>
      )}
      {loading ? (
        <div className={spinnerStyles}>
          <Spinner label="Loading Auto Risk Adjustment POA&Ms..." size={SpinnerSize.large} />
        </div>
      ) : (
        <AutoRiskTable
          poams={poamsWithProposedScore}
          isSovereignCloud={isSovereignCloud}
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
        />
      )}
      <Dialog
        hidden={isSaveDialogClosed}
        onDismiss={() => setIsSaveDialogClosed(true)}
        dialogContentProps={saveDialogContentProps}
        modalProps={excludeModalProps}
        maxWidth="450px"
        minWidth="450px"
      >
        <div style={{ height: '250px', overflow: 'auto' }}>
          {selectedKeys.map((id) => (
            <p key={id}>
              <b>{id}</b>
            </p>
          ))}
        </div>
        <DialogFooter>
          <SaveButton defaultText="Confirm" saveText="Saving..." onSave={onSave} isSaving={isSaving} isPrimary>
            Save
          </SaveButton>
          <DefaultButton onClick={() => setIsSaveDialogClosed(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};
