import { createContext } from 'react';
import {
  ActionCenterMetricsGetResponse,
  EvidencePackagesCurrentListResponse,
  IPendingActions,
  UpcomingServicesListResponse,
  WorkloadInsights,
} from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { ActionCenterData } from './actionCenterProvider';

export interface ContextDataObject<Type> {
  contextData: Type | undefined;
  loadingState: LoadingState;
}

export interface ActionCenterContextProps {
  currentEvidencePackages: ContextDataObject<EvidencePackagesCurrentListResponse[]>;
  pendingActions: ContextDataObject<IPendingActions>;
  workloadInsights: ContextDataObject<WorkloadInsights[]>;
  upcomingServices: ContextDataObject<UpcomingServicesListResponse[]>;
  actionCenterMetrics: ContextDataObject<ActionCenterMetricsGetResponse>;
  requestContextData: (key: ActionCenterData) => void;
}

export const ActionCenterContext = createContext<ActionCenterContextProps>({
  currentEvidencePackages: {
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  },
  pendingActions: {
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  },
  workloadInsights: {
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  },
  upcomingServices: {
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  },
  actionCenterMetrics: {
    contextData: undefined,
    loadingState: LoadingState.NotLoaded,
  },
  requestContextData: (): void => {
    // do nothing for the base case
  },
});

export const ActionCenterConsumer = ActionCenterContext.Consumer;
