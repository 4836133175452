import {
  Checkbox,
  Dropdown,
  ICheckboxProps,
  Icon,
  IDropdownOption,
  IDropdownStyles,
  Label,
  mergeStyles,
  Spinner,
  SpinnerSize,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { ServiceContext } from 'components/serviceProvider/serviceContext';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { Accordion } from '../../../components/accordion/accordion';
import { sectionNames } from '../../../components/domainEvidenceData/domainEvidenceData.functions';
import { ProgressStatus } from '../../../components/fileAttachment/fileAttachment';
import { SavePrompt } from '../../../components/saving/savePrompt';
import { WizardNavigation } from '../../../components/wizardNavigation/wizardNavigation';
import {
  CreateDomainBody,
  EvidencePackage,
  IEvidencePackage,
  IEvidencePackagePutCommand,
  ISnapshotDetail,
  ISnapshotMinimal,
  ISnapshotPostCommand,
} from '../../../generated/clientApi';
import { DocumentType } from '../../../models/documentType';
import { additionalDomainInfoMap, Domains } from '../../../models/domainConstants';
import { createEvidencePackage, updateEvidencePackage } from '../../../modules/evidencePackage/evidencePackage';
import { createServiceAttachment } from '../../../modules/fileAttachment/fileAttachment';
import { logError } from '../../../modules/logging/logging';
import { showError, showSuccess } from '../../../modules/messageBar/messageBar';
import { createSnapshot, getSnapshot } from '../../../modules/snapshot/snapshot';
import { SnapshotPreviewButton } from '../snapshotPreviewButton';
import { SnapshotPreviewPanel, SnapshotPreviewPanelProps } from '../snapshotPreviewPanel';
import { AdditionalDomainDetail } from './additionalDomainDetail';

const wrapperStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const flexRowStyle = mergeStyles({
  display: 'flex',
  maxWidth: '60%',
  flexDirection: 'row',
});

const advancedItemStyle = mergeStyles({
  margin: '15px 0',
  display: 'flex',
  paddingRight: '1em',
});

const advancedItemLabelWrapperStyle = mergeStyles({
  display: 'flex',
  minWidth: '250px',
  alignItems: 'center',
});

const domainContentStyle = mergeStyles({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  paddingLeft: '1em',
  width: '100%',
  margin: '1em auto auto auto',
});

const domainTitleRowStyle = mergeStyles({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  paddingLeft: '0.6em',
  paddingTop: '0.5em',
  maxHeight: '1.5em',
});

const domainTitleStyle = mergeStyles({
  paddingLeft: '5em',
});

const domainElementsContainerStyle = mergeStyles({
  paddingBottom: '1.5em',
});

const iconAdvancedStyle = mergeStyles({
  margin: 'auto 0',
  paddingLeft: '20px',
  fontSize: '15px',
  cursor: 'pointer',
  color: '#243a5e',
  fontWeight: 'bold',
});

const snapshotPreviewDivStyle = mergeStyles({
  marginLeft: 'auto',
  display: 'flex',
});

const footerStyle = mergeStyles({
  borderTop: '1px solid #eee',
  paddingTop: '20px',
});

const headerStyle = mergeStyles({
  fontSize: '14px',
});

const advancedOptionsDropdownStyles: Partial<IDropdownStyles> = {
  root: {
    flex: 1,
  },
  dropdown: {
    maxWidth: '250px',
    alignItems: 'center',
    marginBottom: '5px',
  },
};

const accordionStyle = {
  root: {
    display: 'flex',
    borderTop: '0.5px solid #eee',
    paddingTop: '10px',
    width: '100%',
  },
  icon: {
    margin: 'auto 1em auto auto',
  },
  headerDiv: {
    width: '100%',
  },
  operatorDiv: {
    margin: '0.5em auto auto',
  },
};

const dependentServiceLabelStyle = mergeStyles({
  paddingRight: '0.5em',
});

const dropdownOption = mergeStyles({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export interface CreateEvidenceTabProps {
  serviceOid: string;
  cloud: string;
  certification: string;
  saveEnabled: boolean;
  onEvidencePackageAdded(evidencePackage: IEvidencePackage): void;
  onClickPreviousButton?(): void;
  snapshotDetails: ISnapshotDetail[];
  evidencePackageId?: string;
  setEvidencePackage(evidencePackage: IEvidencePackage): void;
  currentEvidencePackageDomainNames?: string[] | undefined;
  updateSnapshots(): void;
}
export interface DependentServiceInfo {
  name: string;
  serviceId: string;
  options: IDropdownOption[];
  selectedOption: string;
  checkBoxProps: ICheckboxProps;
}
interface DomainDetail {
  domainName: string;
  title: string;
  selected: boolean;
  selectionDisabled: boolean;
  disabledTitle: string;
  selectedDropdown: string;
  options: IDropdownOption[];
  isCreatingSnapshot: boolean;
  isLoading: boolean;
  snapshotPreviewButtonDisabled: boolean;
  snapshotsEnabled: boolean;
  isAccordionExpanded: boolean;
  additionalDomainDetail: string | undefined;
  disableAdditionalDomainDetailInput: boolean;
  isInFocus: boolean;
  dependentServiceInfos: DependentServiceInfo[] | undefined;
  excludedSections: string[];
}

export interface AccordionDomainDetail {
  domainName: string;
  domainDetail: DomainDetail;
}

const defaultOption = { key: 'default', text: 'Default' };

export const CreateEvidenceTab: FunctionComponent<CreateEvidenceTabProps> = (props) => {
  const {
    serviceOid,
    saveEnabled,
    cloud,
    certification,
    onEvidencePackageAdded,
    onClickPreviousButton,
    snapshotDetails,
    evidencePackageId,
    setEvidencePackage,
    currentEvidencePackageDomainNames,
    updateSnapshots,
  } = props;
  const servicesContext = useContext(ServiceContext);
  const evidenceContext = useContext(EvidenceContext);
  const [saveButtonTooltip, setSaveButtonTooltip] = useState<string | undefined>();
  const [isSaveInProgress, setIsSaveInProgress] = useState<boolean>(false);
  const [isSnapshotPreviewOpen, { setTrue: openSnapshotPreviewPanel, setFalse: onDismissSnapshotPreviewPanel }] = useBoolean(false);
  const [accordionDomainDetails, setAccordionDomainDetails] = useState<AccordionDomainDetail[]>([]);
  const [allDomainsSelected, setAllDomainsSelected] = useState<boolean>(true);
  const [progressStatus, setProgressStatus] = useState<ProgressStatus>();
  const [isMainServiceDependencySelected, setIsMainServiceDependencySelected] = useState<boolean>(true);
  const defaultConstant = 'default';
  const excludeS360EtaDomains = React.useMemo(() => [Domains.BCDR, Domains.SDL, Domains.PRIVACY], []); // useMemo is required to avoid ESLint react-hooks/exhaustive-deps

  const defaultPreviewPanelPropsDefault = {
    headerText: defaultConstant,
    snapshotMinimal: { id: defaultConstant, displayName: defaultConstant },
  } as SnapshotPreviewPanelProps;
  const [previewProps, setPreviewProps] = useState<SnapshotPreviewPanelProps>(defaultPreviewPanelPropsDefault);

  useEffect(() => {
    // If the service is already defined, move on
    if (servicesContext.selectedService) {
      return;
    }

    servicesContext.setSelectedService(serviceOid);
  }, [servicesContext, serviceOid]);

  useEffect(() => {
    if (!snapshotDetails) {
      return;
    }

    const accordionDomainDetails = snapshotDetails.map((snapshotDetail) => {
      let dependentServiceInfos;
      if (snapshotDetail.dependentServices.length > 0) {
        dependentServiceInfos = [];
        snapshotDetail.dependentServices.forEach((dependentService) => {
          const options = [defaultOption];
          const selectedOption = defaultOption.key;
          dependentService.snapshotMinimals.forEach((snapshotMinimal) =>
            options.push({ key: snapshotMinimal.id, text: snapshotMinimal.displayName }),
          );
          const checkBoxProps = {
            checked: true,
          } as ICheckboxProps;
          dependentServiceInfos.push({
            name: dependentService.name,
            serviceId: dependentService.serviceId,
            options,
            selectedOption,
            checkBoxProps,
          });
        });
      }

      const { domainName } = snapshotDetail;
      const options: IDropdownOption[] = [];
      options.push(defaultOption);
      snapshotDetail.snapshotMinimals.forEach((snapshotMinimal) => options.push({ key: snapshotMinimal.id, text: snapshotMinimal.displayName }));
      let selected = true;
      if (currentEvidencePackageDomainNames) {
        selected = currentEvidencePackageDomainNames.some((name) => name === domainName);
      }
      const validationDetail = domainName === Domains.MDS_LOGS ? { isRequired: true } : undefined;
      const currentEvidenceDomains = evidenceContext.evidencePackage?.evidenceDomains;
      const currentEvidenceDomain = currentEvidenceDomains?.find((evidence) => evidence.name === domainName);
      const snapshotId = currentEvidenceDomain?.snapshotId;
      const excludedSections = currentEvidenceDomain?.excludedSections || (excludeS360EtaDomains.includes(domainName) ? [sectionNames.S360Eta] : []); // S360 ETA section should be excluded by default
      const selectedSnapshot = options.find((option) => option.key === snapshotId)?.key || defaultConstant;
      const domainDetail = {
        domainName,
        title: snapshotDetail.title,
        selected,
        selectionDisabled: false,
        disabledTitle: '',
        selectedDropdown: selectedSnapshot,
        options,
        isCreatingSnapshot: false,
        isLoading: false,
        snapshotPreviewButtonDisabled: selectedSnapshot === defaultConstant,
        snapshotsEnabled: true,
        previewDisabled: true,
        isAccordionExpanded: false,
        additionalDomainDetail: undefined,
        disableAdditionalDomainDetailInput: false,
        isInFocus: false,
        validationDetail,
        dependentServiceInfos,
        excludedSections: excludedSections || [],
      } as DomainDetail;
      return { domainName, domainDetail };
    });
    setAccordionDomainDetails(accordionDomainDetails);
  }, [serviceOid, snapshotDetails, currentEvidencePackageDomainNames, evidenceContext.evidencePackage?.evidenceDomains, excludeS360EtaDomains]);

  const getAdditionalDomainInfo = useCallback(
    (domainName: string, _selectedOption: string | undefined, additionalDomainDetail?: string, dependentServiceInfos?: DependentServiceInfo[]) => {
      const propertyName = additionalDomainInfoMap.get(domainName);
      if (propertyName === undefined) {
        return undefined;
      }

      if (dependentServiceInfos) {
        const selectedDependentServices = dependentServiceInfos.filter((dependentServiceInfo) => dependentServiceInfo.checkBoxProps.checked);
        const dependencyServices: any[] = selectedDependentServices.map((dependentServiceInfo) => {
          if (dependentServiceInfo.selectedOption === defaultConstant) {
            return { serviceId: dependentServiceInfo.serviceId };
          }
          return { serviceId: dependentServiceInfo.serviceId, snapshotId: dependentServiceInfo.selectedOption };
        });

        const service = servicesContext.selectedService;
        if (service && isMainServiceDependencySelected) {
          if (!_selectedOption) {
            dependencyServices.push({ serviceId: service?.id });
          } else {
            dependencyServices.push({ serviceId: service?.id, snapshotId: _selectedOption });
          }
        }

        return { [propertyName]: dependencyServices };
      }

      if (additionalDomainDetail) {
        return { [propertyName]: additionalDomainDetail };
      }

      return undefined;
    },
    [isMainServiceDependencySelected, servicesContext],
  );

  const updateAllDomainsSelected = useCallback((domainDetails: AccordionDomainDetail[]) => {
    const allDomainsSelected = domainDetails
      .filter((accordionDomainDetail) => !accordionDomainDetail.domainDetail.selectionDisabled)
      .every((accordionDomainDetail) => accordionDomainDetail.domainDetail.selected);
    setAllDomainsSelected(allDomainsSelected);
  }, []);

  const onSetAccordionDomainDetails = useCallback(
    (
      inputAccordionDomainDetails: AccordionDomainDetail[],
      domainName: string,
      isCreatingSnapshot: boolean,
      isLoading: boolean,
      snapshotMinimal?: ISnapshotMinimal,
      selectedDropdown?: string,
      selectionDisabled?: boolean,
      disableAdditionalDomainDetailInput?: boolean,
      additionalDomainDetail?: string,
    ): AccordionDomainDetail[] => {
      const copiedAccordionDomainDetails = [...inputAccordionDomainDetails];
      const accordionDomainDetail = copiedAccordionDomainDetails.find((domainDetail) => domainDetail.domainName === domainName);
      if (accordionDomainDetail) {
        const { domainDetail } = accordionDomainDetail;
        domainDetail.isCreatingSnapshot = isCreatingSnapshot;
        domainDetail.isLoading = isLoading;
        domainDetail.selectedDropdown = selectedDropdown === undefined ? domainDetail.selectedDropdown : selectedDropdown;
        domainDetail.snapshotPreviewButtonDisabled = domainDetail.selectedDropdown === defaultConstant;
        domainDetail.disableAdditionalDomainDetailInput = disableAdditionalDomainDetailInput || false;
        domainDetail.additionalDomainDetail = additionalDomainDetail;
        domainDetail.isInFocus = false;
        if (selectionDisabled !== undefined) {
          domainDetail.selectionDisabled = selectionDisabled;
          updateAllDomainsSelected(copiedAccordionDomainDetails);
        }
        if (snapshotMinimal) {
          domainDetail.options.splice(1, 0, { key: snapshotMinimal.id, text: snapshotMinimal.displayName });
        }
      }
      return copiedAccordionDomainDetails;
    },
    [updateAllDomainsSelected],
  );

  const onSetExclusions = useCallback(
    (inputAccordionDomainDetails: AccordionDomainDetail[], domainName: string, excludedSection: string, isExcluded: boolean): void => {
      const copiedAccordionDomainDetails = [...inputAccordionDomainDetails];
      const accordionDomainDetail = copiedAccordionDomainDetails.find((domainDetail) => domainDetail.domainName === domainName);

      if (accordionDomainDetail) {
        const { domainDetail } = accordionDomainDetail;
        if (!domainDetail.excludedSections) {
          domainDetail.excludedSections = [] as string[];
        }
        if (isExcluded) {
          domainDetail.excludedSections.push(excludedSection);
        } else {
          domainDetail.selected = true;
          domainDetail.excludedSections = domainDetail.excludedSections.filter((section) => section !== excludedSection);
        }
      }

      setAccordionDomainDetails(copiedAccordionDomainDetails);
    },
    [],
  );

  const getPreviewData = useCallback(async (selectedDropdownKey: string) => {
    const snapshot = await getSnapshot(selectedDropdownKey);
    return snapshot;
  }, []);

  const onDropdownChange = useCallback(
    async (domainName: string, option: IDropdownOption, headerText: string) => {
      const selectedDropdownKey = option.key.toString();
      if (!selectedDropdownKey || selectedDropdownKey === defaultConstant) {
        setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
          onSetAccordionDomainDetails(accordionDomainDetails, domainName, false, false, undefined, selectedDropdownKey, undefined, false),
        );
        return;
      }

      setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
        onSetAccordionDomainDetails(accordionDomainDetails, domainName, false, true, undefined, selectedDropdownKey, undefined, false),
      );
      const previewData = await getPreviewData(selectedDropdownKey);
      const additionalDomainDetails = previewData.evidenceData;
      const snapshotMinimal: ISnapshotMinimal = { id: selectedDropdownKey, displayName: option.text };
      const previewProps = {
        headerText,
        snapshotMinimal,
        domainEvidence: previewData,
      } as SnapshotPreviewPanelProps;
      setPreviewProps(previewProps);
      setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
        onSetAccordionDomainDetails(
          accordionDomainDetails,
          domainName,
          false,
          false,
          undefined,
          selectedDropdownKey,
          undefined,
          true,
          additionalDomainDetails,
        ),
      );
    },
    [getPreviewData, onSetAccordionDomainDetails],
  );

  const onSnapshotPreviewClick = useCallback(
    async (domainDetail: DomainDetail) => {
      // Check if the preview button they are pressing is already loaded into the preview props
      // If not, load the correct content
      if (previewProps.snapshotMinimal.id !== domainDetail.selectedDropdown) {
        const option = domainDetail.options.find((option) => option.key === domainDetail.selectedDropdown);

        if (option) {
          await onDropdownChange(domainDetail.domainName, option, domainDetail.title);
        }
      }

      openSnapshotPreviewPanel();
    },
    [openSnapshotPreviewPanel, onDropdownChange, previewProps.snapshotMinimal.id],
  );

  const createSnapshotClick = useCallback(
    async (
      serviceOid: string,
      domainName: string,
      label: string,
      additionalDomainDetail?: string,
      dependentServiceInfos?: DependentServiceInfo[],
    ) => {
      try {
        setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
          onSetAccordionDomainDetails(accordionDomainDetails, domainName, true, false, undefined, undefined, undefined, true, additionalDomainDetail),
        );
        const domainAdditionalInfo = getAdditionalDomainInfo(domainName, undefined, additionalDomainDetail, dependentServiceInfos);
        const snapshot = {
          serviceOid,
          cloudType: cloud,
          domainName,
          domainAdditionalInfo,
        } as ISnapshotPostCommand;
        const snapshotPostResponse = await createSnapshot(snapshot);
        if (snapshotPostResponse.errorMessage) {
          throw new Error(snapshotPostResponse.errorMessage);
        }
        const { snapshotMinimal } = snapshotPostResponse;
        const domainDetail = getDomainDetail(accordionDomainDetails, domainName);
        domainDetail.selectedDropdown = snapshotMinimal.id;
        if (additionalDomainDetail) {
          domainDetail.disableAdditionalDomainDetailInput = true;
        }
        setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
          onSetAccordionDomainDetails(
            accordionDomainDetails,
            domainName,
            false,
            false,
            snapshotMinimal,
            undefined,
            undefined,
            true,
            additionalDomainDetail,
          ),
        );
        showSuccess(`Successfully created ${label} snapshot.`);
        // Trigger the dropdown change function - if they click the preview button immediately after creating a snapshot, we need to know they have that snapshot selected
        await onDropdownChange(domainName, { key: snapshotMinimal.id, text: snapshotMinimal.displayName }, domainDetail.title);
      } catch (error) {
        const errorMessage = `An error occurred when attempting to create snapshot for domain: ${domainName}.`;
        logError(`${errorMessage} Service Oid: ${serviceOid}, Cloud: ${cloud}.`, error);
        showError(errorMessage);
        setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
          onSetAccordionDomainDetails(
            accordionDomainDetails,
            domainName,
            false,
            false,
            undefined,
            undefined,
            undefined,
            false,
            additionalDomainDetail,
          ),
        );
      }
    },
    [accordionDomainDetails, cloud, onDropdownChange, onSetAccordionDomainDetails, getAdditionalDomainInfo],
  );

  const handleIsExpanded = useCallback((domainName: string, isAccordionExpanded: boolean) => {
    setAccordionDomainDetails((domainDetails) => {
      const copiedAccordionDomainDetails = [...domainDetails];
      const domainDetail = getDomainDetail(copiedAccordionDomainDetails, domainName);
      domainDetail.isAccordionExpanded = isAccordionExpanded;
      return copiedAccordionDomainDetails;
    });
  }, []);

  const saveDefaultSnapshots = useCallback(
    async (accordionDomainDetails: AccordionDomainDetail[]) => {
      const promises: Promise<void>[] = [];

      accordionDomainDetails.forEach((accordionDomainDetail) => {
        if (accordionDomainDetail.domainDetail.selectedDropdown === defaultConstant && accordionDomainDetail.domainDetail.selected) {
          const { domainDetail } = accordionDomainDetail;
          handleIsExpanded(accordionDomainDetail.domainName, true);
          promises.push(
            createSnapshotClick(
              serviceOid,
              accordionDomainDetail.domainName,
              domainDetail.title,
              domainDetail.additionalDomainDetail,
              domainDetail.dependentServiceInfos,
            ),
          );
        }
      });

      try {
        await Promise.all(promises);
      } catch (error) {
        logError('There was an issue with saving default snapshots.', error);
      }
    },
    [handleIsExpanded, createSnapshotClick, serviceOid],
  );

  const onCreateEvidencePackageClick = useCallback(async () => {
    setIsSaveInProgress(true);
    await saveDefaultSnapshots(accordionDomainDetails);
    const domainInfos = accordionDomainDetails
      .filter((accordionDomainDetail) => !accordionDomainDetail.domainDetail.selectionDisabled && accordionDomainDetail.domainDetail.selected)
      .map((accordionDomainDetail) => {
        const { domainName, domainDetail } = accordionDomainDetail;
        const snapshotId = domainDetail.selectedDropdown;
        const additionalDomainDetail = getAdditionalDomainInfo(
          accordionDomainDetail.domainName,
          domainDetail.selectedDropdown === 'default' ? undefined : domainDetail.selectedDropdown,
          domainDetail.additionalDomainDetail,
          domainDetail.dependentServiceInfos,
        );
        return {
          name: domainName,
          snapshotId,
          domainAdditionalInfo: additionalDomainDetail,
          excludedSections: domainDetail.excludedSections,
        } as CreateDomainBody;
      });
    const evidencePackage = {
      serviceOid,
      certification,
      cloud,
      domainInfos,
    };

    try {
      let evidencePackageResponse: EvidencePackage;
      let createUpdateMessage: string;
      if (evidencePackageId) {
        evidencePackageResponse = await updateEvidencePackage(evidencePackageId, evidencePackage as IEvidencePackagePutCommand);
        createUpdateMessage = 'updat';
        evidenceContext.reloadEvidencePackage();
      } else {
        evidencePackageResponse = await createEvidencePackage(evidencePackage);
        createUpdateMessage = 'creat';
        evidenceContext.requestEvidencePackage(evidencePackage.serviceOid, evidencePackageResponse.id);
      }

      if (!evidencePackageResponse) {
        showError(`An error occurred when ${createUpdateMessage}ing the evidence package.`);
      } else {
        setEvidencePackage(evidencePackageResponse);
        onEvidencePackageAdded(evidencePackageResponse);
        showSuccess(`Successfully ${createUpdateMessage}ed evidence package.`);
      }
    } catch (error) {
      logError(`Error saving evidence package: ${JSON.stringify(evidencePackage)}`, error);
      showError('There was an issue saving the evidence package.');
    }
    await updateSnapshots();
    setIsSaveInProgress(false);
  }, [
    saveDefaultSnapshots,
    accordionDomainDetails,
    serviceOid,
    certification,
    cloud,
    updateSnapshots,
    getAdditionalDomainInfo,
    evidencePackageId,
    evidenceContext,
    setEvidencePackage,
    onEvidencePackageAdded,
  ]);

  useEffect(() => {
    if (saveEnabled) {
      setSaveButtonTooltip('');
    } else {
      setSaveButtonTooltip('Service must be in the Evidence Gathering stage to allow evidence package creation.');
    }
  }, [saveEnabled]);

  const getDomainDetail = (accordionDomainDetails: AccordionDomainDetail[], domainName: string): DomainDetail => {
    const accordionDomainDetail = accordionDomainDetails.find((domainDetail) => domainDetail.domainName === domainName);
    if (!accordionDomainDetail) {
      throw new Error(`The Accordion Domain Detail name: ${domainName} was not found.`);
    }
    return accordionDomainDetail.domainDetail;
  };

  const handleOnCheckboxClick = useCallback(
    (domainName: string, isChecked: boolean | undefined) => {
      setAccordionDomainDetails((domainDetails) => {
        const copiedAccordionDomainDetails = [...domainDetails];
        const domainDetail = getDomainDetail(copiedAccordionDomainDetails, domainName);
        domainDetail.selected = !!isChecked;
        updateAllDomainsSelected(copiedAccordionDomainDetails);
        return copiedAccordionDomainDetails;
      });
    },
    [updateAllDomainsSelected],
  );

  const handleOnCheckAllClick = () => {
    setAccordionDomainDetails((domainDetails) => {
      const copiedAccordionDomainDetails = [...domainDetails];
      copiedAccordionDomainDetails
        .filter((accordionDomainDetail) => !accordionDomainDetail.domainDetail.selectionDisabled)
        .forEach((accordionDomainDetail) => {
          const { domainDetail } = accordionDomainDetail;
          domainDetail.selected = !allDomainsSelected;
        });
      setAllDomainsSelected(!allDomainsSelected);
      return copiedAccordionDomainDetails;
    });
  };

  const onSetAdditionalDomainDetail = useCallback(
    (domainName: string, additionalDomainDetail: any, isInFocus: boolean, dependentServiceInfos?: DependentServiceInfo[]) => {
      const copiedAccordionDomainDetails = accordionDomainDetails.map((accordionDomainDetail) => {
        const { domainDetail } = accordionDomainDetail;
        domainDetail.isInFocus = false;
        return accordionDomainDetail;
      });
      const domainDetail = getDomainDetail(copiedAccordionDomainDetails, domainName);
      domainDetail.additionalDomainDetail = additionalDomainDetail;
      domainDetail.isInFocus = isInFocus;
      domainDetail.dependentServiceInfos = dependentServiceInfos;
      setAccordionDomainDetails(copiedAccordionDomainDetails);
    },
    [accordionDomainDetails],
  );

  const onUpdateDependentServiceInfos = useCallback(
    (domainName: string, dependentServiceInfos: DependentServiceInfo[]) => {
      const copiedAccordionDomainDetails = [...accordionDomainDetails];
      const domainDetail = getDomainDetail(copiedAccordionDomainDetails, domainName);
      domainDetail.dependentServiceInfos = dependentServiceInfos;
      setAccordionDomainDetails(copiedAccordionDomainDetails);
    },
    [accordionDomainDetails],
  );

  const progressStatusCallback = (loaded: number, total: number): void => {
    setProgressStatus({ loaded, total });
  };
  const onThreatModelFileUpload = useCallback(
    async (file: File) => {
      try {
        setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
          onSetAccordionDomainDetails(accordionDomainDetails, Domains.THREAT_MODEL, false, true, undefined, undefined, undefined, true, undefined),
        );
        await createServiceAttachment(file, serviceOid, certification, cloud, DocumentType.ThreatModel, true, progressStatusCallback);
        setProgressStatus(undefined);
        setAccordionDomainDetails((accordionDomainDetails: AccordionDomainDetail[]) =>
          onSetAccordionDomainDetails(accordionDomainDetails, Domains.THREAT_MODEL, false, false, undefined, undefined, undefined, true, undefined),
        );
        showSuccess('Successfully uploaded document.');
      } catch (error) {
        logError('Error uploading document: ', error);
        showError('There was an issue uploading the document.');
      }
    },
    [serviceOid, certification, cloud, onSetAccordionDomainDetails],
  );

  const handleOnMainServiceDependentCheckBoxClick = useCallback(() => {
    setIsMainServiceDependencySelected(!isMainServiceDependencySelected);
  }, [isMainServiceDependencySelected]);

  const onRenderOption = (option?: IDropdownOption): JSX.Element => (
    <div className={dropdownOption}>
      <>{option?.text}</>
    </div>
  );

  const snapshotActions = useCallback(
    (domainDetail: DomainDetail, domainName: string) => {
      const selectedKey = domainDetail.selectedDropdown;
      let snapshotDisplayName: string | undefined;
      if (domainDetail.selectedDropdown !== defaultConstant) {
        const dropdownOption = domainDetail.options.find((o) => o.key === selectedKey);
        snapshotDisplayName = dropdownOption?.text;
      }

      return (
        <div className={advancedItemStyle}>
          <div>
            <div className={advancedItemLabelWrapperStyle}>
              {/* Inventory and PAVC is special - we need to select whether we want the main service to be added to the dependency services array */}
              {domainName === Domains.INVENTORY_AND_PAVC && (
                <>
                  <Checkbox checked={isMainServiceDependencySelected} onChange={() => handleOnMainServiceDependentCheckBoxClick()} />
                  <Label className={dependentServiceLabelStyle}>{servicesContext.selectedService?.name}:</Label>
                </>
              )}
              <div>
                <Dropdown
                  id={domainName}
                  selectedKey={domainDetail.selectedDropdown}
                  options={domainDetail.options}
                  onRenderOption={onRenderOption}
                  styles={advancedOptionsDropdownStyles}
                  onChange={async (_event, option) => {
                    if (option) {
                      await onDropdownChange(domainName, option, domainDetail.title);
                    }
                  }}
                  disabled={domainDetail.isCreatingSnapshot}
                />
                {excludeS360EtaDomains.includes(domainName) && (
                  <Checkbox
                    label="Include S360 ETA"
                    title="Include S360 ETA"
                    checked={!domainDetail.excludedSections?.includes(sectionNames.S360Eta) ?? false}
                    onChange={(event, checked) => onSetExclusions(accordionDomainDetails, domainName, sectionNames.S360Eta, !checked)}
                  />
                )}
              </div>
            </div>
            <AdditionalDomainDetail
              domainName={domainName}
              additionalDomainDetail={domainDetail.additionalDomainDetail}
              setAdditionalDomainDetail={(additionalDomainDetail: string, isInFocus: boolean, dependentServiceInfos?: DependentServiceInfo[]) =>
                onSetAdditionalDomainDetail(domainName, additionalDomainDetail, isInFocus, dependentServiceInfos)
              }
              disableAdditionalDomainDetailInput={domainDetail.disableAdditionalDomainDetailInput}
              loading={domainDetail.isLoading}
              isInFocus={domainDetail.isInFocus}
              dependentServiceInfos={domainDetail.dependentServiceInfos}
              updateDependentServiceInfos={(domainName, dependentServiceInfos) => onUpdateDependentServiceInfos(domainName, dependentServiceInfos)}
              progressStatus={progressStatus}
              onFileUpload={onThreatModelFileUpload}
            />
          </div>
          <div className={snapshotPreviewDivStyle}>
            {domainDetail.snapshotsEnabled &&
              (domainDetail.isCreatingSnapshot ? (
                <>
                  <Spinner size={SpinnerSize.medium} className={iconAdvancedStyle} />
                </>
              ) : (
                <Icon
                  onClick={() =>
                    createSnapshotClick(
                      serviceOid,
                      domainName,
                      domainDetail.title,
                      domainDetail.additionalDomainDetail,
                      domainDetail.dependentServiceInfos,
                    )
                  }
                  className={iconAdvancedStyle}
                  iconName="Camera"
                  ariaLabel={`Create snapshot for ${domainDetail.title}`}
                  title={domainDetail.title}
                />
              ))}
            <SnapshotPreviewButton
              headerText={domainDetail.title}
              isLoadingSnapshotPreview={domainDetail.isLoading}
              snapshotPreviewButtonDisabled={domainDetail.snapshotPreviewButtonDisabled}
              onSnapshotButtonClick={() => onSnapshotPreviewClick(domainDetail)}
              snapshotDisplayName={snapshotDisplayName}
            />
          </div>
        </div>
      );
    },
    [
      isMainServiceDependencySelected,
      servicesContext.selectedService?.name,
      progressStatus,
      onThreatModelFileUpload,
      handleOnMainServiceDependentCheckBoxClick,
      onDropdownChange,
      onSetAdditionalDomainDetail,
      onUpdateDependentServiceInfos,
      createSnapshotClick,
      serviceOid,
      onSnapshotPreviewClick,
      accordionDomainDetails,
      onSetExclusions,
      excludeS360EtaDomains,
    ],
  );

  const onNextButtonClick = useCallback(async () => onCreateEvidencePackageClick(), [onCreateEvidencePackageClick]);

  const domainElements = useCallback(
    (accordionDomainDetails: AccordionDomainDetail[]) =>
      accordionDomainDetails.map((accordionDomainDetail: AccordionDomainDetail) => {
        const { domainDetail, domainName } = accordionDomainDetail;

        const header = <span className={headerStyle}>{accordionDomainDetail.domainDetail.title}</span>;

        const checkboxProps = {
          checked: domainDetail.selected,
          disabled: domainDetail.selectionDisabled,
          onChange: (event, isChecked) => handleOnCheckboxClick(domainName, isChecked),
        } as ICheckboxProps;

        return (
          <div key={domainName}>
            <div className={flexRowStyle}>
              <div className={domainContentStyle}>
                <Accordion
                  headerElement={header}
                  styles={accordionStyle}
                  isExpanded={domainDetail.isAccordionExpanded}
                  disabledTitle={domainDetail.disabledTitle}
                  onExpanded={() => handleIsExpanded(domainName, !domainDetail.isAccordionExpanded)}
                  checkBoxProps={checkboxProps}
                >
                  {snapshotActions(domainDetail, domainName)}
                </Accordion>
              </div>
            </div>
          </div>
        );
      }),
    [handleIsExpanded, handleOnCheckboxClick, snapshotActions],
  );

  const DomainTable = () => (
    <div>
      <div className={domainTitleRowStyle}>
        <div
          className={mergeStyles({
            display: 'flex',
          })}
        >
          <Checkbox checked={allDomainsSelected} onChange={handleOnCheckAllClick} title="Select all domains" />
        </div>
        <div className={domainTitleStyle}>
          <h3>Domains</h3>
        </div>
      </div>
      <div className={domainElementsContainerStyle}>{domainElements(accordionDomainDetails)}</div>
    </div>
  );

  const saveButtonDisabled =
    !saveEnabled || isSaveInProgress || !accordionDomainDetails.some((accordionDomainDetail) => accordionDomainDetail.domainDetail.selected);

  return (
    <div className={wrapperStyle}>
      <SavePrompt when={saveEnabled} />
      <DomainTable />
      <div className={footerStyle}>
        <WizardNavigation
          isNextDisabled={saveButtonDisabled}
          nextButtonText="Next: Additional Documentation"
          isNextVisible
          isNextLoading={isSaveInProgress}
          isNextTitle={saveButtonTooltip}
          isPreviousVisible
          onClickNextButton={onNextButtonClick}
          onClickPreviousButton={onClickPreviousButton}
        />
      </div>
      <SnapshotPreviewPanel
        onDismissSnapshotPreviewPanel={onDismissSnapshotPreviewPanel}
        headerText={previewProps.headerText}
        isSnapshotPreviewOpen={isSnapshotPreviewOpen}
        snapshotMinimal={previewProps.snapshotMinimal}
        domainEvidence={previewProps.domainEvidence}
        serviceOid={serviceOid}
        serviceName={servicesContext.selectedService?.name || ''}
      />
    </div>
  );
};
