import { ITextFieldStyles, NeutralColors } from '@fluentui/react';

/**
 * A custom class for the fluent UI <PrimaryButton> component to style it properly with MTAC's current AzureThemeLight theme
 * @example
 * <PrimaryButton className={textFieldStyles} iconProps={{ iconName: 'Undo' }}>
 *     Undo
 * </PrimaryButton>
 */
export const textFieldStyles: Partial<ITextFieldStyles> = {
  root: {
    margin: '0.5em 0',
    '.ms-Label': {
      fontWeight: '600 !important',
    },
    'input::placeholder': {
      fontStyle: 'unset',
    },
  },
  fieldGroup: {
    padding: '0.25em 0.5em !important',
    color: '#707070 !important',
    height: 'unset !important',
    border: '1px solid lightgrey !important',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'left',
    width: 300,
    fontSize: '14px',
    ':focus': {
      border: `1px solid ${NeutralColors.black} !important`,
    },
  },
  field: {
    fontSize: '14px',
  },
};
