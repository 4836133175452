import React, { useContext } from 'react';
import { mergeStyles } from '@fluentui/react';
import { ConfigContext } from 'components/configProvider/configContext';

export const MetricsDashboard: React.FunctionComponent = () => {
  const iframeStyle = mergeStyles({
    display: 'block',
    width: '100%',
    height: '90%',
    border: 'none',
    overflowY: 'auto',
    overflowX: 'hidden',
  });

  const configContext = useContext(ConfigContext);
  return (
    <iframe
      title="MTAC Metrics"
      aria-label="MTAC Metrics iframe"
      className={iframeStyle}
      src={configContext.serverConfig?.metricsDashboardLink}
      frameBorder="0"
    />
  );
};
