import { ISortableCuratedTenantMonitoringDataListResponse } from '../tenantGovernance';
import { MetadataDataPoint } from '../tenantGovernanceComplianceOverview';

export const getComplianceCount = (curatedTenants: ISortableCuratedTenantMonitoringDataListResponse[]): MetadataDataPoint => {
  const totalTenantCount = getCuratedTenantCount(curatedTenants);
  const totalTenantCompliant = curatedTenants.filter((curatedTenant) => curatedTenant.isCompliant === undefined || curatedTenant.isCompliant).length;
  return getMetadataDataPoint(totalTenantCompliant, totalTenantCount - totalTenantCompliant);
};

export const getPolicyViolationCount = (curatedTenants: ISortableCuratedTenantMonitoringDataListResponse[]): MetadataDataPoint => {
  const totalTenantCount = getCuratedTenantCount(curatedTenants);
  const totalTenantWithPolicyViolation = curatedTenants.filter(
    (curatedTenant) => curatedTenant.policiesViolatedCount && curatedTenant.policiesViolatedCount > 0,
  ).length;
  return getMetadataDataPoint(totalTenantCount - totalTenantWithPolicyViolation, totalTenantWithPolicyViolation);
};

export const getPolicyDriftsCount = (curatedTenants: ISortableCuratedTenantMonitoringDataListResponse[]): MetadataDataPoint => {
  const totalTenantCount = getCuratedTenantCount(curatedTenants);
  const totalTenantWithResourceDrifts = curatedTenants.filter(
    (curatedTenant) => curatedTenant.resourcesDriftCount && curatedTenant.resourcesDriftCount > 0,
  ).length;
  return getMetadataDataPoint(totalTenantCount - totalTenantWithResourceDrifts, totalTenantWithResourceDrifts);
};

export const getMetadataDataPoint = (compliantCount: number, noncompliantCount: number): MetadataDataPoint => ({
  dataCompliant: { x: 'Compliant Customers', y: compliantCount },
  dataNonCompliant: { x: 'Non-compliant Customers', y: noncompliantCount },
});

const getCuratedTenantCount = (curatedTenants: ISortableCuratedTenantMonitoringDataListResponse[]): number => curatedTenants.length;
