import { EvidenceComment } from '../../generated/clientApi';
import { ReviewCommentType } from './reviewComments';

export const getValue = (domainName: string, evidenceComments?: EvidenceComment[]): string | undefined => {
  let value;
  evidenceComments?.forEach((comment) => {
    if (comment.domain === domainName) {
      value = comment.comment;
    }
  });
  return value;
};

export const addSpace = (str: string): string => {
  const newString = str.replace(/([A-Z])/g, (match) => ` ${match}`);
  return newString;
};

export const getHeaderText = (reviewCommentType: ReviewCommentType): string => {
  switch (reviewCommentType) {
    case ReviewCommentType.AOReview:
      return 'Summary';
    case ReviewCommentType.AuditorReview:
      return 'Auditor summary & documentation';
    case ReviewCommentType.Domain:
    default:
      return 'Supporting comments';
  }
};
