import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { useQueryParameters } from 'components/util/useQueryParameters';
import { getConmonUserOrganizations } from 'modules/user/user';
import { ConmonOrganizationType } from 'generated/clientApi';
import { ConMonFilterContext } from './conMonFilterContext';

export const ConMonFilterProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const queryParams = useQueryParameters();

  const organization = (queryParams.organization as ConmonOrganizationType) ?? undefined;
  const [readonly, setReadonly] = useState<boolean>(true);
  const [userOrgs, setUserOrgs] = useState<string[]>([]);

  const contextProps = {
    readonly,
    organization,
  };

  const fetchUserOrgs = async () => {
    const orgs = await getConmonUserOrganizations();
    setUserOrgs(orgs.map((org) => org.id));
  };

  useEffect(() => {
    fetchUserOrgs();
  }, []);

  useEffect(() => {
    setReadonly(!userOrgs.includes(organization));
  }, [organization, userOrgs]);

  return <ConMonFilterContext.Provider value={contextProps}>{children}</ConMonFilterContext.Provider>;
};
