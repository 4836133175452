/* DEVELOPER NOTE: These changes are server side rendered and following files SHOULD maintain parity.
 * Changes are copied from the API project to the UX project on build.
 *
 * API project : src\Microsoft.Mtac.Portal.Api\Content\Components\domainEvidenceData.tsx
 * UX project : src\Microsoft.Mtac.Portal.Ux\ClientApp\src\components\domainEvidenceData\domainEvidenceData.tsx
 *
 * On change that would affect the pdf download, be sure to run "npm run build" in the API folder.
 * Otherwise, the changes will not reflect on download.
 * I also had to turn the API's "strict" mode to false in the tsconfig.json to get this to work
 */

import { mergeStyles, NeutralColors } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import { IDomainEvidence, SupplementalInfo } from '../../generated/clientApi';
import { TextWithLineBreaksViewer } from './textWithLineBreaksViewer';
import {
  getDescription,
  shouldRenderEvidenceSection,
  transformLabels,
} from './domainEvidenceData.functions';
import { SupplementalData } from './supplementalData';

const wrapperStyle = mergeStyles({
  width: '-webkit-fill-available',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  padding: '0.2em',
});

const routingTableEntry = mergeStyles({
  paddingLeft: '0px',
});

const routingIdTitle = mergeStyles({
  margin: '0px',
  paddingBottom: '0px',
  fontWeight: 'bold',
});

const routingRuleTableHeader = mergeStyles({
  fontWeight: 'bold',
  display: 'block',
});

const routingRuleTableStyle = mergeStyles({
  paddingInlineEnd: '16px',
  paddingInlineStart: '3px',
  marginTop: '10px',
  border: `1px solid ${NeutralColors.black}`,
});

const tableBorder = mergeStyles({
  border: `1px solid ${NeutralColors.black}`,
});

const tableStyle = mergeStyles(tableBorder, {
  tableLayout: 'fixed',
  width: '100%',
});

const rowStyle = mergeStyles(tableBorder, {
  wordBreak: 'break-word',
});

const sectionStyle = mergeStyles({
  overflowX: 'hidden',
});

const labelStyle = mergeStyles({
  marginBottom: '1em',
});

const tableHeader = mergeStyles({
  display: 'block',
});

const getFormattedDateTime = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  return `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()} ${formattedDate.toLocaleString('en-US',
    { hour: 'numeric', minute: 'numeric', hour12: true },
  )}`;
};

interface DomainEvidenceDataProps {
  title: string,
  domainEvidence: IDomainEvidence,
  serviceOid: string,
  serviceName: string,
  shouldRenderAttachments: boolean,
  evidenceAttachments?: JSX.Element,
  shouldRenderComments: boolean,
  evidenceComments?: JSX.Element,
  supplementalInfoElement?: JSX.Element,
  isReadOnly: boolean,
  supplementalInfo?: SupplementalInfo,
}

export const DomainEvidenceData: FunctionComponent<DomainEvidenceDataProps> = (props) => {
  const { domainEvidence, serviceName, isReadOnly, supplementalInfo } = props;
  const evidenceData = JSON.parse(domainEvidence.evidenceData);
  const icMRoutingRulesConstant = 'IcMRoutingRules.xlsx';
  const incidentManagementConstant = 'IncidentManagement';
  const attachmentObject = domainEvidence.attachmentInfo
    ? domainEvidence.attachmentInfo.find((attachment) => attachment.attachmentName?.endsWith(icMRoutingRulesConstant))
    : undefined;
  const routingRuleName = attachmentObject?.attachmentName ? `${incidentManagementConstant}\\${icMRoutingRulesConstant}` : '';

  const customLabeledDataHandling = (label: string, data: any) => {
    switch (label) {
      case 'percentage':
        return `${Math.trunc(data * 100.0)}%`;
      default:
        return data;
    }
  };

  const renderStringOrArrayAsString = (label: string, data: any) => {
    if (Array.isArray(data)) {
      return data.join(', ');
    }
    return `${customLabeledDataHandling(label, data)}`;
  };

  const renderSectionObject = (data: any) => (
    <table className={tableStyle}>
      <tbody>
        {Object.entries(data).map((d) => (
          <tr className={rowStyle} key={d[0]}>
            <th className={rowStyle}>{transformLabels(d[0])}</th>
            <td className={rowStyle}>
              {renderStringOrArrayAsString(d[0], d[1])}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderSectionArray = (name: string, data: any[]) => {
    if (data.length === 0) {
      return <></>;
    }
    const firstElement = data[0];

    return (
      <>
        {name !== 'routingRules' ? (
          renderTables(name, data, firstElement)
        ) : renderRoutingRules(name, data)}
      </>

    );
  };

  const renderTables = (name: string, data: any[], firstElement: any) => (
    <table className={tableStyle}>
      <thead>
        <tr>
          {Object.keys(firstElement).map((prop) => (
            <th key={`${name}${prop}`}>
              {transformLabels(prop)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((d, index) => (
          // Using the array index as a key is a method to create a unique key. Since the data isn't reordered, this ESLint disable line is added and shouldn't introduce any side effects.
          // eslint-disable-next-line react/no-array-index-key
          <tr key={`${domainEvidence.name}${name}${index}`}>
            {Object.values(d).map((value, indexV) => (
              // Using the array index as a key is a method to create a unique key. Since the data isn't reordered, this ESLint disable line is added and shouldn't introduce any side effects.
              // eslint-disable-next-line react/no-array-index-key
              <td key={`${domainEvidence.name}${name}${index}${value}${indexV}`} className={tableStyle}>{renderStringOrArrayAsString(Object.keys(data[index])[indexV], value)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderRoutingRules = (name: string, data: any[]) => {
    const routingRulesLimit = 5;
    const routingRulesHead = data.slice(0, routingRulesLimit);
    const routingRulesLength = data.length;

    return (
      <div>
        {routingRulesHead.map((d, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${domainEvidence.name}${name}${index}`} className={routingTableEntry}>
            {Object.entries(d).map(([prop, value]) => (
              <div key={`${prop}`}>
                {prop === 'routingId' ? (
                  <>
                    <div className={routingIdTitle}>
                      Service Name:
                      {' '}
                      {serviceName}
                      <br />
                      {prop}
                      :
                      {' '}
                      {renderStringOrArrayAsString(prop, value)}
                    </div>
                  </>
                ) : (
                  <div>
                    {prop}
                    :
                    {' '}
                    {renderStringOrArrayAsString(prop, value)}
                  </div>
                )}
              </div>
            ))}
            <br />
          </div>
        ))}

        {routingRulesLength > routingRulesLimit ? (
          <table className={routingRuleTableStyle}>
            <thead className={routingRuleTableHeader}>
              <tr>
                <th>Attached to the evidence package</th>
              </tr>
            </thead>
            <tbody className={tableHeader}>
              <tr>
                <td>
                  Full list of
                  {' '}
                  {routingRulesLength}
                  {' '}
                  routing rules:
                  {' '}
                  {routingRuleName}
                </td>
              </tr>
            </tbody>
          </table>
        ) : <></>}
      </div>
    );
  };

  const renderSectionData = (name: string, data: any) => {
    if (data === null || data === undefined) {
      return '';
    }

    if (Array.isArray(data)) {
      return renderSectionArray(name, data);
    }

    if (typeof data === 'object') {
      return renderSectionObject(data);
    }

    return `${customLabeledDataHandling(name, data)}`;
  };

  const renderSection = ([name, data]: any[]) => {
    const description = getDescription(props.title, name);
    return (
      shouldRenderEvidenceSection(domainEvidence.name, evidenceData, name, domainEvidence.excludedSections) && (
        <div key={name} className={sectionStyle}>
          <h3>{transformLabels(name)}</h3>
          {description && <div className={labelStyle}>{description}</div>}
          {renderSectionData(name, data)}
        </div>
      )
    );
  };

  const renderSupplementalText = () => (
    <SupplementalData
      domainName={domainEvidence.name}
      serviceDescriptionElement={<>{supplementalInfo?.serviceTreeSupplementalInfo?.description}</>}
      serviceUrlElement={<>{supplementalInfo?.serviceTreeSupplementalInfo?.endpoint}</>}
      dataFlowNarrativeElement={<>{supplementalInfo?.dataFlowSupplementalInfo?.narrative}</>}
    />
  );

  const renderEvidenceData = () => {
    if (!domainEvidence.evidenceData) {
      return <></>;
    }
    const evidenceData = JSON.parse(domainEvidence.evidenceData);

    return Object.entries(evidenceData).map(renderSection);
  };

  return (
    <div className={wrapperStyle}>
      <h1>{props.title}</h1>
      <span>
        {`Evidence collected on: ${getFormattedDateTime(
          domainEvidence.createdDate,
        )}`}
      </span>
      <TextWithLineBreaksViewer text={domainEvidence.description} />
      {domainEvidence.dataSources && (
        <>
          <h2>Data Sources</h2>
          {domainEvidence.dataSources.map<JSX.Element>((ds) => (
            <div key={`${domainEvidence.name}Sources`}>
              {'Sources: '}
              {ds.sources?.map((source) => (
                <li key={source}>{source}</li>
              ))}
              <br />
              {'Parameter: '}
              {ds.parameter}
            </div>
          ))}
        </>
      )}

      {props.shouldRenderAttachments && (
        <>
          <h2>Attachments</h2>
          {props.evidenceAttachments}
        </>
      )}
      <br />
      {isReadOnly ? (
        <>
          {renderSupplementalText()}
        </>
      ) : (
        <>
          {props.supplementalInfoElement}
        </>
      )}
      {renderEvidenceData()}

      {props.shouldRenderComments && props.evidenceComments}
    </div>
  );
};
