import { Link, mergeStyles } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BladeHeader } from '../../components/bladeHeader/bladeHeader';
import { AtoAOrganization } from '../../generated/clientApi';
import { showError } from '../../modules/messageBar/messageBar';
import { getUserOrganizations } from '../../modules/user/user';
import { HorizontalRule } from '../../styles/horizontalRule';

const containerStyles = mergeStyles({
  display: 'grid',
  gridTemplateColumns: '13em auto',
  padding: '1em',
});

const labelStyles = mergeStyles({
  fontWeight: 'bold',
  flexBasis: 'content',
  padding: '0.5em',
});

const textStyles = mergeStyles({
  textAlign: 'left',
  padding: '0.5em',
});

interface OrganizationDetailsRouteParams {
  id: string;
}

export const OrganizationDetails: FunctionComponent = () => {
  const { id } = useParams<OrganizationDetailsRouteParams>();
  const [organization, setOrganizion] = useState({} as AtoAOrganization);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const organizations = await getUserOrganizations();
      const organization = organizations.find((org) => org.organizationId === id);
      if (!organization) {
        showError('The organization was not found. Please refresh and try again.');
        return;
      }
      setOrganizion(organization);
    };
    fetchOrganizations();
  }, [id]);

  return (
    <>
      <BladeHeader titleText="Organization Details" titleTextSuffixes={[organization.name ?? '']} />
      <HorizontalRule />
      <div className={containerStyles}>
        <span className={labelStyles}>Name:</span>
        <span className={textStyles}>{organization.name}</span>

        <span className={labelStyles}>Platform:</span>
        <span className={textStyles}>{organization.platformLabel}</span>

        <span className={labelStyles}>Implementing Catalog:</span>
        <Link className={textStyles} href={organization.implementationCatalogDetail?.repoAbsoluteUrl}>
          {organization.implementationCatalogDetail?.title}
        </Link>
      </div>
    </>
  );
};
