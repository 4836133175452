import { IContextualMenuProps, IContextualMenuItem, IIconProps } from '@fluentui/react';

export const createContextMenuItem = (
  key: string,
  text: string,
  href: string,
  onClick?: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => boolean | void,
  iconProps?: IIconProps,
): IContextualMenuItem => ({
  key,
  text,
  href,
  onClick,
  iconProps,
});

export const getMenuProps = (menuItems: IContextualMenuItem[]): IContextualMenuProps => ({
  shouldFocusOnMount: true,
  items: menuItems,
});
