import {
  ScrollablePane,
  ScrollbarVisibility,
  IScrollablePaneStyles,
  ShimmeredDetailsList,
  IColumn,
  DetailsListLayoutMode,
  SelectionMode,
  mergeStyles,
} from '@fluentui/react';
import { ServiceTreeAssetCloud } from 'generated/clientApi';
import React, { useMemo } from 'react';
import { renderFixedDetailsHeader } from '../components/tableUtils';

const customTableStyles = mergeStyles({
  '.ms-Viewport': {
    minWidth: 'inherit',
  },
  overflow: 'auto',
  width: '100%',
  position: 'relative',
});

type ExcludingCoverageTableProps = {
  selectedItems: ServiceTreeAssetCloud[];
};
export const ExcludingCoverageTable: React.FunctionComponent<ExcludingCoverageTableProps> = (props) => {
  const { selectedItems } = props;

  const columns = useMemo((): IColumn[] => {
    const defaultCols: IColumn[] = [
      {
        key: 'serviceTreeName',
        name: 'Service Tree name',
        fieldName: 'serviceTreeName',
        minWidth: 200,
        maxWidth: 200,
      },
      { key: 'cloudType', name: 'Cloud type', fieldName: 'cloudType', minWidth: 100, maxWidth: 100, isMultiline: true },
      { key: 'assetType', name: 'Asset type', fieldName: 'assetType', minWidth: 150, maxWidth: 150 },
    ];

    return defaultCols;
  }, []);

  return (
    <div style={{ height: '300px', overflow: 'auto' }}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={customTableStyles as unknown as IScrollablePaneStyles}>
        <ShimmeredDetailsList
          items={selectedItems}
          columns={columns}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={renderFixedDetailsHeader}
        />
      </ScrollablePane>
    </div>
  );
};
