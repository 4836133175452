import { ICommandBarItemProps, NeutralColors } from '@fluentui/react';
import { DeepReadonly } from 'ts-essentials';
import { createCatalogNavLinkGroup } from 'modules/navMenu/catalogNavMenu';
import { IPageChromeConfig, findNavItem } from './pageChrome';

export const createControlCatalogBladeChromeConfig = (
  pageId: string,
  commandBarItems?: DeepReadonly<ICommandBarItemProps[]>,
): DeepReadonly<IPageChromeConfig> => {
  const navLinkGroups = [...createCatalogNavLinkGroup(false)];
  const selectedNavLink = findNavItem(navLinkGroups, pageId);
  return {
    pageId,
    title: 'Control Catalog',
    contentBackgroundColor: NeutralColors.white,
    isBlade: true,
    bladeNavGroups: navLinkGroups as any,
    bladePageTitles: [selectedNavLink?.name || ''],
    bladeCommandBarItems: commandBarItems as any,
  };
};
