import download from 'downloadjs';
import {
  ComplianceSelfTestListClient,
  ComplianceSelfTestListResponse,
  CstMarkdownToWordPostClient,
  CstMarkdownToWordPostCommand,
  CustomerFacingReportExcelGetClient,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';
import { showError } from '../messageBar/messageBar';

export const getComplianceSelfTestList = async (): Promise<ComplianceSelfTestListResponse> => {
  const complianceSelfTestListClient = new ComplianceSelfTestListClient(getConfig().apiBaseUri);
  return complianceSelfTestListClient.get();
};

export const getWordDocument = async (repoId: string, paths: string[]): Promise<void> => {
  try {
    const fileName = `compliance_self_test_${new Date().toISOString()}.docx`;
    const client = new CstMarkdownToWordPostClient(getConfig().apiBaseUri);
    const command = { repoId, paths } as CstMarkdownToWordPostCommand;
    const response = await client.post(command);
    download(response.data, fileName, 'application/vnd.ms-word');
  } catch (ex) {
    showError('An error occurred while generating the CST word document. Please refresh and try again.');
  }
};

export const getCustomerFacingExcel = async (systemId: string, responsibleRoles: string[]): Promise<void> => {
  try {
    const fileName = `CustomerFacingControl_${new Date().toISOString()}.xlsx`;
    const client = new CustomerFacingReportExcelGetClient(getConfig().apiBaseUri);
    const response = await client.get(systemId, responsibleRoles);
    download(response.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  } catch (ex) {
    showError('An error occurred while generating the CST word document. Please refresh and try again.');
  }
};
