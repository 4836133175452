import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { useMtacIsAuthenticated } from 'hooks/useMtacIsAuthenticated';
import { getSystemsList } from 'modules/system/system';
import { AtoAOrganization, AuthorizedSystem } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { logError } from '../../modules/logging/logging';
import { getUserOrganizations } from '../../modules/user/user';
import { UserContext, UserContextProps } from './userContext';

export const UserProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [systems, setSystems] = useState<AuthorizedSystem[]>([]);
  const [organizations, setOrganizations] = useState<AtoAOrganization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<AtoAOrganization>();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const isUserAuthenticated = useMtacIsAuthenticated();

  useEffect(() => {
    const getUserData = async () => {
      try {
        const organizations = await getUserOrganizations();
        if (organizations && organizations.length > 0) {
          setOrganizations(organizations);
          // Set an initial organization here
          setSelectedOrganization(organizations[0]);
        }
        setLoadingState(LoadingState.Loaded);
      } catch (error) {
        setLoadingState(LoadingState.Error);
        logError('There was an issue loading the user', error);
      }
    };

    if (isUserAuthenticated && loadingState === LoadingState.NotLoaded) {
      setLoadingState(LoadingState.Requested);
      getUserData();
    }
  }, [loadingState, organizations, isUserAuthenticated]);

  useEffect(() => {
    if (selectedOrganization) {
      const fetchSystems = async () => {
        setLoadingState(LoadingState.Requested);
        
        // not all users have access to systems, so fail silently
        try {
          const systems = await getSystemsList(selectedOrganization.id);
          setSystems(systems);
        } catch (error) {
          logError('There was an issue fetching the systems', error);
        }
        setLoadingState(LoadingState.Loaded);
      };

      fetchSystems();
    }
  }, [selectedOrganization]);

  const contextProps: UserContextProps = {
    organizations,
    systems,
    selectedOrganization,
    userLoadingState: loadingState,
    requestUserInformation: () => {
      if (loadingState === LoadingState.NotLoaded || loadingState === LoadingState.Error) {
        setLoadingState(LoadingState.Requested);
      }
    },
    updateSelectedOrganization: (organizationId: string) => {
      const organization = organizations.find((organization) => organization.id === organizationId);
      if (organization) {
        setSelectedOrganization(organization);
      }
    },
  };

  return <UserContext.Provider value={contextProps}>{children}</UserContext.Provider>;
};
