import { CuratedTenantMonitoringDataListResponse } from 'generated/clientApi';

export const getTenantName = (curatedTenantDataList: CuratedTenantMonitoringDataListResponse[]): string[] => {
  const tenantNames = curatedTenantDataList.map((x) => x.tenantName ?? '');
  return findUniqueValuesAndSort(tenantNames);
};

export const getCustomerName = (curatedTenantDataList: CuratedTenantMonitoringDataListResponse[]): string[] => {
  const tenantCustomerNames = curatedTenantDataList.map((x) => x.customerName ?? '');
  return findUniqueValuesAndSort(tenantCustomerNames);
};

export const getCompliantState = (curatedTenantDataList: CuratedTenantMonitoringDataListResponse[]): string[] => {
  const tenantCompliantList = curatedTenantDataList.map((x) => isCuratedTenantCompliant(x));
  return findUniqueValuesAndSort(tenantCompliantList);
};

const findUniqueValuesAndSort = (curatedTenantDataList: string[]): string[] => {
  const uniqueValues = curatedTenantDataList.filter((value, index) => curatedTenantDataList.indexOf(value) === index);
  return uniqueValues.sort();
};

export const getViolationTypes = (curatedTenantDataList: CuratedTenantMonitoringDataListResponse[]): string[] => {
  const res: string[] = [];

  if (curatedTenantDataList.findIndex((x) => x.policiesViolatedCount && x.policiesViolatedCount > 0) !== -1) {
    res.push('Policy violation');
  }

  if (curatedTenantDataList.findIndex((x) => x.resourcesDriftCount && x.resourcesDriftCount > 0) !== -1) {
    res.push('Policy drift');
  }

  return res;
};

export const isCuratedTenantCompliant = (curatedTenantMonitoring: CuratedTenantMonitoringDataListResponse): string => {
  if (curatedTenantMonitoring.scanOrchestrationId) {
    return curatedTenantMonitoring.isCompliant ? 'Yes' : 'No';
  }
  return 'Not Available';
};

export const showCuratedTenantCompliantStyle = (curatedTenantMonitoring: CuratedTenantMonitoringDataListResponse): string => {
  if (curatedTenantMonitoring.scanOrchestrationId) {
    return curatedTenantMonitoring.isCompliant ? 'teal' : 'silver';
  }
  return 'silver';
};

export const showCuratedTenantCompliantIcon = (curatedTenantMonitoring: CuratedTenantMonitoringDataListResponse): string => {
  if (curatedTenantMonitoring.scanOrchestrationId) {
    return curatedTenantMonitoring.isCompliant ? 'SkypeCircleCheck' : 'StatusCircleOuter';
  }
  return 'StatusCircleOuter';
};