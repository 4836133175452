export function convertArrayToCSV(items: { [key: string]: any }[], title: string, downloadLink: HTMLAnchorElement | null): void {
  // Extract the keys from the first item as CSV headers
  const headers = Object.keys(items[0]);

  // Convert each item into a CSV row
  const rows = items.map((item) => headers.map((header) => item[header]).join(','));

  // Combine the headers and rows into a single CSV string
  const csv = [headers.join(','), ...rows].join('\n');

  const report = new Blob([csv], { type: 'text/csv' });
  const fileName = `${title}_${getDateTimeForFilename()}`;

  if (downloadLink) {
    initiateBrowserDownload(report, fileName, 'csv', downloadLink);
  }
}

const timeToString = (date: Date, defaultValue?: string): string => {
  if (date) {
    const parsedDate = new Date(date);
    if (!Number.isNaN(parsedDate.getTime())) {
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const day = String(parsedDate.getDate()).padStart(2, '0');
      const year = String(parsedDate.getFullYear());
      const hours = String(parsedDate.getHours()).padStart(2, '0');
      const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
      return `${month}_${day}_${year}_${hours}${minutes}`;
    }
  }
  return defaultValue ?? '';
};

function getDateTimeForFilename(reportDate?: Date | null): string {
  const date = reportDate ?? new Date();
  return timeToString(date);
}

function initiateBrowserDownload(
  blobData: Blob,
  fileName: string,
  fileExt: string,
  downloadLink: HTMLAnchorElement,
  windowObj: Window & typeof globalThis = window,
): void {
  // eslint-disable-next-line no-param-reassign
  downloadLink.href = windowObj.webkitURL.createObjectURL(blobData);
  // eslint-disable-next-line no-param-reassign
  downloadLink.download = `${fileName}.${fileExt}`;
  downloadLink.click();
}
