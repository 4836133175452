import { IStackTokens, Stack, mergeStyles } from '@fluentui/react';
import React, {
  useEffect,
  useState,
} from 'react';
import { CustomLegend } from 'components/charts/customLegend';
import { StackedBarChart } from 'components/charts/stackedBarChart';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { logError } from 'modules/logging/logging';
import { getCuratedTenantMonitoringHistory } from 'modules/tenanceGovernance/curatedTenant';
import { LoadingState } from '../../models/loadingState';
import { getEndDateForCuratedTenantChartDisplay, getStartDateForCuratedTenantChartDisplay, getTimelineDisplayChartData, getTotalPolicyDrifts, getTotalPolicyViolations } from './functions/tenantGovernanceDetailsTimeline.functions';

const horizontalStackToken: IStackTokens = {
  childrenGap: 5,
};

const chartStyles = mergeStyles({
  margin: '3em 0',
  padding: '0 1em 1em',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
});

const legendStyles = mergeStyles({
  marginLeft: '25px',
});

export interface ActionItemKeys {
  openActionItemsKey: string,
  closedActionItemsKey: string,
}

export enum ComplianceHistoryType {
  PolicyViolation = 'PolicyViolation',
  PolicyDrift = 'PolicyDrift',
}

export interface CuratedTenantTimelineChartData {
  date: string;
  count: number;
  complianceType: ComplianceHistoryType;
}

export interface TenantGovernanceDetailsTimelineProps {
  tenantId: string;
}

export const TenantGovernanceDetailsTimeline: React.FunctionComponent<TenantGovernanceDetailsTimelineProps> = (props) => {
  const { tenantId } = props;
  const [barChartData, setBarChartData] = useState<CuratedTenantTimelineChartData[]>([]);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [totalPolicyViolationCount, setTotalPolicyViolationCount] = useState<number>(0);
  const [totalPolicyDriftCount, setTotalPolicyDriftCount] = useState<number>(0);

  useEffect(() => {
    const fetchTenantDetails = async () => {
      try {
        const today = new Date();
        const endDate = getEndDateForCuratedTenantChartDisplay(today);
        // this get start date method mutates the today object so its being calculated after the end date
        const startDate = getStartDateForCuratedTenantChartDisplay(today);
        const curatedTenantDetails = await getCuratedTenantMonitoringHistory(tenantId, startDate, endDate);

        if (curatedTenantDetails && curatedTenantDetails.dailyScanHistories && curatedTenantDetails.dailyScanHistories.length > 0) {
          const displayChartData = getTimelineDisplayChartData(curatedTenantDetails.dailyScanHistories);
          const sumPolicyViolation = getTotalPolicyViolations(curatedTenantDetails.dailyScanHistories);
          const sumPolicyDrift = getTotalPolicyDrifts(curatedTenantDetails.dailyScanHistories);

          setBarChartData(displayChartData);
          setTotalPolicyViolationCount(sumPolicyViolation);
          setTotalPolicyDriftCount(sumPolicyDrift);
          setLoadingState(LoadingState.Loaded);
        } else {
          setLoadingState(LoadingState.NotLoaded);
        }
      } catch (error) {
        setLoadingState(LoadingState.Error);
        logError(`There was an issue loading the curated tenant timeline for tenant with ID ${tenantId}`, error);
      }
    };
    fetchTenantDetails();
  }, [tenantId]);

  const viewportBasedWidth = Math.max(document.documentElement.clientWidth || 0,
    window.innerWidth || 0)
    * 0.7;
  const chartProps = {
    x: (d: CuratedTenantTimelineChartData) => d.date,
    y: (d: CuratedTenantTimelineChartData) => d.count,
    z: (d: CuratedTenantTimelineChartData) => d.complianceType,
    colors: ['#F7894A', '#228286'],
    width: viewportBasedWidth,
  };

  const getLegend = (policyViolationCounts: number, policyDriftCounts: number) => [
    {
      title: 'Policy violations',
      subtitle: 'over 30 days',
      count: policyViolationCounts,
      color: '#228286',
    },
    {
      title: 'Policy drifts',
      subtitle: 'over 30 days',
      count: policyDriftCounts,
      color: '#F7894A',
    },
  ];

  return (
    <Stack className={chartStyles}>
      <h2>Compliance timeline</h2>
      <Stack horizontal tokens={horizontalStackToken}>
        {loadingState !== LoadingState.Loaded ? (
          <CenteredProgressDots />
        ) : (
          <Stack verticalAlign="start" grow>
            <Stack.Item align="start">
              <StackedBarChart data={barChartData} props={chartProps} />
            </Stack.Item>
            <Stack.Item align="start" shrink grow className={legendStyles}>
              <Stack horizontal grow>
                <CustomLegend legends={getLegend(totalPolicyViolationCount, totalPolicyDriftCount)} />
              </Stack>
            </Stack.Item>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
