import { FontIcon, IColumn, SelectionMode, ShimmeredDetailsList, mergeStyles } from '@fluentui/react';
import { convertArrayToCSV } from 'modules/common/csvReport';
import { renderFixedDetailsHeader } from 'pages/conMon/components/tableUtils';
import React, { MutableRefObject, useMemo, useRef } from 'react';

export const AssetInformationColumnNames = {
  'Service Tree': 'Service Tree',
  'Asset type': 'Asset type',
  'Affected count': 'Affected count',
  'Total count': 'Total count',
  'Is excluded': 'Is excluded',
} as const;

export type ServiceAssetTableObject = {
  serviceTreeName?: string;
  assetType?: string;
  affectedAssetCount: number;
  inventoryAssetCount: number;
  exclude: string | boolean;
};

const customTableStyles = mergeStyles({
  '.ms-Viewport': {
    minWidth: 'inherit',
  },
  overflow: 'auto',
  width: '100%',
  position: 'relative',
  margin: '2rem 0',
  paddingLeft: '0.5rem',
});

const exportToCSVButtonStyle = {
  background: 'none',
  border: 'none',
  padding: 2,
  cursor: 'pointer',
};

type AssetInformationTableProps = {
  assets: ServiceAssetTableObject[];
  poamId: string;
};
export const AssetInformationTable: React.FunctionComponent<AssetInformationTableProps> = (props) => {
  const { assets, poamId } = props;
  const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);

  const columns = useMemo(
    (): IColumn[] => [
      {
        key: AssetInformationColumnNames['Service Tree'],
        name: 'Service Tree',
        fieldName: 'serviceTreeName',
        minWidth: 200,
        maxWidth: 300,
      },
      {
        key: AssetInformationColumnNames['Asset type'],
        name: 'Asset type',
        fieldName: 'assetType',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: AssetInformationColumnNames['Affected count'],
        name: 'Affected count',
        fieldName: 'affectedAssetCount',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: AssetInformationColumnNames['Total count'],
        name: 'Total count',
        fieldName: 'inventoryAssetCount',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: AssetInformationColumnNames['Is excluded'],
        name: 'Is excluded',
        fieldName: 'exclude',
        minWidth: 100,
        maxWidth: 300,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AssetInformationColumnNames],
  );

  const downloadCSV = () => {
    const report = assets.map((asset: { [key: string]: any }) => {
      const newItem: { [key: string]: any } = {};
      columns.forEach((column) => {
        const { name, fieldName } = column;
        if (fieldName) newItem[name] = asset[fieldName];
      });
      return newItem;
    });

    if (report) {
      convertArrayToCSV(report, `Asset-information-for-poam-${poamId}`, downloadLink.current);
    }
  };

  return (
    <div className={customTableStyles}>
      <button style={exportToCSVButtonStyle} type="button" onClick={downloadCSV}>
        <div style={{ paddingLeft: '12px', display: 'flex', gap: '10px', cursor: 'pointer' }}>
          <FontIcon style={{ fontSize: 20, color: '#199F77' }} iconName="ImportMirrored" />
          <span>Export to CSV</span>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a ref={downloadLink} hidden />
        </div>
      </button>
      <ShimmeredDetailsList
        items={assets}
        columns={columns}
        shimmerLines={20}
        selectionMode={SelectionMode.none}
        onRenderDetailsHeader={renderFixedDetailsHeader}
      />
    </div>
  );
};
