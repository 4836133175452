import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Spinner, SpinnerSize, mergeStyles } from '@fluentui/react';
import { HorizontalRule } from 'styles/horizontalRule';
import { Tab } from 'components/tab/tab';
import { TabPage } from 'components/tab/tabPage';
import { ServiceContext } from 'components/serviceProvider/serviceContext';
import { LoadingState } from 'models/loadingState';
import { getCloudCertification, getCombinedCloudCertifications } from 'modules/environmentAuthorization/environmentAuthorization';
import { IEvidencePackage, FileAttachment, SnapshotDetail, Offering } from 'generated/clientApi';
import { ConfigContext } from 'components/configProvider/configContext';
import { CreateEvidenceTab } from 'pages/evidenceTool/phases/createEvidenceTab';
import { getFormattedCloud } from 'models/cloud';
import { getSnapshots } from 'modules/snapshot/snapshot';
import { useLocation, useParams } from 'react-router';
import { getServiceAttachments } from 'modules/fileAttachment/fileAttachment';
import { EvidenceDetailRouteParams } from 'modules/routes/routes';
import {
  downloadAttachments,
  updateEvidencePackageAuditorSubmit,
  updateEvidencePackageAuthorizingOfficialSubmit,
} from 'modules/evidencePackage/evidencePackage';
import { EvidencePackageTab } from 'pages/createEditEvidencePackage/evidencePackageTab';
import { getOfferingsByServiceOid } from 'modules/offering/offering';
import { ErrorLoading } from 'components/errorLoading/errorLoading';
import { createEvidenceDetailBladeChromeConfig, updatePageChromeConfig } from 'modules/pageChrome/pageChrome';
import { IBladeHeaderButtonConfig } from 'components/bladeHeader/bladeHeader';
import {
  EVIDENCE_PACKAGE_AUDITING_REVIEW_WRITE,
  EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_WRITE,
  EVIDENCE_PACKAGE_DOWNLOAD,
  EVIDENCE_PACKAGE_NOTES_WRITE,
  SITE_WIDE_SUBJECT,
} from 'modules/constants';
import { EvidencePackageStatus } from 'models/evidencePackageStatus';
import { AuthContext } from 'components/authProvider/authContext';
import { logError } from 'modules/logging/logging';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { useBoolean } from '@uifabric/react-hooks';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { ProfileTab } from './profileTab';
import { FileEvidenceTab } from './fileEvidenceTab';

const containerStyles = mergeStyles({
  padding: '1em',
});

const bodyStyle = mergeStyles({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '1em',
});

const wrapperStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

enum CreateEditEvidencePackageTabs {
  Profile,
  Evidence,
  Documents,
  Review,
}

enum CreateEditEvidencePackageLoadingStates {
  Services,
  Evidence,
  Attachments,
  Snapshots,
  Offerings,
}

enum CreateEditEvidencePackageStage {
  Create,
  GatherEvidence,
  Edit,
}

const createLoadingMap = (): Map<CreateEditEvidencePackageLoadingStates, LoadingState> => {
  const loadingMap = new Map<CreateEditEvidencePackageLoadingStates, LoadingState>();
  loadingMap.set(CreateEditEvidencePackageLoadingStates.Services, LoadingState.NotLoaded);
  loadingMap.set(CreateEditEvidencePackageLoadingStates.Evidence, LoadingState.NotLoaded);
  loadingMap.set(CreateEditEvidencePackageLoadingStates.Attachments, LoadingState.NotLoaded);
  return loadingMap;
};

const setLoadingMap = (
  loadingMap: Map<CreateEditEvidencePackageLoadingStates, LoadingState>,
  createEditEvidencePackageLoadingStates: CreateEditEvidencePackageLoadingStates[],
  loadingState: LoadingState,
): Map<CreateEditEvidencePackageLoadingStates, LoadingState> => {
  const copiedLoadingMap = new Map<CreateEditEvidencePackageLoadingStates, LoadingState>(loadingMap);
  createEditEvidencePackageLoadingStates.forEach((createEditEvidencePackageLoadingState) => {
    copiedLoadingMap.set(createEditEvidencePackageLoadingState, loadingState);
  });
  return copiedLoadingMap;
};

interface LocationState {
  cloud?: string;
  certification?: string;
  serviceId?: string;
}

export const CreateEditEvidencePackagePage: React.FunctionComponent = () => {
  const { serviceOid, evidencePackageId, navMenuTab } = useParams<EvidenceDetailRouteParams>();
  const location = useLocation();
  const { cloud, certification, serviceId } = (location.state || {}) as LocationState;
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const servicesContext = useContext(ServiceContext);
  const evidenceContext = useContext(EvidenceContext);
  const [isLoadingMap, setIsLoadingMap] = useState<Map<CreateEditEvidencePackageLoadingStates, LoadingState>>(createLoadingMap);
  const [stage, setStage] = useState<CreateEditEvidencePackageStage>(
    serviceOid && evidencePackageId ? CreateEditEvidencePackageStage.Edit : CreateEditEvidencePackageStage.Create,
  );
  const [activeTabPage, setActiveTabPage] = useState<CreateEditEvidencePackageTabs>(() =>
    stage === CreateEditEvidencePackageStage.Edit ? CreateEditEvidencePackageTabs.Review : CreateEditEvidencePackageTabs.Profile,
  );
  const [filteredOfferings, setFilteredOfferings] = useState<Offering[]>();
  const [selectedServiceOid, setSelectedServiceOid] = useState<string>(serviceOid);
  const [selectedCloudCert, setSelectedCloudCert] = useState<string | undefined>();
  const [selectedCloud, setSelectedCloud] = useState<string>('');
  const [selectedCertification, setSelectedCertification] = useState<string>('');
  const [snapshotDetails, setSnapshotDetails] = useState<SnapshotDetail[]>([]);
  const cloudCertifications = getCombinedCloudCertifications(configContext.environmentAuthorizations);
  const [fileAttachments, setFileAttachments] = useState<FileAttachment[]>();
  const [isDownloadButtonBusy, { setTrue: setDownloadButtonBusy, setFalse: setDownloadButtonNotBusy }] = useBoolean(false);
  const [showChat, setShowChat] = useState<boolean>(false);

  const isEditMode = stage === CreateEditEvidencePackageStage.Edit;

  // All from the location state - kept bundled together as a result
  useEffect(() => {
    if (cloud && certification) {
      setSelectedCloud(cloud);
      setSelectedCertification(certification);
      setSelectedCloudCert(`${cloud} ${certification}`);
    }

    if (serviceId) {
      setSelectedServiceOid(serviceId);
    }
  }, [cloud, certification, serviceId]);

  useEffect(() => {
    // if the selected service changes, update
    servicesContext.setSelectedService(selectedServiceOid);
  }, [servicesContext, selectedServiceOid]);

  useEffect(() => {
    const getEvidencePackageTask = async () => {
      setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Evidence], LoadingState.Requested));
      evidenceContext.requestEvidencePackage(selectedServiceOid, evidencePackageId);
    };

    const evidenceLoadingState = isLoadingMap.get(CreateEditEvidencePackageLoadingStates.Evidence);
    if (
      !evidenceContext.evidencePackage &&
      isEditMode &&
      selectedServiceOid &&
      evidencePackageId &&
      evidenceLoadingState !== LoadingState.Requested
    ) {
      getEvidencePackageTask();
    }
  }, [evidenceContext.evidencePackage, evidencePackageId, isEditMode, isLoadingMap, selectedServiceOid, evidenceContext]);

  useEffect(() => {
    if (!evidenceContext.evidencePackage) {
      return;
    }
    setSelectedCloudCert(`${evidenceContext.evidencePackage.cloud} ${evidenceContext.evidencePackage.certification}`);
    setSelectedCloud(evidenceContext.evidencePackage.cloud);
    setSelectedCertification(evidenceContext.evidencePackage.certification);
    setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Evidence], LoadingState.Loaded));
  }, [evidenceContext.evidencePackage]);

  useEffect(() => {
    servicesContext.requestServices();
  }, [isEditMode, servicesContext]);

  const showReviewButton = useCallback(() => {
    if (evidenceContext.evidencePackage?.status === EvidencePackageStatus.AuditorSubmit) {
      const approvedRoleOperations = [{ operation: EVIDENCE_PACKAGE_AUDITING_REVIEW_WRITE, subject: SITE_WIDE_SUBJECT }];
      return authContext.isAuthorized(approvedRoleOperations);
    }

    if (evidenceContext.evidencePackage?.status === EvidencePackageStatus.AoSubmit) {
      const approvedRoleOperations = [{ operation: EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_WRITE, subject: SITE_WIDE_SUBJECT }];
      return authContext.isAuthorized(approvedRoleOperations);
    }
    return false;
  }, [authContext, evidenceContext.evidencePackage?.status]);

  const showDownloadButton = useCallback(
    () => authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_DOWNLOAD, subject: SITE_WIDE_SUBJECT }]),
    [authContext],
  );

  const showChatButton = useCallback(
    () => authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_NOTES_WRITE, subject: SITE_WIDE_SUBJECT }]),
    [authContext],
  );

  const onDownloadButtonClick = useCallback(async () => {
    setDownloadButtonBusy();
    await downloadAttachments(evidenceContext.evidencePackage);
    setDownloadButtonNotBusy();
  }, [evidenceContext.evidencePackage, setDownloadButtonBusy, setDownloadButtonNotBusy]);

  const onChatButtonClick = useCallback(() => {
    setShowChat(!showChat);
  }, [showChat]);

  const onBeginReviewButtonClick = useCallback(async () => {
    try {
      if (evidenceContext.evidencePackage?.status === EvidencePackageStatus.AuditorSubmit) {
        await updateEvidencePackageAuditorSubmit({
          serviceOid: evidenceContext.evidencePackage.serviceOid,
          id: evidenceContext.evidencePackage.id,
        });
      } else if (evidenceContext.evidencePackage?.status === EvidencePackageStatus.AoSubmit) {
        await updateEvidencePackageAuthorizingOfficialSubmit({
          serviceOid: evidenceContext.evidencePackage.serviceOid,
          id: evidenceContext.evidencePackage.id,
        });
      } else {
        throw new Error('Invalid Evidence Package status - cannot be updated');
      }

      evidenceContext.reloadEvidencePackage();
      showSuccess(`Successfully updated evidence package with name: ${evidenceContext.evidencePackage.name}`);
    } catch (error) {
      const baseErrorMessage = 'There was an issue updating the evidence package.';
      logError(`${baseErrorMessage} service oid: ${serviceOid}, evidence package id: ${evidencePackageId}.`, error);
      showError(`${baseErrorMessage} Please refresh and try again.`);
    }
  }, [evidenceContext, serviceOid, evidencePackageId]);

  useEffect(() => {
    const evidenceLoadingState = isLoadingMap.get(CreateEditEvidencePackageLoadingStates.Evidence);
    if (evidenceLoadingState !== LoadingState.Loaded) {
      return undefined;
    }

    const downloadButtonInfo: IBladeHeaderButtonConfig = {
      buttonText: '',
      title: 'Download Attachments',
      onButtonClick: onDownloadButtonClick,
      buttonDisabled: false,
      isBusy: isDownloadButtonBusy,
      busyTitle: 'Downloading...',
    };

    const chatButtonInfo: IBladeHeaderButtonConfig = {
      buttonText: 'Chat',
      title: 'Chat',
      onButtonClick: onChatButtonClick,
      buttonDisabled: false,
      isBusy: false,
      busyTitle: '',
      iconProps: { iconName: 'Comment' },
    };

    updatePageChromeConfig(
      createEvidenceDetailBladeChromeConfig(
        `${isEditMode ? 'Edit' : 'New'} ${evidenceContext.evidencePackage?.name} package`,
        showReviewButton(),
        onBeginReviewButtonClick,
        showDownloadButton() ? downloadButtonInfo : undefined,
        showChatButton() ? chatButtonInfo : undefined,
      ),
    );
    return () => updatePageChromeConfig();
  }, [
    evidenceContext.evidencePackage?.name,
    isDownloadButtonBusy,
    isEditMode,
    isLoadingMap,
    onBeginReviewButtonClick,
    onChatButtonClick,
    onDownloadButtonClick,
    showChatButton,
    showDownloadButton,
    showReviewButton,
  ]);

  useEffect(() => {
    if (servicesContext.servicesLoadingState === LoadingState.Loaded) {
      setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Services], LoadingState.Loaded));
    }
  }, [isEditMode, servicesContext]);

  useEffect(() => {
    const getAttachments = async () => {
      setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Attachments], LoadingState.Loading));
      const attachments = await getServiceAttachments(selectedServiceOid, selectedCloud, selectedCertification);
      setFileAttachments(attachments);
      setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Attachments], LoadingState.Loaded));
    };

    if (!fileAttachments && selectedCertification && selectedCloud && selectedServiceOid) {
      getAttachments();
    }
  }, [fileAttachments, selectedCertification, selectedCloud, selectedServiceOid, setFileAttachments]);

  useEffect(() => {
    const getFilteredOfferingsTask = async () => {
      setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Offerings], LoadingState.Loading));
      const offeringsResponse = await getOfferingsByServiceOid(selectedServiceOid);
      setFilteredOfferings(offeringsResponse);
      setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Offerings], LoadingState.Loaded));
    };

    if (selectedServiceOid && !filteredOfferings) {
      getFilteredOfferingsTask();
    }
  }, [filteredOfferings, selectedServiceOid]);

  const onClickGatherEvidenceButton = (serviceOid: string, cloudCert: string) => {
    setActiveTabPage(CreateEditEvidencePackageTabs.Evidence);
    setStage(CreateEditEvidencePackageStage.GatherEvidence);
    setSelectedCloudCert(cloudCert);
    setSelectedServiceOid(serviceOid);
    const { cloud, certification } = getCloudCertification(cloudCert);
    if (cloud && certification && serviceOid) {
      setSelectedCloud(cloud);
      setSelectedCertification(certification);
      setSnapshotDetails([]);
    }
  };

  const getSnapshotTask = useCallback(async () => {
    setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Snapshots], LoadingState.NotLoaded));
    const formattedCloud = getFormattedCloud(selectedCloud);
    const snapshotListResponse = await getSnapshots(selectedServiceOid, formattedCloud);
    setSnapshotDetails(snapshotListResponse.snapshotDetails);
    setIsLoadingMap((loadingMap) => setLoadingMap(loadingMap, [CreateEditEvidencePackageLoadingStates.Snapshots], LoadingState.Loaded));
  }, [selectedCloud, selectedServiceOid]);

  useEffect(() => {
    if ((!snapshotDetails || !snapshotDetails.length) && selectedServiceOid && selectedCloud) {
      getSnapshotTask();
    }
  }, [getSnapshotTask, selectedCloud, selectedServiceOid, snapshotDetails]);

  const isAnyLoading = () => {
    if (stage === CreateEditEvidencePackageStage.Create || stage === CreateEditEvidencePackageStage.GatherEvidence) {
      const servicesLoadedState = isLoadingMap.get(CreateEditEvidencePackageLoadingStates.Services);
      return servicesLoadedState === LoadingState.NotLoaded;
    }

    let isAnyLoading = false;
    isLoadingMap.forEach((loadingState) => {
      if (loadingState === LoadingState.NotLoaded) {
        isAnyLoading = true;
      }
    });

    return isAnyLoading;
  };

  const onCreateUpdateEvidencePackage = (evidencePackage: IEvidencePackage) => {
    setIsLoadingMap((loadingMap) =>
      setLoadingMap(
        loadingMap,
        [CreateEditEvidencePackageLoadingStates.Evidence, CreateEditEvidencePackageLoadingStates.Attachments],
        LoadingState.Loaded,
      ),
    );
    evidenceContext.requestEvidencePackage(selectedServiceOid, evidencePackage.id);
  };

  const onSetFileAttachments = (fileAttachments: FileAttachment[]) => {
    const copiedFileAttachments = [...fileAttachments];
    setFileAttachments(copiedFileAttachments);
  };

  const ProfileTabContent = (): JSX.Element => (
    <ProfileTab
      services={servicesContext.services || []}
      cloudCertifications={cloudCertifications}
      onClickGatherEvidenceButton={onClickGatherEvidenceButton}
      selectedServiceOid={selectedServiceOid}
      selectedCloudCertFilter={selectedCloudCert}
      isEditMode={isEditMode}
    />
  );

  const CreateEvidenceTabContent = (): JSX.Element => {
    if (!selectedServiceOid && !selectedCloud && !selectedCertification) {
      return <p>Please create a profile before starting the evidence process.</p>;
    }

    const evidencePackageId = evidenceContext.evidencePackage?.id;
    const snapshotLoadingState = isLoadingMap.get(CreateEditEvidencePackageLoadingStates.Snapshots);
    if (snapshotLoadingState !== LoadingState.Loaded) {
      return <Spinner size={SpinnerSize.large} />;
    }

    return (
      <CreateEvidenceTab
        serviceOid={selectedServiceOid}
        cloud={selectedCloud}
        certification={selectedCertification}
        snapshotDetails={snapshotDetails}
        saveEnabled
        onEvidencePackageAdded={() => setActiveTabPage(CreateEditEvidencePackageTabs.Documents)}
        onClickPreviousButton={() => setActiveTabPage(CreateEditEvidencePackageTabs.Profile)}
        evidencePackageId={evidencePackageId}
        setEvidencePackage={onCreateUpdateEvidencePackage}
        currentEvidencePackageDomainNames={evidenceContext.evidencePackage?.evidenceDomains?.map((domain) => domain.name)}
        updateSnapshots={getSnapshotTask}
      />
    );
  };

  const AttachmentsTabContent = (): JSX.Element => {
    if (!selectedServiceOid && !selectedCloud && !selectedCertification) {
      return <p>Please create a profile before starting the attachment process.</p>;
    }
    if (!evidenceContext.evidencePackage) {
      return <p>Please create an evidence package before starting the attachment process.</p>;
    }

    if (!fileAttachments) {
      return <ErrorLoading />;
    }

    return (
      <FileEvidenceTab
        fileAttachments={[...fileAttachments]}
        setFileAttachments={(fileAttachments) => onSetFileAttachments(fileAttachments)}
        onClickNextButton={() => setActiveTabPage(CreateEditEvidencePackageTabs.Review)}
        onClickPreviousButton={() => setActiveTabPage(CreateEditEvidencePackageTabs.Evidence)}
        serviceOid={selectedServiceOid}
        certification={selectedCertification}
        cloud={selectedCloud}
      />
    );
  };

  const ReviewEditPackageTabContent = (): JSX.Element => {
    if (!selectedServiceOid && !selectedCloud && !selectedCertification) {
      return <p>Please create a profile before starting the evidence process.</p>;
    }
    if (!evidenceContext.evidencePackage) {
      return <p>Please create an evidence package before starting the review process.</p>;
    }

    if (!fileAttachments) {
      return <ErrorLoading />;
    }

    return (
      <EvidencePackageTab
        cloud={selectedCloud}
        certification={selectedCertification}
        fileAttachments={[...fileAttachments]}
        setFileAttachments={(fileAttachments) => onSetFileAttachments(fileAttachments)}
        onClickPreviousButton={() => setActiveTabPage(CreateEditEvidencePackageTabs.Documents)}
        navMenuTab={navMenuTab}
        offerings={filteredOfferings}
        showChat={showChat}
      />
    );
  };

  const updateTabPage = (pageNumber: number) => {
    setActiveTabPage(pageNumber);

    // If the user is redirecting to another tab, erase the cached data
    evidenceContext.resetEvidencePackage();
  };

  if (isAnyLoading()) {
    return <CenteredProgressDots />;
  }

  return (
    <>
      <HorizontalRule />
      <div className={containerStyles}>
        <h1>{isEditMode ? 'Edit evidence package' : 'Create new evidence package'}</h1>
        <div className={wrapperStyle}>
          <div className={bodyStyle}>
            <Tab activePage={activeTabPage} onPageUpdated={(pageNumber: number) => updateTabPage(pageNumber)}>
              <TabPage title="Profile">{ProfileTabContent()}</TabPage>
              <TabPage title="Evidence">{CreateEvidenceTabContent()}</TabPage>
              <TabPage title="Additional documents">{AttachmentsTabContent()}</TabPage>
              <TabPage title="Review & submit package">{ReviewEditPackageTabContent()}</TabPage>
            </Tab>
          </div>
        </div>
      </div>
    </>
  );
};
