import React, { FunctionComponent, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { HeroContentContext } from './heroContentContext';

export const HeroContentProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [showOrganizationDropdown, setShowOrganizationDropdown] = useState<boolean>(false);
  const [heroContent, setHeroContent] = useState<React.ReactNode>(<></>);
  const [showHeroContent, setShowHeroContent] = useState<boolean>(false);

  const contextProps = {
    setShowOrganizationDropdown: (displayOrganizationDropdown: boolean) => setShowOrganizationDropdown(displayOrganizationDropdown),
    getShowOrganizationDropdown: (): boolean => showOrganizationDropdown,
    setHeroContent: (content: React.ReactNode) => setHeroContent(content),
    getHeroContent: (): React.ReactNode => heroContent,
    setShowHero: (showHero: boolean) => setShowHeroContent(showHero),
    getShowHero: () => showHeroContent,
    setAndDisplayHeroContent: (content: React.ReactNode, showOrganizationDropdown?: boolean | undefined) => {
      setShowHeroContent(true);
      setHeroContent(content);
      setShowOrganizationDropdown(showOrganizationDropdown ?? false);
    },
  };

  return <HeroContentContext.Provider value={contextProps}>{children}</HeroContentContext.Provider>;
};
