import React, { useCallback, useMemo, useState } from 'react';
import { ActionButton, Dropdown, IDropdownOption, IDropdownStyles, ISearchBoxStyles, SearchBox, Toggle } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { historyParamUpdate } from 'components/util/historyUtils';
import { ValueOf } from 'components/util/objectUtils';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { PoamColumnNames, PoamColumns } from './poamTable';
import { dropdownStyles } from '../coverageTab/filterBar';
import { PoamFilters, usePoamFilters } from './usePoamFilters';
import {
  effectiveRiskOptions,
  cloudOptions,
  dueOptions,
  fpOptions,
  orOptions,
  originalRiskOptions,
  plannedRemediationOptions,
  raOptions,
  scanTypeOptions,
  slaStateOptions,
  vendorCheckinOptions,
  vendorDependency,
} from './poamFilterValues';
import { buttonStyles } from '../styles/buttonStyles';
import { ExceptionsPanel } from './exceptionsPanel';

const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: '225px', height: '28px' } };
const columnDropdownStyles: Partial<IDropdownStyles> = {
  ...dropdownStyles,
  callout: { minWidth: '300px' },
  dropdown: { width: 160 },
};
const all = 'all';

export const PoamFilterNames: Partial<PoamFilters> = {
  cloudType: 'Cloud type',
  slaState: 'Status',
  originalRisk: 'Original risk',
  effectiveRisk: 'Effective risk',
  due: 'Due',
  fp: 'FP',
  or: 'OR',
  ra: 'RA',
  plannedRemediation: 'Planned remediation',
  scanType: 'Scan type',
  plannedRemediationComments: 'Planned remediation comments',
  vendorDependency: 'Vendor dependency',
  vendorCheckInDate: 'Vendor check-in date',
  internalComments: 'Internal comments',
} as const;

export const DefaultPoamFilters: ValueOf<typeof PoamFilterNames>[] = ['Cloud type', 'Status', 'Original risk', 'Effective risk'];

type PoamFilterBarProps = {
  poamColumns: (keyof PoamColumns)[];
  updatePoamColumns: (columns: (keyof PoamColumns)[]) => void;
  poamFilters: ValueOf<typeof PoamFilterNames>[];
  updatePoamFilters: (filters: ValueOf<Partial<typeof PoamFilterNames>>[]) => void;
};
export const PoamFilterBar: React.FunctionComponent<PoamFilterBarProps> = (props) => {
  const { poamColumns, updatePoamColumns, poamFilters, updatePoamFilters } = props;
  const columnOptions: IDropdownOption[] = useMemo(() => Object.keys(PoamColumnNames).map((col) => ({ key: col, text: col })), []);
  const [isExceptionsPanelOpen, setExceptionsPanelOpen] = useState(false);
  const filters = usePoamFilters();
  const history = useHistory();

  const filterOptions = useMemo(() => Object.values(PoamFilterNames).map((value) => ({ key: value as string, text: value as string })), []);

  const onColumnsChange = (item: IDropdownOption<any> | undefined) => {
    if (item) {
      updatePoamColumns(item.selected ? [...poamColumns, item.key as keyof PoamColumns] : poamColumns.filter((key) => key !== item.key));
    }
  };

  const onSelectedFiltersChange = (item: IDropdownOption<any> | undefined) => {
    if (item) {
      updatePoamFilters(
        item.selected ? [...poamFilters, item.key as ValueOf<typeof PoamFilterNames>] : poamFilters.filter((key) => key !== item.key),
      );
    }
  };

  const onFilterChange = useCallback((key: keyof PoamFilters, value: string | boolean | undefined) => {
    const newValue = value === all || value === '' ? undefined : value;
    historyParamUpdate(history, { [key]: newValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const moreStyles: Partial<IDropdownStyles> = {
    ...dropdownStyles,
    callout: { minWidth: '300px' },
    dropdown: { width: 120 },
    title: {
      border: 'none',
    },
    root: {
      '.ms-Dropdown::after': {
        border: 'none',
      },
    },
  };

  const visibleFilters = useMemo(() => {
    const elements = (
      <>
        <Dropdown
          key={PoamFilterNames.cloudType}
          selectedKey={filters.cloudType ?? ''}
          options={cloudOptions}
          styles={columnDropdownStyles}
          placeholder={PoamFilterNames.cloudType}
          onChange={(_, newValue) => onFilterChange('cloudType', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.slaState}
          selectedKey={filters.slaState ?? ''}
          options={slaStateOptions}
          styles={columnDropdownStyles}
          placeholder="State: All"
          onChange={(_, newValue) => onFilterChange('slaState', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.due}
          selectedKey={filters.due ?? ''}
          options={dueOptions}
          styles={columnDropdownStyles}
          placeholder="Due: All"
          onChange={(_, newValue) => onFilterChange('due', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.originalRisk}
          selectedKey={filters.originalRisk ?? ''}
          options={originalRiskOptions}
          styles={columnDropdownStyles}
          placeholder="Original risk: All"
          onChange={(_, newValue) => onFilterChange('originalRisk', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.effectiveRisk}
          selectedKey={filters.effectiveRisk ?? ''}
          options={effectiveRiskOptions}
          styles={columnDropdownStyles}
          placeholder="Effective risk: All"
          onChange={(_, newValue) => onFilterChange('effectiveRisk', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.scanType}
          selectedKey={filters.scanType ?? ''}
          options={scanTypeOptions}
          styles={columnDropdownStyles}
          placeholder="Scan type: All"
          onChange={(_, newValue) => onFilterChange('scanType', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.fp}
          selectedKey={filters.fp ?? ''}
          options={fpOptions}
          styles={columnDropdownStyles}
          placeholder="FP: All"
          onChange={(_, newValue) => onFilterChange('fp', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.or}
          selectedKey={filters.or ?? ''}
          options={orOptions}
          styles={columnDropdownStyles}
          placeholder="OR: All"
          onChange={(_, newValue) => onFilterChange('or', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.ra}
          selectedKey={filters.ra ?? ''}
          options={raOptions}
          styles={columnDropdownStyles}
          placeholder="RA: All"
          onChange={(_, newValue) => onFilterChange('ra', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.plannedRemediation}
          selectedKey={filters.plannedRemediation ?? ''}
          options={plannedRemediationOptions}
          styles={columnDropdownStyles}
          placeholder="Planned remediation: All"
          onChange={(_, newValue) => onFilterChange('plannedRemediation', newValue?.key as string)}
        />
        <SearchBox
          key={PoamFilterNames.plannedRemediationComments}
          placeholder="Planned remediation comments"
          value={filters.plannedRemediationComments ?? ''}
          styles={searchBoxStyles}
          onEscape={(_) => onFilterChange('plannedRemediationComments', undefined)}
          onClear={(_) => onFilterChange('plannedRemediationComments', undefined)}
          onChange={(_, newValue) => onFilterChange('plannedRemediationComments', newValue)}
        />
        <Dropdown
          key={PoamFilterNames.vendorDependency}
          selectedKey={filters.vendorDependency ?? ''}
          options={vendorDependency}
          styles={columnDropdownStyles}
          placeholder="Vendor dependency: All"
          onChange={(_, newValue) => onFilterChange('vendorDependency', newValue?.key as string)}
        />
        <Dropdown
          key={PoamFilterNames.vendorCheckInDate}
          selectedKey={filters.vendorCheckInDate ?? ''}
          options={vendorCheckinOptions}
          styles={columnDropdownStyles}
          placeholder="Vendor check-in date: All"
          onChange={(_, newValue) => onFilterChange('vendorCheckInDate', newValue?.key as string)}
        />
        <SearchBox
          key={PoamFilterNames.internalComments}
          placeholder="Search internal comments"
          value={filters.internalComments ?? ''}
          styles={searchBoxStyles}
          onEscape={(_) => onFilterChange('internalComments', undefined)}
          onClear={(_) => onFilterChange('internalComments', undefined)}
          onChange={(_, newValue) => onFilterChange('internalComments', newValue)}
        />
      </>
    );
    const filteredElements = React.Children.map(elements.props.children, (child) => {
      if (poamFilters.includes(child.key)) {
        return child;
      }
      return null;
    });
    return filteredElements;
  }, [
    filters.effectiveRisk,
    filters.cloudType,
    filters.due,
    filters.fp,
    filters.internalComments,
    filters.or,
    filters.originalRisk,
    filters.plannedRemediation,
    filters.plannedRemediationComments,
    filters.ra,
    filters.scanType,
    filters.slaState,
    filters.vendorCheckInDate,
    filters.vendorDependency,
    onFilterChange,
    poamFilters,
  ]);

  return (
    <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
      <SearchBox
        placeholder="POA&M ID, Vuln ID, or Title"
        value={filters.poamVulnId ?? ''}
        styles={searchBoxStyles}
        onEscape={(_) => onFilterChange('poamVulnId', undefined)}
        onClear={(_) => onFilterChange('poamVulnId', undefined)}
        onChange={(_, newValue) => onFilterChange('poamVulnId', newValue)}
      />
      {!filters.showClosed && (
        <>
          {visibleFilters}
          <Dropdown
            options={filterOptions}
            multiSelect
            selectedKeys={poamFilters as string[]}
            styles={moreStyles}
            placeholder="More filters"
            onRenderTitle={() => <div>More filters</div>}
            onChange={(ev, item) => onSelectedFiltersChange(item)}
          />
        </>
      )}
      <div style={{ marginLeft: 'auto', display: 'flex', gap: '1rem' }}>
        <ActionButton
          className={actionButtonStyles}
          style={{ ...buttonStyles }}
          iconProps={{ iconName: 'Error' }}
          onClick={() => setExceptionsPanelOpen(true)}
        >
          View exceptions
        </ActionButton>
        <Dropdown
          options={columnOptions}
          selectedKeys={poamColumns}
          multiSelect
          styles={columnDropdownStyles}
          placeholder="Select columns"
          onRenderTitle={(items: IDropdownOption<any>[] | undefined) => (
            <div>
              Columns selected:
              {items?.length ?? 0}
            </div>
          )}
          onChange={(ev, item) => onColumnsChange(item)}
        />
        <Toggle
          label="Show closed POA&Ms"
          inlineLabel
          onText=" "
          offText=" "
          onChange={(ev, checked) => onFilterChange('showClosed', checked ?? false)}
          checked={filters.showClosed}
        />
      </div>
      {isExceptionsPanelOpen && <ExceptionsPanel close={() => setExceptionsPanelOpen(false)} />}
    </div>
  );
};
