import React, { FunctionComponent, useEffect, useState } from 'react';
import { ActionButton, Checkbox, IPanelStyles, Panel, PanelType, SelectionMode, Stack, StackItem, mergeStyles } from '@fluentui/react';
import { BasicList, ListColumn } from 'components/basicList/basicList';
import { CacRequest, ICacRequestApprovePutCommand, ICacRequestRejectPutCommand, MtacRole } from 'generated/clientApi';
import { logError } from 'modules/logging/logging';
import { getCacRequests, putRejectedCacRequests, putApprovedCacRequests } from 'modules/user/user';
import { getFormattedDateTime } from 'modules/datetime/datetime';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { HorizontalRule } from 'styles/horizontalRule';
import { LoadingState } from 'models/loadingState';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { PanelFooterButtons } from 'components/panel/panelFooterButtons';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { getRoleOperations } from 'modules/roles/roleOperations';

const labelStyle = mergeStyles({
  padding: '0.5em',
  fontSize: '1.5em',
  height: 'fit-content',
  fontWeight: 'bold',
});

const panelStyles: Partial<IPanelStyles> = {
  scrollableContent: {
    height: 'calc(100% - 30px)',
    overflowY: 'hidden',
  },
  content: {
    height: 'inherit',
  },
};

const stackStyles = mergeStyles({
  display: 'flex',
  height: 'inherit',
});

const overflowStackStyles = mergeStyles({
  overflowY: 'auto',
});

const sectionTitleStyles = mergeStyles({
  marginTop: '3em',
});

const userCredentialStyles = mergeStyles({
  margin: '0.5em 0 0.5em 1em',
  textDecoration: 'underline',
});

const approvedListStyles = mergeStyles({
  margin: '0.5em 0 0.5em 1em',
});

const footerStyles = mergeStyles({
  marginTop: 'auto',
});

const buttonWrapperStyle = mergeStyles({
  display: 'flex',
});
const columns: ListColumn[] = [
  {
    key: 'requestorName',
    name: 'Requestor Name',
    fieldName: 'requestorName',
    minWidth: 250,
    maxWidth: 300,
    onRender: (request: CacRequest) => <>{request.requestorName ? request.requestorName : request.id}</>,
  },
  {
    key: 'submitted',
    name: 'Submitted',
    onRender: (request: CacRequest) => <>{request.submitted && getFormattedDateTime(request?.submitted)}</>,
    minWidth: 50,
    maxWidth: 150,
  },
  {
    key: 'status',
    name: 'Status',
    fieldName: 'status',
    minWidth: 50,
    maxWidth: 150,
  },
  {
    key: 'certificateIssuer',
    name: 'Certificate Issuer',
    fieldName: 'certificateIssuer',
    minWidth: 50,
    maxWidth: 150,
  },
];

export const Requests: FunctionComponent = () => {
  const [cacRequests, setCacRequests] = useState<CacRequest[]>([]);
  const [roleOperations, setRoleOperations] = useState<MtacRole[]>([]);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [isRoleOperationsLoading, setIsRoleOperationsLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isRejectedPanelExpanded, setIsRejectedPanelExpanded] = useState<boolean>(false);
  const [isApprovedPanelExpanded, setIsApprovedPanelExpanded] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<CacRequest[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<MtacRole[]>([]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        setIsRoleOperationsLoading(true);
        const roles = await getRoleOperations();
        roles.sort((roleA, roleB) => (roleA.name < roleB.name ? -1 : 1));
        setRoleOperations(roles);
      } catch (error) {
        logError('There was an issue loading the Roles: ', error);
      }
      setIsRoleOperationsLoading(false);
    };
    getRoles();
  }, []);

  useEffect(() => {
    const getRequests = async () => {
      try {
        setIsLoading(LoadingState.Loading);
        const requests = await getCacRequests();
        setCacRequests(requests);
        setIsLoading(LoadingState.Loaded);
      } catch (error) {
        setIsLoading(LoadingState.Error);
        logError('There was an issue loading the CAC Requests: ', error);
      }
    };
    getRequests();
  }, []);

  const approveIconProps = { iconName: 'Accept' };
  const rejectIconProps = { iconName: 'Clear' };

  const onApprove = async () => {
    try {
      setIsUpdating(true);
      const command: ICacRequestApprovePutCommand = {
        userIds: selectedUsers.flatMap((items) => items.id),
        mtacRoleIds: selectedRoles.flatMap((items) => items.id),
      };

      await putApprovedCacRequests(command);
      const requests = await getCacRequests();
      setCacRequests(requests);
      setIsUpdating(false);
      setIsApprovedPanelExpanded(false);
      showSuccess('Successfully approved users');
      setSelectedUsers([]);
    } catch (error) {
      setIsUpdating(false);
      setIsLoading(LoadingState.Error);
      showError('There was an issue approving the CAC Requests');
      logError('There was an issue approving the CAC requests: ', error);
    }
  };

  const onReject = async () => {
    try {
      setIsUpdating(true);
      const command: ICacRequestRejectPutCommand = {
        userIds: selectedUsers.flatMap((items) => items.id),
      };
      await putRejectedCacRequests(command);
      const requests = await getCacRequests();
      setCacRequests(requests);
      setIsUpdating(false);
      setIsRejectedPanelExpanded(false);
      showSuccess('Successfully rejected users');
      setSelectedUsers([]);
    } catch (error) {
      setIsUpdating(false);
      setIsLoading(LoadingState.Error);
      showError('There was an issue rejecting the CAC Requests');
      logError('There was an issue rejecting the CAC requests: ', error);
    }
  };

  const onSelectedItemsUpdated = (itemsSelected: CacRequest[]) => {
    setSelectedUsers(itemsSelected);
  };

  const onRoleCheckboxChange = (updatedRole: MtacRole, isChecked: boolean | undefined) => {
    if (isChecked === undefined) {
      return;
    }

    setSelectedRoles((roles) => {
      // Add if checked, remove if unchecked
      if (isChecked) {
        roles.push(updatedRole);
      } else {
        const index = roles.findIndex((role) => role.id === updatedRole.id);
        roles.splice(index, 1);
      }

      return roles;
    });
  };

  const approvedPanelDisplay = (
    <Panel
      headerText="Approve credentials"
      isOpen={isApprovedPanelExpanded}
      onDismiss={() => setIsApprovedPanelExpanded(false)}
      closeButtonAriaLabel="Close"
      isBlocking={false}
      type={PanelType.smallFixedFar}
      styles={panelStyles}
    >
      <Stack className={stackStyles}>
        <StackItem>Allow the below CAC credential(s) for MTAC login and assign the selected role(s)</StackItem>
        <StackItem className={sectionTitleStyles}>
          <b>Selected Credentials:</b>
        </StackItem>
        {selectedUsers.map((selectedUser) => (
          <StackItem className={approvedListStyles} key={selectedUser.id}>
            {selectedUser.requestorName}
          </StackItem>
        ))}
        <StackItem className={sectionTitleStyles}>
          <b>Selected roles to give the user:</b>
        </StackItem>
        {isRoleOperationsLoading ? (
          <CenteredProgressDots />
        ) : (
          <Stack className={overflowStackStyles}>
            {roleOperations.map((role) => (
              <StackItem className={approvedListStyles} key={role.id}>
                <Checkbox
                  label={role.name}
                  title={role.name}
                  onChange={(ev: any, isChecked: boolean | undefined) => onRoleCheckboxChange(role, isChecked)}
                />
              </StackItem>
            ))}
          </Stack>
        )}
        <StackItem className={footerStyles}>
          <PanelFooterButtons
            isSaving={isUpdating}
            defaultConfirmText="Approve"
            onSave={() => onApprove()}
            onCancel={() => setIsApprovedPanelExpanded(false)}
          />
        </StackItem>
      </Stack>
    </Panel>
  );

  const rejectedPanelDisplay = (
    <Panel
      headerText="Reject credentials"
      isOpen={isRejectedPanelExpanded}
      onDismiss={() => setIsRejectedPanelExpanded(false)}
      closeButtonAriaLabel="Close"
      isBlocking={false}
      type={PanelType.smallFixedFar}
      styles={panelStyles}
    >
      <Stack className={stackStyles}>
        <StackItem>Reject the below CAC credential(s) for MTAC login</StackItem>
        <StackItem className={sectionTitleStyles}>
          <b>Selected Credentials:</b>
        </StackItem>
        {selectedUsers.map((selectedUser) => (
          <StackItem className={userCredentialStyles} key={selectedUser.id}>
            {selectedUser.requestorName}
          </StackItem>
        ))}
        <StackItem className={footerStyles}>
          <PanelFooterButtons
            isSaving={isUpdating}
            defaultConfirmText="Reject"
            onSave={() => onReject()}
            onCancel={() => setIsRejectedPanelExpanded(false)}
          />
        </StackItem>
      </Stack>
    </Panel>
  );

  return (
    <>
      <div className={labelStyle}>Manage requests</div>
      <div className={buttonWrapperStyle}>
        <ActionButton
          iconProps={approveIconProps}
          title="Approve"
          className={actionButtonStyles}
          onClick={() => setIsApprovedPanelExpanded(true)}
          disabled={isUpdating || selectedUsers.length === 0}
        >
          Approve
        </ActionButton>
        <ActionButton
          iconProps={rejectIconProps}
          title="Reject"
          label="Reject"
          className={actionButtonStyles}
          onClick={() => setIsRejectedPanelExpanded(true)}
          disabled={isUpdating || selectedUsers.length === 0}
        >
          Reject
        </ActionButton>
      </div>
      <HorizontalRule />
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <BasicList
          items={cacRequests}
          columns={columns}
          selectionMode={SelectionMode.multiple}
          selectedItems={selectedUsers}
          onSelectedItemsUpdated={onSelectedItemsUpdated}
        />
      )}
      {isRejectedPanelExpanded && rejectedPanelDisplay}
      {isApprovedPanelExpanded && approvedPanelDisplay}
    </>
  );
};
