import { IBreadcrumbItem, SelectionMode, mergeStyles } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { ISortableFilterableListRow, SearchableFilterableList } from 'components/searchableFilterableList/searchableFilterableList';
import { CuratedTenantMonitoringDataListResponse, ICuratedTenantMonitoringDataListResponse } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { getCuratedTenantMonitorings } from 'modules/tenanceGovernance/curatedTenant';
import { logError } from 'modules/logging/logging';
import { getFormattedDateTime } from 'modules/datetime/datetime';
import { useHistory } from 'react-router';
import { BladeHeader } from 'components/bladeHeader/bladeHeader';
import { getTenantGovernanceColumns } from './tenantGovernanceColumns';
import { getCompliantState, getCustomerName, getTenantName, getViolationTypes } from './functions/tenantGovernance.functions';
import { TenantGovernanceComplianceOverview } from './tenantGovernanceComplianceOverview';

export const pageAlignStyles = mergeStyles({
  paddingLeft: '36px',
});

export const tableAlignStyles = mergeStyles({
  paddingLeft: '24px',
});

export const paragraphStyles = mergeStyles({
  marginTop: '-15px',
});

export interface ISortableCuratedTenantMonitoringDataListResponse extends ICuratedTenantMonitoringDataListResponse {
  id: string;
}

export const TenantGovernancePage: React.FunctionComponent = () => {
  const history = useHistory();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [curatedTenantMonitorings, setCuratedTenantMonitorings] = useState<ISortableCuratedTenantMonitoringDataListResponse[]>([]);
  const [customerNames, setCustomerNames] = useState<string[]>([]);
  const [tenantNames, setTenantNames] = useState<string[]>([]);
  const [typeOfViolations, setTypeOfViolations] = useState<string[]>([]);
  const [compliantStates, setCompliantStates] = useState<string[]>([]);
  const [dataLastestUpdatedDate, setDataLastestUpdatedDate] = useState<Date>();

  const remapCuratedMonitoringDataWithIds = (
    curatedTenantData: CuratedTenantMonitoringDataListResponse,
  ): ISortableCuratedTenantMonitoringDataListResponse => {
    const { tenantId } = curatedTenantData;
    const newCuratedTenantData: ISortableCuratedTenantMonitoringDataListResponse = {
      id: tenantId ?? '',
      ...curatedTenantData,
    };
    return newCuratedTenantData;
  };

  useEffect(() => {
    const getAllCuratedTenantMonitorings = async () => {
      try {
        const allCuratedTenantMonitorings = await getCuratedTenantMonitorings();

        if (allCuratedTenantMonitorings) {
          setTenantNames(getTenantName(allCuratedTenantMonitorings));
          setCustomerNames(getCustomerName(allCuratedTenantMonitorings));
          setCompliantStates(getCompliantState(allCuratedTenantMonitorings));
          setTypeOfViolations(getViolationTypes(allCuratedTenantMonitorings));
          const res = allCuratedTenantMonitorings.map((x) => remapCuratedMonitoringDataWithIds(x));
          setCuratedTenantMonitorings(res);

          const latestDataUpdated = allCuratedTenantMonitorings.reduce((a, b) => {
            if (a.findingDate && b.findingDate) {
              return a.findingDate > b.findingDate ? a : b;
            }
            return a;
          });
          setDataLastestUpdatedDate(latestDataUpdated.findingDate);

          setLoadingState(LoadingState.Loaded);
        } else {
          setLoadingState(LoadingState.NotLoaded);
        }
      } catch (error) {
        setLoadingState(LoadingState.Error);
        logError('There was an issue loading the all curated tenant monitorings', error);
      }
    };

    getAllCuratedTenantMonitorings();
  }, []);

  const tenantGovernanceBreadcrumbItems: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'Home',
      onClick: () => history.push('/'),
    },
    {
      text: 'Tenant governance',
      key: 'TenantGovernance',
      isCurrentItem: true,
    },
  ];

  return (
    <div className={pageChromeChildStyles}>
      {loadingState !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          <BladeHeader breadcrumbs={tenantGovernanceBreadcrumbItems} />
          <div className={pageAlignStyles}>
            <h2>MS-ISR Deployments</h2>
            <p className={paragraphStyles}>
              This dashboard provides a top level view of all MS-ISR customers. Please select a customers name below to see further details.
              <br />
              Data last refresh date: {getFormattedDateTime(dataLastestUpdatedDate)}
            </p>
            <TenantGovernanceComplianceOverview curatedTenants={curatedTenantMonitorings} />
            <h2>Customers</h2>
          </div>
          <div className={tableAlignStyles}>
            <SearchableFilterableList
              items={curatedTenantMonitorings satisfies ISortableFilterableListRow[]}
              columns={getTenantGovernanceColumns(customerNames, tenantNames, compliantStates, typeOfViolations)}
              selectionMode={SelectionMode.none}
              includeSearchFilterValue={(): boolean => false}
            />
          </div>
        </>
      )}
    </div>
  );
};
