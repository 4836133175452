import { mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import React, { FunctionComponent, useContext } from 'react';
import hero from '../../assets/mtac_hero.png';
import { HeroContentContext } from '../heroContentProvider/heroContentContext';

const heroStyles = mergeStyles({
  backgroundColor: NeutralColors.black,
  backgroundImage: `url(${hero})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  color: NeutralColors.white,
  display: 'flex',
  justifyContent: 'space-around',
  maxHeight: 'min(20vh, 160px)',
});

export const Hero: FunctionComponent = () => {
  // TODO: This if statement should be removed when we start displaying the hero image on every page
  const heroContentContext = useContext(HeroContentContext);
  if (heroContentContext.getShowHero() !== true) {
    return <></>;
  }
  return <div className={heroStyles}>{heroContentContext.getHeroContent()}</div>;
};
