import { IDropdownOption, mergeStyles, PrimaryButton, Spinner } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';
import { ComplianceSelfTestListResponse } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { logError } from '../../modules/logging/logging';
import { getWordDocument } from '../../modules/reports/reports';
import { dropdownStyles } from '../../styles/dropdownStyles';
import { SearchableDropdown } from '../../components/searchDropdown/searchDropdown';
import { sortDropdownOptions } from './report.functions';

const buttonStyles = mergeStyles(primaryButtonStyles, {
  margin: '1em 0',
});

const spinnerStyle = mergeStyles({
  marginRight: '5px',
});

interface ComplianceSelfTestReportProps {
  complianceSelfTestList: ComplianceSelfTestListResponse | undefined;
}

export const ComplianceSelfTestReport: FunctionComponent<ComplianceSelfTestReportProps> = (props) => {
  const { complianceSelfTestList } = props;
  const [selectedCompliances, setSelectedCompliances] = useState<string[]>([]);
  const [complianceSelfTestOptions, setComplianceSelfTestOptions] = useState<IDropdownOption[]>([]);
  const [isDocumentLoading, setIsDocumentLoading] = useState<LoadingState>(LoadingState.NotLoaded);

  useEffect(() => {
    setComplianceSelfTestOptions(
      sortDropdownOptions(
        complianceSelfTestList?.complianceSelfTestLinks?.map(
          (link) =>
            ({
              id: link.title || '',
              key: link.title || '',
              text: link.title || '',
              path: link.relativePath,
            }) as IDropdownOption,
        ) || [],
      ),
    );
  }, [complianceSelfTestList?.complianceSelfTestLinks]);

  const onButtonClick = async () => {
    if (!complianceSelfTestList || !complianceSelfTestList.repoId) {
      return;
    }

    try {
      setIsDocumentLoading(LoadingState.Loading);
      await getWordDocument(complianceSelfTestList.repoId, selectedCompliances);
      setIsDocumentLoading(LoadingState.Loaded);
    } catch (error) {
      setIsDocumentLoading(LoadingState.Error);
      logError('There was an issue getting the CST word doc', error);
    }
  };

  const onSelectionChanged = (ev: any, item: IDropdownOption | undefined): void => {
    if (item) {
      setSelectedCompliances((compliances) => (item.selected ? [...compliances, item.key as string] : compliances.filter((key) => key !== item.key)));
    }
  };

  return (
    <>
      <SearchableDropdown
        placeholder="Select an option"
        label="Compliance self tests"
        multiSelect
        selectedKeys={selectedCompliances}
        options={complianceSelfTestOptions}
        styles={dropdownStyles}
        onChange={onSelectionChanged}
      />
      <PrimaryButton
        onClick={onButtonClick}
        className={buttonStyles}
        disabled={!selectedCompliances || selectedCompliances.length === 0 || isDocumentLoading === LoadingState.Loading}
      >
        {isDocumentLoading === LoadingState.Loading ? (
          <>
            <Spinner className={spinnerStyle} />
            Generating self test...
          </>
        ) : (
          <>Generate self test</>
        )}
      </PrimaryButton>
    </>
  );
};
