import { format } from "date-fns";

export const chartDateDisplayFormat = 'MM/dd';
export const yearMonthDayFormat = 'yyyy-MM-dd';
export const monthDayYearTimeFormat = 'M/d/yyyy h:mm a';

export const parseDate = (dateString?: string): Date | undefined => (dateString ? new Date(dateString) : undefined);

export const getFormattedDate = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  return `${formattedDate.getMonth() + 1}/${formattedDate.getDate()}/${formattedDate.getFullYear()}`;
};

export const getFormattedDateTime = (date?: Date): string => {
  if (!date) {
    return '';
  }
  return format(date, monthDayYearTimeFormat);
};

export const getChartFormattedDate = (date?: Date): string => {
  if (!date) {
    return '';
  }
  const formattedDate = new Date(date);
  return `${formattedDate.toLocaleString('default', { month: 'short' })} ${formattedDate.getFullYear()}`;
};

export const formatDateInternationalStandard = (date?: Date | null | undefined): string => (date ? date.toISOString().substring(0, 10) : '');

/**
 * Converts a JS date object into its string representation, without a timezone, in the format 'YYYY-MM-DD'
 * @param date
 * @returns a string representation of the Date with no timezone information
 * @example
 * const d = new Date("Mon Jul 17 2023 12:10:20 GMT-0700")
 * const dateOnly = getDateOnlyString(d); // '2023-07-16'
 */
export const getDateOnlyString = (date?: Date | null | undefined): string => {
  if (!date) {
    return '';
  }
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const getChartFormattedMonthAndDate = (date?: Date): string => {
  if (!date) {
    return '';
  }

  return format(date, chartDateDisplayFormat);
};
