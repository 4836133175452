import { AccountInfo } from '@azure/msal-browser';
import { DefaultButton, IContextualMenuItem, IPersonaSharedProps, Persona, PersonaSize, mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useMtacIsAuthenticated } from 'hooks/useMtacIsAuthenticated';
import { menuStyles } from 'styles/contextMenu';
import { getMenuProps } from 'modules/contextMenu/contextMenu';
import { NotificationsPanel } from 'components/notificationsPanel/notificationsPanel';
import { useBoolean } from '@uifabric/react-hooks';
import { NotificationSettingProvider } from 'components/notificationSettingProvider/notificationSettingProvider';
import { CcmUser } from 'generated/clientApi';
import { AuthContext } from '../authProvider/authContext';

const userMenuStyle = mergeStyles({
  display: 'flex',
  margin: 'auto 0 auto auto',
});

const navLinkStyles = mergeStyles({
  color: NeutralColors.white,
  textDecoration: 'none',
  margin: 'auto 0',
});

const userInfoStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  color: NeutralColors.white,
});

const subheadingStyles = mergeStyles({
  color: NeutralColors.gray50,
});

const settingsMenuStyles = mergeStyles(
  {
    textAlign: 'right',
  },
  menuStyles,
);

const userPersonaStyles = mergeStyles({
  marginLeft: '1em',
});

export interface UserMenuProps {
  hideLoginOptions?: boolean;
}

export const UserMenu: FunctionComponent<UserMenuProps> = (props) => {
  const authContext = useContext(AuthContext);
  const [user, setUser] = useState<AccountInfo | CcmUser | null>(null);
  const isUserAuthenticated = useMtacIsAuthenticated();
  const [isNotificationSettingsOpen, { setTrue: openNotificationSettings, setFalse: dismissNotificationSettings }] = useBoolean(false);

  useEffect(() => {
    const fetchUser = async () => {
      const usr = await authContext.user();
      setUser(usr);
    };

    if (isUserAuthenticated) {
      fetchUser();
    }
  }, [authContext, isUserAuthenticated]);

  const handleLogin = useCallback(() => {
    authContext.signin();
  }, [authContext]);

  const handleLogout = useCallback(() => {
    const signOutTask = async () => {
      await authContext.signout();
    };
    signOutTask();
  }, [authContext]);

  const settingsMenuItems: IContextualMenuItem[] = [
    {
      key: 'notifications',
      text: 'Notifications',
      onClick: openNotificationSettings,
      iconProps: { iconName: 'CannedChat' },
    },
    {
      key: 'logout',
      text: 'Logout',
      onClick: handleLogout,
      iconProps: { iconName: 'SignOut' },
    },
  ];

  const personaDetails: IPersonaSharedProps = {
    text: user?.name,
    secondaryText: user instanceof CcmUser ? (user as CcmUser).id : (user as AccountInfo)?.username,
  };

  const settingsMenu = (
    <DefaultButton
      className={settingsMenuStyles}
      menuProps={getMenuProps(settingsMenuItems)}
      onRenderMenuIcon={() => <Persona {...personaDetails} className={userPersonaStyles} size={PersonaSize.size40} hidePersonaDetails />}
    >
      <div className={userInfoStyles}>
        <span>{user?.name}</span>
        <span className={subheadingStyles}>{user instanceof CcmUser ? (user as CcmUser).id : (user as AccountInfo)?.username}</span>
      </div>
    </DefaultButton>
  );

  return (
    <>
      <div className={userMenuStyle}>
        {!props.hideLoginOptions &&
          (isUserAuthenticated ? (
            settingsMenu
          ) : (
            <a href="#" className={navLinkStyles} onClick={handleLogin}>
              Login
            </a>
          ))}
      </div>
      <NotificationSettingProvider>
        <NotificationsPanel isOpen={isNotificationSettingsOpen} toggleVisibility={dismissNotificationSettings} />
      </NotificationSettingProvider>
    </>
  );
};
