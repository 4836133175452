import { mergeStyles } from '@fluentui/react';
import React, { CSSProperties, FunctionComponent } from 'react';

const legendStyles = mergeStyles({
  margin: '1em 0',
  padding: '0 1em 0 0.5em',
});

const countStyles = mergeStyles({
  fontWeight: 'bold',
  fontSize: '2em',
});

export interface LegendData {
  title: string;
  subtitle: string;
  count: number;
  color: string;
}

interface CustomLegendProps {
  legends: LegendData[];
  style?: CSSProperties;
}

export const CustomLegend: FunctionComponent<CustomLegendProps> = (props) => {
  const { legends, style } = props;

  return (
    <>
      {legends.map((legend) => (
        <div className={legendStyles} style={{ borderLeft: `5px solid ${legend.color}`, ...style }} key={legend.subtitle}>
          <b>{legend.title}</b>
          &nbsp;
          <br />
          {legend.subtitle}
          <div className={countStyles}>{legend.count}</div>
        </div>
      ))}
    </>
  );
};
