import { IButtonStyles, IIconProps, mergeStyles, NeutralColors, Pivot, PivotItem, SharedColors, TextField } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useCallback, useContext, useState } from 'react';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { isUSNat } from 'components/util/cloudUtil';
import { ConfigContext } from 'components/configProvider/configContext';
import { AuthContext } from '../../components/authProvider/authContext';
import { addSpace, getHeaderText, getValue } from '../../components/reviewComments/reviewComments.functions';
import {
  EvidenceComment,
  EvidenceUpdateType,
  IEvidenceComment,
  IEvidencePackage,
  IEvidencePackageCommentsPutCommand,
} from '../../generated/clientApi';
import { Certification } from '../../models/certification';
import { DomainStatus } from '../../models/domainStatus';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import {
  AUDITOR_ACTIONS_READ,
  AUTHORIZATION_ACTIONS_READ,
  DHS_AUTHORIZATION_ACTIONS_WRITE,
  DOD_AUTHORIZATION_ACTIONS_WRITE,
  GSA_AUTHORIZATION_ACTIONS_WRITE,
  SITE_WIDE_SUBJECT,
} from '../../modules/constants';
import { updateEvidencePackageComments, updateEvidencePackageDomainStatus } from '../../modules/evidencePackage/evidencePackage';
import { logError } from '../../modules/logging/logging';
import { showError } from '../../modules/messageBar/messageBar';
import { commentStyles } from '../../styles/comment';
import { pivotStyles } from '../../styles/pivot';
import { ActionButton } from '../reviewActionButton/actionButton';
import { SaveButton } from '../saving/saveButton';

const buttonStyle = mergeStyles({
  width: '12%',
  marginBottom: '20px',
  marginLeft: '5px',
});

const approveStyle: IButtonStyles = {
  root: [
    {
      background: SharedColors.cyan20,
      borderColor: SharedColors.cyan20,
      color: NeutralColors.white,
    },
  ],
  rootHovered: {
    background: SharedColors.cyan30,
    color: NeutralColors.white,
  },
};

const rejectStyle: IButtonStyles = {
  root: [
    {
      background: SharedColors.red10,
      borderColor: SharedColors.red10,
      color: NeutralColors.white,
    },
  ],
  rootHovered: {
    background: SharedColors.red20,
    color: NeutralColors.white,
  },
};

const commentContainerWithPivot = mergeStyles({
  width: 250,
  position: 'relative',
});

const commentContainer = mergeStyles({
  width: 500,
  position: 'relative',
});

const buttonContainerStyles = mergeStyles({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'center',
  width: '95%',
});

const headerStyles = mergeStyles({
  fontSize: '1em',
  fontWeight: 'bold',
  marginTop: '1.33em',
});

export enum ReviewCommentType {
  Domain,
  AOReview,
  AuditorReview,
}
export interface IReviewCommentProps {
  reviewCommentType: ReviewCommentType;
  domainName: string;
  onSaved?(evidencePackage: IEvidencePackage): void;
  onSaveReviewStatus?(domainStatus: DomainStatus, evidenceUpdateType: EvidenceUpdateType, userComment: string | undefined): void;
  onSaveReviewNotes?(evidenceUpdateType: EvidenceUpdateType, userComment: string): void;
  hideAuthorizingOfficialComments: boolean;
  hideAuditorComments: boolean;
  disableAuthorizingOfficialComments: boolean;
  disableAuditorComments: boolean;
  showPivot: boolean;
  hideSave: boolean;
}

interface CurrentStatusProps {
  status?: string;
  className?: string;
}

export const ReviewComments: FunctionComponent<IReviewCommentProps> = (props) => {
  const authContext = useContext(AuthContext);
  const evidenceContext = useContext(EvidenceContext);
  const configContext = useContext(ConfigContext);
  const isAuditor = authContext.isAuthorized([{ operation: AUDITOR_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isAO = authContext.isAuthorized([{ operation: AUTHORIZATION_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isDodOfficial = authContext.isAuthorized([{ operation: DOD_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);
  const isDhsOfficial = authContext.isAuthorized([{ operation: DHS_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);
  const isGsaOfficial = authContext.isAuthorized([{ operation: GSA_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);

  const [isSaving, setIsSaving] = useState<boolean>();
  const [isDomainStatusSaving, setIsDomainStatusSaving] = useState<DomainStatus | undefined>();
  const [auditorText, setAuditorText] = useState<string>();
  const [aoText, setAOText] = useState<string>();
  const [dodText, setDodText] = useState<string>();
  const [dhsText, setDhsText] = useState<string>();
  const [gsaText, setGsaText] = useState<string>();

  const {
    reviewCommentType,
    domainName,
    onSaved,
    hideAuditorComments,
    hideAuthorizingOfficialComments,
    disableAuthorizingOfficialComments,
    disableAuditorComments,
    onSaveReviewStatus,
    onSaveReviewNotes,
    showPivot,
    hideSave,
  } = props;
  const updateComment = async (type: EvidenceUpdateType, evidenceId?: string, serviceId?: string, text?: string) => {
    if (evidenceId === undefined || serviceId === undefined) {
      return;
    }

    setIsSaving(true);
    if (reviewCommentType === ReviewCommentType.Domain) {
      const evidenceComment: IEvidenceComment = { comment: text, domain: domainName, createdDate: new Date() };
      const evidencePackage = await updateEvidencePackageComments({
        id: evidenceId,
        serviceOid: serviceId,
        commentType: type,
        comment: evidenceComment as EvidenceComment,
      });

      if (onSaved) {
        await onSaved(evidencePackage);
      }
    } else if (onSaveReviewNotes && text) {
      onSaveReviewNotes(type, text);
    }
    setIsSaving(false);
  };

  const handleOnSave = (evidenceUpdateType: EvidenceUpdateType, userComment: string | undefined) => {
    if (!userComment) {
      return;
    }
    updateComment(evidenceUpdateType, evidenceContext.evidencePackage?.id, evidenceContext.evidencePackage?.serviceOid, userComment);
  };

  const cacheCommentsForExternalSave = useCallback(
    (type: EvidenceUpdateType, text?: string) => {
      if (!evidenceContext.evidencePackage?.id || !evidenceContext.evidencePackage?.serviceOid) {
        return;
      }

      const evidenceComment: IEvidenceComment = { comment: text, domain: domainName, createdDate: new Date() };
      const evidencePackageComment: IEvidencePackageCommentsPutCommand = {
        id: evidenceContext.evidencePackage?.id,
        serviceOid: evidenceContext.evidencePackage?.serviceOid,
        commentType: type,
        comment: evidenceComment as EvidenceComment,
      };
      evidenceContext.addSupportingComments(evidencePackageComment);
    },
    [domainName, evidenceContext],
  );

  const onAuditorTextChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => {
      if (hideSave) {
        cacheCommentsForExternalSave(EvidenceUpdateType.Auditor, input);
      }
      setAuditorText(input);
      return input;
    },
    [hideSave, cacheCommentsForExternalSave],
  );

  const onAOTextChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => {
      if (hideSave) {
        cacheCommentsForExternalSave(EvidenceUpdateType.AuthorizingOfficial, input);
      }
      setAOText(input);
      return input;
    },
    [hideSave, cacheCommentsForExternalSave],
  );

  const onDodTextChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => {
      if (hideSave) {
        cacheCommentsForExternalSave(EvidenceUpdateType.DodOffical, input);
      }
      setDodText(input);
      return input;
    },
    [hideSave, cacheCommentsForExternalSave],
  );

  const onDhsTextChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => {
      if (hideSave) {
        cacheCommentsForExternalSave(EvidenceUpdateType.DhsOfficial, input);
      }
      setDhsText(input);
      return input;
    },
    [hideSave, cacheCommentsForExternalSave],
  );

  const onGsaTextChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => {
      if (hideSave) {
        cacheCommentsForExternalSave(EvidenceUpdateType.GsaOfficial, input);
      }
      setGsaText(input);
      return input;
    },
    [hideSave, cacheCommentsForExternalSave],
  );

  const CurrentStatus: FunctionComponent<CurrentStatusProps> = (props) => {
    const { className, status } = props;
    return (
      <div className={className}>
        Status: <strong>{status}</strong>
      </div>
    );
  };

  const onButtonClick = async (domainStatus: DomainStatus, evidenceUpdateType: EvidenceUpdateType, userComment: string | undefined) => {
    if (reviewCommentType === ReviewCommentType.Domain) {
      await updateDomainStatus(domainStatus, evidenceUpdateType, userComment);
      return;
    }

    if (onSaveReviewStatus) {
      onSaveReviewStatus(domainStatus, evidenceUpdateType, userComment);
    }
  };

  const updateDomainStatus = async (domainStatus: DomainStatus, evidenceUpdateType: EvidenceUpdateType, userComment: string | undefined) => {
    setIsDomainStatusSaving(domainStatus);
    if (evidenceContext.evidencePackage === undefined) {
      return;
    }

    try {
      const updatedEvidencePackage = await updateEvidencePackageDomainStatus({
        id: evidenceContext.evidencePackage.id,
        serviceOid: evidenceContext.evidencePackage.serviceOid,
        domainName,
        domainStatus,
        evidenceUpdateType,
        comment: userComment,
      });
      if (onSaved) {
        onSaved(updatedEvidencePackage);
      }
    } catch (error) {
      const message = 'Evidence Domain Status Failed to Update.';
      logError(message, error);
      showError(`${message} Please refresh and try again.`);
    }
    setIsDomainStatusSaving(undefined);
  };

  const getDomainStatus = (evidenceUpdateType: EvidenceUpdateType): string => {
    const domain = evidenceContext.evidencePackage?.evidenceDomains?.find((evidenceDomain) => evidenceDomain.name === domainName);
    if (!domain) {
      return DomainStatus.Incomplete;
    }

    let status;

    if (evidenceUpdateType === EvidenceUpdateType.Auditor) {
      status = domain.auditorDomainReviewStatus?.status;
    }

    if (
      evidenceUpdateType === EvidenceUpdateType.AuthorizingOfficial ||
      evidenceUpdateType === EvidenceUpdateType.DhsOfficial ||
      evidenceUpdateType === EvidenceUpdateType.DodOffical ||
      evidenceUpdateType === EvidenceUpdateType.GsaOfficial
    ) {
      status = domain.authorizingOfficialDomainReviewStatus?.status;
    }

    if (status) {
      return status;
    }

    return DomainStatus.Incomplete;
  };

  const showApproveReject = (evidenceUpdateType: EvidenceUpdateType): boolean => {
    if (
      evidenceUpdateType === EvidenceUpdateType.Auditor &&
      (evidenceContext.evidencePackage?.status === EvidencePackageStatus.AuditorReview ||
        evidenceContext.evidencePackage?.status === EvidencePackageStatus.AuditorSubmit)
    ) {
      return true;
    }

    if (
      (evidenceUpdateType === EvidenceUpdateType.AuthorizingOfficial ||
        evidenceUpdateType === EvidenceUpdateType.DhsOfficial ||
        evidenceUpdateType === EvidenceUpdateType.DodOffical ||
        evidenceUpdateType === EvidenceUpdateType.GsaOfficial) &&
      (evidenceContext.evidencePackage?.status === EvidencePackageStatus.AoReview ||
        evidenceContext.evidencePackage?.status === EvidencePackageStatus.AoSubmit)
    ) {
      return true;
    }

    return false;
  };
  const approveIcon: IIconProps = { iconName: 'CheckMark' };
  const rejectIcon: IIconProps = { iconName: 'StatusCircleErrorX' };

  const getCommentView = (
    id: string,
    label: string,
    disable: boolean,
    evidenceUpdateType: EvidenceUpdateType,
    userComment: string | undefined,
    onTextChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => string | undefined,
    text?: string,
  ) => (
    <div className={showPivot ? commentContainerWithPivot : commentContainer}>
      {reviewCommentType === ReviewCommentType.Domain && <CurrentStatus status={getDomainStatus(evidenceUpdateType)} />}
      <TextField
        label={reviewCommentType === ReviewCommentType.Domain ? 'Comment' : 'Summary'}
        placeholder={reviewCommentType === ReviewCommentType.Domain ? 'Enter your comments' : 'Enter your final comments'}
        multiline
        rows={5}
        defaultValue={text}
        onChange={onTextChange}
        styles={commentStyles}
        disabled={disable}
      />

      <div className={buttonContainerStyles}>
        {showApproveReject(evidenceUpdateType) && (
          <>
            <ActionButton
              id={`Approve-${label}`}
              defaultText="Approve"
              onSave={() => onButtonClick(DomainStatus.Approved, evidenceUpdateType, userComment)}
              isSaving={isDomainStatusSaving === DomainStatus.Approved}
              styles={approveStyle}
              className={buttonStyle}
              dataTestId="approve"
              disabled={disable}
              iconProps={approveIcon}
            />
            <ActionButton
              id={`Reject-${label}`}
              defaultText="Reject"
              onSave={() => onButtonClick(DomainStatus.Rejected, evidenceUpdateType, userComment)}
              isSaving={isDomainStatusSaving === DomainStatus.Rejected}
              styles={rejectStyle}
              className={buttonStyle}
              dataTestId="reject"
              disabled={disable}
              iconProps={rejectIcon}
            />
          </>
        )}

        {!hideSave && (
          <SaveButton
            id={id}
            defaultText="Save"
            onSave={() => handleOnSave(evidenceUpdateType, userComment)}
            disabled={!userComment}
            isSaving={isSaving}
            className={buttonStyle}
          />
        )}
      </div>
    </div>
  );

  // showAoComments could easily be simplified but it may be more clear to match the logic for getAoComments()
  const showAoComments: boolean =
    (evidenceContext.evidencePackage?.certification &&
      evidenceContext.evidencePackage?.certification !== Certification.FedRAMP &&
      isAO &&
      !hideAuthorizingOfficialComments) ||
    (evidenceContext.evidencePackage?.certification === Certification.FedRAMP && isDodOfficial && !hideAuthorizingOfficialComments) ||
    (evidenceContext.evidencePackage?.certification === Certification.FedRAMP && isDhsOfficial && !hideAuthorizingOfficialComments) ||
    (evidenceContext.evidencePackage?.certification === Certification.FedRAMP && isGsaOfficial && !hideAuthorizingOfficialComments);

  const getAoComments = (): JSX.Element => (
    <>
      {evidenceContext.evidencePackage?.certification &&
        evidenceContext.evidencePackage?.certification !== Certification.FedRAMP &&
        isAO &&
        !hideAuthorizingOfficialComments && (
          <>
            <strong>Authorizing Official</strong>
            {getCommentView(
              'Btn_Ao_Save',
              `${addSpace(domainName)}`,
              disableAuthorizingOfficialComments,
              EvidenceUpdateType.AuthorizingOfficial,
              aoText,
              onAOTextChange,
              reviewCommentType === ReviewCommentType.Domain
                ? getValue(domainName, evidenceContext.evidencePackage?.evidenceComments?.authorizingOfficialComments)
                : evidenceContext.evidencePackage?.aoUserDecision?.comment,
            )}
          </>
        )}
      {evidenceContext.evidencePackage?.certification === Certification.FedRAMP && isDodOfficial && !hideAuthorizingOfficialComments && (
        <>
          <strong>DoD</strong>
          {getCommentView(
            'Btn_Dod_Save',
            `${addSpace(domainName)}`,
            disableAuthorizingOfficialComments,
            EvidenceUpdateType.DodOffical,
            dodText,
            onDodTextChange,
            reviewCommentType === ReviewCommentType.Domain
              ? getValue(domainName, evidenceContext.evidencePackage?.evidenceComments?.dodOfficialComments)
              : evidenceContext.evidencePackage?.dodUserDecision?.comment,
          )}
        </>
      )}
      {evidenceContext.evidencePackage?.certification === Certification.FedRAMP && isDhsOfficial && !hideAuthorizingOfficialComments && (
        <>
          <strong>DHS</strong>
          {getCommentView(
            'Btn_Dhs_Save',
            `${addSpace(domainName)}`,
            disableAuthorizingOfficialComments,
            EvidenceUpdateType.DhsOfficial,
            dhsText,
            onDhsTextChange,
            reviewCommentType === ReviewCommentType.Domain
              ? getValue(domainName, evidenceContext.evidencePackage?.evidenceComments?.dhsOfficialComments)
              : evidenceContext.evidencePackage?.dhsUserDecision?.comment,
          )}
        </>
      )}
      {evidenceContext.evidencePackage?.certification === Certification.FedRAMP && isGsaOfficial && !hideAuthorizingOfficialComments && (
        <>
          <strong>GSA</strong>
          {getCommentView(
            'Btn_Gsa_Save',
            `${addSpace(domainName)}`,
            disableAuthorizingOfficialComments,
            EvidenceUpdateType.GsaOfficial,
            gsaText,
            onGsaTextChange,
            reviewCommentType === ReviewCommentType.Domain
              ? getValue(domainName, evidenceContext.evidencePackage?.evidenceComments?.gsaOfficialComments)
              : evidenceContext.evidencePackage?.gsaUserDecision?.comment,
          )}
        </>
      )}
    </>
  );

  const getAuditorComments = () =>
    getCommentView(
      'Btn_Auditor_Save',
      `${addSpace(domainName)}`,
      disableAuditorComments,
      EvidenceUpdateType.Auditor,
      auditorText,
      onAuditorTextChange,
      reviewCommentType === ReviewCommentType.Domain
        ? getValue(domainName, evidenceContext.evidencePackage?.evidenceComments?.auditorComments)
        : evidenceContext.evidencePackage?.auditorResponse,
    );

  return (
    <div>
      <div className={headerStyles}>{getHeaderText(reviewCommentType)}</div>
      <div hidden={reviewCommentType !== ReviewCommentType.Domain}>These will be submitted with the packages.</div>
      {showPivot ? (
        <>
          <Pivot styles={pivotStyles}>
            {!isUSNat(configContext.serverConfig?.cloud) && evidenceContext.evidencePackage?.certification && isAuditor && !hideAuditorComments && (
              <PivotItem headerText="Auditor">
                <br />
                {getAuditorComments()}
              </PivotItem>
            )}
            {showAoComments && (
              <PivotItem headerText="AO">
                <br />
                {getAoComments()}
              </PivotItem>
            )}
          </Pivot>
        </>
      ) : (
        <>
          {showAoComments && getAoComments()}
          {getAuditorComments()}
        </>
      )}
    </div>
  );
};
