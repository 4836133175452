import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Deviation,
  DeviationPropertyState,
  DeviationStatuses,
  DeviationTypes,
  PoamExclusion,
  PoamExclusionsGetClient,
  PoamExclusionsGetResponse,
  PoamExclusionsOrGetClient,
  PoamExclusionsOrGetResponse,
} from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import {
  DetailsList,
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IColumn,
  IPlainCardProps,
  ISearchBoxStyles,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  SearchBox,
  SelectionMode,
  Spinner,
  SpinnerSize,
  mergeStyles,
} from '@fluentui/react';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { AzureStatusNoneIcon, AzureStatusPendingIcon, AzureStatusSuccessIcon } from 'modules/azureIcons';
import { pivotStyles } from 'styles/pivot';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

let timeoutId: NodeJS.Timeout | undefined;

const debounce = (func: (...args: any[]) => void, delay: number) => {
  const debouncedFunction = (...args: any[]) => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
  return debouncedFunction;
};

const customPoamTableStyles = mergeStyles({
  '.ms-DetailsRow-fields': {
    userSelect: 'text !important',
  },
  height: 'calc(100vh - (215px))',
  overflow: 'auto',
});

const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: '270px', height: '28px' } };

type ExceptionsPanelProps = {
  close: () => void;
};
export const ExceptionsPanel: React.FunctionComponent<ExceptionsPanelProps> = (props) => {
  const { close } = props;
  const { organization } = useContext(ConMonFilterContext);

  const [fpPoams, setFpPoams] = useState<PoamExclusionsGetResponse>({
    results: [] as PoamExclusion[][],
    continuationToken: '',
    cloudList: [] as string[],
  } as PoamExclusionsGetResponse);
  const [orPoams, setOrPoams] = useState<PoamExclusionsOrGetResponse>({
    results: [] as PoamExclusion[][],
    continuationToken: '',
  } as PoamExclusionsOrGetResponse);
  const [fpLoading, setFpLoading] = useState<boolean>(true);
  const [orLoading, setOrLoading] = useState<boolean>(true);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [searchText, setSearchText] = useState<string>();

  const [cloudList, setCloudList] = useState<string[]>([]);

  const getFpPoams = useCallback(
    async (replace = false) => {
      setFpLoading(true);
      if (replace) {
        setFpPoams((prev) => ({ ...prev, results: [] as PoamExclusion[][], continuationToken: '' }) as PoamExclusionsGetResponse);
      }
      const client = new PoamExclusionsGetClient(getConfig().apiBaseUri);
      try {
        const response = await client.get(replace ? '' : fpPoams.continuationToken, searchText, organization);
        setFpPoams((prev) => {
          if (replace) {
            return response;
          }
          return {
            results: [...(prev?.results ?? []), ...(response.results ?? [])],
            continuationToken: response.continuationToken,
          } as PoamExclusionsGetResponse;
        });
        if (cloudList.length === 0) {
          setCloudList(response.cloudList ?? []);
        }
      } catch (e) {
        setErrorMessage('There was an error fetching vuln exceptions. Please refresh to try again.');
      } finally {
        setFpLoading(false);
      }
    },
    [cloudList.length, fpPoams.continuationToken, searchText, organization],
  );
  const getOrPoams = useCallback(
    async (replace = false) => {
      setOrLoading(true);
      if (replace) {
        setOrPoams({ results: [] as PoamExclusion[][], continuationToken: '' } as PoamExclusionsOrGetResponse);
      }
      const client = new PoamExclusionsOrGetClient(getConfig().apiBaseUri);
      try {
        const response = await client.get(replace ? '' : orPoams.continuationToken, searchText, organization);
        setOrPoams((prev) => {
          if (replace) {
            return response;
          }
          return {
            results: [...(prev?.results ?? []), ...(response.results ?? [])],
            continuationToken: response.continuationToken,
          } as PoamExclusionsOrGetResponse;
        });
      } catch (e) {
        setErrorMessage('There was an error fetching vuln exceptions. Please refresh to try again.');
      } finally {
        setOrLoading(false);
      }
    },
    [orPoams.continuationToken, searchText, organization],
  );

  useEffect(() => {
    getFpPoams();
    getOrPoams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cloudList.length) {
      const debouncedFetchData = debounce(() => {
        getFpPoams(true);
        getOrPoams(true);
      }, 500);
      debouncedFetchData();
    }
    return () => {
      clearTimeout(timeoutId as NodeJS.Timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const onScrollReachedBottom = useCallback(() => {
    if (fpLoading === false && !!fpPoams?.continuationToken) {
      getFpPoams();
    }
  }, [fpPoams?.continuationToken, getFpPoams, fpLoading]);

  const onOrScrollReachedBottom = useCallback(() => {
    if (orLoading === false && !!orPoams?.continuationToken) {
      getOrPoams();
    }
  }, [orLoading, orPoams?.continuationToken, getOrPoams]);

  const onScroll = (event: React.UIEvent<HTMLElement>, func: () => void): void => {
    const target = event.target as HTMLInputElement;
    if (target.scrollHeight - target.scrollTop <= 1.05 * target.clientHeight) {
      func();
    }
  };

  const onRenderPlainCard = (submittedBy: string, drDate: string, expiration?: string): JSX.Element => (
    <div style={{ width: '246px', padding: '2rem' }}>
      <div>
        <div style={{ fontWeight: 'bold', display: 'inline' }}>Submitted by: </div>
        <span>{submittedBy}</span>
      </div>
      <div style={{ fontWeight: 'bold', display: 'inline' }}>Submitted date: </div>
      <span>{drDate}</span>
      {expiration !== undefined && (
        <div>
          <div style={{ fontWeight: 'bold', display: 'inline' }}>Expires on: </div>
          <span>{expiration || 'Never'}</span>
        </div>
      )}
    </div>
  );

  const getCellContent = (dev: Deviation | undefined, status: 'yes' | 'pending', expiration?: string): JSX.Element => {
    const submittedBy = dev?.submittedBy ?? 'Unknown';
    const drDate = dev?.drDate ?? 'Unknown';
    const plainCardProps: IPlainCardProps = {
      onRenderPlainCard: () => onRenderPlainCard(submittedBy, drDate, expiration),
      directionalHint: DirectionalHint.topCenter,
    };
    return (
      <HoverCard
        style={{
          border: 'none',
          borderRadius: '4px',
          background: '#FFF',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
        }}
        plainCardProps={plainCardProps}
        instantOpenOnClick
        type={HoverCardType.plain}
      >
        {status === 'yes' ? <AzureStatusSuccessIcon /> : <AzureStatusPendingIcon />}
      </HoverCard>
    );
  };

  const getColumns = useCallback(
    (property: 'fp' | 'or'): IColumn[] => {
      if (cloudList.length === 0) {
        return [];
      }
      const deviationType = property === 'fp' ? DeviationTypes.FalsePositive : DeviationTypes.OperationalRequirement;
      const vulnIdCol: IColumn = {
        key: 'Vuln ID',
        name: 'Vuln ID',
        minWidth: 100,
        maxWidth: 120,
        onRender: (item: PoamExclusion[]) => <>{item[0].vulnerabilityId}</>,
      };
      const cloudTypeCols: IColumn[] = cloudList.map((cloud) => ({
        key: cloud,
        name: cloud,
        minWidth: 70,
        maxWidth: 70,
        onRender: (item: PoamExclusion[]) => {
          const poam = item.find((poam) => poam.cloudType === cloud);
          if (poam) {
            const expiration = deviationType === DeviationTypes.OperationalRequirement ? poam.orExpirationDate ?? '' : undefined;

            let deviationInfo: Deviation | undefined;
            if (poam[property] === DeviationPropertyState.Yes) {
              deviationInfo = poam.deviationInfo?.find((dev) => dev.deviationType === deviationType && dev.drApproval === DeviationStatuses.Approved);
            } else {
              deviationInfo = poam.deviationInfo?.find((dev) => dev.deviationType === deviationType && dev.drApproval === DeviationStatuses.Proposed);
            }
            if (poam[property] === DeviationPropertyState.Yes) {
              return getCellContent(deviationInfo, 'yes', expiration);
            }
            if (poam[property] === DeviationPropertyState.Pending) {
              return getCellContent(deviationInfo, 'pending', expiration);
            }
          }
          return <AzureStatusNoneIcon />;
        },
      }));

      const earliestSubmittalDateCol: IColumn = {
        key: 'First submittal date',
        name: 'First submittal date',
        minWidth: 130,
        maxWidth: 150,
        onRender: (item: PoamExclusion[]) => {
          const earliestSubmittalDate = item.map((poam) => poam.deviationInfo?.find((dev) => dev.deviationType === deviationType)?.drDate).sort()[0];
          return <>{earliestSubmittalDate ?? 'Unknown'}</>;
        },
      };
      return [vulnIdCol, ...cloudTypeCols, earliestSubmittalDateCol];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cloudList],
  );

  const CommonSearchBox = useMemo(
    () => (
      <div style={{ marginTop: '1.5rem' }}>
        <SearchBox
          placeholder="Search by Vuln ID or POA&M ID"
          value={searchText}
          styles={searchBoxStyles}
          onEscape={(_) => setSearchText(undefined)}
          onClear={(_) => setSearchText(undefined)}
          onChange={(_, newValue) => setSearchText(newValue)}
        />
      </div>
    ),
    [searchText],
  );

  return (
    <Panel
      isOpen
      onDismiss={close}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      headerText="Vulnerabilities with exceptions"
      className={sideModalPoamStyles}
    >
      {errorMessage && (
        <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setErrorMessage(undefined)} dismissButtonAriaLabel="Dismiss">
          {errorMessage}
        </MessageBar>
      )}
      <Pivot styles={pivotStyles} style={{ marginTop: '2rem' }}>
        <PivotItem headerText="False positive">
          {CommonSearchBox}
          <div className={customPoamTableStyles} onScroll={(ev) => onScroll(ev, onScrollReachedBottom)}>
            <DetailsList
              items={fpPoams.results ?? []}
              columns={getColumns('fp')}
              selectionMode={SelectionMode.none}
              onShouldVirtualize={() => false}
            />
            {fpLoading && <Spinner label="Loading vuln exceptions..." size={SpinnerSize.large} />}
            {!fpPoams.results?.length && !fpLoading && (
              <em style={{ display: 'block', marginTop: '2rem', marginLeft: '2rem' }}>No FP exceptions found.</em>
            )}
          </div>
        </PivotItem>
        <PivotItem headerText="Operational requirement">
          {CommonSearchBox}
          <div className={customPoamTableStyles} onScroll={(ev) => onScroll(ev, onOrScrollReachedBottom)}>
            <DetailsList
              items={orPoams.results ?? []}
              columns={getColumns('or')}
              selectionMode={SelectionMode.none}
              onShouldVirtualize={() => false}
            />
            {orLoading && <Spinner label="Loading vuln exceptions..." size={SpinnerSize.large} />}
            {!orPoams.results?.length && !orLoading && (
              <em style={{ display: 'block', marginTop: '2rem', marginLeft: '2rem' }}>No OR exceptions found.</em>
            )}
          </div>
        </PivotItem>
      </Pivot>
    </Panel>
  );
};
