import {
  ActionButton,
  ChoiceGroup,
  DetailsRow,
  GroupHeader,
  GroupedList,
  IChoiceGroupOption,
  IGroup,
  IGroupHeaderCheckboxProps,
  IGroupHeaderProps,
  IGroupRenderProps,
  IObjectWithKey,
  IScrollablePaneStyles,
  Icon,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { CveInfo } from 'generated/clientApi';
import { RiskCalculatorJustifications, RiskCalculatorSubValue, RiskCalculatorValues, riskCalculatorItems } from './riskCalculatorTypes';
import { CVEsInformationTable } from '../cvesInformationTable';
import { calculateCVSSFromVector } from './cvssCalc';
import { riskColors } from '../poamTab';

type RiskCalculatorTabProps = {
  riskCalculatorValues: RiskCalculatorValues;
  updateRiskCalculator: (
    valueKey: keyof RiskCalculatorValues,
    newKey: keyof RiskCalculatorJustifications | undefined,
    newJustification: string | null,
  ) => void;
  setProposedRiskValues: React.Dispatch<
    React.SetStateAction<{
      proposedScore: string;
      fullVectorString: string;
    }>
  >;
  cveInfos: CveInfo[] | undefined;
  maxCVE: CveInfo | undefined;
};

export const riskCalculatorTabStyle = mergeStyles({
  selectors: {
    '& .ms-Textfield': {
      flex: 1,
    },
    '& .ms-Label, .is-checked': {
      fontWeight: 'bold',
    },
  },
});

const scrollPaneStyles = {
  root: {
    '.ms-DetailsList': {
      overflow: 'visible',
    },
  },
};

const customTableStyles = mergeStyles({
  '.ms-Viewport': {
    minWidth: 'inherit',
  },
  flex: 2,
  height: '100%',
  overflow: 'auto',
  width: '100%',
  position: 'relative',
  selectors: {
    '& .ms-GroupHeader:hover, .ms-DetailsRow:hover': {
      backgroundColor: 'white',
    },
    '& .ms-DetailsRow': {
      borderBottom: '0 !important',
      width: '90%',
    },
    '& .ms-DetailsRow-fields': {
      flex: 1,
    },
    '& .ms-DetailsRow-fields > div': {
      width: '100% !important',
    },
  },
});

const groupProps: IGroupRenderProps = {
  onRenderHeader: (props?: IGroupHeaderProps): JSX.Element => <GroupHeader onRenderGroupHeaderCheckbox={onRenderGroupHeaderCheckbox} {...props} />,
};

const onRenderGroupHeaderCheckbox = (props?: IGroupHeaderCheckboxProps) => {
  const iconStyles = { root: { fontSize: '36px' } };

  return props?.checked ? <Icon iconName="ToggleRight" styles={iconStyles} /> : <Icon iconName="ToggleLeft" styles={iconStyles} />;
};

export const RiskCalculatorTab: React.FunctionComponent<RiskCalculatorTabProps> = (props) => {
  const { riskCalculatorValues, updateRiskCalculator, cveInfos, maxCVE, setProposedRiskValues } = props;

  const fullVectorString = useMemo(() => {
    const adjustedString = Object.values(riskCalculatorValues)
      .map((value) => value.option.toUpperCase())
      .join('/');
    return `${maxCVE?.vectorString}/${adjustedString}`;
  }, [maxCVE?.vectorString, riskCalculatorValues]);

  const {
    environmentalMetricScore: adjustedCvssScore,
    environmentalSeverity: adjustedRating,
    baseMetricScore,
    baseSeverity,
  } = useMemo(() => calculateCVSSFromVector(fullVectorString) as any, [fullVectorString]);

  useEffect(() => {
    setProposedRiskValues({ proposedScore: adjustedCvssScore, fullVectorString });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedCvssScore, fullVectorString]);

  const rows: IObjectWithKey[] = useMemo(() => {
    const checkboxes = riskCalculatorItems.map((riskGroup) => {
      const formattedRiskGroupsCheckboxes = riskGroup.items.map((item) => {
        const optionSelected = (riskCalculatorValues as any)[`${riskGroup.key}--${item.key}`] as RiskCalculatorSubValue;

        return {
          key: item.key,
          component: optionSelected ? (
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'stretch' }} className={riskCalculatorTabStyle}>
              <div style={{ flexBasis: '40%' }}>
                <ChoiceGroup
                  options={item.items}
                  onChange={(ev: any, option: any) => changeValueOnRiskCalculator(`${riskGroup.key}--${item.key}`, option, null)}
                  label={item.name}
                  selectedKey={optionSelected.option}
                />
              </div>
              {optionSelected.option && !optionSelected.option.toLowerCase().includes(':x') && (
                <div style={{ flex: 1 }}>
                  <TextField
                    label="Justification"
                    multiline
                    resizable={false}
                    required
                    placeholder="Enter a justification"
                    value={optionSelected.justification}
                    onChange={(ev: any, newValue: any) => changeValueOnRiskCalculator(`${riskGroup.key}--${item.key}`, undefined, newValue)}
                  />
                </div>
              )}
            </div>
          ) : null,
        };
      });

      return formattedRiskGroupsCheckboxes;
    });

    return checkboxes.flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskCalculatorValues]);

  const columns = useMemo(
    () => [
      {
        fieldName: 'component',
        key: 'component',
        minWidth: 500,
        name: 'component',
      },
    ],
    [],
  );
  const [groups, setGroups] = useState<IGroup[]>(riskCalculatorItems.map((riskCalculatorItem) => ({ ...riskCalculatorItem, items: undefined })));

  const expandAllGroups = () => {
    setGroups((currGroups) => currGroups.map((group) => ({ ...group, isCollapsed: false })));
  };

  const changeValueOnRiskCalculator = (valueKey: string, newKey: IChoiceGroupOption | undefined, newJustification: string | null) => {
    if ((newKey !== null && newKey !== undefined) || (newJustification !== null && newJustification !== undefined)) {
      updateRiskCalculator(valueKey as keyof RiskCalculatorValues, newKey?.key as keyof RiskCalculatorJustifications, newJustification);
    }
  };

  const displayRisk = (riskRating: string, riskScore: string, text: string) => {
    const barColor = Object.hasOwn(riskColors, riskRating?.toLowerCase()) ? riskColors[riskRating.toLowerCase() as keyof typeof riskColors] : 'white';

    return (
      <div style={{ height: '50px', borderLeft: `5px ${barColor} solid`, width: '80px' }}>
        <div style={{ paddingLeft: '.31rem' }}>
          <div style={{ fontSize: '11px', fontWeight: 600, lineHeight: '0.875rem' }}>{text}</div>
          <div style={{ fontSize: '11px', lineHeight: '0.875rem' }}>{riskRating?.toUpperCase()}</div>
          <div style={{ fontSize: '22px', fontWeight: 600, lineHeight: '1.4rem' }}>{riskScore}</div>
        </div>
      </div>
    );
  };

  const onRenderCell = React.useCallback(
    (nestingDepth?: number, item?: any, itemIndex?: number, group?: IGroup): React.ReactNode => (
      <DetailsRow
        columns={columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex!}
        selectionMode={SelectionMode.none}
        group={group}
      />
    ),
    [columns],
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div style={{ display: 'flex', gap: '1.5rem', alignItems: 'baseline', marginTop: '1.5rem' }}>
        <span style={{ fontWeight: 'bold' }}>Environmental and Temporal score metrics</span>
        <ActionButton className={actionButtonStyles} style={{ height: 'auto', marginRight: '15.5rem' }} onClick={() => expandAllGroups()}>
          Expand all
        </ActionButton>
        <div style={{ display: 'flex', gap: '5.5rem' }}>
          {displayRisk(adjustedRating, adjustedCvssScore, 'Adjusted')}
          {displayRisk(baseSeverity, baseMetricScore, 'Original')}
        </div>
      </div>
      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
      <span>Base vector: {maxCVE?.vectorString ?? 'No CVEs associated with this POA&M'}</span>
      <div className={customTableStyles}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={scrollPaneStyles as unknown as IScrollablePaneStyles}>
          <GroupedList items={rows} onRenderCell={onRenderCell} selectionMode={SelectionMode.none} groups={groups} groupProps={groupProps} />
        </ScrollablePane>
      </div>
      <div style={{ marginBottom: '1.5rem' }}>
        {cveInfos && cveInfos.length ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 'bold' }}>Associated CVEs</span>
            <CVEsInformationTable cves={cveInfos} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
