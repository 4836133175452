import { createContext } from 'react';
import { IAzureService } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';

export interface ServiceContextProps {
  services: IAzureService[] | undefined;
  servicesLoadingState: LoadingState;
  requestServices: () => void;
  selectedService: IAzureService | undefined;
  setSelectedService: (serviceOid: string) => void;
  updateSelectedService: (service: IAzureService) => void;
}

export const ServiceContext = createContext<ServiceContextProps>({
  services: undefined,
  servicesLoadingState: LoadingState.NotLoaded,
  requestServices: (): void => {
    // do nothing for the base case
  },
  selectedService: undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSelectedService: (serviceOid: string): void => {
    // do nothing for the base case
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateSelectedService: (service: IAzureService): void => {
    // do nothing for the base case
  },
});

export const ServiceConsumer = ServiceContext.Consumer;
