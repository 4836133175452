import { mergeStyles } from '@fluentui/react';

/**
 * A custom class for the fluent UI \<ActionButton> component to style it properly with MTAC's current AzureThemeLight theme
 * @example
 * <ActionButton className={actionButtonStyles} iconProps={{ iconName: 'Undo' }}>
 *     Undo
 * </ActionButton>
 */
export const actionButtonStyles = mergeStyles({
  border: 'none',
  ':hover': {
    color: 'rgb(0, 120, 212)',
    backgroundColor: 'inherit',
    border: 'none',
  },
  ':active': {
    backgroundColor: 'inherit',
    border: 'none',
  },
  ':disabled': {
    backgroundColor: 'inherit',
    border: 'none',
    color: 'rgb(161, 159, 157)',
  },
  ':focus': {
    outline: 'none',
  },
});
