import { GroupHeader, IGroupHeaderProps, mergeStyles } from '@fluentui/react';
import React from 'react';
import { IExtendedGroup } from './commentSummary';
import { scoreColumnWidth } from './commentSummary.function';

const scoreStyle = mergeStyles({
  display: 'inline-block',
  float: 'right',
  width: scoreColumnWidth,
  textAlign: 'left',
});

export const CommentSummaryGroupHeader = (props: IGroupHeaderProps): JSX.Element => {
  // Overriding the default onRenderTitle method to add scoreText
  const onRenderTitle = (groupHeaderProps: IGroupHeaderProps | undefined): JSX.Element => {
    if (!groupHeaderProps) {
      return <></>;
    }

    const extendedGroup = groupHeaderProps.group as IExtendedGroup;
    const scoreText = extendedGroup.scoreText || '';

    return (
      <div className={mergeStyles(groupHeaderProps.className, { width: '100%' })}>
        <strong>{`${groupHeaderProps.group?.name} (${groupHeaderProps.group?.count})`}</strong>
        <span className={scoreStyle}>{scoreText}</span>
      </div>
    );
  };

  return <GroupHeader {...props} onRenderTitle={onRenderTitle} />;
};
