import { DefaultButton, Panel, PanelType, Separator, TextField } from '@fluentui/react';
import { SaveButton } from 'components/saving/saveButton';
import {
  CveInfo,
  Poam,
  PoamDeviationDeleteClient,
  PoamDeviationDeleteCommand,
  PoamGetOneClient,
  PoamGetOneResponse,
  PoamPutClient,
  PoamPutCommand,
  PoamState,
  ServiceAssetType,
} from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { conmonTabKeys, separatorStyles } from 'pages/conMon/conMon';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { ProgressDots } from 'components/progressDots/progressDots';
import { useHistory } from 'react-router';
import { continuousMonitoringRoute } from 'modules/routes/routes';
import { PanelDetailsOverview } from './poamDetailsOverview';
import { PanelDetailsTabs } from './poamDetailsTabs';

type DetailsPanelProps = {
  selectedPoamId?: string;
  selectedPeriod?: string;
  onPoamSaved: () => void;
  isEditable: boolean;
};
export const DetailsPanel: React.FunctionComponent<DetailsPanelProps> = (props) => {
  const { selectedPoamId, selectedPeriod, onPoamSaved, isEditable } = props;

  const [internalPoam, setInternalPoam] = useState<Poam | undefined>();
  const [internalAssets, setInternalAssets] = useState<ServiceAssetType[] | undefined>();
  const [internalCveInfos, setInternalCveInfos] = useState<CveInfo[] | undefined>();
  const [isSaving, setSaving] = useState(false);
  const [wasEdited, setWasEdited] = useState<boolean>(false);
  const history = useHistory();
  const isNonEditableOrClosed = useMemo(() => !isEditable || internalPoam?.state === PoamState.Closed, [isEditable, internalPoam?.state]);

  const fetchPoamDetails = async (): Promise<void> => {
    const client = new PoamGetOneClient(getConfig().apiBaseUri);
    try {
      const poamDetails: PoamGetOneResponse = await client.get(selectedPoamId ?? '', selectedPeriod);
      setInternalPoam(poamDetails.poam);
      setInternalAssets(poamDetails.assets ?? []);
      setInternalCveInfos(poamDetails.cveInfos ?? []);
    } catch (e) {
      showError(`There was an error fetching poam ${selectedPoamId}. Please refresh to try again.`);
      dismissPanel();
    }
  };

  useEffect(() => {
    if (selectedPoamId && selectedPeriod) {
      fetchPoamDetails();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoamId, selectedPeriod]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dismissPanel = () => {
    const { search } = history.location;
    const newPathname = `${continuousMonitoringRoute}/${conmonTabKeys.poam}`;
    const newUrl = `${newPathname}${search}`;
    history.replace(newUrl);
  };

  const onPoamChange = (comment: string) => {
    setWasEdited(true);
    setInternalPoam((prev) => {
      if (prev === undefined) {
        return undefined;
      }
      return { ...prev, internalComments: comment } as Poam;
    });
  };

  const onSave = useCallback(async () => {
    setSaving(true);
    const client = new PoamPutClient(getConfig().apiBaseUri);
    try {
      const putCommand = {
        period: internalPoam?.period ?? '',
        poams: [internalPoam ?? new Poam()],
        isBulkEdit: false,
      };
      const response = await client.put(putCommand as PoamPutCommand);
      onPoamSaved();
      showSuccess(`POAM ${response[0].poamId} successfully saved`, 10);
    } catch (e) {
      showError('There was an error saving POAM. Please refresh to try again.');
    } finally {
      setSaving(false);
      dismissPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismissPanel, internalPoam, onPoamSaved, wasEdited]);

  const onDeleteDeviation = async (poam: Poam, deviationId: string): Promise<void> => {
    setInternalPoam(undefined);
    const client = new PoamDeviationDeleteClient(getConfig().apiBaseUri);
    try {
      const poamDetails: Poam = await client.delete(
        new PoamDeviationDeleteCommand({
          deviationId,
          poamId: poam.poamId,
          period: poam.period ?? '',
        }),
      );
      setInternalPoam(poamDetails);
      onPoamSaved();
    } catch (e) {
      showError('There was an error deleting that deviation. Please refresh to try again.');
      dismissPanel();
    }
  };

  const onRenderFooterContent = useCallback(
    () => (
      <div
        style={{
          background: '#F5F5F5',
          position: 'relative',
          padding: '1rem',
        }}
      >
        <h3 style={{ fontWeight: 600 }}>Internal comments</h3>
        <div style={{ marginBottom: '2rem' }}>
          <TextField
            readOnly={isNonEditableOrClosed}
            placeholder="Enter internal comments if any"
            multiline
            value={internalPoam?.internalComments ?? ''}
            onChange={(ev: any, newValue: any) => onPoamChange(newValue ?? '')}
          />
        </div>
        <div style={{ position: 'absolute', bottom: '1rem' }}>
          <SaveButton defaultText="Save" saveText="Saving..." onSave={onSave} isSaving={isSaving} disabled={isNonEditableOrClosed || !wasEdited}>
            Save
          </SaveButton>
          <DefaultButton onClick={dismissPanel} styles={{ root: { marginLeft: 8 } }}>
            Cancel
          </DefaultButton>
        </div>
      </div>
    ),
    [dismissPanel, internalPoam, isSaving, onSave, isNonEditableOrClosed, wasEdited],
  );

  const updatePoam = (newPoam: Poam): void => {
    setWasEdited(true);
    setInternalPoam(newPoam);
  };

  if (!!selectedPoamId && (internalPoam === undefined || internalAssets === undefined)) {
    return (
      <Panel
        isOpen
        onDismiss={dismissPanel}
        type={PanelType.largeFixed}
        closeButtonAriaLabel="Close"
        headerText={selectedPoamId}
        className={sideModalPoamStyles}
      >
        <div style={{ marginTop: '25%', marginLeft: '50%' }}>
          <ProgressDots />
        </div>
      </Panel>
    );
  }

  if (internalPoam === undefined) {
    return <></>;
  }

  return (
    <Panel
      isOpen={!!selectedPoamId}
      onDismiss={dismissPanel}
      type={PanelType.largeFixed}
      closeButtonAriaLabel="Close"
      headerText={internalPoam.poamId}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalPoamStyles}
    >
      <PanelDetailsOverview internalPoam={internalPoam} />
      <Separator styles={separatorStyles} />
      <PanelDetailsTabs
        internalPoam={internalPoam}
        internalAssets={internalAssets}
        internalCveInfos={internalCveInfos}
        updatePoam={updatePoam}
        onDeleteDeviation={onDeleteDeviation}
      />
    </Panel>
  );
};
