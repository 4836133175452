import _ from 'lodash';
import { ControlMetadataListResponse, ControlsListResponse } from '../../generated/clientApi';
import { allControls } from '../../modules/controlCatalogs/controlCatalogs';
import { Control } from '../../modules/controlMetadata/controlMetadata';
import { ControlMetadataFilter } from './controlMetadataFilters';

export const filterControls = (
  control: Control,
  allControlsMetadata: ControlMetadataListResponse[],
  selectedControlGroup: ControlMetadataFilter,
  selectedInternalServiceTeams: ControlMetadataFilter[],
  selectedComplianceSelfTestNames: string[],
  filterByHasMetadata: boolean,
): boolean => {
  // control catalog filters in getControlCatalogControls
  if (selectedControlGroup.value !== allControls && selectedControlGroup.value !== control.group) {
    return false;
  }

  const metadataForControl = allControlsMetadata.find((metadata) => metadata.controlId === control.controlId);

  // If the toggle is on to only show controls with metadata, return false when the control has no metadata
  if (filterByHasMetadata && metadataForControl === undefined) {
    return false;
  }

  if (selectedInternalServiceTeams.length) {
    const selectedInternalServiceTeamNames = selectedInternalServiceTeams?.map((selectedInternalServiceTeam) => selectedInternalServiceTeam.name);
    if (
      !metadataForControl?.internalServiceTeamNames ||
      !_.intersection(selectedInternalServiceTeamNames, metadataForControl.internalServiceTeamNames).length
    ) {
      return false;
    }
  }

  if (selectedComplianceSelfTestNames.length) {
    if (
      !metadataForControl?.complianceSelfTestDocuments ||
      !_.intersection(
        selectedComplianceSelfTestNames,
        metadataForControl.complianceSelfTestDocuments.map((cst) => cst.title),
      ).length
    ) {
      return false;
    }
  }

  return true;
};

export const getControlGroupIdByTitle = (allControlGroups: ControlsListResponse[], title: string): string => {
  if (title === allControls) {
    return allControls;
  }
  const controlGroup = allControlGroups.find((group) => group.title === title);
  if (!controlGroup || !controlGroup?.id) {
    return '';
  }
  return controlGroup.id;
};

export const getAzureServiceTeamFilters = (internalServiceTeams: string[], selectedInternalServiceTeamNames: string[]): ControlMetadataFilter[] => {
  if (!selectedInternalServiceTeamNames) {
    return [];
  }
  const azureServiceTeams = internalServiceTeams
    .filter((team) => team && selectedInternalServiceTeamNames.includes(team))
    .map((team: string) => ({ value: team || '', name: team || '' }));
  return azureServiceTeams ?? [];
};
