/* DEVELOPER NOTE: These changes are server side rendered and following files SHOULD maintain parity.
 * Changes are copied from the API project to the UX project on build.
 *
 * API project : src\Microsoft.Mtac.Portal.Api\Content\Components\domainEvidenceData.functions.ts
 * UX project : src\Microsoft.Mtac.Portal.Ux\ClientApp\src\components\domainEvidenceData\domainEvidenceData.functions.ts
 *
 * On change that would affect the pdf download, be sure to run "npm run build" in the API folder.
 * Otherwise, the changes will not reflect on download.
 * I also had to turn the API's "strict" mode to false in the tsconfig.json to get this to work
 */

export const sectionNames = {
  S360Eta: 's360Eta',
} as const;

export const Domains = {
  SERVICE_TREE_PROPERTIES: 'ServiceTreeProperties',
  INCIDENT_MANAGEMENT: 'IncidentManagement',
  BCDR: 'BCDR',
  RELEASE_MANAGEMENT: 'ReleaseManagement',
  PRIVACY: 'Privacy',
  DATA_FLOW_DIAGRAM: 'DataFlowDiagram',
  THREAT_MODEL: 'ThreatModel',
  SDL: 'SDL',
  TRAINING: 'Training',
  KEY_VAULT_AND_JIT_ACCESS: 'KeyVaultAndJitAccess',
  INVENTORY_AND_PAVC: 'InventoryAndPAVC',
  MDS_LOGS: 'MDSLogs',
  GENEVA_ASM: 'GenevaASM',
  PACKAGE_NOTES: 'PackageNotes',
};

const TrainingSectionKeys = {
  Percentage: 'percentage',
  PmPercentage: 'pmPercentage',
  DevPercentage: 'devPercentage',
};

const specialCaseLabelMap: Map<string, string> = new Map([
  ['BCPSTRATEGY', 'BCP Strategy'],
  ['DESCRPTION', 'Description'], // fixing typo
  ['ENABLEJIT', 'Enable JIT'],
  ['EVENTID', 'Event Id'],
  ['GADATE', 'GA Date'],
  ['HOSTENV', 'Host Environment'],
  ['LASTBCDREVIEWDATE', 'Last BCDR Review Date'],
  ['LASTSDLREVIEW', 'Last SDL Review'],
  ['LIFECYCLE', 'Life Cycle'],
  ['LIFECYCLES', 'Life Cycles'],
  ['MDSACCOUNT', 'MDS Account'],
  ['ONCALLCONTACTS', 'On Call Contacts'],
  ['ONCALLLIST', 'On Call List'],
  ['ONCALLSTATUS', 'On Call Status'],
  ['ORG', 'Organization'],
  ['PMOWNERS', 'PM Owners'],
  ['PMPERCENTAGE', 'PM Percentage'],
  ['RTORPO', 'RTO, RPO'],
  ['S360ETA', 'S360 ETA'],
  ['SDLSTATUS', 'SDL Status'],
  ['SDLTASKS', 'SDL Tasks'],
]);

export const isServiceTreeDomain = (domain: string): boolean => domain === Domains.SERVICE_TREE_PROPERTIES;

export const isDataFlowDomain = (domain: string): boolean => domain === Domains.DATA_FLOW_DIAGRAM;

export const getDescription = (domain: string, section: string): string | undefined => {
  if (domain === Domains.TRAINING) {
    switch (section) {
      case TrainingSectionKeys.Percentage:
        return 'Represents the overall percentage of the service’s PM and Dev Owner(s) and all the users that report to them';
      case TrainingSectionKeys.PmPercentage:
        return 'Percentages next to each owner represent the completion percentage of the owner and the employees that report to him/her. For a complete list of the users that report to the PM owners along with their completion status please open the Training\\TrainingStatus_PM attachment';
      case TrainingSectionKeys.DevPercentage:
        return 'Percentages next to each owner represent the completion percentage of the owner and the employees that report to him/her. For a complete list of the users that report to the Dev owners along with their completion status please open the Training\\TrainingStatus_Dev attachment';
      default:
        return undefined;
    }
  }

  return undefined;
};

// Disable flag on the 'any' type for evidenceData since this is how it is represent in the API and could have a variety of fields.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const shouldRenderEvidenceSection = (domainEvidenceName: string, evidenceData: any, sectionName: string, excludedSections: string[] | undefined): boolean => {
  const s360EtaSectionName = sectionNames.S360Eta;
  const oneYearSlaMonths = 12;
  const halfYearSlaMonths = 6;

  if (!excludedSections) {
    return true;
  }

  if (excludedSections.includes(sectionName)) {
    return false;
  }

  if (sectionName === s360EtaSectionName) {
    switch (domainEvidenceName) {
      case Domains.BCDR:
        return sectionName !== s360EtaSectionName || (sectionName === s360EtaSectionName && !isDateWithinSlaRange(evidenceData.lastBCDReviewDate, oneYearSlaMonths));
      case Domains.PRIVACY:
        return sectionName !== s360EtaSectionName || (sectionName === s360EtaSectionName && !isDateWithinSlaRange(evidenceData.reviewHistory.lastPrivacyReviewDate, oneYearSlaMonths));
      case Domains.SDL:
        return sectionName !== s360EtaSectionName || (sectionName === s360EtaSectionName && !isDateWithinSlaRange(evidenceData.sdlStatus.signOffDate, halfYearSlaMonths));
      default:
      // do nothing
    }
  }

  return true;
};

const isDateWithinSlaRange = (dateString: string, slaRangeInMonths: number): boolean => {
  const today = new Date();
  const lastInRangeDate = new Date(today.getFullYear(), today.getMonth() - slaRangeInMonths, today.getDate(), 0);
  return new Date(Date.parse(dateString)) > lastInRangeDate;
};

export const capitalizeFirstLetter = (str: string | null): string | null => {
  if (str === null) {
    return null;
  }

  if (str.length === 0) {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const splitCamelCase = (camel: string | null): string | null => (camel ? camel.replace(/([a-z])([A-Z])/g, '$1 $2') : null);

export const transformLabels = (label: string | null): string | null => {
  if (label == null) { return null; }
  const mapped = specialCaseLabelMap.get(label.toUpperCase());
  return mapped || capitalizeFirstLetter(splitCamelCase(label));
};
