import { mergeStyles, Link, IDetailsColumnStyles, NeutralColors } from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getCloudCertification, getCombinedCloudCertifications } from '../../modules/environmentAuthorization/environmentAuthorization';
import { dodExclusiveCertifications, dodExclusivePackages } from '../../models/dodExclusivePackages';
import { ConfigContext } from '../../components/configProvider/configContext';
import { OfferingContext } from '../../components/offeringProvider/offeringContext';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { IOffering } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { OfferingListItem } from '../../components/offeringProvider/offeringListItem';
import { createHomeChromeConfig, updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { offeringDetailRoute } from '../../modules/routes/routes';
import { BasicList, ListColumn } from '../../components/basicList/basicList';
import { FilterBubble } from '../../components/filterBubble/filterBubbleComponent';
import { AuthContext } from '../../components/authProvider/authContext';
import { COMPLIANCE_TEAM_ACTIONS_READ, DOD_AUTHORIZATION_ACTIONS_WRITE, SITE_WIDE_SUBJECT } from '../../modules/constants';

const widgetStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '1em',
  margin: '0.5em',
  display: 'flex',
  flex: '1',
});

const sectionStyle = mergeStyles({
  backgroundColor: NeutralColors.white,
  padding: '1em',
  margin: '0.5em',
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
});

const pageStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

const linkStyle = {
  textDecoration: 'none',
};

const tableStyle = mergeStyles({
  display: 'block',
  width: '100%',
  height: '100%',
});

const columnCenteredStyle = mergeStyles({
  display: 'flex',
  width: 'fit-content',
  margin: 'auto',
});

const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
};

export const OfferingsPage: React.FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const offeringsContext = useContext(OfferingContext);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [offeringListFilteredItems, setOfferingListFilteredItems] = useState<OfferingListItem[] | undefined>(undefined);
  const [cloudCert, setCloudCert] = useState<string>();
  const [offeringOidFilter, setOfferingOidFilter] = useState<string>('');
  const [offeringFilter, setOfferingFilter] = useState<string>('');
  const noneDisplay = 'None';
  const [cloudCertifications, setCloudCertifications] = useState<string[]>([]);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isDodOfficial = authContext.isAuthorized([{ operation: DOD_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    offeringsContext.requestOfferings();
  }, [offeringsContext]);

  useEffect(() => {
    if (offeringsContext.offerings?.length) {
      setIsLoading(LoadingState.Loading);
      getMappedOfferings().then((offerings) => {
        setOfferingListFilteredItems(offerings);
        setIsLoading(LoadingState.Loaded);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringsContext.offerings]);

  useEffect(() => {
    setIsLoading(offeringsContext.offeringsLoadingState);
  }, [offeringsContext.offeringsLoadingState]);

  useEffect(() => {
    updatePageChromeConfig(createHomeChromeConfig('offerings', isComplianceTeam));
    return () => updatePageChromeConfig();
  }, [isComplianceTeam]);

  const onlyUnique = (value: any, index: any, self: any[]) => self.indexOf(value) === index;

  // This method needs some love. Not sure how this passed PR...
  const filterOfferings = (item: OfferingListItem) => {
    let shouldReturn = false;
    if (offeringOidFilter === '' || offeringOidFilter == null) {
      shouldReturn = true;
    } else {
      shouldReturn = item.offering.id === offeringOidFilter;
    }

    const { cloud, certification } = getCloudCertification(cloudCert);
    if ((cloud === '' || cloud == null) && shouldReturn) {
      shouldReturn = true;
    } else {
      shouldReturn = shouldReturn && item.cloud === cloud;
    }

    if ((certification === '' || certification == null) && shouldReturn) {
      shouldReturn = true;
    } else {
      shouldReturn = shouldReturn && item.certification === certification;
    }
    if (!isDodOfficial && (item.certification === dodExclusivePackages.IL4 || item.certification === dodExclusivePackages.IL5)) {
      shouldReturn = false;
    }
    return shouldReturn;
  };

  useEffect(() => {
    const certifications = getCombinedCloudCertifications(configContext.environmentAuthorizations);
    if (!isDodOfficial) {
      setCloudCertifications(
        certifications.filter((certification) => !Object.values(dodExclusiveCertifications).includes(certification as dodExclusiveCertifications)),
      );
      return;
    }
    setCloudCertifications(certifications);
  }, [configContext.environmentAuthorizations, isDodOfficial]);

  const filterFields = useMemo(
    () => [
      {
        key: 'cloudCert',
        name: 'Auth',
        fieldName: 'cloudCert',
        filterSelection: cloudCert,
        options: cloudCertifications,
      },
      {
        key: 'offering',
        name: 'Offering',
        fieldName: 'offering',
        includeNone: true,
        filterSelection: offeringFilter,
        options: offeringsContext.offerings ? offeringsContext.offerings.map((offering) => offering.name).flat() : [],
      },
    ],
    [cloudCert, cloudCertifications, offeringFilter, offeringsContext.offerings],
  );

  const offeringColumns: ListColumn[] = useMemo(
    () => [
      {
        key: 'offeringName',
        name: 'Offering Name',
        getFieldValue: (offeringListItem: OfferingListItem) => offeringListItem.offering.name,
        onRender: (offeringListItem: OfferingListItem) => (
          <Link
            as={RouterLink}
            to={offeringDetailRoute({
              offeringId: offeringListItem.offering.id,
              cloud: offeringListItem.cloud,
              certification: offeringListItem.certification,
            })}
            style={linkStyle}
          >
            {offeringListItem.offering.name}
          </Link>
        ),
        minWidth: 50,
        maxWidth: 500,
      },
      {
        key: 'cloud',
        name: 'Cloud',
        fieldName: 'cloud',
        styles: headerStyle,
        onRender: (offeringListItem: OfferingListItem) => <div className={columnCenteredStyle}>{offeringListItem.cloud}</div>,
        minWidth: 100,
      },
      {
        key: 'certification',
        name: 'Certification',
        fieldName: 'certification',
        styles: headerStyle,
        onRender: (offeringListItem: OfferingListItem) => <div className={columnCenteredStyle}>{offeringListItem.certification}</div>,
        minWidth: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offeringListFilteredItems],
  );

  const setFilterEntry = useCallback(
    (value: string[], columnName: string) => {
      switch (columnName) {
        case 'cloudCert':
          setCloudCert(value[0]);
          break;
        case 'offering': {
          const offeringName = value[0];
          setOfferingFilter(offeringName);
          // probably we should use oid across our app.
          const offering = offeringsContext.offerings?.find((offering) => offering.name === value[0]);
          if (offering && offering.id) {
            setOfferingOidFilter(offering.id);
          }
          if (offeringName === '' || offeringName == null) {
            setOfferingOidFilter('');
          }
          break;
        }
        default:
          break;
      }
    },
    [offeringsContext.offerings],
  );

  async function getMappedOfferings() {
    const offerings: OfferingListItem[] = [];
    offeringsContext.offerings?.forEach((offering: IOffering) => {
      configContext.environmentAuthorizations
        .flatMap((authorization) => authorization.cloud)
        .filter(onlyUnique)
        .forEach((cloud: string) => {
          configContext.environmentAuthorizations
            .flatMap((authorization) => authorization.certification)
            .filter(onlyUnique)
            .forEach((certification: string) => {
              offerings.push({
                cloud,
                certification,
                offering,
                id: offering.id.concat(cloud, certification),
              });
            });
        });
    });
    return offerings;
  }

  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <div className={pageStyle}>
          <div className={widgetStyle}>
            {filterFields.map((filter) => (
              <FilterBubble
                key={filter.fieldName}
                id={filter.fieldName}
                fieldName={filter.name}
                filter={filter.filterSelection ? [filter.filterSelection] : []}
                includeNone={filter.includeNone}
                noneDisplay={noneDisplay}
                valueOptions={filter.options}
                onChange={setFilterEntry}
              />
            ))}
          </div>
          <div className={sectionStyle}>
            <h3>Offering List</h3>
            <div className={tableStyle}>
              <BasicList items={offeringListFilteredItems?.filter(filterOfferings)} columns={offeringColumns} sortLoadingIndicator />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
