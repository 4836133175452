import * as React from 'react';
import { ErrorLoading } from '../../components/errorLoading/errorLoading';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { aggregateLoadingState, LoadingState } from '../../models/loadingState';

export const getFailedToLoadUi = (): React.ReactElement => <ErrorLoading />;

export const getLoadingUi = (): React.ReactElement => <CenteredProgressDots />;

export const getUiFromStates = (loadedUiFactory: () => React.ReactElement, loadingStates: LoadingState[]): React.ReactElement => {
  switch (aggregateLoadingState(loadingStates)) {
    case LoadingState.Loaded:
      return loadedUiFactory();
    case LoadingState.Error:
      return getFailedToLoadUi();
    case LoadingState.Loading:
    default:
      return <CenteredProgressDots />;
  }
};
