import { IStackTokens, Stack, TextField, mergeStyles } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { Accordion } from 'components/accordion/accordion';
import { HeroContentContext } from 'components/heroContentProvider/heroContentContext';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { SaveButton } from 'components/saving/saveButton';
import { UserContext } from 'components/userProvider/userContext';
import { OrganizationalFrontMatter } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { SspManagerNavLinks } from 'models/sspManagerNavLinks';
import { logError } from 'modules/logging/logging';
import { showError } from 'modules/messageBar/messageBar';
import { getOrganizationalFrontMatter, postOrganizationalFrontMatter, putOrganizationalFrontMatter } from 'modules/organization/organization';
import { updatePageChromeConfig } from 'modules/pageChrome/pageChrome';
import { createSspManagerChromeConfig } from 'modules/pageChrome/sspManagerPageChrome';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';
import { textFieldStyles } from 'styles/textFieldStyles';

const headerStyle = mergeStyles({
  fontSize: '20px',
});

const pageStyles = mergeStyles({
  paddingLeft: '0.75em',
});

const saveButtonStyles = mergeStyles(primaryButtonStyles, {
  margin: '1em 0',
});

const subTitleStyle = mergeStyles({
  fontWeight: 'bold',
  fontSize: '14px',
});

const descriptionTextStyle = mergeStyles({
  fontSize: '14px',
});

const accordionStyles = {
  root: {
    padding: '0.75em',
    display: 'flex',
    borderTop: '0.5px solid #eee',
  },
  headerDiv: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
  } as const,
  icon: {
    margin: '5px 10px 10px 0',
  },
};

const fieldStackTokens: IStackTokens = {
  childrenGap: '2.5em',
};

export enum FrontMatterFieldLabels {
  CSPName = 'CSP Name',
  CSOName = 'CSO Name',
  CSOUserGuideURL = 'CSO User Guide URL',
  OrganizationName = 'Organization Name',
  StreetAddress = 'Street Address',
  SuiteRoomBuilding = 'Suite/Room/Building',
  CityStateZip = 'City, State, Zip',
  Name = 'Name',
  Title = 'Title',
  CompanyOrganization = 'Company/Organization',
  Address = 'Address',
  PhoneNumber = 'Phone Number',
  EmailAddress = 'Email Address',
  AuthorizationBoundaryDiagram = 'Authorization boundary diagram',
  DataFlowURL = 'Data flow URL',
  NetworkDiagramURL = 'Network diagram URL',
  AuthorizationBoundaryNarrative = 'Authorization boundary narrative',
  DataFlowNarrative = 'Data flow narrative',
  NetworkNarrative = 'Network narrative',
  ServicePortsProtocols = 'Service, Ports, and Protocols',
  SeparationOfDuties = 'Separation of Duties',
}

export const OrganizationalFrontMatterDetail: FunctionComponent = () => {
  const heroContentContext = useContext(HeroContentContext);
  const userContext = useContext(UserContext);
  const [isSaving, { setTrue: setSaving, setFalse: setNotSaving }] = useBoolean(false);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [frontMatter, setFrontMatter] = useState<OrganizationalFrontMatter>(new OrganizationalFrontMatter());
  const urlHelpText = 'Read only url to md file';

  useEffect(() => {
    if (userContext.organizations.length > 0) {
      heroContentContext.setShowHero(false);
      heroContentContext.setShowOrganizationDropdown(true);
    }
  }, [heroContentContext, userContext.organizations.length]);

  useEffect(() => {
    updatePageChromeConfig(createSspManagerChromeConfig(SspManagerNavLinks.OrganizationFrontMatter));
    return () => updatePageChromeConfig();
  }, []);

  useEffect(() => {
    const loadOrganizationalFrontMatter = async () => {
      try {
        if (!userContext.selectedOrganization?.id) {
          return;
        }
        const currentOrganizationalFrontMatter = await getOrganizationalFrontMatter(userContext.selectedOrganization.id);
        if (currentOrganizationalFrontMatter) {
          setFrontMatter(currentOrganizationalFrontMatter);
        } else {
          // reset state when org is changed
          setFrontMatter(new OrganizationalFrontMatter());
        }

        setIsLoading(LoadingState.Loaded);
      } catch (error) {
        setIsLoading(LoadingState.Error);
        logError('There was an issue getting the organizational front matter', error);
      }
    };

    loadOrganizationalFrontMatter();
  }, [userContext.selectedOrganization?.id]);

  const onSaveClick = async () => {
    try {
      if (!userContext.selectedOrganization?.organizationId) {
        return;
      }
      setSaving();

      let updatedFrontMatter;
      if (frontMatter.organizationId) {
        // update flow since org id already present
        updatedFrontMatter = await putOrganizationalFrontMatter(userContext.selectedOrganization?.organizationId, frontMatter);
      } else {
        frontMatter.organizationId = userContext.selectedOrganization?.organizationId;
        updatedFrontMatter = await postOrganizationalFrontMatter(userContext.selectedOrganization?.organizationId, frontMatter);
      }
      setFrontMatter(updatedFrontMatter);
    } catch (error) {
      showError(`There was an issue saving the front matter. Please refresh and try again: ${error}`);
    } finally {
      setNotSaving();
    }
  };

  return (
    <div className={pageStyles}>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          <h2>Organizational Front Matter</h2>
          <SaveButton defaultText="Save changes" isSaving={isSaving} onSave={onSaveClick} disabled={isSaving} className={saveButtonStyles} />
          <Accordion styles={accordionStyles} headerElement={<div className={headerStyle}>Organization</div>} displayCheckbox={false}>
            <div className={descriptionTextStyle}>
              If the ‘Prepared by’ and ‘Prepared for’ fields are left blank, MTAC will use the provided CSP Name to populate them.
            </div>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.CSPName}
                value={frontMatter?.cspName}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.cspName = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
            <div className={subTitleStyle}>Prepared by</div>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.OrganizationName}
                value={frontMatter?.preparedByOrganizationName}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedByOrganizationName = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.StreetAddress}
                value={frontMatter?.preparedByStreetAddress}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedByStreetAddress = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.SuiteRoomBuilding}
                value={frontMatter?.preparedBySuiteRoomBuilding}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedBySuiteRoomBuilding = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.CityStateZip}
                value={frontMatter?.preparedByCityStateZip}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedByCityStateZip = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>

            <div className={subTitleStyle}>Prepared for</div>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.OrganizationName}
                value={frontMatter?.preparedForOrganizationName}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedForOrganizationName = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.StreetAddress}
                value={frontMatter?.preparedForStreetAddress}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedForStreetAddress = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.SuiteRoomBuilding}
                value={frontMatter?.preparedForSuiteRoomBuilding}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedForSuiteRoomBuilding = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.CityStateZip}
                value={frontMatter?.preparedForCityStateZip}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.preparedForCityStateZip = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
          </Accordion>

          <Accordion styles={accordionStyles} headerElement={<div className={headerStyle}>System Owners & ISSO</div>} displayCheckbox={false}>
            <div className={subTitleStyle}>System Owner</div>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.Name}
                value={frontMatter?.systemOwnerName}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.systemOwnerName = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.Title}
                value={frontMatter?.systemOwnerTitle}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.systemOwnerTitle = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.CompanyOrganization}
                value={frontMatter?.systemOwnerCompanyOrganization}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.systemOwnerCompanyOrganization = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.Address}
                value={frontMatter?.systemOwnerAddress}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.systemOwnerAddress = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.PhoneNumber}
                value={frontMatter?.systemOwnerPhoneNumber}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.systemOwnerPhoneNumber = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.EmailAddress}
                value={frontMatter?.systemOwnerEmailAddress}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.systemOwnerEmailAddress = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>

            <div className={subTitleStyle}>ISSO</div>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.Name}
                value={frontMatter?.issoOwnerName}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.issoOwnerName = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.Title}
                value={frontMatter?.issoOwnerTitle}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.issoOwnerTitle = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.CompanyOrganization}
                value={frontMatter?.issoOwnerCompanyOrganization}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.issoOwnerCompanyOrganization = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.Address}
                value={frontMatter?.issoOwnerAddress}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.issoOwnerAddress = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.PhoneNumber}
                value={frontMatter?.issoOwnerPhoneNumber}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.issoOwnerPhoneNumber = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.EmailAddress}
                value={frontMatter?.issoOwnerEmailAddress}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.issoOwnerEmailAddress = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
          </Accordion>

          <Accordion
            styles={accordionStyles}
            headerElement={<div className={headerStyle}>Illustrated Architecture and Narratives</div>}
            displayCheckbox={false}
          >
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.AuthorizationBoundaryDiagram}
                placeholder={urlHelpText}
                value={frontMatter?.authorizationBoundaryURL}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.authorizationBoundaryURL = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.DataFlowURL}
                placeholder={urlHelpText}
                value={frontMatter?.dataFlowURL}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.dataFlowURL = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.NetworkDiagramURL}
                placeholder={urlHelpText}
                value={frontMatter?.networkDiagramURL}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.networkDiagramURL = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
            <Stack horizontal tokens={fieldStackTokens}>
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.AuthorizationBoundaryNarrative}
                placeholder={urlHelpText}
                value={frontMatter?.authorizationBoundaryNarrativeURL}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.authorizationBoundaryNarrativeURL = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.DataFlowNarrative}
                placeholder={urlHelpText}
                value={frontMatter?.dataFlowNarrativeURL}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.dataFlowNarrativeURL = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
              <TextField
                styles={textFieldStyles}
                label={FrontMatterFieldLabels.NetworkNarrative}
                placeholder={urlHelpText}
                value={frontMatter?.networkNarrativeURL}
                onChange={(_event, input) => {
                  const updatedFrontMatter = structuredClone(frontMatter);
                  updatedFrontMatter.networkNarrativeURL = input;
                  setFrontMatter(updatedFrontMatter);
                }}
              />
            </Stack>
          </Accordion>

          <Accordion
            styles={accordionStyles}
            headerElement={<div className={headerStyle}>Services, Ports, and Protocols</div>}
            displayCheckbox={false}
          >
            <TextField
              styles={textFieldStyles}
              label={FrontMatterFieldLabels.ServicePortsProtocols}
              placeholder={urlHelpText}
              value={frontMatter?.servicePortsProtocolsURL}
              onChange={(_event, input) => {
                const updatedFrontMatter = structuredClone(frontMatter);
                updatedFrontMatter.servicePortsProtocolsURL = input;
                setFrontMatter(updatedFrontMatter);
              }}
            />
          </Accordion>

          <Accordion styles={accordionStyles} headerElement={<div className={headerStyle}>Separation of Duties</div>} displayCheckbox={false}>
            <TextField
              styles={textFieldStyles}
              label={FrontMatterFieldLabels.SeparationOfDuties}
              placeholder={urlHelpText}
              value={frontMatter?.separationOfDutiesURL}
              onChange={(_event, input) => {
                const updatedFrontMatter = structuredClone(frontMatter);
                updatedFrontMatter.separationOfDutiesURL = input;
                setFrontMatter(updatedFrontMatter);
              }}
            />
          </Accordion>
        </>
      )}
    </div>
  );
};
