import { CheckboxVisibility, DetailsList, IColumn, IDetailsGroupDividerProps, IGroup } from '@fluentui/react';
import React, { FunctionComponent, useContext } from 'react';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { CommentData } from '../../pages/evidenceDetail/menuItems/evidenceDetailReview.functions';
import { getCommentsAccordianData, getGroups } from './commentSummary.function';
import { CommentSummaryGroupHeader } from './commentSummaryGroupHeader';

export interface CommentSummaryProps {
  commentData: CommentData[];
  reviewType: string;
}

export interface IExtendedGroup extends IGroup {
  scoreText?: string;
}

const columns: IColumn[] = [
  {
    key: 'authorizingOfficialOrganization',
    name: 'Domain',
    fieldName: 'authorizingOfficialOrganization',
    minWidth: 200,
    maxWidth: 200,
    isResizable: true,
  }, // Technically this is the authorizingOfficialOrganization column, but display 'Domain'
  { key: 'comments', name: 'Comments', fieldName: 'comments', minWidth: 100, isMultiline: true, isResizable: true },
  { key: 'score', name: 'Score', fieldName: 'score', minWidth: 100, maxWidth: 100, isResizable: true },
];

export const CommentSummary: FunctionComponent<CommentSummaryProps> = (props) => {
  const { commentData, reviewType } = props;
  const evidenceContext = useContext(EvidenceContext);
  const commentsAccordianData = getCommentsAccordianData(commentData);
  const groups = getGroups(commentData, evidenceContext.evidencePackage, reviewType);

  return (
    <DetailsList
      items={commentsAccordianData}
      columns={columns}
      groups={groups}
      checkboxVisibility={CheckboxVisibility.hidden}
      groupProps={{
        showEmptyGroups: true,
        onRenderHeader: (props: IDetailsGroupDividerProps | undefined) => (props ? <CommentSummaryGroupHeader {...props} /> : null),
      }}
    />
  );
};
