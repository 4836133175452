import { mergeStyles, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useState, Fragment } from 'react';
import { ImageTypes, ImageViewer } from 'components/content-viewers/imageViewer';
import { Accordion } from '../accordion/accordion';
import { IDomainEvidenceAttachmentInfo } from '../../generated/clientApi';
import { PdfViewer } from '../content-viewers/pdfViewer';
import { TextViewer } from '../content-viewers/txtViewer';
import { ExcelViewer } from '../content-viewers/excelViewer';
import { downloadAttachment, getAttachmentContents } from '../../modules/fileAttachment/fileAttachment';

interface EvidenceAttachmentsProps {
  domainEvidenceAttachmentInfos: IDomainEvidenceAttachmentInfo[];
  serviceOid: string;
}

interface IAttachmentInfo {
  attachmentId: string;
  attachmentName: string;
  attachmentExtension: string;
  ccmFileSource: boolean;
  content?: any;
  isExpanded: boolean;
  isDownloading: boolean;
  isLoadingViewer: boolean;
}

const accordionStyles = {
  root: {
    display: 'flex',
    borderTop: '0.5px solid #eee',
    paddingTop: '10px',
  },
  headerDiv: {
    overflow: 'auto',
  } as const,
  icon: {
    margin: '5px 10px 10px 0',
  },
};

const headerStyle = mergeStyles({
  fontSize: '14px',
});

const downloadButtonStyle = mergeStyles({
  marginTop: '1em',
});

const spinnerStyle = mergeStyles({
  marginBottom: '1em',
});

export const EvidenceAttachments: FunctionComponent<EvidenceAttachmentsProps> = (props) => {
  const [attachmentInfoMap, setAttachmentInfoMap] = useState<IAttachmentInfo[]>([]);
  const { domainEvidenceAttachmentInfos, serviceOid } = props;

  useEffect(() => {
    if (!domainEvidenceAttachmentInfos) {
      return;
    }

    const attachmentInfoMap = domainEvidenceAttachmentInfos.map(
      (attachment) =>
        ({
          attachmentId: attachment.attachmentId,
          attachmentName: attachment.attachmentName,
          attachmentExtension: getExtension(attachment.attachmentName),
          ccmFileSource: attachment.ccmFileSource,
          isExpanded: false,
          isDownloading: false,
          isLoadingViewer: false,
        }) as IAttachmentInfo,
    );
    setAttachmentInfoMap(attachmentInfoMap);
  }, [domainEvidenceAttachmentInfos]);

  const setIsAttachmentDownloading = useCallback((attachmentId: string, isDownloading: boolean) => {
    setAttachmentInfoMap((currentAttachmentInfo) =>
      currentAttachmentInfo.map((item) => (item.attachmentId !== attachmentId ? item : { ...item, isDownloading })),
    );
  }, []);

  const onFileDownload = useCallback(
    async (attachmentInfo: IAttachmentInfo) => {
      setIsAttachmentDownloading(attachmentInfo.attachmentId, true);
      await downloadAttachment(attachmentInfo.attachmentId, attachmentInfo.attachmentName, attachmentInfo.ccmFileSource, serviceOid);
      setIsAttachmentDownloading(attachmentInfo.attachmentId, false);
    },
    [serviceOid, setIsAttachmentDownloading],
  );

  const getAttachmentHeader = (fileName: string | undefined) => <span className={headerStyle}>{fileName}</span>;

  const setIsLoadingViewer = useCallback((attachmentId: string, isLoadingViewer: boolean) => {
    setAttachmentInfoMap((currentAttachmentInfo) =>
      currentAttachmentInfo.map((item) => (item.attachmentId !== attachmentId ? item : { ...item, isLoadingViewer })),
    );
  }, []);

  const onRenderAttachmentViewer = useCallback(
    async (attachmentId: string, attachmentExtension: string) => {
      setIsLoadingViewer(attachmentId, true);
      const copiedAttachmentInfos = [...attachmentInfoMap];
      const attachmentInfo = getAttachmentInfo(copiedAttachmentInfos, attachmentId);
      if (attachmentInfo) {
        if (!attachmentInfo?.content) {
          const attachmentContents = await getAttachmentContents(attachmentId, attachmentInfo.ccmFileSource, serviceOid);
          attachmentInfo.content = await getContent(attachmentContents, attachmentExtension);
        }
        attachmentInfo.isExpanded = !attachmentInfo.isExpanded;
        setAttachmentInfoMap(copiedAttachmentInfos);
      }
      setIsLoadingViewer(attachmentId, false);
    },
    [attachmentInfoMap, serviceOid, setIsLoadingViewer],
  );

  const getContent = async (blob: Blob, attachmentExtension: string) => {
    if (attachmentExtension === 'txt') {
      return blob.text();
    }
    return blob.arrayBuffer();
  };

  const getAttachmentInfo = (copiedAttachmentInfo: IAttachmentInfo[], attachmentId: string) =>
    copiedAttachmentInfo.find((attachmentInfo) => attachmentInfo.attachmentId === attachmentId);

  const getContentViewer = (attachmentExtension: string, content: any) => {
    if (attachmentExtension === 'pdf') {
      return <PdfViewer content={content} />;
    }
    if (attachmentExtension === 'txt') {
      return <TextViewer content={content} />;
    }
    if (attachmentExtension === 'xlsx') {
      return <ExcelViewer content={content} />;
    }
    if (attachmentExtension === 'png') {
      return <ImageViewer imageType={ImageTypes.PNG} content={content} altText="Evidence Attachment" />;
    }

    return <div>Content viewer not supported. Coming soon!</div>;
  };

  const getExtension = (attachmentName: string) => {
    const attachmentSplit = attachmentName.split('.');
    return attachmentSplit[attachmentSplit.length - 1];
  };

  const Attachments = useCallback(() => {
    if (attachmentInfoMap && attachmentInfoMap.length > 0) {
      return (
        <>
          {attachmentInfoMap.map((attachmentInfo) => (
            <Fragment key={attachmentInfo.attachmentId}>
              <Accordion
                key={attachmentInfo.attachmentId}
                headerElement={getAttachmentHeader(attachmentInfo.attachmentName)}
                styles={accordionStyles}
                onExpanded={() => onRenderAttachmentViewer(attachmentInfo.attachmentId, attachmentInfo.attachmentExtension)}
                isExpanded={attachmentInfo.isExpanded}
                displayCheckbox={false}
              >
                {getContentViewer(attachmentInfo.attachmentExtension, attachmentInfo.content)}
                {attachmentInfo.isDownloading ? (
                  <Spinner size={SpinnerSize.medium} />
                ) : (
                  <PrimaryButton className={downloadButtonStyle} type="button" onClick={() => onFileDownload(attachmentInfo)}>
                    Download
                  </PrimaryButton>
                )}
              </Accordion>
              {attachmentInfo.isLoadingViewer && <Spinner className={spinnerStyle} size={SpinnerSize.medium} />}
            </Fragment>
          ))}
        </>
      );
    }

    return <div>No attachments were found for this snapshot.</div>;
  }, [attachmentInfoMap, onFileDownload, onRenderAttachmentViewer]);

  return <Attachments />;
};
