import { DirectionalHint, FontIcon, HoverCard, HoverCardType, IPlainCardProps, mergeStyles } from '@fluentui/react';
import React from 'react';
import { nonSelectedBorder, selectedBorder, systemSelectedBgColor, userSelectedBgColor } from './dayCoverageCell';
import { belowCoverageBgColor } from './coverageTable';

const iconClass = mergeStyles({ marginTop: '1px', color: '#038387' });

const legendItemContainerStyle = mergeStyles({
  display: 'flex',
  gap: '.5rem',
  alignItems: 'end',
  marginTop: '.5rem',
});

const legendItemLabelStyle = mergeStyles({
  textAlign: 'end',
  flexBasis: '60%',
});

const defaultSelectedStyle = mergeStyles({
  backgroundColor: systemSelectedBgColor,
  border: nonSelectedBorder,
  borderRadius: '4px',
});

const userSelectedStyle = mergeStyles({
  backgroundColor: userSelectedBgColor,
  border: nonSelectedBorder,
  borderRadius: '4px',
});

const currentSelectedStyle = mergeStyles({
  border: selectedBorder,
  borderRadius: '4px',
});

const belowCoverageStyle = mergeStyles({
  backgroundColor: belowCoverageBgColor,
  border: nonSelectedBorder,
  borderRadius: '4px',
});

const unselectedCoverageStyle = mergeStyles({
  border: nonSelectedBorder,
  borderRadius: '4px',
});

const legendButtonStyle = {
  background: 'none',
  border: 'none',
  padding: 2,
  cursor: 'pointer',
};

export const Legend: React.FunctionComponent = () => {
  const LegendItem = ({ text, boxStyle }: { text: string; boxStyle: string }) => (
    <div className={legendItemContainerStyle}>
      <span className={legendItemLabelStyle}>{text}</span>
      <div style={{ width: '15px', height: '15px' }} className={boxStyle} />
    </div>
  );
  const onRenderPlainCard = (): JSX.Element => (
    <div style={{ width: '246px', padding: '28px 1rem 1rem 1rem' }}>
      <LegendItem text="Default selected" boxStyle={defaultSelectedStyle} />
      <LegendItem text="User selected" boxStyle={userSelectedStyle} />
      <LegendItem text="Current selected" boxStyle={currentSelectedStyle} />
      <LegendItem text="Unselected" boxStyle={unselectedCoverageStyle} />
      <LegendItem text="Less than 90% coverage" boxStyle={belowCoverageStyle} />
      <div style={{ margin: '1rem auto 0 auto', width: 'fit-content' }}>
        <span>Assets scanned / Total assets</span>
        <div>
          <FontIcon aria-label="Unauthenticated" iconName="Unlock" />
          <span>Unauthenticated</span>
        </div>
      </div>
    </div>
  );
  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard,
    directionalHint: DirectionalHint.topCenter,
    renderData: (
      <button style={legendButtonStyle} type="button">
        <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
          <FontIcon aria-label="Unauthenticated" iconName="UnknownSolid" className={iconClass} />
          <span>View legend</span>
        </div>
      </button>
    ),
  };

  return (
    <div style={{ marginLeft: 'auto' }}>
      <HoverCard
        style={{
          border: 'none',
          borderRadius: '4px',
          background: '#FFF',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
        }}
        plainCardProps={plainCardProps}
        instantOpenOnClick
        type={HoverCardType.plain}
      >
        <button style={legendButtonStyle} type="button">
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <FontIcon aria-label="Unauthenticated" iconName="UnknownSolid" className={iconClass} />
            <span>View legend</span>
          </div>
        </button>
      </HoverCard>
    </div>
  );
};
