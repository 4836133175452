import { IDialogContentProps, Modal, PrimaryButton, SharedColors, mergeStyles } from '@fluentui/react';
import React from 'react';
import { showDialogModal } from 'modules/dialog/dialog';
import { ProgressDots } from 'components/progressDots/progressDots';
import { buttonStyles } from '../styles/buttonStyles';

const modalBodyStyle = mergeStyles({
  marginBottom: '20px',
  padding: '.5rem',
});

type LockButtonProps = {
  onLock: () => void;
  isLocking: boolean;
  isDisabled: boolean;
};

export const LockButton: React.FunctionComponent<LockButtonProps> = (props) => {
  const { onLock, isLocking, isDisabled } = props;
  const styles = { ...buttonStyles, margin: '.5rem 0' };

  const onShowModal = () => {
    const dialogContents: IDialogContentProps = {
      title: 'Lock Coverage',
    };

    const modalBody = (
      <div className={modalBodyStyle}>
        <p>Are you sure you want to lock coverage and POA&Ms for this month?</p>
        <b style={{ color: SharedColors.red10, marginBottom: '2rem', display: 'block' }}>This action cannot be undone.</b>
      </div>
    );

    showDialogModal(dialogContents, onLock, modalBody, undefined, 'Lock coverage', '600px');
  };
  return (
    <>
      <Modal isOpen={isLocking} isBlocking>
        <div style={{ padding: '1rem', width: '600px', height: '250px' }}>
          <h2>Locking coverage</h2>
          <p>Your coverage and POA&M selections for the month are being locked.</p>
          <p>This may take a while</p>
          <div style={{ marginLeft: '40%' }}>
            <ProgressDots />
          </div>
        </div>
      </Modal>
      <PrimaryButton style={styles} onClick={onShowModal} iconProps={{ iconName: 'unlock' }} disabled={isDisabled}>
        Review & lock
      </PrimaryButton>
    </>
  );
};
