import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

export interface TextViewerProps {
  content?: string;
}

const contentViewerStyle = mergeStyles({
  backgroundColor: '#f2f2f2',
});

export const TextViewer: FunctionComponent<TextViewerProps> = (props: TextViewerProps) => {
  const { content } = props;
  return <pre className={contentViewerStyle}>{content}</pre>;
};
