import { NotificationId } from 'generated/clientApi';

export const getNotificationHeader = (notificationId: NotificationId): string => {
  switch (notificationId) {
    // Immediate - package submissions
    case NotificationId.PackageSubmitAuditor:
    case NotificationId.PackageSubmitAO:
      return 'Package submitted';
    // Monthly summary email
    case NotificationId.MonthSummaryAuditor:
    case NotificationId.MonthSummaryAO:
      return 'Monthly summary';
    // Weekly summary email
    case NotificationId.WeekSummaryAuditor:
    case NotificationId.WeekSummaryAO:
      return 'Weekly summary';
    // AO Approval
    case NotificationId.PackageApproveCPM:
      return 'Package approved';
    // Auditor/AO Return
    case NotificationId.PackageRejectCPM:
      return 'Package returned';
    default:
      return 'Unexpected rule.';
  }
};

export const getNotificationDescription = (notificationId: NotificationId): string => {
  switch (notificationId) {
    // Immediate - package submissions
    case NotificationId.PackageSubmitAuditor:
    case NotificationId.PackageSubmitAO:
      return 'Receive an email each time a new evidence package is sent to your action center';
    // Monthly summary email
    case NotificationId.MonthSummaryAuditor:
    case NotificationId.MonthSummaryAO:
      return 'Monthly summary of all packages approved, returned, and remaining in your queue';
    // Weekly summary email
    case NotificationId.WeekSummaryAuditor:
    case NotificationId.WeekSummaryAO:
      return 'Weekly summary of all packages approved, returned, and remaining in your queue';
    // AO Approval
    case NotificationId.PackageApproveCPM:
      return 'Receive an email each time an evidence package is approved';
    // Auditor/AO Return
    case NotificationId.PackageRejectCPM:
      return 'Receive an email each time an evidence package is returned';
    default:
      return 'Unexpected rule.';
  }
};
