import { Icon, mergeStyles, Spinner, SpinnerSize } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

export interface SnapshotPreviewButtonProps {
  headerText: string;
  snapshotPreviewButtonDisabled: boolean;
  isLoadingSnapshotPreview: boolean;
  onSnapshotButtonClick(): void;
  snapshotDisplayName?: string;
}

const iconAdvancedStyle = mergeStyles({
  margin: 'auto 0',
  fontSize: '15px',
  cursor: 'pointer',
  color: '#243a5e',
  fontWeight: 'bold',
});

const snapshotPreviewButtonStyles = mergeStyles({
  display: 'flex',
  textAlign: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  paddingLeft: '1em',
});

const snapshotPreviewButtonWithPointerStyles = mergeStyles(snapshotPreviewButtonStyles, {
  cursor: 'pointer',
});

const snapshotPreviewIconStyle = mergeStyles({
  fontSize: '15px',
  fontWeight: 'bold',
  margin: 'auto 0',
});

const previewTextStyles = mergeStyles({
  margin: 'auto 0',
  paddingLeft: '5px',
});

export const SnapshotPreviewButton: FunctionComponent<SnapshotPreviewButtonProps> = (props) => {
  const { headerText, snapshotPreviewButtonDisabled, isLoadingSnapshotPreview, onSnapshotButtonClick } = props;

  const tooltipText = snapshotPreviewButtonDisabled
    ? 'Select a snapshot to enable snapshot preview.'
    : `Click to preview Snapshot: ${props.snapshotDisplayName}.`;
  const buttonStyles = snapshotPreviewButtonDisabled ? snapshotPreviewButtonStyles : snapshotPreviewButtonWithPointerStyles;

  return (
    <button
      className={buttonStyles}
      onClick={onSnapshotButtonClick}
      type="button"
      disabled={snapshotPreviewButtonDisabled || isLoadingSnapshotPreview}
      title={tooltipText}
    >
      {isLoadingSnapshotPreview ? (
        <Spinner size={SpinnerSize.medium} className={iconAdvancedStyle} />
      ) : (
        <Icon className={snapshotPreviewIconStyle} iconName="RedEye" aria-label={`Preview snapshot for ${headerText}`} />
      )}
      <span className={previewTextStyles}>Preview</span>
    </button>
  );
};
