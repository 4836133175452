import {
  ActionButton,
  DetailsList,
  Icon,
  IDetailsColumnStyles,
  IPanelStyles,
  Link,
  mergeStyles,
  Panel,
  PanelType,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Toggle,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { getFormattedDateTime } from 'modules/datetime/datetime';
import {
  ControlImplementationPlatformType,
  ControlMetadataListResponse,
  FrameworkManagementControl,
  IControlMetadataToWordPostCommand,
} from '../../generated/clientApi';
import { ListColumn } from '../../components/basicList/basicList';
import { iconGreenStyle, iconGreyStyle } from '../../components/statusIcon/statusIcon';
import { ControlCatalogNavLinks } from '../../models/controlCatalogNavLinks';
import { allControls } from '../../modules/controlCatalogs/controlCatalogs';
import { Control, GetInternalAzureServiceTeams, GetInternalServiceTeamSSPWord } from '../../modules/controlMetadata/controlMetadata';
import { logError } from '../../modules/logging/logging';
import { showError, showSuccess } from '../../modules/messageBar/messageBar';
import { createControlCatalogBladeChromeConfig } from '../../modules/pageChrome/catalogPageChrome';
import { updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { actionButtonStyles } from '../../styles/actionButtonStyles';
import { HorizontalRule } from '../../styles/horizontalRule';
import { ControlMetadataDetails } from './controlMetadataDetails';
import { ControlMetadataFilters } from './controlMetadataFilters';
import { controlHasMetadata, filterMetadata } from './controlMetadataLanding.functions';
import { ControlMetadataExcerpt } from './controlMetadataCreateExcerpt';
import { ControlMetaDataStatistics } from './controlMetadataStatistics';

export interface ControlMetadataFilter {
  name: string;
  value: string;
}

const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
};

const iconWrapperStyles = mergeStyles({
  display: 'flex',
});

const buttonWrapperStyles = mergeStyles({
  margin: '1em',
  display: 'flex',
});

const toggleStyles = mergeStyles({
  margin: 'auto 0 auto auto',
});

const panelStyles: Partial<IPanelStyles> = {
  scrollableContent: {
    height: 'calc(100% - 30px)',
    overflowY: 'hidden',
  },
  content: {
    height: 'inherit',
  },
};

const spinnerStyle = mergeStyles({
  marginRight: '5px',
  paddingBottom: '2px',
});

export interface ControlMetadata extends ControlMetadataListResponse {
  id: string;
  title: string;
  metadata?: ControlMetadataListResponse;
}

export const ControlMetadataLanding: React.FunctionComponent = () => {
  // Data loaded once on render. Remains static.
  const [controlMetadata, setControlMetadata] = useState<ControlMetadataListResponse[]>([]);
  const [internalServiceTeams, setInternalServiceTeams] = useState<string[]>([]);
  const [isUpdatingAllMetadata, setIsUpdatingAllMetadata] = useState<boolean | undefined>(undefined);
  const [isGeneratingSSP, setIsGeneratingSSP] = useState<boolean>(false);
  const [lastRefreshMetadata, setLastRefreshMetadata] = useState<string>(getFormattedDateTime(new Date()));

  // State that can change more than on load.
  const [allControlGroupsForCurrentCatalog, setAllControlGroupsForCurrentCatalog] = useState<Control[]>([]);
  const [selectedCatalog, setSelectedCatalog] = useState<string>(allControls);
  const [controls, setControls] = useState<FrameworkManagementControl[]>([]);
  const [filteredControlMetadata, setFilteredControlMetadata] = useState<ControlMetadata[]>([]);
  const [filterByHasMetadata, setFilterByHasMetadata] = useState<boolean>(false);
  const [controlPanelContent, setControlPanelContent] = useState<ControlMetadata | undefined>();
  const [selectedControls, setSelectedControls] = useState<string[]>([]);
  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        const selectedItems = selection.getSelection() as FrameworkManagementControl[];
        setSelectedControls(selectedItems.flatMap((control) => control.id));
      },
    }),
  );

  const [createExcerptPanelOpen, setCreateExcerptPanelOpen] = useState<boolean>(false);
  const [selectedExcerptControlsBasePath, setExcerptControlsBasePath] = useState<string>();
  const [selectedExcerptEnvironment, setSelectedExcerptEnvironment] = useState<ControlImplementationPlatformType>(
    ControlImplementationPlatformType.WW,
  );

  useEffect(() => {
    updatePageChromeConfig(createControlCatalogBladeChromeConfig(ControlCatalogNavLinks.ControlMetadata));
    return () => updatePageChromeConfig();
  }, []);

  useEffect(() => {
    const filteredMetadata = filterMetadata(controls, controlMetadata);
    setFilteredControlMetadata(filteredMetadata);
    setIsUpdatingAllMetadata(false);
  }, [controlMetadata, controls]);

  const onControlClick = (metadata: ControlMetadata) => {
    setControlPanelContent(metadata);
  };

  const updateMetadataRefresh = (date: string) => {
    setLastRefreshMetadata(date);
  };

  const columns: ListColumn[] = [
    {
      key: 'controlId',
      name: 'Control ID',
      onRender: (controlMetadata: ControlMetadata) => <Link onClick={() => onControlClick(controlMetadata)}>{controlMetadata.id}</Link>,
      ariaLabel: 'Control ID',
      minWidth: 200,
      maxWidth: 250,
    },
    {
      key: 'controlName',
      name: 'Control Name',
      ariaLabel: 'Control Name',
      onRender: (controlMetadata: ControlMetadata) => <>{controlMetadata.title}</>,
      minWidth: 300,
    },
    {
      key: 'metadata',
      name: 'Metadata',
      ariaLabel: 'Metadata',
      styles: headerStyle,
      onRender: (controlMetadata: ControlMetadata) => (
        <div className={iconWrapperStyles}>
          {controlMetadata.metadata && controlHasMetadata(controlMetadata.metadata) ? (
            <Icon iconName="SkypeCircleCheck" className={iconGreenStyle} title="Control has metadata" />
          ) : (
            <Icon iconName="CircleFill" className={iconGreyStyle} title="Control does not have metadata" />
          )}
        </div>
      ),
      minWidth: 50,
      maxWidth: 150,
    },
  ];

  const onRefreshAllMetadata = async () => {
    try {
      const allInternalServiceTeams = GetInternalAzureServiceTeams(controlMetadata);
      setInternalServiceTeams(allInternalServiceTeams);
      showSuccess('Azure Service Teams successfully validated.');
    } catch (e) {
      logError('Error retrieving internal service teams', e);
      showError('Azure Service Teams could not be validated. The dropdown list may not reflect the latest list of Azure Service Teams.');
    }
    setIsUpdatingAllMetadata(true);
    updateMetadataRefresh(getFormattedDateTime(new Date()));
  };

  const downloadIconProps = { iconName: 'Sync' };
  const generateIconProps = { iconName: 'EditNote' };

  const filterControls = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    if (checked !== undefined) {
      setFilterByHasMetadata(checked);
    }
  };

  const controlPanelDisplay = (
    <Panel
      headerText={`(${controlPanelContent?.id}) - ${controlPanelContent?.title}`}
      isOpen={controlPanelContent !== undefined}
      onDismiss={() => setControlPanelContent(undefined)}
      closeButtonAriaLabel="Close"
      isBlocking={false}
      type={PanelType.medium}
      styles={panelStyles}
    >
      <ControlMetadataDetails controlMetadata={controlPanelContent?.metadata} />
    </Panel>
  );

  const onClickGenerateSspContent = () => {
    setCreateExcerptPanelOpen(true);
  };

  const onCloseGenerateSspContent = () => {
    setExcerptControlsBasePath(undefined);
    setSelectedExcerptEnvironment(ControlImplementationPlatformType.WW);
    setCreateExcerptPanelOpen(false);
  };

  const onGenerateSSPContent = async () => {
    setIsGeneratingSSP(true);
    const controlCommand: IControlMetadataToWordPostCommand = {
      controlIds: selectedControls,
      catalogId: selectedCatalog,
      controlsBasePath: selectedExcerptControlsBasePath ?? '',
      cloudPlatform: selectedExcerptEnvironment,
    };

    const success = await GetInternalServiceTeamSSPWord(controlCommand);
    if (success) {
      showSuccess('Generated SSP Content.');
    }
    setIsGeneratingSSP(false);
  };

  const excerptPanelDisplay = (
    <Panel
      headerText="Create Excerpt"
      isOpen={createExcerptPanelOpen}
      onDismiss={onCloseGenerateSspContent}
      closeButtonAriaLabel="Close"
      isBlocking={false}
      type={PanelType.medium}
      styles={panelStyles}
    >
      <ControlMetadataExcerpt
        selectedControls={selection.getSelection() as FrameworkManagementControl[]}
        selectedControlsBasePath={selectedExcerptControlsBasePath}
        selectedEnvironment={selectedExcerptEnvironment}
        onSelectControlsBasePath={setExcerptControlsBasePath}
        onSelectEnvironment={setSelectedExcerptEnvironment}
        onCancel={onCloseGenerateSspContent}
        onGenerateSspExcerpt={onGenerateSSPContent}
      />
    </Panel>
  );

  return (
    <div className={pageChromeChildStyles}>
      <h2>Control metadata</h2>
      <div>View coverage metrics and controls that align teams or compliance self-tests.</div>
      <ControlMetaDataStatistics controlIds={controls.flatMap((control) => control.id)} controlMetadata={controlMetadata} />
      <br />
      <h2>Controls</h2>
      <div className={buttonWrapperStyles}>
        <ActionButton
          iconProps={generateIconProps}
          className={actionButtonStyles}
          title="Generate Servce Team SSP Content"
          disabled={isGeneratingSSP}
          onClick={onClickGenerateSspContent}
        >
          {isGeneratingSSP && <Spinner className={spinnerStyle} size={SpinnerSize.small} />}
          Generate Service Team SSP Content
        </ActionButton>
        <ActionButton
          iconProps={downloadIconProps}
          className={actionButtonStyles}
          title={`Last refreshed at ${lastRefreshMetadata}`}
          disabled={isUpdatingAllMetadata}
          onClick={onRefreshAllMetadata}
        >
          {isUpdatingAllMetadata && <Spinner className={spinnerStyle} size={SpinnerSize.small} />}
          Refresh Metadata
        </ActionButton>
        <Toggle label="Show only with metadata" inlineLabel className={toggleStyles} onText="On" offText="Off" onChange={filterControls} />
      </div>
      <HorizontalRule />
      <ControlMetadataFilters
        loadAllControlGroupsForCurrentCatalog
        allControlGroupsForCurrentCatalog={allControlGroupsForCurrentCatalog}
        setSelectedCatalog={setSelectedCatalog}
        setAllControlGroupsForCurrentCatalog={setAllControlGroupsForCurrentCatalog}
        setControls={setControls}
        setControlsMetadata={setControlMetadata}
        allControlsMetadata={controlMetadata}
        internalServiceTeams={internalServiceTeams}
        setInternalServiceTeams={setInternalServiceTeams}
        filterHasMetadata={filterByHasMetadata}
        refreshMetadata={isUpdatingAllMetadata}
      />
      <DetailsList
        items={filteredControlMetadata}
        columns={columns}
        selection={selection}
        selectionMode={SelectionMode.multiple}
        ariaLabelForSelectionColumn="Toggle Control"
        ariaLabelForSelectAllCheckbox="Toggle selection for all controls"
        checkButtonAriaLabel="Select Control"
      />
      {controlPanelContent && controlPanelDisplay}
      {createExcerptPanelOpen === true && excerptPanelDisplay}
    </div>
  );
};
