import { ControlMetadataListResponse } from '../../generated/clientApi';
import { MetadataDataPoint } from './controlMetadataStatistics';

export const getMetadataStatistic = (
  controlMetadataMap: Map<string, ControlMetadataListResponse>,
  controlIds: string[],
  hasTargetMetadata: (metadata: ControlMetadataListResponse | undefined) => boolean,
): MetadataDataPoint => {
  let statisticCount = 0;
  controlIds.forEach((controlId) => {
    const metadataForControl = controlMetadataMap.get(controlId);
    if (hasTargetMetadata(metadataForControl)) {
      statisticCount++;
    }
  });
  return {
    dataWithMetadata: { x: 'Has Metadata', y: statisticCount },
    dataWithoutMetadata: { x: 'No Metadata', y: controlIds.length - statisticCount },
  };
};
