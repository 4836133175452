import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  DetailsList,
  IColumn,
  Dropdown,
  Spinner,
  SpinnerSize,
  SelectionMode,
  SearchBox,
  ISearchBoxStyles,
  mergeStyles,
  DatePicker,
  ITextFieldProps,
} from '@fluentui/react';
import { SaveButton } from 'components/saving/saveButton';
import {
  BasicPoam,
  PoamClosureType,
  PoamMonthlyClosureListClient,
  PoamProposedClosurePostClient,
  PoamProposedClosurePostCommand,
  PoamTargetedClosureListClient,
} from 'generated/clientApi';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { dropdownStyles } from 'styles/dropdownStyles';
import { getConfig } from 'modules/config/config';
import { sortByProperty } from 'pages/conMon/components/tableUtils';
import { ConMonFilterContext } from 'pages/conMon/conMonFilterContext/conMonFilterContext';
import { useConst } from '@uifabric/react-hooks';
import { datePickerStyles } from 'styles/datePickerStyles';
import { cloudOptions } from '../poamFilterValues';
import { ClosureTableColumns } from './closureColumns';

const spinnerStyles = mergeStyles({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type MonthlyClosureTabProps = {
  isOpen: boolean;
  isSaveDialogClosed: boolean;
  selectedPeriod: string;
  isTargetedClosure: boolean;
  lockDate?: Date;
  close: () => void;
  closeDialog: (isDialogClosed: boolean) => void;
  poamsSelected: (isSelected: boolean) => void;
  updatePoamsClosure: () => void;
};

const saveDialogContentProps = {
  type: DialogType.normal,
  title: 'Are you sure you want to close the following POA&Ms?',
  closeButtonAriaLabel: 'Close',
};

const datepickerStyles = {
  ...datePickerStyles,
  root: {
    ...(datePickerStyles.root as any),
    margin: 0,
    '.ms-TextField-fieldGroup': {
      ...(datePickerStyles.root as any)['.ms-TextField-fieldGroup'],
      width: 200,
      padding: '0',
      height: '30px',
    },
  },
};

const textFieldProps: ITextFieldProps = {
  styles: {
    field: { backgroundColor: 'transparent' },
  },
};

const saveDialogStyles = { main: { maxWidth: 250, minWidth: 250, zIndex: 10 } };

export const closedSearchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { width: '250px', height: '30px', borderRadius: '4px' },
};

export const MonthlyClosureTab: React.FunctionComponent<MonthlyClosureTabProps> = (props) => {
  const { close, closeDialog, isOpen, isSaveDialogClosed, lockDate, selectedPeriod, poamsSelected, updatePoamsClosure, isTargetedClosure } = props;
  const { organization } = useContext(ConMonFilterContext);

  const [isSaving, setSaving] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>();
  const [fetchedPoams, setFetchedPoams] = useState<BasicPoam[]>([]);
  const [displayedPoams, setDisplayedPoams] = useState<BasicPoam[]>([]);
  const [selectedCloud, setSelectedCloud] = useState<string>('all');
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [poamSelection, setPoamSelection] = useState<Map<string, BasicPoam>>(new Map<string, BasicPoam>());
  const maxDate = useConst(new Date());

  useEffect(() => {
    let filteredPoams: BasicPoam[] = fetchedPoams.filter((poam) => poam.cloudType === selectedCloud || selectedCloud === 'all') ?? [];
    filteredPoams = filteredPoams.filter(
      (poam) => poam.poamId.toLowerCase().includes(searchText?.toLowerCase() ?? '') || poam.vulnerabilityId?.includes(searchText ?? ''),
    );
    setDisplayedPoams(filteredPoams);
  }, [selectedCloud, fetchedPoams, searchText]);

  useEffect(() => {
    if (selectedPeriod && isOpen) {
      setSelectedCloud('all');
      if (!isTargetedClosure) {
        fetchMonthlyClosures();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeriod]);

  useEffect(() => {
    if (isTargetedClosure && selectedDate) {
      setSelectedCloud('all');
      poamsSelected(false);
      setPoamSelection(new Map<string, BasicPoam>());
      fetchTargetedClosures();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const onSelectDate = useCallback((date: Date | null | undefined): void => {
    if (date) {
      setSelectedDate(date);
    }
  }, []);

  const onSelectionChanged = (selectedPoams: BasicPoam[]): void => {
    const newMap = new Map<string, BasicPoam>();
    selectedPoams.forEach((poam) => {
      newMap.set(poam.id, poam);
    });
    setPoamSelection(newMap);
    poamsSelected(selectedPoams.length > 0);
  };

  const sortTable = (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc', isRiskRatingSort = false): void => {
    if (sortColumn && sortDirection) {
      setDisplayedPoams((prevDisplayedPoams) =>
        sortByProperty(prevDisplayedPoams, sortColumn!.fieldName as keyof BasicPoam, sortDirection, isRiskRatingSort),
      );
    }
  };

  const columns = ClosureTableColumns({
    displayedPoams,
    onSelectionChanged,
    selectedPoams: Array.from(poamSelection.values()),
    sortTable,
  });

  const labelId = 'saveDialogLabel';
  const excludeModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      isBlocking: false,
      styles: saveDialogStyles,
    }),
    [labelId],
  );

  const fetchMonthlyClosures = async (): Promise<void> => {
    setLoading(true);
    const client = new PoamMonthlyClosureListClient(getConfig().apiBaseUri);

    try {
      const response = await client.get(selectedPeriod, organization);

      const poams = response;

      setFetchedPoams(poams);
      setDisplayedPoams(poams);
    } catch (e) {
      showError(`There was an error fetching proposed closures for period: ${selectedPeriod}. Please refresh to try again.`);
    } finally {
      setLoading(false);
    }
  };

  const fetchTargetedClosures = async (): Promise<void> => {
    setLoading(true);
    const client = new PoamTargetedClosureListClient(getConfig().apiBaseUri);

    try {
      const targetedDate = `${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}/${selectedDate
        .getDate()
        .toString()
        .padStart(2, '0')}/${selectedDate.getFullYear()}`;
      const response = await client.get(selectedPeriod, targetedDate, organization);

      const poams = response;

      setFetchedPoams(poams);
      setDisplayedPoams(poams);
    } catch (e) {
      showError(`There was an error fetching proposed targeted closures for period: ${selectedPeriod}. Please refresh to try again.`);
    } finally {
      setLoading(false);
    }
  };

  const onSave = async () => {
    setSaving(true);

    const poamIds = Array.from(poamSelection.keys());
    const client = new PoamProposedClosurePostClient(getConfig().apiBaseUri);
    const command = new PoamProposedClosurePostCommand({
      period: selectedPeriod,
      closureType: isTargetedClosure ? PoamClosureType.Targeted : PoamClosureType.Monthly,
      poamIds,
      justification: isTargetedClosure ? 'Closed based on targeted rescans' : 'Closed based on monthly closures',
    });
    try {
      const response = await client.post(command);
      updatePoamsClosure();

      const poamsProposedClosed = response.length;
      showSuccess(`${poamsProposedClosed} POAMs successfully saved`, 10);
      poamsSelected(false);
      close();
    } catch (e) {
      showError('There was an error saving POAMs. Please refresh to try again.');
    } finally {
      closeDialog(true);
      setSaving(false);
    }
  };

  return (
    <div>
      <div style={{ position: 'sticky', background: 'white', zIndex: 2, paddingBottom: '1rem', top: '92px' }}>
        <div style={{ display: 'flex', gap: '2rem' }}>
          <SearchBox
            placeholder="Search by POA&M ID or Vuln ID"
            value={searchText}
            styles={closedSearchBoxStyles}
            onEscape={(_) => setSearchText(undefined)}
            onClear={(_) => setSearchText(undefined)}
            onChange={(_, newValue) => setSearchText(newValue)}
          />
          <Dropdown
            selectedKey={selectedCloud}
            options={cloudOptions}
            styles={dropdownStyles}
            onChange={(ev, option) => setSelectedCloud((option?.key as string) ?? 'all')}
          />
          {isTargetedClosure && (
            <DatePicker
              isMonthPickerVisible={false}
              minDate={lockDate}
              maxDate={maxDate}
              onSelectDate={onSelectDate}
              styles={datepickerStyles}
              textField={textFieldProps}
              value={selectedDate}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: '.5rem',
            marginBottom: '-.25rem',
            fontWeight: 600,
            zIndex: 2,
            position: 'relative',
            gap: '2rem',
          }}
        >
          <div>{`Displaying ${displayedPoams.length} of ${fetchedPoams.length} rows`}</div>
          <div>{`${poamSelection.size} selected`}</div>
        </div>
      </div>
      {loading ? (
        <div className={spinnerStyles}>
          <Spinner label="Loading Monthly closure POA&Ms..." size={SpinnerSize.large} />
        </div>
      ) : (
        <>
          {!displayedPoams || displayedPoams.length === 0 ? (
            <em style={{ display: 'block', marginTop: '2rem', marginLeft: '2rem' }}>No POA&Ms available for closure. Try clearing filters.</em>
          ) : (
            <div>
              <DetailsList items={displayedPoams} columns={columns} selectionMode={SelectionMode.none} />
            </div>
          )}
        </>
      )}
      <Dialog
        hidden={isSaveDialogClosed}
        onDismiss={() => closeDialog(true)}
        dialogContentProps={saveDialogContentProps}
        modalProps={excludeModalProps}
        maxWidth="450px"
        minWidth="450px"
      >
        <div style={{ height: '250px', overflow: 'auto' }}>
          <p>This will create a deviation that closes the following POA&Ms.</p>

          {Array.from(poamSelection.keys()).map((poamId) => (
            <p key={crypto.randomUUID()}>
              <b>{poamId}</b>
            </p>
          ))}
        </div>
        <DialogFooter>
          <SaveButton defaultText="Confirm" saveText="Submitting..." onSave={onSave} isSaving={isSaving} isPrimary>
            Submit closure
          </SaveButton>
          <DefaultButton onClick={() => closeDialog(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
