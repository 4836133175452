/* eslint-disable no-nested-ternary */
import React from 'react';
import { mergeStyles } from '@fluentui/react';

type DeviationInformationItemProps = {
  title: string;
  text: string | undefined;
};

const tabTitleStyles = mergeStyles({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

export const DeviationInformationItem: React.FunctionComponent<DeviationInformationItemProps> = (props) => {
  const { title, text } = props;

  return (
    <div style={{ display: 'flex', fontSize: '14px' }}>
      <div className={tabTitleStyles} style={{ flexBasis: '30%' }}>
        <div style={{ color: '#605E5C' }}>{title}</div>
      </div>
      <div style={{ flex: 1, lineBreak: 'anywhere' }}>{text || 'N/A'}</div>
    </div>
  );
};
