import { IDropdownOption } from '@fluentui/react';

export const sortDropdownOptions = (dropdownOptions: IDropdownOption[]): IDropdownOption[] =>
  dropdownOptions.sort((itemA, itemB) => {
    if (itemA.text < itemB.text) {
      return -1;
    }
    if (itemA.text > itemB.text) {
      return 1;
    }
    return 0;
  });
