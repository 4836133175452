import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
} from '@fluentui/react';
import { SaveButton } from 'components/saving/saveButton';
import {
  BasicPoam,
  ConmonReportsDefaultJustificationGetClient,
  CveInfo,
  Deviation,
  DeviationStatuses,
  DeviationTypes,
  PoamDeviationPostClient,
  PoamDeviationPostCommand,
  PoamGetOneClient,
  PoamGetOneResponse,
} from 'generated/clientApi';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { pivotClass, pivotStyles } from 'styles/pivot';
import { getConfig } from 'modules/config/config';
import { showError } from 'modules/messageBar/messageBar';
import { ConMonFilterContext } from 'pages/conMon/conMonFilterContext/conMonFilterContext';
import { PoamDeviationPostCommandType } from 'models/typeHelper';
import { ManualRiskAdjustmentTab } from './manualRiskAdjustmentTab';
import { RiskCalculatorTab } from './riskCalculatorTab';
import { RiskCalculatorJustifications, RiskCalculatorValues, getDefaultRiskCalculatorValues } from './riskCalculatorTypes';
import { calculateCVSSFromVector } from './cvssCalc';

type RiskPanelProps = {
  isOpen: boolean;
  close: () => void;
  poamSelection: BasicPoam[];
  selectedPeriod: string;
  updatePoamsRating: () => void;
};

const saveDialogContentProps = {
  type: DialogType.normal,
  title: 'Are you sure you want to create a deviation for the following POA&Ms?',
  closeButtonAriaLabel: 'Close',
};

const saveDialogStyles = { main: { maxWidth: 250, minWidth: 250, zIndex: 10 } };

const tabs = {
  'Manual risk adjustment': 'Manual risk adjustment',
  'Risk calculator': 'Risk calculator',
} as const;

export const RiskPanel: React.FunctionComponent<RiskPanelProps> = (props) => {
  const { isOpen, close, poamSelection, updatePoamsRating, selectedPeriod } = props;
  const { organization } = useContext(ConMonFilterContext);

  const [isSaving, setSaving] = useState(false);
  const [wasEdited, setWasEdited] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<keyof typeof tabs>(poamSelection.length === 1 ? 'Risk calculator' : 'Manual risk adjustment');
  const [deviation, setDeviation] = useState<Deviation>();
  const [isSaveDialogClosed, setIsSaveDialogClosed] = useState<boolean>(true);
  const [proposedRiskValues, setProposedRiskValues] = useState({ proposedScore: '', fullVectorString: '' });
  const [defaultJustifications, setDefaultJustifications] = useState<RiskCalculatorJustifications>();

  const [riskCalculatorValues, setRiskCalculatorValues] = useState<RiskCalculatorValues>(getDefaultRiskCalculatorValues());
  const [messageBarMessages, setMessageBarMessages] = useState({ success: '', error: '' });
  const [draftCalculatorValues, setDraftCalculatorValues] = useState<RiskCalculatorJustifications>();
  const [singlePoamDetails, setSinglePoamDetails] = useState<PoamGetOneResponse>();

  const [internalCveInfo, maxOriginalCve, maxProposedCve] = useMemo(() => {
    if (!singlePoamDetails?.poam || !singlePoamDetails.poam.displayCveIds?.length) {
      return [undefined, undefined];
    }

    const cves = singlePoamDetails?.cveInfos?.map((cve) => ({
      ...cve,
      proposedScore: (calculateCVSSFromVector(cve.proposedVectorString) as any).environmentalMetricScore ?? cve.baseScore ?? 0,
    })) as CveInfo[];
    const maxOriginalCve = cves?.reduce((max, cve) => ((cve.baseScore ?? 0) > (max?.baseScore ?? 0) ? cve : max), cves[0]);
    const maxProposedCve = cves?.reduce((max, cve) => ((cve.proposedScore ?? 0) > (max?.proposedScore ?? 0) ? cve : max), cves[0]);
    return [cves as CveInfo[], maxOriginalCve, maxProposedCve];
  }, [singlePoamDetails]);

  useEffect(() => {
    getDefaultJustifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (poamSelection.length === 1) {
      fetchPoamDetails(poamSelection.at(0) as BasicPoam);
    }
  }, [poamSelection]);

  useEffect(() => {
    const draftDeviation = singlePoamDetails?.poam?.deviationInfo?.filter(
      (dev) => dev.deviationType === DeviationTypes.RiskCalculatorAdjustment && dev.drApproval === DeviationStatuses.Draft,
    );
    if (!draftDeviation?.length) {
      return;
    }
    const draftDeviationInfo = draftDeviation[0].riskDeviationInfo;
    const mappedVals: Map<string, string> = new Map<string, string>();
    const draftAdjVectorStrings = draftDeviationInfo?.adjustedVectorStrings ?? {};
    Object.values(draftAdjVectorStrings).forEach((value) => {
      const parts = value.toLowerCase().split('/');
      parts.forEach((part) => {
        const mapKey = `${part.split(':')[0]}:`;
        mappedVals.set(mapKey, part);
      });
    });

    setRiskCalculatorValues((prev) => {
      const valuesFromDraft = { ...prev };
      Object.entries(valuesFromDraft).forEach(([key, value]) => {
        const draftValue = mappedVals.get(value.type);
        if (draftValue !== undefined) {
          const newVal = {
            ...value,
            option: (draftValue as keyof RiskCalculatorJustifications) ?? '',
            justification: draftDeviationInfo?.justifications ? draftDeviationInfo?.justifications[draftValue] : '',
          };
          valuesFromDraft[key as keyof RiskCalculatorValues] = newVal;
        }
      });
      return valuesFromDraft;
    });
  }, [draftCalculatorValues, singlePoamDetails?.poam?.deviationInfo]);

  const fetchPoamDetails = async (selectedPoam: BasicPoam) => {
    const client = new PoamGetOneClient(getConfig().apiBaseUri);
    try {
      const poamDetails: PoamGetOneResponse = await client.get(selectedPoam.poamId ?? '', selectedPoam.period);
      const draftDeviations = poamDetails.poam?.deviationInfo?.filter(
        (dev) => dev.deviationType === DeviationTypes.RiskCalculatorAdjustment && dev.drApproval === DeviationStatuses.Draft,
      );
      if (draftDeviations?.length) {
        setDraftCalculatorValues(draftDeviations[0].riskDeviationInfo?.justifications as RiskCalculatorJustifications);
      }
      setSinglePoamDetails(poamDetails);
    } catch (e) {
      setMessageBarMessages({
        success: '',
        error: `There was an error fetching CVE Infos for poam ${selectedPoam.poamId}. Please refresh to try again.`,
      });
    }
  };

  const getDefaultJustifications = async () => {
    const client = new ConmonReportsDefaultJustificationGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(organization);
      if (response) {
        setDefaultJustifications(response as RiskCalculatorJustifications);
      } else {
        setDefaultJustifications({} as RiskCalculatorJustifications);
      }
    } catch (e) {
      showError('There was an error fetching the justifications. Please refresh to try again.');
    }
  };

  const labelId = 'saveDialogLabel';
  const excludeModalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      isBlocking: false,
      styles: saveDialogStyles,
    }),
    [labelId],
  );

  const updateDeviation = (newDeviation: Deviation) => {
    if (newDeviation.riskDeviationInfo?.proposedRating && newDeviation.justification?.trim()) {
      setWasEdited(true);
      setDeviation({
        justification: newDeviation.justification,
        riskDeviationInfo: newDeviation.riskDeviationInfo,
      } as Deviation);
    } else if (!newDeviation.justification?.trim()) {
      setWasEdited(false);
    }
  };

  const resetCalculator = () => {
    setRiskCalculatorValues(getDefaultRiskCalculatorValues());
    setWasEdited(false);
  };

  const updateRiskCalculator = (
    valueKey: keyof RiskCalculatorValues,
    newKey: keyof RiskCalculatorJustifications | undefined,
    newJustification: string | null,
  ) => {
    setWasEdited(true);
    if (newKey && defaultJustifications) {
      // eslint-disable-next-line no-param-reassign
      newJustification = defaultJustifications[newKey];
    }

    setRiskCalculatorValues((prevValues) => ({
      ...prevValues,
      [valueKey]: {
        option: newKey ?? prevValues[valueKey].option,
        justification: newJustification ?? prevValues[valueKey].justification,
        isEdited: true,
      },
    }));
  };

  const handlePivotLinkClick = (item?: PivotItem) => {
    if (item) {
      setCurrentTab(item.props.headerText as keyof typeof tabs);
      setWasEdited(false);
      resetCalculator();
    }
  };

  const onSave = async () => {
    if (currentTab === 'Manual risk adjustment') {
      await onSaveManualRisk();
    } else {
      await onSaveRiskCalculator(false);
    }
    setIsSaveDialogClosed(true);
  };

  const onSaveManualRisk = async () => {
    setSaving(true);

    const client = new PoamDeviationPostClient(getConfig().apiBaseUri);
    try {
      const postDeviationCommand: PoamDeviationPostCommandType = {
        deviations: poamSelection.reduce(
          (result, poam) => ({
            ...result,
            [poam.id]: {
              deviationType: DeviationTypes.ManualRiskAdjustment,
              justification: deviation?.justification,
              riskDeviationInfo: deviation?.riskDeviationInfo,
            },
          }),
          {},
        ),
        period: selectedPeriod,
        isDraft: false,
      };

      const response = await client.post(postDeviationCommand as PoamDeviationPostCommand);
      updatePoamsRating();
      setMessageBarMessages({ success: `${response.length} deviations successfully saved`, error: '' });
      setIsSaveDialogClosed(true);
    } catch (e) {
      setMessageBarMessages({
        success: '',
        error: 'There was an error saving the deviations. Please refresh to try again.',
      });
    } finally {
      setWasEdited(false);
      setSaving(false);
    }
  };

  const onSaveRiskCalculator = async (isDraft: boolean) => {
    setSaving(true);

    const client = new PoamDeviationPostClient(getConfig().apiBaseUri);
    try {
      const adjustedVectorStrings: { [key: string]: string } = {};
      const justifications: { [key: string]: string } = {};

      Object.entries(riskCalculatorValues).forEach(([key, value]) => {
        const groupName = key.split('--')[0].split('-').join(' ');
        const { option, justification } = value;
        const deviation = option.toUpperCase();

        if (deviation) {
          if (adjustedVectorStrings[groupName]) {
            adjustedVectorStrings[groupName] += `${adjustedVectorStrings[groupName] ? '/' : ''}${deviation}`;
          } else {
            adjustedVectorStrings[groupName] = deviation;
          }

          if (justification && !deviation.includes(':X')) {
            justifications[deviation.toLowerCase()] = justification;
          }
        }
      });

      const postDeviationCommand: PoamDeviationPostCommandType = {
        deviations: poamSelection.reduce(
          (result, poam) => ({
            ...result,
            [poam.id]: {
              deviationType: DeviationTypes.RiskCalculatorAdjustment,
              riskDeviationInfo: {
                fullVectorString: proposedRiskValues.fullVectorString,
                maxProposedCve,
                maxOriginalCve,
                justifications,
                adjustedVectorStrings,
                proposedRating: '',
                proposedScore: proposedRiskValues.proposedScore,
              },
            },
          }),
          {},
        ),
        period: selectedPeriod,
        isDraft,
      };

      await client.post(postDeviationCommand as PoamDeviationPostCommand);
      updatePoamsRating();
      const successMessage = isDraft
        ? `Draft deviation saved for POA&M ${poamSelection[0].id}`
        : `Risk calculator deviation saved for POA&M ${poamSelection[0].id}`;
      setMessageBarMessages({ success: successMessage, error: '' });
    } catch (e) {
      showError('There was an error saving the deviation. Please refresh to try again.');
    } finally {
      setWasEdited(false);
      setSaving(false);
    }
  };

  const onClose = () => {
    setWasEdited(false);
    resetCalculator();
    setMessageBarMessages({ success: '', error: '' });
    close();
  };

  const areJustificationsValid = () =>
    Object.values(riskCalculatorValues).every((value) => value.option.toLowerCase().includes(':x') || value.justification.trim().length > 0);

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ position: 'absolute', bottom: '1rem' }}>
        {currentTab === 'Risk calculator' ? (
          <>
            <PrimaryButton
              onClick={() => setIsSaveDialogClosed(false)}
              disabled={!areJustificationsValid() || isSaving}
              styles={{ root: { marginRight: 8 } }}
            >
              Submit
            </PrimaryButton>
            <SaveButton
              defaultText="Save draft"
              saveText="Saving draft..."
              onSave={() => onSaveRiskCalculator(true)}
              isSaving={isSaving}
              disabled={!wasEdited || !areJustificationsValid()}
              isPrimary={false}
            >
              Save draft
            </SaveButton>
            <DefaultButton onClick={resetCalculator} styles={{ root: { marginLeft: 8 } }}>
              Reset
            </DefaultButton>
          </>
        ) : (
          <PrimaryButton onClick={() => setIsSaveDialogClosed(false)} text="Save" disabled={!wasEdited} />
        )}
        <DefaultButton onClick={onClose} styles={{ root: { marginLeft: 8 } }}>
          Cancel
        </DefaultButton>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSaving, wasEdited, currentTab, deviation, riskCalculatorValues],
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={onClose}
      type={PanelType.largeFixed}
      closeButtonAriaLabel="Close"
      headerText="Risk Adjustment"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalPoamStyles}
    >
      {(messageBarMessages.success || messageBarMessages.error) && (
        <MessageBar
          messageBarType={messageBarMessages.success ? MessageBarType.success : MessageBarType.error}
          onDismiss={() => setMessageBarMessages({ success: '', error: '' })}
          dismissButtonAriaLabel="Dismiss"
        >
          {messageBarMessages.success || messageBarMessages.error}
        </MessageBar>
      )}

      <div style={{ overflow: 'auto', position: 'relative', height: '100%' }}>
        <Pivot className={pivotClass} styles={pivotStyles} onLinkClick={handlePivotLinkClick}>
          {poamSelection.length === 1 && (
            <PivotItem headerText={tabs['Risk calculator']}>
              {defaultJustifications === undefined || singlePoamDetails === undefined ? (
                <Spinner />
              ) : (
                <RiskCalculatorTab
                  riskCalculatorValues={riskCalculatorValues}
                  updateRiskCalculator={updateRiskCalculator}
                  cveInfos={internalCveInfo}
                  maxCVE={maxOriginalCve as CveInfo}
                  setProposedRiskValues={setProposedRiskValues}
                />
              )}
            </PivotItem>
          )}
          <PivotItem headerText={tabs['Manual risk adjustment']}>
            <ManualRiskAdjustmentTab poamSelection={poamSelection} updateDeviation={updateDeviation} />
          </PivotItem>
        </Pivot>
      </div>
      <Dialog
        hidden={isSaveDialogClosed}
        onDismiss={() => setIsSaveDialogClosed(true)}
        dialogContentProps={saveDialogContentProps}
        modalProps={excludeModalProps}
        maxWidth="450px"
        minWidth="450px"
      >
        <div style={{ height: '250px', overflow: 'auto' }}>
          {poamSelection.map((poam) => (
            <p key={poam.poamId}>
              <b>{poam.poamId}</b>
            </p>
          ))}
        </div>
        <DialogFooter>
          <SaveButton defaultText="Confirm" saveText="Saving..." onSave={onSave} isSaving={isSaving} isPrimary>
            Save
          </SaveButton>
          <DefaultButton onClick={() => setIsSaveDialogClosed(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};
