// import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
// import { createBrowserHistory } from 'history';
import { ReplaySubject } from 'rxjs';
import { getConfig } from '../config/config';
import { showWarning } from '../messageBar/messageBar';

let isInitialized = false;

interface LogEntry {
  message: string;
  severityLevel: any;
}

const loggerSubject = new ReplaySubject<LogEntry>(100, 10000);

const log = (message: string, severityLevel: any): void => loggerSubject.next({ message, severityLevel });

export const logDebug = (message: string): void => log(message, null);

export const logInfo = (message: string): void => log(message, null);

export const logWarning = (message: string): void => log(message, null);

export const logError = (message: string, error: Error | unknown): void => log(`${message} ${error}`, null);

const PROD_ENVIRONMENT = 'prod';

// async function configureAppInsights() {
//   try {
//     // initialize logging
//     // https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
//     const browserHistory = createBrowserHistory();
//     const reactPlugin = new ReactPlugin();

//     const serverConfig = getConfig();
//     const appInsights = new ApplicationInsights({
//       config: {
//         connectionString: serverConfig.applicationInsightsConnectionString,
//         extensions: [reactPlugin],
//         extensionConfig: {
//           [reactPlugin.identifier]: { history: browserHistory },
//         },
//       },
//     });

//     appInsights.loadAppInsights();

//     loggerSubject.subscribe((data) => {
//       appInsights.trackTrace({ message: data.message, severityLevel: data.severityLevel });
//     });
//   } catch (err) {
//     logError('app insights failed to initialize', err);
//     showError('There was an issue loading the page. Please refresh and try again.');
//   }
// }

export const initializeLogging = (): void => {
  if (isInitialized) {
    logWarning('logging attempting to be initialized but is already initialized');
    showWarning('There may be an issue loading the page. Please refresh and try again.');
    return;
  }

  isInitialized = true;
  if (getConfig().environment !== PROD_ENVIRONMENT) {
    loggerSubject.subscribe((logEntry: LogEntry) => {
      /* eslint-disable no-console */
      // if (logEntry.severityLevel === SeverityLevel.Verbose) {
      //   console.debug(logEntry.message);
      // } else if (logEntry.severityLevel === SeverityLevel.Information) {
      //   console.info(logEntry.message);
      // } else if (logEntry.severityLevel === SeverityLevel.Warning) {
      //   console.warn(logEntry.message);
      // } else if (logEntry.severityLevel === SeverityLevel.Error) {
      //   console.error(logEntry.message);
      // } else {
      console.log(logEntry.message);
      // }
      /* eslint-enable no-console */
    });
  }
  // configureAppInsights(); // Not awaited on purpose to not block caller
};
