import { Icon, IDetailsColumnStyles, Link, mergeStyles } from '@fluentui/react';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { iconGreenStyle } from 'components/statusIcon/statusIcon';
import { IOfferingService, OfferingService } from '../../generated/clientApi';
import { Domains } from '../../models/domainConstants';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { LoadingState } from '../../models/loadingState';
import { UserRole } from '../../models/userRole';
import { getFormattedDate } from '../../modules/datetime/datetime';
import { logError } from '../../modules/logging/logging';
import { getOfferingStatus } from '../../modules/offering/offering';
import { evidenceDetailRoute } from '../../modules/routes/routes';
import { CenteredProgressDots } from '../progressDots/progressDots';
import { ListColumn } from '../basicList/basicList';
import { ColumnTabWidget, ColumnTabsProps } from '../columnTabWidget/columnTabWidget';

const columnStyle = mergeStyles({
  display: 'flex',
  width: 'fit-content',
});

const columnCenteredStyle = mergeStyles({
  display: 'flex',
  width: 'fit-content',
  margin: 'auto',
});

const linkStyle = {
  textDecoration: 'none',
};

const iconWrapperStyle = mergeStyles({
  display: 'flex',
  height: 'auto',
});

const offeringStatusStyle = mergeStyles({
  margin: 'auto 1em',
});

const iconSubmittedStyle = mergeStyles({
  margin: 'auto',
  cursor: 'pointer',
  color: '#0078D4',
});

const iconUnderReviewStyle = mergeStyles({
  margin: 'auto',
  cursor: 'pointer',
  color: '#EF6950',
});

const iconReturnedStyle = mergeStyles({
  margin: 'auto',
  cursor: 'pointer',
  color: '#00A2AD',
});

const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
};

enum OfferingStatusTab {
  Approved,
  Submitted,
  UnderReview,
  Returned,
}

enum OfferingStatusDisplay {
  Approved = 'P-ATO',
  Submitted = 'Submitted',
  UnderReview = 'Under review',
  Returned = 'Returned',
}

interface OfferingCategories {
  approvedServices: IOfferingService[];
  submittedServices: IOfferingService[];
  underReviewServices: IOfferingService[];
  returnedServices: IOfferingService[];
}

export interface OfferingStatusWidgetProps {
  userRole: UserRole;
  cloud: string;
  certification: string;
  offering: string;
  userRoleTitle?: string;
}

export const OfferingStatusWidget: FunctionComponent<OfferingStatusWidgetProps> = (props) => {
  const { userRole, cloud, certification, offering, userRoleTitle } = props;
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.Loaded);
  const [offeringCategories, setOfferingCategories] = useState<OfferingCategories>();

  const divideServicesIntoCategories = useCallback(
    (offeringServices: IOfferingService[]): OfferingCategories => {
      const approvedServices: IOfferingService[] = [];
      const returnedServices: IOfferingService[] = [];
      const submittedServices: IOfferingService[] = [];
      const underReviewServices: IOfferingService[] = [];

      offeringServices.forEach((service) => {
        const copiedService = { ...service };
        if (service.evidencePackageStatus === EvidencePackageStatus.Approved) {
          copiedService.offeringStatus = OfferingStatusDisplay.Approved;
          approvedServices.push(copiedService);
        }

        if (
          service.evidencePackageStatus === EvidencePackageStatus.AuditorReturned ||
          service.evidencePackageStatus === EvidencePackageStatus.AoReturned
        ) {
          copiedService.offeringStatus = OfferingStatusDisplay.Returned;
          returnedServices.push(copiedService);
        }

        if (
          (userRole === UserRole.Auditor && service.evidencePackageStatus === EvidencePackageStatus.AuditorSubmit) ||
          (userRole === UserRole.AuthorizingOfficial && service.evidencePackageStatus === EvidencePackageStatus.AoSubmit)
        ) {
          copiedService.offeringStatus = OfferingStatusDisplay.Submitted;
          submittedServices.push(copiedService);
        }

        if (
          (userRole === UserRole.Auditor && service.evidencePackageStatus === EvidencePackageStatus.AuditorReview) ||
          (userRole === UserRole.AuthorizingOfficial && service.evidencePackageStatus === EvidencePackageStatus.AoReview)
        ) {
          copiedService.offeringStatus = OfferingStatusDisplay.UnderReview;
          underReviewServices.push(copiedService);
        }
      });

      return {
        approvedServices,
        returnedServices,
        submittedServices,
        underReviewServices,
      };
    },
    [userRole],
  );

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(LoadingState.NotLoaded);
        const offeringServices = await getOfferingStatus(offering, cloud, certification);

        // We need to divide into buckets here
        const categories = divideServicesIntoCategories(offeringServices);

        setOfferingCategories(categories);
        setIsLoading(LoadingState.Loaded);
      } catch (error) {
        setIsLoading(LoadingState.Error);
        logError('There was an issue loading the current evidence package', error);
      }
    };

    getData();
  }, [offering, cloud, certification, divideServicesIntoCategories]);

  const getSymbol = useCallback((offeringStatus: string) => {
    // If the current package has been returned by an auditor or AO and the authorization is active, do not show the package status at all
    if (offeringStatus === OfferingStatusDisplay.Approved) {
      return <Icon iconName="SkypeCircleCheck" className={iconGreenStyle} title="Approved" />;
    }

    if (offeringStatus === OfferingStatusDisplay.Submitted) {
      return <Icon iconName="CircleFill" className={iconSubmittedStyle} title="Submitted" />;
    }

    if (offeringStatus === OfferingStatusDisplay.UnderReview) {
      return <Icon iconName="CircleFill" className={iconUnderReviewStyle} title="Under review" />;
    }

    if (offeringStatus === OfferingStatusDisplay.Returned) {
      return <Icon iconName="CircleFill" className={iconReturnedStyle} title="Returned" />;
    }
    return <></>;
  }, []);

  const renderStatusSymbol = useCallback(
    (evidencePackageStatus: string) => <div className={iconWrapperStyle}>{getSymbol(evidencePackageStatus)}</div>,
    [getSymbol],
  );

  const offeringColumns: ListColumn[] = useMemo(
    () => [
      {
        key: 'serviceName',
        name: 'Service name',
        onRender: (offeringService: OfferingService) => (
          <Link
            as={RouterLink}
            to={evidenceDetailRoute({
              serviceOid: offeringService.serviceOid,
              evidencePackageId: offeringService.id,
              navMenuTab: Domains.SERVICE_TREE_PROPERTIES,
            })}
            style={linkStyle}
          >
            {offeringService.serviceName}
          </Link>
        ),
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'authorization',
        name: 'Authorization',
        onRender: (offeringService: OfferingService) => (
          <div className={columnStyle}>
            {renderStatusSymbol(offeringService.offeringStatus || '')}
            <div className={offeringStatusStyle}>{offeringService.offeringStatus}</div>
          </div>
        ),
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'lastApproved',
        name: 'Last approved',
        styles: headerStyle,
        onRender: (offeringService: OfferingService) => (
          <div className={columnCenteredStyle}>{offeringService.lastApproved ? <>{getFormattedDate(offeringService.lastApproved)}</> : <>-</>}</div>
        ),
        minWidth: 100,
      },
    ],
    [renderStatusSymbol],
  );

  const serviceTable = (status: OfferingStatusTab) => {
    let filteredServices: IOfferingService[] = [];
    if (!offeringCategories) {
      return filteredServices;
    }

    switch (status) {
      case OfferingStatusTab.Approved:
        filteredServices = offeringCategories.approvedServices;
        break;
      case OfferingStatusTab.Submitted:
        filteredServices = offeringCategories.submittedServices;
        break;
      case OfferingStatusTab.UnderReview:
        filteredServices = offeringCategories.underReviewServices;
        break;
      case OfferingStatusTab.Returned:
        filteredServices = offeringCategories.returnedServices;
        break;
      default:
        break;
    }

    return filteredServices;
  };

  const servicesCount =
    (offeringCategories?.approvedServices.length || 0) +
    (offeringCategories?.submittedServices.length || 0) +
    (offeringCategories?.underReviewServices.length || 0) +
    (offeringCategories?.returnedServices.length || 0);

  const columnsTabs: ColumnTabsProps[] = [
    {
      title: 'Approved',
      count: offeringCategories ? offeringCategories?.approvedServices.length : 0,
      items: serviceTable(OfferingStatusTab.Approved),
      statisticColor: '#107C10',
    },
    {
      title: 'Submitted',
      count: offeringCategories ? offeringCategories?.submittedServices.length : 0,
      items: serviceTable(OfferingStatusTab.Submitted),
      statisticColor: '#0078D4',
    },
    {
      title: 'Under review',
      count: offeringCategories ? offeringCategories?.underReviewServices.length : 0,
      items: serviceTable(OfferingStatusTab.UnderReview),
      statisticColor: '#EF6950',
    },
    {
      title: 'Returned',
      count: offeringCategories ? offeringCategories?.returnedServices.length : 0,
      items: serviceTable(OfferingStatusTab.Returned),
      statisticColor: '#00A2AD',
    },
  ];

  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <ColumnTabWidget
          title={`Offering status by services ${userRoleTitle}`}
          columnTabs={columnsTabs}
          totalTitle="Total Services"
          totalCount={servicesCount}
          defaultActiveColumn={OfferingStatusTab.Approved}
          listColumns={offeringColumns}
        />
      )}
    </>
  );
};
