import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { IAzureService } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { logError } from '../../modules/logging/logging';
import { getServices } from '../../modules/service/service';
import { ServiceContext, ServiceContextProps } from './serviceContext';

export const ServiceProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [services, setServices] = useState<IAzureService[] | undefined>(undefined);
  const [selectedService, setSelectedService] = useState<IAzureService | undefined>(undefined);
  const [servicesLoaded, setServicesLoaded] = useState<LoadingState>(LoadingState.NotLoaded);

  const serviceContextProps: ServiceContextProps = {
    services,
    servicesLoadingState: servicesLoaded,
    requestServices: () => {
      if (servicesLoaded === LoadingState.NotLoaded || servicesLoaded === LoadingState.Error) {
        setServicesLoaded(LoadingState.Requested);
      }
    },
    selectedService,
    setSelectedService: (serviceOid: string | undefined): void => {
      const service = services?.find((service) => service.id === serviceOid);
      setSelectedService(service);
    },
    updateSelectedService: (service: IAzureService): void => {
      setSelectedService(service);
    },
  };

  // Auto grab the services if someone requests them
  useEffect(() => {
    const getAllServices = async () => {
      try {
        const allServices = await getServices();
        setServices(allServices);
        setServicesLoaded(LoadingState.Loaded);
      } catch (error) {
        setServicesLoaded(LoadingState.Error);
        logError('There was an issue loading the services', error);
      }
    };

    if (servicesLoaded === LoadingState.Requested && services === undefined) {
      getAllServices();
    }
  }, [servicesLoaded, services]);

  return <ServiceContext.Provider value={serviceContextProps}>{children}</ServiceContext.Provider>;
};
