import { IIconProps, mergeStyles, NeutralColors } from '@fluentui/react';
import { isUSNat } from 'components/util/cloudUtil';
import { Agency, EvidenceComment, EvidencePackageUserStatus, EvidenceUpdateType, IEvidencePackage } from '../../../generated/clientApi';
import { Certification } from '../../../models/certification';
import { domainMap, Domains } from '../../../models/domainConstants';
import { DomainStatus } from '../../../models/domainStatus';
import { UserRole } from '../../../models/userRole';
import { ReviewType } from '../../../pages/evidenceDetail/menuItems/evidenceDetailReview';

const approvedIconStyles = mergeStyles({
  color: 'green !important',
  margin: 'auto',
});

const rejectedIconStyles = mergeStyles({
  color: 'red !important',
  transform: 'rotate(45deg)',
  margin: 'auto',
});

const incompleteIconStyles = mergeStyles({
  color: 'grey !important',
  margin: 'auto',
});

export const chatStyles = mergeStyles({
  marginTop: '100px',
  marginBottom: '65px',
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  overflowY: 'auto',
  padding: '5px',
  width: '320px',
  backgroundColor: NeutralColors.white,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
});

export interface CommentData {
  domain: string;
  comments?: string;
  DOD?: string;
  DHS?: string;
  GSA?: string;
}

export interface CommentAccordianData {
  domain: string;
  authorizingOfficialOrganization: string;
  comments?: string;
  score?: string;
}

export const getCommentsData = (
  evidencePackage: IEvidencePackage | undefined,
  reviewType: string,
  summaryName: string,
  decisionName: string,
): CommentData[] => {
  if (!evidencePackage) {
    return [];
  }

  const commentsData: CommentData[] = [];
  const domains = Object.values(Domains)
    .filter((e) => e !== Domains.PACKAGE_NOTES)
    .filter((e) => {
      // only include the domains that are in the package
      let shouldFilter = true;
      evidencePackage.evidenceDomains?.forEach((element) => {
        if (element?.domain?.toString() === e) shouldFilter = false;
      });
      return shouldFilter;
    });
  domains.push(summaryName);

  // due to a credscan false positive will bypass push commit for the below code block
  if (reviewType === ReviewType.AUTHORIZATION_OFFICIAL_REVIEW && evidencePackage.certification === Certification.FedRAMP) {
    // e.g. []{domain: ServiceTreeProperties, DOD: 'comment 1', DHS: 'comment 2', GSA: 'comment 3'}
    domains.push(decisionName);
    domains.forEach((domain) => {
      commentsData.push({
        domain: domainMap.get(domain) || domain,
        DOD:
          domain === summaryName
            ? evidencePackage.dodUserDecision?.comment
            : getComment(domain, evidencePackage.evidenceComments?.dodOfficialComments),
        DHS:
          domain === summaryName
            ? evidencePackage.dhsUserDecision?.comment
            : getComment(domain, evidencePackage.evidenceComments?.dhsOfficialComments),
        GSA:
          domain === summaryName
            ? evidencePackage.gsaUserDecision?.comment
            : getComment(domain, evidencePackage.evidenceComments?.gsaOfficialComments),
      });
    });
  } else if (reviewType === ReviewType.AUTHORIZATION_OFFICIAL_REVIEW) {
    // e.g. []{domain: ServiceTreeProperties, comment: 'comment 1'}
    domains.forEach((domain) => {
      commentsData.push({
        domain: domainMap.get(domain) || domain,
        comments:
          domain === summaryName
            ? evidencePackage.aoUserDecision?.comment
            : getComment(domain, evidencePackage.evidenceComments?.authorizingOfficialComments),
      });
    });
  } else {
    // e.g. []{domain: ServiceTreeProperties, comment: 'comment 1'}
    domains.forEach((domain) => {
      commentsData.push({
        domain: domainMap.get(domain) || domain,
        comments: domain === summaryName ? evidencePackage.auditorResponse : getComment(domain, evidencePackage.evidenceComments?.auditorComments),
      });
    });
  }
  return commentsData;
};

export const getComment = (domain: string, comments?: EvidenceComment[]): string | undefined => {
  let comment;
  comments?.forEach((element) => {
    if (element.domain === domain) {
      comment = element.comment;
    }
  });
  return comment;
};

export const getAgency = (evidenceUpdateType: EvidenceUpdateType): Agency | undefined => {
  let aoUpdateType: Agency | undefined;
  switch (evidenceUpdateType) {
    case EvidenceUpdateType.DhsOfficial:
      aoUpdateType = Agency.DHS;
      break;
    case EvidenceUpdateType.DodOffical:
      aoUpdateType = Agency.DOD;
      break;
    case EvidenceUpdateType.GsaOfficial:
      aoUpdateType = Agency.GSA;
      break;
    default:
      aoUpdateType = Agency.AONotAffiliated;
      break;
  }

  return aoUpdateType;
};

export const getApproveModalBodyText = (
  evidencePackage: IEvidencePackage | undefined,
  reviewRole: UserRole,
  serviceTitle: string,
  isAttestationUploaded: boolean,
  cloud: string | undefined,
): string => {
  if (!evidencePackage) {
    return '';
  }

  let bodyText = `You are about to approve ${serviceTitle}`;

  // If there is no attestation letter present, show a different message
  if (reviewRole === UserRole.Auditor) {
    const allAuditorDomainsApproved = evidencePackage.evidenceDomains?.every(
      (domain) => domain.auditorDomainReviewStatus?.status === DomainStatus.Approved,
    );
    if (!allAuditorDomainsApproved) {
      bodyText += '. You have not rendered a decision or rejected one or more domains in this package.';
    } else if (isAttestationUploaded) {
      bodyText += ' and forward it to the authorizing official for final review and approval.';
    } else {
      bodyText +=
        ' without uploading an Attestation Letter. This evidence package will not be sent to the Authorizing Official until one has been uploaded.';
    }
  }
  if (reviewRole === UserRole.AuthorizingOfficial) {
    const allAuditorDomainsApproved = evidencePackage.evidenceDomains?.every(
      (domain) => domain.authorizingOfficialDomainReviewStatus?.status === DomainStatus.Approved,
    );
    if (!allAuditorDomainsApproved) {
      bodyText += '. You have not rendered a decision or rejected one or more domains in this package.';
    } else if (isAttestationUploaded || isUSNat(cloud)) {
      bodyText += ' and forward it to the DOD official for final review and approval.';
    } else {
      bodyText += ' without uploading an Attestation Letter. This evidence package will not be sent to the DOD Official until one has been uploaded.';
    }
  }

  return `${bodyText} Are you sure you want to approve this service at this time?`;
};

export const getIconInfo = (status: string | undefined): IIconProps => {
  if (status === EvidencePackageUserStatus[EvidencePackageUserStatus.Rejected]) {
    return {
      iconName: 'CircleAdditionSolid',
      className: rejectedIconStyles,
    };
  }

  if (status === EvidencePackageUserStatus[EvidencePackageUserStatus.Approved]) {
    return {
      iconName: 'SkypeCircleCheck',
      className: approvedIconStyles,
    };
  }

  return {
    iconName: 'CircleFill',
    className: incompleteIconStyles,
  };
};

export const getIconData = (evidencePackage: IEvidencePackage, key: string): IIconProps | undefined => {
  let iconData: IIconProps | undefined;
  switch (key) {
    case Agency[Agency.DOD]:
      iconData = getIconInfo(evidencePackage?.dodUserDecision?.userStatus.toString());
      break;
    case Agency[Agency.DHS]:
      iconData = getIconInfo(evidencePackage?.dhsUserDecision?.userStatus.toString());
      break;
    case Agency[Agency.GSA]:
      iconData = getIconInfo(evidencePackage?.gsaUserDecision?.userStatus.toString());
      break;
    default:
      break;
  }

  return iconData;
};
