/* DEVELOPER NOTE: These changes are server side rendered and following files SHOULD maintain parity.
 * Changes are copied from the API project to the UX project on build.
 *
 * API project : src\Microsoft.Mtac.Portal.Api\Content\Components\supplementalData.tsx
 * UX project : src\Microsoft.Mtac.Portal.Ux\ClientApp\src\components\domainEvidenceData\supplementalData.tsx
 */

import React, { FunctionComponent } from 'react';
import { isDataFlowDomain, isServiceTreeDomain } from './domainEvidenceData.functions';

interface SupplementalDataProps {
  domainName: string,
  serviceDescriptionElement?: JSX.Element,
  serviceUrlElement?: JSX.Element,
  dataFlowNarrativeElement?: JSX.Element,
}

export const SupplementalData: FunctionComponent<SupplementalDataProps> = (props) => {
  const { domainName, serviceDescriptionElement, serviceUrlElement, dataFlowNarrativeElement } = props;

  if (isServiceTreeDomain(domainName)) {
    return (
      <>
        <h3>Service Description</h3>
        <>{serviceDescriptionElement}</>
        <h3>Service URL</h3>
        <>{serviceUrlElement}</>
      </>
    );
  }

  if (isDataFlowDomain(domainName)) {
    return (
      <>
        <h3>DFD Narrative</h3>
        <>{dataFlowNarrativeElement}</>
      </>
    );
  }

  return <></>;
};
