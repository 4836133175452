import { IDatePickerStyles, NeutralColors } from '@fluentui/react';

export const datePickerStyles: Partial<IDatePickerStyles> = {
  root: {
    margin: '0.5em 0',
    '.ms-Label': {
      fontWeight: '600 !important',
    },
    '.ms-TextField-fieldGroup': {
      padding: '0.25em 0.5em !important',
      color: '#707070 !important',
      height: 'unset !important',
      border: '1px solid lightgrey !important',
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'left',
      width: 300,
      fontSize: '14px',
      ':focus': {
        border: `1px solid ${NeutralColors.black} !important`,
      },
      '::after': {
        border: 'none',
      },
    },
    'input::placeholder': {
      fontSize: '14px',
      fontStyle: 'unset',
    },
    '.ms-TextField-errorMessage': {
      display: 'none',
    },
    input: {
      lineHeight: 'normal !important',
    },
  },
};
