import { Checkbox, Dropdown, Icon, IDropdownStyles, Label, mergeStyles, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useCallback } from 'react';
import { FileAttachmentComponent, ProgressStatus } from '../../../components/fileAttachment/fileAttachment';
import { Domains } from '../../../models/domainConstants';
import { DependentServiceInfo } from './createEvidenceTab';

export interface AdditionalDomainDetailProps {
  domainName: string;
  additionalDomainDetail?: string;
  setAdditionalDomainDetail(additionalDomainDetail: string | undefined, inFocus: boolean, dependentServiceInfos?: DependentServiceInfo[]): void;
  updateDependentServiceInfos(domainName: string, dependentServiceInfos: DependentServiceInfo[]): void;
  onFileUpload(file: File): void;
  disableAdditionalDomainDetailInput: boolean;
  loading: boolean;
  placeholderText?: string;
  isInFocus: boolean;
  dependentServiceInfos?: DependentServiceInfo[];
  progressStatus?: ProgressStatus | undefined;
}

const additionalDomainDetailContainerStyle = mergeStyles({
  paddingTop: '1em',
});

const spinnerStyle = mergeStyles({
  margin: 'auto 0',
  fontSize: '15px',
  fontWeight: 'bold',
});

const infoIconStyle = mergeStyles({
  color: '#0078d4',
  paddingRight: '0.5em',
});

const additionalInfoStyle = mergeStyles({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '60%',
});

const dependentServicesDropdownStyles: Partial<IDropdownStyles> = {
  root: {
    flex: 1,
  },
  dropdown: {
    maxWidth: '250px',
    alignItems: 'center',
  },
};

const dependentServicesDropdownSectionStyle = mergeStyles({
  display: 'flex',
  alignItems: 'center',
});

const dependentServiceLabelStyle = mergeStyles({
  paddingRight: '0.5em',
});

export const AdditionalDomainDetail: FunctionComponent<AdditionalDomainDetailProps> = (props) => {
  const {
    domainName,
    additionalDomainDetail,
    setAdditionalDomainDetail,
    loading,
    isInFocus,
    dependentServiceInfos,
    progressStatus,
    updateDependentServiceInfos,
    onFileUpload,
  } = props;

  const onChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newAdditionalDomainDetail?: string) => {
      if (newAdditionalDomainDetail) {
        setAdditionalDomainDetail(newAdditionalDomainDetail, true);
      }
    },
    [setAdditionalDomainDetail],
  );

  const getPlaceholder = useCallback(() => {
    switch (domainName) {
      case Domains.RELEASE_MANAGEMENT:
        return 'Enter VSRM Release ID';
      case Domains.SDL:
        return 'Provide additional information to submit with errors';
      case Domains.TRAINING:
        return 'Enter reason';
      case Domains.KEY_VAULT_AND_JIT_ACCESS:
        return 'Enter additional details on JIT exceptions';
      case Domains.GENEVA_ASM:
        return 'Additional notes';
      case Domains.MDS_LOGS:
        return 'Enter geneva warm path account';
      default:
        return '';
    }
  }, [domainName]);

  const tryParse = (input: string) => {
    try {
      return JSON.parse(input);
    } catch {
      // ignore
    }

    return input;
  };

  const isDisabled = props.disableAdditionalDomainDetailInput;

  const CcmTextField: FunctionComponent<{ json?: string; isMultiLine?: boolean }> = useCallback(
    (props) => {
      let defaultValue;
      if (props.json !== undefined) {
        defaultValue = props.json;
      } else {
        defaultValue = additionalDomainDetail;
      }

      return (
        <TextField
          id={domainName}
          placeholder={getPlaceholder()}
          defaultValue={defaultValue}
          onChange={onChange}
          autoFocus={isInFocus}
          disabled={isDisabled}
          multiline={props.isMultiLine}
          rows={3}
          onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
        />
      );
    },
    [additionalDomainDetail, domainName, getPlaceholder, isDisabled, isInFocus, onChange],
  );

  const onDependentServiceDropdownChange = useCallback(
    (serviceId: string, selectedOption: string) => {
      if (dependentServiceInfos) {
        const copiedDependentServiceInfos = [...dependentServiceInfos];
        const dependentServiceInfo = copiedDependentServiceInfos.find((dependentServiceInfo) => dependentServiceInfo.serviceId === serviceId);
        if (dependentServiceInfo) {
          dependentServiceInfo.selectedOption = selectedOption;
          setAdditionalDomainDetail(undefined, false, copiedDependentServiceInfos);
        }
      }
    },
    [dependentServiceInfos, setAdditionalDomainDetail],
  );

  const handleOnDependentServiceCheckBoxClick = useCallback(
    (domainName: string, dependentServiceId: string) => {
      if (dependentServiceInfos) {
        const copiedDependentServiceInfos = [...dependentServiceInfos];
        const dependentServiceInfo = copiedDependentServiceInfos.find((service) => service.serviceId === dependentServiceId);
        if (dependentServiceInfo) {
          dependentServiceInfo.checkBoxProps.checked = !dependentServiceInfo.checkBoxProps.checked;
          updateDependentServiceInfos(domainName, copiedDependentServiceInfos);
        }
      }
    },
    [dependentServiceInfos, updateDependentServiceInfos],
  );

  const renderDependentServices = useCallback(() => {
    if (!dependentServiceInfos || dependentServiceInfos.length === 0) {
      return <></>;
    }

    return (
      <>
        <div className={additionalInfoStyle}>
          <Icon className={infoIconStyle} iconName="InfoSolid" />
          <p>
            This service has taken a dependency on a separate hosting service/platform team to run its services in production. Select the ones to
            include in the evidence download Message.
          </p>
        </div>
        <div>
          {dependentServiceInfos.map((dependentServiceInfo) => (
            <div key={dependentServiceInfo.serviceId} className={dependentServicesDropdownSectionStyle}>
              <Checkbox
                checked={dependentServiceInfo.checkBoxProps.checked}
                onChange={() => handleOnDependentServiceCheckBoxClick(domainName, dependentServiceInfo.serviceId)}
              />
              <Label className={dependentServiceLabelStyle}>{dependentServiceInfo.name}:</Label>
              <Dropdown
                id={dependentServiceInfo.serviceId}
                selectedKey={dependentServiceInfo.selectedOption}
                options={dependentServiceInfo.options}
                styles={dependentServicesDropdownStyles}
                onChange={(_event, option) => {
                  if (option) {
                    onDependentServiceDropdownChange(dependentServiceInfo.serviceId, option.key.toString());
                  }
                }}
              />
            </div>
          ))}
        </div>
      </>
    );
  }, [dependentServiceInfos, domainName, handleOnDependentServiceCheckBoxClick, onDependentServiceDropdownChange]);

  const onFileChange = useCallback(
    async (file: File) => {
      onFileUpload(file);
    },
    [onFileUpload],
  );

  const renderThreatModel = useCallback(
    () => (
      <>
        <div className={additionalInfoStyle}>
          <Icon className={infoIconStyle} iconName="InfoSolid" />
          <p>To enable threat model, please select an attachment.</p>
        </div>
        <FileAttachmentComponent
          progressStatus={progressStatus}
          allowedExtensions={['.tm7', '.tm4', '.html', '.htm', '.png', '.jpeg', '.jpg']}
          onFileChange={onFileChange}
        />
      </>
    ),
    [progressStatus, onFileChange],
  );

  const getAdditionalDomainElement = useCallback(() => {
    switch (domainName) {
      case Domains.RELEASE_MANAGEMENT: {
        let releaseId;
        if (additionalDomainDetail) {
          const json = tryParse(additionalDomainDetail);
          releaseId = json.releaseDetail?.releaseId;
        }
        return <CcmTextField json={releaseId} />;
      }
      case Domains.SDL:
      case Domains.TRAINING:
      case Domains.KEY_VAULT_AND_JIT_ACCESS:
      case Domains.GENEVA_ASM: {
        let comments;
        if (additionalDomainDetail) {
          const json = tryParse(additionalDomainDetail);
          comments = json.comments;
        }
        return <CcmTextField json={comments} isMultiLine />;
      }
      case Domains.MDS_LOGS: {
        let mdsAccount;
        if (additionalDomainDetail) {
          const json = tryParse(additionalDomainDetail);
          mdsAccount = json.mdsDomainAdditionalInfo?.mdsAccount;
        }
        return <CcmTextField json={mdsAccount} />;
      }
      case Domains.INVENTORY_AND_PAVC: {
        return <>{renderDependentServices()}</>;
      }
      case Domains.THREAT_MODEL: {
        return <>{renderThreatModel()}</>;
      }
      default:
        return undefined;
    }
  }, [CcmTextField, additionalDomainDetail, domainName, renderDependentServices, renderThreatModel]);

  const DomainElement = () => {
    const additionalDomainElement = getAdditionalDomainElement();
    if (!additionalDomainElement) {
      return <></>;
    }
    return (
      <div className={additionalDomainDetailContainerStyle}>
        {loading ? <Spinner size={SpinnerSize.small} className={spinnerStyle} /> : getAdditionalDomainElement()}
      </div>
    );
  };

  return <DomainElement />;
};
