import { IStackTokens, Icon, Stack, TooltipHost, mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import { IDataPoint, DonutChart, IChartProps } from '@fluentui/react-charting';
import { CustomLegend } from '../../components/charts/customLegend';
import { getComplianceCount, getPolicyDriftsCount, getPolicyViolationCount } from './functions/tenantGovernanceComplianceOverview.functions';
import { ISortableCuratedTenantMonitoringDataListResponse, paragraphStyles } from './tenantGovernance';

const horizontalStackToken: IStackTokens = {
  childrenGap: 5,
};

const chartStyles = mergeStyles({
  margin: '3em 0',
  padding: '0 1em 1em',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
});

const chartTitleStyles = mergeStyles({
  textAlign: 'center',
  margin: '0 0 1em 1em',
});

const tooltipStyles = mergeStyles({
  verticalAlign: 'middle',
});

const iconStyles = mergeStyles({
  marginLeft: '5px',
  paddingRight: '5px',
  verticalAlign: 'middle',
  paddingBottom: '1px',
});

interface TenantGovernanceComplianceOverviewProps {
  curatedTenants: ISortableCuratedTenantMonitoringDataListResponse[];
}

export interface MetadataDataPoint {
  dataCompliant: IDataPoint;
  dataNonCompliant: IDataPoint;
}

const donutChartDimension = 350; // React DonutChart width and height props in pixels.
const donutChartInnerRadius = 75; // React DonutChart inner radius props in pixels.

const getLegend = (controlsWithCompliantCount: number, controlsWithNonCompliantCount: number) => [
  {
    title: 'Compliant',
    subtitle: 'customers',
    count: controlsWithCompliantCount,
    color: '#228286',
  },
  {
    title: 'Non-compliant',
    subtitle: 'customers',
    count: controlsWithNonCompliantCount,
    color: '#F7894A',
  },
];

export const TenantGovernanceComplianceOverview: FunctionComponent<TenantGovernanceComplianceOverviewProps> = (props) => {
  const { curatedTenants } = props;

  const chartsStatistics = [
    {
      key: 'complianceChart',
      chartTitle: 'Compliance',
      tooltip: 'Represents the overall compliance of all MS-ISR customers.',
      data: getComplianceCount(curatedTenants),
    },
    {
      key: 'policyViolationChart',
      chartTitle: 'Policy violation',
      tooltip: 'Represents any policy or resource type that is currently not compliant with the Deployment package.',
      data: getPolicyViolationCount(curatedTenants),
    },
    {
      key: 'policyDriftsChart',
      chartTitle: 'Policy drifts',
      tooltip: 'Represents any policy or resource type with a detected configuration change made to a customer tenant’s architecture and controls.',
      data: getPolicyDriftsCount(curatedTenants),
    },
  ];

  const getData = (withMetadata: IDataPoint, withoutMetadata: IDataPoint): IChartProps => ({
    chartData: [
      { legend: 'Compliant customers', data: withMetadata.y, color: '#228286' },
      { legend: 'Non-compliant customers', data: withoutMetadata.y, color: '#F7894A' },
    ],
  });

  return (
    <Stack className={chartStyles}>
      <h2>Compliance Overview</h2>
      <p className={paragraphStyles}>Total: {curatedTenants.length} customers</p>
      <Stack horizontal tokens={horizontalStackToken}>
        {curatedTenants.length ? (
          chartsStatistics.map((statistic) => (
            <Stack verticalAlign="center" key={statistic.key} grow>
              <Stack.Item align="center">
                <div className={chartTitleStyles}>
                  {statistic.chartTitle}
                  <TooltipHost content={statistic.tooltip} className={tooltipStyles}>
                    <Icon iconName="Info" className={iconStyles} />
                  </TooltipHost>
                </div>
                <Stack horizontal>
                  <DonutChart
                    data={getData(statistic.data.dataCompliant, statistic.data.dataNonCompliant)}
                    innerRadius={donutChartInnerRadius}
                    width={donutChartDimension}
                    height={donutChartDimension}
                    hideLegend
                  />
                </Stack>
              </Stack.Item>
              <Stack.Item align="center" shrink grow>
                <Stack horizontal grow>
                  <CustomLegend legends={getLegend(statistic.data.dataCompliant.y, statistic.data.dataNonCompliant.y)} />
                </Stack>
              </Stack.Item>
            </Stack>
          ))
        ) : (
          <span>No curated tenant data found.</span>
        )}
      </Stack>
    </Stack>
  );
};
