import { ActionButton, DefaultButton, Dialog, DialogFooter, Icon, IDialogContentProps, ILinkStyles, Link, mergeStyles } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { updatePageChromeConfig } from 'modules/pageChrome/pageChrome';
import { createSspManagerChromeConfig } from 'modules/pageChrome/sspManagerPageChrome';
import { SspManagerNavLinks } from 'models/sspManagerNavLinks';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';
import { AuthContext } from 'components/authProvider/authContext';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { ISortableFilterableListRow, SearchableFilterableList } from 'components/searchableFilterableList/searchableFilterableList';
import { AuthorizedSystem } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { SITE_WIDE_SUBJECT, SYSTEM_DELETE } from 'modules/constants';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { systemCreateRoute } from 'modules/routes/routes';
import { deleteSystems } from 'modules/system/system';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { HorizontalRule } from 'styles/horizontalRule';
import { HeroContentContext } from 'components/heroContentProvider/heroContentContext';
import { UserContext } from 'components/userProvider/userContext';
import { SaveButton } from 'components/saving/saveButton';
import { getSystemColumns } from './systemsColumns';
import { getPlatform, getRegimes, getSystemControlBases, includeInSearch } from './functions/systems.functions';

const linkStyles: Partial<ILinkStyles> = {
  root: {
    display: 'flex',
    textDecoration: 'none',
    color: 'white !important',
  },
};

const buttonsWrapperStyles = mergeStyles({
  margin: '2em 0 1em',
  display: 'flex',
});

const addIconStyles = mergeStyles({
  marginRight: '0.5em',
});

const linkLabelStyles = mergeStyles({
  lineHeight: '1em',
});

export const SystemsPage: React.FunctionComponent = () => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [systems, setSystems] = useState<AuthorizedSystem[]>([]);
  const [platforms, setPlatforms] = useState<string[] | any>();
  const [regimes, setRegimes] = useState<string[] | any>();
  const [controlBases, setControlBases] = useState<string[] | any>();
  const [systemIdsToDelete, setSystemIdsToDelete] = useState<string[]>([]);
  const [deleteConfirmationHidden, { setTrue: hideDeleteConfirmation, setFalse: showDeleteConfirmation }] = useBoolean(true);
  const [selectedSystems, setSelectedSystems] = useState<AuthorizedSystem[]>([]);
  const [resetSelection, setResetSelection] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const heroContentContext = useContext(HeroContentContext);
  const userContext = useContext(UserContext);
  const userAuthorizedToDeleteSystems = authContext.isAuthorized([{ operation: SYSTEM_DELETE, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    if (userContext.systems) {
      setSystems(userContext.systems);
      setLoadingState(LoadingState.Loaded);
    }
  }, [loadingState, userContext.selectedOrganization, userContext.systems]);

  useEffect(() => {
    updatePageChromeConfig(createSspManagerChromeConfig(SspManagerNavLinks.Systems));
    return () => updatePageChromeConfig();
  }, []);

  useEffect(() => {
    if (userContext.organizations.length > 0) {
      heroContentContext.setShowHero(false);
      heroContentContext.setShowOrganizationDropdown(true);
    }
  }, [heroContentContext, userContext.organizations]);

  const confirmSystemDelete = (systemIds?: string[]) => {
    setSystemIdsToDelete(systemIds || selectedSystems.filter((system) => system.id).flatMap((system) => system.id || ''));
    showDeleteConfirmation();
  };

  const deleteSystemClick = async () => {
    if (!userAuthorizedToDeleteSystems) {
      showError('You do not have permission to delete systems.');
      hideDeleteConfirmation();
      return;
    }
    if (systemIdsToDelete.length < 1) {
      showError('System cannot be deleted because it has no Id.');
      hideDeleteConfirmation();
      return;
    }
    try {
      // Ensure we deselect all checkboxes
      setResetSelection(true);
      await deleteSystems(systemIdsToDelete);
      setSystems(systems.filter((s) => s.id && !systemIdsToDelete.includes(s.id)));
      showSuccess('System(s) were deleted successfully');
      setResetSelection(false);
    } catch (error) {
      showError('There was an error deleting the system(s).');
      return;
    } finally {
      hideDeleteConfirmation();
    }
  };

  useEffect(() => {
    setPlatforms(getPlatform(systems));
    setRegimes(getRegimes(systems));

    const systemControlBases = getSystemControlBases(systems);
    if (userContext.selectedOrganization?.implementationCatalogDetail?.defaultControlsBasePath) {
      setControlBases([...systemControlBases, userContext.selectedOrganization?.implementationCatalogDetail?.defaultControlsBasePath]);
    } else {
      setControlBases(systemControlBases);
    }
  }, [systems, userContext.selectedOrganization]);

  const dialogContentProps: IDialogContentProps = {
    title: 'Delete System?',
    subText: `Are you sure you want to delete the system(s): ${systems
      .filter((system) => systemIdsToDelete.includes(system.id || ''))
      .flatMap((system) => system.systemMetadata?.name)
      .join(', ')}?`,
  };

  return (
    <div className={pageChromeChildStyles}>
      {loadingState !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          <h2>Systems</h2>
          <p>This a list of the all the current systems within your organization. You can view and create new systems below.</p>
          <div className={buttonsWrapperStyles}>
            <Link className={primaryButtonStyles} as={RouterLink} to={systemCreateRoute()} styles={linkStyles}>
              <Icon iconName="add" className={addIconStyles} />
              <div className={linkLabelStyles}>Create new system</div>
            </Link>
            <ActionButton
              text="Delete"
              className={actionButtonStyles}
              style={{ height: 'unset' }}
              iconProps={{ iconName: 'trash' }}
              onClick={() => confirmSystemDelete()}
              disabled={selectedSystems.length === 0}
            />
          </div>
          <HorizontalRule />
          <SearchableFilterableList
            items={systems as ISortableFilterableListRow[]}
            columns={getSystemColumns(userContext, userAuthorizedToDeleteSystems, platforms, regimes, controlBases, confirmSystemDelete)}
            textSortMessage="Search by name or description"
            textSortFields={['name', 'description']}
            includeSearchFilterValue={includeInSearch}
            onTableSelectionChanged={(selectedItems: any[]) => setSelectedSystems(selectedItems as AuthorizedSystem[])}
            resetSelection={resetSelection}
          />
        </>
      )}
      <Dialog hidden={deleteConfirmationHidden} dialogContentProps={dialogContentProps}>
        <DialogFooter>
          <SaveButton defaultText="Yes (Delete)" saveText="Deleting" onSave={deleteSystemClick} isSaving={resetSelection} />
          <DefaultButton text="No" onClick={() => hideDeleteConfirmation()} disabled={resetSelection} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
