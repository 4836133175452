import { mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';

export const sherpaBlue = '#004E4E';

export const menuStyles = mergeStyles({
  backgroundColor: sherpaBlue,
  color: `${NeutralColors.white} !important`,
  border: '1px solid transparent',
  padding: '1em',
  fontFamily: 'Segoe UI',
  fontSize: '14px',
  fontWeight: '400',
  '.ms-Button-label': {
    fontWeight: '400',
  },
  '&:focus-visible': {
    outline: `${NeutralColors.white} solid 1px`,
    borderRadius: '3px',
  },
  selectors: {
    ':hover': {
      backgroundColor: sherpaBlue,
      borderColor: sherpaBlue,
    },
    ':active': {
      backgroundColor: sherpaBlue,
      borderColor: sherpaBlue,
    },
    ':focus': {
      backgroundColor: sherpaBlue,
      borderColor: sherpaBlue,
    },
    '&.is-expanded': {
      borderColor: sherpaBlue,
    },
  },
});
