import { INavLink, INavLinkGroup } from '@fluentui/react';
import { controlCatalogsRoute, controlMetadataListRoute } from 'modules/routes/routes';
import { ControlCatalogNavLinks } from 'models/controlCatalogNavLinks';
import { createNavLink, createNavLinkGroup } from './navMenu';

const createControlCatalogNavLink = (disabled: boolean): INavLink =>
  createNavLink('Control catalogs', controlCatalogsRoute(), '', disabled, ControlCatalogNavLinks.ControlCatalogs);
const createControlMetadataNavLink = (disabled: boolean): INavLink =>
  createNavLink('Control metadata', controlMetadataListRoute(), '', disabled, ControlCatalogNavLinks.ControlMetadata);

export const createCatalogNavLinkGroup = (disabled: boolean): INavLinkGroup[] => {
  const links = [createControlCatalogNavLink(disabled), createControlMetadataNavLink(disabled)];
  return createNavLinkGroup('', links);
};
