import { mergeStyles, Stack } from '@fluentui/react';
import { ToolTipLabel } from 'components/toolTipLabel/toolTipLabel';
import React from 'react';

export interface MetricProps {
  toolTip?: string;
  label: string;
  value?: number;
}

const labelStyles = mergeStyles({
  fontWeight: 600,
  fontSize: '1.3em',
});

const valueStyles = mergeStyles({
  fontWeight: 'bold',
  fontSize: '3em',
});

export const MetricLabel: React.FunctionComponent<MetricProps> = (props) => (
  <Stack>
    <ToolTipLabel className={labelStyles} label={props.label} ariaLabel={props.toolTip} />
    <span className={valueStyles}>{props.value}</span>
  </Stack>
);
