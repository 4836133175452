import download from 'downloadjs';
import {
  EvidencePackageFileAttachmentPostClient,
  FileAttachment,
  DomainFileAttachmentGetClient,
  FileParameter,
  ServiceFileAttachmentListClient,
  ServiceFileAttachmentPostClient,
  ServiceFileAttachmentGetClient,
  ServiceFileAttachmentDeleteClient,
  ServiceFileAttachmentDeleteResponse,
} from '../../generated/clientApi';
import { createFetchWithProgress, ProgressCallback } from './fileProgress';
import { getConfig } from '../config/config';
import { DocumentType } from '../../models/documentType';

const createFileParameterFromFile = (file: File) =>
  ({
    data: file,
    fileName: file.name,
  }) as FileParameter;

export const createServiceAttachment = async (
  file: File,
  serviceOid: string,
  certification: string | undefined,
  cloud: string | undefined,
  documentType: string,
  uploadToOneCompliance: boolean | undefined,
  callback: ProgressCallback,
): Promise<FileAttachment> => {
  const http = await createFetchWithProgress(callback);
  const client = new ServiceFileAttachmentPostClient(getConfig().apiBaseUri, http);
  const fileAttachment = await client.post(
    serviceOid,
    createFileParameterFromFile(file),
    serviceOid,
    documentType,
    certification,
    cloud,
    uploadToOneCompliance,
  );
  return fileAttachment as FileAttachment;
};

export const createEvidencePackageAttachment = async (
  file: File,
  serviceOid: string,
  evidencePackageId: string,
  certification: string | undefined,
  cloud: string | undefined,
  documentType: string,
  callback: ProgressCallback,
): Promise<FileAttachment> => {
  const http = await createFetchWithProgress(callback);
  const client = new EvidencePackageFileAttachmentPostClient(getConfig().apiBaseUri, http);
  const fileAttachment = await client.post(
    evidencePackageId,
    serviceOid,
    createFileParameterFromFile(file),
    evidencePackageId,
    serviceOid,
    documentType,
    certification,
    cloud,
  );
  return fileAttachment;
};

export const getServiceAttachments = async (
  serviceOid: string,
  cloud?: string,
  certification?: string,
  documentTypes?: DocumentType[],
): Promise<FileAttachment[]> => {
  const client = new ServiceFileAttachmentListClient(getConfig().apiBaseUri);
  return client.get(serviceOid, cloud, certification, documentTypes);
};

export const getAttachmentContents = async (attachmentId: string, ccmFileSource: boolean, serviceOid: string): Promise<Blob> => {
  const client = new DomainFileAttachmentGetClient(getConfig().apiBaseUri);
  const response = await client.get(serviceOid, attachmentId, ccmFileSource);
  return response.data;
};

export const downloadAttachment = async (attachmentId: string, fileName: string, ccmFileSource: boolean, serviceOid: string): Promise<void> => {
  const client = new DomainFileAttachmentGetClient(getConfig().apiBaseUri);
  const response = await client.get(serviceOid, attachmentId, ccmFileSource);
  download(response.data, fileName, response.data.type);
};

export const downloadServiceAttachment = async (serviceOid: string, attachmentId: string, fileName: string): Promise<void> => {
  const client = new ServiceFileAttachmentGetClient(getConfig().apiBaseUri);
  const response = await client.get(serviceOid, attachmentId);
  download(response.data, fileName, response.data.type);
};

export const deleteServiceAttachment = async (serviceOid: string, attachmentId: string | null): Promise<ServiceFileAttachmentDeleteResponse> => {
  const client = new ServiceFileAttachmentDeleteClient(getConfig().apiBaseUri);
  return client.get(serviceOid, attachmentId || '');
};
