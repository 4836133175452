import { mergeStyles, NeutralColors } from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../components/authProvider/authContext';
import { ConfigContext } from '../../components/configProvider/configContext';
import { FilterBubble } from '../../components/filterBubble/filterBubbleComponent';
import { OfferingContext } from '../../components/offeringProvider/offeringContext';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { OfferingStatusWidget } from '../../components/widgets/offeringStatus';
import { ServiceByOfferingWidget } from '../../components/widgets/serviceByOffering';
import { LoadingState } from '../../models/loadingState';
import { UserRole } from '../../models/userRole';
import { AUDITOR_ACTIONS_READ, AUTHORIZATION_ACTIONS_READ, COMPLIANCE_TEAM_ACTIONS_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { createHomeChromeConfig, updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { offeringDetailRoute, OfferingDetailRouteParams } from '../../modules/routes/routes';

const widgetStyle = mergeStyles({
  backgroundColor: NeutralColors.white,
  padding: '1em',
  margin: '0.5em',
  display: 'flex',
  flex: '1',
});

const pageStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

const widgetWrapperStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

export const OfferingDetailPage: React.FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const offeringsContext = useContext(OfferingContext);
  const { offeringId, cloud, certification } = useParams<OfferingDetailRouteParams>();
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [cloudFilter, setCloudFilter] = useState<string>('');
  const [offeringidFilter, setOfferingidFilter] = useState<string>('');
  const [certificationFilter, setCertificationFilter] = useState<string>('');
  const [offeringFilter, setOfferingFilter] = useState<string>('');
  const noneDisplay = 'None';
  const isAuditor = authContext.isAuthorized([{ operation: AUDITOR_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isAO = authContext.isAuthorized([{ operation: AUTHORIZATION_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const history = useHistory();

  useEffect(() => {
    offeringsContext.requestOfferings();
  }, [offeringsContext]);

  useEffect(() => {
    setIsLoading(offeringsContext.offeringsLoadingState);
  }, [offeringsContext.offeringsLoadingState]);

  useEffect(() => {
    setCloudFilter(cloud);
    setCertificationFilter(certification);
    setOfferingidFilter(offeringId);
    setOfferingFilter(offeringsContext.offerings?.find((x) => x.id === offeringId)?.name || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringsContext.offeringsLoadingState]);

  const filterFields = useMemo(
    () => [
      {
        key: 'cloud',
        name: 'Cloud',
        fieldName: 'cloud',
        filterSelection: cloudFilter,
        options: configContext.environmentAuthorizations.flatMap((authorization) => authorization.cloud),
      },
      {
        key: 'certification',
        name: 'Authorization level',
        fieldName: 'certification',
        filterSelection: certificationFilter,
        options: configContext.environmentAuthorizations.flatMap((authorization) => authorization.certification),
      },
      {
        key: 'offering',
        name: 'Offering',
        fieldName: 'offering',
        includeNone: true,
        filterSelection: offeringFilter,
        options: offeringsContext.offerings ? offeringsContext.offerings.map((offering) => offering.name).flat() : [],
      },
    ],
    [configContext.environmentAuthorizations, offeringsContext.offerings, cloudFilter, certificationFilter, offeringFilter],
  );

  useEffect(() => {
    updatePageChromeConfig(createHomeChromeConfig('offerings', isComplianceTeam));
    return () => updatePageChromeConfig();
  }, [isComplianceTeam]);

  const setFilterEntry = useCallback(
    (value: string[], fieldName: string) => {
      switch (fieldName) {
        case 'cloud':
          setCloudFilter(value[0]);
          updateHistory(offeringidFilter, value[0], certificationFilter);
          break;
        case 'certification':
          setCertificationFilter(value[0]);
          updateHistory(offeringidFilter, cloudFilter, value[0]);
          break;
        case 'offering': {
          const offeringName = value[0];
          setOfferingFilter(offeringName);
          const offering = offeringsContext.offerings?.find((offering) => offering.name === offeringName);
          if (offering && offering.id) {
            setOfferingidFilter(offering.id);
            updateHistory(offering.id, cloudFilter, certificationFilter);
          }
          break;
        }
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offeringsContext.offerings],
  );

  function updateHistory(offeringId: string, cloud: string, certification: string) {
    history.push(offeringDetailRoute({ offeringId, cloud, certification }));
  }
  const filtersSelected = offeringFilter !== '' && cloudFilter !== '' && certificationFilter !== '';
  const auditorWidgets = filtersSelected ? (
    <>
      <div className={widgetWrapperStyle}>
        <OfferingStatusWidget
          userRole={UserRole.Auditor}
          cloud={cloudFilter}
          certification={certificationFilter}
          offering={offeringFilter}
          userRoleTitle={isAO && isAuditor ? ' - auditor' : ''}
        />
      </div>
    </>
  ) : (
    <></>
  );
  const aoWidgets = filtersSelected ? (
    <>
      <div className={widgetWrapperStyle}>
        <OfferingStatusWidget
          userRole={UserRole.AuthorizingOfficial}
          cloud={cloudFilter}
          certification={certificationFilter}
          offering={offeringFilter}
          userRoleTitle={isAO && isAuditor ? ' - authorizing official' : ''}
        />
      </div>
    </>
  ) : (
    <></>
  );
  const complianceTeamWidgets = filtersSelected ? (
    <div className={widgetWrapperStyle}>
      <div className={widgetWrapperStyle}>
        <ServiceByOfferingWidget offeringId={offeringidFilter} cloud={cloudFilter} certification={certificationFilter} />
      </div>
    </div>
  ) : (
    <></>
  );

  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <div className={pageStyle}>
          <div className={widgetStyle}>
            {filterFields.map((filter) => (
              <FilterBubble
                key={filter.fieldName}
                id={filter.fieldName}
                fieldName={filter.name}
                filter={filter.filterSelection ? [filter.filterSelection] : []}
                includeNone={filter.includeNone}
                noneDisplay={noneDisplay}
                valueOptions={filter.options}
                onChange={setFilterEntry}
              />
            ))}
          </div>
          {isAuditor && auditorWidgets}
          {isAO && aoWidgets}
          {isComplianceTeam && complianceTeamWidgets}
        </div>
      )}
    </>
  );
};
