import { DefaultButton, IButtonStyles, IIconProps, mergeStyles, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';

const spinnerStyle = mergeStyles({
  marginRight: '5px',
  paddingBottom: '2px',
});

interface SaveButtonProps {
  id?: string;
  disabled?: boolean;
  title?: string;
  isSaving?: boolean;
  saveText?: string;
  defaultText: string;
  className?: string;
  onSave(): void;
  iconProps?: IIconProps;
  isPrimary?: boolean;
  styles?: IButtonStyles;
}

export const SaveButton: React.FunctionComponent<SaveButtonProps> = (props) => {
  SaveButton.defaultProps = {
    disabled: false,
    title: '',
    isSaving: false,
    saveText: 'Saving...',
    className: '',
    isPrimary: true,
  };

  const { id, disabled, title, isSaving, saveText, defaultText, className, onSave, iconProps, isPrimary, styles } = props;

  return isPrimary ? (
    <PrimaryButton
      id={id}
      disabled={disabled || isSaving}
      title={title}
      className={className}
      onClick={onSave}
      data-testid="save"
      styles={styles}
      iconProps={iconProps}
    >
      {isSaving ? (
        <>
          <Spinner className={spinnerStyle} size={SpinnerSize.small} />
          {saveText}
        </>
      ) : (
        <>{defaultText}</>
      )}
    </PrimaryButton>
  ) : (
    <DefaultButton
      id={id}
      disabled={disabled || isSaving}
      title={title}
      className={className}
      onClick={onSave}
      data-testid="save"
      iconProps={iconProps}
      styles={styles}
    >
      {isSaving ? (
        <>
          <Spinner className={spinnerStyle} size={SpinnerSize.small} />
          {saveText}
        </>
      ) : (
        <>{defaultText}</>
      )}
    </DefaultButton>
  );
};
