import { AzureAuthorizationInformation } from 'components/serviceAuthorizationTable/serviceAuthorizationTable';
import { Authorizations, IEvidencePackage } from 'generated/clientApi';

export const combineAuthorizationData = (
  environmentAuthorizations: Authorizations[],
  evidencePackages: IEvidencePackage[],
): AzureAuthorizationInformation[] => {
  const authorizationInformation: AzureAuthorizationInformation[] = [];
  environmentAuthorizations.forEach((environmentAuthorization) => {
    const evidencePackage = evidencePackages.find(
      (evidPackage) => evidPackage.cloud === environmentAuthorization.cloud && evidPackage.certification === environmentAuthorization.certification,
    );
    const azureInformation: AzureAuthorizationInformation = {
      id: environmentAuthorization.id,
      environmentAuthorization,
      evidencePackage,
    };
    authorizationInformation.push(azureInformation);
  });

  // We have to sort here since we are appending the two fields to create the authorization name
  // The Fluent UI list is not smart enough to sort those strings with how we manipulate them
  return sortByCloudCert(authorizationInformation);
};

export const sortByCloudCert = (authorizationInformation: AzureAuthorizationInformation[]): AzureAuthorizationInformation[] =>
  authorizationInformation.sort((authA, authB) => {
    if (authA.environmentAuthorization.cloud === authB.environmentAuthorization.cloud) {
      if (authA.environmentAuthorization.certification < authB.environmentAuthorization.certification) {
        return -1;
      }
      if (authA.environmentAuthorization.certification > authB.environmentAuthorization.certification) {
        return 1;
      }
    }
    if (authA.environmentAuthorization.cloud < authB.environmentAuthorization.cloud) {
      return -1;
    }
    if (authA.environmentAuthorization.cloud > authB.environmentAuthorization.cloud) {
      return 1;
    }
    return 0;
  });

export const updateAuthorizationStatus = (
  evidencePackage: IEvidencePackage,
  authInfo: AzureAuthorizationInformation[],
  note: string,
): AzureAuthorizationInformation[] => {
  const currentAuthInfo = [...authInfo];
  const newEvidencePackage = evidencePackage;
  const index = authInfo.findIndex((info) => info.evidencePackage?.id === newEvidencePackage.id);

  if (index === -1) {
    return currentAuthInfo;
  }
  const occuredStates = currentAuthInfo[index].evidencePackage?.occurredStates;

  newEvidencePackage.occurredStates = occuredStates;
  newEvidencePackage.authorizationAchievedNote = note;
  currentAuthInfo[index].evidencePackage = newEvidencePackage;

  return currentAuthInfo;
};
