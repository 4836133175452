import {
  mergeStyles,
  Announced,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  SelectionMode,
  Spinner,
  SpinnerSize,
  ISpinnerStyles,
  Selection,
  IContextualMenuProps,
  Dialog,
  ContextualMenu,
  DialogType,
  TextField,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  ITextField,
  IDetailsList,
} from '@fluentui/react';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterBubble } from '../filterBubble/filterBubbleComponent';

export interface ListRow {
  id: string;
}

export interface ListColumn extends IColumn {
  isSortingDisabled?: boolean;
  isFilterable?: boolean;
  isSorting?: boolean;
  getFilterValue?(item?: any): string;
  filterName?: string;
  getFieldValue?(item: any): string;
}

export interface ListProps {
  items: ListRow[] | undefined;
  columns: ListColumn[];
  onRenderRow?(onRenderRowProps: IDetailsRowProps | undefined, defaultRender: any): any;
  onRenderItemColumn?(item?: any, index?: number | undefined, column?: ListColumn | undefined): any;
  onSelectedItemsUpdated?(selectedItems: any[]): any;
  selectedItems?: any[];
  initialSortFieldName?: string;
  initialSortDescending?: boolean;
  notScrollable?: boolean;
  selectionMode?: SelectionMode;
  sortLoadingIndicator?: boolean;
}

const getCellText = (item: any, column: ListColumn): string => {
  let value = '';
  if (item && column && column.getFieldValue) {
    value = column.getFieldValue(item);
  } else if (item && column && column.fieldName) {
    value = item[column.fieldName];
  }

  return value.toLowerCase();
};

const BasicListStyles = mergeStyles({
  paddingBottom: 20,
  wordWrap: 'break-word',
});

const scrollableStyle = mergeStyles({
  height: 'auto',
  position: 'relative',
  backgroundColor: 'white',
  overflowY: 'auto',
});

const spinnerStyle: ISpinnerStyles = {
  root: {
    flexDirection: 'row',
  },
  label: {
    margin: 'auto 1em',
  },
};

const notScrollableStyle = mergeStyles({});

const dialogStyles = { main: { maxWidth: 450 } };

export const BasicList: React.FunctionComponent<ListProps> = (props) => {
  const [columns, setColumns] = useState(props.columns.map(setAllColumnDefaults));
  const [items, setItems] = useState(props.items);
  const [sortingColumn, setSortingColumn] = useState<ListColumn>();
  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        const selectedItems = selection.getSelection();
        if (props.onSelectedItemsUpdated) {
          props.onSelectedItemsUpdated(selectedItems);
        }
      },
    }),
  );
  const RESIZE = 'Resize';
  const [contextualMenuProps, setContextualMenuProps] = React.useState<IContextualMenuProps | undefined>(undefined);
  const columnToEdit = React.useRef<IColumn | null>(null);
  const clickHandler = React.useRef<string>(RESIZE);
  const onHideContextualMenu = React.useCallback(() => setContextualMenuProps(undefined), []);
  const [isDialogHidden, setIsDialogHidden] = React.useState(true);
  const hideDialog = () => setIsDialogHidden(true);
  const showDialog = () => setIsDialogHidden(false);
  const resizeDialogContentProps = {
    type: DialogType.normal,
    title: 'Resize Column',
    closeButtonAriaLabel: 'Close',
    subText: 'Enter desired column width pixels:',
  };
  const modalProps = {
    titleAriaId: 'Dialog',
    subtitleAriaId: 'Dialog sub',
    isBlocking: false,
    styles: dialogStyles,
  };
  const textfieldRef = React.useRef<ITextField>(null);
  const detailsListRef = React.useRef<IDetailsList>(null);
  const input = React.useRef<number | null>(null);
  
  useEffect(() => {
    if (props.selectedItems?.length === 0) {
      selection.setAllSelected(false);
    }
  }, [props.selectedItems, selection]);

  function setColumnDefaults(propColumn: ListColumn) {
    const column = { ...propColumn };
    column.minWidth = column.minWidth ?? 210;
    column.maxWidth = Math.max(column.minWidth, column.maxWidth ?? 350);
    column.isResizable = column.isResizable != null ? column.isResizable : true;
    column.isSortingDisabled = column.isSortingDisabled != null ? column.isSortingDisabled : false;
    column.isSortedDescending = column.isSortedDescending != null ? column.isSortedDescending : false;
    column.isPadded = column.isPadded != null ? column.isPadded : false;
    column.sortAscendingAriaLabel = column.sortAscendingAriaLabel || 'Sorted A to Z';
    column.sortDescendingAriaLabel = column.sortDescendingAriaLabel || 'Sorted Z to A';
    column.data = column.data || 'string';
    return column;
  }

  function setColumnOnClick(aColumn: ListColumn) {
    const column = { ...aColumn };
    const existingColumnClick = props.columns.find((col) => col.key === column.key)?.onColumnClick;
    column.onColumnClick = function onClick(ev: React.MouseEvent<HTMLElement>, clickedColumn: ListColumn) {
      onColumnClick.call(ev, ev, clickedColumn);
      existingColumnClick?.call(this, ev, clickedColumn); // eslint-disable-line no-unused-expressions
    };
    return column;
  }

  function setAllColumnDefaults(aColumn: ListColumn): ListColumn {
    return setColumnOnClick(setColumnDefaults(aColumn));
  }

  useEffect(() => {
    setColumns(props.columns.map(setColumnDefaults));
    setColumns((prevColumns) => prevColumns.map(setColumnOnClick));
  }, [props.columns]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setColumns((prevColumns) => prevColumns.map(setColumnOnClick));
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setItems(props.items);
  }, [props.items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.initialSortFieldName !== undefined) {
      const columnToSort = props.columns.find((x) => x.fieldName?.toLowerCase() === props.initialSortFieldName?.toLowerCase());
      if (columnToSort !== undefined) {
        sortColumn(columnToSort);

        if (props.initialSortDescending) {
          sortColumn(columnToSort);
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getKey = (item: ListRow): string => item.id;

  const copyAndSort = function _copyAndSort<T>(itemsToCopyAndSort: T[], column: ListColumn): T[] {
    const sortedItems = itemsToCopyAndSort
      .slice(0)
      .sort((a: T, b: T) =>
        (column.isSortedDescending ? getCellText(a, column) < getCellText(b, column) : getCellText(a, column) > getCellText(b, column)) ? 1 : -1,
      );
    return sortedItems;
  };

  const sortColumn = useCallback(
    (column: ListColumn) => {
      if (!items || column.isSortingDisabled) {
        return;
      }

      setColumns((currentColumns) => {
        const currColumnIndex = currentColumns.findIndex((currCol) => column.key === currCol.key);

        if (currentColumns[currColumnIndex].isSorting) {
          return currentColumns;
        }

        const updatedColumns = [...currentColumns];
        updatedColumns[currColumnIndex].isSorting = true;
        updatedColumns[currColumnIndex].isSortedDescending = !updatedColumns[currColumnIndex].isSortedDescending;

        return updatedColumns;
      });
    },
    [items],
  );

  const resizeColumn = (column: IColumn) => {
    columnToEdit.current = column;
    clickHandler.current = RESIZE;
    showDialog();
  };
  
  const getContextualMenuProps = (ev: React.MouseEvent<HTMLElement>, column: IColumn): IContextualMenuProps => {
    const items = [
      { key: 'resize', text: 'Resize', onClick: () => resizeColumn(column) },
      { key: 'sort', text: 'Sort', onClick: () => sortColumn(column) },
    ];
    return {
      items,
      target: ev.currentTarget as HTMLElement,
      gapSpace: 10,
      isBeakVisible: true,
      onDismiss: onHideContextualMenu,
    };
  };

  const confirmDialog = () => {
    const detailsList = detailsListRef.current;

    if (textfieldRef.current) {
      input.current = Number(textfieldRef.current.value);
    }

    if (columnToEdit.current && input.current && detailsList) {
        const width = input.current;
        detailsList.updateColumn(columnToEdit.current, { width });
    }

    input.current = null;
    hideDialog();
  };

  useEffect(() => {
    const columnIndex = columns.findIndex((column) => column.isSorting);
    if (columnIndex !== -1 && items) {
      columns[columnIndex].isSorting = false;
      setColumns(columns);
      setSortingColumn(undefined);

      const newItems = copyAndSort(items, columns[columnIndex]);
      setItems(newItems);
    }
  }, [columns, items]);

  const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
     setContextualMenuProps(getContextualMenuProps(ev, column));
  };

  useEffect(() => {
    if (sortingColumn && !sortingColumn.isSorting) {
      sortColumn(sortingColumn);
    }
  }, [sortingColumn, sortColumn]);

  const filterableCols = useMemo(() => columns.filter((column) => column.isFilterable), [columns]);
  const [filters, setFilters] = useState<Map<string, string[]>>(new Map<string, string[]>());

  const setFilterEntry = useCallback((newValue: string[] | string, columnName: string) => {
    setFilters((filters) => {
      const newFilters = new Map<string, string[]>();
      filters.forEach((value, key) => newFilters.set(key, value));
      if (typeof newValue === 'string') {
        newFilters.set(columnName, [newValue]);
      } else {
        newFilters.set(columnName, newValue);
      }
      return newFilters;
    });
  }, []);

  const filteredItems = useMemo(
    () =>
      items?.filter((item) => {
        let shouldInclude = true;
        filters.forEach((filter, filterColumn) => {
          const column = filterableCols.find((x) => x.name === filterColumn);
          if (column === undefined) {
            throw new Error('Column is undefined');
          }
          const value = column.getFilterValue ? column.getFilterValue(item) : '';
          if (!filter.includes(value)) {
            shouldInclude = false;
          }
        });
        return shouldInclude;
      }),
    [filterableCols, filters, items],
  );

  return (
    <div className={props.notScrollable ? notScrollableStyle : scrollableStyle}>
      {filterableCols.map((column) => (
        <FilterBubble
          key={column.name}
          id={column.name}
          fieldName={column.filterName ?? column.name}
          filter={filters.get(column.name)}
          valueOptions={items?.map((item) => (column.getFilterValue ? column.getFilterValue(item) : '')) ?? []}
          onChange={setFilterEntry}
          selectionMode={props.selectionMode === undefined ? SelectionMode.single : props.selectionMode}
        />
      ))}
      <Announced message={items ? `${items.length} items are in the list` : 'no items in the list'} />
      {props.sortLoadingIndicator && sortingColumn && <Spinner label="Sorting rows..." size={SpinnerSize.small} styles={spinnerStyle} />}
      <DetailsList
        componentRef={detailsListRef}
        onShouldVirtualize={() => false}
        className={BasicListStyles}
        items={filteredItems || ([] as ListRow[])}
        columns={columns}
        selection={selection}
        selectionMode={props.selectionMode || SelectionMode.none}
        getKey={getKey}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        onRenderRow={props.onRenderRow}
        checkButtonAriaLabel="Toggle selection for single items"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      />
      {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
      <Dialog
        hidden={isDialogHidden}
        onDismiss={hideDialog}
        dialogContentProps={resizeDialogContentProps}
        modalProps={modalProps}
      >
       <TextField
          componentRef={textfieldRef}
          ariaLabel= 'Enter column width'
        />
        <DialogFooter>
          <PrimaryButton onClick={confirmDialog} text={clickHandler.current} />
          <DefaultButton onClick={hideDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div>
  );
};
