import { mergeStyles } from '@fluentui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IAzureService } from 'generated/clientApi';
import { getCloudCertification, getCombinedCloudCertifications } from 'modules/environmentAuthorization/environmentAuthorization';
import { FilterBubble } from '../../components/filterBubble/filterBubbleComponent';
import { LoadingState } from '../../models/loadingState';
import { createHomeChromeConfig, updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { ServiceWidget } from '../../components/widgets/serviceWidget';
import { ConfigContext } from '../../components/configProvider/configContext';
import { ServiceContext } from '../../components/serviceProvider/serviceContext';
import { AuthContext } from '../../components/authProvider/authContext';
import { COMPLIANCE_TEAM_ACTIONS_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';

const widgetStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '1em',
  margin: '0.5em',
  display: 'flex',
  flex: '1',
});

const pageStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

export const ServicesPage: React.FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [serviceFilter, setServiceFilter] = useState<string>();
  const [offeringFilter, setOfferingFilter] = useState<string>();
  const [filteredService, setFilteredService] = useState<IAzureService>();
  const [cloudCert, setCloudCert] = useState<string>();
  const configContext = useContext(ConfigContext);
  const servicesContext = useContext(ServiceContext);
  const authContext = useContext(AuthContext);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const noneDisplay = 'None';

  useEffect(() => {
    servicesContext.requestServices();
  }, [servicesContext]);

  useEffect(() => {
    setIsLoading(servicesContext.servicesLoadingState);
  }, [servicesContext, servicesContext.servicesLoadingState]);

  const filterServices = (azureServices?: IAzureService[], offeringName?: string): string[] => {
    let filteredServices = azureServices || [];
    if (azureServices && offeringName && offeringName !== 'None') {
      filteredServices = azureServices.filter((service) => service.offerings?.some((offering) => offering.name === offeringName));
    }

    return filteredServices?.map((service) => service.name);
  };

  const filterOfferings = (azureServices?: IAzureService[], serviceName?: string): string[] => {
    let filteredServices = azureServices || [];
    if (azureServices && serviceName && serviceName !== 'None') {
      filteredServices = azureServices.filter((service) => service.name === serviceName);
    }

    return filteredServices.map((service) => service.offerings?.flatMap((offering) => offering.name) ?? []).flat();
  };

  const filterFields = useMemo(
    () => [
      {
        key: 'cloudCert',
        name: 'Auth',
        fieldName: 'cloudCert',
        filterSelection: cloudCert,
        options: getCombinedCloudCertifications(configContext.environmentAuthorizations),
      },
      {
        key: 'offering',
        name: 'Offering',
        fieldName: 'offering',
        includeNone: true,
        filterSelection: offeringFilter,
        options: filterOfferings(servicesContext.services, serviceFilter),
      },
      {
        key: 'name',
        name: 'Service',
        fieldName: 'name',
        includeNone: true,
        filterSelection: serviceFilter,
        options: filterServices(servicesContext.services, offeringFilter),
      },
    ],
    [cloudCert, configContext.environmentAuthorizations, offeringFilter, servicesContext.services, serviceFilter],
  );

  useEffect(() => {
    updatePageChromeConfig(createHomeChromeConfig('services', isComplianceTeam));
    return () => updatePageChromeConfig();
  }, [isComplianceTeam]);

  const onServiceFiltered = (serviceName: string) => {
    setServiceFilter(serviceName);
    const service = servicesContext.services?.find((service) => service.name === serviceName);

    if (service) {
      setFilteredService(service);
      servicesContext.setSelectedService(service.id);
    }
  };

  const setFilterEntry = (newValue: string[], columnName: string) => {
    switch (columnName) {
      case 'name':
        onServiceFiltered(newValue[0]);
        break;
      case 'cloudCert':
        setCloudCert(newValue[0]);
        break;
      case 'offering':
        setOfferingFilter(newValue[0]);
        break;
      default:
        break;
    }
  };

  const { cloud, certification } = getCloudCertification(cloudCert);
  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <div className={pageStyle}>
          <div className={widgetStyle}>
            {filterFields.map((filter) => (
              <FilterBubble
                key={filter.fieldName}
                id={filter.fieldName}
                fieldName={filter.name}
                filter={filter.filterSelection ? [filter.filterSelection] : []}
                includeNone={filter.includeNone}
                noneDisplay={noneDisplay}
                valueOptions={filter.options}
                onChange={setFilterEntry}
              />
            ))}
          </div>
          <ServiceWidget
            cloud={cloud}
            certification={certification}
            offering={offeringFilter}
            noneDisplay={noneDisplay}
            showNotFoundMessage={serviceFilter !== undefined && filteredService === undefined}
          />
        </div>
      )}
    </>
  );
};
