import React from 'react';
import { EvidencePackageStatus } from 'models/evidencePackageStatus';
import { Icon } from '@fluentui/react';
import { iconRedStyle, iconYellowStyle, iconGreenStyle, iconGreyStyle } from 'components/statusIcon/statusIcon';
import { AzureAuthorizationInformation } from './serviceAuthorizationTable';

export const getSymbol = (authorizationInformation: AzureAuthorizationInformation, columnStatus: EvidencePackageStatus): any => {
  const evidencePackageStatus = authorizationInformation.evidencePackage?.status;
  switch (true) {
    case (columnStatus === EvidencePackageStatus.AuditorReview && evidencePackageStatus === EvidencePackageStatus.AuditorReturned) ||
      (columnStatus === EvidencePackageStatus.AoReview && evidencePackageStatus === EvidencePackageStatus.AoReturned):
      return <Icon iconName="Blocked2Solid" className={iconRedStyle} title="Evidence Package Returned" />;
    case evidencePackageStatus === columnStatus:
      return <Icon iconName="CircleFill" className={iconYellowStyle} title="Current Stage" />;
    case authorizationInformation.evidencePackage?.occurredStates?.includes(columnStatus):
      return <Icon iconName="SkypeCircleCheck" className={iconGreenStyle} title={EvidencePackageStatus.Completed} />;
    default:
      return <Icon iconName="CircleFill" className={iconGreyStyle} title="Not Started" />;
  }
};
