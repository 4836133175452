import {
  DefaultButton,
  DetailsList,
  Dialog,
  DialogFooter,
  DialogType,
  FontIcon,
  IColumn,
  Icon,
  IconButton,
  Panel,
  PanelType,
  PrimaryButton,
  SelectionMode,
  Spinner,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import {
  DeviationEvidencePostClient,
  DeviationTypes,
  FileParameter,
  BasicPoam,
  PoamDeviationPostClient,
  PoamDeviationPostCommand,
} from 'generated/clientApi';
import React, { useCallback, useMemo, useState } from 'react';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { FileAttachmentComponent } from 'components/fileAttachment/fileAttachment';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { getConfig } from 'modules/config/config';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { PoamDeviationPostCommandType } from 'models/typeHelper';

const fileIconHeaderIcon = mergeStyles({
  padding: 0,
  fontSize: '16px',
});
const fileIconCell = mergeStyles({
  textAlign: 'center',
  selectors: {
    '&:before': {
      content: '.',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
      width: '0px',
      visibility: 'hidden',
    },
  },
});
const fileIconImg = mergeStyles({
  verticalAlign: 'middle',
  maxHeight: '20px',
  maxWidth: '20px',
});

const saveDialogStyles = { main: { maxWidth: 250, minWidth: 250, zIndex: 10 } };

const panelProperties = {
  falsePositive: {
    headerText: 'False positive',
    panelText: 'a false positive',
    deviationType: DeviationTypes.FalsePositive,
  },
  operationalRequirement: {
    headerText: 'Operational requirement',
    panelText: 'an operational requirement',
    deviationType: DeviationTypes.OperationalRequirement,
  },
  manualClosure: {
    headerText: 'Manual closure',
    panelText: 'a manual closure',
    deviationType: DeviationTypes.ManualClosure,
  },
} as const;

type EvidenceDeviationPanelProps = {
  poamSelection: BasicPoam[];
  selectedPeriod: string;
  panelType: 'falsePositive' | 'operationalRequirement' | 'manualClosure';
  isOpen: boolean;
  close: () => void;
  updatePoams: () => void;
};
export const EvidenceDeviationPanel: React.FunctionComponent<EvidenceDeviationPanelProps> = (props) => {
  const { poamSelection, selectedPeriod, panelType, close, updatePoams, isOpen } = props;

  const [isSaving, setSaving] = useState(false);
  const [justification, setJustification] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [isSaveDialogClosed, setIsSaveDialogClosed] = useState<boolean>(true);

  const labelId = 'saveDialogLabel';
  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      isBlocking: isSaving,
      styles: saveDialogStyles,
    }),
    [isSaving],
  );

  const getPanelProperties = useMemo(() => {
    if (panelType && panelType in panelProperties) {
      return panelProperties[panelType];
    }
    return { headerText: '', panelText: '', deviationType: DeviationTypes.ManualClosure };
  }, [panelType]);

  const saveDialogContentProps = useMemo(() => {
    const result = {
      type: DialogType.normal,
      title: '',
      closeButtonAriaLabel: 'Close',
    };

    if (getPanelProperties) {
      const text = getPanelProperties.panelText;
      result.title = `Are you sure you want to create ${text} deviation for the following POA&Ms?`;
    }

    return result;
  }, [getPanelProperties]);

  const saveDeviation = async (): Promise<void> => {
    setSaving(true);
    const evidenceClient = new DeviationEvidencePostClient(getConfig().apiBaseUri);
    const deviationPostClient = new PoamDeviationPostClient(getConfig().apiBaseUri);

    try {
      const fileParams = files.map(
        (file) =>
          ({
            data: file,
            fileName: file.name,
          }) as FileParameter,
      );
      const fileResponse = fileParams.length ? await evidenceClient.post(fileParams, selectedPeriod, getPanelProperties.deviationType) : [];
      const postDeviationCommand: PoamDeviationPostCommandType = {
        deviations: poamSelection.reduce(
          (result, poam) => ({
            ...result,
            [poam.id]: {
              deviationType: getPanelProperties.deviationType,
              justification,
              deviationEvidence: fileResponse,
            },
          }),
          {},
        ),
        period: selectedPeriod,
        isDraft: false,
      };
      const response = await deviationPostClient.post(postDeviationCommand as PoamDeviationPostCommand);
      updatePoams();
      showSuccess(`${response.length} deviation(s) successfully saved`, 10);
    } catch (e) {
      showError('There was an error saving that deviation. Please refresh to try again.');
    } finally {
      setSaving(false);
      close();
    }
  };

  const columns = useMemo(
    (): IColumn[] =>
      // eslint-disable-next-line implicit-arrow-linebreak
      [
        {
          key: 'column1',
          name: 'File Type',
          className: fileIconCell,
          iconClassName: fileIconHeaderIcon,
          iconName: 'Page',
          isIconOnly: true,
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: File) => {
            const fileName = item.name;
            const lastDotIndex = fileName.lastIndexOf('.');
            if (lastDotIndex === -1) {
              return <FontIcon aria-label="Page" iconName="Page" className={fileIconImg} />;
            }
            const extension = fileName.slice(lastDotIndex);
            return <Icon {...getFileTypeIconProps({ extension, size: 16, imageFileType: 'png' })} />;
          },
        },
        {
          key: 'name',
          name: 'Name',
          minWidth: 200,
          maxWidth: 300,
          onRender: (item: File) => item.name,
        },
        {
          key: 'size',
          name: 'File size',
          minWidth: 100,
          maxWidth: 300,
          onRender: (item: File) => {
            const bytesPerMb = 1024 * 1024;
            if (item.size < bytesPerMb) {
              return `${Math.ceil(item.size / 1024)} KB`;
            }
            const fileSizeInMB = item.size / bytesPerMb;
            return `${fileSizeInMB.toFixed(2)} MB`;
          },
        },
        {
          key: 'deleteCol',
          name: 'Delete',
          className: fileIconCell,
          iconClassName: fileIconHeaderIcon,
          iconName: 'Delete',
          isIconOnly: true,
          minWidth: 16,
          maxWidth: 16,
          onRender: (item: File) => (
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              title="Delete"
              ariaLabel="Delete"
              style={{ height: '16px' }}
              onClick={() => {
                setFiles((prev) => prev.filter((file) => file !== item));
              }}
            />
          ),
        },
      ],
    [],
  );

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ position: 'absolute', bottom: '1rem' }}>
        <PrimaryButton onClick={() => setIsSaveDialogClosed(false)} text="Save" disabled={!justification.trim()} />
        <DefaultButton onClick={close} styles={{ root: { marginLeft: 8 } }}>
          Cancel
        </DefaultButton>
      </div>
    ),
    [close, justification],
  );

  if (!panelType) {
    return <></>;
  }

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={close}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      headerText={getPanelProperties ? getPanelProperties.headerText : ''}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalPoamStyles}
    >
      <p style={{ margin: '2.5rem 0', fontWeight: 600 }}>Please upload any evidence files and enter your justifications for your deviations.</p>
      <p style={{ fontWeight: 600 }}>Evidence files</p>
      <FileAttachmentComponent
        disableUpload={false}
        allowedExtensions={['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.csv']}
        onFileChange={(file) => file && setFiles((prev) => [...prev, file])}
      />
      <div style={{ height: '200px', overflow: 'auto' }}>
        <DetailsList items={files} columns={columns} selectionMode={SelectionMode.none} />
      </div>
      <div style={{ marginTop: '4rem' }}>
        <TextField
          label="Justification"
          multiline
          rows={10}
          resizable={false}
          required
          placeholder="Enter a justification"
          onChange={(ev: any, newValue: any) => setJustification(newValue)}
          value={justification}
        />
      </div>
      <Dialog
        hidden={isSaveDialogClosed && !isSaving}
        onDismiss={() => setIsSaveDialogClosed(true)}
        dialogContentProps={saveDialogContentProps}
        modalProps={modalProps}
        maxWidth="450px"
        minWidth="450px"
      >
        {isSaving && <Spinner label="Saving deviations. This may take a while..." />}
        <div style={{ height: '250px', overflow: 'auto' }}>
          {poamSelection.map((poam) => (
            <p key={poam.poamId}>
              <b>{poam.poamId}</b>
            </p>
          ))}
        </div>
        <DialogFooter>
          <PrimaryButton onClick={saveDeviation} disabled={isSaving}>
            Save
          </PrimaryButton>
          <DefaultButton disabled={isSaving} onClick={() => setIsSaveDialogClosed(true)} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </Panel>
  );
};
