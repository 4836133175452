import React, { useEffect, useMemo, useState } from 'react';
import { IStackStyles, SharedColors, Stack, mergeStyles } from '@fluentui/react';
import { FilterBubble } from 'components/filterBubble/filterBubbleComponent';
import { getCloudCertification } from 'modules/environmentAuthorization/environmentAuthorization';
import { EvidencePackage, IAzureService } from 'generated/clientApi';
import { Domains } from 'models/domainConstants';
import { evidenceDetailRoute } from 'modules/routes/routes';
import { searchForEvidencePackage } from '../../modules/evidencePackage/evidencePackage';
import { showWarning } from '../../modules/messageBar/messageBar';
import { WizardNavigation } from '../../components/wizardNavigation/wizardNavigation';

const baseStackStyles: Partial<IStackStyles> = { root: { height: 44 } };
const stackStyles = mergeStyles(baseStackStyles, {
  paddingTop: '1em',
  paddingBottom: '1em',
  borderRadius: '150px',
});

const contentContainerStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

const labelColor = mergeStyles({
  color: SharedColors.pinkRed10,
});

const buttonLabelStyles = mergeStyles({
  paddingBottom: '0.5em',
  paddingTop: '0.5em',
});

export interface ProfileTabProps {
  services: IAzureService[];
  cloudCertifications: string[];
  onClickGatherEvidenceButton(serviceOid: string, cloudCert: string): void;
  selectedServiceOid?: string;
  selectedCloudCertFilter?: string;
  isEditMode: boolean;
}

const noneDisplay = 'None';

export const ProfileTab: React.FunctionComponent<ProfileTabProps> = (props) => {
  const { services, cloudCertifications, onClickGatherEvidenceButton, selectedServiceOid, selectedCloudCertFilter, isEditMode } = props;
  const defaultService = services.filter((service) => service.id === selectedServiceOid);
  const defaultServiceName = defaultService.length > 0 ? defaultService[0].name : '';
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isCheckingForExistingEvidencePackage, setIsCheckingForExistingEvidencePackage] = useState<boolean>(false);
  const [evidencePackage, setEvidencePackages] = useState<EvidencePackage>();
  const [cloudCertFilter, setCloudCertFilter] = useState<string>(selectedCloudCertFilter || '');
  const [serviceFilter, setServiceFilter] = useState<string>(defaultServiceName);

  const filterFields = useMemo(
    () => [
      {
        key: 'cloudCert',
        name: 'Authorization level',
        fieldName: 'cloudCert',
        filterSelection: cloudCertFilter,
        options: cloudCertifications,
      },
      {
        key: 'service',
        name: 'Service',
        fieldName: 'service',
        includeNone: true,
        filterSelection: serviceFilter,
        options: services.map((service) => service.name),
      },
    ],
    [cloudCertFilter, cloudCertifications, serviceFilter, services],
  );

  useEffect(() => {
    const isProfileDataSelected = serviceFilter.length === 0 || cloudCertFilter.length === 0;
    setIsNextButtonDisabled(isProfileDataSelected && !isCheckingForExistingEvidencePackage && !evidencePackage);
  }, [cloudCertFilter, evidencePackage, isCheckingForExistingEvidencePackage, serviceFilter]);

  useEffect(() => {
    const isProfileDataSelected = serviceFilter.length > 0 && cloudCertFilter.length > 0;
    if (isProfileDataSelected && !evidencePackage) {
      const evidencePackageQuery = async () => {
        setIsCheckingForExistingEvidencePackage(true);
        const { cloud, certification } = getCloudCertification(cloudCertFilter);
        const serviceOid = services.find((service) => service.name === serviceFilter)?.id;
        if (serviceOid) {
          const searchForEvidencePackageResponse = await searchForEvidencePackage(serviceOid, cloud, certification);
          const { evidencePackage } = searchForEvidencePackageResponse;
          if (evidencePackage) {
            setEvidencePackages(evidencePackage);
            if (!isEditMode) {
              const domain = evidencePackage.evidenceDomains ? evidencePackage.evidenceDomains[0].name : Domains.SERVICE_TREE_PROPERTIES;
              showWarning(
                `An evidence package has already been created for service: ${serviceFilter}, cloud: ${cloud}, and certification: ${certification}.`,
              );
              // This seems like a hack but I could not figure out a better way to do this.
              window.location.href = evidenceDetailRoute({
                serviceOid,
                evidencePackageId: evidencePackage.id,
                navMenuTab: domain,
              });
            }
          }
          setIsCheckingForExistingEvidencePackage(false);
        }
      };
      evidencePackageQuery();
    }
  }, [cloudCertFilter, evidencePackage, isEditMode, serviceFilter, services]);

  const setFilterEntry = (value: string[], columnName: string) => {
    switch (columnName) {
      case 'cloudCert':
        setCloudCertFilter(value[0]);
        break;
      case 'service':
        setServiceFilter(value[0]);
        break;
      default:
        break;
    }
  };

  const buttonLabelText = (labelText: string) => (
    <div className={buttonLabelStyles}>
      <span>{labelText}</span>
      <span className={labelColor}>{' *'}</span>
    </div>
  );

  const onNextClick = () => {
    const serviceOid = services.find((service) => service.name === serviceFilter)?.id;
    if (serviceOid) {
      onClickGatherEvidenceButton(serviceOid, cloudCertFilter);
    }
  };

  return (
    <div className={contentContainerStyles}>
      <p>Please create a profile for your new evidence package.</p>
      <Stack className={stackStyles}>
        {filterFields.map((filter) => (
          <FilterBubble
            key={filter.fieldName}
            id={filter.fieldName}
            fieldName={filter.name}
            filter={filter.filterSelection ? [filter.filterSelection] : []}
            includeNone={filter.includeNone}
            noneDisplay={noneDisplay}
            valueOptions={filter.options}
            buttonLabelText={buttonLabelText(filter.name)}
            onChange={setFilterEntry}
            isDisabled={isEditMode}
            isNewUi
          />
        ))}
      </Stack>
      <WizardNavigation
        isNextDisabled={isNextButtonDisabled}
        isNextVisible
        isNextLoading={false}
        isPreviousVisible={false}
        nextButtonText="Next: Gather evidence"
        onClickNextButton={onNextClick}
      />
    </div>
  );
};
