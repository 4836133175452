import { Dropdown, DropdownMenuItemType, IDropdownProps, ISearchBoxStyles, ISelectableOption, SearchBox } from '@fluentui/react';
import React, { useState } from 'react';

const searchStyles: Partial<ISearchBoxStyles> = {
  root: {
    margin: '1em',
    border: 'none',
  },
};

export const SearchableDropdown: React.FunctionComponent<IDropdownProps> = (props) => {
  const [searchText, setSearchText] = useState<string>('');
  const searchBar = 'searchBar';

  const renderOption = (option: ISelectableOption | undefined): JSX.Element => {
    if (!option) return <></>;
    return option.itemType === DropdownMenuItemType.Header && option.key === searchBar ? (
      <SearchBox onChange={(ev, newValue) => setSearchText(newValue || '')} placeholder="Search" styles={searchStyles} />
    ) : (
      <>{option.text}</>
    );
  };

  return (
    <Dropdown
      {...props}
      options={[
        { key: searchBar, text: '-', itemType: DropdownMenuItemType.Header },
        { key: 'divider', text: '-', itemType: DropdownMenuItemType.Divider },
        ...props.options.map((option) =>
          !option.disabled && option.text.toLowerCase().includes(searchText.toLowerCase()) ? option : { ...option, hidden: true },
        ),
      ]}
      onRenderOption={renderOption}
      onDismiss={() => setSearchText('')}
    />
  );
};
