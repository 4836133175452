import { IDropdownStyles, NeutralColors } from '@fluentui/react';

export const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    '.ms-Dropdown-label': {
      fontWeight: '600 !important',
    },
  },
  dropdown: {
    padding: '0.25em 0.5em !important',
    color: '#707070 !important',
    height: 'unset !important',
    border: '1px solid lightgrey !important',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'left',
    width: 300,
    fontSize: '14px',
    ':focus': {
      border: `1px solid ${NeutralColors.black} !important`,
    },
  },
  title: {
    height: 'unset',
    margin: 'auto auto auto 0',
    border: 'none',
  },
  caretDownWrapper: {
    position: 'unset',
    margin: 'auto 1em',
  },
  label: {
    fontWeight: '600 !important',
    height: 'fit-content',
  },
  dropdownOptionText: {
    color: '#707070 !important',
  },
  dropdownItem: {
    height: 'fit-content',
  },
};
