import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, IDropdownOption, IDropdownStyles, ISearchBoxStyles, SearchBox } from '@fluentui/react';
import { historyParamUpdate } from 'components/util/historyUtils';
import { CoverageFilterValues } from 'generated/clientApi';
import { IncludeToggle } from './includeToggle';
import { CoverageFilters, useCoverageFilters } from './useCoverageFilters';

const all = 'all';
export const over90 = 'over90';
export const under90 = 'under90';

const coverageOptions: IDropdownOption[] = [
  { key: all, text: 'Coverage: All' },
  { key: over90, text: 'Over 90%' },
  { key: under90, text: 'Under 90%' },
];

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 150 },
  dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
  dropdownItem: { height: 'auto' },
  title: { height: 28, paddingTop: '2px' },
  root: {
    '.ms-Dropdown': {
      height: '28px',
    },
  },
};
const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: '225px', height: '28px' } };

type FilterBarProps = {
  filterValues: CoverageFilterValues;
};

export const FilterBar: React.FunctionComponent<FilterBarProps> = (props) => {
  const { filterValues } = props;
  const [assetOptions, cloudOptions] = useMemo(() => {
    const assetFilters = [{ key: all, text: 'Asset Type: All' }, ...(filterValues.assetTypes ?? []).map((asset) => ({ key: asset, text: asset }))];
    const cloudFilters = [{ key: all, text: 'Cloud Type: All' }, ...(filterValues.cloudTypes ?? []).map((cloud) => ({ key: cloud, text: cloud }))];
    return [assetFilters, cloudFilters];
  }, [filterValues]);

  const filters = useCoverageFilters();
  const history = useHistory();
  const onFilterChange = (key: keyof CoverageFilters, value: string | boolean | undefined) => {
    const newValue = value === all ? undefined : value;
    historyParamUpdate(history, { [key]: newValue });
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1.5rem', gap: '1rem' }}>
      <SearchBox
        placeholder="Search by Service Tree name"
        value={filters.serviceTreeName ?? ''}
        styles={searchBoxStyles}
        onEscape={(_) => onFilterChange('serviceTreeName', undefined)}
        onClear={(_) => onFilterChange('serviceTreeName', undefined)}
        onChange={(_, newValue) => onFilterChange('serviceTreeName', newValue || undefined)}
      />
      <Dropdown
        selectedKey={filters.cloud ?? all}
        options={cloudOptions}
        styles={dropdownStyles}
        onChange={(ev, item) => onFilterChange('cloud', item?.key as string)}
      />
      <Dropdown
        selectedKey={filters.assetType ?? all}
        options={assetOptions}
        styles={dropdownStyles}
        onChange={(ev, item) => onFilterChange('assetType', item?.key as string)}
      />
      <Dropdown
        selectedKey={filters.percentCoverage ?? all}
        options={coverageOptions}
        styles={dropdownStyles}
        onChange={(ev, item) => onFilterChange('percentCoverage', item?.key as string)}
      />
      <IncludeToggle isToggleOn={filters.exclude} onFilterChange={onFilterChange} />
    </div>
  );
};
