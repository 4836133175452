import { DefaultButton, Panel, PanelType, PrimaryButton, Pivot, PivotItem } from '@fluentui/react';

import { BasicPoam } from 'generated/clientApi';
import React, { useCallback, useState } from 'react';
import { sideModalPoamStyles } from 'styles/sidePanelPoam';
import { pivotClass, pivotStyles } from 'styles/pivot';
import { MonthlyClosureTab } from './monthlyClosureTab';

type ProposedClosurePanelProps = {
  isOpen: boolean;
  poams: BasicPoam[];
  selectedPeriod: string;
  close: () => void;
  updatePoamsClosure: () => void;
};

export const ProposedClosurePanel: React.FunctionComponent<ProposedClosurePanelProps> = (props) => {
  const { isOpen, close, poams, selectedPeriod, updatePoamsClosure } = props;
  const [isSaveDialogClosed, setIsSaveDialogClosed] = useState<boolean>(true);

  const [isPoamSelected, setIsPoamSelected] = useState<boolean>(false);

  const handleLinkClick = () => {
    setIsPoamSelected(false);
  };

  const poamsSelected = (isSelected: boolean) => {
    setIsPoamSelected(isSelected);
  };

  const closeDialog = (isDialogClosed: boolean) => {
    setIsSaveDialogClosed(isDialogClosed);
  };

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ paddingBottom: '1rem' }}>
        <PrimaryButton onClick={() => setIsSaveDialogClosed(false)} text="Submit closure" disabled={!isPoamSelected} />
        <DefaultButton onClick={close} styles={{ root: { marginLeft: 8 } }}>
          Cancel
        </DefaultButton>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPoamSelected],
  );

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={close}
      type={PanelType.large}
      closeButtonAriaLabel="Close"
      headerText="Proposed closure"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalPoamStyles}
    >
      <Pivot className={pivotClass} styles={pivotStyles} onLinkClick={handleLinkClick}>
        <PivotItem headerText="Monthly">
          <MonthlyClosureTab
            close={close}
            closeDialog={closeDialog}
            isOpen={isOpen}
            isSaveDialogClosed={isSaveDialogClosed}
            selectedPeriod={selectedPeriod}
            poamsSelected={poamsSelected}
            updatePoamsClosure={updatePoamsClosure}
            isTargetedClosure={false}
          />
        </PivotItem>
        <PivotItem headerText="Targeted">
          <MonthlyClosureTab
            close={close}
            closeDialog={closeDialog}
            isOpen={isOpen}
            isSaveDialogClosed={isSaveDialogClosed}
            selectedPeriod={selectedPeriod}
            poamsSelected={poamsSelected}
            updatePoamsClosure={updatePoamsClosure}
            isTargetedClosure
            lockDate={poams[0]?.lockedDate ?? new Date()}
          />
        </PivotItem>
      </Pivot>
    </Panel>
  );
};
