import {
  Callout,
  IButtonStyles,
  ICheckboxProps,
  IconButton,
  IStackStyles,
  IStackTokens,
  ITextFieldProps,
  mergeStyles,
  NeutralColors,
  Stack,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import React from 'react';

export interface ToolTipLabelProps {
  id: string;
  label: string;
  ariaLabel: string;
  className?: string | undefined;
}

export const ToolTipLabel = (props: ICheckboxProps | ITextFieldProps | ToolTipLabelProps): JSX.Element => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const descriptionId: string = useId('description');
  const iconButtonId: string = useId('iconButton');
  const calloutLabelId = useId('calloutLabel');
  const labelCalloutStackStyles: Partial<IStackStyles> = { root: { padding: 20 } };
  const labelStyles = mergeStyles({ marginLeft: '5px' });
  const iconButtonStyles: Partial<IButtonStyles> = { root: { marginTop: -12, marginLeft: -5 } };
  const iconGrayStyle = mergeStyles({
    color: NeutralColors.gray130,
    cursor: 'pointer',
  });

  const iconProps = { iconName: 'Info' };
  const stackTokens: IStackTokens = {
    childrenGap: 2,
  };

  return (
    <>
      <Stack horizontal>
        <span className={props.className ? props.className : labelStyles} id={props.id}>
          {props.label}
        </span>
        <IconButton
          id={iconButtonId}
          iconProps={iconProps}
          title="Info"
          className={iconGrayStyle}
          styles={iconButtonStyles}
          onClick={toggleIsCalloutVisible}
          aria-labelledby={calloutLabelId}
        />
      </Stack>
      {isCalloutVisible && (
        <Callout
          id={calloutLabelId}
          target={`#${iconButtonId}`}
          setInitialFocus
          onDismiss={toggleIsCalloutVisible}
          ariaDescribedBy={descriptionId}
          role="alertdialog"
        >
          <Stack tokens={stackTokens} horizontalAlign="start" styles={labelCalloutStackStyles}>
            <span id={descriptionId}>{props.ariaLabel}</span>
          </Stack>
        </Callout>
      )}
    </>
  );
};
