import {
  AtoAOrganization,
  OrganizationControlsBasePathsGetClient,
  OrganizationControlsBasePathsGetResponse,
  OrganizationListClient,
  OrganizationResponsibleRoleListClient,
  OrganizationsGetClient,
  OrganizationalFrontMatter,
  OrganizationalFrontMatterGetClient,
  OrganizationalFrontMatterPostClient,
  OrganizationalFrontMatterPostCommand,
  OrganizationalFrontMatterPutCommand,
  OrganizationalFrontMatterPutClient,
  ConmonOrganization,
  ConmonOrganizationListClient,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getOrganization = async (organizationId: string): Promise<AtoAOrganization> => {
  const client = new OrganizationsGetClient(getConfig().apiBaseUri);
  return client.get(organizationId);
};

export const getOrganizations = async (): Promise<AtoAOrganization[]> => {
  const client = new OrganizationListClient(getConfig().apiBaseUri);
  return client.get();
};

export const getConmonOrganizations = async (): Promise<ConmonOrganization[]> => {
  const client = new ConmonOrganizationListClient(getConfig().apiBaseUri);
  return client.get();
};

export const getOrganizationControlsBases = async (organizationId: string): Promise<OrganizationControlsBasePathsGetResponse> => {
  const client = new OrganizationControlsBasePathsGetClient(getConfig().apiBaseUri);
  return client.get(organizationId);
};

export const getOrganizationsResponsibleRoles = async (organizationId: string): Promise<string[]> => {
  const client = new OrganizationResponsibleRoleListClient(getConfig().apiBaseUri);
  return client.get(organizationId);
};

export const getOrganizationalFrontMatter = async (organizationId: string): Promise<OrganizationalFrontMatter> => {
  const client = new OrganizationalFrontMatterGetClient(getConfig().apiBaseUri);
  return client.get(organizationId);
};

export const postOrganizationalFrontMatter = async (
  organizationId: string,
  command: OrganizationalFrontMatterPostCommand,
): Promise<OrganizationalFrontMatter> => {
  const client = new OrganizationalFrontMatterPostClient(getConfig().apiBaseUri);
  return client.post(organizationId, command);
};

export const putOrganizationalFrontMatter = async (
  organizationId: string,
  command: OrganizationalFrontMatterPutCommand,
): Promise<OrganizationalFrontMatter> => {
  const client = new OrganizationalFrontMatterPutClient(getConfig().apiBaseUri);
  return client.put(organizationId, command);
};
