import { Panel, PanelType } from '@fluentui/react';
import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';

/**
 * Example Usage

  <BasicPane isPanelBlocking={false} panelHeader="Test">
    <div>Hello!</div>
  </BasicPane>

 */

export interface BasicPaneProps {
  isPanelBlocking: boolean;
  panelType?: PanelType;
  panelHeader?: string;
  children?: JSX.Element | JSX.Element[];
}

export const BasicPane = forwardRef((props: BasicPaneProps, ref) => {
  const { panelHeader, panelType, children, isPanelBlocking } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const togglePaneClick = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded);
  }, [setIsExpanded]);

  useImperativeHandle(ref, () => ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    show: () => {
      setIsExpanded(true);
    },
    hide: () => {
      setIsExpanded(false);
    },
  }));

  return (
    <Panel
      headerText={panelHeader}
      isOpen={isExpanded}
      isBlocking={isPanelBlocking}
      onDismiss={togglePaneClick}
      closeButtonAriaLabel="Close"
      type={panelType || PanelType.smallFixedFar}
    >
      {children}
    </Panel>
  );
});
