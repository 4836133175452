import { ActionButton, DefaultButton, Spinner, SpinnerSize, Stack, mergeStyles } from '@fluentui/react';
import { UserContext } from 'components/userProvider/userContext';
import { IAuthorizedSystem, SystemFrontMatter, SystemMetadata, SystemPutCommand } from 'generated/clientApi';
import { SspManagerNavLinks } from 'models/sspManagerNavLinks';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { updatePageChromeConfig } from 'modules/pageChrome/pageChrome';
import { createSspManagerChromeConfig } from 'modules/pageChrome/sspManagerPageChrome';
import { SystemDetailsRouteParams } from 'modules/routes/routes';
import { createSystemSnapshot, getSystem, updateSystem } from 'modules/system/system';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { HorizontalRule } from 'styles/horizontalRule';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { LoadingState } from 'models/loadingState';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { SaveButton } from 'components/saving/saveButton';
import { defaultButtonStyles } from 'styles/defaultButtonStyles';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';
import { InplaceTextField } from 'components/inplaceTextField/inplaceTextField';
import { getOrganizationalFrontMatter } from 'modules/organization/organization';
import { SystemFieldLabels } from './systemCreate';
import { SnapshotList } from './snapshotList';
import { SystemFrontMatterDetail } from './systemFrontMatterDetails';

const headerWrapperStyles = mergeStyles({
  display: 'flex',
});

const informationWrapperStyles = mergeStyles({
  display: 'flex',
  fontSize: '14px',
});

const stackWrapperStyles = mergeStyles({
  marginRight: '1em',
  flex: 1,
});

const spinnerStyle = mergeStyles({
  marginRight: '5px',
});

const saveButtonStyles = mergeStyles(primaryButtonStyles, {
  margin: '1em 0',
});

const toggleButtonStyle = mergeStyles(defaultButtonStyles, {
  margin: 'auto 0 auto auto',
  height: 'fit-content !important',
  '.ms-Button-flexContainer': {
    height: 'fit-content !important',
  },
});

export const SystemDetail: FunctionComponent = () => {
  const { systemId } = useParams<SystemDetailsRouteParams>();
  const userContext = useContext(UserContext);
  const [system, setSystem] = useState<IAuthorizedSystem>();
  const [updatedSystem, setUpdatedSystem] = useState<IAuthorizedSystem | undefined>();
  const [frontMatter, setFrontMatter] = useState<SystemFrontMatter>();
  const [cspName, setCspName] = useState<string | undefined>();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [isCreatingSnapshot, setIsCreatingSnapshot] = useState<boolean>(false);
  const [isSnapshotPanelExpanded, setIsSnapshotPanelExpanded] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isUpdatedSystemValid, setIsUpdatedSystemValid] = useState<boolean>(false);

  useEffect(() => {
    updatePageChromeConfig(createSspManagerChromeConfig(SspManagerNavLinks.Systems));
    return () => updatePageChromeConfig();
  }, []);

  useEffect(() => {
    const loadOrganizationalFrontMatter = async () => {
      try {
        if (!userContext.selectedOrganization?.id) {
          return;
        }
        const organizationalFrontMatter = await getOrganizationalFrontMatter(userContext.selectedOrganization.id);
        if (organizationalFrontMatter && organizationalFrontMatter.cspName) {
          setCspName(organizationalFrontMatter.cspName);
        }

        setLoadingState(LoadingState.Loaded);
      } catch (error) {
        setLoadingState(LoadingState.Error);
        showError('There was an issue getting the organizational front matter');
      }
    };

    loadOrganizationalFrontMatter();
  }, [userContext.selectedOrganization?.id]);

  useEffect(() => {
    const getCurrentService = async () => {
      try {
        // Probably have to change this to get the org from the system since there is no dependency
        if (!userContext.selectedOrganization?.id || !systemId) {
          // TODO: error
          setLoadingState(LoadingState.Error);
          return;
        }

        setLoadingState(LoadingState.Loading);
        const currentSystem = await getSystem(userContext.selectedOrganization?.id, systemId);
        setSystem(currentSystem);
        setLoadingState(LoadingState.Loaded);
      } catch (error) {
        setLoadingState(LoadingState.Error);
        showError('There was an issue loading the system. Please refresh and try again.');
      }
    };
    getCurrentService();
  }, [userContext.selectedOrganization?.id, systemId]);

  const onViewSnapshots = () => {
    setIsSnapshotPanelExpanded(true);
  };

  const onSnapshotCreate = async () => {
    try {
      if (!systemId) {
        showError('The system was not found and cannot be saved.');
        return;
      }

      if (!cspName) {
        showError('The CSP name must be set in the Organizational Front Matter to create a snapshot.');
        return;
      }

      if (!system?.systemFrontMatter?.csoName) {
        showError('The System Front Matter must have a CSO name to create a snapshot.');
        return;
      }

      if (!system?.systemFrontMatter?.appendixKFIPS199CategorizationTableURL) {
        showError('The System Front Matter must have a Appendix K FIPS 199 Categorization Table URL to create a snapshot.');
        return;
      }

      if (!system?.systemFrontMatter?.leveragedFEDRAMPTableURL) {
        showError('The System Front Matter must have a Leveraged FedRAMP Table URL to create a snapshot.');
        return;
      }

      setIsCreatingSnapshot(true);
      await createSystemSnapshot({ systemId });
      showSuccess('Successfully created snapshot.');
    } catch (error) {
      showError(`${error}`, undefined, true, false);
    }
    setIsCreatingSnapshot(false);
  };

  const onSaveSystem = async () => {
    try {
      setIsSaving(true);
      if (!userContext.selectedOrganization?.id || !systemId) {
        showError('The system was not found and cannot be saved.');
        return;
      }

      // Need to splice the system data and front matter data together at this point
      // This is because we have this "edit" toggle for the service
      let systemToSave = updatedSystem || system;

      if (frontMatter && systemToSave) {
        systemToSave = { ...systemToSave, systemFrontMatter: frontMatter as SystemFrontMatter };
      }

      await updateSystem(userContext.selectedOrganization.id, systemId, systemToSave as SystemPutCommand);
      setSystem(systemToSave);
      showSuccess('Successfully saved system.');
    } catch (error) {
      showError(`There was an issue saving the system. Please refresh and try again: ${error}`);
    }
    setIsSaving(false);
    setIsEditing(false);
  };

  useEffect(() => {
    setIsUpdatedSystemValid(true);

    if (!updatedSystem?.systemMetadata?.name?.trim().length) {
      setIsUpdatedSystemValid(false);
    }
  }, [updatedSystem]);

  useEffect(() => {
    if (isEditing) {
      setUpdatedSystem(system);
    } else {
      setUpdatedSystem(undefined);
    }
  }, [isEditing, system]);

  const onSystemMetadataChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, name: string, newValue?: string): void =>
    setUpdatedSystem((oldUpdatedSystem) => {
      const clone = { ...oldUpdatedSystem };
      let systemMetadata = { ...clone?.systemMetadata };
      systemMetadata = { ...systemMetadata, [name]: newValue };
      return { ...clone, systemMetadata: systemMetadata as SystemMetadata };
    });

  const updateFrontMatter = (frontMatter: SystemFrontMatter) => {
    setFrontMatter(frontMatter);
  };

  if (loadingState === LoadingState.Loading) return <CenteredProgressDots />;
  return (
    <div className={pageChromeChildStyles}>
      <div className={headerWrapperStyles}>
        <InplaceTextField
          label={SystemFieldLabels.Name}
          value={updatedSystem?.systemMetadata?.name || system?.systemMetadata?.name || ''}
          editMode={isEditing}
          onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => onSystemMetadataChange(event, 'name', newValue)}
          headerStyle
          disabled={isSaving}
        />
        <DefaultButton
          text={isEditing ? 'Cancel' : 'Edit'}
          className={toggleButtonStyle}
          iconProps={isEditing ? {} : { iconName: 'Edit' }}
          onClick={() => setIsEditing(!isEditing)}
          disabled={isCreatingSnapshot || isSaving}
        />
      </div>
      <h2>Information</h2>
      <div className={informationWrapperStyles}>
        <Stack className={stackWrapperStyles}>
          <InplaceTextField
            label={SystemFieldLabels.Organization}
            value={userContext.selectedOrganization?.name || ''}
            editMode={isEditing}
            notEditable
          />
          <InplaceTextField
            label={SystemFieldLabels.Manager}
            value={updatedSystem?.systemMetadata?.manager || system?.systemMetadata?.manager || ''}
            editMode={isEditing}
            onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
              onSystemMetadataChange(event, 'manager', newValue)
            }
            disabled={isSaving}
          />
          <InplaceTextField
            label={SystemFieldLabels.Custodian}
            value={updatedSystem?.systemMetadata?.custodian || system?.systemMetadata?.custodian || ''}
            editMode={isEditing}
            onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
              onSystemMetadataChange(event, 'custodian', newValue)
            }
            disabled={isSaving}
          />
        </Stack>
        <Stack className={stackWrapperStyles}>
          <InplaceTextField
            label={SystemFieldLabels.Authorizer}
            name="authorizer"
            value={updatedSystem?.systemMetadata?.authorizer || system?.systemMetadata?.authorizer || ''}
            editMode={isEditing}
            onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
              onSystemMetadataChange(event, 'authorizer', newValue)
            }
            disabled={isSaving}
          />
          <InplaceTextField
            label={SystemFieldLabels.AuthorizationDate}
            value={updatedSystem?.systemMetadata?.authorizationDate || system?.systemMetadata?.authorizationDate || ''}
            editMode={isEditing}
            onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
              onSystemMetadataChange(event, 'authorizationDate', newValue)
            }
            disabled={isSaving}
            isDate
          />
          <InplaceTextField
            label={SystemFieldLabels.ReauthorizationCadence}
            name="reauthorizationCadence"
            value={updatedSystem?.systemMetadata?.reauthorizationCadence || system?.systemMetadata?.reauthorizationCadence || ''}
            editMode={isEditing}
            onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
              onSystemMetadataChange(event, 'reauthorizationCadence', newValue)
            }
            disabled={isSaving}
          />
        </Stack>
        <Stack className={stackWrapperStyles}>
          <InplaceTextField
            label={SystemFieldLabels.Description}
            value={updatedSystem?.systemMetadata?.description || system?.systemMetadata?.description || ''}
            editMode={isEditing}
            isMultiline
            onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) =>
              onSystemMetadataChange(event, 'description', newValue)
            }
            disabled={isSaving}
          />
        </Stack>
      </div>
      <HorizontalRule />
      <h2>Parameters</h2>
      <InplaceTextField
        label={SystemFieldLabels.ControlCatalog}
        value={system?.systemParameters?.complianceRegime || ''}
        editMode={isEditing}
        notEditable
      />
      <InplaceTextField label={SystemFieldLabels.Cloud} value={system?.systemParameters?.platform || ''} editMode={isEditing} notEditable />
      <InplaceTextField
        label={SystemFieldLabels.ControlsBasePath}
        value={system?.systemParameters?.controlsBase || userContext.selectedOrganization?.implementationCatalogDetail?.defaultControlsBasePath || ''}
        editMode={isEditing}
        notEditable
      />
      <ActionButton
        className={actionButtonStyles}
        iconProps={{ iconName: isCreatingSnapshot ? '' : 'camera' }}
        onClick={onSnapshotCreate}
        disabled={isCreatingSnapshot}
      >
        {isCreatingSnapshot ? (
          <>
            <Spinner className={spinnerStyle} size={SpinnerSize.medium} />
            Creating snapshot
          </>
        ) : (
          <>Create snapshot</>
        )}
      </ActionButton>
      <ActionButton
        text="View Snapshots"
        className={actionButtonStyles}
        iconProps={{ iconName: 'RedEye' }}
        onClick={onViewSnapshots}
        disabled={isCreatingSnapshot}
      />
      {system?.id && (
        <SnapshotList
          systemId={system.id}
          system={system}
          isSnapshotPanelExpanded={isSnapshotPanelExpanded}
          setIsSnapshotPanelExpanded={setIsSnapshotPanelExpanded}
        />
      )}
      <SystemFrontMatterDetail system={system} updateFrontMatter={updateFrontMatter} />
      <SaveButton
        defaultText="Save changes"
        className={saveButtonStyles}
        isSaving={isSaving}
        onSave={onSaveSystem}
        disabled={isCreatingSnapshot || isSaving || (isEditing && !isUpdatedSystemValid) || (!updatedSystem && !frontMatter)}
        isPrimary={isEditing}
      />
    </div>
  );
};
