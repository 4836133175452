import { ControlMetadataListResponse, FrameworkManagementControl } from '../../generated/clientApi';
import { ControlMetadata } from './controlMetadataLanding';

export const filterMetadata = (controls: FrameworkManagementControl[], controlMetadata: ControlMetadataListResponse[]): ControlMetadata[] =>
  controls.map((control) => {
    const metadata = controlMetadata.find((metadata) => metadata.controlId === control.id);
    return {
      id: control.id,
      title: control.title,
      metadata,
    } as ControlMetadata;
  });

export const controlHasMetadata = (metadata: ControlMetadataListResponse): number | boolean | undefined =>
  metadata === undefined
    ? false
    : (metadata.internalServiceTeamNames && metadata.internalServiceTeamNames.length) ||
      (metadata.policyIds && metadata.policyIds.length) ||
      (metadata.standardOperatingProcedureNames && metadata.standardOperatingProcedureNames.length) ||
      (metadata.complianceSelfTestDocuments && metadata.complianceSelfTestDocuments.length);
