import { ILinkStyles, Link, mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import React, { useContext, useEffect, useState } from 'react';
import { ApplicationVersionInfo } from '../../models/applicationVersionInfo';
import { ConfigContext } from '../configProvider/configContext';

const footerWrapperStyles = mergeStyles({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  zIndex: 1,
  display: 'flex',
  backgroundColor: NeutralColors.black,
  color: NeutralColors.white,
});

const footerStyles = mergeStyles({
  width: '100%',
  padding: '10px',
  margin: 'auto',
  display: 'flex',
});

const alignRight = mergeStyles({
  marginLeft: 'auto',
});

const linkStyles: Partial<ILinkStyles> = {
  root: {
    marginLeft: '0.5em',
    ':focus': {
      border: `1px solid ${NeutralColors.white} !important`,
      boxShadow: 'none !important',
    },
  },
};

export interface FooterProps {
  applicationVersion: ApplicationVersionInfo;
}

export const Footer: React.FunctionComponent<FooterProps> = (props) => {
  const { applicationVersion } = props;
  const configContext = useContext(ConfigContext);
  const [privacyLink, setPrivacyLink] = useState<string>('');

  useEffect(() => {
    if (!configContext.serverConfig) {
      return;
    }

    setPrivacyLink(configContext.isInternalUser ? configContext.serverConfig?.internalPrivacyLink : configContext.serverConfig?.externalPrivacyLink);
  }, [configContext]);

  return (
    <div className={footerWrapperStyles}>
      <div className={footerStyles}>
        <span>
          &copy;
          {new Date().getFullYear()}
          &nbsp;Microsoft Corporation |
          <Link href={privacyLink} styles={linkStyles}>
            Privacy Statement
          </Link>
        </span>
        <div className={alignRight}>
          Version:&nbsp;
          {applicationVersion?.assemblyVersion}
        </div>
      </div>
    </div>
  );
};
