import {
  ActionButton,
  ContextualMenuItemType,
  DetailsList,
  IColumn,
  IContextualMenuProps,
  IDialogContentProps,
  IconButton,
  NeutralColors,
  Panel,
  PanelType,
  Spinner,
  SpinnerSize,
  TextField,
  mergeStyles,
  IContextualMenuItemProps,
  mergeStyleSets,
} from '@fluentui/react';
import { FileAttachmentIcon } from 'components/fileAttachment/fileAttachmentIcon';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { UserContext } from 'components/userProvider/userContext';
import { ApiException, IAuthorizedSystem, ISystemSnapshotListResponse } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { SupportFileExtensions } from 'models/supportedFileExtensions';
import { getFormattedDateTime } from 'modules/datetime/datetime';
import { showDialogModal } from 'modules/dialog/dialog';
import { showError } from 'modules/messageBar/messageBar';
import {
  deleteSystemSnapshot,
  downloadDisaDocument,
  downloadOscal,
  downloadRev4WordDocument,
  downloadRev5Zip,
  downloadXactaAvcDocument,
  downloadXactaMpoDocument,
  getSystemSnapshotsList,
  updateSystemSnapshotTags,
} from 'modules/system/system';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';

const spinnerWrapperStyle = mergeStyles({
  display: 'flex',
  fontWeight: 'bold',
});

const spinnerRightStyle = mergeStyles({
  marginRight: '5px',
});

const spinnerLeftStyle = mergeStyles({
  margin: '0 5px',
});

const inputStyle = mergeStyles({
  maxWidth: '750px',
  margin: '10px 10px 10px 1px',
  label: {
    fontWeight: 'bold',
  },
});

const modalBodyStyle = mergeStyles({
  margin: '20px 0',
});

const tagWrapperStyle = mergeStyles({
  display: 'flex',
  flexWrap: 'wrap',
});

const moreButtonStyles = mergeStyles({
  color: NeutralColors.black,
  '.ms-Button-menuIcon': {
    display: 'none',
  },
  ':hover': {
    backgroundColor: 'unset !important',
    color: `${NeutralColors.black} !important`,
  },
});

const tagButtonStyle = mergeStyles(primaryButtonStyles, {
  margin: '0.5em',
  '.ms-Button-flexContainer': {
    flexDirection: 'row-reverse',
  },
  '.ms-Icon': {
    color: NeutralColors.white,
  },
  ':hover': {
    '.ms-Icon': {
      color: 'lightgrey !important',
    },
  },
  '.ms-Icon:hover': {
    color: 'lightgrey !important',
  },
});

const classNames = mergeStyleSets({
  iconContainer: {
    position: 'relative',
    margin: '0 4px',
    height: 32,
    width: 14,
  },
  logoIcon: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
  logoFillIcon: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
});

const RenderWordIcon = (_props?: IContextualMenuItemProps) => (
  <span className={classNames.iconContainer}>
    <FileAttachmentIcon extensionType={SupportFileExtensions.Word} />
  </span>
);

const enum Action {
  DownloadOscal = 'Download Oscal',
  DownloadWordDocument = 'Download Word Document',
  DownloadRev4WordDocument = 'Rev4',
  DownloadRev5WordDocument = 'Rev5',
  DownloadRev5GenericWordDocument = 'Rev5 (Generic)',
  DownloadDisaControl = 'Download DISA Control Import Excel',
  DownloadXactaMpoExcel = 'Download Excel Document (Xacta MPO)',
  DownloadXactaAvcExcel = 'Download Excel Document (Xacta AVC)',
  CreateEditTags = 'Create/Edit Tags',
  Delete = 'Delete',
}

interface SnapshotListProps {
  systemId: string;
  system: IAuthorizedSystem;
  isSnapshotPanelExpanded: boolean;
  setIsSnapshotPanelExpanded(expanded: boolean): void;
}

export const SnapshotList: FunctionComponent<SnapshotListProps> = (props) => {
  const { systemId, system, isSnapshotPanelExpanded, setIsSnapshotPanelExpanded } = props;
  const [snapshotsLoadingState, setSnapshotsLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [snapshotList, setSnapshotList] = useState<ISystemSnapshotListResponse[]>([]);
  const [isSnapshotActionOccurring, setIsSnapshotActionOccurring] = useState<string | undefined>();
  const [isSnapshotTagDeleteOccurring, setIsSnapshotTagDeleteOccurring] = useState<string | undefined>();
  const userContext = useContext(UserContext);
  const columns: IColumn[] = [
    {
      key: 'snapshotDate',
      name: 'Snapshot date',
      onRender: (snapshot: ISystemSnapshotListResponse) => <>{getFormattedDateTime(snapshot.date)}</>,
      ariaLabel: 'Snapshot Date',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      key: 'alias',
      name: 'Alias',
      fieldName: 'userAlias',
      ariaLabel: 'Alias',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      key: 'tags',
      name: 'Tags',
      onRender: (snapshot: ISystemSnapshotListResponse) => (
        <div className={tagWrapperStyle}>
          {snapshot.tags?.map((tag) => (
            <ActionButton
              key={tag}
              className={tagButtonStyle}
              iconProps={{
                iconName: isSnapshotActionOccurring === snapshot.snapshotId && isSnapshotTagDeleteOccurring === tag ? '' : 'Cancel',
              }}
              onClick={() => onDeleteTag(snapshot, tag)}
              disabled={isSnapshotActionOccurring !== undefined}
            >
              {(isSnapshotActionOccurring === snapshot.snapshotId && isSnapshotTagDeleteOccurring === tag && (
                <>
                  <Spinner className={spinnerLeftStyle} size={SpinnerSize.medium} />
                  {tag}
                </>
              )) || <>{tag}</>}
            </ActionButton>
          ))}
        </div>
      ),
      ariaLabel: 'Tags',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      key: 'actions',
      name: 'Actions',
      ariaLabel: 'Actions',
      onRender: (snapshot: ISystemSnapshotListResponse) => {
        const menuProps: IContextualMenuProps = {
          items: [
            {
              key: Action.DownloadOscal,
              text: Action.DownloadOscal,
              onClick: () => {
                downloadDocument(Action.DownloadOscal, snapshot.snapshotId, getFormattedDateTime(snapshot.date));
              },
            },
            {
              key: Action.DownloadWordDocument,
              text: Action.DownloadWordDocument,
              onRenderIcon: (props?: IContextualMenuItemProps) => RenderWordIcon(props),
              subMenuProps: {
                items: [
                  {
                    key: Action.DownloadRev4WordDocument,
                    text: Action.DownloadRev4WordDocument,
                    onRenderIcon: (props?: IContextualMenuItemProps) => RenderWordIcon(props),
                    onClick: () => {
                      downloadDocument(Action.DownloadRev4WordDocument, snapshot.snapshotId, getFormattedDateTime(snapshot.date));
                    },
                  },
                  {
                    key: Action.DownloadRev5WordDocument,
                    text: Action.DownloadRev5WordDocument,
                    onRenderIcon: (props?: IContextualMenuItemProps) => RenderWordIcon(props),
                    onClick: () => {
                      downloadDocument(Action.DownloadRev5WordDocument, snapshot.snapshotId, getFormattedDateTime(snapshot.date));
                    },
                  },
                  {
                    key: Action.DownloadRev5GenericWordDocument,
                    text: Action.DownloadRev5GenericWordDocument,
                    onRenderIcon: (props?: IContextualMenuItemProps) => RenderWordIcon(props),
                    onClick: () => {
                      downloadDocument(Action.DownloadRev5GenericWordDocument, snapshot.snapshotId, getFormattedDateTime(snapshot.date));
                    },
                  },
                ],
              },
            },
            {
              key: Action.DownloadDisaControl,
              text: Action.DownloadDisaControl,
              onClick: () => {
                downloadDocument(Action.DownloadDisaControl, snapshot.snapshotId, getFormattedDateTime(snapshot.date));
              },
            },
            {
              key: Action.DownloadXactaMpoExcel,
              text: Action.DownloadXactaMpoExcel,
              onClick: () => {
                downloadDocument(Action.DownloadXactaMpoExcel, snapshot.snapshotId, getFormattedDateTime(snapshot.date));
              },
            },
            {
              key: Action.DownloadXactaAvcExcel,
              text: Action.DownloadXactaAvcExcel,
              onClick: () => {
                downloadDocument(Action.DownloadXactaAvcExcel, snapshot.snapshotId, getFormattedDateTime(snapshot.date));
              },
            },
            { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
            {
              key: Action.CreateEditTags,
              text: Action.CreateEditTags,
              onClick: () => {
                createEditTags(snapshot.snapshotId, snapshot.tags || []);
              },
            },
            {
              key: Action.Delete,
              text: Action.Delete,
              onClick: () => {
                deleteSnapshot(snapshot.snapshotId);
              },
            },
          ],
        };

        return (
          <IconButton
            iconProps={{
              iconName: isSnapshotActionOccurring === snapshot.snapshotId && !isSnapshotTagDeleteOccurring ? '' : 'More',
            }}
            className={moreButtonStyles}
            menuProps={menuProps}
          >
            {isSnapshotActionOccurring === snapshot.snapshotId && !isSnapshotTagDeleteOccurring && (
              <div className={spinnerWrapperStyle}>
                <Spinner className={spinnerRightStyle} />
              </div>
            )}
          </IconButton>
        );
      },
      minWidth: 100,
      maxWidth: 150,
    },
  ];

  useEffect(() => {
    const getSnapshots = async () => {
      try {
        setSnapshotsLoadingState(LoadingState.Loading);
        // Move this to a context?
        const snapshots = await getSystemSnapshotsList(systemId);
        setSnapshotList(
          snapshots.sort((a, b) => {
            if (a.date! > b.date!) {
              return 1;
            }
            if (a.date! < b.date!) {
              return -1;
            }
            return 0;
          }),
        );
        setSnapshotsLoadingState(LoadingState.Loaded);
      } catch (error) {
        setSnapshotsLoadingState(LoadingState.Error);
        showError('There was an issue loading the snapshots. Please refresh and try again.');
      }
    };

    getSnapshots();
  }, [systemId, isSnapshotPanelExpanded]);

  const deleteSnapshot = (snapshotId: string | undefined) => {
    const removeSnapshot = async () => {
      try {
        if (!systemId || !snapshotId) {
          return;
        }

        setIsSnapshotActionOccurring(snapshotId);
        await deleteSystemSnapshot(systemId, snapshotId);
        setSnapshotList((currentSnapshots) => currentSnapshots.filter((snapshot) => snapshot.snapshotId !== snapshotId));
        setIsSnapshotActionOccurring(undefined);
      } catch (error) {
        setIsSnapshotActionOccurring(undefined);
        showError('There was an issue loading the system. Please refresh and try again.');
      }
    };

    removeSnapshot();
  };

  const downloadDocument = (action: Action, snapshotId: string | undefined, snapshotName: string) => {
    const download = async () => {
      try {
        if (!userContext.selectedOrganization?.id || !systemId || !snapshotId || !system.systemMetadata?.name) {
          return;
        }

        setIsSnapshotActionOccurring(snapshotId);
        if (action === Action.DownloadDisaControl) {
          await downloadDisaDocument(userContext.selectedOrganization?.id, systemId, snapshotId, system.systemMetadata?.name, snapshotName);
        }
        if (action === Action.DownloadRev4WordDocument) {
          await downloadRev4WordDocument(userContext.selectedOrganization?.id, systemId, snapshotId, system.systemMetadata?.name, snapshotName);
        }
        if (action === Action.DownloadRev5WordDocument) {
          await downloadRev5Zip(userContext.selectedOrganization?.id, systemId, snapshotId, system.systemMetadata?.name, snapshotName, false);
        }
        if (action === Action.DownloadRev5GenericWordDocument) {
          await downloadRev5Zip(userContext.selectedOrganization?.id, systemId, snapshotId, system.systemMetadata?.name, snapshotName, true);
        }
        if (action === Action.DownloadOscal) {
          await downloadOscal(userContext.selectedOrganization?.id, systemId, snapshotId, system.systemMetadata?.name, snapshotName);
        }
        if (action === Action.DownloadXactaMpoExcel) {
          await downloadXactaMpoDocument(userContext.selectedOrganization?.id, systemId, snapshotId, system.systemMetadata?.name, snapshotName);
        }
        if (action === Action.DownloadXactaAvcExcel) {
          await downloadXactaAvcDocument(userContext.selectedOrganization?.id, systemId, snapshotId, system.systemMetadata?.name, snapshotName);
        }
      } catch (error) {
        if (error instanceof ApiException) {
          showError(`There was an issue when downloading the SSP. ${error.response}`);
          showError('Please correct the issue and recreate the snapshot.');
        } else {
          showError('There was an unexpected issue when downloading the SSP. Please try again.');
        }
      }
      setIsSnapshotActionOccurring(undefined);
    };

    download();
  };

  const onDeleteTag = (snapshot: ISystemSnapshotListResponse, tag: string) => {
    setIsSnapshotTagDeleteOccurring(tag);
    setIsSnapshotActionOccurring(snapshot.snapshotId);
    if (!snapshot.tags || snapshot.tags.length === 0) {
      return;
    }
    if (snapshot.tags.length === 1) {
      onUpdateTagsClick(snapshot.snapshotId, []);
      return;
    }

    const updatedTags = snapshot.tags.filter((currentTag) => currentTag !== tag);
    onUpdateTagsClick(snapshot.snapshotId, updatedTags);
  };

  const onUpdateTagsClick = (snapshotId: string | undefined, updatedTags: string[], newTag?: string) => {
    const updateTags = async () => {
      try {
        if (!snapshotId) {
          return;
        }
        setIsSnapshotActionOccurring(snapshotId);
        await updateSystemSnapshotTags({ systemId, snapshotId, tags: updatedTags });

        // TODO: Move to functions Bug #24940073
        setSnapshotList((currentSnapshots) => {
          const updatedSnapshots = [...currentSnapshots];
          const snapshotIndex = currentSnapshots.findIndex((snapshot) => snapshot.snapshotId === snapshotId);
          if (snapshotIndex === -1) {
            showError('There was an issue updating the snapshot. Please refresh and try again.');
          }

          updatedSnapshots[snapshotIndex].tags = updatedTags;
          return updatedSnapshots;
        });
      } catch (error) {
        showError('There was an issue updating the snapshots tags. Please refresh and try again.');
      }
      setIsSnapshotTagDeleteOccurring(undefined);
      setIsSnapshotActionOccurring(undefined);
    };

    if (newTag) {
      if (updatedTags.find((tag) => tag === newTag)) {
        showError('You cannot have multiple tags with the same name on a single snapshot.');
        return;
      }

      updatedTags.push(newTag);
    }
    updateTags();
  };

  const createEditTags = (snapshotId: string | undefined, incomingTags: string[]) => {
    if (!snapshotId) {
      return;
    }

    let newTag = '';
    const dialogContents: IDialogContentProps = {
      title: 'Edit Tags',
    };

    // Get the current tags and display them as clickable buttons
    const modalBody = (
      <div className={modalBodyStyle}>
        Input a new tag and click Submit.
        <TextField
          className={inputStyle}
          onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, updatedTag?: string) => {
            newTag = updatedTag?.trim() || '';
          }}
          title="Input a new tag and click Submit"
        />
      </div>
    );
    showDialogModal(dialogContents, () => onUpdateTagsClick(snapshotId, incomingTags, newTag), modalBody, undefined, 'Submit', '600px');
  };

  const onDismiss = (ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => {
    if (ev?.type !== 'mousedown') {
      setIsSnapshotPanelExpanded(false);
    }
  };

  return (
    <Panel
      isOpen={isSnapshotPanelExpanded}
      onDismiss={(ev?: React.SyntheticEvent<HTMLElement> | KeyboardEvent) => onDismiss(ev)}
      closeButtonAriaLabel="Close"
      type={PanelType.medium}
    >
      <>
        <h1>Snapshots</h1>
        {snapshotsLoadingState === LoadingState.Loading ? <CenteredProgressDots /> : <DetailsList items={snapshotList} columns={columns} />}
      </>
    </Panel>
  );
};
