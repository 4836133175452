import { PagedResultOfPoam, Poam, PoamClosedListClient } from 'generated/clientApi';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getConfig } from 'modules/config/config';
import { showError } from 'modules/messageBar/messageBar';
import {
  ScrollablePane,
  ScrollbarVisibility,
  IScrollablePaneStyles,
  SelectionMode,
  DetailsList,
  Spinner,
  SpinnerSize,
  IColumn,
} from '@fluentui/react';
import { PoamColumns, customPoamTableStyles, poamScrollPaneStyles } from './poamTable';
import { renderFixedDetailsHeader, sortByProperty } from '../components/tableUtils';
import { PoamTableColumns } from './poamTableColumns';
import { usePoamFilters } from './usePoamFilters';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

let timeoutId: NodeJS.Timeout | undefined;
const debounce = (func: (...args: any[]) => void, delay: number) => {
  const debouncedFunction = (...args: any[]) => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
  return debouncedFunction;
};

type ClosedPoamTableProps = {
  poamColumns: (keyof PoamColumns)[];
};
export const ClosedPoamTable: React.FunctionComponent<ClosedPoamTableProps> = (props) => {
  const { poamColumns } = props;
  const { organization } = useContext(ConMonFilterContext);

  const [closedPoams, setClosedPoams] = useState<PagedResultOfPoam>({
    results: [],
    continuationToken: '',
  } as unknown as PagedResultOfPoam);
  const [loading, setLoading] = useState<boolean>(true);
  const filters = usePoamFilters();

  const getClosedPoams = useCallback(
    async (replace = false) => {
      setLoading(true);
      const client = new PoamClosedListClient(getConfig().apiBaseUri);
      try {
        const response = await client.get(replace ? '' : closedPoams.continuationToken, filters.poamVulnId, organization);
        setClosedPoams((prev) => {
          if (replace) {
            return response;
          }
          return {
            results: [...(prev?.results ?? []), ...(response.results ?? [])],
            continuationToken: response.continuationToken,
          } as PagedResultOfPoam;
        });
      } catch (e) {
        showError('There was an error fetching closed poams. Please refresh to try again.');
      } finally {
        setLoading(false);
      }
    },
    [closedPoams?.continuationToken, filters.poamVulnId, organization],
  );

  useEffect(() => {
    const debouncedFetchData = debounce(() => getClosedPoams(true), 500);
    debouncedFetchData();
    return () => {
      clearTimeout(timeoutId as NodeJS.Timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.poamVulnId, organization]);

  const onScrollReachedBottom = useCallback(() => {
    if (loading === false && !!closedPoams?.continuationToken) {
      getClosedPoams();
    }
  }, [closedPoams?.continuationToken, getClosedPoams, loading]);

  const onScroll = (event: React.UIEvent<HTMLElement>): void => {
    const target = event.target as HTMLInputElement;
    if (target.scrollHeight - target.scrollTop <= 1.05 * target.clientHeight) {
      onScrollReachedBottom();
    }
  };

  const sortTable = (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc', isRiskRatingSort = false): void => {
    if (closedPoams.results && sortColumn && sortDirection) {
      setClosedPoams(
        (prev) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          ({
            ...prev,
            results: sortByProperty(prev.results as Poam[], sortColumn!.fieldName as keyof Poam, sortDirection, isRiskRatingSort),
          }) as PagedResultOfPoam,
      );
    }
  };

  const columns = PoamTableColumns({ poamColumns, sortTable });

  return (
    <div className={customPoamTableStyles} onScroll={onScroll}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={poamScrollPaneStyles as unknown as IScrollablePaneStyles}>
        <DetailsList
          items={closedPoams?.results ?? []}
          columns={columns}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={renderFixedDetailsHeader}
        />
        {loading && <Spinner label="Loading closed POA&Ms..." size={SpinnerSize.large} />}
      </ScrollablePane>
    </div>
  );
};
