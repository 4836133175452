import { mergeStyles, TextField } from '@fluentui/react';
import { CommentListView } from 'components/commentListView/commentListView';
import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react';
import { EvidenceComment } from '../../generated/clientApi';
import { SaveButton } from '../saving/saveButton';

const textFieldStyle = mergeStyles({
  marginBottom: '10px',
  width: '100%',
});

const containerStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});

const buttonContainerStyle = mergeStyles({
  textAlign: 'right',
});

export interface INotesProps {
  comments?: EvidenceComment[];
  buttonText: string;
  onSave?(input?: string): void;
  isSaving: boolean;
  disabled: boolean;
  showNotes: boolean;
  header: string;
}
export const Notes: FunctionComponent<INotesProps> = (props) => {
  const [inputText, setInputText] = useState<string>();

  const { buttonText, isSaving, onSave: onClick, disabled, comments, showNotes, header } = props;

  const handleOnSave = useCallback(() => {
    if (!onClick) {
      throw new Error('There is no callback defined for the notes button');
    }
    onClick(inputText);
  }, [inputText, onClick]);

  const onTextChange = useCallback((event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, input?: string) => {
    setInputText(input);
    return input;
  }, []);

  return (
    <>
      {showNotes && (
        <div className={containerStyle}>
          <div>
            <h2>{header}</h2>
            <CommentListView comments={comments} />
          </div>
          <div>
            <TextField multiline rows={1} onChange={onTextChange} className={textFieldStyle} disabled={disabled} placeholder="Type a new message" />
            <div className={buttonContainerStyle}>
              <SaveButton
                defaultText=""
                onSave={handleOnSave}
                isSaving={isSaving}
                disabled={disabled}
                iconProps={{ iconName: 'Send' }}
                title={buttonText}
                isPrimary={false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
