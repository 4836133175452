import { DefaultButton, Icon, IIconProps, mergeStyles, Spinner, SpinnerSize } from '@fluentui/react';
import React, { FunctionComponent } from 'react';
import { BreadcrumbItem, NavBreadcrumb } from 'components/breadcrumb/breadcrumb';
import { SaveButton } from '../saving/saveButton';
import { DeepReadonly } from '../util/deepReadonly';

const rootStyle = mergeStyles({
  padding: '8px 20px',
  display: 'flex',
  paddingBottom: '1em',
  borderBottom: 'solid',
  borderBottomColor: '#eee',
  borderBottomWidth: 'thin',
});

const iconContainerStyle = mergeStyles({
  width: '28px',
  height: '48px',
  paddingTop: '10px',
  marginRight: '10px',
  boxSizing: 'border-box',
});

const iconStyle = mergeStyles({
  width: '28px',
  height: '28px',
  fontSize: '28px',
});

const topRowContainerStyle = mergeStyles({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

const topRowTitleContainerStyle = mergeStyles({
  display: 'flex',
});

const topRowStyle = mergeStyles({
  fontSize: '24px',
  height: '32px',
  lineHeight: '28px',
  whiteSpace: 'pre',
  display: 'flex',
});

const titleTextStyle = mergeStyles({
  fontWeight: 600,
});

const subTitleRowStyle = mergeStyles({
  color: 'rgb(100, 100, 100)',
  height: '16px',
});

const topRowButtonStyle = mergeStyles({
  margin: 'auto 0 auto 40px',
});

const suffixStyle = mergeStyles({
  marginLeft: '40px',
});

export interface IBladeHeaderButtonConfig {
  buttonText: string;
  onButtonClick(): void;
  buttonDisabled?: boolean;
  title?: string;
  isBusy?: boolean;
  busyTitle?: string;
  iconProps?: IIconProps;
}

export interface IBladeHeaderProps {
  titleText?: string;
  titleTextSuffixes?: string[];
  subtitleText?: string;
  iconName?: string;
  buttonInfo?: IBladeHeaderButtonConfig;
  downloadButtonInfo?: IBladeHeaderButtonConfig;
  chatButtonInfo?: IBladeHeaderButtonConfig;
  breadcrumbs?: BreadcrumbItem[];
}

export const BladeHeader: FunctionComponent<DeepReadonly<IBladeHeaderProps>> = (props) => {
  const { titleText, titleTextSuffixes, subtitleText, iconName, buttonInfo, downloadButtonInfo, chatButtonInfo, breadcrumbs } = props;

  const saveButtonClicked = () => {
    if (buttonInfo) {
      buttonInfo.onButtonClick();
    }
  };

  const chatButtonClicked = () => {
    if (chatButtonInfo) {
      chatButtonInfo.onButtonClick();
    }
  };

  const button = (
    <SaveButton
      disabled={buttonInfo?.buttonDisabled}
      isSaving={false}
      onSave={saveButtonClicked}
      className={topRowButtonStyle}
      defaultText={buttonInfo?.buttonText || ''}
    />
  );

  const downloadButton = downloadButtonInfo?.isBusy ? (
    <Spinner size={SpinnerSize.medium} className={topRowButtonStyle} title={downloadButtonInfo?.busyTitle} />
  ) : (
    <DefaultButton
      iconProps={{ iconName: 'download' }}
      onClick={downloadButtonInfo?.onButtonClick}
      title={downloadButtonInfo?.title}
      className={topRowButtonStyle}
      text="Download"
    />
  );

  const chatButton = (
    <SaveButton
      isSaving={false}
      onSave={chatButtonClicked}
      className={topRowButtonStyle}
      defaultText={chatButtonInfo?.buttonText || ''}
      iconProps={chatButtonInfo?.iconProps as IIconProps}
      isPrimary={false}
    />
  );

  return (
    <div className={rootStyle}>
      {iconName && (
        <span className={iconContainerStyle}>
          <Icon iconName={iconName} className={iconStyle} />
        </span>
      )}
      <div className={topRowContainerStyle}>
        <div className={topRowTitleContainerStyle}>
          <div className={topRowStyle}>
            {breadcrumbs ? (
              <NavBreadcrumb breadcrumbs={breadcrumbs as BreadcrumbItem[]} />
            ) : (
              <>
                <span className={titleTextStyle}>{titleText}</span>
                {titleTextSuffixes && <span>{' > '}</span>}
                {titleTextSuffixes &&
                  titleTextSuffixes.map((titleTextSuffix, index) => (
                    <span key={titleTextSuffix} className={index > 0 ? suffixStyle : ''}>
                      {titleTextSuffix}
                    </span>
                  ))}
              </>
            )}
          </div>
          <div className={subTitleRowStyle}>{subtitleText}</div>
        </div>
        <div>
          {buttonInfo && button}
          {downloadButtonInfo && downloadButton}
          {chatButtonInfo && chatButton}
        </div>
      </div>
    </div>
  );
};
