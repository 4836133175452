import * as React from 'react';
import { FunctionComponent } from 'react';

interface ErrorLoadingProps {
  errorMessage?: string;
}

export const ErrorLoading: FunctionComponent<ErrorLoadingProps> = (props) => {
  ErrorLoading.defaultProps = {
    errorMessage: undefined,
  };
  const { errorMessage } = props;

  return (
    <>
      <h1>Sorry, there was an issue loading this page.</h1>
      {errorMessage && <h2>{errorMessage}</h2>}
    </>
  );
};
