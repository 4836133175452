import { mergeStyles } from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IWorkloadInsights, WorkloadInsights } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { getChartFormattedDate } from '../../modules/datetime/datetime';
import { ActionCenterContext } from '../actionCenterProvider/actionCenterContext';
import { ActionCenterData } from '../actionCenterProvider/actionCenterProvider';
import { ChartLegend } from '../charts/legend';
import { StackedBarChart } from '../charts/stackedBarChart';
import { CenteredProgressDots } from '../progressDots/progressDots';

const widgetStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '1em',
  margin: '0.5em',
  minWidth: '40em',
  flex: '1',
  '@media (max-width: 320px)': {
    overflowX: 'scroll',
  },
});

const headerStyle = mergeStyles({
  display: 'flex',
});

export interface ActionItemKeys {
  openActionItemsKey: string;
  closedActionItemsKey: string;
}

enum ActionItemState {
  Open = 'Open',
  Closed = 'Closed',
}

interface ChartData {
  date: string;
  count: number;
  state: ActionItemState;
}

export interface WorkloadInsightsWidgetProps {
  actionItemKeys: ActionItemKeys;
  userRoleTitle?: string;
}

export const WorkloadInsightsWidget: React.FunctionComponent<WorkloadInsightsWidgetProps> = (props) => {
  const { actionItemKeys, userRoleTitle } = props;
  const actionCenterContext = useContext(ActionCenterContext);
  const [barChartData, setBarChartData] = useState<ChartData[]>([]);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);

  useEffect(() => {
    actionCenterContext.requestContextData(ActionCenterData.WorkloadInsights);
  }, [actionCenterContext]);

  useEffect(() => {
    setIsLoading(actionCenterContext.workloadInsights.loadingState);
  }, [actionCenterContext.workloadInsights.loadingState]);

  const formatStackChartData = useCallback((): ChartData[] => {
    if (!actionCenterContext.workloadInsights.contextData) {
      return [];
    }

    const data: ChartData[] = [];

    // we only want the last 5 months of data - ensure that it is sorted and then get the last 5 items in the array
    actionCenterContext.workloadInsights.contextData.sort((insightA, insightB) =>
      insightA.targetDate.getTime() > insightB.targetDate.getTime() ? 1 : -1,
    );
    actionCenterContext.workloadInsights.contextData.slice(-5).forEach((workloadInsight: WorkloadInsights) => {
      const formattedDate = getChartFormattedDate(workloadInsight.targetDate);
      data.push({
        date: formattedDate,
        count: Number(workloadInsight[actionItemKeys.openActionItemsKey as keyof IWorkloadInsights]),
        state: ActionItemState.Open,
      });

      data.push({
        date: formattedDate,
        count: Number(workloadInsight[actionItemKeys.closedActionItemsKey as keyof IWorkloadInsights]),
        state: ActionItemState.Closed,
      });
    });

    return data;
  }, [actionItemKeys.openActionItemsKey, actionItemKeys.closedActionItemsKey, actionCenterContext.workloadInsights.contextData]);

  useEffect(() => setBarChartData(formatStackChartData()), [formatStackChartData]);

  const viewportBasedWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) * 0.7;
  const chartProps = {
    x: (d: any) => d.date,
    y: (d: any) => d.count,
    z: (d: any) => d.state,
    colors: ['#468400', '#EB0000'],
    width: viewportBasedWidth,
  };

  return (
    <div className={widgetStyle}>
      <div className={headerStyle}>
        Workload insights
        {userRoleTitle}
        <ChartLegend props={{ colors: ['#468400', '#EB0000'], keys: [ActionItemState.Open, ActionItemState.Closed] }} />
      </div>
      {isLoading !== LoadingState.Loaded ? <CenteredProgressDots /> : <StackedBarChart data={barChartData} props={chartProps} />}
    </div>
  );
};
