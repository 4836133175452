import { ServerConfig } from '../../models/serverConfig';

let configuration: ServerConfig;

export const initializeConfig = async (): Promise<ServerConfig> => {
  try {
    const configData = await fetch('/api/config');
    const serverConfig = await configData.json();
    configuration = serverConfig;
    return configuration;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Configurations failed to initialize', error);
    throw error;
  }
};

export const getConfig = (): ServerConfig => configuration;
