export enum Navigation {
  Start,
  Previous,
  Next,
  End,
}

export enum Zoom {
  In,
  Out,
}

export const getPdfControlStyles = (displayNavigation: boolean): string => {
  if (displayNavigation) {
    return 'space-between';
  }
  return 'center';
};

export const updatePageNumber = (pageNumber: number, pageCount: number, nav: Navigation): number => {
  switch (nav) {
    case Navigation.Start:
      return 1;
    case Navigation.Previous:
      if (pageNumber > 1) {
        return pageNumber - 1;
      }
      break;
    case Navigation.Next:
      if (pageNumber + 1 <= pageCount) {
        return pageNumber + 1;
      }
      break;
    case Navigation.End:
      return pageCount;
    default:
      return 0;
  }

  return pageNumber;
};

export const updateScale = (scale: number, zoom: Zoom): number => {
  if (zoom === Zoom.Out && scale === 0) {
    return scale;
  }
  if (zoom === Zoom.In) {
    return scale + 0.5;
  }
  return scale - 0.5;
};
