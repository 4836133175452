import {
  ChoiceGroup,
  DefaultButton,
  DetailsList,
  Dropdown,
  IChoiceGroupOption,
  IColumn,
  IDetailsListStyles,
  IDropdownOption,
  IStackTokens,
  PrimaryButton,
  SelectionMode,
  Stack,
  mergeStyles,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from 'components/userProvider/userContext';
import { getOrganizationControlsBases } from 'modules/organization/organization';
import { ControlImplementationPlatformType, FrameworkManagementControl } from '../../generated/clientApi';

const controlsTableStyles: Partial<IDetailsListStyles> = {
  root: {
    overflow: 'hidden',
  },
  contentWrapper: {
    maxHeight: '30vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
};

const errorTextStyle = mergeStyles({
  margin: '5px',
  color: '#a4262c',
});

const verticalGapStackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const horizontalStackTokens: IStackTokens = { childrenGap: 5 };

interface ControlMetadataExcerptProps {
  selectedControls: FrameworkManagementControl[];
  selectedControlsBasePath: string | undefined;
  selectedEnvironment: ControlImplementationPlatformType;
  onSelectControlsBasePath: React.Dispatch<React.SetStateAction<string | undefined>>;
  onSelectEnvironment: React.Dispatch<React.SetStateAction<ControlImplementationPlatformType>>;
  onCancel: () => void;
  onGenerateSspExcerpt: () => void;
}

const controlColumns: IColumn[] = [
  {
    key: 'id',
    fieldName: 'id',
    name: 'Control ID',
    ariaLabel: 'Control ID',
    onRender: (control: FrameworkManagementControl) => <>{control.id.toUpperCase()}</>,
    minWidth: 200,
    maxWidth: 250,
  },
  {
    key: 'title',
    fieldName: 'title',
    name: 'Control Name',
    ariaLabel: 'Control Name',
    minWidth: 300,
  },
];

const environmentOptions: IChoiceGroupOption[] = [
  { key: ControlImplementationPlatformType[ControlImplementationPlatformType.WW], text: 'Public' },
  { key: ControlImplementationPlatformType[ControlImplementationPlatformType.FF], text: 'Fairfax' },
  { key: ControlImplementationPlatformType[ControlImplementationPlatformType.RX], text: 'RX' },
  { key: ControlImplementationPlatformType[ControlImplementationPlatformType.EX], text: 'EX' },
];

export const ControlMetadataExcerpt: React.FunctionComponent<ControlMetadataExcerptProps> = (props: ControlMetadataExcerptProps) => {
  const userContext = useContext(UserContext);
  const {
    selectedControls,
    selectedControlsBasePath,
    selectedEnvironment,
    onSelectControlsBasePath,
    onSelectEnvironment,
    onCancel,
    onGenerateSspExcerpt,
  } = props;
  const [controlsBasePathsDropdownOptions, setControlsBasePathsDropdowmOptions] = useState<IDropdownOption[]>([]);
  const [errors, setErrors] = useState<Map<string, string>>();

  useEffect(() => {
    const loadControlsBasePaths = async () => {
      if (userContext.selectedOrganization?.id) {
        const controlBasesResponse = await getOrganizationControlsBases(userContext?.selectedOrganization?.id);
        setControlsBasePathsDropdowmOptions(
          controlBasesResponse.controlsBasePaths?.map((path: any) => ({ key: path, text: path })) ?? ([] as IDropdownOption[]),
        );
      }
    };
    loadControlsBasePaths();
  }, [userContext.selectedOrganization?.id]);

  const onControlsBasePathChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void =>
    onSelectControlsBasePath(option?.key.toString());

  const onChangeEnvironment = (_event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption) =>
    onSelectEnvironment(ControlImplementationPlatformType[option?.key as keyof typeof ControlImplementationPlatformType]);

  const onClickGenerateSspExcerpt = async () => {
    const validationErrors = new Map<string, string>();
    if (!selectedControls.length) {
      validationErrors.set('selectedControls', 'No controls selected. Please close the panel, select at least one control, and try again');
    }

    if (!selectedControlsBasePath) {
      validationErrors.set('selectedControlsBasePath', 'Please select a controls base path.');
    }

    if (validationErrors.size) {
      setErrors(validationErrors);
    } else {
      onGenerateSspExcerpt();
      onCancel();
    }
  };

  return (
    <>
      <h3>Selected Controls</h3>
      {selectedControls?.length ? (
        <DetailsList items={selectedControls} columns={controlColumns} selectionMode={SelectionMode.none} styles={controlsTableStyles} />
      ) : (
        <div>No controls selected.</div>
      )}
      <p className={errorTextStyle}>{errors?.get('selectedControls') ?? undefined}</p>
      <h3>Parameters</h3>
      <Stack tokens={verticalGapStackTokens}>
        <Dropdown
          label="Controls Base"
          options={controlsBasePathsDropdownOptions}
          onChange={onControlsBasePathChange}
          selectedKey={selectedControlsBasePath}
          placeholder="Select a controls base"
          errorMessage={errors?.get('selectedControlsBasePath') ?? undefined}
          required
        />
        <ChoiceGroup
          label="Environment"
          options={environmentOptions}
          selectedKey={ControlImplementationPlatformType[selectedEnvironment]}
          onChange={onChangeEnvironment}
          required
        />
      </Stack>
      <div>
        <Stack>
          <span>Do you still want to take this snapshot?</span>
          <Stack.Item>
            <Stack horizontal tokens={horizontalStackTokens}>
              <PrimaryButton title="Generate Service Team SSP Content" label="Generate Service Team SSP Content" onClick={onClickGenerateSspExcerpt}>
                Yes
              </PrimaryButton>
              <DefaultButton title="Cancel Generagete Service Team SSP Content" label="Cancel Generagete Service Team SSP Content" onClick={onCancel}>
                No
              </DefaultButton>
            </Stack>
          </Stack.Item>
        </Stack>
      </div>
    </>
  );
};
