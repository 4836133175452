import download from 'downloadjs';
import {
  ActionCenterMetricsGetClient,
  ActionCenterMetricsGetResponse,
  EvidencePackage,
  EvidencePackageAuditorReviewPutClient,
  EvidencePackageAuditorReviewPutCommand,
  EvidencePackageAuditorSubmitPutClient,
  EvidencePackageAuditorSubmitPutCommand,
  EvidencePackageAuthorizationAchievedPutClient,
  EvidencePackageAuthorizationAchievedPutCommand,
  EvidencePackageAuthorizingOfficialReviewPutClient,
  EvidencePackageAuthorizingOfficialReviewPutCommand,
  EvidencePackageAuthorizingOfficialSubmitPutClient,
  EvidencePackageAuthorizingOfficialSubmitPutCommand,
  EvidencePackageCommentsPutClient,
  EvidencePackageCommentsPutCommand,
  EvidencePackageDiscussionPutClient,
  EvidencePackageDiscussionPutCommand,
  EvidencePackageDomainStatusPutClient,
  EvidencePackageDomainStatusPutCommand,
  EvidencePackageGetClient,
  EvidencePackageNotesPutClient,
  EvidencePackageNotesPutCommand,
  EvidencePackagePostClient,
  EvidencePackagePostCommand,
  EvidencePackagePutClient,
  EvidencePackagePutCommand,
  EvidencePackageQueuePutClient,
  EvidencePackageQueuePutCommand,
  EvidencePackageRecallPutClient,
  EvidencePackageRecallPutCommand,
  EvidencePackagesCurrentListClient,
  EvidencePackagesCurrentListResponse,
  EvidencePackagesListClient,
  EvidencePackagesSearchGetClient,
  EvidencePackagesSearchGetResponse,
  EvidencePackageSubmissionPutClient,
  EvidencePackageSubmissionPutCommand,
  EvidencePackageZipGetClient,
  IEvidencePackage,
  IEvidencePackageAuditorReviewPutCommand,
  IEvidencePackageAuditorSubmitPutCommand,
  IEvidencePackageAuthorizationAchievedPutCommand,
  IEvidencePackageAuthorizingOfficialReviewPutCommand,
  IEvidencePackageCommentsPutCommand,
  IEvidencePackageDiscussionPutCommand,
  IEvidencePackageDomainStatusPutCommand,
  IEvidencePackageNotesPutCommand,
  IEvidencePackagePostCommand,
  IEvidencePackagePutCommand,
  IEvidencePackageQueuePutCommand,
  IEvidencePackageRecallPutCommand,
  IEvidencePackageSubmission,
  ServiceEvidencePackagesListClient,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';
import { showError } from '../messageBar/messageBar';

export const getEvidencePackages = async (): Promise<EvidencePackage[]> => {
  const client = new EvidencePackagesListClient(getConfig().apiBaseUri);
  return client.get();
};

export const searchForEvidencePackage = async (
  serviceOid: string,
  cloud?: string,
  certification?: string,
): Promise<EvidencePackagesSearchGetResponse> => {
  const client = new EvidencePackagesSearchGetClient(getConfig().apiBaseUri);
  return client.get(serviceOid, cloud, certification);
};

export const getServicesEvidencePackages = async (serviceOid: string): Promise<EvidencePackage[]> => {
  const client = new ServiceEvidencePackagesListClient(getConfig().apiBaseUri);
  return client.get(serviceOid);
};

export const getCurrentEvidencePackages = async (): Promise<EvidencePackagesCurrentListResponse[]> => {
  const client = new EvidencePackagesCurrentListClient(getConfig().apiBaseUri);
  return client.get();
};

export const getActionCenterMetrics = async (): Promise<ActionCenterMetricsGetResponse> => {
  const client = new ActionCenterMetricsGetClient(getConfig().apiBaseUri);
  return client.get();
};

export const createEvidencePackage = async (evidencePackage: IEvidencePackagePostCommand): Promise<EvidencePackage> => {
  if (evidencePackage.serviceOid === undefined) {
    throw new Error(`The parameter ${evidencePackage} must define a service.`);
  }
  const client = new EvidencePackagePostClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackagePostCommand;
  return client.post(evidencePackage.serviceOid, command);
};

export const updateEvidencePackage = async (evidencePackageId: string, evidencePackage: IEvidencePackagePutCommand): Promise<EvidencePackage> => {
  const evidencePackagePutClient = new EvidencePackagePutClient(getConfig().apiBaseUri);
  const evidencePackagePutCommand = evidencePackage as EvidencePackagePutCommand;
  evidencePackagePutCommand.evidencePackageId = evidencePackageId;
  return evidencePackagePutClient.post(evidencePackage.serviceOid, evidencePackageId, evidencePackagePutCommand);
};

export const getEvidencePackage = async (serviceOid: string, evidencePackageId: string): Promise<EvidencePackage> => {
  const serviceGetClient = new EvidencePackageGetClient(getConfig().apiBaseUri);
  return serviceGetClient.get(serviceOid, evidencePackageId);
};

export const updateSingleEvidencePackageSubmission = async (evidencePackageSubmission: IEvidencePackageSubmission): Promise<EvidencePackage> => {
  const client = new EvidencePackageSubmissionPutClient(getConfig().apiBaseUri);
  const command = { evidencePackageSubmissions: [evidencePackageSubmission] } as EvidencePackageSubmissionPutCommand;
  const response = await client.put(evidencePackageSubmission.id, evidencePackageSubmission.serviceOid, command);
  return response[0];
};

export const updateMultipleEvidencePackageSubmission = async (evidencePackageSubmissions: IEvidencePackageSubmission[]): Promise<EvidencePackage> => {
  const client = new EvidencePackageSubmissionPutClient(getConfig().apiBaseUri);
  const command = { evidencePackageSubmissions } as EvidencePackageSubmissionPutCommand;
  const response = await client.put('multiple-evidence-package-ids', 'multiple-service-oids', command);
  return response[0];
};

export const updateEvidencePackageQueue = async (evidencePackage: IEvidencePackageQueuePutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageQueuePutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageQueuePutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageRecall = async (evidencePackage: IEvidencePackageRecallPutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageRecallPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageRecallPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageNotes = async (notesCommand: IEvidencePackageNotesPutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageNotesPutClient(getConfig().apiBaseUri);
  const command = notesCommand as EvidencePackageNotesPutCommand;
  return client.put(notesCommand.id, notesCommand.serviceOid, command);
};

export const updateEvidencePackageComments = async (evidencePackage: IEvidencePackageCommentsPutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageCommentsPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageCommentsPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageDomainStatus = async (evidencePackage: IEvidencePackageDomainStatusPutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageDomainStatusPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageDomainStatusPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageDiscussion = async (evidencePackage: IEvidencePackageDiscussionPutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageDiscussionPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageDiscussionPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageAuditorSubmit = async (evidencePackage: IEvidencePackageAuditorSubmitPutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageAuditorSubmitPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageAuditorSubmitPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageAuthorizingOfficialSubmit = async (
  evidencePackage: IEvidencePackageAuditorSubmitPutCommand,
): Promise<EvidencePackage> => {
  const client = new EvidencePackageAuthorizingOfficialSubmitPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageAuthorizingOfficialSubmitPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageAuthorizationAchieved = async (
  evidencePackage: IEvidencePackageAuthorizationAchievedPutCommand,
): Promise<EvidencePackage> => {
  const client = new EvidencePackageAuthorizationAchievedPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageAuthorizationAchievedPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageAuditorReview = async (evidencePackage: IEvidencePackageAuditorReviewPutCommand): Promise<EvidencePackage> => {
  const client = new EvidencePackageAuditorReviewPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageAuditorReviewPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const updateEvidencePackageAuthorizingOfficialReview = async (
  evidencePackage: IEvidencePackageAuthorizingOfficialReviewPutCommand,
): Promise<EvidencePackage> => {
  const client = new EvidencePackageAuthorizingOfficialReviewPutClient(getConfig().apiBaseUri);
  const command = evidencePackage as EvidencePackageAuthorizingOfficialReviewPutCommand;
  return client.put(evidencePackage.id, evidencePackage.serviceOid, command);
};

export const downloadAttachments = async (evidencePackage?: IEvidencePackage): Promise<void> => {
  if (!evidencePackage) {
    showError('There was an issue downloading the evidence package attachments. Please refresh and try again.');
    return;
  }

  try {
    const fileName = `evidence_${evidencePackage.serviceOid}_${evidencePackage.certification}_${evidencePackage.cloud}.zip`;
    const client = new EvidencePackageZipGetClient(getConfig().apiBaseUri);
    const response = await client.get(evidencePackage.serviceOid, evidencePackage.id);
    download(response.data, fileName, 'application/zip');
  } catch (ex) {
    showError('There was an issue downloading the evidence package attachments. Please refresh and try again.');
  }
};
