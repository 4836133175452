import {
  AccountInfo,
  AuthenticationResult,
  BrowserAuthError,
  Configuration,
  EndSessionRequest,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { AuthType } from '../../components/authProvider/authContext';
import { getUser } from '../../modules/user/user';
import { CcmUser } from '../../generated/clientApi';
import { ServerConfig } from '../../models/serverConfig';
import { getConfig, initializeConfig } from '../config/config';
import { logDebug, logError, logInfo, logWarning } from '../logging/logging';
import { landingRoute } from '../routes/routes';

export const AUTH_TYPE_KEY = 'AUTH_TYPE';

const ACCESS_TOKEN_KEY = 'accessToken';

let isAcquireTokenSilentRequested = false;

let MSAL_CONFIG: Configuration;

let msalInstance: PublicClientApplication;

let currentAccount: AccountInfo | null;

let config: ServerConfig;

export const initializeAuth = async (): Promise<PublicClientApplication> => {
  config = getConfig();
  if (!config) {
    await initializeConfig();
  }
  config = getConfig();

  MSAL_CONFIG = {
    auth: {
      clientId: config.clientId,
      authority: new URL(`/${config.tenantId}`, config.instance).href,
      redirectUri: landingRoute(),
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }

          switch (level) {
            case LogLevel.Error:
              logError(message, new Error(message));
              return;
            case LogLevel.Verbose:
              logDebug(message);
              return;
            case LogLevel.Warning:
              logWarning(message);
              return;
            default:
              logInfo(message);
          }
        },
      },
    },
  };

  msalInstance = new PublicClientApplication(MSAL_CONFIG);
  return msalInstance;
};

export const login = async (tenantId?: string, forceInteractive? : boolean): Promise<void> => {
  const loginRedirectRequest = {
    redirectStartPage: window.location.href,
    scopes: [`api://${MSAL_CONFIG.auth.clientId}/default`],
  } as RedirectRequest;

  // Request a token for a specific tenant, as opposed to the default server configuration, which is likely
  // "organizations" that tells AAD to use the user's home tenant
  if (tenantId !== undefined) {
    loginRedirectRequest.authority = new URL(`/${tenantId}`, config.instance).href;
  }

  try {
    const tokenResponse = await msalInstance.handleRedirectPromise();
    if (!tokenResponse) {
      const account = await getAccount();
      if (!account || forceInteractive) {
        // No user signed in
        await msalInstance.loginRedirect(loginRedirectRequest);
      } else {
        // user is already signed in, acquire token
        await attemptToAcquireTokenSilently(tenantId || config.tenantId, forceInteractive);
      }
    } else {
      handleResponse(tokenResponse);
    }
  } catch (err) {
    logError('login handleRedirectPromise :', err);
  }
};

const attemptToAcquireTokenSilently = async (tenantId?: string, allowInteractive = true): Promise<boolean> => {
  // if silent refresh fails then we will do interactive login
  if (!isAcquireTokenSilentRequested) {
    isAcquireTokenSilentRequested = true;
    try {
      const account = await getAccount();
      // silent flow
      if (account) {
        const silentRequest: SilentRequest = {
          scopes: [`api://${MSAL_CONFIG.auth.clientId}/default`],
          account: account as AccountInfo,
          forceRefresh: false,
        };

        // Request a token for a specific tenant, as opposed to the tenant last used
        if (tenantId !== undefined) {
          silentRequest.authority = new URL(`/${tenantId}`, config.instance).href;
        }
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        if (response) {
          await handleResponse(response);
          isAcquireTokenSilentRequested = false;
          return true;
        }
      } else {
        // interactive flow
        if (allowInteractive) {
          await login(undefined, true);
          return true;
        }
        return false;
      }
    } catch (error) {
      logError('An error occurred while attempting to silently acquire an authentication token', error);
      if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
        // interactive flow
        await login(undefined, true);
        return true;
      }
      isAcquireTokenSilentRequested = false;
    }
  }

  return false;
};

export const logout = async (): Promise<void> => {
  if (getAuthType() === AuthType.AAD_TOKEN_AUTH) {
    const logOutRequest: EndSessionRequest = {
      account: currentAccount,
    };
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(AUTH_TYPE_KEY);
    await msalInstance.logoutRedirect(logOutRequest);
  }
  localStorage.removeItem(AUTH_TYPE_KEY);
  window.location.replace('/');
};

export const getAccount = async (): Promise<AccountInfo | CcmUser | null> => {
  if (getAuthType() === AuthType.CERT_AUTH) {
    const user = await getUser();
    return user;
  }

  if (!msalInstance) {
    await initializeAuth();
  }

  const currentAccounts = msalInstance.getAllAccounts();

  if (currentAccounts === null || currentAccounts.length < 1) {
    return null;
  }

  if (currentAccounts.length > 1) {
    return currentAccounts[0];
  }

  if (currentAccounts.length === 1) {
    return currentAccounts[0];
  }

  return null;
};

export const getAccessToken = async (): Promise<string | null> => {
  switch (getAuthType()) {
    case AuthType.AAD_TOKEN_AUTH:
      // this will retrieve latest token from cache, if not found will retrieve with interactive login
      await attemptToAcquireTokenSilently(config.tenantId);
      return localStorage.getItem(ACCESS_TOKEN_KEY)
    default:
      return null;
  }
};

const handleResponse = async (response: AuthenticationResult): Promise<void> => {
  if (response !== null) {
    currentAccount = response.account;
    localStorage.setItem(ACCESS_TOKEN_KEY, response.accessToken);
    localStorage.setItem(AUTH_TYPE_KEY, AuthType.AAD_TOKEN_AUTH);
  }
};

export const getAuthType = (): AuthType => {
  switch (localStorage.getItem(AUTH_TYPE_KEY)) {
    case AuthType.UNKNOWN:
      return AuthType.UNKNOWN;
    case AuthType.CERT_AUTH:
      return AuthType.CERT_AUTH;
    case AuthType.AAD_TOKEN_AUTH:
      return AuthType.AAD_TOKEN_AUTH;
    default:
      return AuthType.UNKNOWN;
  }
};

export const setAuthType = (authType: AuthType): void => {
  localStorage.setItem(AUTH_TYPE_KEY, authType);
};
