import { createContext } from 'react';
import { CcmUser } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';

export interface UsersContextProps {
  users: CcmUser[];
  usersLoadingState: LoadingState;
  requestUsers: () => void;
  updateUsers: () => void;
}

export const UsersContext = createContext<UsersContextProps>({
  users: [],
  usersLoadingState: LoadingState.NotLoaded,
  requestUsers: (): void => {
    // do nothing for the base case
  },
  updateUsers: (): void => {
    // do nothing for the base case
  },
});

export const UsersConsumer = UsersContext.Consumer;
