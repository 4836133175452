import { useQueryParameters } from 'components/util/useQueryParameters';
import React from 'react';

export type CoverageFilters = {
  serviceTreeName: string;
  percentCoverage: string;
  assetType: string;
  exclude: boolean;
  period: string;
  cloud: string;
};

/**
 * Parses URL and converts query params into a filter object, using default values where necessary.
 * @param value this can be anything you want to compare across renders
 */
export const useCoverageFilters = (currentPeriod?: string): CoverageFilters => {
  const queryParams = useQueryParameters();
  const exclude = (queryParams.exclude ?? false) as boolean;
  const period = (queryParams.period as string) ?? currentPeriod;
  const cloud = queryParams.cloud as string;
  const assetType = queryParams.assetType as string;
  const percentCoverage = queryParams.percentCoverage as string;
  const serviceTreeName = queryParams.serviceTreeName as string;

  return React.useMemo(
    () => ({
      exclude,
      period,
      cloud,
      assetType,
      percentCoverage,
      serviceTreeName,
    }),
    [assetType, cloud, exclude, percentCoverage, period, serviceTreeName],
  );
};
