import {
  IOffering,
  IOfferingService,
  OfferingsListClient,
  OfferingStatusGetClient,
  IOfferingServiceReviewListResponse,
  OfferingServiceReviewListClient,
  OfferingSubmissionDetailsListClient,
  IOfferingSubmissionDetailsListResponse,
  OfferingSubmissionDetailsListCommand,
  OfferingsByServiceOidListClient,
  Offering,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getOfferings = async (): Promise<IOffering[]> => {
  const offeringsGetClient = new OfferingsListClient(getConfig().apiBaseUri);
  return offeringsGetClient.get();
};

export const getOfferingStatus = async (offering: string, cloud: string, certification: string): Promise<IOfferingService[]> => {
  const offeringsGetClient = new OfferingStatusGetClient(getConfig().apiBaseUri);
  return offeringsGetClient.get(offering, cloud, certification);
};

export const getOfferingServiceReviews = async (
  offeringOid: string,
  cloud: string,
  certification: string,
): Promise<IOfferingServiceReviewListResponse> => {
  const offeringServiceReviewClient = new OfferingServiceReviewListClient(getConfig().apiBaseUri);
  return offeringServiceReviewClient.get(offeringOid, cloud, certification);
};

export const getOfferingSubmissionDetailsList = async (
  serviceOids: string[],
  cloud: string,
  certification: string,
  offeringOid: string,
): Promise<IOfferingSubmissionDetailsListResponse> => {
  const offeringSubmissionDetailsList = new OfferingSubmissionDetailsListClient(getConfig().apiBaseUri);
  const offeringSubmissionDetailsListCommand = {
    serviceOids,
    cloud,
    certification,
  } as OfferingSubmissionDetailsListCommand;
  return offeringSubmissionDetailsList.get(cloud, certification, offeringOid, offeringSubmissionDetailsListCommand);
};

export const getOfferingsByServiceOid = async (serviceOid: string): Promise<Offering[]> => {
  const offeringsByServiceOidListClient = new OfferingsByServiceOidListClient(getConfig().apiBaseUri);
  return offeringsByServiceOidListClient.get(serviceOid);
};
