import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { useMtacIsAuthenticated } from '../../hooks/useMtacIsAuthenticated';
import { ApiException, Authorizations } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { getEnvironmentAuthorizations } from '../../modules/environmentAuthorization/environmentAuthorization';
import { getFailedToLoadUi } from '../../modules/loading/loading';
import { logError } from '../../modules/logging/logging';
import { AuthContext } from '../authProvider/authContext';
import { CenteredProgressDots } from '../progressDots/progressDots';
import { ConfigContext, ConfigContextProps } from './configContext';
import { SITE_WIDE_SUBJECT, INTERNAL_USER } from '../../modules/constants';
import { ServerConfig } from '../../models/serverConfig';
import { getConfig } from '../../modules/config/config';

export const ConfigProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [environmentAuthorizations, setEnvironmentAuthorizations] = useState<Authorizations[]>([]);
  const [isInternalUser, setIsInternalUser] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [serverConfig, setServerConfig] = useState<ServerConfig>();
  const [shouldRetryEnvironmentFetch, setShouldRetryEnvironmentFetch] = useState<boolean>(false);
  const authContext = useContext(AuthContext);
  const isUserAuthenticated = useMtacIsAuthenticated();

  const contextProps: ConfigContextProps = {
    environmentAuthorizations,
    isInternalUser,
    serverConfig,
  };

  useEffect(() => {
    const config = getConfig();
    if (!config) {
      return;
    }
    setServerConfig(config);
  }, [serverConfig]);

  useEffect(() => {
    const getEnvironmentAuthorizationsTask = async () => {
      try {
        const environmentAuthorizations = await getEnvironmentAuthorizations();
        setEnvironmentAuthorizations(environmentAuthorizations.authorizations);
        setIsInternalUser(() => authContext.isAuthorized([{ operation: INTERNAL_USER, subject: SITE_WIDE_SUBJECT }]));
        setLoadingState(LoadingState.Loaded);
      } catch (error) {
        if ( error  instanceof ApiException && (error.status === 401 || error.status === 403) && isUserAuthenticated) {
          setLoadingState(LoadingState.NotLoaded);
          // we are seeing 403/401s due to msal returning cached token, so retry
          // transient error, retry fetching environment authorizations
          setTimeout(() => {
            setShouldRetryEnvironmentFetch(!shouldRetryEnvironmentFetch);
          }, 5000);
        } else {
          setLoadingState(LoadingState.Error);
        }
        logError('There was an issue loading the configuration data', error);
      }
    };

    if (isUserAuthenticated && loadingState === LoadingState.NotLoaded) {
      setLoadingState(LoadingState.Requested);
      getEnvironmentAuthorizationsTask();
    }
  }, [isUserAuthenticated, authContext, loadingState, serverConfig, environmentAuthorizations, shouldRetryEnvironmentFetch]);

  const getUiFromState = () => {
    switch (loadingState) {
      case LoadingState.Loaded:
      case LoadingState.Loading:
        return <ConfigContext.Provider value={contextProps}>{children}</ConfigContext.Provider>;
      case LoadingState.Error:
        return getFailedToLoadUi();
      case LoadingState.NotLoaded:
      default:
        return <CenteredProgressDots />;
    }
  };

  return getUiFromState();
};
