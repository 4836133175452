import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import React, { FunctionComponent, useContext } from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import { Loading } from 'components/loading/loading';
import { useMtacIsAuthenticated } from 'hooks/useMtacIsAuthenticated';
import { IMtacClaim } from '../../generated/clientApi';
import { showWarning } from '../../modules/messageBar/messageBar';
import { AccessDenied } from '../../pages/accessDenied/accessDenied';
import { AuthContext } from '../authProvider/authContext';

export interface ProtectedRouteProps extends RouteProps {
  claims: IMtacClaim[];
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ children, claims, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { inProgress } = useMsal();
  const isAuthenticated = useMtacIsAuthenticated();

  const renderFunc = (routeProps: RouteComponentProps<any>) => {
    const paramFilledClaims = claims.map((claim) => {
      const filledSubject = claim.subject.startsWith('$') ? routeProps.match.params[claim.subject.substr(1)] : claim.subject;
      return { operation: claim.operation, subject: filledSubject } as IMtacClaim;
    });

    // User is authenticated, check if Authorized. If not redirect to Access denied route
    if (inProgress !== InteractionStatus.None && isAuthenticated && !authContext.isAuthorized(paramFilledClaims)) {
      if (claims.length > 0) {
        showWarning(
          `This page requires the following operations: ${claims.map((claim) => claim.operation).join(',')}. Please contact your administrator.`,
        );
      }
      return <AccessDenied />;
    }

    // If childNodes have rendered return them
    return children || <Loading />;
  };

  return isAuthenticated ? <Route {...rest} render={renderFunc} /> : <></>;
};
