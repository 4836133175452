import { Link, mergeStyles } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { BasicList, ListColumn, ListRow } from '../../components/basicList/basicList';
import { BladeHeader } from '../../components/bladeHeader/bladeHeader';
import { AtoAOrganization } from '../../generated/clientApi';
import { organizationDetailsRoute } from '../../modules/routes/routes';
import { getUserOrganizations } from '../../modules/user/user';
import { HorizontalRule } from '../../styles/horizontalRule';

const containerStyles = mergeStyles({
  padding: '1em',
});

const columns: ListColumn[] = [
  {
    key: 'name',
    name: 'Name',
    minWidth: 50,
    maxWidth: 150,
    onRender: (organization: AtoAOrganization) =>
      organization.organizationId ? (
        <Link href={organizationDetailsRoute(organization.organizationId)}>{organization.name}</Link>
      ) : (
        <span>{organization.name}</span>
      ),
  },
  {
    key: 'platform',
    name: 'Platform',
    minWidth: 50,
    maxWidth: 150,
    onRender: (organization: AtoAOrganization) => <span>{organization.platformLabel}</span>,
  },
  {
    key: 'implementationCatalog:',
    name: 'Implementation Catalog',
    minWidth: 50,
    maxWidth: 150,
    onRender: (organization: AtoAOrganization) =>
      organization.implementationCatalogDetail?.repoAbsoluteUrl ? (
        <Link href={organization.implementationCatalogDetail?.repoAbsoluteUrl}>{organization.implementationCatalogDetail?.title}</Link>
      ) : (
        <span>{organization.implementationCatalogDetail?.title}</span>
      ),
  },
];

export const Organizations: FunctionComponent = () => {
  const [organizations, setOrganizions] = useState([] as AtoAOrganization[]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const organizations = await getUserOrganizations();
      setOrganizions(organizations);
    };
    fetchOrganizations();
  }, []);

  return (
    <div className={containerStyles}>
      <BladeHeader titleText="Organizations" />
      <HorizontalRule />
      <BasicList items={organizations as ListRow[]} columns={columns} />
    </div>
  );
};
