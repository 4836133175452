import { mergeStyles } from "@fluentui/react";
import { useBoolean } from "@uifabric/react-hooks";
import { FileAttachmentComponent, ProgressStatus } from "components/fileAttachment/fileAttachment";
import { FileAttachmentTable, nameColumnStyle } from "components/fileAttachment/fileAttachmentTable";
import { FileAttachment } from "generated/clientApi";
import { EVIDENCE_LIBRARY } from "modules/constants";
import { createServiceAttachment, getServiceAttachments } from "modules/fileAttachment/fileAttachment";
import React, { FunctionComponent, useCallback, useEffect, useState } from "react";

const pageStyle = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5em',
  });

export const EvidenceLibraryPage : FunctionComponent = () => 
    {
        const [fileAttachments, setFileAttachments] = useState<FileAttachment[]>();
        const [progressStatus, setProgressStatus] = useState<ProgressStatus>();
        const [isUploadInProgress, { setTrue: setUploadInProgress, setFalse: setUploadComplete }] = useBoolean(false);

        const progressStatusCallback = (loaded: number, total: number): void => {
            setProgressStatus({ loaded, total });
          };

        const onFileChange = useCallback(
            async (file: File) => {
              setUploadInProgress();
              const fileAttachment = await createServiceAttachment(file, EVIDENCE_LIBRARY, undefined, undefined, file.type, false, progressStatusCallback);
              const copiedFileAttachments = [...(fileAttachments || [])];
              copiedFileAttachments.push(fileAttachment);
              setProgressStatus(undefined);
              setUploadComplete();
              setFileAttachments(copiedFileAttachments);
            },
            [setUploadInProgress, fileAttachments, setFileAttachments, setUploadComplete],
          );
        
          const getAttachments = async () => {
            setProgressStatus({ loaded: 0, total: 0 });
            const attachments = await getServiceAttachments(EVIDENCE_LIBRARY, undefined, undefined);
            setFileAttachments(attachments);
            };

          useEffect(() => { 
            getAttachments(); 
          }, []);

        return (
            <div className={pageStyle}>
      <h1>Evidence Library Dashboard</h1>
      <FileAttachmentComponent
        progressStatus={progressStatus}
        allowedExtensions={['.doc', '.docx', '.pdf', '.xls', '.xlsx', '.tm7', '.ppt', '.pptx', '.png', '.jpg', '.visio']}
        onFileChange={onFileChange}
        disableUpload={isUploadInProgress}
      />
      <FileAttachmentTable 
        serviceOid={EVIDENCE_LIBRARY} 
        fileAttachments={fileAttachments || []} 
        downloadDisabled={isUploadInProgress} 
        showDelete 
        deleteDisabled={isUploadInProgress}
        onFileChange={getAttachments}
        columns={[
            {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            ariaLabel: 'Name',
            onRender: (fileAttachment: FileAttachment) => (
                <div className={nameColumnStyle}>{fileAttachment.name}</div>
            ),
            },
            {
            key: 'owner',
            name: 'Owner',
            fieldName: 'owner',
            minWidth: 100,
            ariaLabel: 'Modified by',
            },
            {
            key: 'fileSize',
            name: 'File Size',
            fieldName: 'size',
            minWidth: 150,
            ariaLabel: 'File Size',
            }
      ]}/>
    </div>
  )}