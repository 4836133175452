export const Domains = {
  SERVICE_TREE_PROPERTIES: 'ServiceTreeProperties',
  INCIDENT_MANAGEMENT: 'IncidentManagement',
  BCDR: 'BCDR',
  RELEASE_MANAGEMENT: 'ReleaseManagement',
  PRIVACY: 'Privacy',
  DATA_FLOW_DIAGRAM: 'DataFlowDiagram',
  THREAT_MODEL: 'ThreatModel',
  SDL: 'SDL',
  TRAINING: 'Training',
  KEY_VAULT_AND_JIT_ACCESS: 'KeyVaultAndJitAccess',
  INVENTORY_AND_PAVC: 'InventoryAndPAVC',
  MDS_LOGS: 'MDSLogs',
  GENEVA_ASM: 'GenevaASM',
  PACKAGE_NOTES: 'PackageNotes',
};

export const domainMap = new Map<string, string>();
domainMap.set(Domains.SERVICE_TREE_PROPERTIES, 'Service Tree Properties');
domainMap.set(Domains.INCIDENT_MANAGEMENT, 'Incident Management');
domainMap.set(Domains.BCDR, 'BCDR');
domainMap.set(Domains.RELEASE_MANAGEMENT, 'Release Management');
domainMap.set(Domains.PRIVACY, 'Privacy');
domainMap.set(Domains.DATA_FLOW_DIAGRAM, 'Data Flow Diagram');
domainMap.set(Domains.THREAT_MODEL, 'Threat Model');
domainMap.set(Domains.SDL, 'Secure Development Lifecycle');
domainMap.set(Domains.TRAINING, 'Training');
domainMap.set(Domains.KEY_VAULT_AND_JIT_ACCESS, 'JIT Access');
domainMap.set(Domains.INVENTORY_AND_PAVC, 'Inventory and Patch, Antivirus, Vulnerability and Configuration');
domainMap.set(Domains.MDS_LOGS, 'Monitoring & Diagnostics Service Logs');
domainMap.set(Domains.GENEVA_ASM, 'Geneva Azure Security Monitoring and Antimalware');
domainMap.set(Domains.PACKAGE_NOTES, 'Package Notes');

export const additionalDomainInfoMap = new Map<string, string>();
additionalDomainInfoMap.set(Domains.RELEASE_MANAGEMENT, 'releaseId');
additionalDomainInfoMap.set(Domains.SDL, 'comments');
additionalDomainInfoMap.set(Domains.TRAINING, 'comments');
additionalDomainInfoMap.set(Domains.KEY_VAULT_AND_JIT_ACCESS, 'comments');
additionalDomainInfoMap.set(Domains.GENEVA_ASM, 'comments');
additionalDomainInfoMap.set(Domains.MDS_LOGS, 'mdsAccount');
additionalDomainInfoMap.set(Domains.INVENTORY_AND_PAVC, 'dependencyServices');
additionalDomainInfoMap.set(Domains.THREAT_MODEL, 'attachment');
