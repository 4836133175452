import { mergeStyles } from '@fluentui/react';

export const iconWrapperStyle = mergeStyles({
  display: 'flex',
  height: '100%',
});

export const iconGreenStyle = mergeStyles({
  margin: 'auto',
  color: '#107c10',
  cursor: 'pointer',
});

export const iconRedStyle = mergeStyles({
  margin: 'auto',
  color: '#d83b01',
  cursor: 'pointer',
});

export const iconYellowStyle = mergeStyles({
  margin: 'auto',
  color: '#ffb900',
  cursor: 'pointer',
});

export const iconGreyStyle = mergeStyles({
  margin: 'auto',
  color: 'grey',
  cursor: 'pointer',
});
