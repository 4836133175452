import React, { FunctionComponent } from 'react';
import { SupportFileExtensions } from 'models/supportedFileExtensions';
import { Icon, Image, ImageFit } from '@fluentui/react';
import wordLogo from '../../assets/wordLogo.svg';
import excelLogo from '../../assets/excelLogo.svg';
import pdfLogo from '../../assets/pdfLogo.svg';

interface ImgIconProps {
  src: string;
  alt: string;
}

export interface FileAttachmentIconProps {
  extensionType: string;
  imageStyles?: string;
}

export const FileAttachmentIcon: FunctionComponent<FileAttachmentIconProps> = (props) => {
  const { extensionType, imageStyles } = props;
  let imgIconProps: ImgIconProps;

  if (extensionType === SupportFileExtensions.Word) {
    imgIconProps = {
      src: wordLogo,
      alt: 'Word product icon',
    };
  } else if (extensionType === SupportFileExtensions.Excel) {
    imgIconProps = {
      src: excelLogo,
      alt: 'Excel product icon',
    };
  } else if (extensionType === SupportFileExtensions.Pdf) {
    imgIconProps = {
      src: pdfLogo,
      alt: 'Pdf product icon',
    };
  } else {
    return <Icon iconName="ReportWarning" />;
  }

  return <Image className={imageStyles} src={imgIconProps.src} alt={imgIconProps.alt} imageFit={ImageFit.contain} />;
};
