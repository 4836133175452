import { DomainEvidence, IEvidencePackage } from '../../generated/clientApi';
import { domainMap } from '../../models/domainConstants';
import { ReviewType } from '../../pages/evidenceDetail/menuItems/evidenceDetailReview';
import { CommentAccordianData, CommentData } from '../../pages/evidenceDetail/menuItems/evidenceDetailReview.functions';
import { IExtendedGroup } from './commentSummary';

export const scoreColumnWidth = 100;

export const getCommentsAccordianData = (commentDatas: CommentData[]): CommentAccordianData[] => {
  const commentAccordianData: CommentAccordianData[] = [];
  commentDatas.forEach((commentData) => {
    if (commentData.DOD) {
      commentAccordianData.push({
        domain: commentData.domain,
        authorizingOfficialOrganization: 'Department of Defense',
        comments: commentData.DOD,
      });
    }
    if (commentData.DHS) {
      commentAccordianData.push({
        domain: commentData.domain,
        authorizingOfficialOrganization: 'Department of Homeland Security',
        comments: commentData.DHS,
      });
    }
    if (commentData.GSA) {
      commentAccordianData.push({
        domain: commentData.domain,
        authorizingOfficialOrganization: 'General Services Administration',
        comments: commentData.GSA,
      });
    }
    if (commentData.comments) {
      commentAccordianData.push({
        domain: commentData.domain,
        authorizingOfficialOrganization: 'Auditor Comment',
        comments: commentData.comments,
      });
    }
  });

  return commentAccordianData;
};

export const getCommentCountForDomain = (domain: string, commentData: CommentData[]): number => {
  const domainCommentData = commentData.find((x: any) => x.domain === domain);
  if (!domainCommentData) {
    return 0;
  }
  let count = 0;
  if (domainCommentData.DHS) {
    count++;
  }
  if (domainCommentData.DOD) {
    count++;
  }
  if (domainCommentData.GSA) {
    count++;
  }
  if (domainCommentData.comments) {
    count++;
  }
  return count;
};

// getGroups creates the group header data for the comment summary detailsList.  It must maintain the startIndex and count for each group.
export const getGroups = (commentData: CommentData[], evidencePackage: IEvidencePackage | undefined, reviewType: string): IExtendedGroup[] =>
  commentData.reduce((accumulator: IExtendedGroup[], data: CommentData, index: number) => {
    if (!evidencePackage) {
      return [];
    }

    const startIndex = index === 0 ? 0 : accumulator[index - 1].startIndex + getCommentCountForDomain(commentData[index - 1].domain, commentData);
    const scoreText =
      reviewType === ReviewType.AUDITOR_REVIEW
        ? evidencePackage.evidenceDomains?.find((x: DomainEvidence) => domainMap.get(x.name) === data.domain)?.auditorDomainReviewStatus.status
        : evidencePackage.evidenceDomains?.find((x: DomainEvidence) => domainMap.get(x.name) === data.domain)?.authorizingOfficialDomainReviewStatus
            .status;
    const newGroup: IExtendedGroup = {
      key: data.domain,
      name: data.domain,
      startIndex,
      count: getCommentCountForDomain(data.domain, commentData),
      level: 0,
      scoreText,
    };

    return [...accumulator, newGroup];
  }, []);
