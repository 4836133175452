import { IconButton, Link, mergeStyles } from '@fluentui/react';
import { ISortableFilterableListColumn } from 'components/searchableFilterableList/searchableFilterableList';
import { UserContextProps } from 'components/userProvider/userContext';
import { AuthorizedSystem } from 'generated/clientApi';
import { systemDetailRoute } from 'modules/routes/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const linkStyle = {
  textDecoration: 'none',
};

const columnCenteredStyle = mergeStyles({
  display: 'flex',
  width: 'fit-content',
  margin: 'auto',
  cursor: 'pointer',
});

export const getSystemColumns = (
  userContext: UserContextProps,
  userAuthorizedToDeleteSystems: boolean,
  platforms: string[],
  regimes: string[],
  controlBases: string[],
  confirmSystemDelete: (systemIds: string[]) => void,
): ISortableFilterableListColumn[] => [
  {
    key: 'name',
    name: 'Name',
    getFieldValue: (item: AuthorizedSystem) => item.systemMetadata?.name || '',
    onRender: (item: AuthorizedSystem) => (
      <Link as={RouterLink} to={systemDetailRoute({ systemId: item.id })} style={linkStyle}>
        {item.systemMetadata?.name}
      </Link>
    ),
    minWidth: 50,
    isRowHeader: true,
  },
  {
    key: 'cloud',
    name: 'Cloud',
    isFilterable: true,
    customFilterValues: platforms,
    getFieldValue: (item: AuthorizedSystem) => item.systemParameters?.platform || '',
    onRender: (item: AuthorizedSystem) => <>{item.systemParameters?.platform}</>,
    getFilterValue: (item: any) => item.systemParameters?.platform,
    minWidth: 100,
  },
  {
    key: 'complianceRegime',
    name: 'Compliance Regime',
    isFilterable: true,
    customFilterValues: regimes,
    getFieldValue: (item: AuthorizedSystem) => item.systemParameters?.complianceRegime || '',
    onRender: (item: AuthorizedSystem) => <>{item.systemParameters?.complianceRegime}</>,
    getFilterValue: (item: any) => item.systemParameters?.complianceRegime,
    minWidth: 100,
  },
  {
    key: 'controlsBase',
    name: 'Controls Base',
    isFilterable: true,
    customFilterValues: controlBases,
    getFieldValue: (item: AuthorizedSystem) =>
      item.systemParameters?.controlsBase ?? userContext.selectedOrganization?.implementationCatalogDetail?.defaultControlsBasePath ?? '',
    onRender: (item: AuthorizedSystem) => (
      <>{item.systemParameters?.controlsBase ?? userContext.selectedOrganization?.implementationCatalogDetail?.defaultControlsBasePath}</>
    ),
    getFilterValue: (item: any) =>
      item.systemParameters?.controlsBase || userContext.selectedOrganization?.implementationCatalogDetail?.defaultControlsBasePath,
    minWidth: 100,
  },
  {
    key: 'description',
    name: 'Description',
    getFieldValue: (item: AuthorizedSystem) => item.systemMetadata?.description || '',
    onRender: (item: AuthorizedSystem) => <>{item.systemMetadata?.description}</>,
    minWidth: 100,
  },
  {
    key: 'delete',
    name: '',
    onRender: (system: AuthorizedSystem) => {
      if (!userAuthorizedToDeleteSystems) {
        return <></>;
      }
      return (
        <IconButton
          iconProps={{ iconName: 'Delete' }}
          role="button"
          className={columnCenteredStyle}
          aria-label="Delete System"
          title="Delete System"
          onClick={() => system.id && confirmSystemDelete([system.id])}
        />
      );
    },
    minWidth: 100,
  },
];
