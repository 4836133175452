import { Icon, IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import { ActionCenterContext } from 'components/actionCenterProvider/actionCenterContext';
import { ActionCenterData } from 'components/actionCenterProvider/actionCenterProvider';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { LoadingState } from 'models/loadingState';
import React, { useContext, useEffect, useState } from 'react';
import { MetricLabel } from './metricLabel';

const widgetStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '1em',
  margin: '0.5em',
  maxWidth: '70em',
  display: 'flex',
  flexDirection: 'column',
});

const containerStyle = mergeStyles({
  padding: '2.5em 0.5em 0 1.5em',
});

const labelStyle = mergeStyles({
  paddingLeft: '0.3em',
  fontSize: '1.5em',
  height: 'fit-content',
  fontWeight: 'bold',
});

const stackTokens: IStackTokens = {
  childrenGap: 120,
};

export const SummaryWidget: React.FunctionComponent = () => {
  const actionCenterContext = useContext(ActionCenterContext);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);

  useEffect(() => {
    setIsLoading(actionCenterContext.actionCenterMetrics.loadingState);
  }, [actionCenterContext.actionCenterMetrics.loadingState]);

  useEffect(() => {
    actionCenterContext.requestContextData(ActionCenterData.ActionCenterMetrics);
  }, [actionCenterContext]);

  return (
    <div className={widgetStyle}>
      <Stack horizontal wrap>
        <Icon iconName="AzureCloudGear" title="Summary" />
        <span className={labelStyle}>Summary</span>
      </Stack>

      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <div className={containerStyle}>
          <Stack horizontal tokens={stackTokens} wrap>
            <MetricLabel
              label="Clouds"
              toolTip="Number of clouds that MTAC packages are being submitted for. i.e public = 1, FF = 1. Cloud count would equal 2."
              value={actionCenterContext.actionCenterMetrics.contextData?.cloudCount}
            />
            <MetricLabel
              label="Offerings"
              toolTip="Number of offerings being tracked for authorization"
              value={actionCenterContext.actionCenterMetrics.contextData?.offeringCount}
            />
          </Stack>

          <Stack style={{ marginTop: '2.5em' }} horizontal tokens={stackTokens} wrap>
            <MetricLabel
              label="Services"
              toolTip="Number of services being tracked for authorization"
              value={actionCenterContext.actionCenterMetrics.contextData?.serviceCount}
              />
            <MetricLabel
              label="Packages"
              toolTip="Number of packages active in MTAC"
              value={actionCenterContext.actionCenterMetrics.contextData?.evidencePackageCount}
              />
          </Stack>
        </div>
      )}
    </div>
  );
};
