import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const heroWrapperStyles = mergeStyles({
  width: '100%',
  margin: '2em 11em',
});

export const ControlCatalogHeroContent: FunctionComponent = () => (
  <div className={heroWrapperStyles}>
    <h1>Control Catalogs</h1>
    <h2>View all control catalogs and control metadata</h2>
  </div>
);
