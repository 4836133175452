import {
  IButtonProps,
  IIconProps,
  INavLink,
  INavLinkGroup,
  IStackTokens,
  mergeStyles,
  NeutralColors,
  Pivot,
  PivotItem,
  Stack,
  IContextualMenuProps,
} from '@fluentui/react';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { evidenceDetailRoute } from 'modules/routes/routes';
import { HorizontalRule } from 'styles/horizontalRule';
import { WizardNavigation } from 'components/wizardNavigation/wizardNavigation';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { isUSNat } from 'components/util/cloudUtil';
import { ConfigContext } from 'components/configProvider/configContext';
import { AuthContext } from '../../components/authProvider/authContext';
import { LabelAndText } from '../../components/labelAndText/labelAndText';
import { NavMenu } from '../../components/navMenu/navMenu';
import { FileAttachment } from '../../generated/clientApi';
import { domainMap, Domains } from '../../models/domainConstants';
import { DomainStatus } from '../../models/domainStatus';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { UserRole } from '../../models/userRole';
import {
  AUDITOR_ACTIONS_READ,
  AUTHORIZATION_ACTIONS_READ,
  EVIDENCE_PACKAGE_AUDITING_REVIEW_READ,
  EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_READ,
  EVIDENCE_PACKAGE_SUBMISSION_WRITE,
  SITE_WIDE_SUBJECT,
} from '../../modules/constants';
import { getFormattedDate } from '../../modules/datetime/datetime';
import { createNavLinkGroup } from '../../modules/navMenu/navMenu';
import { pivotStyles } from '../../styles/pivot';
import { DomainViewer } from '../domainViewer/domainViewer';
import { EvidenceDetailsReview, ReviewType } from './menuItems/evidenceDetailReview';

const containerStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '-webkit-fill-available',
});

const borderTopStyles = mergeStyles({
  borderTop: '3px solid #eee',
});

const domainViewerContainerStyles = mergeStyles(borderTopStyles, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch',
  flexGrow: 1,
  flexShrink: 1,
  overflowY: 'hidden',
});

const leftBladeStyles = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

const navMenuClassName = mergeStyles({
  marginTop: '24px',
});

const approvedIconStyles = mergeStyles({
  color: 'green !important',
});

const rejectedIconStyles = mergeStyles({
  color: 'red !important',
  transform: 'rotate(45deg)',
});

const unsavedIconStyles = mergeStyles({
  color: 'red !important',
});

const incompleteIconStyles = mergeStyles({
  color: 'grey !important',
});

const statusHeading = mergeStyles({
  marginBottom: '4px',
});

const disabledPivotItemProps: IButtonProps = {
  disabled: true,
  style: {
    color: NeutralColors.gray40,
  },
};

const warningMessage = 'One or more domains has unsaved data, be sure to save before moving away from this evidence tab.';
const alertPivotItemProps: IButtonProps = {
  iconProps: {
    iconName: 'AlertSolid',
    className: unsavedIconStyles,
    title: warningMessage,
  },
};

const horizontalStackToken: IStackTokens = {
  childrenGap: 24,
};

const renderStatus = (status: string, submittedDate: Date | undefined): JSX.Element => {
  if (!submittedDate) {
    return <></>;
  }
  return (
    <div>
      <h2 className={statusHeading}>Status: {status}</h2>
      <span>{'Submitted on '}</span>
      <strong>{getFormattedDate(submittedDate)}</strong>
    </div>
  );
};

export interface EvidenceDetailProps {
  serviceOid: string;
  serviceName: string;
  fileAttachments: FileAttachment[];
  setFileAttachments(fileAttachments: FileAttachment[]): void;
  onClickPreviousButton(): void;
  submitButtonMenuProps: IContextualMenuProps;
  submitButtonText: string;
  navMenuTab: string;
  showChat: boolean;
  submitButtonSaving: boolean;
}

export const EvidenceDetail: FunctionComponent<EvidenceDetailProps> = (props) => {
  const {
    serviceOid,
    serviceName,
    fileAttachments,
    setFileAttachments,
    onClickPreviousButton,
    submitButtonMenuProps,
    navMenuTab,
    showChat,
    submitButtonText,
    submitButtonSaving,
  } = props;
  const authContext = useContext(AuthContext);
  const evidenceContext = useContext(EvidenceContext);
  const configContext = useContext(ConfigContext);
  const [packageDomains, setPackageDomains] = useState<string[]>([]);
  const navMenuOptions = new Map<string, string>(domainMap);
  const [showNotes, setShowNotes] = useState(showChat);
  // const [showChatButton, setShowChatButton] = useState(false);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>(false);
  const [warningSaveMessage, setWarningSaveMessage] = useState<string | undefined>();
  navMenuOptions.set('review', 'Review');

  useEffect(() => {
    setWarningSaveMessage(undefined);
    if (evidenceContext.dirtyDomains.length > 0) {
      setWarningSaveMessage(warningMessage);
    }
  }, [evidenceContext.dirtyDomains]);

  useEffect(() => {
    if (!evidenceContext.evidencePackage?.evidenceDomains) {
      return;
    }

    setPackageDomains(evidenceContext.evidencePackage.evidenceDomains.map((evidenceDomain) => evidenceDomain.name));
  }, [evidenceContext.evidencePackage]);

  const isReviewTabEnabled = useCallback(
    (reviewTab: string) => {
      // Auditor permissions
      if (
        reviewTab === 'auditor' &&
        evidenceContext.evidencePackage?.status !== EvidencePackageStatus.Created &&
        evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AuditorSubmit
      ) {
        return true;
      }

      // Authorizing Official permissions
      if (
        reviewTab === 'ao' &&
        evidenceContext.evidencePackage?.status !== EvidencePackageStatus.Created &&
        evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AuditorSubmit &&
        evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AuditorReview &&
        evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AoSubmit &&
        evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AuditorReturned
      ) {
        return true;
      }

      return false;
    },
    [evidenceContext.evidencePackage?.status],
  );

  const shouldPopulateAuditorReview =
    (authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_AUDITING_REVIEW_READ, subject: SITE_WIDE_SUBJECT }]) ||
      authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_SUBMISSION_WRITE, subject: SITE_WIDE_SUBJECT }])) &&
    !isUSNat(configContext.serverConfig?.cloud);

  const shouldPopulateAuthorizingOfficialReview =
    authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_READ, subject: SITE_WIDE_SUBJECT }]) ||
    authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_SUBMISSION_WRITE, subject: SITE_WIDE_SUBJECT }]);

  const createEvidenceDetailsNavGroup = (): INavLinkGroup[] => {
    const navLinks: INavLink[] = [];

    Object.values(Domains).forEach((domain) => {
      if (!packageDomains.includes(domain)) {
        return;
      }
      navLinks.push(createEvidenceDetailsNavLink(domainMap.get(domain) || '', domain, serviceOid, evidenceContext.evidencePackage?.id || '', false));
    });

    const reviewTabs: INavLink[] = [];
    reviewTabs.forEach((reviewTab) => {
      navLinks.push(reviewTab);
    });

    return createNavLinkGroup('', navLinks);
  };

  const getIconData = (domain: string): IIconProps | undefined => {
    const evidenceDomain = evidenceContext.evidencePackage?.evidenceDomains?.find(
      (evidenceDomain) => evidenceDomain.name === domain.replace(' ', ''),
    );

    // if unsaved data, add indicator
    if (evidenceContext.dirtyDomains.find((dirtyDomain) => dirtyDomain === domain)) {
      return {
        iconName: 'AlertSolid',
        className: unsavedIconStyles,
        title: 'There is unsaved data in this domain.',
      };
    }

    if (!evidenceDomain || domain === Domains.PACKAGE_NOTES) {
      return undefined;
    }

    const isAuditor = authContext.isAuthorized([{ operation: AUDITOR_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
    const isAO = authContext.isAuthorized([{ operation: AUTHORIZATION_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);

    let domainReviewStatus = '';

    if (isAuditor) {
      domainReviewStatus = evidenceDomain.auditorDomainReviewStatus?.status || '';
    }

    if (isAO) {
      domainReviewStatus = evidenceDomain.authorizingOfficialDomainReviewStatus?.status || '';
    }

    if (domainReviewStatus === DomainStatus.Rejected) {
      return {
        iconName: 'CircleAdditionSolid',
        className: rejectedIconStyles,
      };
    }
    if (domainReviewStatus === DomainStatus.Approved) {
      return {
        iconName: 'SkypeCircleCheck',
        className: approvedIconStyles,
      };
    }
    return {
      iconName: 'CircleFill',
      className: incompleteIconStyles,
    };
  };

  const createEvidenceDetailsNavLink = (
    name: string,
    navMenuTab: string,
    serviceOid: string,
    evidencePackageId: string,
    disabled: boolean,
  ): INavLink => ({
    name,
    key: navMenuTab,
    url: evidenceDetailRoute({ serviceOid, evidencePackageId, navMenuTab }),
    iconProps: getIconData(navMenuTab),
    disabled,
    navMenuTab,
  });

  const getDomains = (navLinks: INavLinkGroup[]) => navLinks[0].links.map((nav) => nav.name);

  const onPivotClick = (item?: PivotItem) => {
    // setShowChatButton(item?.props.headerText !== 'Evidence');
    // If the user is redirecting to another tab, erase the cached data
    evidenceContext.resetEvidencePackage();
    if (item?.props.headerText === 'Evidence') {
      setShowNotes(false);
    }
  };

  const navLinks = createEvidenceDetailsNavGroup();
  const validDomains = getDomains(navLinks);
  const selectedTab = packageDomains.includes(navMenuTab) ? navMenuTab : navMenuTab;

  const EvidenceOnlyComponent = (): JSX.Element => (
    <>
      <h2>Evidence</h2>
      <div className={domainViewerContainerStyles}>
        <div className={leftBladeStyles}>
          <NavMenu navLinkGroups={navLinks as any} selectedLink={selectedTab} className={navMenuClassName} />
          <WizardNavigation
            isNextDisabled={false}
            isNextLoading={isSubmitButtonLoading || submitButtonSaving}
            nextButtonText={submitButtonText}
            isNextVisible
            isPreviousVisible
            nextButtonMenuProps={submitButtonMenuProps}
            onClickPreviousButton={onClickPreviousButton}
            errorMessage={warningSaveMessage}
          />
        </div>
        <DomainViewer
          selectedTitle={selectedTab || packageDomains[0]}
          serviceOid={serviceOid}
          validDomains={validDomains}
          fileAttachments={fileAttachments}
          setFileAttachments={setFileAttachments}
          setIsLoading={(isLoading) => setIsSubmitButtonLoading(isLoading)}
          displayMinimalAttachmentsComponent
        />
      </div>
    </>
  );

  const EvidenceComponent = (): JSX.Element => (
    <Stack horizontal tokens={horizontalStackToken}>
      <Pivot styles={pivotStyles} onLinkClick={onPivotClick}>
        <PivotItem headerText="Evidence" headerButtonProps={warningSaveMessage ? alertPivotItemProps : undefined}>
          <div>{EvidenceOnlyComponent()}</div>
        </PivotItem>
        {shouldPopulateAuditorReview && (
          <PivotItem headerText="Auditor score & summary" headerButtonProps={isReviewTabEnabled('auditor') ? undefined : disabledPivotItemProps}>
            <EvidenceDetailsReview
              reviewType={ReviewType.AUDITOR_REVIEW}
              reviewRole={UserRole.Auditor}
              serviceTitle={serviceName}
              serviceOid={serviceOid}
              fileAttachments={fileAttachments}
              validDomains={validDomains}
              onFileAttachmentsUpdated={setFileAttachments}
              showNotes={showNotes}
            />
          </PivotItem>
        )}
        {shouldPopulateAuthorizingOfficialReview && (
          <PivotItem
            headerText="Authorizing Officials score & summary"
            headerButtonProps={isReviewTabEnabled('ao') ? undefined : disabledPivotItemProps}
          >
            <EvidenceDetailsReview
              reviewType={ReviewType.AUTHORIZATION_OFFICIAL_REVIEW}
              reviewRole={UserRole.AuthorizingOfficial}
              serviceTitle={serviceName}
              serviceOid={serviceOid}
              validDomains={validDomains}
              fileAttachments={fileAttachments}
              onFileAttachmentsUpdated={setFileAttachments}
              showNotes={showNotes}
            />
          </PivotItem>
        )}
      </Pivot>
    </Stack>
  );

  return (
    <div className={containerStyles}>
      {evidenceContext.evidencePackage && (
        <>
          <div>
            {renderStatus(evidenceContext.evidencePackage.status, evidenceContext.evidencePackage?.submittedDate)}
            <h2>Profile information</h2>
            <Stack horizontal tokens={horizontalStackToken}>
              <LabelAndText label="Profile Name:" text={evidenceContext.evidencePackage.name} />
              <LabelAndText label="Authorization Level:" text={evidenceContext.evidencePackage.certification} />
              <LabelAndText label="Cloud:" text={evidenceContext.evidencePackage.cloud} />
              <LabelAndText label="Service:" text={serviceName} />
            </Stack>
          </div>
          <div className={containerStyles}>
            <br />
            <HorizontalRule />
            {EvidenceComponent()}
          </div>
        </>
      )}
    </div>
  );
};
