import { DefaultButton, Panel, PanelType, TextField, Spinner } from '@fluentui/react';
import { SaveButton } from 'components/saving/saveButton';
import { ConmonReportsOwnersGetClient, ConmonReportsOwnersPostClient, ConmonReportsOwnersPostCommand, ReportOwners } from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import React, { useContext, useEffect, useState } from 'react';
import { sideModalReportStyles } from 'styles/sidePanelPoam';
import { ConMonFilterContext } from '../conMonFilterContext/conMonFilterContext';

type ReportOwnersPanelProps = {
  close: () => void;
};

export const ReportOwnersPanel: React.FunctionComponent<ReportOwnersPanelProps> = (props) => {
  const { close } = props;
  const [isSaving, setSaving] = useState(false);
  const [reportOwners, setReportOwners] = useState<ReportOwners>();
  const { readonly, organization } = useContext(ConMonFilterContext);

  useEffect(() => {
    getReportOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReportOwners = async () => {
    const client = new ConmonReportsOwnersGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(organization);

      setReportOwners(response ?? {});
    } catch (e) {
      showError('There was an error fetching report owners. Please refresh to try again.');
    }
  };

  const onSave = async () => {
    setSaving(true);

    const client = new ConmonReportsOwnersPostClient(getConfig().apiBaseUri);
    try {
      await client.post({ owners: { ...reportOwners, organization } } as ConmonReportsOwnersPostCommand);
      showSuccess('Report owners successfully saved', 10);
      close();
    } catch (e) {
      showError('There was an error saving report owners. Please refresh to try again.');
    } finally {
      setSaving(false);
    }
  };

  const onRenderFooterContent = () => (
    <div style={{ position: 'absolute', bottom: '1rem' }}>
      <SaveButton onSave={() => onSave()} defaultText="Save" isSaving={isSaving} disabled={readonly} />
      <DefaultButton onClick={close} styles={{ root: { marginLeft: 8 } }}>
        Cancel
      </DefaultButton>
    </div>
  );

  return (
    <Panel
      isOpen
      onDismiss={close}
      type={PanelType.medium}
      closeButtonAriaLabel="Close"
      headerText="Report owners"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalReportStyles}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '.7rem', marginTop: '1rem' }}>
        {reportOwners === undefined ? (
          <Spinner />
        ) : (
          <>
            <TextField
              label="FedRAMP Program Owner name"
              placeholder="Enter FedRAMP Program Owner name"
              value={reportOwners.fedRampProgramOwnerName}
              onChange={(ev: any, newValue: any) => setReportOwners((prev) => ({ ...prev, fedRampProgramOwnerName: newValue }) as ReportOwners)}
            />
            <TextField
              label="FedRAMP Program Owner title"
              placeholder="Enter FedRAMP Program Owner title"
              value={reportOwners.fedRampProgramOwnerTitle}
              onChange={(ev: any, newValue: any) => setReportOwners((prev) => ({ ...prev, fedRampProgramOwnerTitle: newValue }) as ReportOwners)}
            />
            <TextField
              label="FedRAMP Program Owner phone number"
              placeholder="Enter FedRAMP Program Owner phone number"
              value={reportOwners.fedRampProgramOwnerPhone}
              onChange={(ev: any, newValue: any) => setReportOwners((prev) => ({ ...prev, fedRampProgramOwnerPhone: newValue }) as ReportOwners)}
            />
            <TextField
              label="FedRAMP Program Owner email"
              placeholder="Enter FedRAMP Program Owner email"
              value={reportOwners.fedRampProgramOwnerEmail}
              onChange={(ev: any, newValue: any) => setReportOwners((prev) => ({ ...prev, fedRampProgramOwnerEmail: newValue }) as ReportOwners)}
            />
            <TextField
              label="RA-05 POA&M Owner"
              placeholder="Enter RA-05 POA&M Owner name"
              value={reportOwners.poamRa05OwnerName}
              onChange={(ev: any, newValue: any) => setReportOwners((prev) => ({ ...prev, poamRa05OwnerName: newValue }) as ReportOwners)}
            />
            <TextField
              label="Other POA&M Owner"
              placeholder="Enter Other POA&M Owner name"
              value={reportOwners.poamOtherOwnerName}
              onChange={(ev: any, newValue: any) => setReportOwners((prev) => ({ ...prev, poamOtherOwnerName: newValue }) as ReportOwners)}
            />
          </>
        )}
      </div>
    </Panel>
  );
};
