import {
  DefaultButton,
  FontIcon,
  IScrollablePaneStyles,
  Modal,
  PrimaryButton,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  SharedColors,
  ShimmeredDetailsList,
  mergeStyles,
} from '@fluentui/react';
import { ManualPoamPutClient, ManualPoamPutCommand, Poam } from 'generated/clientApi';
import { buttonStyles } from 'pages/conMon/styles/buttonStyles';
import React, { useState } from 'react';
import { renderFixedDetailsHeader } from 'pages/conMon/components/tableUtils';
import { getConfig } from 'modules/config/config';
import { ManualPoamTableColumns } from './manualPoamTableColumns';

const scrollClass = mergeStyles({
  height: '100%',
  width: '100%',
  position: 'relative',
});

const customTableStyles = {
  root: {
    '.ms-DetailsList': {
      overflow: 'visible',
    },
  },
};

const customModalStyles = mergeStyles({
  '.ms-Modal-scrollableContent': {
    overflowX: 'hidden',
  },
});

const iconClass = mergeStyles({
  fontSize: 40,
  margin: '8px 12px',
  color: 'green',
});

const failedIconClass = mergeStyles(iconClass, {
  color: 'red',
  margin: '8px 3px',
});

type ManualPoamModalProps = {
  poams: Poam[];
  onClose: () => void;
};

export const ManualPoamModal: React.FunctionComponent<ManualPoamModalProps> = (props) => {
  const { poams, onClose } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSave = async () => {
    setSaving(true);
    const client = new ManualPoamPutClient(getConfig().apiBaseUri);
    try {
      await client.put(new ManualPoamPutCommand({ poams }));
      setSuccess(true);
    } catch {
      setErrorMessage('There was an error saving manual POAMs, please try again. If the problem persists, please reach out to support.');
    } finally {
      setSaving(false);
    }
  };

  if (errorMessage || success) {
    return (
      <Modal className={customModalStyles} isOpen onDismiss={onClose}>
        <div style={{ height: '200px', width: '550px', padding: '1rem 3rem' }}>
          <div style={{ display: 'flex' }}>
            <h2>{success ? 'Success' : 'Failed'}</h2>
            {success ? (
              <FontIcon aria-label="Success" iconName="CheckMark" className={iconClass} />
            ) : (
              <FontIcon aria-label="Failed" iconName="StatusCircleErrorX" className={failedIconClass} />
            )}
          </div>
          <div style={{ display: 'flex' }} className={scrollClass}>
            {success ? <h3>All manual POAMs were successfully saved</h3> : <h3>{errorMessage}</h3>}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '.5rem', padding: '1rem 1.5rem' }}>
          <DefaultButton style={buttonStyles} onClick={onClose} disabled={saving}>
            Close
          </DefaultButton>
        </div>
      </Modal>
    );
  }

  return (
    <Modal className={customModalStyles} isOpen isBlocking onDismiss={onClose}>
      <div
        style={{
          height: 'calc(min(80vh, 1000px))',
          width: 'calc(min(80vw, 1700px))',
          padding: '2rem',
          overflow: 'auto',
        }}
      >
        <div style={{ display: 'flex' }} className={scrollClass}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={customTableStyles as unknown as IScrollablePaneStyles}>
            <h2>{`The following ${poams.length} POAM(s) were successfully parsed:`}</h2>
            <p style={{ color: SharedColors.red10 }}>
              Please ensure all POAM properties below are correct. Once a POAM has been manually ingested, it cannot be ingested again.
            </p>
            <div style={{ paddingBottom: '1rem' }}>
              <ShimmeredDetailsList
                enableShimmer={saving}
                selectionMode={SelectionMode.none}
                items={poams}
                columns={ManualPoamTableColumns()}
                onRenderDetailsHeader={renderFixedDetailsHeader}
              />
            </div>
          </ScrollablePane>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '.5rem', padding: '1rem' }}>
        <PrimaryButton style={buttonStyles} onClick={onSave} iconProps={{ iconName: 'save' }} disabled={saving}>
          Save
        </PrimaryButton>
        <DefaultButton style={buttonStyles} onClick={onClose} disabled={saving}>
          Cancel
        </DefaultButton>
      </div>
    </Modal>
  );
};
