import { CuratedTenantMonitoringDataHistoryGetDailyScanData } from 'generated/clientApi';
import { getChartFormattedMonthAndDate, yearMonthDayFormat } from 'modules/datetime/datetime';
import { format } from 'date-fns';
import { ComplianceHistoryType, CuratedTenantTimelineChartData } from '../tenantGovernanceDetailsTimeline';

export const getTimelineDisplayChartData = (scanHistories: CuratedTenantMonitoringDataHistoryGetDailyScanData[]): CuratedTenantTimelineChartData[] => {
  const data: CuratedTenantTimelineChartData[] = [];

  // be sure to order the data by ascending date before adding to the data array
  scanHistories.sort((a, b) => (a.scanDate ?? new Date()).getTime() - (b.scanDate ?? new Date()).getTime()).forEach((history) => {
    data.push({
      date: getChartFormattedMonthAndDate(history.scanDate),
      count: history.resourcesDriftCount ?? 0,
      complianceType: ComplianceHistoryType.PolicyDrift,
    });
    data.push({
      date: getChartFormattedMonthAndDate(history.scanDate),
      count: history.policiesViolatedCount ?? 0,
      complianceType: ComplianceHistoryType.PolicyViolation,
    });
  });

  return data;
};

export const getTotalPolicyViolations = (scanHistories: CuratedTenantMonitoringDataHistoryGetDailyScanData[]): number => scanHistories.reduce((sum, current) => sum + (current.policiesViolatedCount ?? 0), 0);
export const getTotalPolicyDrifts = (scanHistories: CuratedTenantMonitoringDataHistoryGetDailyScanData[]): number => scanHistories.reduce((sum, current) => sum + (current.resourcesDriftCount ?? 0), 0);

// returned date format is 'YYYY-MM-DD'
export const getStartDateForCuratedTenantChartDisplay = (today: Date): string => format(new Date(today.setDate(today.getDate() - 30)), yearMonthDayFormat);
export const getEndDateForCuratedTenantChartDisplay = (today: Date): string => format(today, yearMonthDayFormat);
