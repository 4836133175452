import { IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const subtextContent = mergeStyles({
  fontSize: '1.4em',
  margin: '0.25em',
  marginRight: '0.4em',
  marginBottom: '2em',
  verticalAlign: 'middle',
});

const horizontalStackToken: IStackTokens = {
  childrenGap: 12,
};

export const ControlEvidencePageHeroContent: FunctionComponent = () => (
  <Stack>
    <h1>Control Evidence</h1>
    <Stack horizontal tokens={horizontalStackToken}>
      <span className={subtextContent}>
        View statuses of individual services or offerings and submit packages to expedite the authorization process.
      </span>
    </Stack>
  </Stack>
);
