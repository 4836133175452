import { ConmonOrganizationType } from 'generated/clientApi';
import { createContext } from 'react';

export interface ConMonFilterContextProps {
  readonly: boolean;
  organization: ConmonOrganizationType | undefined;
}

export const ConMonFilterContext = createContext<ConMonFilterContextProps>({
  readonly: true,
  organization: undefined,
});

export const ConMonFilterConsumer = ConMonFilterContext.Consumer;
