import { DetailsList, MessageBar, MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { CatalogsListResponse } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { getControlCatalogs } from '../../modules/controlCatalogs/controlCatalogs';
import { logError } from '../../modules/logging/logging';
import { controlCatalogRoute } from '../../modules/routes/routes';
import { updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { createControlCatalogBladeChromeConfig } from '../../modules/pageChrome/catalogPageChrome';
import { ControlCatalogNavLinks } from '../../models/controlCatalogNavLinks';

const controlCatalogCols = [
  {
    key: 'id',
    name: 'Catalog name',
    minWidth: 400,
    maxWidth: 800,
    isResizable: true,
    onRender: (item: CatalogsListResponse) => <Link to={{ pathname: controlCatalogRoute({ controlCatalogId: item?.id || '' }) }}>{item.title}</Link>,
  },
];

export const ControlCatalogsList: React.FunctionComponent = () => {
  const [controlCatalogs, setControlCatalogs] = useState<CatalogsListResponse[]>([]);
  const [loading, setLoading] = useState(LoadingState.Loading);

  useEffect(() => {
    updatePageChromeConfig(createControlCatalogBladeChromeConfig(ControlCatalogNavLinks.ControlCatalogs));
    return () => updatePageChromeConfig();
  }, []);

  useEffect(() => {
    const getItems = async () => {
      try {
        setLoading(LoadingState.Loading);
        const controlCatalogsResults = await getControlCatalogs();
        setControlCatalogs(controlCatalogsResults);
        setLoading(LoadingState.Loaded);
      } catch (e) {
        logError('Error retrieving Control Catalogs', e);
        setLoading(LoadingState.Error);
      }
    };
    getItems();
  }, []);

  if (loading === LoadingState.Loading) return <CenteredProgressDots />;
  if (loading === LoadingState.Error) return <MessageBar messageBarType={MessageBarType.error}>Failed to load control catalogs.</MessageBar>;

  return (
    <div className={pageChromeChildStyles}>
      <h2>Control catalogs</h2>
      <div>View controls by selecting an authorization or template catalog for your security system plan.</div>
      <DetailsList
        items={controlCatalogs}
        columns={controlCatalogCols}
        checkButtonAriaLabel="Select row"
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      />
    </div>
  );
};
