import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { mergeStyles } from '@fluentui/react';
import { LoadingState } from '../../models/loadingState';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { getServicesEvidencePackages } from '../../modules/evidencePackage/evidencePackage';
import { EvidencePackage } from '../../generated/clientApi';
import { logError } from '../../modules/logging/logging';
import { ButtonBanner } from '../../components/buttonBanner/buttonBanner';
import { Severity } from '../../models/severity';
import { AzureAuthorizationInformation, ServiceAuthorizationTable } from '../../components/serviceAuthorizationTable/serviceAuthorizationTable';
import { ConfigContext } from '../../components/configProvider/configContext';
import { combineAuthorizationData, updateAuthorizationStatus } from './serviceDetail.functions';
import { ServiceContext } from '../../components/serviceProvider/serviceContext';

const bodyStyle = mergeStyles({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '20px',
});

const tableStyle = mergeStyles({
  textDecoration: 'none',
});

const tableHeaderStyle = mergeStyles({
  fontSize: '20px',
  fontWeight: 600,
  marginTop: '10px',
});

interface AuthorizationStatusProps {
  serviceOid: string;
}

export const AuthorizationStatus: FunctionComponent<AuthorizationStatusProps> = (props) => {
  const { serviceOid } = props;
  const [isLoading, setIsLoading] = useState<LoadingState>();
  const [authorizationInformation, setAuthorizationInformation] = useState<AzureAuthorizationInformation[]>([]);
  const configContext = useContext(ConfigContext);
  const servicesContext = useContext(ServiceContext);

  useEffect(() => {
    const getAuthorizationInformation = async () => {
      try {
        setIsLoading(LoadingState.Loading);
        const evidencePackages = await getServicesEvidencePackages(serviceOid);

        // Combine authorizations and evidence packages into one object
        const authorizationInformation = combineAuthorizationData(configContext.environmentAuthorizations, evidencePackages);
        setIsLoading(LoadingState.Loaded);
        setAuthorizationInformation(authorizationInformation);
      } catch (error) {
        setIsLoading(LoadingState.Error);
        logError(`There was an issue loading the azure authorizations, evidence packages, and/or service with oid: ${serviceOid}`, error);
      }
    };
    getAuthorizationInformation();
  }, [configContext.environmentAuthorizations, serviceOid]);

  const shouldRenderBanner = useCallback(
    () => !servicesContext.selectedService?.isEvidenceGatheringEnabled,
    [servicesContext.selectedService?.isEvidenceGatheringEnabled],
  );
  const evidenceGathering = (
    <ButtonBanner severity={Severity.Warning} message="This service must be in the Onboarding Approved stage for evidence gathering." removeButton />
  );

  const updateEvidencePackageStatus = (evidencePackage: EvidencePackage, note: string) => {
    setAuthorizationInformation((authInfo): any => updateAuthorizationStatus(evidencePackage, authInfo, note));
  };

  return (
    <>
      {isLoading !== LoadingState.Loaded || !servicesContext.selectedService ? (
        <CenteredProgressDots />
      ) : (
        <div className={bodyStyle}>
          {shouldRenderBanner() && evidenceGathering}
          <div className={tableHeaderStyle}>Authorization Status</div>
          <div className={tableStyle}>
            <ServiceAuthorizationTable
              updateEvidencePackageStatus={updateEvidencePackageStatus}
              authorizationInformation={authorizationInformation}
            />
          </div>
        </div>
      )}
    </>
  );
};
