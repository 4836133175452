import { Link, mergeStyles } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import React, { FunctionComponent, useState, useMemo, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { serviceDetailRoute } from '../../modules/routes/routes';
import { CenteredProgressDots } from '../progressDots/progressDots';
import { ColumnTabWidget, ColumnTabsProps } from '../columnTabWidget/columnTabWidget';
import { ServiceReviewDetail } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { ListColumn } from '../basicList/basicList';
import { getOfferingServiceReviews } from '../../modules/offering/offering';

export interface ServiceByOfferingWidgetProps {
  offeringId: string;
  cloud: string;
  certification: string;
}

const linkStyle = {
  textDecoration: 'none',
};

const iconStyle = mergeStyles({
  fontSize: '1.2rem',
  marginLeft: '0.5rem',
  cursor: 'default',
  color: '#ffb900',
});

export const ServiceByOfferingWidget: FunctionComponent<ServiceByOfferingWidgetProps> = (props) => {
  const { offeringId, cloud, certification } = props;
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.Loaded);
  const [offeringServiceReviews, setOfferingServiceReviews] = useState<{ [key: string]: ServiceReviewDetail[] }>();

  useEffect(() => {
    const fetchOfferingServiceReviews = async () => {
      setIsLoading(LoadingState.Loading);
      const response = await getOfferingServiceReviews(offeringId, cloud, certification);
      setOfferingServiceReviews(response.serviceReviewDetailMap);
      setIsLoading(LoadingState.Loaded);
    };

    fetchOfferingServiceReviews();
  }, [certification, cloud, offeringId]);

  const serviceColumns: ListColumn[] = useMemo(
    () => [
      {
        key: 'serviceName',
        name: 'Service name',
        onRender: (serviceReviewDetail: ServiceReviewDetail) => (
          <Link as={RouterLink} to={serviceDetailRoute({ serviceOid: serviceReviewDetail.serviceOid })} style={linkStyle}>
            {serviceReviewDetail.serviceName}
          </Link>
        ),
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'lastApproved',
        name: 'Last approved',
        onRender: (serviceReviewDetail: ServiceReviewDetail) => <span>{serviceReviewDetail.approvedDate}</span>,
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'nextAnnualReview',
        name: 'Next annual review',
        onRender: (serviceReviewDetail: ServiceReviewDetail) => (
          <>
            <span>{serviceReviewDetail.nextAnnualReview}</span>
            {serviceReviewDetail.reviewRequired ? (
              <Icon className={iconStyle} iconName="WarningSolid" title={`${serviceReviewDetail.serviceName} needs to be reviewed.`} />
            ) : (
              <></>
            )}
          </>
        ),
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'submittedBy',
        name: 'Submitted by',
        onRender: (serviceReviewDetail: ServiceReviewDetail) => <span>{serviceReviewDetail.submittedBy}</span>,
        minWidth: 50,
        maxWidth: 150,
        filterName: 'Submitted by',
        isFilterable: true,
        getFilterValue: (serviceReviewDetail: ServiceReviewDetail) => serviceReviewDetail.submittedBy,
      },
      {
        key: 'approvedBy',
        name: 'Approved by',
        onRender: (serviceReviewDetail: ServiceReviewDetail) => <span>{serviceReviewDetail.approvedBy}</span>,
        minWidth: 50,
        maxWidth: 150,
        filterName: 'Approved by',
        isFilterable: true,
        getFilterValue: (serviceReviewDetail: ServiceReviewDetail) => serviceReviewDetail.approvedBy,
      },
    ],
    [],
  );

  const createColumnTabsProps = (title: string, stage: string, color: string) => {
    if (offeringServiceReviews) {
      const serviceReviewDetails = offeringServiceReviews[stage];
      if (serviceReviewDetails) {
        return {
          key: stage,
          title,
          count: serviceReviewDetails.length,
          items: serviceReviewDetails,
          statisticColor: color,
        };
      }
    }

    return {
      key: stage,
      title,
      count: 0,
      items: [],
      statisticColor: color,
    };
  };

  const columnTabs: ColumnTabsProps[] = [
    createColumnTabsProps('Not Started', 'NotStarted', '#054b16'),
    createColumnTabsProps('In Progress', 'InProgress', '#0078d4'),
    createColumnTabsProps('Queued', 'Queued', '#d83b01'),
    createColumnTabsProps('Under Review', 'UnderReview', '#6b2929'),
    createColumnTabsProps('Onboarded', 'Onboarded', '#8661c5'),
    createColumnTabsProps('Returned', 'Returned', '#243a5e'),
  ];

  const getTotal = useCallback(() => {
    if (offeringServiceReviews) {
      const keys = Object.keys(offeringServiceReviews);
      let totalCount = 0;
      for (let i = 0; i < keys.length; i++) {
        totalCount += offeringServiceReviews[keys[i]].length;
      }
      return totalCount;
    }
    return 0;
  }, [offeringServiceReviews]);

  const defaultActiveColumn = () => {
    let activeColumn = 0;
    let largestArrayCount = 0;
    for (let i = 0; i < columnTabs.length; i++) {
      const column = columnTabs[i];
      if (column.items.length > largestArrayCount) {
        largestArrayCount = column.items.length;
        activeColumn = i;
      }
    }
    return activeColumn;
  };

  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <ColumnTabWidget
          title="Services by offering"
          columnTabs={columnTabs}
          totalTitle="Total Services"
          totalCount={getTotal()}
          defaultActiveColumn={defaultActiveColumn()}
          listColumns={serviceColumns}
        />
      )}
    </>
  );
};
