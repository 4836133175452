import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { mergeStyles } from '@fluentui/react';
import { HeroContentContext } from '../../components/heroContentProvider/heroContentContext';
import { SecurityPlanManagerHeroContent } from './securityPlanManagerHeroContent';
import { InfoCard } from '../../components/infoCard/infoCard';
import { systemsRoute, reportsRoute, organizationFrontMatterRoute } from '../../modules/routes/routes';
import systemsImage from '../../assets/systems.png';
import reportsImage from '../../assets/reports.png';
import { UserContext } from '../../components/userProvider/userContext';
import { AccessDenied } from '../accessDenied/accessDenied';
import { LoadingState } from '../../models/loadingState';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';

const cardWrapperStyles = mergeStyles({
  display: 'flex',
  margin: '0 auto',
  width: 'fit-content',
});

export const SecurityPlanManager: FunctionComponent = () => {
  const heroContentContext = useRef(useContext(HeroContentContext));
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);

  useEffect(() => {
    heroContentContext.current.setAndDisplayHeroContent(<SecurityPlanManagerHeroContent />, true);
  }, []);

  useEffect(() => {
    setIsLoading(userContext.userLoadingState);
  }, [userContext.userLoadingState]);

  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          {userContext.organizations.length === 0 ? (
            <AccessDenied accessMessage="You do not have access to any organizations and are not allowed to view this page." />
          ) : (
            <div className={cardWrapperStyles}>
              {/* NOTE: We need to open the links in a new tab to maintain chosen organization state on this page  */}
              <InfoCard
                title="Systems"
                description="Create, view and filter systems with via Cloud and Compliance Regime"
                image={systemsImage}
                link={systemsRoute()}
                linkText="View Systems"
              />
              <InfoCard
                title="Reports"
                description="Generate a word document to self test on based off any domain or criteria"
                image={reportsImage}
                link={reportsRoute()}
                linkText="View Reports"
              />
              <InfoCard
                title="Organizational Front Matter"
                description="Manage organiztional data to insert into your system security plans"
                image={reportsImage}
                link={organizationFrontMatterRoute()}
                linkText="View Front Matter"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
