import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { BreadcrumbItem } from 'components/breadcrumb/breadcrumb';
import {
  CommandBarButton,
  Dropdown,
  IDropdownOption,
  IIconProps,
  IStackStyles,
  Spinner,
  SpinnerSize,
  Stack,
  mergeStyles,
  Link,
  PrimaryButton,
  ImageFit,
  Image,
} from '@fluentui/react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { BasicList, ListColumn } from 'components/basicList/basicList';
import { EvidencePackage } from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { createEvidencePackageRoute, evidenceDetailRoute } from 'modules/routes/routes';
import neatClipboardArtSvg from 'assets/neatClipboardArt.svg';
import { Domains } from 'models/domainConstants';
import { createChromeConfig, updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { AuthContext } from '../../components/authProvider/authContext';
import { COMPLIANCE_TEAM_ACTIONS_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { getEvidencePackages } from '../../modules/evidencePackage/evidencePackage';

const breadcrumbs: BreadcrumbItem[] = [
  {
    key: 'serviceKey',
    text: 'Service',
  },
  {
    key: 'serviceEvidenceKey',
    text: 'Service Evidence',
  },
];

const containerStyles = mergeStyles({
  padding: '1em',
});

const dropdownStyles = mergeStyles({
  minWidth: '150px',
  marginRight: '0.5em',
});

const groupByStyles = mergeStyles(dropdownStyles, {
  marginLeft: 'auto',
});

const options: IDropdownOption[] = [{ key: 'none', text: 'none' }];

const baseStackStyles: Partial<IStackStyles> = { root: { height: 44 } };
const stackStyles = mergeStyles(baseStackStyles, {
  paddingTop: '1em',
  paddingBottom: '1em',
  borderRadius: '150px',
});

const buttonContainerStyles = mergeStyles({
  minHeight: '50em',
  minWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const linkStyle = {
  textDecoration: 'none',
};

const buttonStyles = mergeStyles({
  backgroundColor: '#00404e',
  borderColor: '#00404e',
});

const noEvidencePackagesStyles = mergeStyles({
  paddingBottom: '1em',
});

enum ServiceEvidenceEnums {
  Service,
  Cloud,
  Certification,
  Status,
}

interface ServiceEvidenceFilter {
  service?: string;
  cloud?: string;
  certification?: string;
  status?: string;
}

const addIcon: IIconProps = { iconName: 'Add' };
const forwardIcon: IIconProps = { iconName: 'ChromeBackMirrored' };
const createEvidencePackageText = 'Create new evidence package';

const createOptionsMap = (evidencePackages: EvidencePackage[]): Map<ServiceEvidenceEnums, IDropdownOption[]> => {
  const optionsMap = new Map<ServiceEvidenceEnums, IDropdownOption[]>();
  if (!evidencePackages) {
    return optionsMap;
  }

  optionsMap.set(
    ServiceEvidenceEnums.Certification,
    createDropDownOptions(ServiceEvidenceEnums.Certification, new Set(evidencePackages.map((evidencePackage) => evidencePackage.certification))),
  );
  optionsMap.set(
    ServiceEvidenceEnums.Cloud,
    createDropDownOptions(ServiceEvidenceEnums.Cloud, new Set(evidencePackages.map((evidencePackage) => evidencePackage.cloud))),
  );
  optionsMap.set(
    ServiceEvidenceEnums.Service,
    createDropDownOptions(ServiceEvidenceEnums.Service, new Set(evidencePackages.map((evidencePackage) => evidencePackage.serviceName))),
  );
  optionsMap.set(
    ServiceEvidenceEnums.Status,
    createDropDownOptions(ServiceEvidenceEnums.Status, new Set(evidencePackages.map((evidencePackage) => evidencePackage.status))),
  );
  return optionsMap;
};

const createDropDownOptions = (serviceEvidenceEnum: ServiceEvidenceEnums, inputs: Set<string | undefined>): IDropdownOption[] => {
  const dropdownOptions = [{ key: serviceEvidenceEnum, text: '' }] as IDropdownOption[];
  Array.from(inputs).forEach((item) => {
    if (item) {
      dropdownOptions.push({ key: item, text: item });
    }
  });
  return dropdownOptions;
};

export const ServiceEvidencePage: React.FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);
  const [evidencePackages, setEvidencePackages] = useState<EvidencePackage[] | undefined>();
  const [currentEvidencePackages, setCurrentEvidencePackages] = useState<EvidencePackage[] | undefined>();
  const [serviceEvidenceFilter, setServiceEvidenceFilter] = useState<ServiceEvidenceFilter>({});
  const [optionsMap, setOptionsMap] = useState<Map<ServiceEvidenceEnums, IDropdownOption[]>>(new Map<ServiceEvidenceEnums, IDropdownOption[]>());
  const history = useHistory();

  const getOptions = (serviceEvidenceEnum: ServiceEvidenceEnums): IDropdownOption<any>[] => {
    const option = optionsMap.get(serviceEvidenceEnum);
    if (option) {
      return option;
    }
    return [{ key: serviceEvidenceEnum, text: 'none' }];
  };

  useEffect(() => {
    const getEvidencePackageData = async () => {
      const evidencePackageListResponse = await getEvidencePackages();
      setEvidencePackages(evidencePackageListResponse);
      setCurrentEvidencePackages(evidencePackageListResponse);
      setOptionsMap(createOptionsMap(evidencePackageListResponse));
      setLoadingState(LoadingState.Loaded);
    };
    if (!evidencePackages) {
      getEvidencePackageData();
    }
  });

  useEffect(() => {
    const pageChromeConfig = createChromeConfig('service-evidence', 'Service Evidence', isComplianceTeam, undefined, breadcrumbs);
    updatePageChromeConfig(pageChromeConfig);
    return () => updatePageChromeConfig();
  }, [isComplianceTeam]);

  const updateServiceEvidenceEvidenceFilters = useCallback(
    (serviceEvidenceEnum: ServiceEvidenceEnums, option: string | undefined) => {
      const newServiceEvidenceFilter = { ...serviceEvidenceFilter };
      switch (serviceEvidenceEnum) {
        case ServiceEvidenceEnums.Certification:
          newServiceEvidenceFilter.certification = option;
          break;
        case ServiceEvidenceEnums.Cloud:
          newServiceEvidenceFilter.cloud = option;
          break;
        case ServiceEvidenceEnums.Service:
          newServiceEvidenceFilter.service = option;
          break;
        case ServiceEvidenceEnums.Status:
          newServiceEvidenceFilter.status = option;
          break;
        default:
          break;
      }

      return newServiceEvidenceFilter;
    },
    [serviceEvidenceFilter],
  );

  const onDropdownOptionsChange = useCallback(
    (serviceEvidenceEnum: ServiceEvidenceEnums, option: string | undefined): void => {
      if (!evidencePackages) {
        return;
      }

      const newServiceEvidenceFilter = updateServiceEvidenceEvidenceFilters(serviceEvidenceEnum, option);
      const filterEvidencePackages: EvidencePackage[] = evidencePackages.filter(
        (evidencePackage) =>
          (!newServiceEvidenceFilter.certification || evidencePackage.certification === newServiceEvidenceFilter.certification) &&
          (!newServiceEvidenceFilter.service || evidencePackage.serviceName === newServiceEvidenceFilter.service) &&
          (!newServiceEvidenceFilter.cloud || evidencePackage.cloud === newServiceEvidenceFilter.cloud) &&
          (!newServiceEvidenceFilter.status || evidencePackage.status === newServiceEvidenceFilter.status),
      );

      setCurrentEvidencePackages(filterEvidencePackages);
      setServiceEvidenceFilter(newServiceEvidenceFilter);
      setOptionsMap(createOptionsMap(filterEvidencePackages));
    },
    [evidencePackages, updateServiceEvidenceEvidenceFilters],
  );

  const onCreateEvidencePackageClick = useCallback(() => history.push(createEvidencePackageRoute()), [history]);

  const evidencePackageColumns: ListColumn[] = useMemo(
    () => [
      {
        key: 'serviceName',
        name: 'Name',
        getFieldValue: (evidencePackage: EvidencePackage) => evidencePackage.serviceOid,
        onRender: (evidencePackage: EvidencePackage) => (
          <Link
            as={RouterLink}
            to={evidenceDetailRoute({
              serviceOid: evidencePackage.serviceOid,
              evidencePackageId: evidencePackage.id,
              navMenuTab: evidencePackage.evidenceDomains ? evidencePackage.evidenceDomains[0].name : Domains.SERVICE_TREE_PROPERTIES,
            })}
            style={linkStyle}
          >
            {evidencePackage.serviceName}
          </Link>
        ),
        minWidth: 100,
        maxWidth: 500,
      },
      {
        key: 'submitted',
        name: 'Submitted',
        getFieldValue: (evidencePackage: EvidencePackage) => evidencePackage.id,
        onRender: (evidencePackage: EvidencePackage) => <>{evidencePackage.submittedDateString}</>,
        minWidth: 75,
        maxWidth: 500,
      },
      {
        key: 'compliance',
        name: 'Compliance',
        getFieldValue: (evidencePackage: EvidencePackage) => evidencePackage.certification,
        onRender: (evidencePackage: EvidencePackage) => <>{evidencePackage.certification}</>,
        minWidth: 75,
        maxWidth: 500,
      },
      {
        key: 'cloud',
        name: 'Cloud',
        getFieldValue: (evidencePackage: EvidencePackage) => evidencePackage.cloud,
        onRender: (evidencePackage: EvidencePackage) => <>{evidencePackage.cloud}</>,
        minWidth: 75,
        maxWidth: 500,
      },
      {
        key: 'status',
        name: 'Status',
        getFieldValue: (evidencePackage: EvidencePackage) => evidencePackage.status,
        onRender: (evidencePackage: EvidencePackage) => <>{evidencePackage.status}</>,
        minWidth: 75,
        maxWidth: 500,
      },
      {
        key: 'owner',
        name: 'Owner',
        getFieldValue: (evidencePackage: EvidencePackage) => evidencePackage.createdBy,
        onRender: (evidencePackage: EvidencePackage) => <>{evidencePackage.createdBy}</>,
        minWidth: 75,
        maxWidth: 500,
      },
    ],
    [],
  );

  const getLoadedUiState = () => {
    if (loadingState === LoadingState.Loaded) {
      return (
        <div className={containerStyles}>
          <Stack horizontal className={stackStyles}>
            <CommandBarButton iconProps={addIcon} text={createEvidencePackageText} onClick={onCreateEvidencePackageClick} />
            {/* BUTTON LOGIC TBD (in future story) */}
            <CommandBarButton iconProps={forwardIcon} text="Submit queued" />
          </Stack>
          <hr />
          <Stack horizontal className={stackStyles}>
            <Dropdown
              placeholder="Cloud"
              options={getOptions(ServiceEvidenceEnums.Cloud)}
              className={dropdownStyles}
              onChange={(_event, option) => onDropdownOptionsChange(ServiceEvidenceEnums.Cloud, option?.text)}
              ariaLabel="Cloud"
            />
            <Dropdown
              placeholder="Authorization level"
              options={getOptions(ServiceEvidenceEnums.Certification)}
              className={dropdownStyles}
              onChange={(_event, option) => onDropdownOptionsChange(ServiceEvidenceEnums.Certification, option?.text)}
              ariaLabel="Authorization level"
            />
            <Dropdown
              placeholder="Service"
              options={getOptions(ServiceEvidenceEnums.Service)}
              className={dropdownStyles}
              onChange={(_event, option) => onDropdownOptionsChange(ServiceEvidenceEnums.Service, option?.text)}
              ariaLabel="Service"
            />
            <Dropdown
              placeholder="Status"
              options={getOptions(ServiceEvidenceEnums.Status)}
              className={dropdownStyles}
              onChange={(_event, option) => onDropdownOptionsChange(ServiceEvidenceEnums.Status, option?.text)}
              ariaLabel="Status"
            />
            <Dropdown placeholder="Group by" options={options} className={groupByStyles} ariaLabel="Group by" />
          </Stack>
          {currentEvidencePackages && currentEvidencePackages.length ? (
            <BasicList items={currentEvidencePackages} columns={evidencePackageColumns} />
          ) : (
            <div className={buttonContainerStyles}>
              <Image src={neatClipboardArtSvg} alt="Neat Clipboard Art. Too bad it didn't load." imageFit={ImageFit.contain} />
              <h1 className={noEvidencePackagesStyles}>You have not created any evidence packages yet.</h1>
              <PrimaryButton className={buttonStyles} text={createEvidencePackageText} onClick={onCreateEvidencePackageClick} />
            </div>
          )}
        </div>
      );
    }

    return <Spinner size={SpinnerSize.medium} />;
  };

  return getLoadedUiState();
};
