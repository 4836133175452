import { IChoiceGroupOption } from '@fluentui/react';

export const riskCalculatorItems: RiskCalculatorGroup[] = [
  {
    children: [],
    key: 'base-modifier-group',
    name: 'Base modifier',
    startIndex: 0,
    count: 5,
    isCollapsed: true,
    defaultCollapsed: true,
    items: [
      {
        key: 'attack-vector',
        name: 'Attack Vector (AV)',
        items: [
          {
            key: 'mav:x',
            text: 'Not Defined (MAV:X)',
          },
          {
            key: 'mav:n',
            text: 'Network (MAV:N)',
          },
          {
            key: 'mav:a',
            text: 'Adjacent Network (MAV:A)',
          },
          {
            key: 'mav:l',
            text: 'Local (MAV:L)',
          },
          {
            key: 'mav:p',
            text: 'Physical (MAV:P)',
          },
        ],
      },
      {
        key: 'attack-complexity',
        name: 'Attack Complexity (AC)',
        items: [
          {
            key: 'mac:x',
            text: 'Not Defined (MAC:X)',
          },
          {
            key: 'mac:l',
            text: 'Low (MAC:L)',
          },
          {
            key: 'mac:h',
            text: 'High (MAC:H)',
          },
        ],
      },
      {
        key: 'privileges-required',
        name: 'Privileges Required (PR)',
        items: [
          {
            key: 'mpr:x',
            text: 'Not Defined (MPR:X)',
          },
          {
            key: 'mpr:n',
            text: 'None (MPR:N)',
          },
          {
            key: 'mpr:l',
            text: 'Low (MPR:L)',
          },
          {
            key: 'mpr:h',
            text: 'High (MPR:H)',
          },
        ],
      },
      {
        key: 'user-interaction',
        name: 'User Interaction (UI)',
        items: [
          {
            key: 'mui:x',
            text: 'Not Defined (MUI:X)',
          },
          {
            key: 'mui:n',
            text: 'None (MUI:N)',
          },
          {
            key: 'mui:r',
            text: 'Required (MUI:R)',
          },
        ],
      },
      {
        key: 'scope',
        name: 'Scope (S)',
        items: [
          {
            key: 'ms:x',
            text: 'Not Defined (MS:X)',
          },
          {
            key: 'ms:u',
            text: 'Unchanged (MS:U)',
          },
          {
            key: 'ms:c',
            text: 'Changed (MS:C)',
          },
        ],
      },
    ],
  },
  {
    children: [],
    key: 'impact-metrics-group',
    name: 'Impact metrics',
    startIndex: 5,
    count: 3,
    isCollapsed: true,
    defaultCollapsed: true,
    items: [
      {
        key: 'confidentiality-impact',
        name: 'Confidentiality Impact (C)',
        items: [
          {
            key: 'mc:x',
            text: 'Not Defined (MC:X)',
          },
          {
            key: 'mc:n',
            text: 'None (MC:N)',
          },
          {
            key: 'mc:l',
            text: 'Low (MC:L)',
          },
          {
            key: 'mc:h',
            text: 'High (MC:H)',
          },
        ],
      },
      {
        key: 'integrity-impact',
        name: 'Integrity Impact (I)',
        items: [
          {
            key: 'mi:x',
            text: 'Not Defined (MI:X)',
          },
          {
            key: 'mi:n',
            text: 'None (MI:N)',
          },
          {
            key: 'mi:l',
            text: 'Low (MI:L)',
          },
          {
            key: 'mi:h',
            text: 'High (MI:H)',
          },
        ],
      },
      {
        key: 'availability-impact',
        name: 'Availability Impact (A)',
        items: [
          {
            key: 'ma:x',
            text: 'Not Defined (MA:X)',
          },
          {
            key: 'ma:n',
            text: 'None (MA:N)',
          },
          {
            key: 'ma:l',
            text: 'Low (MA:L)',
          },
          {
            key: 'ma:h',
            text: 'High (MA:H)',
          },
        ],
      },
    ],
  },
  {
    children: [],
    key: 'impact-subscore-modifiers-group',
    name: 'Impact subscore modifiers',
    startIndex: 8,
    count: 3,
    isCollapsed: true,
    defaultCollapsed: true,
    items: [
      {
        key: 'confidentiality-requirement',
        name: 'Confidentiality Requirement (CR)',
        items: [
          {
            key: 'cr:x',
            text: 'Not Defined (CR:X)',
          },
          {
            key: 'cr:l',
            text: 'Low (CR:L)',
          },
          {
            key: 'cr:m',
            text: 'Medium (CR:M)',
          },
          {
            key: 'cr:h',
            text: 'High (CR:H)',
          },
        ],
      },
      {
        key: 'integrity-impact',
        name: 'Integrity Impact (IR)',
        items: [
          {
            key: 'ir:x',
            text: 'Not Defined (IR:X)',
          },
          {
            key: 'ir:l',
            text: 'Low (IR:L)',
          },
          {
            key: 'ir:m',
            text: 'Medium (IR:M)',
          },
          {
            key: 'ir:h',
            text: 'High (IR:H)',
          },
        ],
      },
      {
        key: 'availability-requirement',
        name: 'Availability Requirement (AR)',
        items: [
          {
            key: 'ar:x',
            text: 'Not Defined (AR:X)',
          },
          {
            key: 'ar:l',
            text: 'Low (AR:L)',
          },
          {
            key: 'ar:m',
            text: 'Medium (AR:M)',
          },
          {
            key: 'ar:h',
            text: 'High (AR:H)',
          },
        ],
      },
    ],
  },
  {
    children: [],
    key: 'temporal-score-metrics-group',
    name: 'Temporal score metrics',
    startIndex: 11,
    count: 3,
    isCollapsed: true,
    defaultCollapsed: true,
    items: [
      {
        key: 'exploitability',
        name: 'Exploitability (E)',
        items: [
          {
            key: 'e:x',
            text: 'Not Defined (E:X)',
          },
          {
            key: 'e:u',
            text: 'Unproven that exploits exists (E:U)',
          },
          {
            key: 'e:p',
            text: 'Proof of concept code (E:P)',
          },
          {
            key: 'e:f',
            text: 'Functional Exploit Exists (E:F)',
          },
          {
            key: 'e:h',
            text: 'High (E:H)',
          },
        ],
      },
      {
        key: 'remediation-level',
        name: 'Remediation Level (RL)',
        items: [
          {
            key: 'rl:x',
            text: 'Not Defined (RL:X)',
          },
          {
            key: 'rl:o',
            text: 'Official Fix (RL:O)',
          },
          {
            key: 'rl:t',
            text: 'Temporary Fix (RL:T)',
          },
          {
            key: 'rl:w',
            text: 'Workaround (RL:W)',
          },
          {
            key: 'rl:u',
            text: 'Unavailable (RL:U)',
          },
        ],
      },
      {
        key: 'report-confidence',
        name: 'Report Confidence (RC)',
        items: [
          {
            key: 'rc:x',
            text: 'Not Defined (RC:X)',
          },
          {
            key: 'rc:u',
            text: 'Unknown (RC:U)',
          },
          {
            key: 'rc:r',
            text: 'Reasonable (RC:R)',
          },
          {
            key: 'rc:c',
            text: 'Confirmed (RC:C)',
          },
        ],
      },
    ],
  },
];

export type RiskCalculatorItem = {
  key: string;
  name: string;
  items: IChoiceGroupOption[];
};

export type RiskCalculatorGroup = {
  children: any[];
  key: string;
  name: string;
  startIndex: number;
  count: number;
  isCollapsed: boolean;
  defaultCollapsed: boolean;
  items: RiskCalculatorItem[];
};

export type RiskCalculatorValues = {
  'base-modifier-group--attack-vector': RiskCalculatorSubValue;
  'base-modifier-group--attack-complexity': RiskCalculatorSubValue;
  'base-modifier-group--privileges-required': RiskCalculatorSubValue;
  'base-modifier-group--user-interaction': RiskCalculatorSubValue;
  'base-modifier-group--scope': RiskCalculatorSubValue;
  'impact-metrics-group--confidentiality-impact': RiskCalculatorSubValue;
  'impact-metrics-group--integrity-impact': RiskCalculatorSubValue;
  'impact-metrics-group--availability-impact': RiskCalculatorSubValue;
  'impact-subscore-modifiers-group--confidentiality-requirement': RiskCalculatorSubValue;
  'impact-subscore-modifiers-group--integrity-impact': RiskCalculatorSubValue;
  'impact-subscore-modifiers-group--availability-requirement': RiskCalculatorSubValue;
  'temporal-score-metrics-group--exploitability': RiskCalculatorSubValue;
  'temporal-score-metrics-group--remediation-level': RiskCalculatorSubValue;
  'temporal-score-metrics-group--report-confidence': RiskCalculatorSubValue;
};

export type RiskCalculatorJustifications = {
  'e:x': string;
  'e:u': string;
  'e:p': string;
  'e:f': string;
  'e:h': string;
  'rl:x': string;
  'rl:o': string;
  'rl:t': string;
  'rl:w': string;
  'rl:u': string;
  'rc:x': string;
  'rc:u': string;
  'rc:r': string;
  'rc:c': string;
  'mav:x': string;
  'mav:n': string;
  'mav:a': string;
  'mav:l': string;
  'mav:p': string;
  'mac:x': string;
  'mac:l': string;
  'mac:h': string;
  'mpr:x': string;
  'mpr:n': string;
  'mpr:l': string;
  'mpr:h': string;
  'mui:x': string;
  'mui:n': string;
  'mui:r': string;
  'ms:x': string;
  'ms:u': string;
  'ms:c': string;
  'mc:x': string;
  'mc:n': string;
  'mc:l': string;
  'mc:h': string;
  'mi:x': string;
  'mi:n': string;
  'mi:l': string;
  'mi:h': string;
  'ma:x': string;
  'ma:n': string;
  'ma:l': string;
  'ma:h': string;
  'cr:x': string;
  'cr:l': string;
  'cr:m': string;
  'cr:h': string;
  'ir:x': string;
  'ir:l': string;
  'ir:m': string;
  'ir:h': string;
  'ar:x': string;
  'ar:l': string;
  'ar:m': string;
  'ar:h': string;
};

export const emptyJustifications: RiskCalculatorJustifications = {
  'e:x': '',
  'e:u': '',
  'e:p': '',
  'e:f': '',
  'e:h': '',
  'rl:x': '',
  'rl:o': '',
  'rl:t': '',
  'rl:w': '',
  'rl:u': '',
  'rc:x': '',
  'rc:u': '',
  'rc:r': '',
  'rc:c': '',
  'mav:x': '',
  'mav:n': '',
  'mav:a': '',
  'mav:l': '',
  'mav:p': '',
  'mac:x': '',
  'mac:l': '',
  'mac:h': '',
  'mpr:x': '',
  'mpr:n': '',
  'mpr:l': '',
  'mpr:h': '',
  'mui:x': '',
  'mui:n': '',
  'mui:r': '',
  'ms:x': '',
  'ms:u': '',
  'ms:c': '',
  'mc:x': '',
  'mc:n': '',
  'mc:l': '',
  'mc:h': '',
  'mi:x': '',
  'mi:n': '',
  'mi:l': '',
  'mi:h': '',
  'ma:x': '',
  'ma:n': '',
  'ma:l': '',
  'ma:h': '',
  'cr:x': '',
  'cr:l': '',
  'cr:m': '',
  'cr:h': '',
  'ir:x': '',
  'ir:l': '',
  'ir:m': '',
  'ir:h': '',
  'ar:x': '',
  'ar:l': '',
  'ar:m': '',
  'ar:h': '',
};

export type RiskCalculatorSubValue = {
  option: keyof RiskCalculatorJustifications;
  justification: string;
  type: string;
};

export const getDefaultRiskCalculatorValues = (): RiskCalculatorValues => {
  const calculator = {
    'temporal-score-metrics-group--exploitability': {
      option: 'e:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'e:',
    },
    'temporal-score-metrics-group--remediation-level': {
      option: 'rl:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'rl:',
    },
    'temporal-score-metrics-group--report-confidence': {
      option: 'rc:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'rc:',
    },
    'impact-subscore-modifiers-group--confidentiality-requirement': {
      option: 'cr:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'cr:',
    },
    'impact-subscore-modifiers-group--integrity-impact': {
      option: 'ir:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'ir:',
    },
    'impact-subscore-modifiers-group--availability-requirement': {
      option: 'ar:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'ar:',
    },
    'base-modifier-group--attack-vector': {
      option: 'mav:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'mav:',
    },
    'base-modifier-group--attack-complexity': {
      option: 'mac:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'mac:',
    },
    'base-modifier-group--privileges-required': {
      option: 'mpr:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'mpr:',
    },
    'base-modifier-group--user-interaction': {
      option: 'mui:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'mui:',
    },
    'base-modifier-group--scope': {
      option: 'ms:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'ms:',
    },
    'impact-metrics-group--confidentiality-impact': {
      option: 'mc:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'mc:',
    },
    'impact-metrics-group--integrity-impact': {
      option: 'mi:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'mi:',
    },
    'impact-metrics-group--availability-impact': {
      option: 'ma:x' as keyof RiskCalculatorJustifications,
      justification: '',
      type: 'ma:',
    },
  };

  return calculator;
};
