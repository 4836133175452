import { NeutralColors, Stack, mergeStyles } from '@fluentui/react';
import { SummaryWidget } from 'components/widgets/summaryWidget';
import React, { useContext, useEffect } from 'react';
import { NewSummaryWidget } from 'components/widgets/newSummaryWidget';
import { pageChromeChildStyles } from 'styles/pageChromeChildStyles';
import { AuthContext } from '../../components/authProvider/authContext';
import { ActionItemWidget, StatusMapping } from '../../components/widgets/actionItemWidget';
import { PendingActionsKey, PendingActionsWidget } from '../../components/widgets/pendingActionsWidget';
import { UpcomingServicesUserRoleKeys, UpcomingServicesWidget } from '../../components/widgets/upcomingServicesWidget';
import { ActionItemKeys, WorkloadInsightsWidget } from '../../components/widgets/workloadInsightsWidget';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { AUDITOR_ACTIONS_READ, AUTHORIZATION_ACTIONS_READ, COMPLIANCE_TEAM_ACTIONS_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { createHomeChromeConfig, updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';

const widgetWrapperStyle = mergeStyles(pageChromeChildStyles, {
  backgroundColor: NeutralColors.white,
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 320px)': {
    overflowX: 'scroll',
  },
});

const secondRowStyle = mergeStyles({
  display: 'flex',
  flexWrap: 'wrap',
});

export const ActionCenterPage: React.FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const auditorActionMapping: StatusMapping = {
    notStarted: EvidencePackageStatus.AuditorSubmit,
    inProgress: EvidencePackageStatus.AuditorReview,
  };
  const aoActionMapping: StatusMapping = {
    notStarted: EvidencePackageStatus.AoSubmit,
    inProgress: EvidencePackageStatus.AoReview,
  };
  const compliancePMActionMapping: StatusMapping = {
    notStarted: EvidencePackageStatus.AoReturned,
    alternateStatus: EvidencePackageStatus.AuditorReturned,
    inProgress: EvidencePackageStatus.Approved,
  };
  const auditorWorkloadInsightsKeys: ActionItemKeys = {
    openActionItemsKey: 'auditorOpenActionItemsCount',
    closedActionItemsKey: 'auditorClosedActionItemsCount',
  };
  const aoWorkloadInsightsKeys: ActionItemKeys = {
    openActionItemsKey: 'authorizingOfficialOpenActionItemsCount',
    closedActionItemsKey: 'authorizingOfficialClosedActionItemsCount',
  };
  const auditorPendingActionsKeys: PendingActionsKey[] = [
    { label: 'Evidence', property: 'pendingEvidencePackageReviewCount' },
    { label: 'Attestation letter', property: 'pendingDocumentUploadCount' },
  ];
  const aoPendingActionsKeys: PendingActionsKey[] = [
    { label: 'Evidence', property: 'pendingEvidencePackageReviewCount' },
    { label: 'Authorization memo', property: 'pendingDocumentUploadCount' },
  ];
  const compliancePMPendingActionKeys: PendingActionsKey[] = [
    { label: 'Approved', property: 'pendingEvidencePackagePMAchieved' },
    { label: 'Returned packages', property: 'pendingEvidencePackageReturned' },
  ];

  const upcomingServicesUserRoleKeys: UpcomingServicesUserRoleKeys = {
    aoKeys: {
      projectedDateKey: 'aoProjectedDate',
      timelineKey: 'aoExpectedServiceTimeline',
    },
    auditorKeys: {
      projectedDateKey: 'auditorProjectedDate',
      timelineKey: 'auditorExpectedServiceTimeline',
    },
  };
  const isAuditor = authContext.isAuthorized([{ operation: AUDITOR_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isAO = authContext.isAuthorized([{ operation: AUTHORIZATION_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    updatePageChromeConfig(createHomeChromeConfig('action-center', isComplianceTeam, [], '#ffffff'));
    return () => updatePageChromeConfig();
  }, [isComplianceTeam]);

  const auditorWidgets = (
    <div className={widgetWrapperStyle}>
      <div className={secondRowStyle}>
        <SummaryWidget />
        <PendingActionsWidget
          userRoleKey="auditorPendingAction"
          chartKeys={auditorPendingActionsKeys}
          userRoleTitle={isAO && isAuditor ? 'Auditor' : ''}
        />
        <UpcomingServicesWidget
          userRoleTitle={isAO && isAuditor ? 'auditor ' : ''}
          upcomingServiceKeysForRole={upcomingServicesUserRoleKeys.auditorKeys}
          allExpectedServicesPanelEnabled
        />
      </div>
      <div className={secondRowStyle}>
        <ActionItemWidget statusMapping={auditorActionMapping} userRoleTitle={isAO && isAuditor ? 'auditor ' : ''} />
        <WorkloadInsightsWidget actionItemKeys={auditorWorkloadInsightsKeys} userRoleTitle={isAO && isAuditor ? ' - auditor' : ''} />
      </div>
    </div>
  );

  const aoWidgets = (
    <div className={widgetWrapperStyle}>
      <PendingActionsWidget
        userRoleKey="authorizingOfficialPendingAction"
        chartKeys={aoPendingActionsKeys}
        userRoleTitle={isAO && isAuditor ? 'Authorizing Official' : ''}
      />
      <UpcomingServicesWidget
        userRoleTitle={isAO && isAuditor ? ' - authorizing official' : ''}
        upcomingServiceKeysForRole={upcomingServicesUserRoleKeys.aoKeys}
        allExpectedServicesPanelEnabled
      />
      <div className={secondRowStyle}>
        <ActionItemWidget statusMapping={aoActionMapping} userRoleTitle={isAO && isAuditor ? 'authorizing official ' : ''} />
        <WorkloadInsightsWidget actionItemKeys={aoWorkloadInsightsKeys} userRoleTitle={isAO && isAuditor ? ' - authorizing official' : ''} />
      </div>
    </div>
  );

  const complianceTeamWidgets = (
    <div className={widgetWrapperStyle}>
      <NewSummaryWidget />
      <Stack horizontal>
        <PendingActionsWidget userRoleKey="compliancePMPendingAction" chartKeys={compliancePMPendingActionKeys} userRoleTitle="Compliance PM" />
        <ActionItemWidget statusMapping={compliancePMActionMapping} userRoleTitle="Compliance PM " />
      </Stack>
    </div>
  );

  return (
    <div className={widgetWrapperStyle}>
      <h2>Action Center</h2>
      {isAuditor && auditorWidgets}
      {isAO && aoWidgets}
      {isComplianceTeam && complianceTeamWidgets}
    </div>
  );
};
