import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { LoadingState } from 'models/loadingState';
import { logError } from 'modules/logging/logging';
import { getNotificationSettings } from 'modules/notificationSetting/notificationSetting';
import { INotificationSettings } from 'generated/clientApi';
import { NotificationSettingContext, NotificationSettingContextProps } from './notificationSettingContext';

export const NotificationSettingProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [notificationSettings, setNotificationSettings] = useState<INotificationSettings | undefined>(undefined);
  const [notificationSettingsLoaded, setNotificationSettingsLoaded] = useState<LoadingState>(LoadingState.NotLoaded);

  const contextProps: NotificationSettingContextProps = {
    notificationSettings,
    notificationSettingsLoadingState: notificationSettingsLoaded,
    requestNotificationSettings: () => {
      if (notificationSettingsLoaded === LoadingState.NotLoaded) {
        setNotificationSettingsLoaded(LoadingState.Requested);
      }
    },
    updateNotificationSettings: (notificationSettings: INotificationSettings) => {
      setNotificationSettings(notificationSettings);
    },
  };

  // Auto grab the notification settings if someone requests them
  useEffect(() => {
    const getAllSettings = async () => {
      try {
        const allSettings = await getNotificationSettings();
        setNotificationSettings(allSettings);
        setNotificationSettingsLoaded(LoadingState.Loaded);
      } catch (error) {
        setNotificationSettingsLoaded(LoadingState.Error);
        logError('There was an issue loading the notification settings', error);
      }
    };

    if (notificationSettingsLoaded === LoadingState.Requested && notificationSettings === undefined) {
      getAllSettings();
    }
  }, [notificationSettingsLoaded, notificationSettings]);

  return <NotificationSettingContext.Provider value={contextProps}>{children}</NotificationSettingContext.Provider>;
};
