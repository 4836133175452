import {
  ISnapshotPostResponse,
  SnapshotListClient,
  SnapshotListResponse,
  SnapshotPostClient,
  ISnapshotPostCommand,
  SnapshotPostCommand,
  ISnapshotGetResponse,
  SnapshotGetClient,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const createSnapshot = async (snapShot: ISnapshotPostCommand): Promise<ISnapshotPostResponse> => {
  const client = new SnapshotPostClient(getConfig().apiBaseUri);
  return client.post(snapShot.serviceOid, snapShot as SnapshotPostCommand);
};

export const getSnapshots = async (serviceOid: string, cloud: string): Promise<SnapshotListResponse> => {
  const client = new SnapshotListClient(getConfig().apiBaseUri);
  return client.get(serviceOid, cloud);
};

export const getSnapshot = async (snapshotId: string): Promise<ISnapshotGetResponse> => {
  const client = new SnapshotGetClient(getConfig().apiBaseUri);
  return client.get(snapshotId);
};
