import { Certification } from '../../models/certification';
import { EvidencePackageUserStatus, IEvidencePackage } from '../../generated/clientApi';

export const isPackageAOApproved = (evidencePackage: IEvidencePackage): boolean => {
  if (
    evidencePackage.certification === Certification.FedRAMP &&
    isDecisionUnanimous(evidencePackage) &&
    evidencePackage.dodUserDecision?.userStatus.toString() === EvidencePackageUserStatus[EvidencePackageUserStatus.Approved]
  ) {
    return true;
  }

  if (
    evidencePackage.certification !== Certification.FedRAMP &&
    evidencePackage.aoUserDecision?.userStatus.toString() === EvidencePackageUserStatus[EvidencePackageUserStatus.Approved]
  ) {
    return true;
  }

  return false;
};

export const isDecisionUnanimous = (evidencePackage: IEvidencePackage): boolean => {
  // A role has not "voted"
  if (
    evidencePackage.dodUserDecision === undefined ||
    evidencePackage.dhsUserDecision === undefined ||
    evidencePackage.gsaUserDecision === undefined
  ) {
    return false;
  }

  return (
    evidencePackage.dodUserDecision?.userStatus === evidencePackage.dhsUserDecision?.userStatus &&
    evidencePackage.dodUserDecision?.userStatus === evidencePackage.gsaUserDecision?.userStatus
  );
};
