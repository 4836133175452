import { IBreadcrumbItem, Dropdown, IDropdownOption, Pivot, PivotItem, mergeStyleSets, mergeStyles } from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BladeHeader } from 'components/bladeHeader/bladeHeader';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { HeroContentContext } from 'components/heroContentProvider/heroContentContext';
import { ConmonOrganization, LockedStatusGetClient, LockedStatusGetResponse } from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import { getConmonOrganizations } from 'modules/organization/organization';
import { ValueOf } from 'components/util/objectUtils';
import { CenteredProgressDots } from 'components/progressDots/progressDots';
import { showError } from 'modules/messageBar/messageBar';
import { useQueryParameters } from 'components/util/useQueryParameters';
import { historyParamUpdate } from 'components/util/historyUtils';
import { PoamTab } from './poamTab/poamTab';
import { ReportTab } from './reportTab/reportTab';
import { DefaultPoamColumns, PoamColumns } from './poamTab/poamTable';
import { DefaultPoamFilters, PoamFilterNames } from './poamTab/poamFilterBar';
import { dropdownStyles } from './coverageTab/filterBar';
import { PeriodFilter } from './components/periodFilter';
import { CoverageTabDataContainer } from './coverageTab/coverageTabDataContainer';

const conMonPageStyles = mergeStyles({
  marginLeft: '1rem',
  marginRight: '1rem',
  '.ms-Check-circle': {
    borderRadius: '100%',
  },
  '@media (max-height: 350px)': {
    overflow: 'auto',
  },
});

export const separatorStyles = mergeStyleSets({
  root: {
    height: '1px',
    '&::before': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      opacity: 0.9,
    },
  },
});

export const conmonTabKeys = {
  coverage: 'coverage',
  poam: 'poam',
  reports: 'reports',
} as const;

const orgDropdownStyles = mergeStyleSets(dropdownStyles, {
  dropdown: {
    width: '100px',
  },
});

export const ConMon: React.FunctionComponent = () => {
  const { tab } = useParams<{ tab?: string }>();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParameters();
  const [conmonOrgs, setConmonOrgs] = useState<ConmonOrganization[]>([]);
  const [organization, setOrganization] = useState<string>((queryParams.organization as string) ?? '');

  const conmonDropdownOptions: IDropdownOption[] = useMemo(
    () => [{ key: 'all', text: 'All' }, ...conmonOrgs.map((org) => ({ key: org.id, text: org.name }))],
    [conmonOrgs],
  );

  const [lockedStatus, setLockedStatus] = useState<LockedStatusGetResponse[]>([]);
  const lockedMap: Record<string, boolean> = useMemo(() => {
    if (!organization || organization.toLowerCase() === 'all') {
      return Object.fromEntries(lockedStatus.map((status) => [status.period, true]));
    }
    const orgStatuses = new Map<string, boolean>(
      lockedStatus.filter((status) => status.organization === organization).map((status) => [status.period, status.locked as boolean]),
    );
    return lockedStatus.reduce(
      (acc, status) => {
        const { period } = status;
        acc[period] = orgStatuses.get(period) ?? true;
        return acc;
      },
      {} as Record<string, boolean>,
    );
  }, [lockedStatus, organization]);

  const periods = useMemo(() => Object.keys(lockedMap), [lockedMap]);

  const [period, setPeriod] = useState<string>((queryParams.period as string) ?? '');

  const onPeriodChange = (period: string) => {
    setPeriod(period);
    historyParamUpdate(history, { period });
  };

  useEffect(() => {
    if (!period && periods.length > 0) {
      onPeriodChange(periods[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, periods]);

  const onOrganizationChange = (organization: string) => {
    setOrganization(organization);
    const newValue = organization === 'all' || organization === '' ? undefined : organization;
    historyParamUpdate(history, { organization: newValue });
  };

  const getInitialPoamColumns = () => {
    const storedColumns = JSON.parse(sessionStorage.getItem('poamColumns') ?? '[]') as (keyof PoamColumns)[];
    return storedColumns.length > 0 ? storedColumns : DefaultPoamColumns;
  };

  const updatePoamColumns = (columns: (keyof PoamColumns)[]) => {
    sessionStorage.setItem('poamColumns', JSON.stringify(columns));
    setPoamColumns(columns);
  };

  const getInitialPoamFilters = () => {
    const storedFilters = JSON.parse(sessionStorage.getItem('poamFilters') ?? '[]') as ValueOf<typeof PoamFilterNames>[];
    return storedFilters.length > 0 ? storedFilters : DefaultPoamFilters;
  };

  const updatePoamFilters = (filters: ValueOf<Partial<typeof PoamFilterNames>>[]) => {
    sessionStorage.setItem('poamFilters', JSON.stringify(filters));
    setPoamFilters(filters);
  };

  const [poamColumns, setPoamColumns] = useState<(keyof PoamColumns)[]>(getInitialPoamColumns());
  const [poamFilters, setPoamFilters] = useState<ValueOf<typeof PoamFilterNames>[]>(getInitialPoamFilters());

  const getConmonOrganizationList = async () => {
    const organizations = await getConmonOrganizations();
    setConmonOrgs(organizations);
  };

  useEffect(() => {
    const client = new LockedStatusGetClient(getConfig().apiBaseUri);
    const fetchLockedStatus = async () => {
      try {
        const result = await client.get();
        setLockedStatus(result);
      } catch (error) {
        showError('Failed to load ConMon. Please refresh to try again.');
      }
    };
    fetchLockedStatus();
    getConmonOrganizationList();
  }, []);

  const heroContentContext = useRef(useContext(HeroContentContext));
  useEffect(() => {
    heroContentContext.current.setShowHero(false);
  }, []);

  const onTabChange = useCallback(
    (item?: PivotItem): void => {
      if (item?.props?.itemKey) {
        const queryParams = new URLSearchParams(location.search);
        const period = queryParams.get('period');
        const organization = queryParams.get('organization');

        const newQueryParams = new URLSearchParams();
        if (period) newQueryParams.set('period', period);
        if (organization) newQueryParams.set('organization', organization);

        history.push({
          pathname: item.props.itemKey,
          search: newQueryParams.toString(),
        });
      }
    },
    [history, location.search],
  );

  const conMonBreadcrumbItems: IBreadcrumbItem[] = [
    {
      text: 'Home',
      key: 'Home',
      onClick: () => history.push('../'),
    },
    {
      text: 'Continuous Monitoring',
      key: 'ConMon',
      isCurrentItem: true,
    },
  ];

  if (tab === undefined) {
    return <Redirect to={`${location.pathname}/${conmonTabKeys.coverage}`} />;
  }
  return (
    <div>
      <BladeHeader breadcrumbs={conMonBreadcrumbItems} />
      <div className={conMonPageStyles}>
        {Object.keys(lockedMap).length === 0 ? (
          <div style={{ marginTop: '25%' }}>
            <CenteredProgressDots />
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                gap: '2.5rem',
                alignItems: 'center',
                position: 'sticky',
                top: '0',
                width: '100%',
                background: 'white',
                zIndex: 100,
              }}
            >
              <h2 style={{ fontWeight: 600 }}>Continuous Monitoring</h2>
              <div style={{ marginTop: '6px' }}>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <PeriodFilter periods={periods} selectedPeriod={period || periods[0]} onPeriodChange={onPeriodChange} />
                  <Dropdown
                    selectedKey={organization || 'all'}
                    options={conmonDropdownOptions}
                    styles={orgDropdownStyles}
                    onChange={(ev, item) => onOrganizationChange(item?.key as string)}
                  />
                </div>
              </div>
            </div>
            <div style={{ position: 'sticky', top: '55px', width: '100%', background: 'white', zIndex: 100 }}>
              <Pivot key={tab} defaultSelectedKey={tab} onLinkClick={onTabChange} selectedKey={tab} headersOnly>
                <PivotItem headerText="Coverage" itemKey={conmonTabKeys.coverage} />
                <PivotItem headerText="POA&M" itemKey={conmonTabKeys.poam} />
                <PivotItem headerText="Reports" itemKey={conmonTabKeys.reports} />
              </Pivot>
            </div>
            {tab === conmonTabKeys.coverage && <CoverageTabDataContainer lockedMap={lockedMap} />}

            {tab === conmonTabKeys.poam && (
              <PoamTab
                lockedMap={lockedMap}
                poamColumns={poamColumns}
                updatePoamColumns={updatePoamColumns}
                poamFilters={poamFilters}
                updatePoamFilters={updatePoamFilters}
              />
            )}
            {tab === conmonTabKeys.reports && <ReportTab lockedMap={lockedMap} />}
          </>
        )}
      </div>
    </div>
  );
};
