import { ChoiceGroup, IChoiceGroupOption, IContextualMenuProps, IDialogContentProps, mergeStyles } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { isEvidencePackageResumbit as isEvidencePackageResubmit } from 'components/util/evidenceUtil';
import { ServiceContext } from 'components/serviceProvider/serviceContext';
import { EvidenceContext } from 'components/evidenceProvider/evidenceContext';
import { isUSNat } from 'components/util/cloudUtil';
import { ConfigContext } from 'components/configProvider/configContext';
import { EvidenceDetail } from '../../pages/evidenceDetail/evidenceDetail';
import {
  IEvidencePackage,
  FileAttachment,
  IOffering,
  Offering,
  IAzureService,
  IEvidencePackagePutCommand,
  EvidencePackageCommentsPutCommand,
  SupplementalInfo,
} from '../../generated/clientApi';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { showDialogModal } from '../../modules/dialog/dialog';
import {
  updateEvidencePackage,
  updateEvidencePackageQueue,
  updateEvidencePackageRecall,
  updateSingleEvidencePackageSubmission,
} from '../../modules/evidencePackage/evidencePackage';
import { logError } from '../../modules/logging/logging';
import { showError, showSuccess } from '../../modules/messageBar/messageBar';

const modalBodyStyle = mergeStyles({
  marginBottom: '1em',
});

const enum Action {
  Save = 'Save',
  Submit = 'Submit',
  Queue = 'Queue',
  Recall = 'Recall',
}

interface EvidencePackageTabProps {
  cloud: string;
  certification: string;
  fileAttachments: FileAttachment[];
  setFileAttachments(fileAttachments: FileAttachment[]): void;
  offerings?: IOffering[] | undefined;
  onClickPreviousButton(): void;
  navMenuTab: string;
  showChat: boolean;
}

export const EvidencePackageTab: FunctionComponent<EvidencePackageTabProps> = (props) => {
  const { cloud, certification, fileAttachments, setFileAttachments, offerings, onClickPreviousButton, navMenuTab, showChat } = props;
  const servicesContext = useContext(ServiceContext);
  const evidenceContext = useContext(EvidenceContext);
  const configContext = useContext(ConfigContext);
  const [service, setService] = useState<IAzureService>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const submitToAuthorizingOfficial =
    isEvidencePackageResubmit(evidenceContext.evidencePackage, EvidencePackageStatus.AoReturned) || isUSNat(configContext.serverConfig?.cloud);

  useEffect(() => {
    setService(servicesContext.selectedService);
  }, [servicesContext]);

  const submitEvidencePackage = async (evidencePackage: IEvidencePackage, action: Action, queuedOffering?: IOffering) => {
    try {
      let successActionText;

      switch (action) {
        case Action.Queue:
          if (!queuedOffering) {
            showError('Please select an offering.');
            return;
          }
          successActionText = 'queued';
          await updateEvidencePackageQueue({
            id: evidencePackage.id,
            serviceOid: evidencePackage.serviceOid,
            offering: new Offering(queuedOffering),
          });
          break;
        case Action.Recall:
          successActionText = 'recalled';
          await updateEvidencePackageRecall({
            id: evidencePackage.id,
            serviceOid: evidencePackage.serviceOid,
          });
          break;
        case Action.Submit:
          successActionText = 'submitted';
          await updateSingleEvidencePackageSubmission({
            id: evidencePackage.id,
            serviceOid: evidencePackage.serviceOid,
          });
          break;
        default:
          throw new Error('Invalid submit button action.');
      }

      evidenceContext.reloadEvidencePackage();
      showSuccess(`Successfully ${successActionText} the evidence package: ${evidencePackage.name}`);
    } catch (error) {
      const errorMessage = 'There was an issue updating the evidence package.';
      logError(`${errorMessage} Service oid: ${service?.id}, Evidence Package ID: ${evidencePackage.id}`, error);
      showError(`${errorMessage} Package not found.`);
    }
  };

  const saveButtonClick = async () => {
    if (!evidenceContext.evidencePackage?.id || !servicesContext.selectedService?.id) {
      return;
    }

    try {
      setIsSaving(true);
      const evidencePackageCommand: IEvidencePackagePutCommand = {
        serviceOid: servicesContext.selectedService.id,
        evidencePackageId: evidenceContext.evidencePackage.id,
        certification: evidenceContext.evidencePackage.certification,
        cloud: evidenceContext.evidencePackage.cloud,
        supplementalInfo: evidenceContext.supplementalInfo as SupplementalInfo,
        comments: evidenceContext.supportingComments as EvidencePackageCommentsPutCommand[],
      };

      await updateEvidencePackage(evidenceContext.evidencePackage.id, evidencePackageCommand);
      evidenceContext.reloadEvidencePackage();
    } catch (error) {
      showError(`Error updating the evidence package: ${error}`);
    }
    setIsSaving(false);
  };

  const submitButtonClick = async (evidencePackage: IEvidencePackage | undefined) => {
    if (!evidencePackage) {
      return;
    }

    const dialogContents: IDialogContentProps = {
      title: 'Are you sure you want to submit this evidence package?',
    };

    const role = submitToAuthorizingOfficial ? 'Authorizing Official' : 'Auditor';
    const bodyText = `You are about to submit package ${evidencePackage.name} to the ${role}. Are you sure you want to submit this package?`;

    const modalBody = <div className={modalBodyStyle}>{bodyText}</div>;
    showDialogModal(dialogContents, () => submitEvidencePackage(evidencePackage, Action.Submit), modalBody, undefined, undefined, '600px');
  };

  const queueButtonClick = async (evidencePackage: IEvidencePackage | undefined) => {
    if (!evidencePackage) {
      return;
    }

    if (!offerings?.length) {
      return;
    }
    let queuedOffering: IOffering;

    const offeringOptions = offerings
      .sort((offeringA, offeringB) => offeringA.name.localeCompare(offeringB.name))
      .map((offering) => ({ key: offering.id, text: offering.name }));

    queuedOffering = { id: offeringOptions[0].key, name: offeringOptions[0].text };
    const defaultSelectedKey = offeringOptions[0].key;

    const dialogContents: IDialogContentProps = {
      title: 'Which offering would you like to queue this service for?',
    };

    const handleOfferingsChange = (_ev: FormEvent<HTMLElement | HTMLInputElement> | undefined, selectedOption: IChoiceGroupOption | undefined) => {
      if (!selectedOption || !selectedOption.text) {
        return;
      }
      queuedOffering = { id: selectedOption.key, name: selectedOption.text };
    };

    const modalBody = <ChoiceGroup options={offeringOptions} defaultSelectedKey={defaultSelectedKey} onChange={handleOfferingsChange} />;
    showDialogModal(
      dialogContents,
      () => submitEvidencePackage(evidencePackage, Action.Queue, queuedOffering),
      modalBody,
      undefined,
      'Queue',
      '600px',
    );
  };

  const recallButtonClick = async (evidencePackage: IEvidencePackage | undefined) => {
    if (!evidencePackage) {
      return;
    }

    const dialogContents: IDialogContentProps = {
      title: 'Are you sure you want to recall this evidence package?',
    };

    const bodyText = `You are about to recall evidence package ${evidencePackage.name}.  It is currently queued as part of the ${evidencePackage.offering?.name} offering. Do you still want to recall this package?"`;

    const modalBody = <div className={modalBodyStyle}>{bodyText}</div>;
    showDialogModal(dialogContents, () => submitEvidencePackage(evidencePackage, Action.Recall), modalBody, undefined, undefined, '600px');
  };

  const isAuthorizationEnabled =
    service?.authorizationsActive.find(
      (serviceEnabledAuthorization) =>
        serviceEnabledAuthorization.cloud === cloud &&
        serviceEnabledAuthorization.certification === certification &&
        serviceEnabledAuthorization.isActive,
    ) !== undefined;
  const isEvidenceCollectionStage = service?.isEvidenceGatheringEnabled;
  const isSubmitted =
    evidenceContext.evidencePackage?.status !== EvidencePackageStatus.Approved &&
    evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AuditorReturned &&
    evidenceContext.evidencePackage?.status !== EvidencePackageStatus.AoReturned &&
    evidenceContext.evidencePackage?.submittedDate !== undefined;

  const submitButtonMenuProps = (): IContextualMenuProps => {
    const isAnyOfferings = offerings !== undefined && offerings.length > 0;
    const queueButtonToolTip = isAnyOfferings ? '' : 'This service does not have any offerings.';

    const isSubmitButtonDisabled =
      isSubmitted ||
      !isEvidenceCollectionStage ||
      !isAuthorizationEnabled ||
      evidenceContext.evidencePackage?.status === EvidencePackageStatus.Queued;

    const isQueueButtonDisabled = !isAnyOfferings;
    const isRecallButtonDisabled = evidenceContext.evidencePackage?.status !== EvidencePackageStatus.Queued || isSubmitted;
    const submitActionText = submitToAuthorizingOfficial ? 'Submit to Authorizing Official' : 'Submit to Auditor';

    const menuProps: IContextualMenuProps = {
      items: [
        {
          key: Action.Save,
          text: Action.Save,
          onClick: () => {
            saveButtonClick();
          },
        },
        {
          key: Action.Submit,
          text: submitActionText,
          disabled: isSubmitButtonDisabled,
          onClick: () => {
            submitButtonClick(evidenceContext.evidencePackage);
          },
        },
        {
          key: Action.Queue,
          text: Action.Queue,
          disabled: isQueueButtonDisabled,
          title: queueButtonToolTip,
          onClick: () => {
            queueButtonClick(evidenceContext.evidencePackage);
          },
        },
        {
          key: Action.Recall,
          text: Action.Recall,
          disabled: isRecallButtonDisabled,
          onClick: () => {
            recallButtonClick(evidenceContext.evidencePackage);
          },
        },
      ],
    };
    return menuProps;
  };

  return (
    <EvidenceDetail
      serviceOid={service?.id || ''}
      serviceName={service?.name || ''}
      fileAttachments={fileAttachments}
      setFileAttachments={setFileAttachments}
      onClickPreviousButton={onClickPreviousButton}
      submitButtonMenuProps={submitButtonMenuProps()}
      submitButtonText="Actions"
      submitButtonSaving={isSaving}
      navMenuTab={navMenuTab}
      showChat={showChat}
    />
  );
};
