import { IStackStyles, IStackTokens, mergeStyles, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../components/authProvider/authContext';
import { HeroContentContext } from '../../components/heroContentProvider/heroContentContext';
import { InfoCard } from '../../components/infoCard/infoCard';
import { PendingActionsKey, PendingActionsWidget } from '../../components/widgets/pendingActionsWidget';
import { UpcomingServicesUserRoleKeys, UpcomingServicesWidget } from '../../components/widgets/upcomingServicesWidget';
import {
  COMPLIANCE_TEAM_ACTIONS_READ,
  EVIDENCE_PACKAGE_AUDITING_REVIEW_READ,
  EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_READ,
  SITE_WIDE_SUBJECT,
} from '../../modules/constants';
import { actionCenterRoute, offeringsRoute, servicesRoute } from '../../modules/routes/routes';
import { ControlEvidencePageHeroContent } from './controlEvidenceHeroContent';
import actionCenterInfoCardImage from '../../assets/actioncenter_infocard_image.png';
import offeringsInfoCardImage from '../../assets/offerings_infocard_image.png';
import servicesInfoCardImage from '../../assets/services_infocard_image.png';

const labelStyle = mergeStyles({
  padding: '2em',
  fontSize: '2em',
  marginTop: '2em',
  height: 'fit-content',
  fontWeight: 'bold',
  marginRight: '10em',
  maxWidth: '100em',
  alignItems: 'left',
});

const infoCardStyles = mergeStyles({
  marginLeft: '10em',
  marginRight: '10em',
  maxWidth: '100em',
});

const widgetContainerStyles = mergeStyles({
  marginTop: '5em',
  marginLeft: '10em',
  marginRight: '10em',
  maxWidth: '100em',
});

const stackStyles: IStackStyles = {
  root: {
    width: '75em',
  },
};

const themedExtraLargeStackTokens: IStackTokens = {
  childrenGap: 'l2',
  padding: 'l2',
};

const containerStackTokens: IStackTokens = { childrenGap: 30 };

export const ControlEvidencePage: React.FunctionComponent = () => {
  const authContext = useContext(AuthContext);
  const heroContentContext = useRef(useContext(HeroContentContext));

  useEffect(() => {
    heroContentContext.current.setAndDisplayHeroContent(ControlEvidencePageHeroContent, false);
  }, []);

  const auditorPendingActionsKeys: PendingActionsKey[] = [
    { label: 'Evidence', property: 'pendingEvidencePackageReviewCount' },
    { label: 'Attestation letter', property: 'pendingDocumentUploadCount' },
  ];
  const aoPendingActionsKeys: PendingActionsKey[] = [
    { label: 'Evidence', property: 'pendingEvidencePackageReviewCount' },
    { label: 'Authorization memo', property: 'pendingDocumentUploadCount' },
  ];
  const upcomingServicesUserRoleKeys: UpcomingServicesUserRoleKeys = {
    aoKeys: {
      projectedDateKey: 'aoProjectedDate',
      timelineKey: 'aoExpectedServiceTimeline',
    },
    auditorKeys: {
      projectedDateKey: 'auditorProjectedDate',
      timelineKey: 'auditorExpectedServiceTimeline',
    },
  };
  const isAuditor = authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_AUDITING_REVIEW_READ, subject: SITE_WIDE_SUBJECT }]);
  const isAO = authContext.isAuthorized([{ operation: EVIDENCE_PACKAGE_AUTHORIZING_REVIEW_READ, subject: SITE_WIDE_SUBJECT }]);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);

  const auditorWidgets = (
    <Stack horizontal styles={stackStyles} tokens={themedExtraLargeStackTokens} horizontalAlign="space-between">
      <PendingActionsWidget
        userRoleKey="auditorPendingAction"
        chartKeys={auditorPendingActionsKeys}
        userRoleTitle={isAO && isAuditor ? ' - auditor' : ''}
      />
      <UpcomingServicesWidget
        userRoleTitle={isAO && isAuditor ? 'auditor ' : ''}
        upcomingServiceKeysForRole={upcomingServicesUserRoleKeys.auditorKeys}
        allExpectedServicesPanelEnabled={false}
      />
    </Stack>
  );

  const aoWidgets = (
    <Stack horizontal styles={stackStyles} tokens={themedExtraLargeStackTokens} horizontalAlign="space-between">
      <PendingActionsWidget
        userRoleKey="authorizingOfficialPendingAction"
        chartKeys={aoPendingActionsKeys}
        userRoleTitle={isAO && isAuditor ? ' - authorizing official' : ''}
      />
      <UpcomingServicesWidget
        userRoleTitle={isAO && isAuditor ? ' - authorizing official' : ''}
        upcomingServiceKeysForRole={upcomingServicesUserRoleKeys.aoKeys}
        allExpectedServicesPanelEnabled={false}
      />
    </Stack>
  );

  return (
    <Stack tokens={containerStackTokens} disableShrink horizontalAlign="center">
      <Stack horizontal tokens={{ childrenGap: '3em' }} className={infoCardStyles}>
        <InfoCard
          title="Action Center"
          description="
          Action Center - View evidence packages awaiting your review and metrics on the number of items closed out in previous months."
          image={actionCenterInfoCardImage}
          link={actionCenterRoute()}
          linkText="View pending actions"
          openNewTab={false}
        />
        {isComplianceTeam && (
          <InfoCard
            title="Services"
            description="View the statuses and progress of Azure services that require certifications in the Commercial and Government clouds."
            image={servicesInfoCardImage}
            link={servicesRoute()}
            linkText="View Azure services"
            openNewTab={false}
          />
        )}
        <InfoCard
          title="Offerings"
          description="View a list of available Azure offerings and details of all the services that fall under it."
          image={offeringsInfoCardImage}
          link={offeringsRoute()}
          linkText="View Azure offerings"
          openNewTab={false}
        />
      </Stack>
      <Stack className={widgetContainerStyles}>
        <Stack.Item align="start">
          <span className={labelStyle}>Workload</span>
        </Stack.Item>
        <Stack.Item align="center" disableShrink>
          {isAuditor && auditorWidgets}
          {isAO && aoWidgets}
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
