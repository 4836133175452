import { mergeStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { EvidenceComment } from 'generated/clientApi';
import React, { FunctionComponent } from 'react';
import { getFormattedDateTime } from '../../modules/datetime/datetime';

const rowHeaderStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '5px',
});

const rowStyle = mergeStyles({
  padding: '5px',
  paddingBottom: '10px',
  border: '1px solid',
  borderColor: NeutralColors.gray30,
});

const rowStyleGray = mergeStyles(rowStyle, {
  backgroundColor: NeutralColors.gray20,
});

const dateStyle = mergeStyles({
  textAlign: 'right',
});

const nameStyle = mergeStyles({
  fontWeight: 'bold',
  textAlign: 'left',
});

export interface ICommentListProps {
  comments?: EvidenceComment[];
}

export const CommentListView: FunctionComponent<ICommentListProps> = (props) => {
  const { comments } = props;

  const sortedComments = comments?.sort((a, b) => {
    if (a.createdDate && b.createdDate) {
      if (a.createdDate > b.createdDate) {
        return -1;
      }
      if (a.createdDate < b.createdDate) {
        return 1;
      }
    }
    return 0;
  });

  const sortedItemListRows = sortedComments
    ? sortedComments.map((value) => ({
        name: `${value.name}`,
        date: getFormattedDateTime(value.createdDate),
        comment: `${value.comment}`,
        key: `${value.name}-${value.comment}-${value.createdDate}`,
      }))
    : undefined;

  return (
    <>
      {sortedItemListRows?.map((item, index: number) => (
        <div className={index % 2 === 1 ? rowStyleGray : rowStyle} key={item.key}>
          <div className={rowHeaderStyle}>
            <span className={nameStyle}>{item.name}</span>
            <span className={dateStyle}>{item.date}</span>
          </div>
          <div>{item.comment}</div>
        </div>
      ))}
    </>
  );
};
