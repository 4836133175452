import { AccountIdentifiers, useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import { AuthContext, AuthType } from '../components/authProvider/authContext';
import { AUTHENTICATED, SITE_WIDE_SUBJECT } from '../modules/constants';

export const useMtacIsAuthenticated = (matchAccount?: AccountIdentifiers): boolean => {
  const authContext = useContext(AuthContext);
  const isUserAuthenticated = useIsAuthenticated(matchAccount);

  if (authContext.getAuthType() === AuthType.CERT_AUTH) {
    return authContext.isAuthorized([{ operation: AUTHENTICATED, subject: SITE_WIDE_SUBJECT }]);
  }
  return isUserAuthenticated;
};
