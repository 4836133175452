// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/bar-chart

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect } from 'react';
import { NeutralColors } from '@fluentui/theme';
import * as d3 from 'd3';
import { PieArcDatum } from 'd3';

export interface DonutChartData {
  label: string;
  count: number;
}

export interface DonutChartProps {
  margin?: number;
  width?: number;
  height?: number;
  subText?: string;
  colors?: string[];
}

export interface ChartParameters {
  data: DonutChartData[];
  props: DonutChartProps;
}

export const DonutChart: React.FunctionComponent<ChartParameters> = ({ data, props }) => {
  let {
    margin = 10,
    width = 300, // the outer width of the chart, in pixels
    height = 300, // the outer height of the chart, in pixels
    subText = '',
    colors = ['#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56'], // bar fill color, default to azure blue
  } = props;
  const svgRef = React.useRef(null);
  useEffect(() => {
    const radius = Math.min(width, height) / 2 - margin;
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll('*').remove();

    const svg = svgEl
      .attr('width', '30vh')
      .attr('height', '30vh')
      .attr('viewBox', `0, 0, ${width}, ${height}`)
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const pie = d3.pie<DonutChartData>().value((d) => d.count);
    const dataReady = pie(data);

    // Donut partition
    svg
      .selectAll('g')
      .data(dataReady)
      .enter()
      .append('path')
      .attr(
        'd',
        d3
          .arc<PieArcDatum<DonutChartData>>()
          .innerRadius(radius / 1.75) // This is the size of the donut hole
          .outerRadius(radius),
      )
      .attr('fill', (d) => colors[d.index])
      .attr('cursor', 'pointer')
      .append('title')
      .text((d) => `${d.data.label}\n${d.data.count}`)
      .attr('stroke', NeutralColors.white)
      .style('stroke-width', '2')
      .style('opacity', '0.8');

    // Add middle info
    const total = data.reduce((previousValue, currentValue) => previousValue + currentValue.count, 0);
    svg.append('text').attr('text-anchor', 'middle').attr('dy', '-10').text(total).style('font-size', '4em');
    svg.append('text').attr('text-anchor', 'middle').attr('dy', '20').text(subText).style('font-size', '1.25em');
  }, [data]);
  return <svg ref={svgRef} width={width} height={height} />;
};
