import { MessageBarType } from '@fluentui/react';
import { Observable, Subject } from 'rxjs';
import { getConfig } from '../config/config';

const showMessageSubject = new Subject<MessageBarItem>();
const dismissMessageSubject = new Subject<string>();

const randomKey = (): string => Math.random().toString(36).substring(2);

let defaultAutoDismissSeconds: number;

export const initializeMessageBar = (): void => {
  defaultAutoDismissSeconds = getConfig().messageBarAutoDismissSeconds;
};

export interface MessageBarItem {
  readonly key: string;
  readonly message: string | JSX.Element;
  readonly severity: MessageBarType;
  readonly autoDismissSeconds: number;
  readonly canDismiss: boolean;
  readonly isMultiline: boolean;
}

const showMessage = (messageBarItem: MessageBarItem): MessageBarItem => {
  showMessageSubject.next(messageBarItem);
  return messageBarItem;
};

export const showInfo = (
  message: string,
  autoDismissSeconds: number = defaultAutoDismissSeconds,
  canDismiss = true,
  isMultiline = false,
): MessageBarItem =>
  showMessage({
    key: randomKey(),
    message,
    severity: MessageBarType.info,
    autoDismissSeconds: canDismiss ? autoDismissSeconds : 0,
    canDismiss,
    isMultiline,
  });

export const showSuccess = (
  message: string,
  autoDismissSeconds: number = defaultAutoDismissSeconds,
  canDismiss = true,
  isMultiline = false,
): MessageBarItem =>
  showMessage({
    key: randomKey(),
    message,
    severity: MessageBarType.success,
    autoDismissSeconds: canDismiss ? autoDismissSeconds : 0,
    canDismiss,
    isMultiline,
  });

export const showWarning = (
  message: string,
  autoDismissSeconds: number = defaultAutoDismissSeconds,
  canDismiss = true,
  isMultiline = false,
): MessageBarItem =>
  showMessage({
    key: randomKey(),
    message,
    severity: MessageBarType.warning,
    autoDismissSeconds: canDismiss ? autoDismissSeconds : 0,
    canDismiss,
    isMultiline,
  });

export const showError = (
  message: string | JSX.Element,
  autoDismissSeconds: number = defaultAutoDismissSeconds,
  canDismiss = true,
  isMultiline = false,
): MessageBarItem =>
  showMessage({
    key: randomKey(),
    message,
    severity: MessageBarType.error,
    autoDismissSeconds: canDismiss ? autoDismissSeconds : 0,
    canDismiss,
    isMultiline,
  });

export const onMessage = (): Observable<MessageBarItem> => showMessageSubject.asObservable();
export const onMessageDismiss = (): Observable<string> => dismissMessageSubject.asObservable();

export const dismissMessage = (messageKey: string): void => dismissMessageSubject.next(messageKey);
