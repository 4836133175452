import { ISearchBoxStyles } from '@fluentui/react';

export const searchStyles: Partial<ISearchBoxStyles> = {
  root: {
    border: '1px solid lightgrey !important',
    borderRadius: '4px',
    minWidth: 300,
    height: 'fit-content',
    padding: '0.25em 0.5em',
  },
};
