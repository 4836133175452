export enum Severity {
  Success,
  Info,
  Warning,
  Error,
}

// TODO: Add color/icons for each severity
export const getSeverityColor = (severity: Severity): string => {
  switch (severity) {
    case Severity.Warning:
      return 'rgb(255, 248, 240)'; // Color came from FluentUI message bar
    default:
      return 'rgb(255, 255, 255)';
  }
};

export const getSeverityIcon = (severity: Severity): string => {
  switch (severity) {
    case Severity.Warning:
      return 'WarningSolid';
    default:
      return '';
  }
};
