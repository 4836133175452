import {
  DefaultButton,
  DetailsRow,
  GroupHeader,
  GroupedList,
  IGroup,
  IGroupHeaderCheckboxProps,
  IGroupHeaderProps,
  IGroupRenderProps,
  IObjectWithKey,
  IScrollablePaneStyles,
  Icon,
  Panel,
  PanelType,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  TextField,
  ActionButton,
} from '@fluentui/react';
import { SaveButton } from 'components/saving/saveButton';
import {
  ConmonOrganizationType,
  ConmonReportsDefaultJustificationGetClient,
  ConmonReportsDefaultJustificationPostClient,
  ConmonReportsDefaultJustificationPostCommand,
} from 'generated/clientApi';
import { getConfig } from 'modules/config/config';
import { showError, showSuccess } from 'modules/messageBar/messageBar';
import { ConMonFilterContext } from 'pages/conMon/conMonFilterContext/conMonFilterContext';
import { riskCalculatorTabStyle } from 'pages/conMon/poamTab/riskPanel/riskCalculatorTab';
import { RiskCalculatorJustifications, emptyJustifications, riskCalculatorItems } from 'pages/conMon/poamTab/riskPanel/riskCalculatorTypes';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { sideModalReportStyles } from 'styles/sidePanelPoam';

type DefaultJustificationPanelProps = {
  isOpen: boolean;
  close: () => void;
};

const scrollPaneStyles = {
  root: {
    '.ms-DetailsList': {
      overflow: 'visible',
      margin: '1rem 1rem 2rem 1rem',
    },
    '.ms-List-cell > div': {
      width: '90%',
    },
    '.ms-List-cell > div > div, .ms-List-cell > div > div > div': {
      width: '100% !important',
    },
    '& .ms-TextField-fieldGroup': {
      border: 0,
      height: '100%',
    },
    '& .ms-TextField,.ms-TextField-wrapper': {
      height: '32px !important',
    },
    '& .ms-TextField-field': {
      height: '32px',
      border: '1px solid #D1D1D1 !important',
      borderRadius: '4px',
      padding: '.3rem 1rem',
      borderBottom: '1px solid #616161 !important',
    },
    '.ms-GroupedList:last-of-type': {
      marginBottom: '3rem',
    },
  },
};

const groupProps: IGroupRenderProps = {
  onRenderHeader: (props?: IGroupHeaderProps): JSX.Element => <GroupHeader onRenderGroupHeaderCheckbox={onRenderGroupHeaderCheckbox} {...props} />,
};

const onRenderGroupHeaderCheckbox = (props?: IGroupHeaderCheckboxProps) => {
  const iconStyles = { root: { fontSize: '36px' } };

  return props?.checked ? <Icon iconName="ToggleRight" styles={iconStyles} /> : <Icon iconName="ToggleLeft" styles={iconStyles} />;
};

export const DefaultJustificationPanel: React.FunctionComponent<DefaultJustificationPanelProps> = (props) => {
  const { isOpen, close } = props;
  const [isSaving, setSaving] = useState(false);
  const { readonly, organization } = useContext(ConMonFilterContext);

  const [riskCalculatorJustifications, setRiskCalculatorJustifications] = useState<RiskCalculatorJustifications>(emptyJustifications);

  useEffect(() => {
    getInitialJustifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialJustifications = async () => {
    const client = new ConmonReportsDefaultJustificationGetClient(getConfig().apiBaseUri);
    try {
      const response = await client.get(organization);
      if (response) {
        setRiskCalculatorJustifications(response as RiskCalculatorJustifications);
      }
    } catch (e) {
      showError('There was an error fetching the justifications. Please refresh to try again.');
    }
  };

  const changeJustification = (newValue: string | undefined, key: string): void => {
    if (newValue) {
      const newJustifications = structuredClone(riskCalculatorJustifications);
      newJustifications[key as keyof RiskCalculatorJustifications] = newValue;
      setRiskCalculatorJustifications(newJustifications);
    }
  };

  const rows: IObjectWithKey[] = useMemo(() => {
    const checkboxes = riskCalculatorItems.map((riskGroup) => {
      const formattedRiskGroupsCheckboxes = riskGroup.items.map((item) => ({
        key: item.key,
        component: (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems: 'stretch' }} className={riskCalculatorTabStyle}>
            <h3>{item.name}</h3>
            {item.items.map((vectorModifier) => {
              const justification = riskCalculatorJustifications[vectorModifier.key as keyof RiskCalculatorJustifications];

              return (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }} key={vectorModifier.text}>
                  <span>{vectorModifier.text}</span>
                  <TextField
                    placeholder="Enter justification"
                    value={justification}
                    onChange={(event, input) => changeJustification(input, vectorModifier.key)}
                  />
                </div>
              );
            })}
          </div>
        ),
      }));

      return formattedRiskGroupsCheckboxes;
    });

    return checkboxes.flat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskCalculatorJustifications]);

  const columns = useMemo(
    () => [
      {
        fieldName: 'component',
        key: 'component',
        minWidth: 500,
        name: 'component',
      },
    ],
    [],
  );
  const [groups, setGroups] = useState<IGroup[]>(riskCalculatorItems.map((riskCalculatorItem) => ({ ...riskCalculatorItem, items: undefined })));

  const onRenderCell = React.useCallback(
    (nestingDepth?: number, item?: any, itemIndex?: number, group?: IGroup): React.ReactNode => (
      <DetailsRow
        columns={columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex!}
        selectionMode={SelectionMode.none}
        group={group}
      />
    ),
    [columns],
  );

  const onSave = async () => {
    setSaving(true);

    const client = new ConmonReportsDefaultJustificationPostClient(getConfig().apiBaseUri);
    try {
      const conmonReportsDefaultJustificationCommand = new ConmonReportsDefaultJustificationPostCommand({
        justifications: riskCalculatorJustifications,
        organization: organization ?? ConmonOrganizationType.Azure,
      });

      await client.post(conmonReportsDefaultJustificationCommand);
      showSuccess('Justifications successfully saved', 10);
      close();
    } catch (e) {
      showError('There was an error saving the justifications. Please refresh to try again.');
    } finally {
      setSaving(false);
    }
  };

  const onRenderFooterContent = useCallback(
    () => (
      <div style={{ position: 'absolute', bottom: '1rem' }}>
        <SaveButton onSave={() => onSave()} defaultText="Save" isSaving={isSaving} disabled={readonly} />
        <DefaultButton onClick={close} styles={{ root: { marginLeft: 8 } }}>
          Cancel
        </DefaultButton>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSaving, riskCalculatorJustifications],
  );

  const expandAllGroups = () => {
    setGroups((currGroups) => currGroups?.map((group) => ({ ...group, isCollapsed: group.key === 'temporal-score-metrics-group' })));
  };

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={close}
      type={PanelType.large}
      closeButtonAriaLabel="Close"
      headerText="Default justification"
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      className={sideModalReportStyles}
    >
      <div style={{ overflow: 'auto', position: 'relative', height: '100%' }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={scrollPaneStyles as unknown as IScrollablePaneStyles}>
          <GroupedList
            items={rows}
            onRenderCell={onRenderCell}
            selectionMode={SelectionMode.none}
            groups={groups?.filter((group) => group.key === 'temporal-score-metrics-group')}
            groupProps={groupProps}
            onShouldVirtualize={() => false}
          />
          <div style={{ display: 'flex', gap: '1.5rem', alignItems: 'baseline', margin: '1rem', fontSize: '1.5rem' }}>
            <span style={{ fontWeight: 'bold' }}>Environmental and Temporal score metrics</span>
            <ActionButton className={actionButtonStyles} style={{ height: 'auto', marginRight: '15.5rem' }} onClick={() => expandAllGroups()}>
              Expand all
            </ActionButton>
          </div>
          <GroupedList
            items={rows}
            onRenderCell={onRenderCell}
            selectionMode={SelectionMode.none}
            groups={groups?.filter((group) => group.key !== 'temporal-score-metrics-group')}
            groupProps={groupProps}
            onShouldVirtualize={() => false}
          />
        </ScrollablePane>
      </div>
    </Panel>
  );
};
