import {
  CuratedTenantMonitoringDataGetClient,
  CuratedTenantMonitoringDataGetResponse,
  CuratedTenantMonitoringDataHistoryGetClient,
  CuratedTenantMonitoringDataHistoryGetResponse,
  CuratedTenantMonitoringDataListClient,
  CuratedTenantMonitoringDataListResponse,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getCuratedTenantMonitorings = (): Promise<CuratedTenantMonitoringDataListResponse[]> => {
  const curatedTenantMonitoringClient = new CuratedTenantMonitoringDataListClient(getConfig().apiBaseUri);
  return curatedTenantMonitoringClient.get();
};

export const getCuratedTenant = (tenantId: string, monitoringScanId: string): Promise<CuratedTenantMonitoringDataGetResponse> => {
  const curatedTenantMonitoringClient = new CuratedTenantMonitoringDataGetClient(getConfig().apiBaseUri);
  return curatedTenantMonitoringClient.get(tenantId, monitoringScanId);
};

export const getCuratedTenantMonitoringHistory = (tenantId: string, startDate: string, endDate: string): Promise<CuratedTenantMonitoringDataHistoryGetResponse> => {
  const curatedTenantMonitoringClient = new CuratedTenantMonitoringDataHistoryGetClient(getConfig().apiBaseUri);
  return curatedTenantMonitoringClient.get(tenantId, startDate, endDate);
};
