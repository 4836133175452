import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getImageSource } from './imageViewer.functions';

export enum ImageTypes {
  PNG = 'image/png',
}

export interface ImageViewerProps {
  imageType: ImageTypes;
  content: ArrayBuffer;
  altText?: string;
}

const contentViewerStyle = mergeStyles({
  backgroundColor: '#f2f2f2',
});

export const ImageViewer: FunctionComponent<ImageViewerProps> = (props: ImageViewerProps) => {
  const { imageType, content, altText } = props;
  const [source, setSource] = useState<string>();

  useEffect(() => {
    if (!content) {
      return;
    }
    setSource(getImageSource(imageType, content));
  }, [imageType, content]);

  return (
    <div className={contentViewerStyle}>
      <img src={source} alt={altText} />
    </div>
  );
};
