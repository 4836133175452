import React from 'react';
import { Spinner, SpinnerSize, Stack, mergeStyles, IContextualMenuProps, PrimaryButton, DefaultButton, SharedColors } from '@fluentui/react';
import { primaryButtonStyles } from 'styles/primaryButtonStyles';
import { defaultButtonStyles } from 'styles/defaultButtonStyles';

const commonButtonStyles = mergeStyles({
  marginRight: '1em',
});

const errorMessageStyles = mergeStyles({
  marginBottom: '1em',
  color: SharedColors.red10,
});

const previousButtonStyles = mergeStyles(commonButtonStyles, defaultButtonStyles);
const nextButtonStyles = mergeStyles(commonButtonStyles, primaryButtonStyles);

const buttonContainerStyles = mergeStyles({
  paddingTop: '3em',
});

export interface WizardNavigationProps {
  isNextDisabled: boolean;
  isNextVisible: boolean;
  isNextLoading: boolean;
  isNextTitle?: string;
  isPreviousVisible: boolean;
  nextButtonText: string;
  previousButtonText?: string;
  onClickNextButton?(): void;
  onClickPreviousButton?(): void;
  nextButtonMenuProps?: IContextualMenuProps;
  errorMessage?: string;
}

export const WizardNavigation: React.FunctionComponent<WizardNavigationProps> = (props) => {
  const {
    isNextDisabled,
    isNextVisible,
    isNextLoading,
    isNextTitle,
    isPreviousVisible,
    nextButtonText,
    previousButtonText,
    onClickPreviousButton,
    onClickNextButton,
    nextButtonMenuProps,
    errorMessage,
  } = props;
  const previousText = previousButtonText ?? 'Previous';

  const onNextClick = () => {
    if (onClickNextButton) {
      onClickNextButton();
    }
  };

  const onPreviousClick = () => {
    if (onClickPreviousButton) {
      onClickPreviousButton();
    }
  };

  const getNextButtonText = () => {
    if (!isNextLoading) {
      return <span>{nextButtonText}</span>;
    }

    return <Spinner size={SpinnerSize.small} />;
  };

  const getNextButtonLoading = () => {
    if (!isNextLoading) {
      return <></>;
    }

    return <Spinner size={SpinnerSize.small} />;
  };

  const NextButton = (): JSX.Element => {
    if (isNextVisible) {
      if (nextButtonMenuProps) {
        return (
          <>
            <PrimaryButton
              text={nextButtonText}
              className={nextButtonStyles}
              splitButtonAriaLabel="See 2 options"
              aria-roledescription="split button"
              menuProps={nextButtonMenuProps}
              disabled={isNextLoading}
              onClick={onNextClick}
            >
              {getNextButtonLoading()}
            </PrimaryButton>
          </>
        );
      }

      return (
        <PrimaryButton className={nextButtonStyles} onClick={onNextClick} disabled={isNextDisabled} title={isNextTitle}>
          {getNextButtonText()}
        </PrimaryButton>
      );
    }
    return <></>;
  };

  return (
    <div className={buttonContainerStyles}>
      {errorMessage && <div className={errorMessageStyles}>{errorMessage}</div>}
      <Stack horizontal>
        {isPreviousVisible && (
          <DefaultButton text={previousText} className={previousButtonStyles} onClick={onPreviousClick} disabled={isNextLoading} />
        )}
        <NextButton />
      </Stack>
    </div>
  );
};
