/* eslint-disable react/jsx-curly-newline */
import React, { ReactElement, useContext, useState } from 'react';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import {
  TextField,
  DatePicker,
  DirectionalHint,
  FontIcon,
  TooltipDelay,
  TooltipHost,
  ITooltipHostStyles,
  mergeStyles,
  IColumn,
  DetailsList,
  SelectionMode,
  Icon,
  Separator,
  ActionButton,
  IconButton,
} from '@fluentui/react';
import { getDateOnlyString } from 'modules/datetime/datetime';
import { PlannedMilestone, Remediation } from 'generated/clientApi';
import { separatorStyles } from 'pages/conMon/conMon';
import { ConMonFilterContext } from 'pages/conMon/conMonFilterContext/conMonFilterContext';
import { actionButtonStyles } from 'styles/actionButtonStyles';
import { fileIconCell, fileIconHeaderIcon } from 'styles/fileIconStyles';
import { fieldsStyle } from './poamDetailsTab';
import { RemediationInternalComments } from '../bulkEditPanel/bulkEditPanel';

const titleTooltipStyles = mergeStyles({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
});

const fieldWrapperStyle = mergeStyles({
  height: '70px',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25em',
});

const remediationKeys = {
  vendorDependencyNullable: 'vendorDependencyNullable',
  checkInDate: 'checkInDate',
  productName: 'productName',
  externalComments: 'externalComments',
  plannedRemediationDate: 'plannedRemediationDate',
  comment: 'comment',
  internalComments: 'internalComments',
} as const;

const formOptions = [
  {
    key: remediationKeys.vendorDependencyNullable,
    text: 'Vendor dependency',
  },
  {
    key: remediationKeys.checkInDate,
    text: 'Check-in date',
  },
  {
    key: remediationKeys.productName,
    text: 'Product name',
  },
  {
    key: remediationKeys.externalComments,
    text: 'External comments',
  },
  {
    key: remediationKeys.plannedRemediationDate,
    text: 'Planned remediation date',
  },
  {
    key: remediationKeys.comment,
    text: 'Planned remediation comments',
  },
  {
    key: remediationKeys.internalComments,
    text: 'Internal comments',
  },
];

const vendorDepOptions = [
  {
    key: 'Yes',
    text: 'Yes',
  },
  {
    key: 'No',
    text: 'No',
  },
];
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'flex' } };

type PlannedMilestoneWithId = PlannedMilestone & { id: string };

type RemediationFormProps = {
  remediationInfo?: RemediationInternalComments;
  updateRemediation: (key: keyof RemediationInternalComments, value?: string | PlannedMilestone[]) => void;
};
export const RemediationForm: React.FunctionComponent<RemediationFormProps> = (props) => {
  const { remediationInfo, updateRemediation } = props;
  const { readonly } = useContext(ConMonFilterContext);
  const isBulkEditMode = remediationInfo === undefined;
  const [selectedFormKeys, setSelectedFormKeys] = useState<string[]>(isBulkEditMode ? [] : Object.keys(remediationKeys));
  const [internalMilestones, setInternalMilestones] = useState<PlannedMilestoneWithId[]>(
    (remediationInfo?.milestoneHistory ?? []).map((m) => ({ ...m, id: crypto.randomUUID() }) as PlannedMilestoneWithId),
  );
  const [editingId, setEditingId] = useState<string>('');

  const updateMilestoneHistory = (property: keyof PlannedMilestone, value: any, index: number) => {
    const existingMilestones = internalMilestones;
    const updatedMilestone = internalMilestones.at(index) ?? ({} as PlannedMilestone);
    updatedMilestone[property] = value;
    if (existingMilestones.length > index) {
      existingMilestones[index] = updatedMilestone as PlannedMilestoneWithId;
    } else {
      existingMilestones.push(updatedMilestone as PlannedMilestoneWithId);
    }
    updateRemediation('milestoneHistory', existingMilestones);
    updateRemediation('plannedRemediationDate', existingMilestones.at(-1)?.plannedRemediationDate);
    updateRemediation('comment', existingMilestones.at(-1)?.comment);
    setInternalMilestones(existingMilestones);
  };

  const columns: IColumn[] = [
    {
      key: 'Planned remediation date',
      name: 'Planned remediation date',
      minWidth: 165,
      maxWidth: 165,
      fieldName: 'plannedRemediationDate',
      onRender: (item: PlannedMilestoneWithId) => {
        if (item.id === editingId) {
          return (
            <DatePicker
              value={item.plannedRemediationDate ? new Date(`${item.plannedRemediationDate}T00:00`) : undefined}
              style={{ height: 'fit-content' }}
              onSelectDate={(newValue) =>
                updateMilestoneHistory(
                  'plannedRemediationDate',
                  getDateOnlyString(newValue),
                  internalMilestones.findIndex((m) => m.id === item.id),
                )
              }
              className={fieldsStyle}
              placeholder="Select a date"
              disabled={readonly}
            />
          );
        }
        return <>{item.plannedRemediationDate}</>;
      },
    },
    {
      key: 'Planned remediation comments',
      name: 'Planned remediation comments',
      fieldName: 'comment',
      minWidth: 195,
      maxWidth: 195,
      isMultiline: true,
      onRender: (item: PlannedMilestoneWithId) => {
        if (item.id === editingId) {
          return (
            <TextField
              value={item.comment}
              onChange={(ev, newValue) =>
                updateMilestoneHistory(
                  'comment',
                  newValue,
                  internalMilestones.findIndex((m) => m.id === item.id),
                )
              }
              placeholder="Enter comments"
              multiline
              autoAdjustHeight
              disabled={readonly}
            />
          );
        }
        return <>{item.comment}</>;
      },
    },
    {
      key: 'Submitted by',
      name: 'Submitted by',
      fieldName: 'submittedBy',
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: PlannedMilestoneWithId) => {
        if (item.id === editingId) {
          return (
            <TextField
              value={item.submittedBy}
              onChange={(ev, newValue) =>
                updateMilestoneHistory(
                  'submittedBy',
                  newValue,
                  internalMilestones.findIndex((m) => m.id === item.id),
                )
              }
            />
          );
        }
        return <>{item.submittedBy}</>;
      },
    },
    {
      key: 'Submitted date',
      name: 'Submitted date',
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: PlannedMilestoneWithId) => {
        const convertedLocalDate = item.submittedDateUtc ? new Date(`${item.submittedDateUtc.toISOString().split('T')[0]}T00:00`) : undefined;
        if (item.id === editingId) {
          return (
            <DatePicker
              value={convertedLocalDate}
              style={{ height: 'fit-content' }}
              onSelectDate={(newValue) =>
                updateMilestoneHistory(
                  'submittedDateUtc',
                  newValue,
                  internalMilestones.findIndex((m) => m.id === item.id),
                )
              }
              className={fieldsStyle}
              placeholder="Select a date"
              disabled={readonly}
            />
          );
        }
        return <>{getDateOnlyString(convertedLocalDate)}</>;
      },
    },
    {
      key: 'edit',
      name: 'Edit',
      className: fileIconCell,
      iconClassName: fileIconHeaderIcon,
      iconName: 'Edit',
      isIconOnly: true,
      minWidth: 25,
      maxWidth: 25,
      onRender: (item: PlannedMilestoneWithId) => (
        <div style={{ textAlign: 'initial', marginLeft: '-.5rem' }}>
          <IconButton
            iconProps={{ iconName: 'Edit' }}
            title="Edit"
            ariaLabel="Edit"
            style={{ height: '16px' }}
            onClick={() => setEditingId(item.id)}
          />
        </div>
      ),
    },
    {
      key: 'delete',
      name: 'Delete',
      className: fileIconCell,
      iconClassName: fileIconHeaderIcon,
      iconName: 'Delete',
      isIconOnly: true,
      minWidth: 16,
      maxWidth: 16,
      onRender: (item: PlannedMilestoneWithId) => (
        <div style={{ textAlign: 'initial', marginLeft: '-.5rem' }}>
          <IconButton
            iconProps={{ iconName: 'Delete' }}
            title="Delete"
            ariaLabel="Delete"
            style={{ height: '16px' }}
            onClick={() => {
              const updatedMilestones = internalMilestones.filter((m) => m.id !== item.id);
              updateRemediation('milestoneHistory', updatedMilestones);
              setInternalMilestones(updatedMilestones);
            }}
          />
        </div>
      ),
    },
  ];

  const createTooltip = (element: ReactElement): ReactElement => (
    <TooltipHost
      tooltipProps={{ onRenderContent: () => element }}
      delay={TooltipDelay.zero}
      directionalHint={DirectionalHint.bottomRightEdge}
      styles={hostStyles}
    >
      <FontIcon style={{ color: '#605E5C', cursor: 'pointer' }} iconName="Info" />
    </TooltipHost>
  );

  const onRenderCaretDown = (): JSX.Element => <Icon iconName="Add" />;

  const getFormContent = () => {
    const formElements = [
      <div key={remediationKeys.vendorDependencyNullable} className={fieldWrapperStyle}>
        <div className={titleTooltipStyles}>
          <div style={{ color: '#242424' }}>Vendor dependency</div>
          {createTooltip(
            <>
              <p>
                <b>Kusto: </b>
                N/A
              </p>
              <p>
                <b>POA&M Report: </b>
                Vendor Dependency
              </p>
            </>,
          )}
        </div>
        <Dropdown
          selectedKey={remediationInfo?.vendorDependencyNullable ?? remediationInfo?.vendorDependency}
          options={vendorDepOptions}
          placeholder="Select an option"
          aria-label="Select vendor dependency"
          className={fieldsStyle}
          style={{ height: 'fit-content', lineHeight: '23px' }}
          onChange={(ev, option): void => {
            updateRemediation('vendorDependencyNullable', option?.key as string);
          }}
          disabled={readonly}
        />
      </div>,
      <div key={remediationKeys.checkInDate} className={fieldWrapperStyle}>
        <div className={titleTooltipStyles}>
          <div style={{ color: '#242424' }}>Vendor check-in date</div>
          {createTooltip(
            <>
              <p>
                <b>Kusto: </b>
                N/A
              </p>
              <p>
                <b>POA&M Report: </b>
                Last Vendor Check-in Date
              </p>
            </>,
          )}
        </div>
        <DatePicker
          value={remediationInfo?.checkInDate ? new Date(`${remediationInfo?.checkInDate}T00:00`) : undefined}
          style={{ height: 'fit-content' }}
          onSelectDate={(newValue) => updateRemediation('checkInDate', getDateOnlyString(newValue))}
          className={fieldsStyle}
          placeholder="Select a date"
          ariaLabel="Select vendor check-in date"
          disabled={readonly}
        />
      </div>,
      <div key={remediationKeys.productName} className={fieldWrapperStyle}>
        <div className={titleTooltipStyles}>
          <div style={{ color: '#242424' }}>Product name</div>
          {createTooltip(
            <>
              <p>
                <b>Kusto: </b>
                N/A
              </p>
              <p>
                <b>POA&M Report: </b>
                Vendor Dependent Product Name
              </p>
            </>,
          )}
        </div>
        <TextField
          value={remediationInfo?.productName}
          onChange={(ev, newValue) => updateRemediation('productName', newValue)}
          className={fieldsStyle}
          placeholder="Enter product name"
          aria-label="Enter vendor dependent product name"
          disabled={readonly}
        />
      </div>,
      <div key={remediationKeys.externalComments} className={fieldWrapperStyle} style={{ height: 'auto', marginBottom: '1rem' }}>
        <div className={titleTooltipStyles} style={{ marginBottom: '.25rem' }}>
          <div style={{ color: '#242424' }}>External comments</div>
          {createTooltip(
            <>
              <p>
                <b>Kusto: </b>
                N/A
              </p>
              <p>
                <b>POA&M Report: </b>
                Comments
              </p>
            </>,
          )}
        </div>
        <TextField
          value={remediationInfo?.externalComments}
          onChange={(ev, newValue) => updateRemediation('externalComments', newValue)}
          placeholder="Enter external comments"
          aria-label="Enter external comments"
          multiline
          rows={5}
          disabled={readonly}
        />
      </div>,
      <div key={remediationKeys.plannedRemediationDate} className={fieldWrapperStyle}>
        <div className={titleTooltipStyles}>
          <div style={{ color: '#242424' }}>Planned remediation date</div>
          {createTooltip(
            <>
              <p>
                <b>Kusto: </b>
                <i>ScanCompleteTime </i>
                and OriginalRiskRating are used to generate the PlannedRemediationDate
              </p>
              <p>
                <b>POA&M Report: </b>
                Used for Scheduled Completion Date and Milestone Changes
              </p>
            </>,
          )}
        </div>
        <DatePicker
          value={
            remediationInfo?.milestoneHistory?.at(-1)?.plannedRemediationDate
              ? new Date(`${remediationInfo?.milestoneHistory?.at(-1)?.plannedRemediationDate}T00:00`)
              : undefined
          }
          style={{ height: 'fit-content' }}
          onSelectDate={(newValue) => updateMilestoneHistory('plannedRemediationDate', getDateOnlyString(newValue), 0)}
          className={fieldsStyle}
          placeholder="Select a date"
          aria-label="Enter planned remediation date"
          disabled={readonly}
        />
      </div>,
      <div key={remediationKeys.comment} className={fieldWrapperStyle}>
        <div className={titleTooltipStyles}>
          <div style={{ color: '#242424' }}>Planned remediation comments</div>
          {createTooltip(
            <>
              <p>
                <b>Kusto: </b>
                N/A
              </p>
              <p>
                <b>POA&M Report: </b>
                PlannedRemediationComment, used in MilestoneChanges
              </p>
            </>,
          )}
        </div>
        <TextField
          value={remediationInfo?.milestoneHistory?.at(-1)?.comment}
          onChange={(ev, newValue) => updateMilestoneHistory('comment', newValue, 0)}
          className={fieldsStyle}
          placeholder="Enter comments"
          aria-label="Enter planned remediation comments"
          disabled={readonly}
        />
      </div>,
      <div key={remediationKeys.internalComments} className={fieldWrapperStyle} style={{ height: 'auto', marginBottom: '1rem' }}>
        <div className={titleTooltipStyles}>
          <div style={{ color: '#242424' }}>Internal comments</div>
          {createTooltip(
            <>
              <p>
                <b>Kusto: </b>
                N/A
              </p>
              <p>
                <b>POA&M Report: </b>
                N/A
              </p>
            </>,
          )}
        </div>
        <TextField
          onChange={(ev, newValue) => updateRemediation('internalComments', newValue)}
          placeholder="Enter internal comments"
          aria-label="Enter internal comments"
          multiline
          autoAdjustHeight
          disabled={readonly}
        />
      </div>,
    ];

    if (isBulkEditMode) {
      return formElements.filter((el) => selectedFormKeys.includes(el.key as string));
    }
    return formElements.filter(
      (el) => el.key !== remediationKeys.plannedRemediationDate && el.key !== remediationKeys.comment && el.key !== remediationKeys.internalComments,
    );
  };

  return (
    <div style={{ marginBottom: '5rem', marginTop: '2rem' }}>
      {isBulkEditMode && (
        <>
          <div className={fieldWrapperStyle}>
            <Dropdown
              options={formOptions}
              label="Choose which fields to edit"
              multiSelect
              className={fieldsStyle}
              onRenderCaretDown={onRenderCaretDown}
              style={{ height: 'fit-content', lineHeight: '23px' }}
              onChange={(ev, item): void => {
                if (!item) {
                  return;
                }
                setSelectedFormKeys(item.selected ? [...selectedFormKeys, item.key as string] : selectedFormKeys.filter((key) => key !== item.key));
                if (!item.selected) {
                  updateRemediation(item.key as keyof Remediation, undefined);
                } else {
                  updateRemediation(item.key as keyof Remediation, '');
                }
              }}
              disabled={readonly}
            />
          </div>
          <div style={{ marginTop: '1rem', marginBottom: '5rem' }}>
            <Separator styles={separatorStyles} />
          </div>
        </>
      )}
      {getFormContent()}
      {!isBulkEditMode && (
        <div className={fieldWrapperStyle} style={{ maxWidth: '100%', height: 'auto' }}>
          <div style={{ display: 'flex', gap: 25 }}>
            <div className={titleTooltipStyles}>
              <div style={{ color: '#242424' }}>Planned milestone history</div>
              {createTooltip(
                <>
                  <p>
                    <b>Kusto: </b>
                    Automated text that includes ScheduledCompletionDate
                  </p>
                  <p>
                    <b>POA&M Report: </b>
                    Planned Milestones
                  </p>
                </>,
              )}
            </div>
            <ActionButton
              className={actionButtonStyles}
              disabled={false}
              iconProps={{ iconName: 'Add' }}
              onClick={() => {
                const id = crypto.randomUUID();
                setInternalMilestones((prev) => [...prev, { id, submittedDateUtc: new Date() } as PlannedMilestoneWithId]);
                setEditingId(id);
              }}
            >
              Add entry
            </ActionButton>
          </div>
          <DetailsList items={internalMilestones.slice().reverse() ?? []} columns={columns} selectionMode={SelectionMode.none} />
        </div>
      )}
    </div>
  );
};
