/* eslint-disable class-methods-use-this */
import { getAccessToken, getAuthType } from '../modules/auth/auth';

export class ClientApiBase {
  async transformOptions(options: RequestInit): Promise<any> {
    const token = await getAccessToken();
    const newOptions = { ...options };
    newOptions.headers = new Headers(newOptions.headers);
    if (token) newOptions.headers.set('Authorization', `Bearer ${token}`);
    newOptions.headers.set('auth_type', getAuthType());
    return newOptions;
  }

  async CreateXMLHttpRequest(url: RequestInfo, init: RequestInit): Promise<XMLHttpRequest> {
    const xmlHttpRequest = new XMLHttpRequest();
    if (!init || typeof init.method !== 'string' || typeof url !== 'string') {
      throw new Error('Unable to create HTTP request for system attachment');
    }
    xmlHttpRequest.open(init.method, url);
    const token = await getAccessToken();

    if (token) {
      xmlHttpRequest.setRequestHeader('Authorization', `Bearer ${token}`);
    }
    return xmlHttpRequest;
  }
}
