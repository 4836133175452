import { Checkbox, ICheckboxProps, ICheckboxStyles, Icon, IStyle, IStyleSet, mergeStyles, mergeStyleSets, NeutralColors } from '@fluentui/react';
import * as React from 'react';
import { useCallback, useState, KeyboardEvent, FunctionComponent, useMemo } from 'react';

export interface IAccordionType {
  key: string;
  deletable: boolean;
}

export interface IExpandableListStyle {
  root?: IStyle;
  header?: IStyle;
  icon?: IStyle;
  headerDiv?: IStyle;
  operatorDiv?: IStyle;
  checkBox?: IStyle;
}
export interface AccordionProps {
  headerElement: JSX.Element | JSX.Element[];
  subHeaderElement?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
  defaultIsExpanded?: boolean;
  isExpanded?: boolean;
  className?: string;
  styles?: IExpandableListStyle;
  onExpanded?(isExpanded: boolean): void;
  checkBoxProps?: ICheckboxProps;
  displayCheckbox?: boolean;
  disabledTitle?: string;
}

const headerStyle = {
  marginTop: '0.25em',
  outline: 'none',
  display: 'flex',
  cursor: 'pointer',
  '&:focus-visible': {
    outline: `${NeutralColors.black} solid 1px`,
    borderRadius: '3px',
  },
} as IStyle;

const headerIconStyle = mergeStyles({
  margin: 'auto 10px auto 0',
  cursor: 'pointer',
});

const contentStyle = mergeStyles({
  margin: '20px 0',
});

const checkBoxStyle = mergeStyles({
  margin: 'auto 3em auto auto',
});

const disabledCheckboxStyles: Partial<IStyleSet<ICheckboxStyles>> = {
  checkbox: {
    background: 'lightgrey',
  },
};

const operatorDivStyle = mergeStyles({
  display: 'flex',
});

export const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const { headerElement, subHeaderElement, children, className, styles, onExpanded, checkBoxProps, disabledTitle } = props;
  const [isExpandedInteral, setIsExpanded] = useState<boolean>(props.defaultIsExpanded ?? false);
  const isExpanded = props.isExpanded ?? isExpandedInteral;

  const toggleContentClick = useCallback(() => {
    if (onExpanded) {
      onExpanded(isExpandedInteral);
    }
    setIsExpanded((toggleContent) => !toggleContent);
  }, [isExpandedInteral, onExpanded]);

  const toggleContentKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 32 || event.keyCode === 13) {
        // key code of space and enter, respectively
        setIsExpanded((toggleContent) => !toggleContent);
      }
    },
    [setIsExpanded],
  );

  const classNames = useMemo(
    () =>
      mergeStyleSets({
        root: [className, styles?.root],
        header: [styles?.header, headerStyle],
        icon: [headerIconStyle, styles?.icon],
        headerDiv: [styles?.headerDiv],
        operatorDiv: [operatorDivStyle, styles?.operatorDiv],
        checkBox: [checkBoxStyle, styles?.checkBox],
      }),
    [className, styles?.root, styles?.header, styles?.icon, styles?.headerDiv, styles?.operatorDiv, styles?.checkBox],
  );

  return (
    <div className={classNames.root}>
      <div className={classNames.operatorDiv}>
        {props.displayCheckbox || props.displayCheckbox === undefined ? (
          <Checkbox
            title={checkBoxProps?.disabled ? disabledTitle : 'Select row'}
            checked={checkBoxProps?.checked || false}
            disabled={checkBoxProps?.disabled || false}
            onChange={checkBoxProps?.onChange}
            className={classNames.checkBox}
            styles={checkBoxProps?.disabled ? disabledCheckboxStyles : undefined}
          />
        ) : (
          <></>
        )}
        <Icon
          role="button"
          aria-label="Chevron expand icon button"
          className={classNames.icon}
          iconName={isExpanded ? 'ChevronUp' : 'ChevronDown'}
          onClick={toggleContentClick}
          onKeyUp={toggleContentKeyUp}
        />
      </div>
      <div className={classNames.headerDiv}>
        <div
          className={classNames.header}
          role="button"
          tabIndex={0}
          aria-label="Toggle Content Expansion"
          onClick={toggleContentClick}
          onKeyUp={toggleContentKeyUp}
        >
          {headerElement}
        </div>
        {isExpanded && (
          <div className={contentStyle}>
            {subHeaderElement}
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
