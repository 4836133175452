import * as React from 'react';
import { IBreadcrumbItem, Spinner, SpinnerSize, Breadcrumb, IBreadcrumbStyles } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';

export interface BreadcrumbItem extends IBreadcrumbItem {
  isClickable?: boolean;
}

const renderBreadcrumb = (props?: IBreadcrumbItem, defaultRender?: (props?: IBreadcrumbItem) => JSX.Element | null): JSX.Element | null => {
  if (props?.text === '') {
    return (
      <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>
        <Spinner size={SpinnerSize.small} />
      </span>
    );
  }

  return defaultRender!(props);
};

export interface INavBreadcrumbProps {
  breadcrumbs?: BreadcrumbItem[];
}

const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
  root: {
    marginTop: 0,
  },
  listItem: {
    '.ms-Breadcrumb-itemLink, .ms-Breadcrumb-itemLink:hover, .ms-Breadcrumb-itemLink:active, .ms-Breadcrumb-itemLink:focus, .ms-Breadcrumb-itemLink:hover:focus':
      {
        color: NeutralColors.gray130,
        textDecoration: 'none',
      },
    '.ms-Breadcrumb-listItem, .ms-Breadcrumb-item:last-child': {
      color: NeutralColors.gray160,
    },
  },
  itemLink: {
    fontSize: '18px',
  },
};

export const NavBreadcrumb: React.FunctionComponent<INavBreadcrumbProps> = (props) => {
  const { breadcrumbs } = props;

  if (breadcrumbs) {
    return (
      <Breadcrumb
        items={breadcrumbs}
        onRenderItem={renderBreadcrumb}
        ariaLabel="Breadcrumb with items rendered as buttons"
        overflowAriaLabel="Breadcrumb links"
        styles={breadcrumbStyles}
      />
    );
  }
  return <></>;
};
