import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash';
import { RouteProps } from 'react-router-dom';
import { getEvidencePackage } from 'modules/evidencePackage/evidencePackage';
import {
  EvidencePackage,
  IDataFlowSupplementalInfo,
  IEvidencePackageCommentsPutCommand,
  IServiceTreeSupplementalInfo,
  ISupplementalInfo,
  SupplementalInfo,
} from 'generated/clientApi';
import { LoadingState } from 'models/loadingState';
import { logError } from 'modules/logging/logging';
import { Domains } from 'models/domainConstants';
import { EvidenceContext, EvidenceContextProps } from './evidenceContext';

export const EvidenceProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [evidencePackage, setEvidencePackage] = useState<EvidencePackage | undefined>(undefined);
  const [savedEvidencePackage, setSavedEvidencePackage] = useState<EvidencePackage | undefined>(undefined);
  const [supplementalInfo, setSupplementalInfo] = useState<ISupplementalInfo | undefined>();
  const [dirtyDomains, setDirtyDomains] = useState<string[]>([]);
  const [supportingComments, setSupportingComments] = useState<IEvidencePackageCommentsPutCommand[]>([]);
  const [serviceOid, setServiceOid] = useState<string>();
  const [evidencePackageId, setEvidencePackageId] = useState<string>();
  const [evidencePackageLoadingState, setEvidencePackageLoadingState] = useState<LoadingState>(LoadingState.NotLoaded);

  const evidenceContextProps: EvidenceContextProps = {
    evidencePackage,
    evidencePackageLoadingState,
    // When an evidence package is selected, request the evidence package data and call the API to retrieve the latest
    requestEvidencePackage: (serviceOid: string, evidencePackageId: string) => {
      if (evidencePackageLoadingState === LoadingState.NotLoaded || evidencePackageLoadingState === LoadingState.Error) {
        setServiceOid(serviceOid);
        setEvidencePackageId(evidencePackageId);
        setEvidencePackageLoadingState(LoadingState.Requested);
      }
    },
    // When we have the latest evidence package from a separate api call and want to update the context to have the latest
    // There are a lot of API calls that already return the evidence package and getting it a 2nd time from the API seemed unnecessary
    // and this prevents the page contents from refreshing
    updateEvidencePackage: (evidence: EvidencePackage) => {
      // Update the current evidence package and the cached from API version
      setSavedEvidencePackage(evidence);
      setEvidencePackage(evidence);
    },
    // Erases the current evidence package in the context and re-grabs it from the API (results in a refresh of the page content)
    reloadEvidencePackage: () => {
      if (evidencePackage) {
        setEvidencePackageLoadingState(LoadingState.Requested);
        setSupportingComments([]);
        setSupplementalInfo(undefined);
        setDirtyDomains([]);
        setEvidencePackage(undefined);
      }
    },
    // Erases the current evidence package in the context and resets it to the cached/locally saved version
    // This is primarily used to erase state when the user goes to another tab and doesn't explicitly save the data (rather than reaching back out to the api and causing a refresh of the page
    resetEvidencePackage: () => {
      // revert to the original evidence package because the user did not save the package and the state was lost
      setEvidencePackage(_.cloneDeep(savedEvidencePackage));
      setSupportingComments([]);
      setSupplementalInfo(undefined);
      setDirtyDomains([]);
    },
    dirtyDomains,
    supplementalInfo,
    addSupplementalInfo: (domainName: string, field: string, fieldValue: string | undefined) => {
      populateSupplementalInfo(domainName, field, fieldValue);

      // If the domain is already dirty, don't add it again
      if (!dirtyDomains.find((domain) => domain === domainName)) {
        setDirtyDomains([...dirtyDomains, domainName]);
      }
    },
    supportingComments,
    addSupportingComments: (comment: IEvidencePackageCommentsPutCommand) => {
      populateSupportingComments(comment);

      // If the domain is already dirty, don't add it again
      if (comment.comment.domain && !dirtyDomains.find((domain) => domain === comment.comment.domain)) {
        setDirtyDomains([...dirtyDomains, comment.comment.domain]);
      }
    },
  };

  useEffect(() => {
    const getEvidence = async () => {
      try {
        if (!serviceOid || !evidencePackageId) {
          return;
        }
        const evidence = await getEvidencePackage(serviceOid, evidencePackageId);
        setEvidencePackage(_.cloneDeep(evidence));
        // Creating an original copy so if we need to revert due to the dirty domains
        setSavedEvidencePackage(_.cloneDeep(evidence));
        setEvidencePackageLoadingState(LoadingState.Loaded);
      } catch (error) {
        setEvidencePackageLoadingState(LoadingState.Error);
        logError('There was an issue loading the evidence package', error);
      }
    };

    if (evidencePackageLoadingState === LoadingState.Requested && evidencePackage === undefined) {
      getEvidence();
    }
  }, [evidencePackageLoadingState, evidencePackage, serviceOid, evidencePackageId]);

  const populateSupportingComments = (comment: IEvidencePackageCommentsPutCommand) => {
    setSupportingComments((comments) => {
      const copiedData = [...comments];
      const index = copiedData.findIndex((commentData) => commentData.comment.domain === comment.comment.domain);
      if (index === -1) {
        copiedData.push(comment);
      } else {
        copiedData[index] = comment;
      }
      return copiedData;
    });
  };

  const populateSupplementalInfo = (domainName: string, field: string, fieldValue: string | undefined) => {
    if (!evidencePackage) {
      return;
    }

    let supplementalData: ISupplementalInfo = {
      serviceTreeSupplementalInfo: {
        description: '',
        endpoint: '',
      },
      dataFlowSupplementalInfo: {
        narrative: '',
      },
    } as SupplementalInfo;

    if (supplementalInfo) {
      supplementalData = supplementalInfo;
    } else if (!supplementalInfo && evidencePackage.supplementalInfo) {
      supplementalData = evidencePackage.supplementalInfo;
    }

    if (domainName === Domains.SERVICE_TREE_PROPERTIES) {
      supplementalData.serviceTreeSupplementalInfo[field as keyof IServiceTreeSupplementalInfo] = fieldValue;
    } else if (domainName === Domains.DATA_FLOW_DIAGRAM) {
      supplementalData.dataFlowSupplementalInfo[field as keyof IDataFlowSupplementalInfo] = fieldValue;
    }

    setSupplementalInfo(supplementalData);
    const copiedEvidencePackage = _.cloneDeep(evidencePackage);
    copiedEvidencePackage.supplementalInfo = supplementalData as SupplementalInfo;
    setEvidencePackage(copiedEvidencePackage as EvidencePackage);
  };

  return <EvidenceContext.Provider value={evidenceContextProps}>{children}</EvidenceContext.Provider>;
};
