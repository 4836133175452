import React, { useContext, useEffect, useRef } from 'react';
import { mergeStyles } from '@fluentui/react';
import { ConfigContext } from '../../components/configProvider/configContext';
import { InfoCard } from '../../components/infoCard/infoCard';
import controlCatalogImage from '../../assets/control_catalogs.jpg';
import securityPlanManagerImage from '../../assets/security_plan_manager.jpg';
import controlEvidenceImage from '../../assets/control_evidence.jpg';
import { controlCatalogsLandingRoute, controlEvidenceRoute } from '../../modules/routes/routes';
import { HeroContentContext } from '../../components/heroContentProvider/heroContentContext';
import { LandingPageHeroContent } from './landingPageHeroContent';

export const LandingPage: React.FunctionComponent = () => {
  const configContext = useContext(ConfigContext);
  const heroContentContext = useRef(useContext(HeroContentContext));

  useEffect(() => {
    heroContentContext.current.setAndDisplayHeroContent(LandingPageHeroContent, false);
  }, []);

  const cardWrapperStyles = mergeStyles({
    display: 'flex',
    margin: '0 auto',
    width: 'fit-content',
  });

  return (
    <div className={cardWrapperStyles}>
      <InfoCard
        title="Control Catalogs"
        description="View control requirements and metadata for control frameworks and baselines from any regulatory standard."
        image={controlCatalogImage}
        link={controlCatalogsLandingRoute() || ''}
        linkText="View control catalogs"
      />
      <InfoCard
        title="System Security Plan Manager"
        description="Manage security control implementation details and other security plan content. Generate and download security plan documents or export to Governance Risk Compliance tools."
        image={securityPlanManagerImage}
        link={configContext.serverConfig?.securityPlansLink || ''}
        linkText="View security plans"
      />
      <InfoCard
        title="Control Evidence"
        description="Manage Azure Services accreditation through evidence package review, approval workflow and reporting submissions. Review compliance posture, trends, and manage all your actions in a centralized dashboard."
        image={controlEvidenceImage}
        link={controlEvidenceRoute() || ''}
        linkText="View your actions"
        openNewTab={false}
      />
    </div>
  );
};
