import download from 'downloadjs';
import { showError } from 'modules/messageBar/messageBar';
import {
  ControlMetadataGetClient,
  ControlMetadataGetResponse,
  ControlMetadataListClient,
  ControlMetadataListResponse,
  ControlMetadataToWordPostClient,
  ControlMetadataToWordPostCommand,
  ControlsListResponse,
  IControlMetadataToWordPostCommand,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getControlsMetadata = async (refreshData: boolean, withAllInternalServiceTeams: boolean): Promise<ControlMetadataListResponse[]> => {
  const controlMetadataListClient = new ControlMetadataListClient(getConfig().apiBaseUri);
  return controlMetadataListClient.get(refreshData, withAllInternalServiceTeams);
};

export const getControlMetadata = async (controlId: string): Promise<ControlMetadataGetResponse> => {
  const controlMetadataGetClient = new ControlMetadataGetClient(getConfig().apiBaseUri);
  return controlMetadataGetClient.get(controlId);
};

export const GetInternalServiceTeamSSPWord = async (controlIds: IControlMetadataToWordPostCommand): Promise<boolean> => {
  try {
    const fileName = `internal_service_team_ssp_${new Date().toISOString()}.docx`;
    const internalAzureServiceTeamsGetClient = new ControlMetadataToWordPostClient(getConfig().apiBaseUri);
    const command = controlIds as ControlMetadataToWordPostCommand;
    const response = await internalAzureServiceTeamsGetClient.post(command);
    download(response.data, fileName, 'application/vnd.ms-word');
    return true;
  } catch (ex) {
    showError(`An error occurred while generating the SSP word document: ${ex}`);
    return false;
  }
};

export const GetInternalAzureServiceTeams = (controlMetadataListResponses: ControlMetadataListResponse[]): string[] => {
  const nameSet = new Set<string>();
  controlMetadataListResponses.forEach((controlMetadataListResponse) =>
    controlMetadataListResponse.internalServiceTeamNames?.forEach((internalServiceTeamName) => nameSet.add(internalServiceTeamName.trim())),
  );
  return Array.from(nameSet);
};

export interface Control {
  group: string;
  controlId: string;
  title: string;
}

export const mapControlsListResponseToControlsList = (controlsListResponse: ControlsListResponse[]): Control[] =>
  controlsListResponse
    .filter((listResponse) => listResponse?.controls)
    .map((listResponse) =>
      listResponse.controls
        ? listResponse.controls.map((c) => ({ group: listResponse.id ?? '', controlId: c.id ?? '', title: c.title ?? '' }) as Control)
        : ({} as Control),
    )
    .flatMap((control) => control);
