import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const headerStyles = mergeStyles({
  width: '100%',
  margin: '2em 11em',
});

export const LandingPageHeroContent: FunctionComponent = () => (
  <div className={headerStyles}>
    <h1>Mission Trust Assurance Center</h1>
    <h2>Stay secure and compliant</h2>
  </div>
);
