export enum Certification {
  IL4 = 'IL4',
  IL5 = 'IL5',
  FedRAMP = 'FedRAMP',
}

export const getFormattedCertification = (unformattedCertification: string): string => {
  const lowerCaseCertification = unformattedCertification.toLowerCase();
  switch (lowerCaseCertification) {
    case Certification.IL4.toLowerCase():
      return Certification.IL4;
    case Certification.IL5.toLowerCase():
      return Certification.IL5;
    case Certification.FedRAMP.toLowerCase():
      return Certification.FedRAMP;
    default:
      return unformattedCertification;
  }
};
