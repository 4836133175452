import React, { FunctionComponent, useContext, useEffect, useRef } from 'react';
import { mergeStyles } from '@fluentui/react';
import { HeroContentContext } from '../../components/heroContentProvider/heroContentContext';
import { ControlCatalogHeroContent } from './controlCatalogHeroContent';
import { InfoCard } from '../../components/infoCard/infoCard';
import { controlCatalogsRoute, controlMetadataListRoute } from '../../modules/routes/routes';
import controlMetadataImage from '../../assets/control-metadata.png';
import controlCatalogImage from '../../assets/control-catalogs-homepage.png';

const cardWrapperStyles = mergeStyles({
  display: 'flex',
  margin: '0 auto',
  width: 'fit-content',
});

export const ControlCatalog: FunctionComponent = () => {
  const heroContentContext = useRef(useContext(HeroContentContext));

  useEffect(() => {
    heroContentContext.current.setAndDisplayHeroContent(ControlCatalogHeroContent, false);
  }, []);

  return (
    <div className={cardWrapperStyles}>
      <InfoCard
        title="Control Catalogs"
        description="This is an overview list of all catalogs that can be used as templates"
        image={controlCatalogImage}
        link={controlCatalogsRoute()}
        linkText="View Control Catalogs"
      />
      <InfoCard
        title="Control Metadata"
        description="View coverage metrics and controls that align teams or compliance self-test"
        image={controlMetadataImage}
        link={controlMetadataListRoute()}
        linkText="View Control Metadata"
      />
    </div>
  );
};
