import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { mergeStyles, NeutralColors } from '@fluentui/react';
import { useParams } from 'react-router-dom';
import { FilterBubble } from 'components/filterBubble/filterBubbleComponent';
import { ServiceContext } from 'components/serviceProvider/serviceContext';
import { LoadingState } from '../../models/loadingState';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { AuthorizationStatus } from './authorizationStatus';
import { createHomeChromeConfig, updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { AuthContext } from '../../components/authProvider/authContext';
import { COMPLIANCE_TEAM_ACTIONS_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { ServiceDetailRouteParams } from '../../modules/routes/routes';
import { IAzureService } from '../../generated/clientApi';

const widgetStyle = mergeStyles({
  backgroundColor: NeutralColors.white,
  padding: '1em',
  margin: '0.5em',
  display: 'flex',
  flex: '1',
});

export const ServiceDetail: FunctionComponent = () => {
  const { serviceOid } = useParams<ServiceDetailRouteParams>();
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [serviceSelection, setServiceSelection] = useState<IAzureService>();
  const servicesContext = useContext(ServiceContext);
  const authContext = useContext(AuthContext);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    updatePageChromeConfig(createHomeChromeConfig('service-detail', isComplianceTeam));
    return () => updatePageChromeConfig();
  }, [isComplianceTeam]);

  useEffect(() => {
    servicesContext.requestServices();
  }, [servicesContext]);

  useEffect(() => {
    setIsLoading(servicesContext.servicesLoadingState);
  }, [servicesContext, servicesContext.servicesLoadingState]);

  useEffect(() => {
    if (isLoading !== LoadingState.Loaded) {
      return;
    }

    const service = servicesContext?.services?.find((service) => service.id === serviceOid);
    if (service) {
      setServiceSelection(service);
    }
  }, [servicesContext?.services, isLoading, serviceOid]);

  const setService = (updatedService: string[]) => {
    if (!updatedService.length) {
      return;
    }

    const service = servicesContext?.services?.find((service) => service.name === updatedService[0]);
    if (service) {
      setServiceSelection(service);
      servicesContext.setSelectedService(service.id);
    }
  };

  return (
    <>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          <div className={widgetStyle}>
            <FilterBubble
              key="Service"
              id="Service"
              fieldName="Service"
              filter={serviceSelection ? [serviceSelection.name] : []}
              noneDisplay="None"
              valueOptions={servicesContext.services?.flatMap((service) => service.name) || []}
              onChange={setService}
            />
          </div>
          <div className={widgetStyle}>
            {!serviceSelection ? <>Choose a service</> : <AuthorizationStatus serviceOid={serviceSelection.serviceOid} />}
          </div>
        </>
      )}
    </>
  );
};
