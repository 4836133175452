import { AuthorizedSystem } from 'generated/clientApi';
import _ from 'lodash';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const includeInSearch = (field: any, item: any, searchQuery: any): boolean => {
  if (field === 'name' && ((item as AuthorizedSystem).systemMetadata?.name?.toLowerCase().indexOf(searchQuery) as any) > -1) {
    return true;
  }

  if (field === 'description' && ((item as AuthorizedSystem).systemMetadata?.description?.toLowerCase().indexOf(searchQuery) as any) > -1) {
    return true;
  }
  return false;
};

export const getPlatform = (systems: AuthorizedSystem[]): string[] | any =>
  _.chain(systems)
    .map((x) => x.systemParameters?.platform)
    .uniq()
    .sortBy()
    .value();
export const getRegimes = (systems: AuthorizedSystem[]): string[] | any =>
  _.chain(systems)
    .map((x) => x.systemParameters?.complianceRegime)
    .uniq()
    .sortBy()
    .value();
export const getSystemControlBases = (systems: AuthorizedSystem[]): string[] | any =>
  _.chain(systems)
    .filter((x) => x.systemParameters?.controlsBase !== null)
    .map((x) => x.systemParameters?.controlsBase)
    .uniq()
    .sortBy()
    .value();
