import { IStackTokens, Link, mergeStyles, NeutralColors, PanelType, SharedColors, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { InformationPane } from '../../components/informationPane/informationPane';
import { LoadingState } from '../../models/loadingState';
import { CenteredProgressDots } from '../progressDots/progressDots';
import { ActionCenterContext } from '../actionCenterProvider/actionCenterContext';
import { ActionCenterData } from '../actionCenterProvider/actionCenterProvider';
import { ExpectedServiceTimeline, UpcomingServicesListResponse } from '../../generated/clientApi';
import { ColumnTabWidget } from '../columnTabWidget/columnTabWidget';
import { ListColumn } from '../basicList/basicList';
import { serviceDetailRoute } from '../../modules/routes/routes';
import { COMPLIANCE_TEAM_ACTIONS_READ, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { AuthContext } from '../authProvider/authContext';

const widgetStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '1em',
  margin: '0.5em',
  maxWidth: '70em',
  minWidth: '30em',
  display: 'flex',
  flexDirection: 'column',
});

const countWrapperStyle = mergeStyles({
  padding: '0 1em',
  margin: 'auto 1em auto 0',
  height: 'fit-content',
  fontWeight: 'bold',
  borderLeft: `0.5em solid ${SharedColors.cyanBlue10}`, // #0078d4 standard blue.
});

const countStyle = mergeStyles({
  fontSize: '2em',
});

const informationPaneLinkStyle = mergeStyles({
  margin: '0 1em',
  marginRight: '1em',
});

const themedMediumStackTokens: IStackTokens = {
  childrenGap: 'm',
  padding: 'm',
};

const linkStyle = {
  textDecoration: 'none',
};

const labelStyle = mergeStyles({
  padding: '0 1em',
  fontSize: '1em',
  height: 'fit-content',
  fontWeight: 'bold',
});

const userRoleLabelStyle = mergeStyles({
  padding: '0 1em',
  fontSize: '1em',
  height: 'fit-content',
});

export interface UpcomingServicesUserRoleKeys {
  aoKeys: UpcomingServiceKeys;
  auditorKeys: UpcomingServiceKeys;
}

export interface UpcomingServiceKeys {
  projectedDateKey: string;
  timelineKey: string;
}

interface UpcomingService {
  serviceOid: string;
  serviceName: string;
  cloud: string;
  certification: string;
  projectedDate: Date;
  expectedServiceTimeline: ExpectedServiceTimeline;
}

interface UpcomingServicesDisplayCounts {
  expectedInTwoWeeksCount: number;
  expectedin30DaysCount: number;
}

interface UpcomingServiesByTimeline {
  expectedInTwoWeeks: UpcomingService[];
  expectedInThirtyDays: UpcomingService[];
  delayedOrRemoved: UpcomingService[];
  onboadedEarly: UpcomingService[];
}

export interface UpcomingServicesWidgetProps {
  userRoleTitle: string;
  upcomingServiceKeysForRole: UpcomingServiceKeys;
  allExpectedServicesPanelEnabled: boolean;
}

export const UpcomingServicesWidget: React.FunctionComponent<UpcomingServicesWidgetProps> = (props) => {
  const actionCenterContext = useContext(ActionCenterContext);
  const authContext = useContext(AuthContext);
  const { userRoleTitle, upcomingServiceKeysForRole, allExpectedServicesPanelEnabled } = props;
  const [upcomingServicesByTimeline, setUpcomingServicesByTimeline] = useState<UpcomingServiesByTimeline | undefined>();
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    actionCenterContext.requestContextData(ActionCenterData.UpcomingServices);
  }, [actionCenterContext]);

  useEffect(() => {
    setIsLoading(actionCenterContext.upcomingServices.loadingState);
  }, [actionCenterContext.upcomingServices.loadingState]);

  useEffect(() => {
    if (!actionCenterContext.upcomingServices.contextData) {
      return;
    }

    const upcomingServices = actionCenterContext.upcomingServices.contextData.map(
      (upcomingService) =>
        ({
          ...upcomingService,
          expectedServiceTimeline: upcomingService[upcomingServiceKeysForRole.timelineKey as keyof UpcomingServicesListResponse],
          projectedDate: upcomingService[upcomingServiceKeysForRole.projectedDateKey as keyof UpcomingServicesListResponse],
        }) as UpcomingService,
    );

    const timelineGroups = _.groupBy(upcomingServices, (upcomingService) => upcomingService.expectedServiceTimeline.toString());

    const servicesForTimeline = (key: ExpectedServiceTimeline): UpcomingService[] =>
      timelineGroups !== undefined
        ? Array.from(timelineGroups[ExpectedServiceTimeline[key]] !== undefined ? timelineGroups[ExpectedServiceTimeline[key]].values() : [])
        : [];

    const servicesByTimeline: UpcomingServiesByTimeline = {
      expectedInTwoWeeks: servicesForTimeline(ExpectedServiceTimeline.TwoWeeks),
      expectedInThirtyDays: servicesForTimeline(ExpectedServiceTimeline.ThirtyDays),
      delayedOrRemoved: servicesForTimeline(ExpectedServiceTimeline.DelayedOrRemoved),
      onboadedEarly: servicesForTimeline(ExpectedServiceTimeline.OnboardedEarly),
    };

    setUpcomingServicesByTimeline(servicesByTimeline);
  }, [actionCenterContext.upcomingServices.contextData, upcomingServiceKeysForRole.projectedDateKey, upcomingServiceKeysForRole.timelineKey]);

  const upcomingServiceCounts: UpcomingServicesDisplayCounts = useMemo(
    () => ({
      expectedInTwoWeeksCount: upcomingServicesByTimeline?.expectedInTwoWeeks.length ?? 0,
      expectedin30DaysCount: upcomingServicesByTimeline?.expectedInThirtyDays.length ?? 0,
    }),
    [upcomingServicesByTimeline],
  );

  const serviceColumns: ListColumn[] = useMemo(
    () => [
      {
        key: 'serviceName',
        name: 'Service name',
        onRender: (upcomingService: UpcomingService) => (
          <>
            {isComplianceTeam ? (
              <Link as={RouterLink} to={serviceDetailRoute({ serviceOid: upcomingService.serviceOid })} style={linkStyle}>
                {upcomingService.serviceName}
              </Link>
            ) : (
              <span>{upcomingService.serviceName}</span>
            )}
          </>
        ),
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'cloud',
        name: 'Cloud',
        onRender: (upcomingService: UpcomingService) => <span>{upcomingService.cloud}</span>,
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'certification',
        name: 'Cloud certification',
        onRender: (upcomingService: UpcomingService) => <span>{upcomingService.certification}</span>,
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'projectedDate',
        name: 'Projection date',
        onRender: (upcomingService: UpcomingService) => <span>{upcomingService.projectedDate?.toISOString().split('T')[0]}</span>,
        minWidth: 50,
        maxWidth: 150,
      },
    ],
    [isComplianceTeam],
  );

  const serviceTimelineTabs = useMemo(
    () => [
      {
        title: 'Expected in 2 weeks',
        count: upcomingServicesByTimeline?.expectedInTwoWeeks.length ?? 0,
        statisticColor: NeutralColors.black,
        items: upcomingServicesByTimeline?.expectedInTwoWeeks ?? [],
      },
      {
        title: 'Expected in 30 days',
        count: upcomingServicesByTimeline?.expectedInThirtyDays.length ?? 0,
        statisticColor: SharedColors.cyanBlue10,
        items: upcomingServicesByTimeline?.expectedInThirtyDays ?? [],
      },
      {
        title: 'Delayed or Removed',
        count: upcomingServicesByTimeline?.delayedOrRemoved.length ?? 0,
        statisticColor: SharedColors.orange30,
        items: upcomingServicesByTimeline?.delayedOrRemoved ?? [],
      },
      {
        title: 'Onboarded early',
        count: upcomingServicesByTimeline?.onboadedEarly.length ?? 0,
        statisticColor: SharedColors.blueMagenta10,
        items: upcomingServicesByTimeline?.onboadedEarly ?? [],
      },
    ],
    [upcomingServicesByTimeline],
  );

  const getTotalCount = () => {
    if (upcomingServicesByTimeline !== undefined) {
      return Object.values(upcomingServicesByTimeline).reduce((prevValue, currentValue) => (prevValue + currentValue.length) as number, 0);
    }

    return 0;
  };

  return (
    // Intentionally retuning empty div until requirements for this widget can be figured out. Tyler 2022-10-20
    // eslint-disable-next-line no-constant-condition
    true ? (
      <></>
    ) : (
      <div className={widgetStyle}>
        <span className={labelStyle}>Upcoming Packages</span>
        <span className={userRoleLabelStyle}>{userRoleTitle}</span>
        {/* Note: Tabs/pivot item buttons do not show unless there is more than 1 - not adding until needed */}
        {isLoading !== LoadingState.Loaded ? (
          <CenteredProgressDots />
        ) : (
          <Stack tokens={themedMediumStackTokens}>
            <Stack.Item className={countWrapperStyle}>
              Expected in two weeks
              <div className={countStyle}>{`${upcomingServiceCounts.expectedInTwoWeeksCount} Service(s)`}</div>
            </Stack.Item>
            <Stack.Item className={countWrapperStyle}>
              Expected in thirty days
              <div className={countStyle}>{`${upcomingServiceCounts.expectedin30DaysCount} Service(s)`}</div>
            </Stack.Item>
            <Stack.Item className={countWrapperStyle}>
              Total expected services
              <div
                className={countStyle}
              >{`${upcomingServiceCounts.expectedInTwoWeeksCount + upcomingServiceCounts.expectedin30DaysCount} Service(s)`}</div>
            </Stack.Item>
          </Stack>
        )}
        {allExpectedServicesPanelEnabled && (
          <div className={informationPaneLinkStyle}>
            <InformationPane
              text="View all expected services"
              isPanelBlocking={false}
              panelHeader="All Expected Services"
              panelType={PanelType.large}
            >
              {isLoading !== LoadingState.Loaded ? (
                <CenteredProgressDots />
              ) : (
                <ColumnTabWidget
                  title="All expected services"
                  columnTabs={serviceTimelineTabs}
                  totalTitle="Total Services"
                  totalCount={getTotalCount()}
                  defaultActiveColumn={0}
                  listColumns={serviceColumns}
                />
              )}
            </InformationPane>
          </div>
        )}
      </div>
    )
  );
};
