import {
  CatalogGetClient,
  CatalogGetResponse,
  CatalogsListClient,
  CatalogsListResponse,
  ControlGetClient,
  ControlGetResponse,
  ControlsListClient,
  ControlsListResponse,
  GroupGetClient,
  GroupGetResponse,
  GroupsListClient,
  GroupsListResponse,
  ISystemSnapshotControlGetResponse,
  SystemSnapshotControlGetClient,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const allControls = 'all';

export const getControlCatalogs = async (): Promise<CatalogsListResponse[]> => {
  const catalogsListClient = new CatalogsListClient(getConfig().apiBaseUri);
  return catalogsListClient.get();
};

export const getControlCatalog = async (catalogId: string): Promise<CatalogGetResponse> => {
  const catalogGetClient = new CatalogGetClient(getConfig().apiBaseUri);
  return catalogGetClient.get(catalogId);
};

export const getControlCatalogControlGroups = async (catalogId?: string): Promise<GroupsListResponse[]> => {
  const controlsListClient = new GroupsListClient(getConfig().apiBaseUri);
  return controlsListClient.get(catalogId ?? allControls);
};

export const getControlGroup = async (catalogId: string, groupId: string): Promise<GroupGetResponse> => {
  const controlGroupsGetClient = new GroupGetClient(getConfig().apiBaseUri);
  return controlGroupsGetClient.get(catalogId, groupId);
};

export const getControl = async (catalogId: string, groupId: string, controlId: string): Promise<ControlGetResponse> => {
  const controlGetClient = new ControlGetClient(getConfig().apiBaseUri);
  return controlGetClient.get(catalogId, groupId, controlId);
};

export const getControlImplementation = async (
  organizationId: string,
  systemId: string,
  snapshotId: string,
  controlId: string,
): Promise<ISystemSnapshotControlGetResponse> => {
  const controlGetClient = new SystemSnapshotControlGetClient(getConfig().apiBaseUri);
  return controlGetClient.get(organizationId, systemId, snapshotId, controlId);
};

export const getControlCatalogControlsByGroup = async (catalogId?: string, groupId?: string): Promise<ControlsListResponse[]> => {
  const controlsListClient = new ControlsListClient(getConfig().apiBaseUri);
  return controlsListClient.get(catalogId ?? allControls, groupId ?? allControls);
};
