import {
  AzureService,
  IServiceAuthorizationPutCommand,
  ServiceAuthorizationPutClient,
  ServiceAuthorizationPutCommand,
  ServiceGetClient,
  ServicesListClient,
  IAzureService,
  UpcomingServicesListClient,
  UpcomingServicesListResponse,
} from '../../generated/clientApi';
import { getConfig } from '../config/config';

export const getServices = async (): Promise<IAzureService[]> => {
  const servicesGetClient = new ServicesListClient(getConfig().apiBaseUri);
  return servicesGetClient.get();
};

export const getService = async (serviceOid: string): Promise<AzureService> => {
  const serviceGetClient = new ServiceGetClient(getConfig().apiBaseUri);
  return serviceGetClient.get(serviceOid);
};

export const updateServiceAuthorizationsActive = async (service: IServiceAuthorizationPutCommand): Promise<AzureService> => {
  const client = new ServiceAuthorizationPutClient(getConfig().apiBaseUri);
  const command = service as ServiceAuthorizationPutCommand;
  return client.put(command.serviceOid, command);
};

export const getUpcomingServices = async (): Promise<UpcomingServicesListResponse[]> => {
  const upcomingServicesGetClient = new UpcomingServicesListClient(getConfig().apiBaseUri);
  return upcomingServicesGetClient.get();
};
