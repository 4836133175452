import { DatePicker, Dropdown, IDropdownOption, IStackTokens, Stack, TextField, defaultDatePickerStrings, mergeStyles } from '@fluentui/react';
import { Accordion } from 'components/accordion/accordion';
import { IAuthorizedSystem, ISystemFrontMatter } from 'generated/clientApi';
import { formatDateInternationalStandard, getDateOnlyString, parseDate } from 'modules/datetime/datetime';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { datePickerStyles } from 'styles/datePickerStyles';
import { dropdownStyles } from 'styles/dropdownStyles';
import { textFieldStyles } from 'styles/textFieldStyles';

const headerStyle = mergeStyles({
  fontSize: '20px',
});

const pageStyles = mergeStyles({
  paddingLeft: '0.75em',
});

const accordionStyles = {
  root: {
    padding: '0.75em',
    display: 'flex',
    borderTop: '0.5px solid #eee',
  },
  headerDiv: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
  } as const,
  icon: {
    margin: '5px 10px 10px 0',
  },
};

const fieldStackTokens: IStackTokens = {
  childrenGap: '2.5em',
};

const serviceModelOptions: IDropdownOption[] = [
  { key: 'IaaS', text: 'IaaS' },
  { key: 'PaaS', text: 'PaaS' },
  { key: 'SaaS', text: 'SaaS' },
  { key: 'IaaSPaaS', text: 'IaaS/PaaS' },
  { key: 'IaaSPaaSSaaS', text: 'IaaS/PaaS/SaaS' },
  { key: 'IaaSSaaS', text: 'IaaS/SaaS' },
  { key: 'PaaSSaaS', text: 'PaaS/SaaS' },
  { key: 'LISaaS', text: 'LI-SaaS' },
];

const dilDeterminationOptions: IDropdownOption[] = [
  { key: 'IAAL3_FAL3_AAL3', text: 'IAL3/FAL3/AAL3' },
  { key: 'IAAL2_FAL2_AAL2', text: 'IAL2/FAL2/AAL2' },
  { key: 'IAAL1_FAL1_AAL1', text: 'IAL1/FAL1/AAL1' },
];

const fipsLevelOptions: IDropdownOption[] = [
  { key: 'High', text: 'High' },
  { key: 'Moderate', text: 'Moderate' },
  { key: 'Low', text: 'Low' },
  { key: 'LlSaas', text: 'LI-SaaS' },
];

const deploymentModelOptions: IDropdownOption[] = [
  { key: 'PublicCloud', text: 'Public Cloud' },
  { key: 'GovernmentOnly', text: 'Government-Only Cloud' },
  { key: 'Hybrid', text: 'Hybrid Cloud' },
];

const authorizationPathOptions: IDropdownOption[] = [
  { key: 'FedRampJab', text: 'Joint Authorization Board Provisional' },
  { key: 'FedRampAgency', text: 'Agency Authorization' },
];

const lowestDILLevelOptions: IDropdownOption[] = [
  { key: 'Level1_AAL1_IAL1_FAL1', text: 'Level 1: AAL1, IAL1, FAL1' },
  { key: 'Level2_AAL2_IAL2_FAL2', text: 'Level 2: AAL2, IAL2, FAL2' },
  { key: 'Level3_AAL3_IAL2_FAL3', text: 'Level 3: AAL3, IAL2, FAL3' },
];

export enum SystemFrontMatterFieldLabels {
  CSOName = 'CSO Name',
  CSOUserGuideURL = 'CSO User Guide URL',
  FedRAMPPackageId = 'FedRAMP Package Id',
  RevisionHistoryURL = 'Revision History URL',
  ServiceModel = 'Service Model',
  DILDetermination = 'DIL Determination',
  FIPS199Level = 'FIPS 199 Level',
  DeploymentModel = 'Deployment Model',
  AuthorizationPath = 'Authorization Path',
  FullyDeployedOnDate = 'Fully Deployed On Date',
  TableURL = 'Table URL',
  AppendixBRelatedAcronymsTableURL = 'Appendix B Related Acronyms Table URL',
  AppendixEDILTableURL = 'Appendix E DIL Table URL',
  LowestDILLevel = 'Lowest DIL Level',
  AppendixKFIPS199CategorizationTableURL = 'Appendix K FIPS 199 Categorization Table URL',
  AppendixLSpecificLawsAndRegulationsTableURL = 'Appendix L Specific Laws and Regulations Table URL',
  AppendixQCryptographicModulesTableURL = 'Appendix Q Cryptographic Modules Table URL',
}

export enum SystemFrontMatterFields {
  CSOName = 'csoName',
  CSOUserGuideURL = 'csoUserGuideURL',
  fedRAMPPackageId = 'fedRAMPPackageId',
  revisionHistoryURL = 'revisionHistoryURL',
  serviceModel = 'serviceModel',
  dilDetermination = 'dilDetermination',
  fipS199Level = 'fipS199Level',
  deploymentModel = 'deploymentModel',
  authorizationPath = 'authorizationPath',
  fullyDeployedOnDate = 'fullyDeployedOnDate',
  leveragedFEDRAMPTableURL = 'leveragedFEDRAMPTableURL',
  externalSystemTableURL = 'externalSystemTableURL',
  appendixBRelatedAcronymsTableURL = 'appendixBRelatedAcronymsTableURL',
  appendixEDILTableURL = 'appendixEDILTableURL',
  appendixKTableURL = 'appendixKTableURL',
  lowestDILLevel = 'lowestDILLevel',
  appendixKFIPS199CategorizationTableURL = 'appendixKFIPS199CategorizationTableURL',
  appendixLSpecificLawsAndRegulationsTableURL = 'appendixLSpecificLawsAndRegulationsTableURL',
  appendixQCryptographicModulesTableURL = 'appendixQCryptographicModulesTableURL',
}

interface SystemFrontMatterProps {
  system: IAuthorizedSystem | undefined;
  updateFrontMatter(frontMatter: ISystemFrontMatter): void;
}

export const SystemFrontMatterDetail: FunctionComponent<SystemFrontMatterProps> = (props) => {
  const { system, updateFrontMatter } = props;
  const [frontMatter, setFrontMatter] = useState<ISystemFrontMatter>({} as ISystemFrontMatter);

  useEffect(() => {
    if (system?.systemFrontMatter) {
      setFrontMatter(system.systemFrontMatter);
    }
  }, [system]);

  const onFrontMatterChange = (name: string, newValue?: string): void => {
    const newFrontMatter: ISystemFrontMatter = { ...frontMatter, [name]: newValue };
    updateFrontMatter(newFrontMatter);
    setFrontMatter(newFrontMatter);
  };

  const checkIfUrlValid = (value?: string): string => {
    if (!value) {
      return '';
    }

    const regex = new RegExp(
      /^(https?:\/\/(?:www\.|(?!www))?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})?$/,
      'g',
    );
    if (regex.test(value)) {
      return '';
    }
    return 'Not a valid URL';
  };

  return (
    <div className={pageStyles}>
      <h2>Front Matter</h2>
      <Accordion styles={accordionStyles} headerElement={<div className={headerStyle}>Package Information</div>} displayCheckbox={false}>
        <Stack horizontal tokens={fieldStackTokens}>
          <TextField
            required
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.CSOName}
            value={frontMatter?.csoName || ''}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.CSOName, newValue)}
          />
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.CSOUserGuideURL}
            value={frontMatter?.csoUserGuideURL || ''}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.CSOUserGuideURL, newValue)}
          />
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.FedRAMPPackageId}
            value={frontMatter?.fedRAMPPackageId || ''}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.fedRAMPPackageId, newValue)}
          />
        </Stack>
        <Stack horizontal tokens={fieldStackTokens}>
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.RevisionHistoryURL}
            value={frontMatter?.revisionHistoryURL}
            errorMessage={checkIfUrlValid(frontMatter?.revisionHistoryURL)}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.revisionHistoryURL, newValue)}
          />
        </Stack>
      </Accordion>

      <Accordion styles={accordionStyles} headerElement={<div className={headerStyle}>System Information</div>} displayCheckbox={false}>
        <Stack horizontal tokens={fieldStackTokens}>
          <Dropdown
            label={SystemFrontMatterFieldLabels.ServiceModel}
            placeholder="Select a service model"
            options={serviceModelOptions}
            styles={dropdownStyles}
            selectedKey={frontMatter.serviceModel}
            onChange={(event: any, option?: IDropdownOption) => onFrontMatterChange(SystemFrontMatterFields.serviceModel, option?.key as string)}
          />
          <Dropdown
            label={SystemFrontMatterFieldLabels.DILDetermination}
            placeholder="Select a DIL determination"
            options={dilDeterminationOptions}
            styles={dropdownStyles}
            selectedKey={frontMatter.dilDetermination}
            onChange={(event: any, option?: IDropdownOption) => onFrontMatterChange(SystemFrontMatterFields.dilDetermination, option?.key as string)}
          />
          <Dropdown
            label={SystemFrontMatterFieldLabels.FIPS199Level}
            placeholder="Select a FIPS 199 level"
            options={fipsLevelOptions}
            styles={dropdownStyles}
            selectedKey={frontMatter.fipS199Level}
            onChange={(event: any, option?: IDropdownOption) => onFrontMatterChange(SystemFrontMatterFields.fipS199Level, option?.key as string)}
          />
        </Stack>

        <Stack horizontal tokens={fieldStackTokens}>
          <Dropdown
            label={SystemFrontMatterFieldLabels.DeploymentModel}
            placeholder="Select a deployment model"
            options={deploymentModelOptions}
            styles={dropdownStyles}
            selectedKey={frontMatter.deploymentModel}
            onChange={(event: any, option?: IDropdownOption) => onFrontMatterChange(SystemFrontMatterFields.deploymentModel, option?.key as string)}
          />
          <Dropdown
            label={SystemFrontMatterFieldLabels.AuthorizationPath}
            placeholder="Select a authorization path"
            options={authorizationPathOptions}
            styles={dropdownStyles}
            selectedKey={frontMatter.authorizationPath}
            onChange={(event: any, option?: IDropdownOption) => onFrontMatterChange(SystemFrontMatterFields.authorizationPath, option?.key as string)}
          />
          <DatePicker
            label={SystemFrontMatterFieldLabels.FullyDeployedOnDate}
            allowTextInput
            ariaLabel="Select a date"
            styles={datePickerStyles}
            placeholder="Select a date"
            formatDate={formatDateInternationalStandard}
            strings={defaultDatePickerStrings}
            value={parseDate(frontMatter.fullyDeployedOnDate)}
            onSelectDate={(date: Date | null | undefined) =>
              onFrontMatterChange(SystemFrontMatterFields.fullyDeployedOnDate, getDateOnlyString(date))
            }
          />
        </Stack>
      </Accordion>

      <Accordion
        styles={accordionStyles}
        headerElement={<div className={headerStyle}>Leveraged FedRAMP Authorized Services</div>}
        displayCheckbox={false}
      >
        <Stack horizontal tokens={fieldStackTokens}>
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.TableURL}
            value={frontMatter?.leveragedFEDRAMPTableURL}
            errorMessage={checkIfUrlValid(frontMatter?.leveragedFEDRAMPTableURL)}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.leveragedFEDRAMPTableURL, newValue)}
          />
        </Stack>
      </Accordion>

      <Accordion
        styles={accordionStyles}
        headerElement={<div className={headerStyle}>External Systems and Services Not Having FedRAMP Authorization</div>}
        displayCheckbox={false}
      >
        <Stack horizontal tokens={fieldStackTokens}>
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.TableURL}
            value={frontMatter?.externalSystemTableURL}
            errorMessage={checkIfUrlValid(frontMatter?.externalSystemTableURL)}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.externalSystemTableURL, newValue)}
          />
        </Stack>
      </Accordion>

      <Accordion styles={accordionStyles} headerElement={<div className={headerStyle}>Appendices</div>} displayCheckbox={false}>
        <Stack horizontal tokens={fieldStackTokens}>
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.AppendixBRelatedAcronymsTableURL}
            value={frontMatter?.appendixBRelatedAcronymsTableURL}
            errorMessage={checkIfUrlValid(frontMatter?.appendixBRelatedAcronymsTableURL)}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.appendixBRelatedAcronymsTableURL, newValue)}
          />
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.AppendixEDILTableURL}
            value={frontMatter?.appendixEDILTableURL}
            errorMessage={checkIfUrlValid(frontMatter?.appendixEDILTableURL)}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.appendixEDILTableURL, newValue)}
          />
        </Stack>
        <Stack horizontal tokens={fieldStackTokens}>
          <Dropdown
            label={SystemFrontMatterFieldLabels.LowestDILLevel}
            placeholder="Select a lowest DIL level"
            options={lowestDILLevelOptions}
            styles={dropdownStyles}
            selectedKey={frontMatter.lowestDILLevel}
            onChange={(event: any, option?: IDropdownOption) => onFrontMatterChange(SystemFrontMatterFields.lowestDILLevel, option?.key as string)}
          />
          <TextField
            styles={textFieldStyles}
            required
            label={SystemFrontMatterFieldLabels.AppendixKFIPS199CategorizationTableURL}
            value={frontMatter?.appendixKFIPS199CategorizationTableURL}
            errorMessage={checkIfUrlValid(frontMatter?.appendixKFIPS199CategorizationTableURL)}
            onChange={(event: any, newValue?: string) =>
              onFrontMatterChange(SystemFrontMatterFields.appendixKFIPS199CategorizationTableURL, newValue)
            }
          />
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.AppendixLSpecificLawsAndRegulationsTableURL}
            value={frontMatter?.appendixLSpecificLawsAndRegulationsTableURL}
            errorMessage={checkIfUrlValid(frontMatter?.appendixLSpecificLawsAndRegulationsTableURL)}
            onChange={(event: any, newValue?: string) =>
              onFrontMatterChange(SystemFrontMatterFields.appendixLSpecificLawsAndRegulationsTableURL, newValue)
            }
          />
        </Stack>
        <Stack horizontal tokens={fieldStackTokens}>
          <TextField
            styles={textFieldStyles}
            label={SystemFrontMatterFieldLabels.AppendixQCryptographicModulesTableURL}
            value={frontMatter?.appendixQCryptographicModulesTableURL}
            errorMessage={checkIfUrlValid(frontMatter?.appendixQCryptographicModulesTableURL)}
            onChange={(event: any, newValue?: string) => onFrontMatterChange(SystemFrontMatterFields.appendixQCryptographicModulesTableURL, newValue)}
          />
        </Stack>
      </Accordion>
    </div>
  );
};
