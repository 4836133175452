import { mergeStyles, Panel, PanelType } from '@fluentui/react';
import React from 'react';
import { domainMap } from '../../models/domainConstants';
import { DomainEvidenceData } from '../../components/domainEvidenceData/domainEvidenceData';
import { EvidenceAttachments } from '../../components/evidenceAttachments/evidenceAttachments';
import { DomainEvidenceAttachmentInfo, IDomainEvidence, SnapshotMinimal } from '../../generated/clientApi';

export interface SnapshotPreviewPanelProps {
  headerText: string;
  isSnapshotPreviewOpen: boolean;
  snapshotMinimal: SnapshotMinimal;
  domainEvidence: IDomainEvidence;
  serviceOid: string;
  serviceName: string;
  onDismissSnapshotPreviewPanel(): void;
}

const panelHeaderStyle = mergeStyles({
  fontSize: 24,
});

const attachmentsDivStyles = mergeStyles({
  paddingLeft: '1%',
});

export const SnapshotPreviewPanel: React.FunctionComponent<SnapshotPreviewPanelProps> = (props) => {
  const { onDismissSnapshotPreviewPanel, isSnapshotPreviewOpen, domainEvidence, snapshotMinimal, serviceOid, serviceName } = props;

  const panelHeader = `${props.headerText} | Snapshot | ${snapshotMinimal.displayName}`;

  return (
    <Panel
      isOpen={isSnapshotPreviewOpen}
      headerText={panelHeader}
      headerClassName={mergeStyles(panelHeaderStyle)}
      type={PanelType.large}
      closeButtonAriaLabel="Close"
      onDismiss={onDismissSnapshotPreviewPanel}
      isLightDismiss
    >
      <div className={attachmentsDivStyles}>
        {domainEvidence && (
          <DomainEvidenceData
            title={domainMap.get(domainEvidence.name) || ''}
            shouldRenderAttachments
            domainEvidence={domainEvidence}
            serviceOid={serviceOid}
            serviceName={serviceName}
            shouldRenderComments={false}
            evidenceAttachments={
              <EvidenceAttachments
                domainEvidenceAttachmentInfos={domainEvidence?.attachmentInfo as DomainEvidenceAttachmentInfo[]}
                serviceOid={serviceOid}
              />
            }
            isReadOnly
          />
        )}
      </div>
    </Panel>
  );
};
