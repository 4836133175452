import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const heroWrapperStyles = mergeStyles({
  width: '100%',
  margin: '2em 11em',
});

export const SecurityPlanManagerHeroContent: FunctionComponent = () => (
  <div className={heroWrapperStyles}>
    <h1>System Security Plan Manager</h1>
    <h2>View and create a system and generate reports.</h2>
  </div>
);
