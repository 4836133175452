import { ITextFieldStyles, NeutralColors, SharedColors } from '@fluentui/react';

export const commentStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    borderTopColor: NeutralColors.gray40,
    borderLeftColor: NeutralColors.gray40,
    borderRightColor: NeutralColors.gray40,
    borderBottomColor: SharedColors.cyan20,
    borderBottomWidth: '4px',
    marginBottom: '10px',
  },
};
