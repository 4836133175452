import { Checkbox, DetailsList, IColumn, ISearchBoxStyles, SearchBox, SelectionMode } from '@fluentui/react';
import { Poam } from 'generated/clientApi';
import { baseSortableColumn, handleTableSort, sortByProperty } from 'pages/conMon/components/tableUtils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PoamColumnNames } from '../poamTable';
import { formatRiskWithColor, formatRiskScoreWithColor } from '../poamTableColumns';
import { PoamWithProposedScore } from './autoRiskPanel';

const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: '250px', height: '28px' } };

type AutoRiskTableProps = {
  poams: PoamWithProposedScore[];
  isSovereignCloud: boolean;
  selectedKeys: string[];
  setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;
};
export const AutoRiskTable: React.FunctionComponent<AutoRiskTableProps> = (props) => {
  const { poams, isSovereignCloud, selectedKeys, setSelectedKeys } = props;
  const [searchText, setSearchText] = useState<string>();

  const [filteredPoams, setFilteredPoams] = useState(poams);
  useEffect(
    () =>
      setFilteredPoams(
        poams.filter(
          (poam) => poam.poamId?.toLowerCase().includes(searchText?.toLowerCase() ?? '') || poam.vulnerabilityId?.includes(searchText ?? ''),
        ),
      ),
    [poams, searchText],
  );

  const sortTable = useCallback((sortColumn: IColumn | null, sortDirection: 'asc' | 'desc', isRiskRatingSort = false): void => {
    if (sortColumn && sortDirection) {
      setFilteredPoams((prev) => sortByProperty(prev, sortColumn.fieldName as keyof PoamWithProposedScore, sortDirection, isRiskRatingSort));
    }
  }, []);

  const isSovereignCloudHighToLow = (originalRating?: string, proposedScore?: string) => {
    if (!isSovereignCloud) {
      return false;
    }
    const isHigh: boolean = parseFloat(originalRating ?? '') >= 7.0;
    const isLow: boolean = parseFloat(proposedScore ?? '') < 4;
    return isHigh && isLow;
  };

  const defaultColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'column1',
        name: 'SelectAll',
        ariaLabel: 'Select All Rows',
        minWidth: 24,
        maxWidth: 24,
        onRender: (item: Poam) => (
          <Checkbox
            checked={selectedKeys.includes(item.id)}
            onChange={(ev, checked) => {
              if (checked) {
                setSelectedKeys((prev) => [...prev, item.id]);
              } else {
                setSelectedKeys((prev) => prev.filter((val) => val !== item.id));
              }
            }}
          />
        ),
        onRenderHeader: () => (
          <Checkbox
            styles={{
              root: {
                height: '42px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              },
            }}
            checked={filteredPoams.length > 0 && selectedKeys.length === filteredPoams.length}
            onChange={() => {
              if (selectedKeys.length === filteredPoams.length) {
                setSelectedKeys([]);
              } else if (selectedKeys.length > 0 && selectedKeys.length < filteredPoams.length) {
                setSelectedKeys(poams.map((poam) => poam.id));
              } else {
                setSelectedKeys(poams.map((poam) => poam.id));
              }
            }}
          />
        ),
      },
      {
        key: PoamColumnNames['POA&M ID'],
        name: 'POA&M ID',
        fieldName: 'id',
        minWidth: 100,
        maxWidth: 200,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
      },
      {
        key: PoamColumnNames.Title,
        name: 'Description',
        fieldName: 'title',
        minWidth: 150,
        maxWidth: 300,
        onRender: (item: Poam) => <span title={item.title}>{item.title}</span>,
      },
      {
        key: PoamColumnNames['Original risk'],
        name: 'Original risk',
        fieldName: 'originalRisk',
        minWidth: 130,
        maxWidth: 150,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable, true),
        onRender: (item: Poam) => formatRiskWithColor(item.originalRating ?? '', item.originalRiskScore),
      },
      {
        key: PoamColumnNames['Effective risk'],
        name: 'Effective risk',
        fieldName: 'effectiveRating',
        minWidth: 130,
        maxWidth: 150,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable, true),
        onRender: (item: Poam) => formatRiskWithColor(item.effectiveRating ?? '', item.effectiveRiskScore),
      },
      {
        key: 'Proposed Risk',
        name: 'Proposed risk',
        fieldName: 'proposedScore',
        minWidth: 140,
        maxWidth: 150,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
        onRender: (item: PoamWithProposedScore) => {
          const { proposedScore } = item;
          if (isSovereignCloudHighToLow(item.originalRiskScore, proposedScore)) {
            return (
              <div style={{ display: 'flex', gap: '0.2rem', alignItems: 'center' }}>
                {formatRiskWithColor('Moderate', proposedScore)}
                <div>*</div>
              </div>
            );
          }
          return formatRiskScoreWithColor(proposedScore);
        },
      },
      {
        key: PoamColumnNames.OR,
        name: 'OR status',
        fieldName: 'or',
        minWidth: 100,
        maxWidth: 100,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
      },
      {
        key: PoamColumnNames.FP,
        name: 'FP status',
        fieldName: 'fp',
        minWidth: 100,
        maxWidth: 100,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleTableSort(column, setColumns, sortTable),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSovereignCloudHighToLow, selectedKeys, sortTable],
  );

  const [columns, setColumns] = useState(defaultColumns);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setColumns(defaultColumns), [selectedKeys.length]);
  return (
    <div style={{ marginTop: '1.5rem' }}>
      <SearchBox
        placeholder="Search by POA&M ID or Vuln ID"
        value={searchText}
        styles={searchBoxStyles}
        onEscape={(_) => setSearchText(undefined)}
        onClear={(_) => setSearchText(undefined)}
        onChange={(_, newValue) => setSearchText(newValue)}
      />
      <DetailsList items={filteredPoams} columns={columns} selectionMode={SelectionMode.none} />
    </div>
  );
};
