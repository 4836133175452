import { NeutralColors, mergeStyles } from '@fluentui/react';

/**
 * A custom class for the fluent UI <PrimaryButton> component to style it properly with MTAC's current AzureThemeLight theme
 * @example
 * <PrimaryButton className={defaultButtonStyles} iconProps={{ iconName: 'Undo' }}>
 *     Undo
 * </PrimaryButton>
 */
export const defaultButtonStyles = mergeStyles({
  minWidth: '56px',
  borderRadius: '4px',
  padding: '0.5em 1em !important',
  color: '#242424',
  backgroundColor: NeutralColors.white,
  border: '1px solid #D1D1D1 !important',
  height: 'unset !important',
  ':active:hover': {
    textDecoration: 'none',
  },
  ':hover': {
    color: '#242424',
    backgroundColor: NeutralColors.gray10,
    border: '1px solid #D1D1D1 !important',
    textDecoration: 'none',
  },
  ':active': {
    backgroundColor: NeutralColors.gray10,
    border: '1px solid #D1D1D1 !important',
    textDecoration: 'none',
  },
  ':disabled': {
    color: 'lightgrey',
    borderColor: '#D1D1D1',
  },
  ':focus': {
    backgroundColor: `${NeutralColors.gray10} !important`,
    border: '1px solid #D1D1D1 !important',
    outline: 'none',
  },
});
