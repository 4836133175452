import { INavLink, INavLinkGroup } from '@fluentui/react';
import { organizationFrontMatterRoute, reportsRoute, systemsRoute } from 'modules/routes/routes';
import { SspManagerNavLinks } from 'models/sspManagerNavLinks';
import { createNavLink, createNavLinkGroup } from './navMenu';

const createSystemsNavLink = (disabled: boolean): INavLink => createNavLink('Systems', systemsRoute(), '', disabled, SspManagerNavLinks.Systems);

const createReportNavLink = (disabled: boolean): INavLink => createNavLink('Report', reportsRoute(), '', disabled, SspManagerNavLinks.Reports);

const createOrgnaiztionalFrontMatterNavLink = (disabled: boolean): INavLink =>
  createNavLink('Org Front Matter', organizationFrontMatterRoute(), '', disabled, SspManagerNavLinks.OrganizationFrontMatter);

export const createSspManagerNavLinkGroup = (disabled: boolean): INavLinkGroup[] => {
  const links = [createReportNavLink(disabled), createSystemsNavLink(disabled), createOrgnaiztionalFrontMatterNavLink(disabled)];

  return createNavLinkGroup('', links);
};
