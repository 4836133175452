/* Note: Originally pulled from an older repository and continues to be updated to be more efficient and conform with current codestyle. */
import {
  mergeStyles,
  Announced,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  IDetailsRowProps,
  SelectionMode,
  SearchBox,
  Dropdown,
  IDropdownOption,
  Selection,
} from '@fluentui/react';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { searchStyles } from 'styles/searchStyles';
import { dropdownStyles } from 'styles/dropdownStyles';

const filterWrapperStyles = mergeStyles({
  display: 'flex',
  flexWrap: 'wrap',
});

const filterStyles = mergeStyles({
  margin: '1em 0 1em 1em',
  '.ms-Dropdown': {
    minHeight: '32px',
  },
});

const searchBoxStyles = mergeStyles({
  margin: 'auto 0 1em 1em',
  minHeight: '32px',
});

const listStyles = mergeStyles({
  paddingBottom: 20,
  wordWrap: 'break-word',
});

export interface ISortableFilterableListRow {
  id: string;
}

export interface ISortableFilterableListColumn extends IColumn {
  isHidden?: boolean;
  isSortable?: boolean;
  isFilterable?: boolean;
  getFilterValue?(item?: any): string;
  getFilterValues?(item?: any): string[];
  getFieldValue?(item: any): string;
  customFilterValues?: string[];
  customFilterFunction?: (value: string, filterValues: readonly string[]) => boolean;
  caseInsensitiveFiltering?: boolean;
  filterDefaultSelection?: string[];
}

export interface SortableFilterableListProps {
  items: ISortableFilterableListRow[] | undefined;
  columns: ISortableFilterableListColumn[];
  onRenderRow?(onRenderRowProps: IDetailsRowProps | undefined, defaultRender: any): any;
  onRenderItemColumn?(item?: any, index?: number | undefined, column?: ISortableFilterableListColumn | undefined): any;
  onTableSelectionChanged?(selectedItems: any[]): any;
  initialSortFieldName?: string;
  initialSortDescending?: boolean;
  notScrollable?: boolean;
  textSortFields?: string[];
  textSortMessage?: string;
  resetSelection?: boolean;
  selectionMode?: SelectionMode;
  includeSearchFilterValue(field: any, item: any, searchQuery: any): boolean;
}

const getCellText = (item: any, column: ISortableFilterableListColumn): string => {
  let value = '';
  if (item && column && column.getFieldValue) {
    value = column.getFieldValue(item);
  } else if (item && column && column.fieldName) {
    value = item[column.fieldName];
  }

  if (value === null || value === undefined) {
    value = '';
  }
  return value.toLowerCase();
};

const setColumnDefaults = (propColumn: ISortableFilterableListColumn) => {
  const column = { ...propColumn };
  column.minWidth = column.minWidth ?? 210;
  column.maxWidth = Math.max(column.minWidth, column.maxWidth ?? 350);
  column.isRowHeader = column.isRowHeader != null ? column.isRowHeader : false;
  column.isResizable = column.isResizable != null ? column.isResizable : true;
  column.isSortable = column.isSortable != null ? column.isSortable : true;
  column.isSorted = column.isSorted != null ? column.isSorted : false;
  column.isSortedDescending = column.isSortedDescending != null ? column.isSortedDescending : false;
  column.isPadded = column.isPadded != null ? column.isPadded : false;
  column.sortAscendingAriaLabel = column.sortAscendingAriaLabel || 'Sorted A to Z';
  column.sortDescendingAriaLabel = column.sortDescendingAriaLabel || 'Sorted Z to A';
  column.data = column.data || 'string';
  column.ariaLabel = column.name || 'Delete';
  return column;
};

const getKey = (item: ISortableFilterableListRow): string => item.id;

const copyAndSort = function _copyAndSort<T>(itemsToCopyAndSort: T[], column: ISortableFilterableListColumn): T[] {
  return itemsToCopyAndSort
    .slice(0)
    .sort((a: T, b: T) =>
      (column.isSortedDescending ? getCellText(a, column) < getCellText(b, column) : getCellText(a, column) > getCellText(b, column)) ? 1 : -1,
    );
};

const createDropdownOption = (dropdownValues: string[]): IDropdownOption[] =>
  dropdownValues.map(
    (dropdownValue) =>
      ({
        key: dropdownValue || '',
        text: dropdownValue || '',
      }) as IDropdownOption,
  );

export const SearchableFilterableList: React.FunctionComponent<SortableFilterableListProps> = (props) => {
  const {
    items,
    columns,
    onRenderRow,
    onRenderItemColumn,
    onTableSelectionChanged,
    initialSortFieldName,
    initialSortDescending,
    notScrollable,
    textSortFields,
    textSortMessage,
    resetSelection,
    selectionMode,
    includeSearchFilterValue,
  } = props;

  // this function is created here so that we do not need to use the 'function' syntax to hoist since it used in below constant.
  const setColumnOnClick = React.useCallback(
    (aColumn: ISortableFilterableListColumn) => {
      const column = { ...aColumn };
      const existingColumnClick = columns.find((col) => col.key === column.key)?.onColumnClick;
      column.onColumnClick = function onClick(ev: React.MouseEvent<HTMLElement>, clickedColumn: ISortableFilterableListColumn) {
        onColumnClick.call(ev, clickedColumn);
        existingColumnClick?.call(this, ev, clickedColumn);
      };
      return column;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns],
  );

  const setAllColumnDefaults = (aColumn: ISortableFilterableListColumn): ISortableFilterableListColumn =>
    setColumnOnClick(setColumnDefaults(aColumn));
  const [listColumns, setListColumns] = useState(columns.map(setAllColumnDefaults));
  const [listItems, setListItems] = useState(items);
  const [announcedMessage, setAnnouncedMessage] = useState('');
  const filterableCols = useMemo(() => listColumns.filter((column) => column.isFilterable), [listColumns]);
  const [filters, setFilters] = useState<Map<string, string[]>>(new Map<string, string[]>());
  const [textFilters, setTextFilters] = useState('');
  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        if (onTableSelectionChanged) {
          onTableSelectionChanged(selection.getSelection());
        }
      },
    }),
  );

  const sortColumn = React.useCallback(
    (column: ISortableFilterableListColumn) => {
      if (!listItems || !column.isSortable) {
        return;
      }

      const newColumns: ISortableFilterableListColumn[] = listColumns.slice();
      const currColumn: ISortableFilterableListColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

      newColumns.forEach((newCol: ISortableFilterableListColumn) => {
        /* eslint-disable no-param-reassign */
        if (newCol === currColumn) {
          newCol.isSortedDescending = newCol.isSorted && !newCol.isSortedDescending;
          newCol.isSorted = true;
          setAnnouncedMessage(`${newCol.name} is sorted ${newCol.isSortedDescending ? 'descending' : 'ascending'}`);
        } else {
          newCol.isSorted = false;
          newCol.isSortedDescending = false;
        }
        /* eslint-enable no-param-reassign */
      });

      setListColumns(newColumns);

      const newItems = copyAndSort(listItems, currColumn);
      setListItems(newItems);
    },
    [listColumns, listItems],
  );

  useEffect(() => {
    if (resetSelection) {
      selection.setAllSelected(false);
    }
  }, [resetSelection, selection]);

  useEffect(() => {
    setListColumns(columns.map(setColumnDefaults));
    setListColumns((prevColumns) => prevColumns.map(setColumnOnClick));
  }, [columns, setColumnOnClick]);

  useEffect(() => {
    setListColumns((prevColumns) => prevColumns.map(setColumnOnClick));
  }, [listItems, setColumnOnClick]);

  useEffect(() => {
    setListItems(items);
  }, [items]);

  useEffect(() => {
    if (initialSortFieldName !== undefined) {
      const columnToSort = columns.find((x) => x.fieldName?.toLowerCase() === initialSortFieldName?.toLowerCase());
      if (columnToSort !== undefined) {
        sortColumn(columnToSort);

        if (initialSortDescending) {
          sortColumn(columnToSort);
        }
      }
    }
  }, [columns, initialSortDescending, initialSortFieldName, sortColumn]);

  const onColumnClick = (column: ISortableFilterableListColumn) => {
    sortColumn(column);
  };

  const scrollableWrapper = mergeStyles(
    notScrollable
      ? {}
      : {
          height: 'auto',
          position: 'relative',
          backgroundColor: 'white',
          overflowY: 'auto',
        },
  );

  const setFilterEntry = (columnName: string, event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
    setFilters((filters) => {
      // If filtered option is empty, do nothing
      if (!option?.text) {
        return filters;
      }

      const newFilters = new Map<string, string[]>();
      filters.forEach((value, key) => newFilters.set(key, value));
      const filter = newFilters.get(columnName);

      // If the filter does not exist, add it and return with the new filter
      if (!filter) {
        newFilters.set(columnName, [option.text]);
        return newFilters;
      }

      // Find if the filter already has the filtered option and add or remove as fit
      const filteredItemIndex = filter.findIndex((filteredItem) => filteredItem === option.text);
      if (filteredItemIndex !== -1) {
        filter.splice(filteredItemIndex, 1);
      } else {
        filter.push(option?.text);
      }

      // If the filter is now empty, remove it
      if (filter.length === 0) {
        newFilters.delete(columnName);
        return newFilters;
      }

      // Otherwise add the new filtered options
      newFilters.set(columnName, filter);
      return newFilters;
    });
  };

  const filteredItems = useMemo(
    () =>
      listItems?.filter((item) => {
        let shouldInclude = true;
        filters.forEach((filter, filterColumn: string) => {
          const column = filterableCols.find((x) => x.name === filterColumn);
          if (column === undefined) {
            throw new Error('Column is undefined');
          }
          if (column.getFilterValues) {
            const rawValue = column.getFilterValues ? column.getFilterValues(item) : '';
            const value = column.caseInsensitiveFiltering && rawValue ? rawValue.map((x) => x.toLowerCase()) : rawValue;
            if (filter.filter((test) => value.includes(test)).length === 0) {
              shouldInclude = false;
            }
          } else {
            const rawValue = column.getFilterValue ? column.getFilterValue(item) : '';
            const value: string = column.caseInsensitiveFiltering ? rawValue.toLowerCase() : rawValue;
            if (!filter.includes(value)) {
              shouldInclude = false;
            }
          }
        });
        const searchTextInAvailableValues = textFilters ? textSortFields?.some((x) => includeSearchFilterValue(x, item, textFilters)) : true;
        if (shouldInclude && textFilters.length && !searchTextInAvailableValues) {
          shouldInclude = false;
        }
        return shouldInclude;
      }),
    [listItems, filters, textFilters, filterableCols, textSortFields, includeSearchFilterValue],
  );

  const onTextFilterChange = (ev: React.FormEvent<HTMLInputElement> | undefined, text?: string) => {
    setTextFilters(text?.toLowerCase() ?? '');
  };

  const getOptions = (column: ISortableFilterableListColumn): IDropdownOption[] =>
    createDropdownOption(column.customFilterValues || []) ??
    createDropdownOption(listItems?.map((item) => (column.getFilterValue ? column.getFilterValue(item) : '')) || []);

  return (
    <div className={scrollableWrapper}>
      <div className={filterWrapperStyles}>
        {textSortFields?.length && (
          <SearchBox
            onChange={onTextFilterChange}
            placeholder={textSortMessage ?? 'Filter by ID...'}
            className={searchBoxStyles}
            styles={searchStyles}
          />
        )}
        {filterableCols.map((column) => (
          <Dropdown
            key={column.name}
            id={column.name}
            placeholder="All"
            label={column.name}
            options={getOptions(column)}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) =>
              setFilterEntry(column.name, event, option)
            }
            styles={dropdownStyles}
            className={filterStyles}
            multiSelect
            ariaLabel={column.name}
          />
        ))}
      </div>
      <Announced message={listItems ? `${listItems.length} items are in the list` : 'no items in the list'} />
      {announcedMessage ? <Announced message={announcedMessage} /> : undefined}
      <DetailsList
        className={listStyles}
        items={filteredItems || ([] as ISortableFilterableListRow[])}
        columns={listColumns.filter((x) => !x.isHidden)}
        selectionMode={selectionMode ?? SelectionMode.multiple}
        getKey={getKey}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible
        onRenderRow={onRenderRow}
        onRenderItemColumn={onRenderItemColumn}
        selection={selection}
        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
      />
    </div>
  );
};
