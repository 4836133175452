import { mergeStyles, registerIcons } from '@fluentui/react';
import React from 'react';
import {
  AzureGeneralSearchGridIcon,
  AzureIdentityIdentityGovernanceIcon,
  AzureManagementAndGovernanceActivityLogIcon,
  AzureMenuAdminIcon,
  AzureMenuArcVMwareIcon,
  AzureMenuAppServiceAdvisorIcon,
  AzureMenuAutomationTasksIcon,
  AzureMenuAutonomousSystemIcon,
  AzureMenuMsixPackageIcon,
  AzureMenuPersonalizerConfigIcon,
  AzureMenuPoliciesIcon,
  AzureMenuRegistryIcon,
  AzureMenuS360OverviewIcon,
  AzureCloudGearIcon,
} from './icons';

export * from './icons';

const azureIconStyle = mergeStyles({
  width: '100%',
  height: '100%',
});

export function registerAzureIcons(): void {
  registerIcons({
    icons: {
      AzureGeneralSearchGrid: <AzureGeneralSearchGridIcon className={azureIconStyle} />,
      AzureIdentityIdentityGovernance: <AzureIdentityIdentityGovernanceIcon className={azureIconStyle} />,
      AzureManagementAndGovernanceActivityLog: <AzureManagementAndGovernanceActivityLogIcon className={azureIconStyle} />,
      AzureMenuAdmin: <AzureMenuAdminIcon className={azureIconStyle} />,
      AzureMenuArcVMware: <AzureMenuArcVMwareIcon className={azureIconStyle} />,
      AzureMenuAppServiceAdvisor: <AzureMenuAppServiceAdvisorIcon className={azureIconStyle} />,
      AzureMenuAutomationTasks: <AzureMenuAutomationTasksIcon className={azureIconStyle} />,
      AzureMenuAutonomousSystem: <AzureMenuAutonomousSystemIcon className={azureIconStyle} />,
      AzureMenuMsixPackage: <AzureMenuMsixPackageIcon className={azureIconStyle} />,
      AzureMenuPersonalizerConfig: <AzureMenuPersonalizerConfigIcon className={azureIconStyle} />,
      AzureMenuPolicies: <AzureMenuPoliciesIcon className={azureIconStyle} />,
      AzureMenuRegistry: <AzureMenuRegistryIcon className={azureIconStyle} />,
      AzureMenuS360Overview: <AzureMenuS360OverviewIcon className={azureIconStyle} />,
      AzureCloudGear: <AzureCloudGearIcon className={azureIconStyle} />,
    },
  });
}
