import {
  IColumn,
  IScrollablePaneStyles,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  mergeStyles,
} from '@fluentui/react';
import { CveInfo } from 'generated/clientApi';
import { renderFixedDetailsHeader } from 'pages/conMon/components/tableUtils';
import React, { useMemo } from 'react';
import { calculateCVSSFromVector } from './riskPanel/cvssCalc';

export const CVEsInformationColumnNames = {
  'CVE ID': 'CVE ID',
  Score: 'Score',
  'Proposed Score': 'Proposed Score',
  Version: 'Version',
  Link: 'Link',
} as const;

const customTableStyles = mergeStyles({
  '.ms-Viewport': {
    minWidth: 'inherit',
  },
  height: '300px',
  overflow: 'auto',
  width: '100%',
  position: 'relative',
});

type CVEsInformationTableProps = {
  cves: CveInfo[] | undefined;
};
export const CVEsInformationTable: React.FunctionComponent<CVEsInformationTableProps> = (props) => {
  const { cves } = props;

  const columns = useMemo(
    (): IColumn[] => [
      {
        key: CVEsInformationColumnNames['CVE ID'],
        name: 'CVE ID',
        fieldName: 'displayCveId',
        minWidth: 200,
        maxWidth: 300,
      },
      {
        key: CVEsInformationColumnNames.Score,
        name: 'Score',
        fieldName: 'baseScore',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: CVEsInformationColumnNames['Proposed Score'],
        name: 'Proposed Score',
        fieldName: 'proposedVectorString',
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: CveInfo) => (calculateCVSSFromVector(item.proposedVectorString) as any).environmentalMetricScore,
      },
      {
        key: CVEsInformationColumnNames.Version,
        name: 'Version',
        fieldName: 'version',
        minWidth: 100,
        maxWidth: 100,
      },
      {
        key: CVEsInformationColumnNames.Link,
        name: 'Link',
        fieldName: 'link',
        minWidth: 100,
        maxWidth: 300,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [CVEsInformationColumnNames],
  );

  const scrollPaneStyles = {
    root: {
      '.ms-DetailsList': {
        overflow: 'visible',
      },
    },
  };

  const renderLink = (item: CveInfo): JSX.Element => (
    <Link target="_blank" href={item.link}>
      {item.link}
    </Link>
  );

  return (
    <div className={customTableStyles}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={scrollPaneStyles as unknown as IScrollablePaneStyles}>
        <ShimmeredDetailsList
          items={cves as CveInfo[]}
          columns={columns}
          shimmerLines={20}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={renderFixedDetailsHeader}
          onRenderItemColumn={(item, index, column) => {
            if (column?.name === 'Link') {
              return renderLink(item);
            }
            // Render other columns
            return item[column!.fieldName ?? ''];
          }}
        />
      </ScrollablePane>
    </div>
  );
};
