import { mergeStyles } from '@fluentui/react';

export const sideModalPoamStyles = mergeStyles({
  selectors: {
    '& .ms-Panel-content': {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0,
    },
    '& .ms-Panel-footerInner': {
      paddingBottom: 0,
    },
  },
});

export const sideModalReportStyles = mergeStyles({
  selectors: {
    '& .ms-Panel-content': {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem',
      paddingBottom: 0,
      height: '100%',
    },
  },
});
