import {
  ScrollablePane,
  ScrollbarVisibility,
  IScrollablePaneStyles,
  DetailsRow,
  IColumn,
  IDetailsRowProps,
  mergeStyles,
  Selection,
  IObjectWithKey,
  DetailsListLayoutMode,
  ITooltipProps,
  TooltipHost,
  TooltipDelay,
  Spinner,
  SpinnerSize,
  ShimmeredDetailsList,
} from '@fluentui/react';
import { Coverage, DayCoverage, ServiceTreeAssetCloud, Selection as CoverageSelection, DayTotal, SortDirection } from 'generated/clientApi';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { baseSortableColumn, handleServerSideTableSort, renderFixedDetailsHeader } from '../components/tableUtils';
import DayCoverageCell from './dayCoverageCell';

export const belowCoverageBgColor = '#FDE5DE';

let timeoutId: NodeJS.Timeout | undefined;
const debounce = (func: (...args: any[]) => void, delay: number) => {
  const debouncedFunction = (...args: any[]) => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
  return debouncedFunction;
};

const customTableStyles = {
  root: {
    '.ms-DetailsList': {
      overflow: 'visible',
    },
    '.ms-DetailsRow': {
      height: '68px',
    },
    '.ms-Check': {
      cursor: 'pointer',
    },
    '.ms-DetailsRow-cellCheck': {
      position: 'sticky',
      left: '0',
      zIndex: '3',
      backgroundColor: 'white',
      opacity: '1',
    },
    '.ms-DetailsRow-fields > div:nth-child(-n+3)': {
      zIndex: '3',
    },
    '.ms-DetailsRow-fields > div:nth-child(-n+4)': {
      position: 'sticky',
      left: '48px',
      zIndex: '1',
      backgroundColor: 'white',
      opacity: '1',
      paddingTop: '15px',
    },
    '.ms-DetailsHeader > div:nth-child(-n+5)': {
      position: 'sticky',
      left: '0',
      zIndex: '3',
      backgroundColor: 'white',
      opacity: '1',
    },
    '.ms-DetailsRow-fields > div:nth-child(2)': {
      left: '268px',
    },
    '.ms-DetailsHeader > div:nth-child(2)': {
      left: '48px',
    },
    '.ms-DetailsRow-fields > div:nth-child(3)': {
      left: '388px',
    },
    '.ms-DetailsHeader > div:nth-child(3)': {
      left: '268px',
    },
    // allow 'max' column to be scrolled over
    '.ms-DetailsRow-fields > div:nth-child(4)': {
      left: '558px',
      zIndex: '0',
    },
    '.ms-DetailsHeader > div:nth-child(4)': {
      left: '388px',
    },
    '.ms-DetailsHeader > div:nth-child(5)': {
      left: '558px',
      zIndex: '0',
    },
    '.ms-DetailsRow-fields > div:nth-child(n+5)': {
      background: 'white',
    },
    // Day coverage cells can be scrolled into view over 'max' column
    '.ms-DetailsHeader > div:nth-child(n+6) span:last-child': {
      zIndex: '2',
      background: 'white',
      margin: 'auto',
    },
    '.ms-DetailsRow-cell': {
      overflow: 'visible',
    },
    '.ms-DetailsRow-fields': {
      userSelect: 'text !important',
      ':hover': {
        filter: 'brightness(95%)',
      },
    },
  },
};

const scrollClass = mergeStyles({
  height: '100%',
  width: '100%',
  position: 'relative',
});

const tableSeparatorClass = mergeStyles({
  width: '.5px',
  height: '155%',
  marginTop: '-30px',
  marginLeft: 'auto',
  background: '#EDEBE9',
  boxShadow: '1px 0px 2px 0px rgba(0, 0, 0, 0.10)',
});

type CoverageTableProps = {
  coverage: Coverage[];
  loading: boolean;
  totals: DayTotal[];
  loadingInitial: boolean;
  isCurrentPeriodLocked: boolean;
  newSelectedDays: Map<string, CoverageSelection>;
  userSelectedDays: Map<string, number>;
  systemSelectedDays: Map<string, number>;

  onScrollTable: (event: React.UIEvent<HTMLElement>) => void;
  setScrollLeft: React.Dispatch<React.SetStateAction<number>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<ServiceTreeAssetCloud[]>>;
  setNewSelectedDays: React.Dispatch<React.SetStateAction<Map<string, CoverageSelection>>>;
  setSort: React.Dispatch<
    React.SetStateAction<
      | {
          column: keyof Coverage;
          direction: SortDirection;
        }
      | undefined
    >
  >;
};
const CoverageTable: React.FunctionComponent<CoverageTableProps> = (props) => {
  const {
    coverage,
    loading,
    loadingInitial,
    setSelectedItems,
    userSelectedDays,
    systemSelectedDays,
    newSelectedDays,
    setNewSelectedDays,
    isCurrentPeriodLocked,
    setScrollLeft,
    onScrollTable,
    setSort,
  } = props;

  const onDaySelected = useCallback(
    (item: Coverage, dayId: string) => {
      const { id } = item;
      const newMap = new Map(newSelectedDays);
      const covSelection = {
        serviceTreeName: item.serviceTreeName,
        assetType: item.assetType,
        cloudType: item.cloudType,
        newSelectedId: dayId,
      };
      newMap.set(id, covSelection as CoverageSelection);
      setNewSelectedDays(newMap);
    },
    [newSelectedDays, setNewSelectedDays],
  );

  const isDaySelected = useCallback(
    (item: Coverage, day: number, dayId: string) => {
      if (newSelectedDays.get(item.id)?.newSelectedId === dayId) {
        return true;
      }
      if (userSelectedDays.get(item.id) === day && !newSelectedDays.has(item.id)) {
        return true;
      }
      if (systemSelectedDays.get(item.id) === day && !newSelectedDays.has(item.id) && userSelectedDays.get(item.id) === 0) {
        return true;
      }
      return false;
    },
    [newSelectedDays, systemSelectedDays, userSelectedDays],
  );

  const tooltipProps = useCallback(
    (vulnCount: number) => ({
      onRenderContent: () => (
        <div style={{ width: '150px' }}>
          <p>{`Vulnerabilities (${vulnCount})`}</p>
        </div>
      ),
    }),
    [],
  );

  const sortTable = useCallback(
    (sortColumn: IColumn | null, sortDirection: 'asc' | 'desc'): void => {
      if (sortColumn && sortDirection) {
        setSort({
          column: sortColumn.fieldName as keyof Coverage,
          direction: sortDirection === 'asc' ? SortDirection.Asc : SortDirection.Desc,
        });
      }
    },
    [setSort],
  );

  const defaultCols = useMemo((): IColumn[] => {
    const cols: IColumn[] = [
      {
        key: 'serviceTreeName',
        name: 'Service Tree name',
        fieldName: 'serviceTreeName',
        minWidth: 200,
        maxWidth: 200,
        isMultiline: true,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
      },
      {
        key: 'cloudType',
        name: 'Cloud type',
        fieldName: 'cloudType',
        minWidth: 100,
        maxWidth: 100,
        isMultiline: true,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
      },
      {
        key: 'assetType',
        name: 'Asset type',
        fieldName: 'assetType',
        minWidth: 150,
        maxWidth: 150,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
      },
      {
        key: 'maxPercentage',
        name: 'Max %',
        fieldName: 'maxPercentage',
        minWidth: 100,
        maxWidth: 100,
        ...baseSortableColumn,
        onColumnClick: (ev, column) => handleServerSideTableSort(column, sortTable, setColumns),
        onRender: (item: Coverage) => (
          <>
            <span>{`${item.max}%`}</span>
            <div className={tableSeparatorClass} />
          </>
        ),
      },
    ];

    return cols;
  }, [sortTable]);

  useEffect(() => {
    const cols: IColumn[] = [];
    const month = coverage.at(0)?.period?.split('-')[1] ?? '';
    const monthStripped = month.startsWith('0') ? month.substring(1) : month;
    const maximumDays = coverage.reduce((max, cov) => {
      const currentLength = cov.days?.at(-1)?.day ?? 0;
      return Math.max(max, currentLength);
    }, 0);

    const emptyDay = {
      id: '-1',
      day: -1,
      scanAssetCount: 0,
      inventoryAssetCount: 0,
      percentage: 0,
      authenticatedPercentage: 0,
      vulnerabilityCount: 0,
    };

    for (let i = 1; i <= maximumDays; i++) {
      const column: IColumn = {
        key: `${i}`,
        name: `${monthStripped}/${i}`,
        minWidth: 85,
        maxWidth: 85,
        isResizable: false,
        onRender: (item: Coverage) => {
          const index = i - 1;
          const dayCoverage = item.days?.at(index);
          if (!dayCoverage || dayCoverage.day !== i) {
            if (i !== maximumDays && (dayCoverage?.day ?? 0) > 0) {
              item.days?.splice(index, 0, { ...emptyDay } as DayCoverage);
            }
            return <div style={{ width: '90px', height: '45px', textAlign: 'center', paddingTop: '0.5rem', opacity: '.7' }}>no data</div>;
          }

          return (
            <TooltipHost
              tooltipProps={tooltipProps(dayCoverage.vulnerabilityCount!) as ITooltipProps}
              id="dayCoverageTooltipId"
              delay={TooltipDelay.medium}
            >
              <button
                type="button"
                aria-label={`Day coverage: Scan asset count of ${dayCoverage.scanAssetCount} over Inventory asset count of ${dayCoverage.inventoryAssetCount}.
                Vulnerability count of ${dayCoverage.vulnerabilityCount} vulnerabilities.`}
                style={{
                  border: 'none',
                  background: 'inherit',
                  padding: '0',
                  marginTop: '-5px',
                  display: 'table-cell',
                  fontSize: '13px',
                  fontFamily:
                    '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                  cursor: isCurrentPeriodLocked ? 'not-allowed' : 'pointer',
                  overflow: 'hidden',
                }}
                onClick={() => !isCurrentPeriodLocked && onDaySelected(item, dayCoverage.id)}
              >
                <DayCoverageCell
                  isCurrentSelection={isDaySelected(item, i, dayCoverage.id)}
                  isOldUserSelection={userSelectedDays.get(item.id) === i}
                  isDefaultSystemSelection={systemSelectedDays.get(item.id) === i}
                  dayCoverage={dayCoverage}
                  showRawNumberAsTooltip={false}
                />
              </button>
            </TooltipHost>
          );
        },
      };
      cols.push(column);
    }
    setColumns((prev) => prev.slice(0, 4).concat(cols));
  }, [coverage, isCurrentPeriodLocked, isDaySelected, onDaySelected, systemSelectedDays, tooltipProps, userSelectedDays]);

  const [columns, setColumns] = useState(defaultCols);

  const handleSelectionChanged = useCallback(() => {
    const selectedItems = selection.getSelection().map((item) => ({
      serviceTreeName: item.serviceTreeName,
      assetType: item.assetType,
      cloudType: item.cloudType,
    }));

    setSelectedItems(selectedItems as ServiceTreeAssetCloud[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedItems]);

  const selection = useMemo(() => {
    const selection = new Selection<Coverage>({
      getKey: (item: any) => item.id,
      onSelectionChanged: (): void => handleSelectionChanged(),
    });
    return selection;
  }, [handleSelectionChanged]);

  const onRenderRow = useCallback(
    (props?: IDetailsRowProps) => {
      if (!props) {
        return <></>;
      }
      const { max } = props.item as Coverage;
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={(ev?: any) => {
            if (ev.target.classList.contains('ms-Check-check')) {
              const { id } = props.item ?? '';
              selection.setKeySelected(id, !selection.isKeySelected(id), true);
            }
          }}
        >
          <DetailsRow
            {...props}
            data-selection-disabled
            styles={{
              fields: {
                backgroundColor: max! < 90 ? belowCoverageBgColor : 'white',
              },
            }}
          />
        </div>
      );
    },
    [selection],
  );

  const isHandlingScroll = useRef(false);

  const handleScroll = useCallback((event: any) => {
    if (isHandlingScroll.current) {
      return;
    }
    const { target, currentTarget } = event;
    if (target === currentTarget) {
      isHandlingScroll.current = true;
      const debouncedSetScroll = debounce(() => setScrollLeft(target.scrollLeft), 300);
      debouncedSetScroll();
      isHandlingScroll.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const memoizedContent = useMemo(
    () => (
      <div style={{ height: 'calc(100vh - (495px))', minHeight: '150px', overflow: 'auto' }}>
        <div style={{ display: 'flex' }} className={scrollClass} onScroll={onScrollTable}>
          <ScrollablePane
            onScroll={handleScroll}
            scrollbarVisibility={ScrollbarVisibility.auto}
            styles={customTableStyles as unknown as IScrollablePaneStyles}
          >
            <div>
              <ShimmeredDetailsList
                items={coverage}
                columns={columns}
                enableShimmer={loadingInitial}
                shimmerLines={20}
                selection={selection as Selection<IObjectWithKey>}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                onRenderDetailsHeader={renderFixedDetailsHeader}
                onRenderRow={onRenderRow}
              />
            </div>
            {loading && <Spinner label="Loading Coverage Data..." size={SpinnerSize.large} />}
            {coverage.length === 0 && loading === false && (
              <em style={{ display: 'block', marginTop: '2rem', marginLeft: '2rem' }}>
                No coverage items match the given filter criteria. Try clearing or modifying the filter values.
              </em>
            )}
          </ScrollablePane>
        </div>
      </div>
    ),
    [columns, coverage, handleScroll, loading, loadingInitial, onRenderRow, onScrollTable, selection],
  );
  return <>{memoizedContent}</>;
};

// eslint-disable-next-line import/no-default-export
export default React.memo(CoverageTable);
