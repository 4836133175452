import { ICuratedTenantMonitoringDataGetResponse } from 'generated/clientApi';
import { MetadataDataPoint } from '../tenantGovernanceComplianceOverview';
import { getMetadataDataPoint } from './tenantGovernanceComplianceOverview.functions';

// Compliant Customers = Total compliance evaluations where IsCompliant is true
// Non-Compliant Customers = Total compliance evaluations where IsCompliant is false
export const getCompliantDetailCount = (curatedTenant: ICuratedTenantMonitoringDataGetResponse): MetadataDataPoint => {
  const compliantCount = curatedTenant.compliances?.filter((compliance) => compliance.complianceEvaluations?.filter((p) => p.isCompliant)).length || 0;
  const nonCompliantCount = curatedTenant.compliances?.filter((compliance) => compliance.complianceEvaluations?.filter((p) => p.isCompliant === false)).length || 0;
  return getMetadataDataPoint(compliantCount, nonCompliantCount);
};

// Compliant Customers = Total policies violation where PoliciesViolatedCount is 0
// Non-Compliant Customers = Total policies violation where PoliciesViolatedCount > 0
export const getPolicyViolationDetailCount = (curatedTenant: ICuratedTenantMonitoringDataGetResponse): MetadataDataPoint => {
  const compliantCustomers = (curatedTenant.policiesEvaluatedCount || 0) - (curatedTenant.policiesViolatedCount || 0);
  const nonCompliantCustomers = curatedTenant.policiesViolatedCount || 0;
  return getMetadataDataPoint(compliantCustomers, nonCompliantCustomers);
};

// Compliant Customers = Total drifts where ResourcesDriftCount is 0
// Non-Compliant Customers = Total drifts where ResourcesDriftCount > 0
export const getPolicyDriftDetailCount = (curatedTenant: ICuratedTenantMonitoringDataGetResponse): MetadataDataPoint => {
  const compliantCustomers = (curatedTenant.resourcesEvaluatedCount || 0) - (curatedTenant.resourcesDriftCount || 0);
  const nonComplianceCustomers = curatedTenant.resourcesDriftCount || 0;
  return getMetadataDataPoint(compliantCustomers, nonComplianceCustomers);
};
