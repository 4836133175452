import React from 'react';
import { CuratedTenantMonitoringDataListResponse } from 'generated/clientApi';
import { Link as RouterLink } from 'react-router-dom';
import { Icon, Link, mergeStyles } from '@fluentui/react';
import { ISortableFilterableListColumn } from 'components/searchableFilterableList/searchableFilterableList';
import { tenantGovernanceDetailRoute } from 'modules/routes/routes';
import { getViolationTypes, isCuratedTenantCompliant, showCuratedTenantCompliantIcon, showCuratedTenantCompliantStyle } from './functions/tenantGovernance.functions';

const linkStyle = {
  textDecoration: 'underline',
  color: 'black',
};

const compliantStyles = mergeStyles({
  paddingRight: '5px',
});

export const getTenantGovernanceColumns = (
  customerNames: string[],
  tenantNames: string[],
  compliantStates: string[],
  typeOfViolations: string[],
): ISortableFilterableListColumn[] => [
    {
      key: 'customerName',
      name: 'Customer name',
      isFilterable: true,
      customFilterValues: customerNames,
      getFieldValue: (item: CuratedTenantMonitoringDataListResponse) => item.customerName || '',
      onRender: (item: CuratedTenantMonitoringDataListResponse) => {
        if (item.scanOrchestrationId) {
          return (
            <Link
              as={RouterLink}
              to={tenantGovernanceDetailRoute({
                tenantId: item.tenantId ?? '',
                monitoringScanId: item.scanOrchestrationId ?? '',
              })}
              style={linkStyle}
            >
              {item.customerName}
            </Link>
          )
        }
        return (
          // Return item.name as plain text if id or scanId is null or empty
          <>{item.customerName}</>
        )

      },
      getFilterValue: (item: any) => item.customerName,
      minWidth: 50,
      isRowHeader: true,
    },
    {
      key: 'tenantName',
      name: 'Tenant name',
      isFilterable: true,
      customFilterValues: tenantNames,
      getFieldValue: (item: CuratedTenantMonitoringDataListResponse) => item.tenantName || '',
      onRender: (item: CuratedTenantMonitoringDataListResponse) => <>{item.tenantName}</>,
      getFilterValue: (item: any) => item.tenantName,
      minWidth: 100,
    },
    {
      key: 'complianceState',
      name: 'Compliance state',
      isFilterable: true,
      customFilterValues: compliantStates,
      getFieldValue: (item: CuratedTenantMonitoringDataListResponse) => isCuratedTenantCompliant(item) || '',
      onRender: (item: CuratedTenantMonitoringDataListResponse) => (
        <>
          <Icon
            role="img"
            aria-label="Valid Compliant"
            title="Valid Compliant"
            className={mergeStyles(compliantStyles, { color: showCuratedTenantCompliantStyle(item) })}
            iconName={showCuratedTenantCompliantIcon(item)}
          />
          {isCuratedTenantCompliant(item)}
        </>
      ),
      getFilterValue: (item: any) => isCuratedTenantCompliant(item),
      minWidth: 100,
    },
    {
      key: 'typeOfViolation',
      name: 'Type of violation',
      isFilterable: true,
      customFilterValues: typeOfViolations,
      getFieldValue: (item: CuratedTenantMonitoringDataListResponse) => getViolationTypes([item]).join(', ') || '',
      onRender: (item: CuratedTenantMonitoringDataListResponse) => <>{getViolationTypes([item]).join(', ')}</>,
      getFilterValues: (item: any) => getViolationTypes([item]),
      minWidth: 100,
    },
  ];
