import { IEvidencePackage } from 'generated/clientApi';
import { EvidencePackageStatus } from 'models/evidencePackageStatus';

export const isEvidencePackageResumbit = (evidencePackage: IEvidencePackage | undefined, evidenceStatus: EvidencePackageStatus): boolean => {
  if (evidencePackage?.statusHistories && evidencePackage?.statusHistories.length > 0) {
    evidencePackage.statusHistories.sort((history1, history2) => {
      if (history1.date && history2.date) {
        return history1.date.getTime() - history2.date.getTime();
      }
      return 0;
    });
    if (evidencePackage.statusHistories[evidencePackage.statusHistories.length - 1].newStatus === evidenceStatus.toString()) {
      return true;
    }
  }

  return false;
};
