import { createContext } from 'react';
import { INotificationSettings } from 'generated/clientApi';
import { LoadingState } from '../../models/loadingState';

export interface NotificationSettingContextProps {
  notificationSettings: INotificationSettings | undefined;
  notificationSettingsLoadingState: LoadingState;
  requestNotificationSettings: () => void;
  updateNotificationSettings: (notificationSettings: INotificationSettings) => void;
}

export const NotificationSettingContext = createContext<NotificationSettingContextProps>({
  notificationSettings: undefined,
  notificationSettingsLoadingState: LoadingState.NotLoaded,
  requestNotificationSettings: (): void => undefined,
  updateNotificationSettings: (): void => undefined,
});
