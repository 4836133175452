import { Link, mergeStyles } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { ServiceContext } from 'components/serviceProvider/serviceContext';
import { EvidencePackage } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { searchForEvidencePackage } from '../../modules/evidencePackage/evidencePackage';
import { logError } from '../../modules/logging/logging';
import { serviceDetailRoute } from '../../modules/routes/routes';
import { CenteredProgressDots } from '../progressDots/progressDots';
import { ServiceAuthorizationTable } from '../serviceAuthorizationTable/serviceAuthorizationTable';

const widgetStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '1em',
  margin: '0.5em',
  display: 'flex',
  flexDirection: 'column',
});

const headerStyle = mergeStyles({
  display: 'flex',
});

export interface ServiceWidgetProps {
  cloud?: string;
  certification?: string;
  offering?: string;
  showNotFoundMessage?: boolean;
  noneDisplay?: string;
}

export const ServiceWidget: React.FunctionComponent<ServiceWidgetProps> = (props) => {
  const { cloud, certification, offering, showNotFoundMessage, noneDisplay } = props;
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.Loaded);
  const [evidencePackage, setEvidencePackage] = useState<EvidencePackage>();
  const noneComparator = noneDisplay || 'None';
  const servicesContext = useContext(ServiceContext);

  useEffect(() => {
    const getData = async () => {
      try {
        if (servicesContext.selectedService === undefined || cloud === undefined || certification === undefined) {
          return;
        }

        setIsLoading(LoadingState.NotLoaded);
        const searchForEvidencePackageResponse = await searchForEvidencePackage(servicesContext.selectedService.id, cloud, certification);
        const { evidencePackage } = searchForEvidencePackageResponse;
        if (evidencePackage) {
          setEvidencePackage(evidencePackage);
        }
        setIsLoading(LoadingState.Loaded);
      } catch (error) {
        setIsLoading(LoadingState.Error);
        logError('There was an issue loading the current evidence package', error);
      }
    };

    getData();
  }, [servicesContext.selectedService, cloud, certification]);

  const widgetInstructions = showNotFoundMessage ? (
    <>No service matches the filter criteria provided above. Please refine your search.</>
  ) : (
    <>Choose from the filters above to select an authorization for a service.</>
  );

  const displayService =
    offering === undefined ||
    (servicesContext.selectedService?.offerings === undefined || servicesContext.selectedService?.offerings?.length === 0
      ? offering === noneComparator
      : servicesContext.selectedService?.offerings?.find((o) => o.name === offering) !== undefined);

  const updateEvidencePackageStatus = (evidencePackage: EvidencePackage): void => {
    setEvidencePackage(evidencePackage);
  };

  return (
    <div className={widgetStyle}>
      {isLoading !== LoadingState.Loaded ? (
        <CenteredProgressDots />
      ) : (
        <>
          {servicesContext.selectedService !== undefined && cloud !== undefined && certification !== undefined && displayService ? (
            <>
              <div className={headerStyle}>
                Service authorization status -&nbsp;
                <span>
                  <Link href={serviceDetailRoute({ serviceOid: servicesContext.selectedService.serviceOid })}>
                    {servicesContext.selectedService.name}
                  </Link>
                </span>
              </div>
              <ServiceAuthorizationTable
                authorizationInformation={[
                  {
                    id: '',
                    environmentAuthorization: {
                      id: '',
                      cloud,
                      certification,
                      owningAgencies: [],
                      isActive: false,
                    },
                    evidencePackage,
                  },
                ]}
                updateEvidencePackageStatus={updateEvidencePackageStatus}
              />
            </>
          ) : (
            <div className={headerStyle}>{widgetInstructions}</div>
          )}
        </>
      )}
    </div>
  );
};
