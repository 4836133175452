import { IPivotStyles, SharedColors, mergeStyles } from '@fluentui/react';

export const pivotStyles: Partial<IPivotStyles> = {
  root: {
    marginTop: '24px',
  },
  linkIsSelected: {
    selectors: {
      '::before': {
        backgroundColor: SharedColors.cyan20,
      },
    },
  },
};

export const pivotClass = mergeStyles({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  selectors: {
    '& > div:nth-of-type(2)': {
      flex: 1,
    },
    '& > div:nth-of-type(2) > div': {
      height: '100%',
    },
    '& > div:nth-of-type(2) > div > div': {
      height: '100%',
    },
    '& .poam-tab-Vulnerability > div:first-of-type': {
      height: 'auto',
    },
    '& .poam-tab-Vulnerability > div:nth-of-type(2)': {
      height: '300px',
    },
    '.ms-Pivot': {
      position: 'sticky',
      zIndex: 1,
      top: '50px',
      background: 'white',
      paddingBottom: '0.5rem',
    },
  },
});
