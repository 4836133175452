import { mergeStyles } from '@fluentui/react';
import React from 'react';

export interface LabelAndTextProps {
  label: string;
  text: string;
}

const labelAndTextStyles = mergeStyles({
  display: 'flex',
  alignItems: 'center',
});

const labelStyles = mergeStyles({
  marginRight: '.5em',
});

export const LabelAndText: React.FunctionComponent<LabelAndTextProps> = (props) => (
  <div className={labelAndTextStyles}>
    <span className={labelStyles}>{props.label}</span>
    <strong>{props.text}</strong>
  </div>
);
