import { createContext } from 'react';
import { IOffering } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';

export interface OfferingContextProps {
  offerings: IOffering[] | undefined;
  offeringsLoadingState: LoadingState;
  requestOfferings: () => void;
}

export const OfferingContext = createContext<OfferingContextProps>({
  offerings: undefined,
  offeringsLoadingState: LoadingState.NotLoaded,
  requestOfferings: (): void => {
    // do nothing for the base case
  },
});

export const OfferingConsumer = OfferingContext.Consumer;
