import { DetailsList, IColumn, IDetailsListStyles, Link, SelectionMode } from '@fluentui/react';
import React from 'react';
import { ControlMetadataListResponse } from '../../generated/clientApi';

const internalTeamsTableStyles: Partial<IDetailsListStyles> = {
  root: {
    overflow: 'hidden',
  },
  contentWrapper: {
    maxHeight: '30vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
};

const complianceTableStyles: Partial<IDetailsListStyles> = {
  root: {
    overflow: 'hidden',
  },
  contentWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
};

interface ControlMetadataDetailsParams {
  controlMetadata: ControlMetadataListResponse | undefined;
}

const internalServiceTeamsColumns: IColumn[] = [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 100,
    maxWidth: 200,
  },
];

const complianceSelfTestColumns: IColumn[] = [
  {
    key: 'title',
    name: 'Self Test Links for Control',
    onRender: (item: any) => <Link href={item.link}>{item.title}</Link>,
    minWidth: 100,
    maxWidth: 200,
  },
];

export const ControlMetadataDetails: React.FunctionComponent<ControlMetadataDetailsParams> = (params) => {
  const { controlMetadata } = params;

  return (
    <>
      <h3>Associated Internal service teams ({controlMetadata?.internalServiceTeamNames?.length || 0})</h3>
      {controlMetadata?.internalServiceTeamNames?.length ? (
        <DetailsList
          items={controlMetadata?.internalServiceTeamNames?.map((name) => ({ name })) || []}
          columns={internalServiceTeamsColumns}
          selectionMode={SelectionMode.none}
          styles={internalTeamsTableStyles}
          onShouldVirtualize={() => false}
        />
      ) : (
        <div>This Control has no associated Internal Service Teams.</div>
      )}
      <h3>Compliance self test</h3>
      <div>
        Below are compliance self tests that are associated with this control. Self tests are useful during audits to understand what procedures to
        perform for evidence gathering.
      </div>
      {controlMetadata?.complianceSelfTestDocuments ? (
        <DetailsList
          items={controlMetadata?.complianceSelfTestDocuments || []}
          columns={complianceSelfTestColumns}
          selectionMode={SelectionMode.none}
          styles={complianceTableStyles}
          onShouldVirtualize={() => false}
        />
      ) : (
        <>
          <br />
          <div>This Control has no associated Compliance self tests.</div>
        </>
      )}
    </>
  );
};
