import { Icon, IDetailsColumnStyles, IDialogContentProps, Link, mergeStyles, NeutralColors, TextField } from '@fluentui/react';
import React, { FormEvent, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { updateEvidencePackageAuthorizationAchieved } from 'modules/evidencePackage/evidencePackage';
import { iconWrapperStyle, iconGreenStyle, iconGreyStyle } from 'components/statusIcon/statusIcon';
import { getFormattedDateTime } from 'modules/datetime/datetime';
import { createEvidencePackageRoute, evidenceDetailRoute } from 'modules/routes/routes';
import { ConfigContext } from 'components/configProvider/configContext';
import { isUSNat } from 'components/util/cloudUtil';
import { ServiceContext } from 'components/serviceProvider/serviceContext';
import { EvidencePackage, IAuthorizations, IEvidencePackage } from '../../generated/clientApi';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { BasicList, ListColumn } from '../basicList/basicList';
import { SaveButton } from '../saving/saveButton';
import { SERVICE_WRITE, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { AuthContext } from '../authProvider/authContext';
import { updateServiceAuthorizationsActive } from '../../modules/service/service';
import { logError } from '../../modules/logging/logging';
import { showDialogModal } from '../../modules/dialog/dialog';
import { getSymbol } from './serviceAuthorizationTable.function';

const linkStyle = {
  textDecoration: 'none',
};

const buttonStyle = mergeStyles({
  margin: 'auto',
});

const authorizationAchievedIconStyle = mergeStyles({
  margin: 'auto',
  display: 'center',
});

const headerStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
};

const modalBodyStyle = mergeStyles({
  marginBottom: '20px',
});

const authorBody = mergeStyles({
  textAlign: 'end',
});

const textFieldStyle = mergeStyles({
  maxWidth: '100%',
  borderColor: NeutralColors.black,
  justifyContent: 'center',
  paddingBottom: '20px',
  root: [
    {
      fontWeight: 'bold',
    },
  ],
});

export const iconNoteStyle = mergeStyles({
  margin: 'inherit',
  color: NeutralColors.black,
  paddingLeft: '3px',
  cursor: 'pointer',
});

export interface AzureAuthorizationInformation {
  id: string;
  environmentAuthorization: IAuthorizations;
  evidencePackage: IEvidencePackage | undefined;
}

export interface ServiceAuthorizationTableProps {
  authorizationInformation: AzureAuthorizationInformation[];
  updateEvidencePackageStatus(evidencePackage: EvidencePackage, note: string): void;
}

export const ServiceAuthorizationTable: FunctionComponent<ServiceAuthorizationTableProps> = (props) => {
  const { authorizationInformation, updateEvidencePackageStatus } = props;
  const [isSaving, setIsSaving] = useState<string | undefined>(undefined);
  const [filteredColumns, setFilteredColumns] = useState<ListColumn[]>([]);
  const authContext = useContext(AuthContext);
  const configContext = useContext(ConfigContext);
  const servicesContext = useContext(ServiceContext);

  const dialogInput = useCallback(
    async (environmentAuthorization: IAuthorizations) => {
      try {
        if (!servicesContext.selectedService) {
          return;
        }

        setIsSaving(environmentAuthorization.id);
        const updatedService = await updateServiceAuthorizationsActive({
          serviceOid: servicesContext.selectedService.id,
          cloud: environmentAuthorization.cloud,
          certification: environmentAuthorization.certification,
        });
        servicesContext.updateSelectedService(updatedService);
        setIsSaving(undefined);
        // redirect user to evidence tool
      } catch (error) {
        setIsSaving(undefined);
        logError(`There was an issue updating the authorization to enabled for service with oid: ${servicesContext.selectedService?.id}`, error);
      }
    },
    [servicesContext],
  );

  const dialogAuthorizationAchievedInput = useCallback(
    async (evidencePackage: IEvidencePackage, note: string) => {
      try {
        setIsSaving(evidencePackage.id);
        const evidencePackageCurrent = await updateEvidencePackageAuthorizationAchieved({
          id: evidencePackage.id,
          serviceOid: evidencePackage.serviceOid,
          authorizationAchievedNote: note,
        });
        updateEvidencePackageStatus(evidencePackageCurrent as EvidencePackage, note);
      } catch (error) {
        setIsSaving(undefined);
        logError(`There was an issue updating the authorization to enabled for service with oid: ${servicesContext.selectedService?.id}`, error);
      }
    },
    [servicesContext.selectedService?.id, updateEvidencePackageStatus],
  );

  const saveButtonClicked = useCallback(
    async (environmentAuthorization: IAuthorizations) => {
      const dialogContents: IDialogContentProps = {
        title: `Are you sure you want to begin gathering evidence for ${environmentAuthorization.certification}?`,
      };

      const bodyText = `You are about to begin gathering evidence for ${servicesContext.selectedService?.name} - ${environmentAuthorization.certification} in ${environmentAuthorization.cloud}.
    This will progress the service from Onboarding Approved to Evidence Gathering in the ${environmentAuthorization.cloud} ${environmentAuthorization.certification} for ${servicesContext.selectedService?.name}.`;

      const modalBody = <div className={modalBodyStyle}>{bodyText}</div>;
      showDialogModal(dialogContents, () => dialogInput(environmentAuthorization), modalBody, undefined, 'Begin', '600px');
    },
    [servicesContext.selectedService?.name, dialogInput],
  );

  const confirmButtonClicked = useCallback(
    async (evidencePackage: IEvidencePackage) => {
      const dialogContents: IDialogContentProps = {
        title: `Are you sure you want to mark this service as achieved for ${evidencePackage.certification} in ${evidencePackage.cloud}?`,
      };
      const bodyText = `By clicking the confirm button, you are confirming the final checks have been done for this service and can officially be marked as achieved for this certification in the ${evidencePackage.cloud} environment. Please input
    any comments in the provided space below you would like to surface to.....`;
      const textFieldText = 'Notes here from the Compliance PM. Service has been verified and can be input the authorization achieved state';
      const createdBy = evidencePackage.authorizationAchievedNoteCreatedBy;
      const createdDate = evidencePackage.authorizationAchievedNoteCreatedDate;
      let note = evidencePackage.authorizationAchievedNote || '';
      const modalBody = (
        <div className={modalBodyStyle}>
          {bodyText}
          <TextField
            aria-label="Confirmation Text Field"
            defaultValue={evidencePackage.authorizationAchievedNote}
            multiline
            rows={10}
            placeholder={textFieldText}
            onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, updatedNote?: string) => {
              note = updatedNote?.trim() || '';
            }}
            className={mergeStyles(textFieldStyle, { fontWeight: '700', textAlign: 'center', paddingTop: '5px' })}
          />
          <div className={authorBody}>{createdBy === null ? '' : `Created by: ${createdBy} at ${getFormattedDateTime(createdDate)}`}</div>
        </div>
      );
      showDialogModal(dialogContents, () => dialogAuthorizationAchievedInput(evidencePackage, note), modalBody, undefined, 'Confirm', '600px');
    },
    [dialogAuthorizationAchievedInput],
  );

  const renderStatusSymbol = (authorizationInformation: AzureAuthorizationInformation, columnStatus: EvidencePackageStatus) => (
    <div className={iconWrapperStyle}>{getSymbol(authorizationInformation, columnStatus)}</div>
  );

  const stageColumn = (key: string, name: string, status: EvidencePackageStatus): ListColumn => ({
    key,
    name,
    styles: headerStyle,
    onRender: (authorizationInfo: AzureAuthorizationInformation) => (
      <>
        {status === EvidencePackageStatus.Approved ? (
          <div className={iconWrapperStyle}>
            {authorizationInfo.evidencePackage?.status === EvidencePackageStatus.Approved ? (
              <Icon iconName="SkypeCircleCheck" className={iconGreenStyle} title={EvidencePackageStatus.Completed} />
            ) : (
              <Icon iconName="CircleFill" className={iconGreyStyle} title="Not Started" />
            )}
          </div>
        ) : (
          renderStatusSymbol(authorizationInfo, status)
        )}
      </>
    ),
    minWidth: 50,
    maxWidth: 150,
  });

  const auditorColumns: ListColumn[] = useMemo(
    () => [
      stageColumn('auditorSubmit', 'Auditor Submit', EvidencePackageStatus.AuditorSubmit),
      stageColumn('auditorReview', 'Auditor Review', EvidencePackageStatus.AuditorReview),
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const columns: ListColumn[] = useMemo(
    () => [
      {
        key: 'authorization',
        name: 'Authorization',
        onRender: (authorizationInfo: AzureAuthorizationInformation) => (
          <Link
            as={RouterLink}
            to={{
              pathname: authorizationInfo.evidencePackage?.id
                ? evidenceDetailRoute({
                    serviceOid: servicesContext.selectedService?.id || '',
                    evidencePackageId: authorizationInfo.evidencePackage?.id,
                    navMenuTab: '',
                  })
                : createEvidencePackageRoute(),
              state: {
                cloud: authorizationInfo.environmentAuthorization.cloud,
                certification: authorizationInfo.environmentAuthorization.certification,
                serviceId: servicesContext.selectedService?.id,
              },
            }}
            style={linkStyle}
          >
            {authorizationInfo.environmentAuthorization.cloud}
            &nbsp;
            {authorizationInfo.environmentAuthorization.certification}
          </Link>
        ),
        minWidth: 50,
        maxWidth: 150,
      },
      {
        key: 'evidenceGathering',
        name: 'Evidence Gathering',
        styles: headerStyle,
        onRender: (authorizationInfo: AzureAuthorizationInformation) => {
          const serviceAuthorizationsActive = servicesContext.selectedService?.authorizationsActive.find(
            (serviceEnabledAuthorization) =>
              serviceEnabledAuthorization.cloud === authorizationInfo.environmentAuthorization.cloud &&
              serviceEnabledAuthorization.certification === authorizationInfo.environmentAuthorization.certification,
          );
          return (
            <div className={iconWrapperStyle}>
              {serviceAuthorizationsActive !== undefined && serviceAuthorizationsActive.isActive ? (
                <Icon iconName="SkypeCircleCheck" className={iconGreenStyle} title={EvidencePackageStatus.Completed} />
              ) : (
                <SaveButton
                  isSaving={isSaving === authorizationInfo.id}
                  onSave={() => saveButtonClicked(authorizationInfo.environmentAuthorization)}
                  className={buttonStyle}
                  defaultText="Get Started"
                  disabled={
                    isSaving !== undefined ||
                    !servicesContext.selectedService?.isEvidenceGatheringEnabled ||
                    serviceAuthorizationsActive === undefined ||
                    serviceAuthorizationsActive.isActive ||
                    !authContext.isAuthorized([{ operation: SERVICE_WRITE, subject: SITE_WIDE_SUBJECT }])
                  }
                />
              )}
            </div>
          );
        },
        minWidth: 50,
        maxWidth: 150,
      },
      stageColumn('aoSubmit', 'AO Submit', EvidencePackageStatus.AoSubmit),
      stageColumn('aoReview', 'AO Review', EvidencePackageStatus.AoReview),
      stageColumn('AuthorizationStageApproved', 'Authorization Approved', EvidencePackageStatus.AuthorizationApproved),
      {
        key: 'authorizationAchieved',
        name: EvidencePackageStatus.Achieved,
        styles: headerStyle,
        onRender: (authorizationInfo: AzureAuthorizationInformation) => {
          const status = authorizationInfo.evidencePackage?.status;
          return (
            <div className={iconWrapperStyle}>
              {status === EvidencePackageStatus.Achieved ? (
                <div className={authorizationAchievedIconStyle}>
                  <Icon
                    iconName="SkypeCircleCheck"
                    className={iconGreenStyle}
                    title={EvidencePackageStatus.Completed}
                    data-testid="green-check-icon"
                  />
                  <Icon
                    iconName="EditNote"
                    className={iconNoteStyle}
                    onClick={() => confirmButtonClicked(authorizationInfo.evidencePackage!)}
                    title={EvidencePackageStatus.Completed}
                    data-testid="edit-note-icon"
                  />
                </div>
              ) : (
                <SaveButton
                  isSaving={isSaving === authorizationInfo.id}
                  onSave={() => confirmButtonClicked(authorizationInfo.evidencePackage!)}
                  className={buttonStyle}
                  defaultText="Confirm"
                  disabled={authorizationInfo.evidencePackage?.status !== EvidencePackageStatus.Approved}
                />
              )}
            </div>
          );
        },
        minWidth: 50,
        maxWidth: 100,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSaving,
      authContext,
      servicesContext.selectedService?.id,
      servicesContext.selectedService?.isEvidenceGatheringEnabled,
      servicesContext.selectedService?.authorizationsActive,
      saveButtonClicked,
      confirmButtonClicked,
    ],
  );

  useEffect(() => {
    if (isUSNat(configContext.serverConfig?.cloud)) {
      setFilteredColumns(columns);
      return;
    }

    // want to place the auditor columns after the evidenceGathering column
    const evidenceGatheringColumnIndex = columns.findIndex((column) => column.key === 'evidenceGathering');
    const rightColumns = columns.splice(evidenceGatheringColumnIndex + 1, columns.length - evidenceGatheringColumnIndex - 1);
    setFilteredColumns([...columns.slice(0, evidenceGatheringColumnIndex + 1), ...auditorColumns, ...rightColumns]);
  }, [configContext.serverConfig?.cloud, columns, auditorColumns]);

  return <BasicList items={authorizationInformation} columns={filteredColumns} />;
};
