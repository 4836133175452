import { initializeIcons } from '@fluentui/react';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app';
import { registerAzureIcons } from './modules/azureIcons';

initializeIcons();
initializeFileTypeIcons();
registerAzureIcons();

ReactDOM.render(<App />, document.getElementById('root'));
