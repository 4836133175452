import {
  IColumn,
  IScrollablePaneStyles,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  ShimmeredDetailsList,
  mergeStyles,
} from '@fluentui/react';
import { BasicPoam } from 'generated/clientApi';
import { renderFixedDetailsHeader } from 'pages/conMon/components/tableUtils';
import React, { useMemo } from 'react';

export const PoamRiskColumnNames = {
  'POA&M ID': 'POA&M ID',
  'Current Risk': 'Current Risk',
  'Vuln ID': 'Vuln ID',
  'Cloud Type': 'Cloud Type',
} as const;

const customTableStyles = mergeStyles({
  '.ms-Viewport': {
    minWidth: 'inherit',
  },
  height: '300px',
  overflow: 'auto',
  width: '100%',
  position: 'relative',
});

type PoamRiskTableProps = {
  poams: BasicPoam[] | undefined;
};
export const PoamRiskTable: React.FunctionComponent<PoamRiskTableProps> = (props) => {
  const { poams } = props;

  const columns = useMemo(
    (): IColumn[] =>
      // eslint-disable-next-line implicit-arrow-linebreak
      [
        {
          key: PoamRiskColumnNames['POA&M ID'],
          name: 'POA&M ID',
          fieldName: 'poamId',
          minWidth: 100,
          maxWidth: 160,
        },
        {
          key: PoamRiskColumnNames['Current Risk'],
          name: 'Current Risk',
          minWidth: 100,
          maxWidth: 100,
        },
        {
          key: PoamRiskColumnNames['Vuln ID'],
          name: 'Vuln ID',
          fieldName: 'vulnerabilityId',
          minWidth: 150,
          maxWidth: 200,
        },
        {
          key: PoamRiskColumnNames['Cloud Type'],
          name: 'Cloud Type',
          fieldName: 'cloudType',
          minWidth: 150,
          maxWidth: 200,
        },
      ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [PoamRiskColumnNames],
  );

  const scrollPaneStyles = {
    root: {
      '.ms-DetailsList': {
        overflow: 'visible',
      },
    },
  };

  const renderLink = (item: BasicPoam): JSX.Element => <span>{item.effectiveRating}</span>;

  return (
    <div className={customTableStyles}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={scrollPaneStyles as unknown as IScrollablePaneStyles}>
        <ShimmeredDetailsList
          items={poams as BasicPoam[]}
          columns={columns}
          shimmerLines={20}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={renderFixedDetailsHeader}
          onRenderItemColumn={(item, index, column) => {
            if (column?.name === 'Current Risk') {
              return renderLink(item);
            }
            // Render other columns
            return item[column!.fieldName ?? ''];
          }}
        />
      </ScrollablePane>
    </div>
  );
};
