import { CveInfo, PlannedMilestone, Poam, Remediation, ServiceAssetType } from 'generated/clientApi';
import React, { useEffect, useState } from 'react';
import { ITooltipProps, Pivot, PivotItem } from '@fluentui/react';
import { pivotStyles } from 'styles/pivot';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { PanelDetailsTab } from './poamDetailsTab';
import { AssetInformationTable, ServiceAssetTableObject } from './assetInformationTable';
import { CVEsInformationTable } from '../cvesInformationTable';
import { DeviationInformationTab } from './deviationInformationTab';
import { calculateCVSSFromVector } from '../riskPanel/cvssCalc';
import { RemediationForm } from './remediationForm';
import { formatRiskWithoutColor } from '../poamTableColumns';
import { RemediationInternalComments } from '../bulkEditPanel/bulkEditPanel';

type PanelDetailsOverviewTabs = {
  internalPoam: Poam;
  internalCveInfos?: CveInfo[];
  internalAssets?: ServiceAssetType[];
  updatePoam: (poam: Poam) => void;
  onDeleteDeviation(poam: Poam, deviationId: string): void;
};

export const PanelDetailsTabs: React.FunctionComponent<PanelDetailsOverviewTabs> = (props) => {
  const { internalPoam, internalCveInfos, internalAssets, updatePoam, onDeleteDeviation } = props;
  const [tabs, setTabs] = useState<Tab[]>();
  const [tabSelected, setTabSelected] = useState<Tab>();
  const [CVEs, setCVEs] = useState<CveInfo[] | undefined>([]);

  useEffect(() => {
    setTabs(
      Object.keys(Tabs).map((tab) => ({
        key: tab,
        title: Tabs[tab as keyof typeof Tabs].title,
        items: Tabs[tab as keyof typeof Tabs].items.map((item) => ({
          ...item,
          title: item.key !== 'separator' ? TitlesTranslation[item.key?.split('.').reverse()[0] as keyof typeof TitlesTranslation] : 'separator',
          value: item.computized
            ? formatRiskWithoutColor(getProperty(internalPoam, 'originalRating') ?? '', getProperty(internalPoam, 'originalRiskScore'))
            : getProperty(internalPoam, item.key),
        })),
      })),
    );
    setCVEs(
      internalCveInfos?.map((CveInfo) => {
        // eslint-disable-next-line no-param-reassign
        CveInfo.proposedScore = (calculateCVSSFromVector(CveInfo.proposedVectorString) as any).environmentalMetricScore;
        // eslint-disable-next-line no-param-reassign
        CveInfo.link = `http://cve.mitre.org/cgi-bin/cvename.cgi?name=${CveInfo.displayCveId}`;
        return CveInfo;
      }),
    );
  }, [internalCveInfos, internalPoam]);

  const getProperty = (poam: Poam, key: string): string => {
    const [mainKey, subKey] = key.split('.');

    let value = (poam as any)[mainKey];

    if (subKey && mainKey) {
      value = (poam as any)[mainKey]?.[subKey];
    }
    return value ?? '';
  };

  useEffect(() => {
    if (tabs && !tabSelected) {
      setTabSelected(tabs[0]);
    } else if (tabSelected) {
      setTabSelected(tabs?.filter((tab) => tab.title === tabSelected.title)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const updateRemediation = (key: keyof RemediationInternalComments, value?: string | PlannedMilestone[]): void => {
    const newRemediationInfo = { ...internalPoam.remediationInfo, [key]: value } as Remediation;
    const newPoam = structuredClone(internalPoam);
    newPoam.remediationInfo = newRemediationInfo;
    updatePoam(newPoam);
  };

  return (
    <Pivot styles={pivotStyles}>
      {tabs?.map((tab) => (
        <PivotItem headerText={tab.title} key={`poam-tab-${tab.title}`} className={`poam-tab-${tab.title}`}>
          <div style={{ display: 'flex', flexDirection: 'column', margin: '2rem 0' }}>
            <PanelDetailsTab tab={tab} />
            {CVEs?.length ? <CVEsInformationTable cves={CVEs} /> : null}
          </div>
        </PivotItem>
      ))}
      <PivotItem headerText="Remediation information">
        <div style={{ marginTop: '2rem', marginLeft: '1rem' }}>
          <RemediationForm remediationInfo={internalPoam.remediationInfo} updateRemediation={updateRemediation} />
        </div>
      </PivotItem>
      <PivotItem headerText="Deviation information">
        <DeviationInformationTab poam={internalPoam as Poam} onDeleteDeviation={onDeleteDeviation} />
      </PivotItem>
      <PivotItem headerText="Asset information">
        <AssetInformationTable
          assets={(internalPoam.isManualPoam ? internalPoam.assets ?? [] : internalAssets ?? []) as ServiceAssetTableObject[]}
          poamId={internalPoam.poamId ?? ''}
        />
      </PivotItem>
    </Pivot>
  );
};

const createTooltip = (element: ReactElement): ITooltipProps => ({
  onRenderContent: () => element,
});

const Tabs = {
  vulnerabilityInfo: {
    title: 'Vulnerability information',
    items: [
      {
        key: 'dueDate',
        type: 'text',
        tooltip: createTooltip(
          <>
            <p>
              <b>Kusto: </b>
              SLA date based on max CVSS score and OriginalDetectionDate
            </p>
            <p>
              <b>POA&M Report: </b>
              SLA date used to determine PlannedRemediationDate and LockedScheduledCompletionDate, which are both used for Scheduled Completion Date
              column (If POA&M is new, use PlannedRemediationDate; Otherwise use LockedScheduledCompletionDate)
            </p>
          </>,
        ),
      },
      {
        key: 'detectionDate',
        type: 'text',
        tooltip: createTooltip(
          <>
            <p>
              <b>Kusto: </b>
              <i>ScanCompleteTime</i>
            </p>
            <p>
              <b>POA&M Report: </b>
              Original Detection Date
            </p>
          </>,
        ),
      },
      {
        key: 'originalRisk',
        type: 'text',
        tooltip: createTooltip(
          <>
            <p>
              <b>Kusto: </b>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <i>CVSS_V3_BASE</i>,<i> CVSS_BASE </i>
              and NVD scores used to determine Original Rating of vulnerability
            </p>
            <p>
              <b>POA&M Report: </b>
              Original Risk Rating
            </p>
          </>,
        ),
        computized: true,
      },
      {
        key: 'adjustedRisk',
        type: 'text',
        tooltip: createTooltip(<p>Adjusted Risk Rating from an approved risk deviation.</p>),
      },
      {
        key: 'source',
        type: 'text',
        tooltip: createTooltip(
          <>
            <p>
              <b>Kusto: </b>
              <i> VULN_TYPE </i>
              (from QualysKB)
            </p>
            <p>
              <b>POA&M Report: </b>
              N/A
            </p>
          </>,
        ),
      },
      {
        key: 'scanType',
        type: 'text',
        tooltip: createTooltip(
          <>
            <p>
              <b>Kusto: </b>
              <i>ScanType</i>
            </p>
            <p>
              <b>POA&M Report: </b>
              Weakness Detector Source
            </p>
          </>,
        ),
      },
      {
        key: 'vulnerabilityId',
        type: 'text',
        tooltip: createTooltip(
          <>
            <p>
              <b>Kusto: </b>
              <i>QID </i>
              or manually generated. (This is the vulnerability ID)
            </p>
            <p>
              <b>POA&M Report: </b>
              Weakness Source Identifier
            </p>
          </>,
        ),
      },
      {
        key: 'separator',
        type: 'separator',
      },
    ] as TabItem[],
  },
};

enum TitlesTranslation {
  operationalRequirement = 'Operational requirement',
  falsePositive = 'False positive',
  externalComments = 'External comments',
  checkInDate = 'Vendor check-in date',
  productName = 'Product Name',
  comment = 'Planned remediation comments',
  plannedRemediationDate = 'Planned remediation date',
  overallRemediationPlan = 'Overall remediation plan',
  slaDate = 'SLA Date',
  detectionDate = 'Detection Date',
  dueDate = 'Due Date',
  originalRisk = 'Original Risk',
  adjustedRisk = 'Adjusted Risk',
  source = 'Source',
  scanType = 'Scan Tool',
  drTitle = 'DR Title',
  expirationDate = 'Expiration',
  proposedRiskRating = 'Proposed risk adjustment',
  approvedRiskRating = 'Approved risk adjustment',
  vulnerabilityId = 'Scan ID',
  drDate = 'DR Date',
  drReviewDate = 'DR Review Date',
  drLink = 'DR Link',
  drApproval = 'DR approval',
}

export interface Tab {
  [key: string]: any;
  title: string;
  items: TabItem[];
}

export interface TabItem {
  key: string;
  title: string;
  value: string;
  type: string;
  inputType?: string;
  placeholder?: string;
  tooltip?: ITooltipProps;
  computized?: boolean;
}
