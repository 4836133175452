import { DetailsList, IColumn, SelectionMode, TooltipHost, mergeStyles } from '@fluentui/react';
import { DayCoverage, DayTotal } from 'generated/clientApi';
import React, { useEffect, useMemo, useRef } from 'react';
import DayCoverageCell from './dayCoverageCell';
import { useCoverageFilters } from './useCoverageFilters';

const customTableStyles = mergeStyles({
  'div:first-child': {
    minWidth: 'fit-content',
  },
});
type TotalsProps = {
  totals: DayTotal[];
  scrollLeft: number;
};
export const Totals: React.FunctionComponent<TotalsProps> = (props) => {
  const { totals, scrollLeft } = props;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
    }
  }, [scrollLeft]);

  const isExcludeView = useCoverageFilters().exclude;

  const columns = useMemo((): IColumn[] => {
    const dayColumns: IColumn[] = [];

    const dayTotalMap: Map<number, DayTotal> = totals.reduce((map, total) => {
      map.set(total.day, total);
      return map;
    }, new Map());
    const maxDay = totals.at(-1)?.day ?? 0;
    for (let i = 0; i < maxDay; i++) {
      const total = dayTotalMap.get(i + 1);
      const column: IColumn = {
        key: `${i}`,
        name: `${i}`,
        minWidth: 85,
        maxWidth: 85,
        isResizable: false,
        onRender: (_item: DayTotal[]) => {
          if (!total) {
            return <div style={{ width: '90px', height: '45px', textAlign: 'center', lineHeight: '2.7rem', opacity: '.7' }}>no data</div>;
          }
          const scanAssetCount = total.scanAssetCountTotal;
          const inventoryAssetCount = total.inventoryAssetCountTotal;
          const dayCov: DayCoverage = {
            id: '',
            scanAssetCount,
            inventoryAssetCount,
            day: total.day,
            percentage: total.percentage,
            authenticatedPercentage: total.percentage,
          } as DayCoverage;
          return (
            <TooltipHost content={`${scanAssetCount} / ${inventoryAssetCount}`} id="dayCoverageTooltipId">
              <button type="button" aria-label="Day coverage tooltip" style={{ all: 'unset', display: 'table-cell', pointerEvents: 'none' }}>
                <DayCoverageCell
                  isCurrentSelection={false}
                  isOldUserSelection={false}
                  isDefaultSystemSelection={false}
                  dayCoverage={dayCov}
                  showRawNumberAsTooltip
                />
              </button>
            </TooltipHost>
          );
        },
      };
      dayColumns.push(column);
    }
    return dayColumns;
  }, [totals]);

  if (totals.length === 0 || isExcludeView) {
    return <></>;
  }
  return (
    <div style={{ display: 'flex', paddingRight: '1rem', alignItems: 'center', gap: '1rem' }}>
      <div style={{ marginLeft: 'auto', fontSize: '16px', fontWeight: 600 }}>Coverage totals</div>
      <div
        ref={scrollContainerRef}
        style={{ overflowX: 'hidden', width: 'calc(100% - 710px)', paddingRight: '2.1rem' }}
        className={customTableStyles}
      >
        <DetailsList items={[totals]} columns={columns} selectionMode={SelectionMode.none} isHeaderVisible={false} />
      </div>
    </div>
  );
};
