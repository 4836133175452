import { mergeStyles } from '@fluentui/react';
import React, { FunctionComponent } from 'react';

const tabPageStyles = mergeStyles({
  display: 'flex',
  flex: 1,
});

const tabPageNoMarginStyles = mergeStyles({
  display: 'flex',
  flex: 1,
});

export interface TabPageProps {
  title: string;
  noMargin?: boolean;
  children?: JSX.Element;
}

export const TabPage: FunctionComponent<TabPageProps> = (props) => (
  <div className={props.noMargin ? tabPageNoMarginStyles : tabPageStyles} title={props.title}>
    {props.children}
  </div>
);
