import { IDialogContentProps, Link, mergeStyles, NeutralColors } from '@fluentui/react';
import { ConfigContext } from 'components/configProvider/configContext';
import { getCloudCertification, getCombinedCloudCertifications } from 'modules/environmentAuthorization/environmentAuthorization';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { dodExclusiveCertifications } from 'models/dodExclusivePackages';
import { AuthContext } from '../../components/authProvider/authContext';
import { BasicList, ListColumn } from '../../components/basicList/basicList';
import { FilterBubble } from '../../components/filterBubble/filterBubbleComponent';
import { OfferingContext } from '../../components/offeringProvider/offeringContext';
import { PanelFooterButtons } from '../../components/panel/panelFooterButtons';
import { CenteredProgressDots } from '../../components/progressDots/progressDots';
import { IEvidencePackageSubmission, IOffering, IOfferingSubmissionDetail, OfferingSubmissionDetail } from '../../generated/clientApi';
import { EvidencePackageStatus } from '../../models/evidencePackageStatus';
import { LoadingState } from '../../models/loadingState';
import { COMPLIANCE_TEAM_ACTIONS_READ, DOD_AUTHORIZATION_ACTIONS_WRITE, SITE_WIDE_SUBJECT } from '../../modules/constants';
import { showDialogModal } from '../../modules/dialog/dialog';
import { updateMultipleEvidencePackageSubmission } from '../../modules/evidencePackage/evidencePackage';
import { getOfferingSubmissionDetailsList } from '../../modules/offering/offering';
import { createHomeChromeConfig, updatePageChromeConfig } from '../../modules/pageChrome/pageChrome';
import { evidenceDetailRoute, serviceDetailRoute } from '../../modules/routes/routes';

interface IOfferingBubbleFilter {
  name?: string;
  cloudCert?: string;
  offering?: IOffering;
}

const widgetStyle = mergeStyles({
  backgroundColor: NeutralColors.white,
  padding: '1em',
  margin: '0.5em',
  display: 'flex',
  flex: '1',
});

const pageStyle = mergeStyles({
  display: 'flex',
  flexDirection: 'column',
});

const linkStyle = {
  textDecoration: 'none',
};

const footerStyle = mergeStyles({
  borderTop: '1px solid #eee',
  paddingTop: '20px',
});

const modalBodyStyle = mergeStyles({
  marginBottom: '20px',
});

const columns: ListColumn[] = [
  {
    key: 'serviceName',
    name: 'Service Name',
    onRender: (offeringSubmissionDetail: IOfferingSubmissionDetail) => (
      <Link as={RouterLink} to={serviceDetailRoute({ serviceOid: offeringSubmissionDetail.serviceOid })} style={linkStyle}>
        {offeringSubmissionDetail.serviceName}
      </Link>
    ),
    minWidth: 200,
    maxWidth: 250,
  },
  {
    key: 'packageStatus',
    name: 'Package Status',
    onRender: (offeringSubmissionDetail: IOfferingSubmissionDetail) => <>{offeringSubmissionDetail.status}</>,
    minWidth: 50,
    maxWidth: 150,
  },
  {
    key: 'createdDate',
    name: 'Created Date',
    onRender: (offeringSubmissionDetail: IOfferingSubmissionDetail) => <>{offeringSubmissionDetail.createdDate}</>,
    minWidth: 50,
    maxWidth: 150,
  },
  {
    key: 'submittedBy',
    name: 'Created By',
    onRender: (offeringSubmissionDetail: IOfferingSubmissionDetail) => <>{offeringSubmissionDetail.createdBy}</>,
    minWidth: 50,
    maxWidth: 150,
  },
  {
    key: 'submittedDate',
    name: 'Submitted',
    onRender: (offeringSubmissionDetail: IOfferingSubmissionDetail) => <>{offeringSubmissionDetail.submittedDate}</>,
    minWidth: 50,
    maxWidth: 150,
  },
  {
    key: 'package',
    name: 'Package',
    onRender: (offeringSubmissionDetail: IOfferingSubmissionDetail) => (
      <>
        {offeringSubmissionDetail.name && (
          <Link
            as={RouterLink}
            to={evidenceDetailRoute({
              serviceOid: offeringSubmissionDetail.serviceOid,
              evidencePackageId: offeringSubmissionDetail.id,
              navMenuTab: '',
            })}
            style={linkStyle}
          >
            {offeringSubmissionDetail.name}
          </Link>
        )}
      </>
    ),
    minWidth: 50,
    maxWidth: 150,
  },
];

export const SubmitOfferingPage: React.FunctionComponent = () => {
  const [offeringSubmissionDetails, setOfferingSubmissionDetails] = useState<OfferingSubmissionDetail[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<LoadingState>(LoadingState.NotLoaded);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [offeringBubbleFilter, setOfferingBubbleFilter] = useState<IOfferingBubbleFilter>({
    name: undefined,
    cloudCert: undefined,
  });
  const offeringsContext = useContext(OfferingContext);
  const configContext = useContext(ConfigContext);
  const authContext = useContext(AuthContext);
  const [cloudCertifications, setCloudCertifications] = useState<string[]>([]);
  const isComplianceTeam = authContext.isAuthorized([{ operation: COMPLIANCE_TEAM_ACTIONS_READ, subject: SITE_WIDE_SUBJECT }]);
  const isDodOfficial = authContext.isAuthorized([{ operation: DOD_AUTHORIZATION_ACTIONS_WRITE, subject: SITE_WIDE_SUBJECT }]);

  useEffect(() => {
    updatePageChromeConfig(createHomeChromeConfig('submit-offering', isComplianceTeam));
    return () => updatePageChromeConfig();
  }, [isComplianceTeam]);

  useEffect(() => {
    offeringsContext.requestOfferings();
  }, [offeringsContext]);

  useEffect(() => {
    setIsLoading(offeringsContext.offeringsLoadingState);
  }, [offeringsContext.offeringsLoadingState]);

  const isAnyQueuedService = useCallback(() => {
    if (!offeringSubmissionDetails) {
      return false;
    }
    return offeringSubmissionDetails.some((o) => o.status === EvidencePackageStatus.Queued);
  }, [offeringSubmissionDetails]);

  useEffect(() => {
    const getData = async () => {
      const { cloud, certification } = getCloudCertification(offeringBubbleFilter.cloudCert);
      if (
        offeringBubbleFilter &&
        offeringBubbleFilter.name &&
        cloud &&
        certification &&
        offeringBubbleFilter.offering &&
        offeringBubbleFilter.offering.serviceOids
      ) {
        setOfferingSubmissionDetails(undefined);
        setIsLoading(LoadingState.Loading);
        const offeringSubmissionDetailResponse = await getOfferingSubmissionDetailsList(
          offeringBubbleFilter.offering.serviceOids,
          cloud,
          certification,
          offeringBubbleFilter.offering.id,
        );
        if (offeringSubmissionDetailResponse && offeringSubmissionDetailResponse.offeringSubmissionDetails) {
          setOfferingSubmissionDetails(offeringSubmissionDetailResponse.offeringSubmissionDetails);
          setIsSubmitDisabled(
            () => !offeringSubmissionDetailResponse.offeringSubmissionDetails?.some((o) => o.status === EvidencePackageStatus.Queued),
          );
        }
        setIsLoading(LoadingState.Loaded);
      } else {
        setOfferingSubmissionDetails(undefined);
        setIsSubmitDisabled(true);
      }
    };
    getData();
  }, [offeringBubbleFilter, offeringBubbleFilter.cloudCert, offeringBubbleFilter.offering]);

  useEffect(() => {
    const certifications = getCombinedCloudCertifications(configContext.environmentAuthorizations);
    if (!isDodOfficial) {
      setCloudCertifications(
        certifications.filter((certification) => !Object.values(dodExclusiveCertifications).includes(certification as dodExclusiveCertifications)),
      );
      return;
    }
    setCloudCertifications(certifications);
  }, [configContext.environmentAuthorizations, isDodOfficial]);

  const offeringNames = useMemo(() => offeringsContext.offerings?.map((offering) => offering.name) ?? [], [offeringsContext]);

  const filterFields = [
    {
      key: 'cloudCert',
      name: 'Auth',
      fieldName: 'cloudCert',
      filterSelection: offeringBubbleFilter?.cloudCert,
      options: cloudCertifications,
    },
    {
      key: 'offering',
      name: 'Offering',
      fieldName: 'offering',
      includeNone: true,
      filterSelection: offeringBubbleFilter?.name,
      options: offeringNames,
    },
  ];

  const onSetOfferingBubbleFilter = (name?: string, cloudCert?: string, offering?: IOffering): IOfferingBubbleFilter => {
    const copiedOfferingBubbleFilter = { ...offeringBubbleFilter };
    copiedOfferingBubbleFilter.name = name;
    copiedOfferingBubbleFilter.cloudCert = cloudCert;
    copiedOfferingBubbleFilter.offering = offering;
    return copiedOfferingBubbleFilter;
  };

  const setFilterEntry = async (value: string[], columnName: string) => {
    const newValue = value[0];
    switch (columnName) {
      case 'cloudCert':
        setOfferingBubbleFilter(() => onSetOfferingBubbleFilter(offeringBubbleFilter.name, newValue, offeringBubbleFilter.offering));
        break;
      case 'offering': {
        const offering = offeringsContext.offerings?.find((offering) => offering.name === newValue);
        setOfferingBubbleFilter(() => onSetOfferingBubbleFilter(newValue, offeringBubbleFilter.cloudCert, offering));
        break;
      }
      default:
        break;
    }
  };

  const onSubmitOfferingPackages = useCallback(async () => {
    if (offeringSubmissionDetails && offeringSubmissionDetails.length > 0) {
      setIsLoading(LoadingState.Loaded);
      const queuedOfferingSubmissionDetails = offeringSubmissionDetails.filter(
        (offeringSubmissionDetail) => offeringSubmissionDetail.status === EvidencePackageStatus.Queued,
      );
      const copiedOfferingSubmissionDetails = queuedOfferingSubmissionDetails.map(
        (offeringSubmissionDetail) =>
          ({
            id: offeringSubmissionDetail.id,
            serviceOid: offeringSubmissionDetail.serviceOid,
          }) as IEvidencePackageSubmission,
      );

      await updateMultipleEvidencePackageSubmission(copiedOfferingSubmissionDetails);
      // force page refresh to show updated data.
      setOfferingBubbleFilter((offeringBubbleFilter: IOfferingBubbleFilter) => ({ ...offeringBubbleFilter }));
    }
  }, [offeringSubmissionDetails]);

  const onSaveButtonClick = useCallback(async () => {
    const dialogContents: IDialogContentProps = {
      title: `${offeringBubbleFilter.name} Submission`,
    };

    let bodyText;
    if (!isAnyQueuedService()) {
      bodyText = `You are about to submit the ${offeringBubbleFilter.name} offering and all the associated evidence packages to the Auditor for their review. Are you sure you want to submit?`;
    } else {
      bodyText = `You are about to submit the ${offeringBubbleFilter.name} offering without having a package queued for every service. Are you sure you want to submit this incomplete offering?`;
    }
    const modalBody = <div className={modalBodyStyle}>{bodyText}</div>;
    showDialogModal(dialogContents, () => onSubmitOfferingPackages(), modalBody, undefined, undefined, '600px');
  }, [isAnyQueuedService, offeringBubbleFilter.name, onSubmitOfferingPackages]);

  const submitToolTip = isSubmitDisabled
    ? `No service in offering ${offeringBubbleFilter.name} is in the 'Queued' stage.`
    : 'Submit Offering Package';

  return (
    <div className={pageStyle}>
      <div className={widgetStyle}>
        {filterFields.map((filter) => (
          <FilterBubble
            key={filter.fieldName}
            id={filter.fieldName}
            fieldName={filter.name}
            filter={filter.filterSelection ? [filter.filterSelection] : []}
            includeNone={filter.includeNone}
            noneDisplay="None"
            valueOptions={filter.options}
            onChange={setFilterEntry}
          />
        ))}
      </div>
      {isLoading !== LoadingState.Loaded ? (
        <div className={widgetStyle}>
          <CenteredProgressDots />
        </div>
      ) : (
        <>
          {offeringSubmissionDetails ? (
            <div className={pageStyle}>
              <h1>Offering Submission Details</h1>
              <BasicList items={offeringSubmissionDetails} columns={columns} />
              <div className={footerStyle}>
                <PanelFooterButtons
                  defaultConfirmText="Submit"
                  saveDisabled={isSubmitDisabled}
                  isSaving={false}
                  saveTitle={submitToolTip}
                  onSave={onSaveButtonClick}
                />
              </div>
            </div>
          ) : (
            <div className={widgetStyle}>Choose from the filters above to select an authorization for a offering.</div>
          )}
        </>
      )}
    </div>
  );
};
