import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { CcmUser } from '../../generated/clientApi';
import { LoadingState } from '../../models/loadingState';
import { logError } from '../../modules/logging/logging';
import { getUserList } from '../../modules/user/user';
import { UsersContext, UsersContextProps } from './usersContext';

export const UsersProvider: FunctionComponent<RouteProps> = ({ children }) => {
  const [users, setUsers] = useState<CcmUser[]>([]);
  const [usersLoaded, setUsersLoaded] = useState<LoadingState>(LoadingState.NotLoaded);
  const getUsers = async () => {
    try {
      const users = await getUserList();
      setUsers(users);
      setUsersLoaded(LoadingState.Loaded);
    } catch (error) {
      setUsersLoaded(LoadingState.Error);
      logError('There was an issue loading the users', error);
    }
  };

  const contextProps: UsersContextProps = {
    users,
    usersLoadingState: usersLoaded,
    requestUsers: () => {
      if (usersLoaded === LoadingState.NotLoaded || usersLoaded === LoadingState.Error) {
        setUsersLoaded(LoadingState.Requested);
      }
    },
    updateUsers: () => {
      setUsersLoaded(LoadingState.Requested);
      getUsers();
    },
  };

  // Auto grab the offerings if someone requests them
  useEffect(() => {
    if (usersLoaded === LoadingState.Requested) {
      getUsers();
    }
  }, [usersLoaded, users]);

  return <UsersContext.Provider value={contextProps}>{children}</UsersContext.Provider>;
};
